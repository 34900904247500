import React from "react";
import { Link } from "react-router-dom";
import { SidebarItemView } from "../../CategorySidebar";
import '../MenuItem.scss';
export default class SumMenuItem extends React.PureComponent<{ onClick: () => void, onNavigate?: () => void, menuItem: SidebarItemView,
     role?: string, catalogMode: 'express' | 'trade' | 'api' },
    { title: string, isOpened: boolean, subItems: SidebarItemView[], id: string }> {
    constructor(props: any) {
        super(props);
        this.state = { title: '', subItems: [], isOpened: false, id: '' };
    }
    componentDidMount() {
        this.setState({ title: this.props.menuItem.title, subItems: this.props.menuItem.subMenu, isOpened: false, id: this.props.menuItem.id });
    }

    componentDidUpdate(prevProps: any) {
        this.setState(prevState => { return { ...prevState, isOpened: this.props.menuItem.isActive } });
    }

    activeSubMenu = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        e.stopPropagation();
        this.props.onClick();
        //this.setState(prevState => { return { ...prevState, subItems: prevState.subItems } });
    }

    render(): React.ReactNode {
        return (
            <li onClick={(e) => this.activeSubMenu(e)}>
                {
                    this.state.subItems.length > 0 ?
                        <div>
                            {this.state.title}
                            {this.state.isOpened ?
                                <ul>
                                    {
                                        this.state.subItems.map((item, index) => (
                                            <SumMenuItem role={this.props.role} key={index} menuItem={item} onClick={() => { }} onNavigate={this.props.onNavigate} catalogMode={this.props.catalogMode}></SumMenuItem>
                                        ))
                                    }
                                </ul>
                                : null
                            }
                        </div>
                        :
                        <Link onClick={this.props.onNavigate} to={`/marketplace/?categoryId=${this.state.id}&mode=${this.props.catalogMode}`}>{this.state.title}</Link>
                }
            </li>
        );
    }
}