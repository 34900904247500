import React, { RefObject } from "react"
import { Form, Row } from "react-bootstrap"
import OrdersService, { SellerOrderRowDTO } from "../../../../services/orders/OrdersService"
import AuthenticationContext from '../../../../contexts/AuthenticationContext/AuthenticationContext'
import { IState } from "./SellOrders.models"
import { GridAction, GridColumn, GridColumnTypes, GridResult } from "../../../Grid/Grid.models"
import orderStatusDisplayBadge, { OrderStatusEnum } from "../../OrderEnums"
import SellOrderDetails from "./details/SellOrderDetails"
import DynamicGrid from "../../../shared/DynamicGrid/DynamicGrid"
import { ISellOrdersPaginationFilters } from "../OrdersList/OrdersList.models"
import { toast } from "react-toastify"

export default class SellOrders extends React.PureComponent<{ defaultFilters?: ISellOrdersPaginationFilters }, IState> {
    dynamicGrid: RefObject<DynamicGrid<ISellOrdersPaginationFilters>>
    sellOrderDetails: RefObject<SellOrderDetails>
    ordersService: OrdersService
    static contextType = AuthenticationContext
    context!: React.ContextType<typeof AuthenticationContext>
    constructor(props: any) {
        super(props)
        this.dynamicGrid = React.createRef()
        this.sellOrderDetails = React.createRef()
        this.ordersService = new OrdersService()
        this.state = {}
    }

    render(): React.ReactNode {
        let gridColumns: GridColumn[] = [
            { title: 'Order Number', key: 'orderNumber' },
            { title: 'Amount', key: 'totalAmount', type: GridColumnTypes.Amount },
            { title: 'Cashout Amount', key: 'totalCashoutAmount', type: GridColumnTypes.Amount },
            { title: 'Share Amount', key: 'totalShareAmount', type: GridColumnTypes.Amount },
            { title: 'Buyer Name', key: 'buyerName' },
            { title: 'Status', key: 'statusBadge' },
            { title: 'Date', key: 'createAt', type: GridColumnTypes.Date },
        ]
        let gridActions: GridAction[] = [
            {
                caption: 'Details', variant: 'outline-info', onClick: (row) => this.setState({ selectedRowId: row.id }),

            }
        ]

        return (<div>
            <DynamicGrid<ISellOrdersPaginationFilters> ref={this.dynamicGrid}
                columns={gridColumns}
                onFetch={this.ordersService.getSellOrders}
                actions={gridActions}
                defaultFilters={this.props.defaultFilters}
                refactorRow={(row) => {
                    return { ...row, statusBadge: orderStatusDisplayBadge(row.status) }
                }} />

            {
                this.state.selectedRowId !== undefined && <SellOrderDetails orderId={this.state.selectedRowId} onClose={this.closeDetailsModal} />
            }
        </div>)
    }

    closeDetailsModal = () => {
        this.setState({ selectedRowId: undefined })
        this.dynamicGrid.current.resetSpinner();
    }

    filtersView = (filters: ISellOrdersPaginationFilters, onChange: (newFilters: ISellOrdersPaginationFilters) => void): JSX.Element => {
        return <>
            <Form.Group>
                <Form.Label>Order Number</Form.Label>
                <Form.Control value={filters.orderNumber} onChange={(e) => {
                    onChange({ ...filters, orderNumber: e.target.value })
                }} type="text" name="orderNumber" placeholder="Enter order number" />
            </Form.Group>
        </>
    }
}