import React from "react";
import './PageTitle.scss'

export default class PageTitle extends React.PureComponent<{ title: string, subTitle?: string }, {}> {
    render(): React.ReactNode {
        return <div>
            <span className="title">{this.props.title}</span>
            {this.props.subTitle && <span className="subtitle">{this.props.subTitle}</span>}
        </div>
    }
}