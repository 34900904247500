import { IAdminWithdrawalsFilters } from "../../../components/admin/financials/AdminWithdrawals/AdminWithdrawals.models";
import { CashOutStatusEnum } from "../../../components/financials/Enums/CashOutStatusEnum";
import { GridResult } from "../../../components/Grid/Grid.models";
import { PaginationQuery } from "../../../components/Grid/Pagination/Pagination.models";
import { mapToDataOnly } from "../../common/HttpUtility";
import { CashoutApiFactory, WithdrawApiFactory } from "../../../generated-api/financials";
import { WithdrawApiFactory as OldWithdrawApiFactory } from "../../../generated-api/admin";

interface IAdminWithdrawRowDto {
    id: string
    amount: number
    handleAt: string | null
    requestAt: string
    status: CashOutStatusEnum
    shopTitle: string
    shopId: string
    isBlockchainVerification: boolean | null
}

interface IAdminWithdrawDetailsDto {
    id: string
    amount: number
    handleAt: string | null
    requestAt: string
    status: CashOutStatusEnum
    shopTitle: string
    shopId: string
    description: string,
    transactionNumber: string,
    financialAccountType: string,
    financialAccount: string,
    handlerUserId: string,
    handlerName: string,
}

export default class AdminWithdrawalsService {
    get(query: IAdminWithdrawalsFilters, paginationQuery: PaginationQuery): Promise<GridResult<IAdminWithdrawRowDto>> {
        return mapToDataOnly<any>(WithdrawApiFactory().apiV1AdminWithdrawGet(query.status, query.transactionNumber, query.shopId, query.unreviewedOnly, paginationQuery.pageskip - 1, paginationQuery.pagesize));
    }

    reject(withdrawId: string): Promise<void> {
        return mapToDataOnly<any>(OldWithdrawApiFactory().adminWithdrawRejectWithdrawIdPut(withdrawId));
    }

    done(withdrawId: string, transactionNumber: string): Promise<void> {
        return mapToDataOnly<any>(WithdrawApiFactory().apiV1AdminWithdrawDoneWithdrawIdPut(withdrawId, { transactionNumber }));
    }
    
    accept(withdrawId: string): Promise<void> {
        return mapToDataOnly<any>(WithdrawApiFactory().apiV1AdminWithdrawAcceptWithdrawIdPut(withdrawId));
    }

    review(withdrawId: string): Promise<void> {
        return mapToDataOnly<any>(WithdrawApiFactory().apiV1AdminWithdrawReviewWithdrawIdPut(withdrawId));
    }


    getById(id: string): Promise<IAdminWithdrawDetailsDto> {
        return mapToDataOnly<any>(CashoutApiFactory().apiV1AdminCashoutIdGet(id));
    }
}