import React from "react";
import { Container, Navbar, Offcanvas } from "react-bootstrap";
import './NavBarComponent.scss'
import LogoImage from '../../../assets/images/logo/logo.webp'
import { NavLink } from "react-router-dom";
import NavLinkItem from "../../shared/NavLinkItem/NavLinkItem";

export default class NavBarComponent extends React.PureComponent<{}, {}> {
    render(): React.ReactNode {
        return <Navbar bg="light" expand="lg" id="nav-bar-landing" className="border-bottom-custom">
            <Container>
                <Navbar.Brand>
                    <div className="d-flex align-items-center">
                        <img
                            src={LogoImage}
                            width="36"
                            alt=""
                        />
                        <NavLink to="/" className="me-2 text-black font-bold fs-2">API Gift</NavLink>
                    </div>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <div className="web"><NavLinkItem /></div>
                {this.menuMobile()}
            </Container>
        </Navbar >
    }

    menuMobile() {
        return <>
            <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-lg`}
                aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
                placement="end"
            >
                <Offcanvas.Header closeVariant="white" closeButton></Offcanvas.Header>
                <Offcanvas.Body>
                    <NavLinkItem />
                </Offcanvas.Body>
            </Navbar.Offcanvas>
        </>
    }
}
