import React from "react";
import { Alert, Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import AdminWalletsServices from "../../../../../services/admin/financials/AdminWalletsServices";
import { toast } from "react-toastify";

export default class LockWalletModal extends React.Component<{ reload: () => void, onClose: () => void, walletId: string, isLock: boolean }, { description: string, pendingRequest: boolean }> {
    adminWalletsServices: AdminWalletsServices
    constructor(props: any) {
        super(props);
        this.state = { pendingRequest: false, description: '' }
        this.adminWalletsServices = new AdminWalletsServices()
    }

    submit = () => {
        this.setState({ pendingRequest: true })

        let body = {
            description: this.state.description,
            isLock: !this.props.isLock
        }

        this.adminWalletsServices.lockWallet(body, this.props.walletId).then(response => {
            toast.success(this.props.isLock ? "The wallet unlocked successfully" : "The wallet  locked successfully")
            this.props.onClose()
            this.props.reload()
        }).finally(() => this.setState({ pendingRequest: false }))
    }

    render(): React.ReactNode {
        let { onClose } = this.props
        let { description, pendingRequest } = this.state

        return <Modal show={true} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>{this.props.isLock ? 'Unlock Wallet' : 'Lock Wallet'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        {
                            !this.props.isLock &&
                            <Col md="12" sm="12">
                                <Alert variant="warning">The outgoing transactions of this wallet will be blocked, the incoming transactions will still be active</Alert>
                            </Col>
                        }
                        <Col className="mt-3" md="12" sm="12">
                            <Form.Label>Description:</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="Enter description"
                                value={description}
                                onChange={e => this.setState({ description: e.target.value })} />
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                {pendingRequest ? <Button variant='warning' disabled><Spinner size="sm" animation='border' /></Button> :
                    <Button variant='warning' onClick={this.submit}>{this.props.isLock ? 'Unlock' : 'Lock'}</Button>}
            </Modal.Footer>
        </Modal>
    }
}