import React from "react";
import { Alert, Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import AdminWalletsServices, { WalletChargeStatusDTO } from "../../../../../services/admin/financials/AdminWalletsServices";
import ValidAmount from "../../../../utilities/Amount/ValidAmount";
import ShopService from "../../../../../services/shops/ShopService";
import Amount from "../../../../utilities/Amount/Amount";

export default class AdminExchangeModal extends React.Component<{ reload: () => void, onClose: () => void, walletId: string },
    {
        description: string, amount: string, toWalletTitle?: string, toWalletAddress: string, pendingRequest: boolean, walletInformation?: WalletChargeStatusDTO,
        toWalletInquiryPendingRequest: boolean, errorMessage?: string
    }> {
    adminWalletsServices: AdminWalletsServices
    shopService: ShopService
    constructor(props: any) {
        super(props);
        this.state = { pendingRequest: false, description: '', amount: '0', toWalletAddress: '', toWalletInquiryPendingRequest: false }
        this.adminWalletsServices = new AdminWalletsServices()
        this.shopService = new ShopService()
    }

    componentDidMount() {
        this.adminWalletsServices.getStatus(this.props.walletId).then((response) => {
            this.setState({ walletInformation: response })
        })
    }

    submit = () => {
        this.setState({ pendingRequest: true })

        let body = {
            fromWalletId: this.props.walletId,
            toWalletId: this.state.toWalletAddress,
            amount: this.state.amount,
            description: this.state.description
        }

        this.adminWalletsServices.exchange(body).then(() => {
            this.props.onClose()
            this.props.reload()
        }).finally(() => this.setState({ pendingRequest: false }))
    }

    onChangeWalletAddress = async (value: string) => {
        this.setState({ toWalletAddress: value, toWalletTitle: undefined, errorMessage: undefined })

        if (value != '' && value != null) {
            this.setState({ toWalletInquiryPendingRequest: true })

            await this.adminWalletsServices.getStatus(value).then((response) => {
                if (response.walletCurrencyId == this.state.walletInformation.walletCurrencyId)
                    this.setState({ errorMessage: 'The source and destination currencies are the same, use the transfer section.' })
                else
                    this.setState({ toWalletTitle: response.title + " - " + response.walletCurrencyTitle })
            }).finally(() => this.setState({ toWalletInquiryPendingRequest: false }))
        }
    }

    render(): React.ReactNode {
        let { onClose } = this.props
        let { description, errorMessage, pendingRequest, amount, toWalletAddress, walletInformation, toWalletTitle, toWalletInquiryPendingRequest } = this.state

        return <Modal show={true} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Wallet Exchange</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {
                        walletInformation &&
                        <Row className="mb-3">
                            <Col md="12" sm="12">
                                From Wallet: <b>{walletInformation.title} - {walletInformation.walletCurrencyTitle}</b>
                            </Col>
                            <Col md="12" sm="12">
                                Balance: <b><Amount value={walletInformation.balance} /></b>
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Col md="12" sm="12">
                            <Form.Group>
                                <Form.Label>Wallet Address:  </Form.Label>
                                <Form.Control type="text" placeholder="Enter destination address"
                                    value={toWalletAddress}
                                    onChange={(e) => this.onChangeWalletAddress(e.target.value)} />
                            </Form.Group>
                            {toWalletInquiryPendingRequest ? <Spinner animation="border" size="sm" /> :
                                toWalletTitle && <div className='mt-1 mb-2'> To Wallet: <b>{toWalletTitle}</b></div>
                            }
                            {errorMessage && <Alert className="mt-2" variant='danger'>{errorMessage}</Alert>}
                        </Col>
                        <Col className="mt-3" md="12" sm="12">
                            <Form.Group>
                                <Form.Label>Amount:</Form.Label>
                                <Form.Control
                                    type="text"
                                    inputMode='decimal'
                                    placeholder="Enter amount"
                                    value={amount}
                                    onChange={(e) => ValidAmount(e.target.value) && this.setState({ amount: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col className="mt-3" md="12" sm="12">
                            <Form.Label>Description (just for you):</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="Enter description"
                                value={description}
                                onChange={e => this.setState({ description: e.target.value })} />
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                {pendingRequest ? <Button variant='success' disabled><Spinner size="sm" animation='border' /></Button> :
                    <Button variant='success' disabled={toWalletTitle == undefined} onClick={this.submit}>Exchange</Button>}
            </Modal.Footer>
        </Modal >
    }
}