import React, { RefObject } from "react";
import { Offcanvas } from "react-bootstrap";
import { List } from "react-bootstrap-icons";
import ProductCatalogService, { ProductCatalogDto, ProductCategoryTitleView } from "../../../services/catalog/ProductCatalogService";
import TradeProductCatalog from "./TradeProductCatalog/TradeProductCatalog";
import ExpressProductCatalog from "./ExpressProductCatalog/ExpressProductCatalog";
import './Catalog.scss'
import { NavLink } from "react-router-dom"
import CatalogImages from './CatalogImages'
import Sell from "../../inventory/Sell/Sell";
import CategorySidebar from "../../dashboard/Sidebar/CategorySidebar/CategorySidebar";
import BuyProductCatalog from "../BuyProductCatalog/BuyProductCatalog";
import OwnedOffersBoard from "../../orders/offers/OwnedOffersBoard/OwnedOffersBoard";
import Search from "../Search/Search";
import CartService from "../../../services/shopping/CartService";
import LayoutContext from "../../../contexts/LayoutContext/LayoutContext";
import BuyOffers from "../../orders/offers/BuyOffers/BuyOffers";
import CategoryCatalog from "../CategoryCatalog/ExpressCategoryCatalog";

interface ProductCatalogState {
    title: string,
    searchText: string,
    selectedBuyProduct?: { id: string, title: string }
    sellSelectedProductId?: string,
    loading: boolean,
    categoryLoading: boolean,
    showSidebar: boolean
    categories: ProductCategoryTitleView[]
}
interface IProps {
    mode?: 'express' | 'trade',
    categoryId?: string
    productId?: string
    search?: string
    defaultSortKey: string
}

export default class Catalog extends React.Component<IProps, ProductCatalogState> {
    productCatalogService: ProductCatalogService;
    cartService: CartService;
    static contextType = LayoutContext;
    context!: React.ContextType<typeof LayoutContext>;
    ownedOffersBoard: RefObject<OwnedOffersBoard>;
    constructor(props: any) {
        super(props);
        this.ownedOffersBoard = React.createRef();
        this.cartService = new CartService();
        this.productCatalogService = new ProductCatalogService();

        this.state = {
            showSidebar: false,
            categoryLoading: false,
            title: '', searchText: '',
            loading: false,
            categories: [],
        }
    }


    addToCart = (count: number, productId: string, sellerShopId: string, unitPrice: number): Promise<any> => {
        return this.cartService.add({ count: count, productId: productId, sellerShopId: sellerShopId, unitPrice: unitPrice })
            .finally(() => this.context.loadCart())
    }

    componentDidMount() {
        this.loadCategories();

    }

    loadCategories = () => {
        var self = this;
        self.setState({ categoryLoading: true })
        
        this.productCatalogService.getProductCategories().then(function (categories) {
            self.setState({ categories });
        }).finally(() => self.setState({ categoryLoading: false }));
    }

    render(): React.ReactNode {
        return <div className="ProductCatalog">

            {this.state.sellSelectedProductId !== undefined && <Sell productId={this.state.sellSelectedProductId} onClose={() => this.setState({ sellSelectedProductId: undefined })} />}
            {(this.state.selectedBuyProduct !== undefined) && <BuyProductCatalog
                mode={this.props.mode}
                productId={this.state.selectedBuyProduct.id}
                title={this.state.selectedBuyProduct.title}
                onAddToCart={this.addToCart}
                onClose={() => {
                    this.setState({ selectedBuyProduct: undefined })
                    this.ownedOffersBoard.current.loadBoard();
                }
                } />}

            <Offcanvas show={this.state.showSidebar} onHide={() => this.setState({ showSidebar: false })} responsive="lg">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Categories</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <CategorySidebar onNavigate={() => this.setState({ showSidebar: false })} fixMargin categories={this.state.categories} catalogMode={this.props.mode} />
                </Offcanvas.Body>
            </Offcanvas>

            <div className="header">
                <ul>
                    <NavLink to="/marketplace?mode=express"> <li className={this.props.mode === "express" && "active"}>Express</li></NavLink>
                    <NavLink to="/marketplace?mode=trade">  <li className={this.props.mode === "trade" && "active"}>Trade</li></NavLink>
                </ul>

                <div className="mobile-category-toggle">
                    <List onClick={() => this.setState({ showSidebar: true })} />
                </div>
                <div className="catalog-options">
                </div>

                <div className="search-box">
                    <Search productId={this.props.productId} search={this.props.search} categoryId={this.props.categoryId} catalogMode={this.props.mode} />
                </div>
            </div>

            {
                this.bodyView()
            }

        </div >
    }

    bodyView = () => {
        return <>
            <div className="catalog-body">
                {
                    !(this.props.mode == "express" && this.props.categoryId == null) &&
                    <div className="categories">
                        <CategorySidebar categories={this.state.categories} catalogMode={this.props.mode} />
                    </div>
                }
                <div className={`products ${(this.props.mode == "express" && this.props.categoryId == null) && ' full-width'}`}>
                    {
                        this.props.mode === 'express' ?
                            <>
                                {
                                    this.props.categoryId ?
                                        <ExpressProductCatalog
                                            categoryId={this.props.categoryId} search={this.props.search} productId={this.props.productId}
                                            onClickBuy={(productId, title) => this.setState({ selectedBuyProduct: { id: productId, title } })}
                                            onClickSell={(productId) => this.setState({ sellSelectedProductId: productId })}
                                            generalProductImage={CatalogImages(this.props.categoryId)} />
                                        : <CategoryCatalog isLoading={this.state.categoryLoading} categories={this.state.categories} />
                                }
                            </>
                            : <>
                                {/* <CreateOffer categoryId={this.props.categoryId} /> */}
                                <BuyOffers onClickSell={(productId) => this.setState({ sellSelectedProductId: productId })} categoryId={this.props.categoryId} />
                                <OwnedOffersBoard categoryId={this.props.categoryId} ref={this.ownedOffersBoard} />
                                <TradeProductCatalog catalogMode={this.props.mode}
                                    defaultSortKey={this.props.defaultSortKey}
                                    categoryId={this.props.categoryId} productId={this.props.productId} search={this.props.search}
                                    onClickBuy={(productId, title) => this.setState({ selectedBuyProduct: { id: productId, title } })}
                                    onClickSell={(productId) => this.setState({ sellSelectedProductId: productId })}
                                />
                            </>
                    }
                </div>
            </div>
        </>

    }
    getBestPrice(product: ProductCatalogDto) {
        return product.sellers.length > 0 ?
            Math.min(...product.sellers.map((seller) => seller.plans.length > 0 ? seller.plans[0].price : 0)) : null;
    }
}