import React from "react"

export default class CoverFullScreen extends React.Component<{ onClick: any, isHide: boolean }, {}> {
    render(): React.ReactNode {
        return (
            <>
                <div onClick={this.props.onClick} className={'cover ' + (!this.props.isHide ? 'opened' : 'closed')}></div>
            </>
        )
    }
}