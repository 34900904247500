import React, { RefObject } from "react"
import { GridAction, GridColumn } from "../../../Grid/Grid.models"
import DynamicGrid from "../../../shared/DynamicGrid/DynamicGrid"
import supportTicketStatusDisplayBadge from "../../../helpCenter/SupportTickets/SupportTicketStatusEnum"
import AdminSupportTicketDepartementService from "../../../../services/admin/Support/AdminSupportTicketDepartementService"
import { Button } from "react-bootstrap"
import AdminSupportTicketDepartementCreate from "./Create/AdminSupportTicketDepartementCreate"

export default class AdminSupportTicketDepartement extends React.Component<{}, { departmentId?: string, showCreateModal: boolean }> {
    dynamicGrid: RefObject<DynamicGrid<{}>>
    adminSupportTicketDepartementService: AdminSupportTicketDepartementService

    constructor(props: any) {
        super(props)
        this.dynamicGrid = React.createRef()
        this.state = { showCreateModal: false }
        this.adminSupportTicketDepartementService = new AdminSupportTicketDepartementService()
    }

    departmentStatusDisplay = (status: boolean) => {
        return <span>{status ? 'Active' : 'Inactive'}</span>
    }

    requestToRemoveRow = (id: string) => {
        if (window.confirm('Are you sure? This Departement will be deleted permanently')) {
            this.adminSupportTicketDepartementService.remove(id).then(() => {
                this.dynamicGrid.current.fetch();
            })
        }
    }

    render(): React.ReactNode {
        let gridColumns: GridColumn[] = [
            { title: "Title", key: "title" },
            { title: "Status", key: "isActive" },
            { title: "Order Id", key: "orderId" },
        ]

        let gridActions: GridAction[] = [
            { caption: "Edit", variant: "outline-warning", onClick: (row) => this.setState({ showCreateModal: true, departmentId: row.id }), skipSpinner: true },
            { caption: "Delete", variant: "outline-danger", onClick: (row) => this.requestToRemoveRow(row.id), skipSpinner: true },
        ]

        return (
            <>
                <Button variant="success" className="mt-2 mb-2" onClick={() => this.setState({ showCreateModal: true })}>Add</Button>
                <DynamicGrid<{}>
                    ref={this.dynamicGrid}
                    columns={gridColumns}
                    onFetch={this.adminSupportTicketDepartementService.getPaged}
                    hideFilterBar
                    actions={gridActions}
                    defaultFilters={{}}
                    refactorRow={(row) => {
                        return { ...row, isActive: this.departmentStatusDisplay(row.isActive) }
                    }}
                />
                {this.state.showCreateModal && <AdminSupportTicketDepartementCreate id={this.state.departmentId} reload={this.dynamicGrid.current.fetch} onClose={() => this.setState({ showCreateModal: false, departmentId: undefined })} />}
            </>
        )
    }
}
