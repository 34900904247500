import { Badge } from "react-bootstrap";

export enum CashPaymentStatusEnum {
    Accepted = 0,
    Rejected = 1,
    Pending = 2,
    PaymentCreation = 3
}

export function cashPaymentStatusDisplayCaption(status: number) {
    let types = ['Accepted', 'Rejected', 'Pending', 'Initial'];
    return types.length <= status ? status : types[status];
}

export function cashPaymentStatusColor(status: number) {
    let types = ['success', 'danger', 'warning', 'info'];
    return types.length <= status ? 'dark' : types[status];
}

export default function cashPaymentStatusDisplayBadge(status: number) {
    return <Badge bg={cashPaymentStatusColor(status)}>{cashPaymentStatusDisplayCaption(status)}</Badge>;
}