import { mapToDataOnly } from "../../common/HttpUtility";
import { OrderProductApiFactory } from "../../../generated-api/admin";

export default class AdminOrderProductService {
    getStatistics(onlyShared: boolean, fromDate?: string, toDate?: string, timePeriod?: TimePeriod): Promise<any> {
        if (onlyShared)
            return mapToDataOnly<any>(OrderProductApiFactory().adminOrderProductSharePriceStatisticsGet(fromDate, toDate, timePeriod));
        else
            return mapToDataOnly<any>(OrderProductApiFactory().adminOrderProductStatisticsGet(fromDate, toDate, timePeriod));
    }

    getReport(): Promise<SalesReport> {
        return mapToDataOnly<SalesReport>(OrderProductApiFactory().adminOrderProductReportGet());
    }
}

export interface SalesReport {
    profitPrice: number
    totalPrice: number
}

export enum TimePeriod {
    Yearly = 0,
    Monthly = 1,
    Daily = 2,
    Month = 3,
    Weekly = 4
}