import { GridResult } from "../../../components/Grid/Grid.models";
import { PaginationQuery } from "../../../components/Grid/Pagination/Pagination.models";
import { mapToDataOnly } from "../../common/HttpUtility";
import { WalletTransactionStatusEnum } from "../../../components/financials/Enums/WalletTransactionStatusEnum";
import { SelectionView } from "../../common/Models";
import { WalletTransactionApiFactory, WalletApiFactory, ExchangeTransactionApiFactory } from "../../../generated-api/financials";

interface GetPagedWalletTransactionQueryString {
    walletId: string;
}

export interface WalletTransactionRowDTO {
    amount: number;
    status: WalletTransactionStatusEnum;
    description: string;
    memberUserId: string;
    memberName: string;
    createAt: string;
    isOutTransaction: boolean;
}

export interface WalletChargeStatusDTO {
    walletOwnerId: string | null;
    title: string;
    balance: number;
    blockedBalance: number;
    walletCurrencyTitle: string;
    walletCurrencyId: string;
}

export interface IWalletChargeDTO {
    walletId: string;
    amount: number;
    description: string;
}

export interface GlobalWalletBalanceDTO {
    id: string;
    key: string;
    value: number;
}

interface WalletTransferFund {
    amount: number,
    description: string,
    sourceWalletId: string,
    destinationWalletId: string,
}

interface OverviewStatistic {
    positiveAverage: number
    negativeAverage: number
    average: number
}

export interface IFiltersGetWallets {
    onlyNegativeWallet?: boolean
    ownerId?: string
    onlyGlobalWallet?: boolean
}

export interface WalletTransactionDTO {
    status?: WalletTransactionStatusEnum;
    fromWalletDescription?: string;
    toWalletDescription?: string;
    acceptorUserId?: string;
    acceptDescription?: string;
    memberUserId?: string;
    createAt: string;
    toWalletId?: string | null;
    fromWalletId?: string | null;
    toShopId?: string | null;
    fromShopId?: string | null;
    sourceAmount: number;
    destinationAmount: number;
    isRefund: boolean;
    fromShopTitle?: string;
    toShopTitle?: string;
    acceptorUser: string;
    memberName?: string
}

export default class AdminWalletsServices {
    getPaged(filters: IFiltersGetWallets, paginationQuery: PaginationQuery): Promise<any> {
        return mapToDataOnly<any>(WalletApiFactory().apiV1AdminWalletGet(filters.onlyNegativeWallet, filters.onlyGlobalWallet, filters.ownerId, paginationQuery.pageskip - 1, paginationQuery.pagesize));
    }

    getTransaction(filters: GetPagedWalletTransactionQueryString, paginationQuery: PaginationQuery): Promise<GridResult<WalletTransactionRowDTO>> {
        return mapToDataOnly<GridResult<WalletTransactionRowDTO>>(WalletTransactionApiFactory().apiV1AdminWalletTransactionGet(filters.walletId, paginationQuery.pageskip - 1, paginationQuery.pagesize));
    }

    getTransactionById(transactionId: string): Promise<WalletTransactionDTO> {
        return mapToDataOnly<WalletTransactionDTO>
            (WalletTransactionApiFactory().apiV1AdminWalletTransactionIdGet(transactionId));
    }

    getStatus(walletId: string): Promise<WalletChargeStatusDTO> {
        return mapToDataOnly<WalletChargeStatusDTO>(WalletApiFactory().apiV1AdminWalletWalletIdGet(walletId));
    }

    lockWallet(body: { description: string, isLock: boolean }, id: string): Promise<any> {
        return mapToDataOnly<any>(WalletApiFactory().apiV1AdminWalletLockWalletHandlerIdPut(id, body));
    }

    getGlobalWalletBalances(): Promise<GlobalWalletBalanceDTO[]> {
        return mapToDataOnly<GlobalWalletBalanceDTO[]>(WalletApiFactory().apiV1AdminWalletGlobalWalletBalancesGet());
    }

    getOverviewStatistic(): Promise<OverviewStatistic> {
        return mapToDataOnly<OverviewStatistic>(WalletApiFactory().apiV1AdminWalletOverviewStatisticGet());
    }

    transferFund(body: WalletTransferFund): Promise<any> {
        return mapToDataOnly<any>(WalletTransactionApiFactory().apiV1AdminWalletTransactionTransferPost(body));
    }

    getTitleValues(): Promise<SelectionView[]> {
        return mapToDataOnly<SelectionView[]>(WalletApiFactory().apiV1AdminWalletTitleValuesGet());
    }

    exchange(body: { fromWalletId: string, toWalletId: string, amount: string, description: string }): Promise<any> {
        return mapToDataOnly<any>(ExchangeTransactionApiFactory().apiV1AdminExchangeTransactionPost({ ...body, amount: Number(body.amount) }));
    }

    create(title, currencyId): Promise<string> {
        return mapToDataOnly<string>(WalletApiFactory().apiV1AdminWalletGlobalPost({ key: title, currencyId }));
    }
}