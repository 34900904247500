import React, { Children } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Navigate, NavLink } from "react-router-dom";
import './RouterTabs.scss'

export default class RouterTabs extends React.PureComponent<{ items: { title: string, route: string, isActive?: boolean }[] }, {}> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }


    render(): React.ReactNode {

        return (<div className="RouterTabs">
            {this.props.items.map((item) => <NavLink to={item.route}> <div className={`nav-link ${item.isActive && 'active'}`}>{item.title}</div></NavLink>)}
        </div>)
    }
}