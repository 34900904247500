import React, { FormEvent, RefObject } from "react"
import { Button, Col, Form, Row, Spinner, Table } from "react-bootstrap"
import IState, { IProps } from "./AdminProductManage.models"
import AdminProductService from "../../../../services/admin/products/AdminProductService"
import Uploader from "../../../shared/Uploader/Uploader"
import { LocalCurrencyService } from "../../../../services/financials/LocalCurrencyService"
import ProductCategoryService from "../../../../services/category/ProductCategoryService"
import { toast } from "react-toastify"
import ValidAmount from "../../../utilities/Amount/ValidAmount"

export default class AdminProductManage extends React.Component<IProps, IState> {
    uploader: RefObject<Uploader>
    productService: AdminProductService
    localCurrencyService: LocalCurrencyService
    productCategoryService: ProductCategoryService

    constructor(props: any) {
        super(props)
        this.state = {
            loading: false, pendingRequest: false,
            product: { title: '', shortTitle: '', price: '', isPrivate: false, category: '', currency: '', isActive: true, structures: [] },
            categoryTitleValues: [], currencyTitleValues: [], productGridData: []
        }

        this.productService = new AdminProductService()
        this.localCurrencyService = new LocalCurrencyService()
        this.productCategoryService = new ProductCategoryService()
        this.uploader = React.createRef()
    }

    componentDidMount(): void {
        this.getCurrency()
        this.getCategory()
    }

    getCurrency = () => {
        this.productService.getCurrency().then((response) => this.setState({ currencyTitleValues: response }))
    }

    getCategory = () => {
        this.productCategoryService.getChildOnlyTitleValues().then(response => this.setState({ categoryTitleValues: response, product: { ...this.state.product, category: response[0].value } }))
            .finally(() => {
                if (this.props.productId !== null) this.getDetails()
            })
    }

    getDetails = () => {
        this.setState({ loading: true })

        this.productService.getById(this.props.productId).then(response => {
            this.setState({ details: response })

            if (response) {
                this.setState({
                    product: {
                        ...this.state.product,
                        title: response.title,
                        shortTitle: response.shortTitle,
                        price: String(response.realPrice),
                        category: response.categoryId ? response.categoryId : this.state.categoryTitleValues[0].value,
                        currency: response.currencyId ? response.currencyId : this.state.currencyTitleValues[0].value,
                        isActive: response.isActive,
                        isPrivate: response.isPrivate,
                        structures: response.structures,
                        imageName: response.imageName !== undefined && response.imageName,
                        imageSrc: response.imageSrc !== undefined && response.imageSrc,
                    },
                    productGridData: response.structures
                });
            }
        }).finally(() => this.setState({ loading: false }))
    }

    submit = (e: FormEvent<HTMLFormElement>) => {
        let { productId, state } = this.props;
        let { product } = this.state;

        e.preventDefault();
        e.stopPropagation();

        this.setState({ pendingRequest: true })

        let body = {
            title: product.title,
            shortTitle: product.shortTitle,
            realPrice: Number(product.price),
            categoryId: product.category,
            currencyId: product.currency,
            isActive: product.isActive,
            isPrivate: product.isPrivate,
            imageName: product.imageName,
            structures: this.state.productGridData
        }

        if (state == 'edit') this.productService.update(productId, body).then(() => toast.success("Product has been updated successfully")).finally(() => this.setState({ pendingRequest: false }))
        else this.productService.add(body).then(() => toast.success("Your product created successfully")).finally(() => this.setState({ pendingRequest: false }))
    }

    addRow = () => {
        let temp = this.state.productGridData;
        temp.push({ key: '', title: '', isUniqueValue: false });
        this.setState({ productGridData: temp })
        this.forceUpdate();
    }

    handleKeyValue = (value, index) => {
        let temp = this.state.productGridData
        temp[index].key = value

        this.setState({ productGridData: temp })
        this.forceUpdate();
    }

    handleTitleValue = (value, index) => {
        let temp = this.state.productGridData
        temp[index].title = value

        this.setState({ productGridData: temp })
        this.forceUpdate();
    }

    handleIsUniqueValue = (value, index) => {
        let temp = this.state.productGridData
        temp[index].isUniqueValue = !value

        this.setState({ productGridData: temp })
        this.forceUpdate();
    }

    gridView() {
        return (
            <>
                <Table hover responsive size="lg">
                    <thead>
                        <tr>
                            <th>Key</th>
                            <th>Title</th>
                            <th>Is Unique Value</th>
                            <th>
                                <Button variant="success" onClick={() => this.addRow()}>Add</Button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            !this.state.productGridData ? (<tr><td colSpan={5} align="center"><Spinner animation="grow" /></td></tr>) :
                                this.state.productGridData && this.state.productGridData.map((product, index) =>
                                    <tr key={index}>
                                        <td>
                                            <Form.Control
                                                type="text"
                                                value={product.key}
                                                onChange={(e) => this.handleKeyValue(e.target.value, index)}
                                            />
                                        </td>
                                        <td>
                                            <Form.Control
                                                type="text"
                                                value={product.title}
                                                onChange={(e) => this.handleTitleValue(e.target.value, index)}
                                            />
                                        </td>
                                        <td>
                                            <Form.Check type="checkbox" checked={product.isUniqueValue}
                                                onChange={(e) => this.handleIsUniqueValue(product.isUniqueValue, index)}
                                            />
                                        </td>
                                    </tr>)}
                    </tbody>
                </Table>
            </>
        )
    }

    render(): React.ReactNode {
        let { loading, product, categoryTitleValues, currencyTitleValues, pendingRequest } = this.state

        return <div>
            {
                !loading ?
                    <>
                        <Form className="mt-5" onSubmit={this.submit}>
                            <Row>
                                <Col md="4" sm="12">
                                    <h3>Product</h3>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col md="3" sm="12">
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Title:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={product.title}
                                            onChange={(e) => this.setState({ product: { ...product, title: e.target.value } })}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md="3" sm="12">
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                        <Form.Label>Short Title:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={product.shortTitle}
                                            onChange={(e) => this.setState({ product: { ...product, shortTitle: e.target.value } })}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md="3" sm="12">
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                                        <Form.Label>Real Price:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            inputMode='decimal'
                                            value={product.price}
                                            onChange={(e) => ValidAmount(e.target.value) && this.setState({ product: { ...product, price: e.target.value } })}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col md="3" sm="12">
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                                        <Form.Label>Photo:</Form.Label>
                                        <Uploader ref={this.uploader} folderName='products' onUpload={(path) => this.setState({ product: { ...product, imageName: path } })} imageAddress={product.imageSrc} />
                                    </Form.Group>
                                </Col>
                                <Col md="3" sm="12">
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                                        <Form.Label>Category:</Form.Label>
                                        <Form.Select aria-label="Select source currency" id="currencyId"
                                            value={product.category}
                                            onChange={e => this.setState({ product: { ...product, category: e.target.value } })}>
                                            {categoryTitleValues.map((item) => <option value={item.value} key={item.value}>{item.title}</option>)}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col md="3" sm="12">
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput6">
                                        <Form.Label>Currency:</Form.Label>
                                        <Form.Select aria-label="Select source currency" id="currencyId"
                                            value={product.currency}
                                            onChange={e => this.setState({ product: { ...product, currency: e.target.value } })}>
                                            {currencyTitleValues.map((item) => <option value={item.value} key={item.value}>{item.title}</option>)}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col md="1" sm="12">
                                    <Form.Group className="mt-4" controlId="exampleForm.ControlInput7">
                                        <Form.Check
                                            type='checkbox'
                                            label='Active'
                                            checked={product.isActive}
                                            onChange={(e) => this.setState({ product: { ...product, isActive: !product.isActive } })}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md="1" sm="12">
                                    <Form.Group className="mt-4" controlId="exampleForm.ControlInput7">
                                        <Form.Check
                                            type='checkbox'
                                            label='Private'
                                            checked={product.isPrivate}
                                            onChange={(e) => this.setState({ product: { ...product, isPrivate: !product.isPrivate } })}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            {this.gridView()}
                            {pendingRequest ? <Button variant='success' disabled><Spinner size="sm" animation='border' /></Button> :
                                <Button variant="success" type="submit">Save</Button>}
                        </Form>
                    </>
                    : <Spinner animation="grow" />
            }
        </div>
    }
}
