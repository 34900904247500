import React, { RefObject } from "react"
import { Button, Table } from "react-bootstrap"
import { NavLink } from "react-router-dom"
import { CaretDownFill, CaretUpFill } from "react-bootstrap-icons"
import DynamicGrid from "../../shared/DynamicGrid/DynamicGrid"
import AdminProductCategoriesService from "../../../services/admin/categories/AdminCategoriesService"
import IState, { ICategory } from "./AdminProductCategory.models"
import { toast } from "react-toastify"

export default class AdminProductCategory extends React.Component<{}, IState> {
    dynamicGrid: RefObject<DynamicGrid<{}>>
    adminProductCategoriesService: AdminProductCategoriesService

    constructor(props: any) {
        super(props)
        this.dynamicGrid = React.createRef()
        this.state = { categories: [], loading: false }
        this.adminProductCategoriesService = new AdminProductCategoriesService()
    }

    componentDidMount() {
        this.getColumns()
    }

    getColumns = () => {
        this.setState({ loading: true })
        this.adminProductCategoriesService.getPaged().then((response) => {
            let data: ICategory[] = response.rows.filter(category => category.parentId == null).map(category => {
                return {
                    ...category,
                    isShow: false,
                    childs: response.rows.filter(child => child.parentId == category.id).map(child => {
                        return {
                            ...child,
                            isShow: false,
                            childs: response.rows.filter(item => item.parentId == child.id).map(item => {
                                return {
                                    ...item,
                                    isShow: false,
                                    childs: []
                                }
                            })
                        }
                    })
                }
            })
            this.setState({ categories: data, loading: false })
        })
    }

    showChild = (index: number, childIndex?: number) => {
        let temp = this.state.categories;

        if (childIndex != undefined)
            temp[index].childs[childIndex].isShow = !temp[index].childs[childIndex].isShow
        else
            temp[index].isShow = !temp[index].isShow

        this.setState({ categories: temp })
        this.forceUpdate()
    }

    departmentStatusDisplay = (status: boolean) => {
        return <span>{status ? 'Active' : 'Inactive'}</span>
    }

    requestToRemoveRow = (id: string) => {
        if (window.confirm('Are you sure? This category will be deleted permanently')) {
            this.adminProductCategoriesService.delete(id).then(() => {
                toast.success("The product category deleted successfully")
                this.dynamicGrid.current.fetch();
            })
        }
    }

    render(): React.ReactNode {
        return (
            <>
                <NavLink to={'/admin/category-manage'}><Button variant="success" className="mt-2 mb-2">Add</Button></NavLink>
                <Table style={{ textAlign: "center", verticalAlign: "middle" }} size="lg" responsive>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Title</th>
                            <th>Is Active</th>
                            <th>Parent Title</th>
                            <th>Order</th>
                            <th>Childs</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.categories.map((category, index) => {
                            return (
                                <>
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{category.title}</td>
                                        <td>{this.departmentStatusDisplay(category.isActive)}</td>
                                        <td>{category.parentTitle}</td>
                                        <td>{category.orderId}</td>
                                        <td>{category.childs.length}</td>
                                        <td className="text-center" style={{ verticalAlign: 'middle' }}>
                                            <NavLink to={`/admin/category-manage?categoryId=${category.id}`}><Button className="mr-1" size="sm" variant="outline-warning">Edit</Button></NavLink>
                                            <Button className="mr-1" size="sm" variant="outline-danger" onClick={() => this.requestToRemoveRow(category.id)}>Delete</Button>
                                            {category.childs.length > 0 && (category.isShow ? <CaretUpFill onClick={() => this.showChild(index)} /> : <CaretDownFill onClick={() => this.showChild(index)} />)}
                                        </td>
                                    </tr>
                                    {category.childs.map((child, childIndex) => {
                                        if (category.isShow)
                                            return (
                                                <>
                                                    <tr>
                                                        <td>{ }</td>
                                                        <td>{child.title}</td>
                                                        <td>{this.departmentStatusDisplay(child.isActive)}</td>
                                                        <td>{child.parentTitle}</td>
                                                        <td>{child.orderId}</td>
                                                        <td>{child.childs.length}</td>
                                                        <td className="text-center" style={{ verticalAlign: 'middle' }}>
                                                            <NavLink to={`/admin/category-manage?categoryId=${child.id}`}><Button className="mr-1" size="sm" variant="outline-warning">Edit</Button></NavLink>
                                                            <Button className="mr-1" size="sm" variant="outline-danger" onClick={() => this.requestToRemoveRow(child.id)}>Delete</Button>
                                                            {child.childs.length > 0 && (child.isShow ? <CaretUpFill onClick={() => this.showChild(index, childIndex)} /> : <CaretDownFill onClick={() => this.showChild(index, childIndex)} />)}
                                                        </td>
                                                    </tr>
                                                    {child.childs.map(sub_child => {
                                                        if (child.isShow)
                                                            return (
                                                                <tr>
                                                                    <td>{ }</td>
                                                                    <td>{sub_child.title}</td>
                                                                    <td>{this.departmentStatusDisplay(sub_child.isActive)}</td>
                                                                    <td>{sub_child.parentTitle}</td>
                                                                    <td>{sub_child.orderId}</td>
                                                                    <td>{sub_child.childs.length}</td>
                                                                    <td className="text-center" style={{ verticalAlign: 'middle' }}>
                                                                        <NavLink to={`/admin/category-manage?categoryId=${sub_child.id}`}><Button className="mr-1" size="sm" variant="outline-warning">Edit</Button></NavLink>
                                                                        <Button className="mr-1" size="sm" variant="outline-danger" onClick={() => this.requestToRemoveRow(sub_child.id)}>Delete</Button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                    })}
                                                </>
                                            )
                                    })}
                                </>
                            )
                        })}
                    </tbody>
                </Table>
            </>
        )
    }
}