import { Badge } from 'react-bootstrap'

export enum WalletTransactionStatusEnum {
    Pending,
    Done
}

export function walletTransactionStatusDisplayCaption(status: number) {
    let types = ['Pending', 'Done']
    return types.length <= status ? status : types[status]
}

export function walletTransactionStatusColor(status: number) {
    let types = ['warning', 'success']
    return types.length <= status ? 'dark' : types[status]
}

export default function walletTransactionStatusDisplayBadge(status: number) {
    return <Badge bg={walletTransactionStatusColor(status)}>{walletTransactionStatusDisplayCaption(status)}</Badge>
}