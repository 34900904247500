import { mapToDataOnly } from "../common/HttpUtility"
import { InventoryStatisticsApiFactory } from "../../generated-api";

export default class InventoryStatisticsService {
    getFinancial(): Promise<InventoryFinancialStatisticsDTO> {
        return mapToDataOnly<InventoryFinancialStatisticsDTO>(InventoryStatisticsApiFactory().inventoryStatisticsGet());
    }
}

export interface InventoryFinancialStatisticsDTO {
    sourceTotalBalance: number;
    totalDeficits: number;
    availableAmount: number;
    totalSold: number;
    totalProfit: number;
    totalShared: number;
}

export interface IPeriodDatePickerQueryString {
    fromDate?: string;
    toDate?: string;
}