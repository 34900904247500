import React from "react";
import { Form, InputGroup } from "react-bootstrap";

export default class InputGroupView extends React.PureComponent<{ maxHeight?: string, mark?: string, renderMarkView?: any, textColor?: string, onClick?: () => void }> {
    render(): React.ReactNode {
        return <>
            <Form.Group>
                <InputGroup onClick={this.props.onClick}>
                    {this.props.children}
                    <InputGroup.Text id="basic-addon1" className={`text-${this.props.textColor ?? 'success'}`}>
                        {this.props.renderMarkView ? this.props.renderMarkView : this.props.mark ?? '$'}
                    </InputGroup.Text>
                </InputGroup>
            </Form.Group>
        </>
    }
}