import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import AdminNotificationPermissions from "./notifications/AdminNotificationPermissions";

export default class AdminSetting extends React.Component<{ activeTab?: string | null }, { navigateToTab?: string }> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    componentDidUpdate(prevProps: Readonly<{ activeTab: string | null; }>, prevState: Readonly<{ navigateToTab?: string; }>): void {
        if (prevProps.activeTab !== this.props.activeTab)
            this.setState({ navigateToTab: undefined })
    }

    handleSelect = (key) => this.setState({ navigateToTab: key });

    render(): React.ReactNode {
        return <>
            {this.state.navigateToTab && this.state.navigateToTab !== this.props.activeTab ? <Navigate to={"/admin/setting/?activeTab=" + this.state.navigateToTab} /> : null}
            <Tabs activeKey={this.props.activeTab ?? "notifications"} onSelect={this.handleSelect} id="setting-tabs" className="mb-3" mountOnEnter={true} unmountOnExit={true}>
                <Tab eventKey="notifications" title="Notifications">
                    <AdminNotificationPermissions />
                </Tab>
            </Tabs>
        </>
    }
}
