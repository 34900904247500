import React from 'react';
import { Button, Modal, Form, Spinner, Row, Col, Alert } from 'react-bootstrap'
import { toast } from 'react-toastify'
import ExchangeService from '../../../../../services/financials/ExchangeService';
import WalletService from '../../../../../services/financials/WalletService';
import IState, { IProps } from './CreateExchangeRequestModal.models'
import ValidAmount from '../../../../utilities/Amount/ValidAmount';
import Amount, { amount } from "../../../../utilities/Amount/Amount"

export default class CreateExchangeRequestModal extends React.Component<IProps, IState> {
    exchangeService: ExchangeService
    walletService: WalletService
    constructor(props: IProps) {
        super(props)
        this.state = { pendingRequest: false, amount: '0', wallets: [] }
        this.exchangeService = new ExchangeService()
        this.walletService = new WalletService()
    }

    componentDidMount() {
        this.getWalletList()
    }

    getWalletList = () => {
        this.walletService.getWalletList({}, { pagesize: 100, pageskip: 1 }).then(response => {
            var wallets = response.rows.map(row => {
                return { title: row.currencyTitle, walletId: row.id, balance: row.balance, blockedBalance: row.hold }
            });

            this.setState({
                wallets: wallets.filter(wallet => wallet.walletId != this.props.fromWalletId), amount: '0',
                fromWallet: wallets.filter(wallet => wallet.walletId == this.props.fromWalletId)[0],
                toWallet: wallets.filter(wallet => wallet.walletId != this.props.fromWalletId)[0]
            })
        })
    }

    submit = () => {
        if (Number(this.state.amount) <= 0) return toast.error('Invalid your amount.')
        if (this.state.fromWallet.walletId === this.state.toWallet.walletId) return toast.error('The source and the destination cannot be equal.')

        let body = {
            amount: Number(this.state.amount),
            toWalletId: this.state.toWallet.walletId,
            fromWalletId: this.state.fromWallet.walletId
        }

        this.setState({ pendingRequest: true })
        this.exchangeService.create(body).then(() => {
            toast.success('Your request created successfully');
            this.props.onSubmit()
            this.props.closeModal()
        }).finally(() => {
            this.setState({ pendingRequest: false })
        })
    }

    render(): React.ReactNode {
        const { pendingRequest } = this.state
        const { closeModal } = this.props
        const availableBalance = this.state.fromWallet?.balance - this.state.fromWallet?.blockedBalance;
        if (!this.state.fromWallet || !this.state.toWallet)
            return <></>;

        return (
            <Modal size="lg"
                show={true}
                onHide={closeModal}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Exchange From <b>{this.state.fromWallet.title}</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Row>
                            <Col md="12" sm="12">
                                Your {this.state.fromWallet.title} Balance:  {amount(this.state.fromWallet.balance)} <br />
                                Available Balance: <b> {amount(availableBalance)}</b>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md="6" sm="12">
                                <Form.Group controlId="toWalletId">
                                    <Form.Label>Exchange To:</Form.Label>
                                    <Form.Select aria-label=">Select to Wallet" id="toWalletId" value={this.state.toWallet.walletId}
                                        onChange={(e) => this.setState({ toWallet: this.state.wallets.filter(wallet => wallet.walletId == e.target.value)[0] })}>
                                        {
                                            this.state.wallets.map((wallet) => <option value={wallet.walletId} key={wallet.walletId}>{wallet.title} </option>)
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col md="6" sm="12">
                                <Form.Label>Amount</Form.Label>
                                <Form.Control type="text" inputMode='decimal' aria-label="Enter amount"
                                    value={this.state.amount}
                                    onChange={(e) => ValidAmount(e.target.value) && this.setState({ amount: e.target.value })} />
                            </Col>
                        </Row>
                        <br />
                        {
                            Number(this.state.amount) > (availableBalance) &&
                            <Alert variant='danger'>
                                Your enter amount more than source wallet available balance ({amount(availableBalance)})
                            </Alert>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>

                    {pendingRequest ? <Button variant='success' disabled><Spinner animation='border' /></Button> :
                        <Button variant='success' disabled={Number(this.state.amount) > this.state.fromWallet.balance} onClick={this.submit}>Exchange</Button>}
                </Modal.Footer>
            </Modal>
        )
    }
}