import { forwardRef, PureComponent, ReactNode } from "react"
import { Col, Form, Row } from "react-bootstrap"
import { Calendar } from "react-bootstrap-icons"
import DatePicker from "react-datepicker"
import InputGroupView from "../../inventory/InventoryPlans/InputGroupView/InputGroupView"

export interface IProps {
    fromDate?: Date,
    toDate?: Date,
    onChangeFromDate: (date: Date) => void
    onChangeToDate: (date: Date) => void
}

const CustomInput = forwardRef((props: any, ref: any) => {
    return (
        <InputGroupView textColor="muted" renderMarkView={<Calendar />} onClick={props.onClick}>
            <Form.Control aria-describedby="s" {...props} ref={ref} />
        </InputGroupView>
    )
});

export default class PeriodDatePicker extends PureComponent<IProps, {}> {
    utcDate(date: Date) {
        return new Date(Date.UTC(date.getFullYear(), date.getMonth(),
            date.getDate(), 0,
            0, 0))
    }
    render(): ReactNode {
        return <>
            <Row>
                <Col md="6" sm="12">
                    <label>From Date</label>
                    <DatePicker selected={this.props.fromDate}
                        customInput={<CustomInput />}
                        onChange={(date: Date) => this.props.onChangeFromDate(this.utcDate(date))}
                        className="form-control"
                    />
                </Col>
                <Col md="6" sm="12">
                    <label>To Date</label>
                    <DatePicker selected={this.props.toDate}
                        customInput={<CustomInput />}
                        onChange={(date: Date) => this.props.onChangeToDate(this.utcDate(date))}
                        className="form-control"
                    />
                </Col>
            </Row>
        </>
    }
}