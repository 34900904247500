import { useSearchParams } from 'react-router-dom'
import AdminProductManage from '../../../components/admin/products/Manage/AdminProductManage'
import AdminAuthenticated from '../../../components/shared/AdminAuthenticated/AdminAuthenticated'
import { ADMIN, SUPERADMIN } from '../../../constants/ConstantsPermissions'

export default function AdminProductsManageRoute() {
  const [searchParams] = useSearchParams()
  let productId = searchParams.get('productId')
  let state = searchParams.get('state')

  return (
    <AdminAuthenticated roles={[SUPERADMIN, ADMIN]}>
      <AdminProductManage productId={productId as string} state={state} />
    </AdminAuthenticated>
  )
}
