import { GridResult } from "../../components/Grid/Grid.models";
import { ISourceTransactionFilters } from "../../components/inventory/InventorySourceTransaction/InventorySourceTransactionList/InventorySourceTransactionList.models";
import { mapToDataOnly } from "../common/HttpUtility";
import { SelectionView } from "../common/Models";
import { InventorySourceTransactionApiFactory } from "../../generated-api";
import { ToLocaleDate } from "../../components/utilities/HybridMoment";

export default class InventorySourceTransactionService {
    getPaged(queryString: ISourceTransactionFilters): Promise<GridResult<ISourceTransactionDTO>> {
        return mapToDataOnly<GridResult<ISourceTransactionDTO>>(
            InventorySourceTransactionApiFactory().sellerInventorySourceTransactionGet(queryString.inventorySourceId, queryString.currencyId, queryString.pageskip - 1, queryString.pagesize))
    }

    getById(id: string): Promise<IUpdateSourceTransactionDTO> {
        return mapToDataOnly<IUpdateSourceTransactionDTO>(InventorySourceTransactionApiFactory().sellerInventorySourceTransactionInventorySourceTransactionIdGet(id));
    }

    getTitleValue(id: string): Promise<SelectionView[]> {
        return mapToDataOnly<SelectionView[]>(InventorySourceTransactionApiFactory().sellerInventorySourceTransactionCurrenciesTitleValueInventorySourceIdGet(id));
    }

    create(dto: ICreateSourceTransactionDTO): Promise<any> {
        return mapToDataOnly(InventorySourceTransactionApiFactory().sellerInventorySourceTransactionPost({ ...dto, transactionAt: ToLocaleDate(dto.transactionAt) }));
    }

    update(dto: IUpdateSourceTransactionDTO): Promise<any> {
        return mapToDataOnly(InventorySourceTransactionApiFactory()
            .sellerInventorySourceTransactionPut({ ...dto, transactionAt: ToLocaleDate(dto.transactionAt) }));
    }

    delete(id: string): Promise<any> {
        return mapToDataOnly(InventorySourceTransactionApiFactory().sellerInventorySourceTransactionInventorySourceTransactionIdDelete(id));
    }
}

export interface ISourceTransactionDTO {
    id: string;
    amount: number;
    transactionAt: string;
    isToSource: boolean;
    description: string;
}

export interface IUpdateSourceTransactionDTO {
    inventorySourceTransactionId: string;
    sourceAmountValue: number;
    shopAmountValue: number;
    description: string;
    transactionAt: Date;
}

export interface ICreateSourceTransactionDTO {
    shopCurrencyId: string;
    sourceCurrencyId: string;
    inventorySourceId: string;
    sourceAmountValue: number;
    shopAmountValue: number;
    description: string;
    transactionAt: Date;
    isToSource: boolean;
}