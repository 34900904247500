import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import { CashOutStatusEnum } from "../../financials/Enums/CashOutStatusEnum";
import AdminDeposits from "./AdminDeposits/AdminDeposits";
import AdminWithdrawals from "./AdminWithdrawals/AdminWithdrawals";
import AdminWalletOverview from "./AdminOverview/AdminWalletOverview";
import AdminWalletsList from "./AdminWallets/AdminWalletsList";
import { CashPaymentStatusEnum } from "../../financials/Enums/CashPaymentStatusEnum";
import Cashier from "./Cashier/Cashier";
import AdminCredits from "./AdminCredits/AdminCredits";
import AdminCurrencies from "./AdminCurrencies/AdminCurrencies";

export default class AdminCashier extends React.Component<{ activeTab: string | null, onlynegative?: boolean | null, ownerId?: string | null }, { navigateToTab?: string, onlyNegativeWallet?: boolean }> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }
    componentDidUpdate(prevProps: Readonly<{ activeTab: string | null; walletId?: string; }>, prevState: Readonly<{ navigateToTab?: string; }>, snapshot?: any): void {
        if (prevProps.activeTab !== this.props.activeTab)
            this.setState({ navigateToTab: undefined })
    }
    handleSelect = (key) => {
        this.setState({ navigateToTab: key });
    }
    render(): React.ReactNode {

        return <>
            {this.state.navigateToTab && this.state.navigateToTab !== this.props.activeTab ? <Navigate to={"/admin/financials/?activeTab=" + this.state.navigateToTab} /> : null}
            <Tabs activeKey={this.props.activeTab ?? "overview"} onSelect={this.handleSelect} id="financials-tabs" className="mb-3" mountOnEnter={true} unmountOnExit={true}>
                <Tab eventKey="overview" title="Overview">
                    <AdminWalletOverview onClickedWalletsStatistics={() => {
                        this.setState({ onlyNegativeWallet: true })
                        this.handleSelect("wallets")
                    }} />
                </Tab>
                <Tab eventKey="withdrawals" title="Withdrawals">
                    <Tabs id="withdrawals-tabs" className="mb-3" mountOnEnter={true} unmountOnExit={true}>
                        <Tab eventKey="withdrawals-done" title="Done">
                            <AdminWithdrawals status={CashOutStatusEnum.Done} />
                        </Tab>
                        <Tab eventKey="withdrawals-accepted" title="Accepted">
                            <AdminWithdrawals status={CashOutStatusEnum.Accepted} />
                        </Tab>
                        <Tab eventKey="withdrawals-pending" title="Pending">
                            <AdminWithdrawals status={CashOutStatusEnum.Pending} />
                        </Tab>
                        <Tab eventKey="withdrawals-all" title="All">
                            <AdminWithdrawals />
                        </Tab>
                        <Tab eventKey="withdrawals-unreviewed" title="Unreviewed">
                            <AdminWithdrawals status={CashOutStatusEnum.Done} unreviewedOnly />
                        </Tab>
                    </Tabs>
                </Tab>

                <Tab eventKey="deposits" title="Deposits">
                    <Tabs id="deposits-tabs" className="mb-3" mountOnEnter={true} unmountOnExit={true}>
                        <Tab eventKey="deposits-accepted" title="Accepted">
                            <AdminDeposits status={CashPaymentStatusEnum.Accepted} />
                        </Tab>
                        <Tab eventKey="deposits-pending" title="Pending">
                            <AdminDeposits status={CashPaymentStatusEnum.Pending} />
                        </Tab>
                        <Tab eventKey="deposits-rejected" title="Rejected">
                            <AdminDeposits status={CashPaymentStatusEnum.Rejected} />
                        </Tab>
                        <Tab eventKey="deposits-all" title="All">
                            <AdminDeposits />
                        </Tab>
                        <Tab eventKey="deposits-unreviewed" title="Unreviewed">
                            <AdminDeposits unreviewedOnly />
                        </Tab>
                    </Tabs>
                </Tab>

                <Tab eventKey="wallets" title="Wallets">
                    <AdminWalletsList onlyNegativeWallet={this.state.onlyNegativeWallet} ownerId={this.props.ownerId} />
                </Tab>

                <Tab eventKey="cashier" title="Cashier">
                    <Cashier />
                </Tab>

                <Tab eventKey="credit" title="Credits / Holds">
                    <Tabs id="credit-tabs" className="mb-3" mountOnEnter={true} unmountOnExit={true}>
                        <Tab eventKey="credits-positive" title="Credits">
                            <AdminCredits isNegativeCredit={false}  />
                        </Tab>
                        <Tab eventKey="credits-negative" title="Holdes / Negative credits">
                            <AdminCredits isNegativeCredit />
                        </Tab>
                    </Tabs>
                </Tab>

                <Tab eventKey="currencies" title="Currencies">
                    <AdminCurrencies />
                </Tab>
            </Tabs>
        </>
    }
}
