import React from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import IdentityService from "../../../services/identity/IdentityService";
import { ClipboardPlus } from "react-bootstrap-icons";
import { toast } from "react-toastify";

export default class TelegramBot extends React.PureComponent<{}, {token: string, pendingRequest: boolean}> {
    identityService: IdentityService 
    constructor(props: any) {
        super(props);
        this.state = { token: '', pendingRequest: false };
        this.identityService = new IdentityService();
    }

    copyTextToClipboard = async (text: string) => {
        if ("clipboard" in navigator) {
            toast.info("Copied.", { style: { width: "200px" } })
            return await navigator.clipboard.writeText(text)
        }
    }

    generateToken = () => {
        this.setState({pendingRequest: true});
        this.identityService.generateTelegramBotToken().then(res => this.setState({token: res, pendingRequest: false}))
    }

    render(): React.ReactNode {
        return (
            <div className="p-3 mt-3">
                <h3>Never miss any notification in your shop!</h3>
                <Row>
                    <Col md="12" sm="12">
                        <div className="sub-title">                         
                            You can easily keep informed about your shop notifications using our official Telegram bot.
                            To setup API Gift Telegram bot for notifications, please click Generate button then copy the generated token and send it to out bot at Telegram.
                        </div>
                        <div className="sub-title">
                                Contact <a className="font-bold" href="https://t.me/apigiftbot">@apigiftbot</a>
                        </div>
                    </Col>
                    {this.state.token && <Col md="12" sm="12" className="mt-4 box-gray">
                            <label className="pe-2">Your token is:</label>
                            <label style={{fontSize: 18}} className="font-bold text-secondary">{this.state.token}
                                <ClipboardPlus className="pointer mb-1 ml-1" onClick={() => this.copyTextToClipboard(this.state.token)} />
                            </label>
                    </Col>}
                    {this.state.token == '' && <Col md="12" sm="12" className="mt-3">
                        {this.state.pendingRequest ? <Button variant='success' disabled><Spinner size="sm" animation='border' /></Button> :
                        <Button variant='success' onClick={() => this.generateToken()}>Generate</Button>}
                    </Col>}
                </Row>
            </div>
        )
    }
}