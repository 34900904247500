import { useSearchParams } from 'react-router-dom'
import AdminUsersList from '../../../components/admin/users/AdminUsersList'
import AdminAuthenticated from '../../../components/shared/AdminAuthenticated/AdminAuthenticated'
import { ADMIN } from '../../../constants/ConstantsPermissions'

export default function AdminUsersRoute() {
    const [searchParams] = useSearchParams()
    let userId = searchParams.get("id")

    return <AdminAuthenticated roles={[ADMIN]}><AdminUsersList id={userId} />  </AdminAuthenticated>
}