import React from "react"
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import { Autoplay, Pagination } from "swiper"
import './Silder.scss'
import SectionTitle from "../../../shared/SectionView/SectionTitle/SectionTitle"

interface FAQView {
    question: string,
    answer: string,
}
export default class SliderComponent extends React.PureComponent<{ title?: string, data: FAQView[] }, {}> {
    render(): React.ReactNode {
        return <div className="FAQ-Slider">
            <div className="font-bold fs-3 pb-3 text-black">{this.props.title}</div>
            <Swiper
                direction={"vertical"}
                pagination={{
                    clickable: true,
                }}
                autoplay={{
                    delay: 15000,
                }}
                rewind={true}
                modules={[Autoplay, Pagination]}

                className="mySwiper"
            >
                {
                    this.props.data.map((item, index) => {
                        return <SwiperSlide key={index}>
                            <div>
                                <SectionTitle title={item.question} />
                                <div className="line-normal p-large text-justify fs-md">{item.answer}</div>
                            </div>
                        </SwiperSlide>
                    })
                }
            </Swiper>
        </div>
    }
}