import React from "react";
import ProductCatalog from "../../catalog/Catalog/Catalog";
import './Marketplace.scss'
import AuthenticationContext from "../../../contexts/AuthenticationContext/AuthenticationContext";
import ConfigurationService from "../../../services/api/ConfigurationService";
import { Spinner } from "react-bootstrap";

export default class Marketplace extends React.Component<{ categoryId?: string, productId?: string, search?: string, mode?: 'express' | 'trade' }> {
    static contextType = AuthenticationContext;
    context!: React.ContextType<typeof AuthenticationContext>;
    constructor(props: any) {
        super(props);
        this.state = {};

    }
    hasPolicy = (policy: string) => this.context.policies?.includes(policy)
    render(): React.ReactNode {
        return <div className="Shopping">
            <ProductCatalog categoryId={this.props.categoryId}
                mode={this.props.mode}
                defaultSortKey={this.hasPolicy('SELLER') ? 'availableInventory' : 'realPrice'}
                productId={this.props.productId}
                search={this.props.search} />
        </div>
    }
}