import React, { RefObject } from "react"
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap"
import Amount from "../../../../utilities/Amount/Amount"
import Moment from 'moment';
import cashPaymentStatusDisplayBadge, { CashPaymentStatusEnum } from "../../../../financials/Enums/CashPaymentStatusEnum"
import AdminDepositsService, { HandleCashPaymentDTO } from "../../../../../services/admin/financials/AdminDepositsService";
import { toast } from "react-toastify";
import { Router } from "react-router-dom";

interface IProps {
    details?: {
        id: string
        amount: number
        handleAt: string | null
        requestAt: string
        status: CashPaymentStatusEnum
        shopTitle: string
        shopId: string
        description: string,
        transactionNumber: string,
        financialAccountType: string,
        financialAccount: string,
        handlerUserId: string,
        handlerName: string,
    }
    onClose: () => void;
}

export default class AdminDepositDetails extends React.PureComponent<IProps, {
    pendingRequest: boolean, withdrawDetails?: string, description?: string
}> {
    adminDepositsService: AdminDepositsService

    constructor(props: any) {
        super(props)
        this.adminDepositsService = new AdminDepositsService()
        this.state = { description: "", pendingRequest: false }
    }

    handle = (status: boolean) => {
        this.setState({ pendingRequest: true })

        if (status) {
            this.adminDepositsService.accept({ id: this.props.details.id }).then(() => {
                toast.success("Payment accepted successfully")
                this.props.onClose()
                this.close()
            })
                .catch((error) => {
                    if (error.response.status == 400) {
                        toast.error("This payment not verfied by provider")
                    }
                })
                .finally(() => this.setState({ pendingRequest: false }))
        } else {
            this.adminDepositsService.reject(this.props.details.id).then(() => {
                toast.success("Payment rejected successfully")
                this.props.onClose()
                this.close()
            }).finally(() => this.setState({ pendingRequest: false }))
        }
    }

    close = () => {
        this.props.onClose();
        this.setState({ withdrawDetails: undefined })
    }

    footerView = (status: CashPaymentStatusEnum) => {
        switch (status) {
            case CashPaymentStatusEnum.Accepted: case CashPaymentStatusEnum.Rejected:
                return <Button onClick={this.close} variant="light">Close</Button>
            case CashPaymentStatusEnum.Pending:
                return <>
                    <Row>
                        <Col md="12" sm="12" className="mt-2">
                            {
                                this.state.pendingRequest ? <Button variant="dark" disabled><Spinner animation="border" size="sm" /></Button> :
                                    <div style={{ textAlign: 'right' }}>
                                        <Button className="me-2" onClick={() => this.handle(true)} variant="success">Accept</Button>
                                        <Button onClick={() => this.handle(false)} variant="danger" className="mr-2">Reject</Button>
                                    </div>
                            }

                        </Col>
                    </Row>
                </>
        }
    }

    render(): React.ReactNode {
        return <Modal show={this.props.details !== undefined} onHide={this.close} size="lg">
            <Modal.Header>
                <Modal.Title>Deposits Details
                    {this.props.details !== undefined && <> | {cashPaymentStatusDisplayBadge(this.props.details.status)}</>}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    this.props.details !== undefined ? (<>
                        <Row>
                            <Col>
                                <label>Shop: </label> <b>{this.props.details.shopTitle}</b>
                            </Col>
                            <Col>
                                <label>Amount: </label> <b>$<Amount value={this.props.details.amount} /></b>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <label>Account: </label> <b>{this.props.details.financialAccountType}</b>
                            </Col>
                            <Col>
                                <label>Create At: </label> <b title={(Moment(this.props.details.requestAt).utc().format('YYYY/MM/DD HH:mm')) + ' (UTC)'}>{Moment(this.props.details.requestAt).local().format('YYYY/MM/DD HH:mm')} </b>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <label>Transaction Number: </label> <b>{this.props.details.transactionNumber}</b>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <label>Description: </label> <b>{this.props.details.description}</b>
                            </Col>
                        </Row>
                    </>) : <Spinner animation="grow" />
                }
            </Modal.Body>
            <Modal.Footer style={{ display: 'unset' }}>
                <Row>
                    <Col md="12" sm="12">
                        {this.props.details !== undefined && <>
                            {
                                this.footerView(this.props.details.status)
                            }
                        </>}
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    }
}