import Apple from '../../../assets/images/giftcarts/Apple.webp'
import AppleiTunes from '../../../assets/images/giftcarts/AppleiTunes.webp'
import IMVU from '../../../assets/images/giftcarts/IMVU.webp'
import GooglePlay from '../../../assets/images/giftcarts/GooglePlay.webp'
import Amazon from '../../../assets/images/giftcarts/Amazon.webp'
import Skype from '../../../assets/images/giftcarts/Skype.webp'

interface ICardView {
    title: string,
    image: any,
    subTitle?: string
}
export const DailyPracticals: ICardView[] = [
    {
        image: Apple,
        title: 'Fortnite',
    },
    {
        image: AppleiTunes,
        title: 'Apple iTunes',
    },
    {
        image: IMVU,
        title: 'IMVU',
    },
    {
        image: GooglePlay,
        title: 'Google Play',
    },
    {
        image: Amazon,
        title: 'Amazon',
    },
    {
        image: Skype,
        title: 'Skype',
    },
]
