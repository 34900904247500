import React from "react";
import { Alert, Button, Form, Modal, Spinner } from "react-bootstrap";
import { Cart3, CartCheckFill } from "react-bootstrap-icons";
import OrdersService from "../../../services/orders/OrdersService";
import CartService from "../../../services/shopping/CartService";
import './Cart.scss'
import CartItem from "./Item/CartItem";
import { toast } from 'react-toastify';
import ShopAuthenticated from "../../shared/ShopAuthenticated/ShopAuthenticated";
import { SECRET_INFORMATION } from "../../../constants/ConstantsPermissions";
import authenticationContext from "../../../contexts/AuthenticationContext/AuthenticationContext";
import { SELLER, WEB_SERVICE } from "../../../constants/ConstantsPolicies";
import WalletService from "../../../services/financials/WalletService";
import { NavLink } from "react-router-dom";
import OrderDeliveryModal from "../../orders/Orders/OrderDelivery/OrderDeliveryModal";

export default class Cart extends React.Component<{}, { payUSDTRedirectLink?: string, orderId?: string, walletBalance: number, credit: number, items: CartItemView[], initialized: boolean, loading: boolean, showDetails: boolean, addToInventory: boolean }> {
    static contextType = authenticationContext;
    context!: React.ContextType<typeof authenticationContext>;
    cartService: CartService;
    walletService: WalletService;
    ordersService: OrdersService;
    USDTCurrencyId: string = 'b8b8eaaa-c421-4783-b481-ae4fea6024de';

    constructor(props: any) {
        super(props);
        this.state = { items: [], walletBalance: 0, credit: 0, loading: false, showDetails: false, initialized: false, addToInventory: false }
        this.cartService = new CartService();
        this.ordersService = new OrdersService();
        this.walletService = new WalletService();
    }

    componentDidMount() {
        this.setState({ addToInventory: this.context.policies?.includes(SELLER) || this.context.policies?.includes(WEB_SERVICE) })
        this.loadCart();
        this.loadWalletBalance();
    }

    addToCart = (count: number, productId: string, sellerShopId: string, unitPrice: number): Promise<any> => {
        return this.cartService.add({ count: count, productId: productId, sellerShopId: sellerShopId, unitPrice: unitPrice })
            .finally(() => {
                this.loadCart();
            })
    }

    generateChargeLink = () => {
        this.walletService.getWalletList({}, { pagesize: 10, pageskip: 1 }).then(wallets => {
            var walletId = wallets.rows.filter(wallet => wallet.currencyId == this.USDTCurrencyId)[0].id;
            this.setState({ payUSDTRedirectLink: `/deposit/?currencyId=${this.USDTCurrencyId}` })
        })
    }

    loadWalletBalance = () => {
        this.walletService.getOverview().then((response) => {
            this.setState({ walletBalance: response.balance, credit: response.credit })
            if (response.balance == 0) this.generateChargeLink()
        })
    }

    validateWalletBalance = () => {
        let { walletBalance, credit, items } = this.state
        var balance = credit + walletBalance;
        if (balance == 0) return false
        let pricePayment = items.map(item => item.unitPrice * item.count).reduce((partialSum, a) => partialSum + a, 0);
        if (balance < pricePayment) return false
        return true
    }

    openCart = () => this.setState({ showDetails: true }, () => this.loadCart())

    loadCart = () => {
        this.cartService.get().then((cartItems) => {
            this.setState({
                items: cartItems.map((item) => {
                    return {
                        id: item.id, productId: item.productId, sellerShopId: item.sellerShopId, productInventoryAvailableCount: item.productInventoryAvailableCount,
                        count: item.count, imageSrc: '', title: item.productTitle, unitPrice: item.unitPrice
                    }
                })
            });
        }).finally(() => this.setState({ initialized: true }))
    }

    order = () => {
        if (!this.validateWalletBalance()) return toast.warning('Your wallet balance is not enough.');

        this.setState({ loading: true })
        this.ordersService.order({ addToInventory: this.state.addToInventory }).then((response) => {
            this.loadCart();
            this.setState({ showDetails: false, orderId: response });
            toast.success('Your order created successfully');
        }).catch(() => {
            toast.warning('There was a problem registering the order!');
        }).finally(() => this.setState({ loading: false }))
    }

    delete = (id: string) => {
        this.setState({ loading: true })
        this.cartService.delete(id).then(() => {
            this.loadCart();
        }).finally(() => this.setState({ loading: false }))
    }

    deleteAll = () => {
        this.setState({ loading: true })
        let count = this.state.items.length;
        for (let item of this.state.items)
            this.cartService.delete(item.id).then(() => {
                this.loadCart();
            }).finally(() => count > 0 ? count-- : this.setState({ loading: false }))
    }

    update = (id: string, count: number) => {
        this.setState({ loading: true })
        this.cartService.update({ productBasketId: id, count }).then(() => {
            this.loadCart();
        }).finally(() => this.setState({ loading: false }))
    }

    render(): React.ReactNode {
        if (!this.state.initialized)
            return null
        const count = this.state.items.reduce((total, item) => total + item.count, 0);
        return <div>
            <div className="toggle-icon" onClick={() => this.setState({ showDetails: true })}>
                <Cart3 />
                {count > 0 && <span className="red-badge">{count}</span>}
            </div>

            <Modal show={this.state.showDetails} onHide={() => this.setState({ showDetails: false })}>
                <Modal.Header closeButton>
                    <Modal.Title>Cart {count > 0 && <span> - {count}</span>}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        this.state.items.length > 0 ? <>
                            {
                                this.state.items.map((item, key) => <div key={key}><CartItem inventoryCount={item.productInventoryAvailableCount} title={item.title} count={item.count} imageSrc={item.imageSrc}
                                    unitPrice={item.unitPrice} onDelete={() => this.delete(item.id)} onChangeCount={(count) => {
                                        if (count > item.productInventoryAvailableCount && item.productInventoryAvailableCount != null) return
                                        this.update(item.id, count)
                                    }} /></div>)
                            }
                            {
                                (this.context.policies?.includes(SELLER) || this.context.policies?.includes(WEB_SERVICE)) &&
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                                    <Form.Check name="active" id={'v'} className="mt-4"
                                        checked={this.state.addToInventory}
                                        onChange={() => this.setState({ addToInventory: !this.state.addToInventory })}
                                        type="checkbox" label={'Add items to inventory'}
                                    />
                                </Form.Group>
                            }
                            {
                                (this.state.walletBalance <= 0 && this.state.payUSDTRedirectLink) && <Alert variant="warning">
                                    <p>You need to charge your wallet to buy.</p>
                                    <NavLink to={this.state.payUSDTRedirectLink}>
                                        <Button variant="warning" size="sm" onClick={() => this.setState({ showDetails: false })}>Click To Charge</Button>
                                    </NavLink>
                                </Alert>
                            }

                        </> : <p>
                            Your shopping cart is empty!
                        </p>
                    }
                </Modal.Body>
                <Modal.Footer>

                    <ShopAuthenticated permission={SECRET_INFORMATION}>
                        {this.state.loading ? <Button variant="success"><Spinner animation="border" /></Button>
                            : <Button variant="success" disabled={this.state.items.length === 0} onClick={this.order}><CartCheckFill /> Checkout</Button>

                        }
                    </ShopAuthenticated>

                    <ShopAuthenticated permission={SECRET_INFORMATION} reverse>
                        <Alert variant="warning" style={{ width: '100%', textAlign: 'center' }}>You do not have access to purchase operations</Alert>
                    </ShopAuthenticated>

                </Modal.Footer>
            </Modal>
            {this.state.orderId &&
                < OrderDeliveryModal addToInventory={this.state.addToInventory} orderId={this.state.orderId} closeModal={() => this.setState({ orderId: undefined })} />}
        </div>
    }
}

interface CartItemView { id: string, title: string, productInventoryAvailableCount: number, sellerShopId: string, productId: string, count: number, unitPrice: number, imageSrc: string }