import { mapToDataOnly } from "../common/HttpUtility";
import { NotificationPermissionApiFactory } from "../../generated-api";

export default class NotificationPermissionService {
    get(): Promise<NotificationPermissionRowDto[]> {
        return mapToDataOnly<NotificationPermissionRowDto[]>(NotificationPermissionApiFactory().marketPlaceNotificationPermissionGet());
    }

    update(body: UpdateNotificationPermission[]): Promise<any> {
        return mapToDataOnly<any>(NotificationPermissionApiFactory().marketPlaceNotificationPermissionPut(body));
    }
}

export class NotificationPermissionRowDto {
    id: string
    key: string
    title: string
    isActive: boolean
}

export class UpdateNotificationPermission {
    isActive: boolean
    permissionId: string
}