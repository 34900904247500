import React, { FormEvent } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import IdentityService from "../../../services/identity/IdentityService";
import '../IdentityLayout.scss'

export default class ForgotPassword extends React.Component<{}, State> {
    returnUrl: string;
    identityService: IdentityService;
    constructor(props: any) {
        super(props);
        this.state = { email: '', loading: false, done: false };
        this.identityService = new IdentityService();
        this.returnUrl = `${window.location.protocol}//${window.location.host}/reset-password`;
    }
    submit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();

        this.setState({ loading: true });
        this.identityService.forgotPassword(this.state.email, this.returnUrl).then(() => {
            toast.success('Password recovery email was sent to you.');
            this.setState({ done: true });
        }).finally(() => {
            this.setState({ loading: false });
        })
    }
    render(): React.ReactNode {
        return <div className="IdentityLayout">
            <div className="box sm">
                <h3>Forgot Password</h3>
                <hr />
                {
                    this.state.done ?
                        <div className="center-text">
                            Check your inbox to next step.
                        </div> :
                        <Form onSubmit={this.submit}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" placeholder="Enter email" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} />
                            </Form.Group>
                            {
                                this.state.loading ? <Button variant="primary" disabled>
                                    <Spinner animation="border" size="sm" />
                                </Button> : <Button variant="primary" type="submit">
                                    Next
                                </Button>
                            }
                        </Form>
                }
            </div>
            <div className="footer">
                <NavLink to={'/login'}>Sign in</NavLink>
            </div>
        </div>
    }
}

interface State {
    email: string,
    loading: boolean,
    done: boolean
}