import React from "react";
import { Modal, OverlayTrigger, Tab, Table, Tabs, Tooltip } from "react-bootstrap";
import OrdersService from "../../../services/orders/OrdersService";
import HubridMoment from "../../utilities/HybridMoment";
import orderStatusDisplayBadge from "../OrderEnums";
import OrderItems from "./OrderItems";
import { OrderTypeEnum } from "../Enums/OrderTypeEnum";
import { Stack } from "react-bootstrap-icons";

interface OrderDerailsView {
    type: OrderTypeEnum,
    orderNumber: string;
    productCount: number;
    orderAt: string;
    status: number;
    totalPrice: number;
    addToInventory: boolean;
    products: OrderDetailsItemView[];
}

interface OrderDetailsItemView {
    productCount: number;
    title: string;
    totalAmount: number;
    unitPrice: number;
}

interface OrderDerailsState {
    order: OrderDerailsView;
    show: boolean;
}

export default class OrderDetails extends React.Component<{ orderId: string, onClose: () => void, onLoaded: () => void }, OrderDerailsState> {
    ordersService: OrdersService;
    constructor(props: any) {
        super(props);
        this.state = { order: { orderNumber: '', orderAt: '', productCount: 0, status: 0, totalPrice: 0, products: [], type: OrderTypeEnum.Website, addToInventory: false }, show: false };
        this.ordersService = new OrdersService();
    }
    componentDidMount() {
        this.loadDetails();
    }
    componentDidUpdate(prevProps: { orderId: string }, prevState: OrderDerailsState) {
        if (this.props.orderId !== prevProps.orderId)
            this.loadDetails();
    }
    loadDetails = () => {
        this.ordersService.getDetails(this.props.orderId).then((details: any) => {
            this.setState({ show: true, order: { ...details } });
            this.props.onLoaded();
        });
    }
    close = () => {
        this.setState({ show: false });
        this.props.onClose();
    }
    render(): React.ReactNode {
        return (<Modal
            size="lg"
            show={this.state.show}
            onHide={this.close}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Order {this.state.order.orderNumber}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mb-4">
                    <Table size="lg">
                        <thead>
                            <tr>
                                <th>Status</th>
                                <th>Total Price</th>
                                <th>Count</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    {orderStatusDisplayBadge(this.state.order.status)}
                                    <span style={{ display: 'inline-block', marginLeft: '5px' }}>
                                        {
                                            this.state.order.addToInventory && this.state.order.status == 1 &&
                                            <OverlayTrigger
                                                key="top"
                                                placement="top"
                                                overlay={
                                                    <Tooltip id="tooltip-top">
                                                        This order items added to your inventory
                                                    </Tooltip>
                                                }
                                            >
                                                <div style={{ color: 'green' }}>
                                                    <Stack />
                                                </div>
                                            </OverlayTrigger>
                                        }
                                    </span>
                                </td>
                                <td>{this.state.order.totalPrice}</td>
                                <td>{this.state.order.productCount}</td>
                                <td>{HubridMoment(this.state.order.orderAt)}</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
                <Tabs
                    defaultActiveKey="overview"
                    transition={false}
                    className="mb-3"
                >
                    <Tab eventKey="overview" title="Overview">
                        {this.overview()}
                    </Tab>
                    <Tab eventKey="items" title="Items">
                        <OrderItems orderId={this.props.orderId} disableShowCode={this.state.order.addToInventory} />
                    </Tab>
                </Tabs>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>);
    }

    overview() {
        return <Table striped hover responsive size="lg">
            <thead>
                <tr>
                    <th>#</th>
                    <th>Title</th>
                    <th>Count</th>
                    <th>Total Price</th>
                    <th>Unit Price</th>
                </tr>
            </thead>
            <tbody>
                {this.state.order.products.map((product, index) =>
                    <tr>
                        <td>{index + 1}</td>
                        <td>{product.title}</td>
                        <td>{product.productCount}</td>
                        <td>{product.totalAmount}</td>
                        <td>{product.unitPrice}</td>
                    </tr>)}
                <tr>
                </tr>
            </tbody>
        </Table>
    }
}