import { mapToDataOnly } from '../common/HttpUtility'
import { ArticleApiFactory, ArticleCategoryApiFactory } from '../../generated-api/api'

export default class ArticleService {
  getArticle(categoryId?: string): Promise<IArticleDTO[]> {
    return mapToDataOnly<IArticleDTO[]>(ArticleApiFactory().marketPlaceArticleGet(categoryId));
  }

  getArticleCategory(): Promise<articleCategories[]> {
    return mapToDataOnly<any>(ArticleCategoryApiFactory().marketPlaceArticleCategoryGet())
  }
}

export interface IArticleDTO {
  id: string
  title: string
  details: string
  isActive: boolean
  imageName: string
  imageSrc: string
  createAt: string
  categoryId: string
  categoryTitle: string
}

export interface articleCategories {
  id: string
  title: string
  orderId: string
  isActive: boolean
  imageName: string
  imageSrc: string
  parentId: string | null
  parentTitle: string | null
  createAt: string
}
