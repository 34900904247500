import React, { RefObject } from "react";
import { Badge, Button, Card, Col, Row, Spinner } from "react-bootstrap";
import SupportTicketsService from "../../../../services/helpCenter/SupportTicketService";
import TOTPForm from "../../../identity/Security/TwoFactor/TOTPForm/TOTPForm";
import { IShopInventoryRowDTO, IShopInventoryTicketDTO } from "../../../inventory/InventoriesList/InventoryList.models";
import TicketView from "../../Common/TicketView/TicketView";
import supportTicketStatusDisplayBadge, { SupportTicketStatus } from "../SupportTicketStatusEnum";
import SupportTicketAddVote from "./AddVote/SupportTicketAddVote";
import './SupportTicketDetials.scss'
import Moment from 'moment';
import { EyeFill, EyeSlash, ShieldFillCheck } from "react-bootstrap-icons";
import InventoryService from "../../../../services/inventory/InventoryService";
import { toast } from "react-toastify";
import ApigiftMediationModal from "../../Common/apigift-mediation-modal/ApigiftMediationModal";
import TagList from "../tag-list/TagList";
import { SelectionView } from "../../../../services/common/Models";
import OrdersService, { OrderRelativeStatisticsDto } from "../../../../services/orders/OrdersService";
import ReviewRequestService from "../../../../services/orders/ReviewRequestService";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default class SupportTicketDetials extends React.Component<{ id: string }, State> {
    supportTicketsService: SupportTicketsService;
    inventoryService: InventoryService;
    reviewRequestService: ReviewRequestService;
    ordersService: OrdersService;
    ticketView: RefObject<TicketView>
    totpForm: RefObject<TOTPForm>
    constructor(props: any) {
        super(props);
        this.supportTicketsService = new SupportTicketsService();
        this.inventoryService = new InventoryService();
        this.ordersService = new OrdersService();
        this.reviewRequestService = new ReviewRequestService();
        this.state = {
            isLoading: false,
            title: '', returnReasonCode: 'INVALID_CODE', createAt: new Date(), department: { title: '', value: '' }, handlerName: '', isGood: null, modifyAt: '', status: 0, ticketNumber: '', replies: [],
            showAcceptDialog: false,
            showOtpForm: false,
            withSecretInfo: false,
            showMediationModal: false,
            canRequestMediation: false,
            sellerId: '',
            tags: [],
        };
        this.ticketView = React.createRef();
        this.totpForm = React.createRef();
    }

    addReply = (text: string, attachmentNames?: string[]): Promise<void> => {
        return this.supportTicketsService.reply(this.props.id, text, attachmentNames).then(() => {
            this.loadTickets();
        });
    }

    componentDidMount = () => {
        this.loadTickets();

    }

    loadTickets = () => {
        this.supportTicketsService.getById(this.props.id).then((ticket) => {
            this.setState({ ...ticket })
            this.ordersService.getSaleRelativeStatistics(ticket.sellerId).then((statistics) => {
                this.setState({ orderRelativeStatistics: statistics })
            })
        })
    }

    accept = () => {
        if (this.state.isLoading)
            return;
        
        this.setState({ isLoading: true })
        this.reviewRequestService.accept(this.props.id).then(() => {
            this.loadTickets();
        }).finally(() => {
            this.setState({ isLoading: false })
        })
    }

    informationToArray(information): string[] {
        return JSON.parse(information);
    }

    inventoryDetailsView = () => {
        const { inventory } = this.state;
        let information = this.informationToArray(inventory.information);
        return <Card>
            <Card.Header>Item Info</Card.Header>
            <Card.Body>
                <Card.Title >{information.map((info, index) => <span key={index}>{info}<br /></span>)}</Card.Title>
                <Card.Text>
                    <div><label>Product:</label>  {inventory.title}</div>
                </Card.Text>
            </Card.Body>
        </Card>
    }

    saleRelativeStatisticsView = () => {
        return <Card>
            <Card.Header>Seller Statistics</Card.Header>
            <Card.Body>
                <Row>
                    <Col md="6" sm="6">
                        <span>Order Count (Sell Total):</span>
                        <b>
                            {
                                this.state.orderRelativeStatistics.totalRelativeOrderItemCount
                            }
                        </b>
                    </Col>
                    <Col md="6" sm="6">
                        <span>Review Request Percent (Sell Total):</span>
                        <b>
                            {
                                this.state.orderRelativeStatistics.totalReviewRequestPercent
                            }%
                        </b>
                    </Col>
                </Row>
                <Row>
                    <Col md="6" sm="6">
                        <span>Order Count (Sell to you):</span>
                        <b>
                            {
                                this.state.orderRelativeStatistics.orderItemCount
                            }
                        </b>
                    </Col>
                    <Col md="6" sm="6">
                        <span>Review Request Percent (Sell to you):</span>
                        <b>
                            {
                                this.state.orderRelativeStatistics.reviewRequestPercent
                            }%
                        </b>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    }

    returnReasonDetailsView = () => {
        var systemMessage = <></>;
        switch (this.state.returnReasonCode) {
            case 'INVALID_CODE':
                systemMessage = <>
                    <Badge bg="danger">Invalid Code</Badge>
                    <p>
                        You have submitted a request that your code is invalid
                    </p>
                    <br />
                    <h6>Suggested steps for you</h6>
                    <ol>
                        <li>Send your picture and other explanations about the invalidity of the code to the seller here</li>
                        <li>If you are sure that the code is invalid, ask the seller to refund your money</li>
                        <li>If you did not receive any response from the user after 36 hours or the response was not convincing for you, you can use the mediation option.</li>
                    </ol>
                </>
                break;
            case 'ALREADY_REDEEMED':
                systemMessage = <>
                    <Badge bg="danger">Already Redeemed</Badge>
                    <p>
                        You have registered a request that the desired code has already been used.
                    </p>
                    <br />
                    <h6>Suggested steps for you</h6>
                    <ol>
                        <li>Send your photo and other explanations about the code being used to the seller here</li>
                        <li>Follow up through support on the website of each product to make sure the exact time the code is used.</li>
                        <li>If you are sure that you or your end users did not use the mod, ask the seller to refund your money</li>
                        <li>If you did not receive any response from the user after 36 hours or the response was not convincing for you, you can use the mediation option.</li>
                    </ol>
                </>
                break;
            case 'DIFFERENT_REGION':
                systemMessage = <>
                    <Badge bg="danger">Different Region</Badge>
                    <p>
                        You have filed a request regarding the discrepancy of the desired code region.
                    </p>
                    <br />
                    <h6>Suggested steps for you</h6>
                    <ol>
                        <li>Submit your photo and other description of the desired code region discrepancy to the seller here</li>
                        <li>If you are sure that the code region you want is different, ask the seller for a refund</li>
                        <li>If you did not receive any response from the user after 36 hours or the response was not convincing for you, you can use the mediation option.</li>
                    </ol>
                </>
                break;
            case 'DIFFERENT_VALUE':
                systemMessage = <>
                    <Badge bg="danger">Different Value</Badge>
                    <p>
                        You have filed a request regarding the discrepancy of the desired code value.
                    </p>
                    <br />
                    <h6>Suggested steps for you</h6>
                    <ol>
                        <li>Send your photo and other explanations about the value discrepancy of the desired code to the seller here</li>
                        <li>If you are sure that the core value you want is different, ask the seller for a refund</li>
                        <li>If you did not receive any response from the user after 36 hours or the response was not convincing for you, you can use the mediation option.</li>
                    </ol>
                </>
                break;

        }

        return <Card style={{ marginTop: '10px' }}>
            <Card.Header>Review Request</Card.Header>
            <Card.Body>
                {
                    systemMessage
                }

                <br />
                <h6>
                    Keep the following points in mind during the entire conversation with the seller.
                </h6>
                <ul>
                    <li>No contact information should be sent between the seller and the buyer</li>
                    <li>Make sure that you have chosen the right reason for the review request</li>
                    <li>Sellers need some time to review your request and it is usually not an automatic process, so be patient</li>
                    <li>Do not provide the information of your user accounts to the seller (in this case, the responsibility will be with you)</li>
                    <li>Transactions on this platform will always be the best way, and the buyer and seller will trade safely</li>
                    <li>We protect sellers' capital and buyers' trust, if the request is prolonged, we will check it directly</li>
                    <li>Always maintain courtesy and respect in your correspondence with the seller</li>
                </ul>
            </Card.Body>
        </Card>
    }

    render(): React.ReactNode {
        const mediationRequest = this.state.mediationRequestDate !== null && this.state.mediationRequestDate !== undefined;
        const dateTimeNow = new Date();
        const createDatePassedForMediation = this.state.canRequestMediation
        const underMediation = mediationRequest && this.state.isMediationDone !== true;
        const canMediationRequest = (this.state.orderProductId != null && this.state.orderProductId != undefined) &&
            this.state.status != SupportTicketStatus.Closed
            && (!mediationRequest)
            && createDatePassedForMediation;
        return <div className="SupportTicketDetials">
            <Row>
                <Col xl="8">
                    <h3 style={{ display: "inline-block" }}>Ticket <b>{this.state.ticketNumber} </b></h3><br /> {(mediationRequest && this.state.isMediationDone !== true && this.state.status != SupportTicketStatus.Closed) && <span style={{ color: "green" }}>
                        This ticket is under mediation <ShieldFillCheck />
                    </span>}
                    <TagList onHandle={this.loadTickets} ticketId={this.props.id} datas={this.state.tags} />
                </Col>
                <Col>
                    <div style={{ float: 'right' }}>
                        {
                            canMediationRequest && <Button variant="outline-danger" size="sm" className="m-1" onClick={() => this.setState({ showMediationModal: true })}>Mediation Request</Button>
                        }
                        {
                            this.state.status != SupportTicketStatus.Closed && !underMediation && <Button variant="outline-success" size="sm" onClick={() => this.setState({ showAcceptDialog: true })}>Accept & Close Review Request</Button>
                        }
                        {
                            this.state.status != SupportTicketStatus.Closed && underMediation && <Button variant="danger" size="sm" onClick={() => this.setState({ showAcceptDialog: true })}>Accept & Close Mediation {this.state.isLoading && <Spinner animation="border" />} </Button>
                        }

                    </div>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col>
                    <h6>Subject: <b>{this.state.title}</b></h6>
                    <label>{this.state.productTitle} {this.state.sellPrice !== null && this.state.sellPrice !== undefined && this.state.sellPrice > 0 ? "(Price: " + this.state.sellPrice + ")" : ""}</label>
                </Col>
                <Col>
                    <h6>Ticket Number: <b>{this.state.ticketNumber}</b></h6>
                </Col>
                <Col>
                    <h6>Status: <b>{supportTicketStatusDisplayBadge(this.state.status)}</b></h6>
                </Col>
                <Col>
                    {
                        this.state.department !== null &&
                        <h6>Department: <b>{this.state.department.title}</b></h6>
                    }
                </Col>
            </Row>
            <SupportTicketAddVote isGood={this.state.isGood} onHandle={this.loadTickets} supportTicketId={this.props.id} />

            <Row className="mt-2">
                {this.state.inventory &&
                    <Col md="3" sm="12">
                        {this.inventoryDetailsView()}
                        {this.returnReasonDetailsView()}
                    </Col>
                }
                <Col md={this.state.inventory ? 9 : 12} sm="12">
                    {this.state.orderRelativeStatistics && this.saleRelativeStatisticsView()}
                    <div className="mt-2">
                        <TicketView ref={this.ticketView} shopId={localStorage.getItem('activeshop')} onHandleReply={this.addReply} draftId={this.props.id} hasEnableAddReply title="Reply" comments={this.state.replies} />
                    </div>
                </Col>
            </Row>
            {this.state.showMediationModal && <ApigiftMediationModal onHandleSubmit={this.loadTickets} closeModal={() => this.setState({ showMediationModal: false })} ticketId={this.props.id} />}
            <Dialog
                open={this.state.showAcceptDialog}
                keepMounted
                onClose={() => this.setState({ showAcceptDialog: false })}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Are you sure close this request?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        This review request will be close and you can't open again review request for this order item, are you sure close this request?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.setState({ showAcceptDialog: false })} variant="outline-danger">Cancel</Button>
                    <Button onClick={() => this.accept()}>Accept and Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    }
}

interface State {
    isLoading: boolean,
    orderRelativeStatistics?: OrderRelativeStatisticsDto,
    returnReasonCode: 'INVALID_CODE' | 'DIFFERENT_VALUE' | 'ALREADY_REDEEMED' | 'DIFFERENT_REGION',
    showMediationModal: boolean,
    createAt: Date
    department: {
        title: string
        value: string
    },
    handlerName: string
    isGood: boolean | null
    modifyAt: string
    replies: {
        createAt: string
        description: string
        userDisplayName: string,
        shopId: string | null
    }[]
    status: number
    sellerId: string
    ticketNumber: string
    title: string
    orderProductId?: string | null,
    inventory?: IShopInventoryTicketDTO,
    productTitle?: string,
    sellPrice?: number,
    inventoryId?: string | null
    showOtpForm: boolean
    withSecretInfo: boolean,
    mediationRequestDate?: Date,
    canRequestMediation: boolean,
    isMediationDone?: boolean,
    tags: SelectionView[],
    showAcceptDialog: boolean
}