import React, { RefObject } from 'react'
import DynamicGrid from '../../../shared/DynamicGrid/DynamicGrid'
import { Button, Form, Spinner } from 'react-bootstrap'
import Note from '../../../shared/Note/Note'
import { toast } from 'react-toastify'
import AdminSupportTicketConfigService from '../../../../services/admin/review-request/AdminReviewRequestConfigService'

export default class AdminConfig extends React.Component<
  {},
  {
    isAutoReview: boolean
    isActiveFootNoot: boolean
    pendingRequest: boolean
  }
> {
  dynamicGrid: RefObject<DynamicGrid<{}>>
  supportService: AdminSupportTicketConfigService

  constructor(props: any) {
    super(props)
    this.state = { isAutoReview: false, pendingRequest: false, isActiveFootNoot: false }
    this.dynamicGrid = React.createRef()
    this.supportService = new AdminSupportTicketConfigService()
  }

  componentDidMount(): void {
    this.fetchData()
  }

  fetchData = () => {
    this.supportService.get().then((res) => {
      var autoReview = res.filter((item) => item.key === 'IS_AUTO_REVIEW');
      var activeFootNoot = res.filter((item) => item.key === 'ACTIVE_FOOT_NOTE');

      this.setState({
        isAutoReview: autoReview.length > 0 && autoReview[0].value === 'true' ? true : false,
        isActiveFootNoot: activeFootNoot.length > 0 && activeFootNoot[0].value === 'true' ? true : false
      })
    })
  }

  setData = () => {
    let { isAutoReview, isActiveFootNoot } = this.state
    this.setState({ pendingRequest: true })

    let body = [
      { key: 'IS_AUTO_REVIEW', value: String(isAutoReview)},
      { key: 'ACTIVE_FOOT_NOTE', value: String(isActiveFootNoot)},
    ];

    this.supportService
      .set(body)
      .then(() => toast.success("Set config successfully"))
      .finally(() => this.setState({ pendingRequest: false }))
  }

  render(): React.ReactNode {
    let { pendingRequest, isActiveFootNoot, isAutoReview } = this.state

    return (
      <>
        <Note>
          You can setting tickets on this page
        </Note>
        <hr />
        <div className='ms-3'>
          <Form>
            <Form.Label className='fw-bold'>Ticket Review</Form.Label>
            <div className="d-flex align-items-center mt-2 mb-5">
              <Form.Check
                type='checkbox'
                label='Enable Auto Review'
                checked={isAutoReview}
                onChange={(e) => this.setState({ isAutoReview: e.target.checked })}
              />
            </div>
            <Form.Label className='fw-bold'>Ticket Reply</Form.Label>
            <div className="d-flex align-items-center mt-2">
              <Form.Check
                type='checkbox'
                label='Activate Reply Footer'
                checked={isActiveFootNoot}
                onChange={(e) => this.setState({ isActiveFootNoot: e.target.checked })}
              />
            </div>
          </Form>
          <Button
            className="mt-5"
            onClick={() => this.setData()}
            variant="success"
          >
            {pendingRequest ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <span>Save</span>
            )}
          </Button>
        </div>
      </>
    )
  }
}
