import React, { RefObject } from "react";
import { Button } from "react-bootstrap";
import ExchangeService, { IExchangesPaginationFilters } from "../../../../services/financials/ExchangeService";
import { GridColumn, GridColumnTypes } from "../../../Grid/Grid.models";
import DynamicGrid from "../../../shared/DynamicGrid/DynamicGrid";
import exchangeStatusDisplayBadge from "../../Enums/ExchangeStatusEnum";

export default class ExchangeList extends React.PureComponent<{}, {}>{
    exchangeService: ExchangeService
    dynamicGrid: RefObject<DynamicGrid<IExchangesPaginationFilters>>;
    constructor(props: any) {
        super(props);
        this.dynamicGrid = React.createRef();
        this.exchangeService = new ExchangeService()
        this.state = { showModal: false }
    }


    render(): React.ReactNode {
        let gridColumns: GridColumn[] = [
            { title: 'Source', key: 'sourceCurrencyTitle' },
            { title: 'Destination', key: 'destinationCurrencyTitle' },
            { title: 'Source Amount', key: 'sourceAmount', type: GridColumnTypes.Amount },
            { title: 'Destination Amount', key: 'destinationAmount', type: GridColumnTypes.Amount },
            { title: 'Status', key: 'statusBadge' },
            { title: 'Request Date', key: 'requestAt', type: GridColumnTypes.Date },
        ];

        return (<div>
            <DynamicGrid<IExchangesPaginationFilters> ref={this.dynamicGrid}
                columns={gridColumns}
                onFetch={this.exchangeService.get}
                refactorRow={(row) => {
                    return { ...row, statusBadge:  exchangeStatusDisplayBadge(row.status)}
                }} />
        </div>)
    }
}