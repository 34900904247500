import React from 'react';
import { Modal, Row, Col, Form, Button, Spinner, Badge } from 'react-bootstrap'
import InventoryService from '../../../services/inventory/InventoryService';
import IState, { IProps } from './DownloadProductInventoryModal.models';
import authenticationContext from "../../../contexts/AuthenticationContext/AuthenticationContext";
import TOTPForm from '../../identity/Security/TwoFactor/TOTPForm/TOTPForm';
import Note from '../../shared/Note/Note';

export default class DownloadProductInventoryModal extends React.Component<IProps, IState> {
    inventoryService: InventoryService
    static contextType = authenticationContext;
    context!: React.ContextType<typeof authenticationContext>;
    constructor(props: IProps) {
        super(props)
        this.state = { isOut: true, description: '', pendingRequest: false, activeOTPMode: false }
        this.inventoryService = new InventoryService()
    }

    download = () => {
        const { description, isOut } = this.state

        this.setState({ pendingRequest: true, activeOTPMode: false })

        this.inventoryService.download(this.props.productId, { description, isOut })
            .then(() => this.props.closeModal())
            .finally(() => this.setState({ isOut: true, description: '', pendingRequest: false }))
    }

    downloadHandler = () => {
        if (this.context.twoFactorAvtive) this.setState({ activeOTPMode: true })

        else this.download();
    }

    render(): React.ReactNode {
        const { isOut, description } = this.state
        const { closeModal } = this.props
        return (
            <Modal size="lg"
                show={true}
                onHide={closeModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Download All Product Inventory</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md="6" sm="12">
                            <Form.Group controlId="withdrawInventor">
                                <Form.Check type="checkbox" label="Out Inventory" checked={isOut} onChange={() => this.setState({ isOut: !isOut })} />
                            </Form.Group>
                        </Col>
                        {
                            isOut &&
                            <>
                                <Col className="mt-3" md="12" sm="12">
                                    <Form.Label>Description:</Form.Label>
                                    <Form.Control as="textarea" rows={3} placeholder="Enter description"
                                        value={description}
                                        onChange={e => this.setState({ description: e.target.value })} />
                                </Col>
                                <div style={{padding: '7px'}}>
                                    <Note>All inventories are download by the excel file and the status changes to the <b>Out</b>.</Note>
                                </div>
                            </>
                        }
                    </Row>
                    {this.state.activeOTPMode && <TOTPForm showModal={this.state.activeOTPMode} onSubmit={this.download} onHide={() => this.setState({ activeOTPMode: false })} />}
                </Modal.Body>
                <Modal.Footer>
                    {this.state.pendingRequest ? <Button variant='success' disabled><Spinner animation='border' size="sm" /></Button> :
                        <Button variant='success' onClick={this.downloadHandler}>Download</Button>}
                </Modal.Footer>
            </Modal>
        )
    }
}