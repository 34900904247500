import React from "react";
import { Col, Modal, Row, Spinner } from "react-bootstrap";
import AdminArticleService from "../../../../../services/admin/articles/AdminArticlesService";

export default class AdminArticleDetailsModal extends React.Component<{ articleId?: string, onClose: () => void }, { loading: boolean, details: string, title: string, imageSrc?: string }> {
    adminArticleService: AdminArticleService
    constructor(props: any) {
        super(props);
        this.state = { loading: false, details: '', title: '' }
        this.adminArticleService = new AdminArticleService()
    }

    componentDidMount(): void {
        this.props.articleId && this.getDetails()
    }

    getDetails = () => {
        this.setState({ loading: true })
        this.adminArticleService.getById(this.props.articleId)
            .then(response => this.setState({ details: response.details, title: response.title, loading: false, imageSrc: response.imageSrc }))
    }

    render(): React.ReactNode {
        let { loading, details, title, imageSrc } = this.state

        return <Modal size="xl" show={true} onHide={this.props.onClose}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="article-body">
                    {
                        !loading ?
                            <>
                                {
                                    imageSrc &&
                                    <div className="d-flex align-items-center justify-content-center mb-3">
                                        <img src={imageSrc} style={{maxWidth: '300px', maxHeight: '300px'}}/>
                                    </div>
                                }
                                <div dangerouslySetInnerHTML={{ __html: details }}></div>
                            </>
                            : <Spinner animation="grow" />
                    }
                </div>
            </Modal.Body>
        </Modal>
    }
}