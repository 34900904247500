import React from "react";
import { Button, Form, Spinner, Table } from "react-bootstrap";
import { Navigate, NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import UserPrivilegeService from "../../../services/identity/UserPrivilegeService";
import IState, { IEditUserPrivilegeDTO, IProps, IUserPrivilegesRowDTO } from "./UserPrivilege.models"

export default class UserPrivilege extends React.PureComponent<IProps, IState> {
    userPrivilegeService: UserPrivilegeService
    constructor(props: IProps) {
        super(props);
        this.state = { privileges: [], loading: false, isSelectedAll: false, pendingRequest: false }
        this.userPrivilegeService = new UserPrivilegeService()
    }

    componentDidMount() {
        this.loadData()
    }

    loadData = async () => {
        this.setState({ loading: true })
        this.userPrivilegeService.getUserPrivileges(this.props.userId).then((response) => {
            this.setState({ loading: false, privileges: response, isSelectedAll: response.filter(item => !item.hasPrivilege).length > 0 ? false : true },)
        })
    }

    updatePrivileges = () => {
        this.setState({ pendingRequest: true })

        let dto: IEditUserPrivilegeDTO[] = this.state.privileges.map((privilege) => {
            return {
                isSelected: privilege.hasPrivilege,
                privilegeId: privilege.id
            } as IEditUserPrivilegeDTO
        })

        this.userPrivilegeService.updatePrivilege(dto, this.props.userId).then(() => {
            toast.success('User privilege updates successfully.')
            return <Navigate to={'/management'} />
        }).finally(() => {
            this.setState({ pendingRequest: false })
        })
    }

    toggleSelectAll = () => {
        let updatedPrivileges = this.state.privileges.map((privilege) => ({
            ...privilege,
            hasPrivilege: !this.state.isSelectedAll,
        }));

        this.setState({ isSelectedAll: !this.state.isSelectedAll, privileges: updatedPrivileges })
    }

    selectRow = (row: IUserPrivilegesRowDTO) => {
        let tmpPrivileges: IUserPrivilegesRowDTO[] = this.state.privileges
        tmpPrivileges.filter((item) => item.id === row.id).map((privilege) => {
            return privilege.hasPrivilege = !privilege.hasPrivilege
        })

        this.setState({ privileges: tmpPrivileges, isSelectedAll: tmpPrivileges.filter(item => !item.hasPrivilege).length > 0 ? false : true })
        this.forceUpdate()
    }

    render(): React.ReactNode {
        return <>
            <h3> User Privilege</ h3>
            <hr />
            {this.gridView()}
            < div className="mt-3" > {
                this.state.pendingRequest ? <Button size="sm" variant='success' disabled><Spinner animation='border' size="sm" /></Button>
                    : <Button size="sm" variant="success" onClick={this.updatePrivileges}>Update Privileges</Button>
            }
                <NavLink to={`/management`}>
                    <Button size="sm" className="m-2" variant="outline-info">Back To Shop Members List</Button>
                </NavLink>
            </ div>
        </>
    }

    gridView() {
        return (
            <Table hover responsive size="lg">
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Key</th>
                        <th><Form.Check type="checkbox" checked={this.state.isSelectedAll} onChange={() => this.toggleSelectAll()} /></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.loading ? (<tr><td colSpan={5} align="center"><Spinner animation="grow" /></td></tr>) :
                            this.state.privileges.map((privilege) =>
                                <tr key={privilege.id}>
                                    <td>{privilege.title}</td>
                                    <td>{privilege.key}</td>
                                    <td><Form.Check type="checkbox" checked={privilege.hasPrivilege} onChange={() => this.selectRow(privilege)} /></td>
                                </tr>)}
                </tbody>
            </Table>
        )
    }
}