import React, { FormEvent, RefObject } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import AdminArticleCategoryService from "../../../../../services/admin/articles/AdminArticleCategoryService";
import AdminArticleService from "../../../../../services/admin/articles/AdminArticlesService";
import EditorComponent from "../../../../shared/Editor/Editor";
import Uploader from "../../../../shared/Uploader/Uploader";
import { IProps, IState } from "./AdminCreateArticle.models";
import AdminAuthenticated from "../../../../shared/AdminAuthenticated/AdminAuthenticated";
import { ADMIN, SUPERADMIN } from "../../../../../constants/ConstantsPermissions";

export default class AdminCreateArticle extends React.Component<IProps, IState> {
    uploader: RefObject<Uploader>
    adminArticleService: AdminArticleService
    adminArticleCategoryService: AdminArticleCategoryService
    constructor(props: any) {
        super(props);
        this.state = { loading: false, pendingRequest: false, categoryTitleValues: [], article: { categoryId: '', details: '', imageName: null, published: false, title: '', imageSrc: '' } }
        this.adminArticleService = new AdminArticleService()
        this.adminArticleCategoryService = new AdminArticleCategoryService()
    }

    componentDidMount(): void {
        this.getCategory()
    }

    getCategory = () => {
        this.adminArticleCategoryService.getTitleValues().then(response => {
            this.setState({ categoryTitleValues: response, article: { ...this.state.article, categoryId: response[0].value } })
        }).finally(() => {
            if (this.props.articleId !== null) this.getDetails()
        })
    }

    getDetails = () => {
        this.setState({ loading: true })
        this.adminArticleService.getById(this.props.articleId).then((response) => {
            this.setState({ article: response })
        }).finally(() => this.setState({ loading: false }))
    }

    requestToEdit = (body: any) => {
        this.adminArticleService.update(this.props.articleId, body).then(() => {
            toast.success("Article updated successfully")
        }).finally(() => this.setState({ pendingRequest: false }))
    }

    requestToCreate = (body: any) => {
        this.adminArticleService.add(body).then(() => {
            toast.success("The article added successfully")
        }).finally(() => this.setState({ pendingRequest: false }))
    }

    submit = (e: FormEvent<HTMLFormElement>) => {
        this.setState({ pendingRequest: true })

        e.preventDefault();
        e.stopPropagation();

        let { article } = this.state

        let body = {
            title: article.title,
            categoryId: article.categoryId,
            imageName: article.imageName,
            published: article.published,
            details: article.details
        }

        if (this.props.articleId)
            this.requestToEdit(body)
        else this.requestToCreate(body)
    }

    onChangeText = (text: string) => {
        this.setState({ article: { ...this.state.article, details: text } })
    }

    render(): React.ReactNode {
        let { pendingRequest, article, loading, categoryTitleValues } = this.state
        let { articleId } = this.props

        return <div>
            {
                loading ? <Spinner animation="grow" /> :
                    <>
                        <Form className="mt-5" onSubmit={this.submit}>
                            <Row>
                                <Col md="4" sm="12">
                                    <h3>{articleId ? 'Edit Article' : 'Add Article'}</h3>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col md="4" sm="12">
                                    <Form.Label>Title:</Form.Label>
                                    <Form.Control className="mb-3" placeholder="Enter Title"
                                        value={article.title}
                                        onChange={e => this.setState({ article: { ...article, title: e.target.value } })} />
                                </Col>
                                <Col md="3" sm="12">
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                                        <Form.Label>Category:</Form.Label>
                                        <Form.Select aria-label="Select source currency" id="currencyId"
                                            value={article.categoryId}
                                            onChange={e => this.setState({ article: { ...article, categoryId: e.target.value } })}>
                                            {categoryTitleValues.map((item) => <option value={item.value} key={item.value}>{item.title}</option>)}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col md="3" sm="12">
                                    <AdminAuthenticated roles={[ADMIN, SUPERADMIN]}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                                            <Form.Check name="active" id={'v'} className="mt-4"
                                                checked={article.published}
                                                onChange={(e) => this.setState({ article: { ...article, published: !article.published } })}
                                                type="checkbox" label={'Publish'}
                                            />
                                        </Form.Group>
                                    </AdminAuthenticated>
                                </Col>
                                <Col md="4" sm="12">
                                    <Uploader ref={this.uploader} folderName='products' onUpload={(path) => this.setState({ article: { ...article, imageName: path } })} imageAddress={article.imageSrc} />
                                </Col>
                            </Row>
                            <Row className="mb-5">
                                <Col className="mt-3 mb-3" md="7" sm="12">
                                    <Form.Label>Description:</Form.Label>
                                    <EditorComponent onChange={this.onChangeText} text={article.details} isImageEditor />
                                </Col>
                            </Row>

                            {pendingRequest ? <Button variant='success' disabled><Spinner size="sm" animation='border' /></Button> :
                                <Button variant="success" type="submit">Save</Button>}
                        </Form>
                    </>
            }
        </div>
    }
}