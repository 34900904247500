import React from "react"
import { VirtualCreditCards } from "../../../constants/Data/CreditCardsData"
import { CryptocurrencyData } from "../../../constants/Data/CryptocurrencyData"
import SectionTitle from "../../../shared/SectionView/SectionTitle/SectionTitle"
import Cards from "../Cards/CreditCards"

export default class FinancialServicesComponent extends React.PureComponent<{}, {}> {
    render(): React.ReactNode {
        return (<div className="m-lg" id="Know Gift Cards and see services">
            <SectionTitle title="Some of our most used Financial Services" />
            <Cards redirectButton data={VirtualCreditCards} title="Virtual credit cards" />
            {/* <Cards redirectButton data={CryptocurrencyData} title="Cryptocurrency credit cards" /> */}
        </div>)
    }
}