import React from "react";
import { Tab, Tabs } from "react-bootstrap"
import { SupportTicketStatus } from "../Reports/ReportsList/ReportsList.models";
import SupportTicketList from "./SupportTicketList";
import { Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Note from "../../shared/Note/Note";
export default class SupportTickets extends React.PureComponent<{ globalTicketOnly: boolean, defaultActiveKey?: string | null }, {}> {
    render(): React.ReactNode {
        return (<div>
            {
                this.props.globalTicketOnly &&
                <h3><NavLink to="/create-support-ticket"><Button variant="outline-primary">Add New Ticket</Button></NavLink></h3>
            }
            <Tabs defaultActiveKey={this.props.defaultActiveKey === null ? 'unresponded' : this.props.defaultActiveKey} id="orders-tabs" className="mb-3" mountOnEnter={true} unmountOnExit={true}>
                <Tab eventKey="unresponded" title="Wait for me">
                    <SupportTicketList status={SupportTicketStatus.UserPending} globalTicketOnly={this.props.globalTicketOnly} />
                </Tab>
                <Tab eventKey="responded" title="Pending">
                    <SupportTicketList status={SupportTicketStatus.TargetPending} globalTicketOnly={this.props.globalTicketOnly} />
                </Tab>
                <Tab eventKey="closed" title="Closed">
                    <SupportTicketList status={SupportTicketStatus.Closed} globalTicketOnly={this.props.globalTicketOnly} />
                </Tab>
                <Tab eventKey="archive" title="Archive">
                    <SupportTicketList isActive={false} globalTicketOnly={this.props.globalTicketOnly} />
                </Tab>
                <Tab eventKey="all" title="All">
                    <SupportTicketList globalTicketOnly={this.props.globalTicketOnly} />
                </Tab>
            </Tabs>
        </div>)
    }
}