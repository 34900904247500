import { PackageStatusEnum } from "../../components/shared/policy/Enum/PackageStatusEnum";
import { mapToDataOnly } from "../common/HttpUtility";
import { ShopPolicyApiFactory } from "../../generated-api";

export default class ShopPolicyService {
    sendRequest(policyKey: string): Promise<{}> {
        return mapToDataOnly<{}>(ShopPolicyApiFactory().buyerShopPolicyPackageKeyPost(policyKey));
    }

    checkShopPackage(policyKey: string): Promise<{ status?: PackageStatusEnum, createDate?: Date }> {
        return mapToDataOnly<{ status?: PackageStatusEnum, createDate?: Date }>(ShopPolicyApiFactory().buyerShopPolicyStatusPackageKeyGet(policyKey));
    }
}
