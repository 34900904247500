import React from "react"
import './HorizontalCardView.scss'

export default class HorizontalCardView extends React.PureComponent<{ image: any, title: string, onclick?: () => void, subTitle?: string }, {}> {
    render(): React.ReactNode {
        const { image, title, subTitle } = this.props
        return <>
            <div className="d-flex align-items-center">
                <div className="card_img flex-1">
                    <img alt="" src={image} />
                </div>
                <div className="font-bold pt-4 fs-md flex-1">
                    <span>{title}</span>
                    <p>{subTitle}</p>
                </div>
            </div>
        </>
    }
}