import React, { RefObject } from "react";
import { Button, Form } from "react-bootstrap";
import InventoryService from "../../../services/inventory/InventoryService";
import { GridAction, GridColumn, GridColumnTypes } from "../../Grid/Grid.models";
import ChangeInventoryProductModal from "../ChangeInventoryProduct/ChangeInventoryProductModal";
import shopInventoryStatusDisplayBadge, { shopInventoryStatusDisplayCaption } from "../Enums/ShopInventoryStatusEnum";
import ShowCodeModal from "../InventoryInformations/ShowCodeModal";
import IState, { InventoryFilters, IProps } from "./InventoryList.models";
import { ORDER_INFORMATION } from "../../../constants/ConstantsPermissions";
import DynamicGrid from "../../shared/DynamicGrid/DynamicGrid";
import DownloadProductInventoryModal from "../DownloadProductInventory/DownloadProductInventoryModal";
import Note from "../../shared/Note/Note";

export default class InventoryList extends React.PureComponent<IProps, IState> {
    dynamicGrid: RefObject<DynamicGrid<InventoryFilters>>;
    inventoryService: InventoryService;
    constructor(props: IProps) {
        super(props);
        this.dynamicGrid = React.createRef();
        this.inventoryService = new InventoryService()
        this.state = { showDownloadModal: false, filters: { information: '' }, editFilters: { information: '' }, paginationQuery: { pageskip: 1, pagesize: 10 } };
    }

    showCodeHandler = (row: any) => {
        if (this.context.twoFactorAvtive) this.setState({ showCodeRowSelected: row })
        else this.setState({ showCodeModal: true, showCodeRowSelected: row })
        this.dynamicGrid.current?.resetSpinner()
    }


    render(): React.ReactNode {
        let gridColumns: GridColumn[] = [
            { title: 'Title', key: 'title' },
            { title: 'Information', key: 'information' },
            { title: 'Out By', key: 'orderNumber' },
            { title: 'Entry Price', key: 'buyPrice', type: GridColumnTypes.Amount },
            { title: 'Enter By', key: 'source' },
            { title: 'Status', key: 'statusBadge' },
            { title: 'Enter at', key: 'createAt', type: GridColumnTypes.Date },
            { title: 'Out at', key: 'soldAt', type: GridColumnTypes.Date },
            { title: 'Profit Amount', key: 'sellerProfitAmount', type: GridColumnTypes.Amount },
            { title: 'Out Price', key: 'sellerSoldAmount', type: GridColumnTypes.Amount },
        ];

        let gridActions: GridAction[] = [
            {
                caption: 'Show‌', permission: ORDER_INFORMATION, variant: 'success', onClick: (row) => {
                    this.showCodeHandler(row)
                }
            }, {
                caption: 'Change', variant: 'warning', onClick: (row) => {
                    this.setState({ changeStatusRowSelected: row })
                    this.dynamicGrid.current?.resetSpinner()
                }
            },
        ];
        return (<div>
            {
                this.props.defaultFilter.productId && <Button variant='outline-primary' className="mb-2" onClick={() => this.setState({ showDownloadModal: true })}>Download All</Button>
            }
            <DynamicGrid<InventoryFilters> ref={this.dynamicGrid}
                columns={gridColumns}
                actions={gridActions}
                onFetch={this.inventoryService.getPaged}
                filtersForm={this.filtersView}
                defaultFilters={this.props.defaultFilter}
                hiddenFilterkeys={["status"]}
                refactorRow={(row) => {
                    return {
                        ...row,
                        statusBadge: shopInventoryStatusDisplayBadge(row.status),
                        information: this.readableInformation(row.information),
                    }
                }} />

            {this.state.showCodeRowSelected && <ShowCodeModal closeModal={() => this.setState({ showCodeRowSelected: undefined })} inventoryShopId={this.state.showCodeRowSelected?.id as string} />}
            {this.state.changeStatusRowSelected &&
                <ChangeInventoryProductModal onSubmit={() => {
                    this.setState({ changeStatusRowSelected: undefined })
                    this.dynamicGrid.current?.fetch()
                }} hideDontShowOnly={this.props.hideDontShowOnlyinLockRow} closeModal={() => this.setState({ changeStatusRowSelected: undefined })} currentStatus={this.state.changeStatusRowSelected?.status} inventoryShopId={this.state.changeStatusRowSelected?.id as string} />
            }
            {(this.state.showDownloadModal) &&
                <DownloadProductInventoryModal productId={this.props.defaultFilter.productId} closeModal={() => this.setState({ showDownloadModal: false })} />}
        </div>)
    }

    filtersView = (filters: InventoryFilters, onChange: (newFilters: InventoryFilters) => void) => {
        return <>
            <Form.Group>
                <Form.Label>Information</Form.Label>
                <Form.Control value={filters.information} onChange={(e) => {
                    onChange({ ...filters, information: e.target.value })
                }} type="text" name="information" placeholder="Enter information" />
            </Form.Group>

            {/* <Form.Group>
                <label>Status</label>
                <Form.Select aria-label=">Select status"
                    id="status" value={filters.status}
                    onChange={(e) => onChange({ ...filters, status: Number(e.target.value) })}>
                    {Object.keys(ShopInventoryStatusEnum).map(key => typeof ShopInventoryStatusEnum[key as any] !== 'number' && (
                        <option value={key} key={key}>{ShopInventoryStatusEnum[key as any]}</option>
                    ))}
                    <option value="" selected>All</option>
                </Form.Select>
            </Form.Group> */}
        </>
    }

    readableInformation(information: string) {

        var fieldValues: string[] = JSON.parse(information);

        try {
            return <>
                {fieldValues.map(fieldValue => <div style={{
                    background: '#bbbbbb', padding: '5px', margin: '5px',
                    borderRadius: '5px', color: '#000000'
                }}>{fieldValue}</div>)}
            </>
        }
        catch {
            return <>{information}</>
        }
    }
}