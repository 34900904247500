import { useSearchParams } from "react-router-dom";
import HelpCenter from "../../components/helpCenter/HelpCenter/HelpCenter";
import ShopAuthenticated from "../../components/shared/ShopAuthenticated/ShopAuthenticated";
import { HELP_CENTER_MENU } from "../../constants/ConstantsPermissions";

export default function HelpCenterRoute() {
    const [searchParams] = useSearchParams()
    let activeTab = searchParams.get("activeTab")
    let categoryId = searchParams.get("categoryId");
    return <>
        <ShopAuthenticated showAccessDenied permission={HELP_CENTER_MENU}>
            <HelpCenter activeTab={activeTab} categoryId={categoryId} />
        </ShopAuthenticated>
    </>
}