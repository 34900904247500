import { GridResult } from "../../components/Grid/Grid.models";
import { IShopReportSupportTicketQueryString } from "../../components/helpCenter/Reports/ReportsList/ReportsList.models";
import { IShopInventoryTicketDTO } from "../../components/inventory/InventoriesList/InventoryList.models";
import { mapToDataOnly } from "../common/HttpUtility";
import { SelectionView } from "../common/Models";
import { SupportTicketsApiFactory } from "../../generated-api";

export default class SupportTicketsService {

    getGlobalTicket(query: IShopReportSupportTicketQueryString): Promise<GridResult<{
        createAt: string
        id: string
        memberDisplayName: string
        modifyAt: string
        status: number
        title: string,
        tags: string[]
    }>> {
        return mapToDataOnly<GridResult<any>>(SupportTicketsApiFactory().supportTicketsGet(query.isActive, query.status, query.ticketNumber, query.pageskip - 1, query.pagesize));
    }

    getById(id: string): Promise<SupportTicketDetailsDto> {
        return mapToDataOnly<SupportTicketDetailsDto>(SupportTicketsApiFactory().supportTicketsSupportTicketIdGet(id));
    }

    getDepartments(): Promise<DepartmentSelectionDTO[]> {
        return mapToDataOnly<DepartmentSelectionDTO[]>(SupportTicketsApiFactory().supportTicketsDepartmentsGet());
    }

    reply(supportTicketId: string, text: string, attachmentNames?: string[]): Promise<any> {
        return mapToDataOnly<any>((SupportTicketsApiFactory().supportTicketsSupportTicketIdPut(supportTicketId, { attachmentNames: attachmentNames, replyText: text, supportTicketId })));
    }

    create(title: string, text: string, departmentId: string, attachmentNames?: string[]): Promise<any> {
        return mapToDataOnly<any>(
            SupportTicketsApiFactory().supportTicketsPost({ attachmentNames: attachmentNames, departmentId, ticketText: text, title }));
    }
    createComment(supportTicketId: string, text: string): Promise<any> {
        return mapToDataOnly<any>(SupportTicketsApiFactory().supportTicketsSupportTicketIdCommentPost(supportTicketId, { commentText: text, supportTicketId }));
    }
    addVote(dto: IUpdateSupportTicketVoteDTO): Promise<any> {
        return mapToDataOnly<any>(SupportTicketsApiFactory().supportTicketsVotePut({ ...dto }))
    }
}

interface IUpdateSupportTicketVoteDTO {
    isGood: boolean;
    supportTicketId: string;
}

interface DepartmentSelectionDTO {
    value: string,
    title: string
}

interface SupportTicketDetailsDto {
    sellerId: string,
    returnReasonCode: 'INVALID_CODE' | 'DIFFERENT_VALUE' | 'ALREADY_REDEEMED' | 'DIFFERENT_REGION'
    createAt: Date
    department: {
        title: string
        value: string
    },
    handlerName: string
    isGood: boolean | null
    modifyAt: string
    replies: {
        createAt: string
        description: string
        userDisplayName: string
        shopId: string | null
    }[]
    status: number
    ticketNumber: string,
    title: string,
    orderProductId?: string | null,
    productTitle?: string,
    sellPrice?: number,
    inventory?: IShopInventoryTicketDTO,
    mediationRequestDate?: Date,
    canRequestMediation: boolean,
    isMediationDone?: boolean,
    tags: SelectionView[]
}