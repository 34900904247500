import { Route } from "react-router-dom";
import DefaultLayout from "../../components/layout/DefaultLayout";
import WebSiteLayout from "../../website/layout/DefaultLayout/DefaultLayout";
import AboutUs from "../../website/content/AboutUs/AboutUs";
import ContactUs from "../../website/content/ContactUs/ContactUs";
import PrivacyPolicy from "../../website/content/PrivacyPolicy/PrivacyPolicy";
import NotFoundRoute from "../shared/NotFoundRoute";
import IndexRoute from "../website/IndexRoute";
import Login from "../../components/identity/Login/Login";
import SignUp from "../../components/identity/SignUp/SignUp";
import RegisterConfirmed from "../../components/identity/RegisterConfirmed/RegisterConfirmed";
import ForgotPasswordRoute from "../identity/ForgotPasswordRoute";
import ResetPasswordRoute from "../identity/ResetPasswordRoute";

export default function BaseRoutes() {
    return (
        <>
            <Route element={<WebSiteLayout />}>
                <Route index element={<IndexRoute />}></Route>
                <Route path="/about-us" element={<AboutUs />}></Route>
                <Route path="/signUp" element={<SignUp />} />
                <Route path="/registerConfirmed" element={<RegisterConfirmed />} />
                <Route path="/forgotPassword" element={<ForgotPasswordRoute />} />
                <Route path="/reset-password" element={<ResetPasswordRoute />} />
                <Route path="/login" element={<Login />} />
                <Route path="/policy" element={<PrivacyPolicy />}></Route>
                <Route path="/contact-us" element={<ContactUs />}></Route>
            </Route>
            <Route element={<DefaultLayout />}>
                <Route path="*" element={<NotFoundRoute />} />
            </Route>
        </>
    )
}