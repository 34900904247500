import React, { RefObject } from "react"
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap"
import AdminWithdrawalsService from "../../../../../services/admin/financials/AdminWithdrawalsService"
import cashOutStatusDisplayBadge, { CashOutStatusEnum } from "../../../../financials/Enums/CashOutStatusEnum"
import Amount from "../../../../utilities/Amount/Amount"
import Moment from 'moment';
import { toast } from "react-toastify"

interface IState {
    isLoading: boolean,
    withdrawDetails?: {
        id: string
        amount: number
        handleAt: string | null
        requestAt: string
        status: CashOutStatusEnum
        shopTitle: string
        shopId: string
        description: string,
        transactionNumber: string,
        financialAccountType: string,
        financialAccount: string,
        handlerUserId: string,
        handlerName: string,
    }
}

export default class AdminWithdrawDetails extends React.PureComponent<{ id?: string, onClose: () => void, onSubmit: () => void }, IState> {
    adminWithdrawalsService: AdminWithdrawalsService
    constructor(props: any) {
        super(props);
        this.adminWithdrawalsService = new AdminWithdrawalsService()
        this.state = { isLoading: false }
    }
    componentDidMount() {
        if (this.props.id !== undefined)
            this.adminWithdrawalsService.getById(this.props.id).then((withdraw) => {
                this.setState({ withdrawDetails: withdraw })
            })

    }

    componentDidUpdate(prevProps: Readonly<{ id?: string; onClose: () => void; }>, prevState: Readonly<{}>, snapshot?: any): void {
        if (prevProps.id !== this.props.id && this.props.id !== undefined)
            this.adminWithdrawalsService.getById(this.props.id).then((withdraw) => {
                this.setState({ withdrawDetails: withdraw })
            })

    }

    done = () => {
        if (this.state.withdrawDetails.transactionNumber === undefined || this.state.withdrawDetails.transactionNumber.length === 0) {
            toast.error('Please enter transaction number');
            return;
        }
        this.setState({ isLoading: true })
        this.adminWithdrawalsService.done(this.props.id, this.state.withdrawDetails.transactionNumber).then(() => {
            this.props.onSubmit();
            this.close();
        }).finally(() => {
            this.setState({ isLoading: false })
        })
    }

    accept = () => {
        this.setState({ isLoading: true })
        this.adminWithdrawalsService.accept(this.props.id).then(() => {
            this.props.onSubmit();
            this.close();
        }).finally(() => {
            this.setState({ isLoading: false })
        })
    }

    reject = () => {
        this.setState({ isLoading: true })
        this.adminWithdrawalsService.reject(this.props.id).then(() => {
            this.props.onSubmit();
            this.close();
        }).finally(() => {
            this.setState({ isLoading: false })
        })
    }

    close = () => {
        this.props.onClose();
        this.setState({ withdrawDetails: undefined })
    }

    footerView = (status: CashOutStatusEnum) => {
        if (this.state.isLoading)
            return null;
        switch (status) {
            case CashOutStatusEnum.Done: case CashOutStatusEnum.Rejected:
                return <Button onClick={this.close} variant="light">Close</Button>
            case CashOutStatusEnum.Accepted:
                return <Button onClick={this.done} variant="success">Done</Button>
            case CashOutStatusEnum.Pending:
                return <>
                    <Button onClick={this.accept} variant="success" className="mr-1">Accept</Button>
                    <Button onClick={this.reject} variant="danger">Reject</Button>
                </>
        }
    }

    render(): React.ReactNode {
        return <Modal show={this.props.id !== undefined} onHide={this.close} size="lg">
            <Modal.Header>
                <Modal.Title>Withdraw Details
                    {this.state.withdrawDetails !== undefined && <> | {cashOutStatusDisplayBadge(this.state.withdrawDetails.status)}</>}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    this.state.withdrawDetails !== undefined && !this.state.isLoading ? (<>
                        <Row>
                            <Col>
                                <label>Shop: </label> <b>{this.state.withdrawDetails.shopTitle}</b>
                            </Col>
                            <Col>
                                <label>Amount: </label> <b>$<Amount value={this.state.withdrawDetails.amount} /></b>
                            </Col>
                            <Col>
                                <label>Request At: </label> <b title={(Moment(this.state.withdrawDetails.requestAt).utc().format('YYYY/MM/DD HH:mm')) + ' (UTC)'}>{Moment(this.state.withdrawDetails.requestAt).local().format('YYYY/MM/DD HH:mm')} </b>
                            </Col>
                        </Row>
                        {
                            (this.state.withdrawDetails.status === CashOutStatusEnum.Accepted || this.state.withdrawDetails.status === CashOutStatusEnum.Done)
                            && <>
                                <Row>
                                    <Col xs="4">
                                        <label>Network: </label> <b>{this.state.withdrawDetails.financialAccountType}</b>
                                    </Col>
                                    <Col xs="8">
                                        <label>Address: </label> <b>{this.state.withdrawDetails.financialAccount}</b>
                                    </Col>
                                </Row>
                                {
                                    this.state.withdrawDetails.status === CashOutStatusEnum.Done && <Row>
                                        <Col>
                                            <label>Transaction Number: </label> <b>{this.state.withdrawDetails.transactionNumber}</b>
                                        </Col>
                                    </Row>
                                }

                                <Row>
                                    <Col>
                                        <label>Description: </label> <b>{this.state.withdrawDetails.description}</b>
                                    </Col>
                                </Row>
                            </>
                        }

                    </>) : <Spinner animation="grow" />
                }
            </Modal.Body>
            <Modal.Footer style={{ flexWrap: 'unset' }}>
                {this.state.withdrawDetails !== undefined && <>
                    {
                        this.state.withdrawDetails.status === CashOutStatusEnum.Accepted &&
                        <Form.Group style={{ width: '80%' }}>
                            <Form.Control type="text" placeholder="Enter transaction number"
                                value={this.state.withdrawDetails.transactionNumber}
                                onChange={(e) => this.setState({ withdrawDetails: { ...this.state.withdrawDetails, transactionNumber: e.target.value } })} />
                        </Form.Group>
                    }


                    <span style={{ width: '20%', textAlign: 'right' }}>
                        {
                            this.footerView(this.state.withdrawDetails.status)
                        }
                    </span>
                </>}

            </Modal.Footer>
        </Modal>
    }
}