import React from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap'
import IState, { IProps, IUserRoleView } from './AdminUserRoleAssignmentModal.models';
import AdminUserService from '../../../../services/admin/users/AdminUserService';
import { toast } from 'react-toastify';

export default class AdminUserRoleEditModal extends React.Component<IProps, IState> {
    userService: AdminUserService

    constructor(props: any) {
        super(props)
        this.userService = new AdminUserService()
        this.state = { pendingRequest: false, loading: false, userRoles: [] }
    }

    componentDidMount(): void {
        this.setState({ loading: true })

        this.userService.getUserRoles(this.props.userId).then(response => {
            this.setState({ userRoles: response })
        }).finally(() => this.setState({ loading: false }))
    }

    submit = () => {
        this.setState({ pendingRequest: true })

        var selectesUserRoleKeys = this.state.userRoles.filter(userRole => userRole.hasRole == true).map(userRole => userRole.key)
        this.userService.editUserRoles(this.props.userId, selectesUserRoleKeys).then((response) => {
            toast.success('Role assignment was successfully')
            this.props.onClose()
        }).finally(() => this.setState({ pendingRequest: false }))
    }

    toggleSelection = (key: string) => {
        let tmpUserRoles: IUserRoleView[] = this.state.userRoles
        tmpUserRoles.filter((item) => item.key === key).map((role) => {
            return role.hasRole = !role.hasRole
        })

        this.setState({ userRoles: tmpUserRoles })
        this.forceUpdate()
    }

    render(): React.ReactNode {
        const { } = this.state

        return <>
            <Modal
                show={true}
                backdrop="static"
                onHide={this.props.onClose}
            >
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>Role Assignment</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            {
                                this.state.loading ? <Spinner animation={'grow'} /> :
                                    this.state.userRoles.map((userRole, index) => {
                                        return <Col md="12" sm="12" key={index}>
                                            <Form.Check className='mt-4' type="checkbox" label={userRole.title}
                                                checked={userRole.hasRole}
                                                onChange={(e) => this.toggleSelection(userRole.key)} />
                                        </Col>
                                    })
                            }
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        {this.state.pendingRequest ? <Button variant='success' disabled><Spinner animation='border' size='sm' /></Button> :
                            <Button variant='success' onClick={this.submit}>Confirm</Button>}
                    </Modal.Footer>
                </Form>
            </Modal >
        </>
    }
}