import React from "react";
import { Form, Pagination as PaginationBootstrap } from "react-bootstrap";
import PaginationState, { PaginationProps } from "./Pagination.models";
import './Pagination.scss'
export default class Pagination extends React.Component<PaginationProps, PaginationState> {
    /**
     *
     */
    constructor(props: PaginationProps) {
        super(props);

        this.state = { pagesize: this.props.pagesize ?? 10 };
    }
    componentDidMount = () => {
        this.chnagePage(1);
    }

    componentDidUpdate() {
    }


    chnagePage = (page: number) => {
        this.props.onChangePageNumber(page, this.state.pagesize);
    }

    chnageSize = (size: number) => {
        this.setState({ pagesize: size });
        this.props.onChangePageSize(size);
    }

    pageSizeView() {
        let sizes = [10, 20, 50, 100];
        return <Form.Select value={this.state.pagesize} onChange={(e) => this.chnageSize(parseInt(e.target.value))}>
            {sizes.map((size) => (<option value={size} key={size}>{size}</option>))}
        </Form.Select>
    }

    paginationView() {
        let totalPage = Math.ceil(this.props.totalRows / this.state.pagesize);

        if (totalPage < 8) {
            let pages = [];

            for (let i = 1; i <= totalPage; i++)
                pages.push(<PaginationBootstrap.Item onClick={() => this.chnagePage(i)} active={this.props.pageNumber == i} key={i}>{i}</PaginationBootstrap.Item>);

            return <PaginationBootstrap size="sm">{pages}</PaginationBootstrap>
        }
        else {
            let currentPage = this.props.pageNumber;
            return (<PaginationBootstrap>
                <PaginationBootstrap.First onClick={() => this.chnagePage(1)} disabled={currentPage == 1} />
                <PaginationBootstrap.Prev onClick={() => this.chnagePage(currentPage - 1)} disabled={currentPage == 1} />
                {currentPage !== 1 && currentPage !== 2 ? <PaginationBootstrap.Item onClick={() => this.chnagePage(currentPage - 2)}>{currentPage - 2}</PaginationBootstrap.Item> : null}
                {currentPage !== 1 ? <PaginationBootstrap.Item onClick={() => this.chnagePage(currentPage - 1)}>{currentPage - 1}</PaginationBootstrap.Item> : null}

                <PaginationBootstrap.Item active>{currentPage}</PaginationBootstrap.Item>

                {currentPage !== totalPage ? <PaginationBootstrap.Item onClick={() => this.chnagePage(currentPage + 1)}>{currentPage + 1}</PaginationBootstrap.Item> : null}
                {currentPage !== totalPage && currentPage !== totalPage - 1 ? <PaginationBootstrap.Item onClick={() => this.chnagePage(currentPage + 2)}>{currentPage + 2}</PaginationBootstrap.Item> : null}




                <PaginationBootstrap.Next onClick={() => this.chnagePage(currentPage + 1)} disabled={currentPage == totalPage} />
                <PaginationBootstrap.Last onClick={() => this.chnagePage(totalPage)} disabled={currentPage == totalPage} />
            </PaginationBootstrap>);

        }


        return
    }

    render(): React.ReactNode {
        return (<div className="page-options">
            <div className="pagination">
                {this.paginationView()}
            </div>
            <div className="page-size">
                {this.pageSizeView()}
            </div>
        </div>);
    }
}