import React, { Component } from "react";

import { Placeholder } from "react-bootstrap";

import './CategorySidebar.scss';
import LayoutContext from "../../../../contexts/LayoutContext/LayoutContext";
import MenuItem from "./MenuItem/MenuItem";
import { articleCategories } from "../../../../services/helpCenter/ArticleService";

export interface SidebarItemView {
    title: string;
    parentId?: string;
    id: string;
    isActive: boolean;
    subMenu: SidebarItemView[];

}

export interface IState {
    menuItems: SidebarItemView[],
}

export default class CategorySidebar extends Component<{ categories: articleCategories[], onNavigate?: () => void, fixMargin?: boolean, loading: boolean }, IState> {
    static contextType = LayoutContext;
    context!: React.ContextType<typeof LayoutContext>;
    constructor(props: any) {
        super(props);
        this.state = { menuItems: [] };
    }

    componentDidUpdate(prevProps: Readonly<{ categories: articleCategories[]; onNavigate?: () => void; fixMargin?: boolean; }>, prevState: Readonly<IState>, snapshot?: any): void {
        if (prevProps.categories != this.props.categories)
            this.menuInitial(this.props.categories)

    }

    componentDidMount() {
        this.menuInitial(this.props.categories)
    }

    menuInitial = (categories: any[]) => {
        let context = this;
        let menuItems = categories.map((category) => {
            return {
                id: category.id,
                title: category.title,
                parentId: category.parentId,
                isActive: false,
                subMenu: [],
            } as SidebarItemView;
        });

        let masterMenus = menuItems.filter(item => item.parentId === null)
        masterMenus.map(masterMenu => context.setSubMenu(masterMenu, menuItems));
        masterMenus.map(masterMenu => masterMenu.subMenu.map(subMenu => context.setSubMenu(subMenu, menuItems)));

        context.setState({ menuItems: masterMenus })
    }

    setSubMenu(master: SidebarItemView, allMenuItems: SidebarItemView[]) {
        master.subMenu = allMenuItems.filter(menuItem => menuItem.parentId === master.id);
    }

    handleClickMenuItem = (id: string) => {
        this.closeAll(id);
        this.setState(prevState => {
            return {
                menuItems: prevState.menuItems
                    .map(item => { return item.id === id ? { ...item, isActive: !item.isActive } : item })
            }
        });
    }

    closeAll = (exceptedId: string) => {
        this.setState(prevState => {
            return {
                menuItems: prevState.menuItems
                    .map(item => { return item.id === exceptedId ? item : { ...item, isActive: false } })
            }
        });
    }

    render(): React.ReactNode {
        return <div className={"CategorySidebar " + (this.props.fixMargin === true && "fix-margin")}>
            {
                this.props.loading ? ([...Array(11)].map((e, i) => <Placeholder animation="glow" key={i}>
                    <Placeholder style={{ height: '5vh', borderRadius: '8px', width: '100%', marginTop: '10px' }} />
                </Placeholder>)) :
                    this.state.menuItems.map((category) => {
                        return (<MenuItem
                            key={category.id} title={category.title} id={category.id} onNavigate={this.props.onNavigate}
                            subItems={category.subMenu} isOpened={category.isActive} onClick={() => this.handleClickMenuItem(category.id)} />);
                    })
            }
        </div >;
    }
}