import React from "react";
import { Card, Col, Row, } from "react-bootstrap";
import HubridMoment from "../../../utilities/HybridMoment";

interface IProps {
    headerTitle?: string
    createAt?: string
    description?: string
    headerChildren?: any
    footerChildren?: any
    actionChildren?: any
    float?: boolean
    width?: string
    attachmentPaths?: string[] | null
}

export default class TicketCardView extends React.PureComponent<IProps, {}> {
    render(): React.ReactNode {
        const { description, createAt, headerTitle, headerChildren, children, footerChildren, actionChildren, float, width, attachmentPaths } = this.props
        const note = headerTitle == 'Comments' ? <span style={{ color: '#a5a5a5', fontSize: '12px' }}>Comments are private and only visible to you and members you have allowed</span> : null
        return <div>
            {
                <Card className="mt-1" style={{ float: float === true ? "left" : float === false ? "right" : "none", width: width ?? "100%" }}>
                    <Card.Header>
                        <Row>
                            {headerTitle && <Col md="6" sm="6">{headerTitle}</Col>}
                            {createAt && <Col md="6" sm="6" style={{ textAlign: 'end' }}>{HubridMoment(createAt)}</Col>}
                            {headerChildren && <Col style={{ textAlign: 'end' }}>{headerChildren()}</Col>}
                        </Row>
                        {note}
                        {
                            (footerChildren) && (
                                <div className="pt-1">
                                    {footerChildren} {actionChildren}
                                </div>
                            )
                        }
                    </Card.Header>
                    <Card.Body>
                        {description && <>
                            <div dangerouslySetInnerHTML={{ __html: description }}></div>
                            {
                                attachmentPaths && attachmentPaths.length > 0 &&
                                <div className="mt-2 zoom-img">
                                    {
                                        attachmentPaths.map((attachmentPath, index) => {
                                            return <div className="mt-1 me-1">
                                                <input type="checkbox" id={attachmentPath} />
                                                <label htmlFor={attachmentPath}>
                                                    <div>
                                                        <img className="attachment-style" src={attachmentPath} />
                                                    </div>
                                                </label>
                                            </div>
                                        })
                                    }
                                </div>
                            }
                        </>}
                        {children}
                    </Card.Body>
                </Card>
            }
            <div style={{ clear: "both" }}></div>
        </div>
    }
}
