import { InventoryShopBasePlanStatusEnum } from "../../components/inventory/Enums/InventoryShopBasePlanStatusEnum";
import { mapToDataOnly } from "../common/HttpUtility";
import { OfferStatusEnum } from "../../components/orders/offers/OfferStatusEnum";
import { PaginationQuery } from "../../components/Grid/Pagination/Pagination.models";
import { GridResult } from "../../components/Grid/Grid.models";
import { OfferApiFactory } from "../../generated-api";

export default class OffersService {
    getActiveOffers(categoryId?: string): Promise<GridResult<OwnedOfferDto>> {
        if (categoryId)
            return mapToDataOnly<GridResult<OwnedOfferDto>>(OfferApiFactory().offerGet([0], 0, 1000, undefined, categoryId));
        else
            return mapToDataOnly<GridResult<OwnedOfferDto>>(OfferApiFactory().offerGet([0], 0, 1000));
    }

    get(filters: any, paginationQuery: PaginationQuery): Promise<GridResult<OwnedOfferDto>> {
        return mapToDataOnly<GridResult<OwnedOfferDto>>(OfferApiFactory().offerGet([0, 1, 2, 3], paginationQuery.pageskip - 1, paginationQuery.pagesize, filters?.productId, filters?.productCategoryId));
    }

    getBuyOffers(categoryId?: string): Promise<OfferRowDto[]> {
        if (categoryId)
            return mapToDataOnly<OfferRowDto[]>(OfferApiFactory().offerBuyGet(categoryId));
        else
            return mapToDataOnly<OfferRowDto[]>(OfferApiFactory().offerBuyGet());
    }

    getBuyOffersPagination(filters: any, paginationQuery: PaginationQuery): Promise<GridResult<OfferRowDto>> {
        return mapToDataOnly<GridResult<OfferRowDto>>(OfferApiFactory().offerBuyPaginationGet(paginationQuery.pageskip - 1, paginationQuery.pagesize, filters.categoryId));
    }

    create(dto: CreateOfferDto): Promise<any> {
        return OfferApiFactory().offerPost({ ...dto });
    }

    cancel(id: string): Promise<any> {
        return OfferApiFactory().offerCancelIdDelete(id);
    }
}

export interface IGetBuyOffersQueryString extends PaginationQuery { categoryId?: string }

export interface CreateOfferDto {
    productId: string,
    quantity: number,
    price: number,
    addToInventory: boolean
}

export interface OfferRowDto {
    productId?: string,
    productTitle?: string,
    quantity: number,
    inCommingCount: number,
    sellerBlockCount: number,
    buyerBlockCount: number,
    orderingPlanStatus: InventoryShopBasePlanStatusEnum;
    availableInventory: number,
    outOfPriceRangeCount: number,
}

export interface OwnedOfferDto {
    id: string,
    productId?: string,
    expireAt?: string,
    closedAt?: string,
    quantity: number,
    acceptedCount: number,
    price: number,
    isFixed: boolean,
    status: OfferStatusEnum,
    productTitle: string
}