import React, { RefObject } from "react"
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone, { IFileWithMeta } from 'react-dropzone-uploader'
import './Uploader.scss'
import { CloudArrowUpFill } from "react-bootstrap-icons"
import CommonService from "../../../services/common/CommonService"
import { CloseButton, Spinner } from "react-bootstrap"

export default class Uploader extends React.Component<{ onRemovd?: (filename: string) => void, onUpload?: (filename?: string) => void, folderName: string, imageAddress: string, multiple?: boolean },
    { files: { data: File, loading: boolean, url?: string, id: string, filename?: string }[], defaultImage: string }> {
    dropzone: RefObject<Dropzone>
    commonService: CommonService
    constructor(props: any) {
        super(props)
        this.dropzone = React.createRef()
        this.commonService = new CommonService()
        this.state = { files: [], defaultImage: '' }
    }

    componentDidMount() {
        this.setState({ defaultImage: this.props.imageAddress })
    }

    callUploadService = (file: File, fileId: string): Promise<any> => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('fileName', file.name as string);

        return this.commonService.upload(formData, this.props.folderName).then(res => {
            this.onUpload(res.fullPath, fileId)
        })
    }

    onUpload = (fullPath: string, fileId: string) => {
        this.props.onUpload(fullPath);
        let files = this.state.files
        let index = files.map(({ id }) => id).indexOf(fileId)
        files[index].loading = false
        files[index].filename = fullPath
        this.setState({ files })
        this.forceUpdate();
    }

    handleChangeStatus = (data: IFileWithMeta, status) => {
        let files = [...this.state.files]
        const { meta } = data

        if (status == 'done') {
            files.push({ loading: true, data: data.file, url: meta.previewUrl, id: meta.id })
            this.setState({ files }, () => {
                this.callUploadService(data.file, meta.id)
            })
        } else if (status == 'removed')
            this.props.multiple ? this.props.onRemovd(files.filter(file => file.id == meta.id)[0].filename) : this.props.onUpload(undefined);
    }

    cancel = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ defaultImage: null })
        this.props.onUpload(null);
    }

    render(): React.ReactNode {
        const { files } = this.state

        return <>
            <Dropzone
                multiple={this.props.multiple ?? false}
                accept="image/jpeg,image/png"
                PreviewComponent={(this.props.multiple && files.filter(file => file.loading).length == files.length) && this.preview}
                autoUpload={true}
                onChangeStatus={this.handleChangeStatus}
                inputContent={
                    <>
                        {this.state.defaultImage
                            ? <div className="defaultImage-box"><img width={100} src={this.state.defaultImage} /><CloseButton onClick={(e) => this.cancel(e)} /></div>
                            : <><CloudArrowUpFill fontSize={'30px'} className="m-2" /> Drag and drop or browse to choose a file</>
                        }
                    </>}
            />
        </>
    }

    preview = ({ meta }) => {
        const { files } = this.state

        return (
            <>
                {
                    (files.length >= 1 && meta.status == 'done' && !files.filter(file => file.id == meta.id)[0].loading) ?
                        <div className="dzu-previewContainer">
                            <img className="dzu-previewImage"
                                src={meta.previewUrl}
                                alt={meta.name} title={meta.name} />
                        </div>
                        :
                        <span style={{ alignSelf: 'flex-start', margin: '10px 3%', fontFamily: 'Helvetica' }}>
                            <Spinner animation="border" size="sm" />
                        </span>
                }
            </>
        )
    }
}