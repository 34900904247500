import ApexCharts from "react-apexcharts";
import { ApexOptions } from "apexcharts";

interface IProps {
    dataSeries: any;
    categories: any[];
    colors?: any[];
}

export function Chart(props: IProps) {
    const chartOptions: ApexOptions = {
        chart: {
            type: 'line',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight',
            width: [4, 0, 0]
        },
        grid: {
            show: false
        }, xaxis: {
            categories: props.categories
        },
        colors: props.colors ?? ['#42ab93'],
        markers: {
            size: 3,
            strokeWidth: 1,
            fillOpacity: 0,
            strokeOpacity: 0,
            hover: {
                size: 6
            },
        }
    };

    return (
        <ApexCharts
            options={chartOptions}
            series={props.dataSeries}
            height={300}
        />
    );
}
