import { GridResult } from "../../components/Grid/Grid.models";
import { PaginationQuery } from "../../components/Grid/Pagination/Pagination.models";
import { mapToDataOnly } from "../common/HttpUtility";
import { InventoryShopCollectionFilters } from "../../components/inventory/InventoryShopCollection/InventoryShopCollectionList";
import { InventoryShopCollectionApiFactory } from "../../generated-api";

export default class InventoryShopCollectionService {
    getPaged(filters: InventoryShopCollectionFilters, paginationQuery: PaginationQuery): Promise<GridResult<InventoryShopCollectionRowDTO>> {
        return mapToDataOnly<GridResult<InventoryShopCollectionRowDTO>>(InventoryShopCollectionApiFactory()
            .sellerInventoryShopCollectionGet(filters.orderNumber, paginationQuery.pageskip - 1, paginationQuery.pagesize))
    }

    updateDeficitCount(dto: UpdateDeficitDTO): Promise<any> {
        return mapToDataOnly<any>(InventoryShopCollectionApiFactory().sellerInventoryShopCollectionChangeDeficitPut(dto));
    }

    getDeficits(filters: IDeficitsPaginationFilters, paginationQuery: PaginationQuery): Promise<GridResult<InventoryShopCollectionRowDTO>> {
        return mapToDataOnly<any>(InventoryShopCollectionApiFactory().sellerInventoryShopCollectionDeficitsGet(filters.productId))
    }

    getDeficitCount(): Promise<number> {
        return mapToDataOnly<any>(InventoryShopCollectionApiFactory().sellerInventoryShopCollectionDeficitsCountGet());
    }
}

export interface IDeficitsPaginationFilters {
    productId?: string
}

export interface IDeficitRowDTO {
    productId: string;
    title: string;
    count: number;
    sourceTitle: string;
    orderNumber: string;
    date: string;
}

interface InventoryShopCollectionRowDTO {
    id: string;
    buyAt: string;
    productTitle: string;
    sourceTitle: string;
    price: number;
    count: number;
    buyOrderNumber: string;
    deficitCount: number;
}

interface UpdateDeficitDTO {
    shopCollectionId: string;
    deficitCount: number;
}