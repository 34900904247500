import React, { FormEvent } from "react";
import IState, { IProps } from "./EmailVerification.models";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import AuthenticationContext from "../../../contexts/AuthenticationContext/AuthenticationContext";
import IdentityService from "../../../services/identity/IdentityService";
import { toast } from "react-toastify";

export default class EmailConfirmation extends React.Component<IProps, IState> {
    identityService: IdentityService
    hostUrl: string;

    constructor(props: IProps) {
        super(props);
        this.state = { email: '', pendingRequest: false, loading: false }
        this.identityService = new IdentityService()
        this.hostUrl = window.location.protocol + "//" + window.location.host;
    }

    componentDidMount(): void {
        this.fetch()
    }

    fetch = () => {
        this.setState({ loading: true })
        this.identityService.getUserInformationComplete()
            .then((response) => this.setState({ email: response.email }))
            .finally(() => this.setState({ loading: false }))
    }

    submit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        this.setState({ pendingRequest: true })
        this.identityService.confirmationEmail(this.state.email, `${this.hostUrl}/registerConfirmed`)
            .then(() => { toast.success('Email confirmation message sent, check your email.') })
            .finally(() => this.setState({ pendingRequest: false }))
    }

    render(): React.ReactNode {
        let { email, pendingRequest, loading } = this.state
        let { isEmailConfirmed } = this.props

        return (
            <div className="p-3 mt-3">
                <h3>Email Verification
                    {!loading && <label className={!isEmailConfirmed ? 'text-danger p-1' : 'text-success p-1'}>{isEmailConfirmed ? '(Verified)' : '(Not Verified)'}</label>}
                </h3>
                <Form onSubmit={this.submit}>
                    {
                        loading ? <Spinner animation="grow" /> :
                            <Row>
                                <Col md="4" sm="12">
                                    <Form.Label>Email Address:</Form.Label>
                                    <Form.Control readOnly={isEmailConfirmed} type="email" placeholder="Enter your email"
                                        value={email}
                                        onChange={e => this.setState({ email: e.target.value })} />
                                </Col>
                                {
                                    !isEmailConfirmed &&
                                    <Col md="12" sm="12">
                                        {pendingRequest ? <Button variant="submit" disabled>
                                            <Spinner animation="border" size="sm" /> </Button> :
                                            <Button variant='success' className="mt-2" type="submit">Confirm</Button>}
                                    </Col>
                                }
                            </Row>
                    }
                </Form>
            </div >
        )
    }
}