import { Route } from "react-router-dom";
import DefaultLayout from "../../components/layout/DefaultLayout";
import StoreRoute from "../shopping/StoreRoute";

export function StoreRoutes() {
    return (
        <>
            <Route element={<DefaultLayout fullContent />}>
                <Route path="/marketplace" element={<StoreRoute />} />
            </Route>
        </>
    )
}