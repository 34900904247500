import React, { RefObject } from "react";
import { Button, Col, Form, Modal, Row, Spinner, } from "react-bootstrap";
import TwoFactorService from "../../../../../services/identity/TwoFactorService";
import IRecoveryCodeState, { IRecoveryCodeProps } from "./RecoveryCode.models";
import { toast } from "react-toastify";
import { LockFill } from "react-bootstrap-icons";

export default class RecoveryCodeModal extends React.PureComponent<IRecoveryCodeProps, IRecoveryCodeState> {
    twoFactorService: TwoFactorService
    input: RefObject<HTMLInputElement>
    constructor(props: IRecoveryCodeProps) {
        super(props)
        this.state = { pendingRequest: false, totp: '', checkedDisableTwoFactor: false }
        this.twoFactorService = new TwoFactorService()
        this.input = React.createRef()
    }

    focusToInput = () => {
        this.input.current?.focus();
    }

    submit = () => {
        if (this.state.totp === '') return toast.error("Please enter your TOTP")

        this.setState({pendingRequest: true})
        this.props.onSubmit(this.state.totp, this.state.checkedDisableTwoFactor)
    }

    resetSpinner = () => {
        this.setState({pendingRequest: false})
    }

    onKeyDown = (event: React.KeyboardEvent<any>): void => {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            this.submit();
        }
    }

    render(): React.ReactNode {
        const { pendingRequest, checkedDisableTwoFactor } = this.state
        const { showModal, closeModal, showDisableTwoFactor } = this.props

        return (
            <Modal
                size="sm"
                show={showModal}
                onHide={closeModal}
                onShow={this.focusToInput}
                className="recovery-code"
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Recovery-code
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row style={{ textAlign: 'center' }}>
                        <Col className="p-3"><LockFill size="60px" /></Col>
                        <label>Enter one of your recovery codes that were provided to you once two-factor activated.</label>
                    </Row>
                    <Form>
                        <Form.Group className="mt-4" controlId="recovery-code">
                            <Form.Label>Recovery code</Form.Label>
                            <Form.Control type="text" placeholder="Type code..." autoComplete="off" ref={this.input} onKeyDown={(e) => this.onKeyDown(e)}
                                value={this.state.totp} onChange={(e) => this.setState({ totp: e.target.value })} />
                        </Form.Group>
                        {
                            showDisableTwoFactor && <Form.Check label="Disable two factor" checked={checkedDisableTwoFactor} onChange={() => this.setState({ checkedDisableTwoFactor: !checkedDisableTwoFactor })} type="checkbox" />
                        }
                    </Form>
                </Modal.Body>
                <Modal.Footer className="footer-reverse">
                    {
                        pendingRequest ? <Button disabled variant="success">
                            <Spinner animation="border" size="sm"/>
                        </Button> : <Button type="submit" variant="success" onClick={() => this.submit()}>Recovery</Button>
                    }
                </Modal.Footer>
            </Modal>
        )
    }
}