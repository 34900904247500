import { Route } from "react-router-dom";
import DefaultLayout from "../../components/layout/DefaultLayout";
import FinancialManagementRoute from "../financials/FinancialManagement/FinancialManagementRoute";
import FinancialsRoute from "../financials/FinancialsRoute";
import WalletChargeRoute from "../financials/WalletChargeRoute";
import WalletTransactionListRoute from "../financials/WalletTransactionListRoute";

export default function FinancialRoutes() {
    return (
        <>
            <Route element={<DefaultLayout />}>
                <Route path="/financials" element={<FinancialsRoute />} />
                <Route path="/walletTransaction" element={<WalletTransactionListRoute />} />
                <Route path="/deposit" element={<WalletChargeRoute />} />
                <Route path="/financial-management" element={<FinancialManagementRoute />} />
            </Route>
        </>
    )
}