import React from "react";
import { Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import AdminWalletsServices from "../../../../../../services/admin/financials/AdminWalletsServices";
import IState, { IProps } from "./AdminWalletTransactionDetails.models";
import Amount from "../../../../../utilities/Amount/Amount";
import HubridMoment from "../../../../../utilities/HybridMoment";
import walletTransactionStatusDisplayBadge from "../../Enums/WalletTransactionStatusEnum";

export default class AdminWalletTransactionDetails extends React.Component<IProps, IState> {
    adminWalletsServices: AdminWalletsServices
    constructor(props: any) {
        super(props);
        this.state = { loading: false }
        this.adminWalletsServices = new AdminWalletsServices()
    }

    componentDidMount(): void {
        this.setState({ loading: true })

        this.adminWalletsServices.getTransactionById(this.props.transactionId).then(response => {
            this.setState({ details: response })

        }).finally(() => this.setState({ loading: false }))
    }

    render(): React.ReactNode {
        let { onClose } = this.props
        let { loading, details } = this.state

        return <Modal size="lg" show={true} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Transaction Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading || details == undefined ? <Spinner animation='grow' /> :
                    <>
                        <Row>
                            <Col className="mt-3" md="6" sm="12">
                                <span>Create Date: <b>{details.createAt && HubridMoment(details.createAt)}</b></span>
                            </Col>
                            <Col className="mt-3" md="6" sm="12">
                                <span>Status: <b>{walletTransactionStatusDisplayBadge(details.status)}</b></span>
                            </Col>
                            <Col className="mt-3" md="6" sm="12">
                                <span>Source Amount: <b><Amount value={details.sourceAmount} />$</b></span>
                            </Col>
                            <Col className="mt-3" md="6" sm="12">
                                <span>Destination Amount: <b><Amount value={details.destinationAmount} />$</b></span>
                            </Col>
                            <Col className="mt-3" md="6" sm="12">
                                <span>Source Wallet: <b>{details.fromShopTitle}</b></span>
                            </Col>
                            <Col className="mt-3" md="6" sm="12">
                                <span>Destination Wallet: <b>{details.toShopTitle}</b></span>
                            </Col>
                            <Col className="mt-3" md="6" sm="12">
                                <span>Member: <b>{details.memberName}</b></span>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mt-3" md="6" sm="12">
                                <span>Acceptor User: <b>{details.acceptorUser}</b></span>
                            </Col>
                            <Col className="mt-3" md="12" sm="12">
                                <span>Acceptor Description: <b>{details.acceptDescription}</b></span>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mt-3" md="12" sm="12">
                                <span>Source Wallet Description: <b>{details.fromWalletDescription}</b></span>
                            </Col>
                            <Col className="mt-3" md="12" sm="12">
                                <span>Destination Wallet Description: <b>{details.toWalletDescription}</b></span>
                            </Col>
                        </Row>
                    </>

                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={() => this.props.onClose()}>Close</Button>
            </Modal.Footer>
        </Modal>
    }
}