import React, { FormEvent } from 'react';
import CashOutsService from '../../../../services/financials/CashOutsService'
import authenticationContext from "../../../../contexts/AuthenticationContext/AuthenticationContext";
import FinancialAccountService from '../../../../services/financials/FinancialAccountService'
import WalletService from '../../../../services/financials/WalletService'
import InventorySourceService from '../../../../services/inventory/InventorySourceService'
import IState, { IProps } from './WalletTransferFund.models'
import TransferFund from '../../../TransferFund/TransferFund';

export default class WalletTransferFund extends React.Component<IProps, IState> {
    financialAccountService: FinancialAccountService
    inventorySourceService: InventorySourceService
    walletService: WalletService
    cashOutsService: CashOutsService
    static contextType = authenticationContext;
    context!: React.ContextType<typeof authenticationContext>;
    constructor(props: any) {
        super(props)
        this.financialAccountService = new FinancialAccountService()
        this.inventorySourceService = new InventorySourceService()
        this.cashOutsService = new CashOutsService()
        this.walletService = new WalletService()
        this.state = {
            isLoading: false,
            walletBalance: 0,
            blockedBalance: 0,
            activeOTPMode: false,
            transactionId: '',
            showReceipt: false
        }
    }

    componentDidMount() {
        this.walletService.getBalance(this.props.walletId).then((walletBalance) => {
            this.setState({ walletBalance: walletBalance.balance, blockedBalance: walletBalance.blockedBalance })
        })
    }

    submit = (destinationAddress: string, amount: number, description: string, isAddDestinationToFavorites: boolean) => {
        this.setState({ isLoading: true, activeOTPMode: false })

        this.walletService.transferFund({
            amount,
            description,
            destinationWalletId: destinationAddress,
            sourceWalletId: this.props.walletId,
            addDestinationToFavorites: isAddDestinationToFavorites
        }).then((response) => {
            this.setState({ isLoading: false, showReceipt: true, transactionId: response.transactionId });
            this.props.reload()
        })
    }

    render(): React.ReactNode {
        return <>
            <TransferFund
                isLoading={this.state.isLoading}
                showReceipt={this.state.showReceipt}
                walletBlockedBalance={this.state.blockedBalance}
                walletBalance={this.state.walletBalance}
                walletId={this.props.walletId}
                transactionId={this.state.transactionId}
                onClose={this.props.onClose} submit={this.submit}
            />
        </>
    }
}
