import React, { FormEvent } from "react";
import IUserProfileState, { IUserProfileProps } from './UserProfile.models'
import { Button, Spinner, Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import IdentityService from "../../../services/identity/IdentityService";

export default class UserProfile extends React.PureComponent<IUserProfileProps, IUserProfileState> {
    identityService: IdentityService;
    constructor(props: IUserProfileProps) {
        super(props);
        this.identityService = new IdentityService()
        this.state = { loading: false, isLoading: false, userInformation: { id: '', lastName: '', firstName: '', email: ''}};
    }

    componentDidMount() {
        this.getInfo()
    }


    getInfo() {
        this.setState({loading: true})
        this.identityService.getUserInformationComplete().then((response) => {
            this.setState({
                userInformation: { email: response.email, id: response.id, firstName: response.firstName, lastName: response.lastName },
                loading: false
            })
        })
    }

    submit = (e: FormEvent<HTMLFormElement>) => {
        const { userInformation } = this.state;
        e.preventDefault();
        e.stopPropagation();

        if (userInformation.firstName.length === 0 || userInformation.lastName.length === 0) {
            toast.error('All fields are required.');
            return;
        }

        this.setState({isLoading: true})
        this.identityService.updateUserInformation({ firstName: userInformation.firstName, lastName: userInformation.lastName }).then(() => {
            toast.success('Your information changed successfully.');
        }).finally(() => {
            this.setState({ isLoading: false });
        }).catch(() => {
            toast.error('An error has occurred.');
        })
    }

    render(): React.ReactNode {
        const { loading, userInformation, isLoading } = this.state;
        return (
            loading ? <Spinner animation="grow" />  :
            <div className="p-3">
                <h3>Edit Profile</h3>
                <h6>{userInformation.email}</h6>
                <Form onSubmit={this.submit}>
                    <Row>
                        <Col md="4" sm="12">
                            <Form.Group controlId="userInformation.firstName">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control type="text" value={userInformation.firstName}
                                    placeholder="Enter your name" onChange={e => { this.setState(prevState => { return { userInformation: { ...prevState.userInformation, firstName: e.target.value } } }) }} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4" sm="12">
                            <Form.Group controlId="userInformation.lastName">
                                <Form.Label>Last name</Form.Label>
                                <Form.Control type="text" value={userInformation.lastName}
                                    placeholder="Enter your last name" onChange={e => { this.setState(prevState => { return { userInformation: { ...prevState.userInformation, lastName: e.target.value } } }) }} />
                            </Form.Group>
                        </Col>
                    </Row>
                    {
                        isLoading ? <Button variant="submit" disabled>
                            <Spinner animation="border" size="sm" /> </Button> :
                            <Button variant='success' className="mt-2" type="submit">Save changes</Button>
                    }
                </Form>
            </div>
        )
    }
}