import React, { RefObject } from "react"
import { Form } from "react-bootstrap"
import AdminDepositsService from "../../../../services/admin/financials/AdminDepositsService"
import cashPaymentStatusDisplayBadge from "../../../financials/Enums/CashPaymentStatusEnum"
import FilterBar from "../../../Grid/Filters/FilterBar"
import Grid from "../../../Grid/Grid"
import { GridAction, GridColumn, GridColumnTypes } from "../../../Grid/Grid.models"
import { PaginationQuery } from "../../../Grid/Pagination/Pagination.models"
import AdminDepositDetails from "./AdminDepositDetails/AdminDepositDetails"
import IState, { IFilters, IProps } from "./AdminDeposits.models"
import BlockchainVerificationImg from '../../../../assets/images/content/blockchain-verification.png'

export default class AdminWithdrawals extends React.PureComponent<IProps, IState> {
    adminDepositsService: AdminDepositsService
    grid: RefObject<Grid>
    filters: IFilters
    constructor(props: any) {
        super(props);
        this.grid = React.createRef()
        this.adminDepositsService = new AdminDepositsService()
        this.filters = { shopId: '' }
        this.state = { rows: [], editFilters: { shopId: '' }, filters: { shopId: '' }, paginationQuery: { pageskip: 0, pagesize: 10 } }
    }

    componentDidMount = () => {
        this.getPaged()
    }

    verificationDisplay = (isVerification: boolean) => {
        return isVerification && <div className="text-center"><img src={BlockchainVerificationImg}/></div>
    }

    getPaged = (paginationQuery: PaginationQuery = null) => {
        if (paginationQuery == null)
            paginationQuery = this.state.paginationQuery;
        else {
            this.setState({ paginationQuery })
        }

        this.adminDepositsService.getPaged({ ...paginationQuery, ...this.filters, status: this.props.status, unreviewedOnly: this.props.unreviewedOnly }).then((gridResult) => {
            let rows = gridResult.rows.map((row) => {
                return { ...row, status: cashPaymentStatusDisplayBadge(row.status), isBlockchainVerification: this.verificationDisplay(row.isBlockchainVerification)}
            });
            this.grid.current?.load(rows, gridResult.totalRows)
            this.setState({ rows: gridResult.rows });
        })

        this.setState({ filters: { ...this.filters } });
    }

    getSelectedRow = () => {
        if (this.state.showDetailsId !== undefined && this.state.rows.filter(row => row.id == this.state.showDetailsId).length > 0) {
            return this.state.rows.filter(row => row.id == this.state.showDetailsId)[0];
        }
        return undefined;
    }

    markAsReview = (id: string) => this.adminDepositsService.review(id).then(() => {
        this.getPaged()
    })

    render(): React.ReactNode {
        let gridColumns: GridColumn[] = [
            { title: 'Shop', key: 'shopTitle' },
            { title: 'Amount', key: 'amount', type: GridColumnTypes.Amount },
            { title: 'Account', key: 'cashPaymentAccountTitle' },
            { title: 'Transaction Number', key: 'transactionNumber' },
            { title: 'Status', key: 'status' },
            { title: 'Create At', key: 'crreateAt', type: GridColumnTypes.Date },
            { title: 'Blockchain Verification', key: 'isBlockchainVerification', align: 'center'},
        ]

        var gridActions: GridAction[] = [
            { caption: 'Details', variant: 'outline-info', onClick: (row) => this.setState({ showDetailsId: row.id }), skipSpinner: true }
        ]

        if (this.props.unreviewedOnly === true)
            gridActions.push({ caption: 'Mark as review', variant: 'outline-primary', onClick: (row) => this.markAsReview(row.id), skipSpinner: true })

        return (<>
            <AdminDepositDetails details={this.getSelectedRow() as any} onClose={() => {
                this.setState({ showDetailsId: undefined })
                this.getPaged()
                }} />
            {this.filtersView()}
            <Grid ref={this.grid} columns={gridColumns} actions={gridActions} onChangePage={this.getPaged}></Grid>
        </>)
    }

    applyFilters = () => {
        this.filters = { ...this.state.editFilters }
        this.getPaged()
    }

    removeFilter = (key: string) => {
        let filters: any = this.state.editFilters
        filters[key] = ''
        this.setState({ editFilters: filters })
        this.applyFilters()
    }

    filtersView = () => {
        return <>
            <FilterBar onApply={this.applyFilters}
                onOpened={() => this.setState({ editFilters: { ...this.state.filters } })}
                filters={this.state.filters}
                onRemoveFilter={this.removeFilter}>
                <Form.Group>
                    <Form.Label>Financial Account</Form.Label>
                    <Form.Control value={this.state.editFilters.shopId} onChange={(e) => {
                        this.setState({ editFilters: { ...this.state.editFilters, shopId: e.target.value } })
                    }} type="text" placeholder="Enter Financial Account" />
                </Form.Group>
            </FilterBar>
        </>
    }
}