import React, { forwardRef, RefObject } from "react";
import { Calendar } from "react-bootstrap-icons";
import { Button, Col, Form, Row } from "react-bootstrap";
import moment from 'moment';
import OrderTypeDisplayBadge from "../../../Enums/OrderTypeEnum";
import { orderTypeDisplayCaption, OrderTypeEnum } from "../../../Enums/OrderTypeEnum";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import DatePicker from "react-datepicker";
import InputGroupView from "../../../../inventory/InventoryPlans/InputGroupView/InputGroupView";
import OrderProductStatusBadge, { OrderProductStatusDisplayCaption, OrderProductStatusEnum } from "../../../Enums/OrderProductStatusEnum";
import { GridAction, GridColumn, GridColumnTypes } from "../../../../Grid/Grid.models";
import { BuyOrderListItem } from "../../../Orders/OrdersList/OrdersList.models";
import OrderReviewRequest from "../../../OrderReviewRequest/OrderReviewRequest";
import OrdersService, { ISellOrderItemTableRow } from "../../../../../services/orders/OrdersService";
import { SelectionView } from "../../../../../services/common/Models";
import IState, { ISellOrderItemsFilters } from "./SellOrderItems.models";
import DynamicGrid from "../../../../shared/DynamicGrid/DynamicGrid";
import PeriodDatePicker from "../../../../shared/PeriodDatePicker/PeriodDatePicker";
import { NavLink } from "react-router-dom";
import Note from "../../../../shared/Note/Note";

export default class SellOrderItems extends React.PureComponent<{}, IState> {
    dynamicGrid: RefObject<DynamicGrid<ISellOrderItemsFilters>>;
    ordersService: OrdersService;
    constructor(props: any) {
        super(props);
        this.dynamicGrid = React.createRef();
        this.ordersService = new OrdersService();
        this.state = {
            products: []
        };
    }

    componentDidMount() {
        this.ordersService.getProductsTitleValues().then((response) => {
            this.setState({ products: response })
        })
    }


    informationView(informations: string[]) {
        return (
            informations.map((information, key) => {
                return (<span key={key}>
                    {information}
                    <br />
                </span>)
            })
        )
    }


    renderCustomFilterView = (key: string, value: string) => {
        switch (key) {
            case 'status':
                return OrderProductStatusDisplayCaption(Number(value))
            case 'orderType':
                return orderTypeDisplayCaption(Number(value))
            case 'deliveryDate':
            case 'fromDate':
            case 'toDate':
                return moment(value).format('YYYY-MM-DD')
            default: return value
        }
    }

    render(): React.ReactNode {
        let gridColumns: GridColumn[] = [
            { title: 'Title', key: 'productTitle' },
            { title: 'Order Number', key: 'orderNumber' },
            { title: 'Order Date', key: 'orderDate', type: GridColumnTypes.Date },
            { title: 'Status', key: 'statusBadge' },
            { title: 'Sell Price', key: 'sellerPrice' },
            { title: 'Information', key: 'informations' },
        ];

        let gridActions: GridAction[] = [
            {
                render: (row: ISellOrderItemTableRow) =>
                    row.reviewRequestId && <NavLink to={`/support-ticket-report-view/?ticketId=${row.reviewRequestId}`}><Button variant="outline-danger">Show Review Request</Button></NavLink>
            },
        ];

        return <DynamicGrid<ISellOrderItemsFilters> ref={this.dynamicGrid}
            columns={gridColumns}
            actions={gridActions}
            onFetch={this.ordersService.getSellOrderItems}
            refactorFilterDisplay={this.renderCustomFilterView}
            filtersForm={this.filtersView}
            refactorRow={(row) => {
                return {
                    ...row,
                    statusBadge: OrderProductStatusBadge(row.status),
                    orderType: OrderTypeDisplayBadge(row.orderType),
                    informations: this.informationView(row.informations)
                }
            }} />
    }


    filtersView = (filters: ISellOrderItemsFilters, onChange: (newFilters: ISellOrderItemsFilters) => void): JSX.Element => {
        return <>
            <Row className="mt-2">
                <Col md="12" sm="12" className="mt-2">
                    <Form.Group>
                        <Form.Label>
                            <span>Search Inventory Code:</span>
                        </Form.Label>
                        <Form.Control value={filters.inventorySafeInformation} onChange={e => onChange({ ...filters, inventorySafeInformation: e.target.value })} type="text" name="title" placeholder="Enter information" />
                        <Note>Search for a section of code (Example: XYSW)</Note>
                        <Note>Search the full code (Example: XYSWJ4F53SY42VS7)</Note>
                    </Form.Group>
                </Col>
            </Row>
        </>
    }
}

const CustomInput = forwardRef((props: any, ref: any) => {
    return (
        <InputGroupView textColor="muted" renderMarkView={<Calendar />} onClick={props.onClick}>
            <Form.Control aria-describedby="basic-addon1" {...props} ref={ref} />
        </InputGroupView>
    )
});