import { mapToDataOnly } from "../common/HttpUtility";
import { SupportTicketTagApiFactory } from "../../generated-api";

export default class SupportTicketTagService {

    create(dto: CreateSupportTicketTagDTO): Promise<void> {
        return mapToDataOnly(SupportTicketTagApiFactory().supportTicketTagPost({ ...dto }));
    }

    deleteById(id: string): Promise<void> {
        return mapToDataOnly<void>(SupportTicketTagApiFactory().supportTicketTagIdDelete(id));
    }
}

export interface CreateSupportTicketTagDTO {
    ticketId: string;
    key?: string;
    value: string;
}