import { mapToDataOnly } from "../common/HttpUtility"
import { ProductBasketApiFactory } from "../../generated-api"

export default class CartService {
    add(cartItem: {
        count: number
        productId: string
        sellerShopId: string
        unitPrice: number
    }): Promise<string> {

        return mapToDataOnly<string>(ProductBasketApiFactory().buyerProductBasketPost(cartItem));
    }

    update(cartItem: {
        count: number
        productBasketId: string
    }): Promise<string> {

        return mapToDataOnly<string>(ProductBasketApiFactory().buyerProductBasketPut(cartItem));
    }

    delete(id: string): Promise<string> {

        return mapToDataOnly<string>(ProductBasketApiFactory().buyerProductBasketProductBasketIdDelete(id));
    }

    get(): Promise<{
        count: number
        id: string
        imageId: string | null
        productTitle: string
        productInventoryAvailableCount: number
        productId: string
        sellerShopId: string
        unitPrice: number
    }[]> {
        return mapToDataOnly<any>(ProductBasketApiFactory().buyerProductBasketGet());
    }
}