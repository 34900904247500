import { Route } from "react-router-dom";
import DefaultLayout from "../../components/layout/DefaultLayout";
import NotificationsRoute from "../notifications/NotificationsRoute";

export function NotificationsRoutes() {
    return (
        <>
            <Route element={<DefaultLayout />}>
                <Route path="/notifications-setting" element={<NotificationsRoute />}/>
            </Route>
        </>
    )
}