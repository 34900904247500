import React from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import IState, { IProps } from "./AdminCreateGlobalWalletModal.models";
import AdminProductService from "../../../../../services/admin/products/AdminProductService";
import AdminWalletsServices from "../../../../../services/admin/financials/AdminWalletsServices";
import { toast } from "react-toastify";

export default class AdminCreateGlobalWallet extends React.Component<IProps, IState> {
    productService: AdminProductService
    walletService: AdminWalletsServices
    constructor(props: any) {
        super(props);
        this.productService = new AdminProductService()
        this.walletService = new AdminWalletsServices()
        this.state = { pendingRequest: false, currencyId: '', title: '', currencyTitleValues: [] }
    }

    componentDidMount(): void {
        this.productService.getCurrency().then((response) => this.setState({ currencyTitleValues: response }))
    }

    submit = () => {
        if (!this.state.title) return toast.error('Enter the title.')
        if (!this.state.currencyId) return toast.error('Enter the currency.')

        this.setState({ pendingRequest: true })

        this.walletService.create(this.state.title, this.state.currencyId).then(response => {
            this.props.onClose()
            this.props.onSubmit && this.props.onSubmit()
        }).finally(() => this.setState({ pendingRequest: false }))
    }

    render(): React.ReactNode {
        let { onClose } = this.props
        let { title, pendingRequest, currencyId, currencyTitleValues } = this.state

        return <Modal show={true} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Create Global Wallet</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col className="mt-3" md="12" sm="12">
                            <Form.Label>Title:</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Wallet Title"
                                value={title} onChange={e => this.setState({ title: e.target.value })} />
                        </Col>
                        <Col className="mt-3" md="12" sm="12">
                            <Form.Label>Currency:</Form.Label>
                            <Form.Select aria-label="Select Wallet Currency" value={currencyId}
                                onChange={(e) => this.setState({ currencyId: e.target.value })}>
                                <option value="" selected>Select Wallet Currency</option>
                                {
                                    currencyTitleValues.map((currency) => <option value={currency.value} key={currency.value}>{currency.title}</option>)
                                }
                            </Form.Select>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                {pendingRequest ? <Button variant='success' disabled><Spinner size="sm" animation='border' /></Button> :
                    <Button variant='success' onClick={this.submit}>Create</Button>}
            </Modal.Footer>
        </Modal>
    }
}