import { useSearchParams } from "react-router-dom";
import ResetPassword from "../../components/identity/ResetPassword/ResetPassword";
import NotFoundRoute from "../shared/NotFoundRoute";

export default function ResetPasswordRoute() {
    const [searchParams] = useSearchParams();
    let token = searchParams.get("token").toString().replace(/\s/g, "+");
    let userId = searchParams.get("userId");

    return token === null || userId === null ? <NotFoundRoute /> : <ResetPassword userId={userId as string} token={token as string} />
}