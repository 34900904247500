import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import IdentityService from "../../../services/identity/IdentityService";
import ChangePassword from "./Password/ChangePassword";
import TwoFactorVerification from "./TwoFactor/TwoFactorVerification/TwoFactorVerification";
import EmailConfirmation from "../EmailVerification/EmailVerification";

export default class Security extends React.PureComponent<{}, { isTwoFactorEnabled: boolean, isEmailConfirmed: boolean }> {
    identityService: IdentityService
    constructor(props: any) {
        super(props);
        this.identityService = new IdentityService()
        this.state = { isTwoFactorEnabled: false, isEmailConfirmed: false };
    }

    componentDidMount() {
        this.getPaged()
    }

    getPaged() {
        this.identityService.getUserSecurityInformation().then((response) => {
            this.setState({ isTwoFactorEnabled: response.isTwoFactorEnabled, isEmailConfirmed: response.isEmailConfirmed })
        })
    }

    render(): React.ReactNode {
        return (
            <>
                <Tabs defaultActiveKey={'emailConfirmation'} id="orders-tabs" className="mb-3" mountOnEnter={true} unmountOnExit={true}>
                    <Tab eventKey="emailConfirmation" title="Email Confirmation">
                        <EmailConfirmation isEmailConfirmed={this.state.isEmailConfirmed} />
                    </Tab>
                    <Tab eventKey="changePassword" title="Change Password">
                        <ChangePassword />
                    </Tab>
                    <Tab eventKey="twoFactor" title="Two-Factor">
                        <TwoFactorVerification reloadPage={() => this.setState({ isTwoFactorEnabled: !this.state.isTwoFactorEnabled })} isTwoFactorEnabled={this.state.isTwoFactorEnabled} />
                    </Tab>
                </Tabs>
            </>
        )
    }
}