/* tslint:disable */
/* eslint-disable */
/**
 * Admin
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export const ApiGiftCommonCommandsOrdersMediationCreateReviewRequestResultCommandCorrectnessResults = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type ApiGiftCommonCommandsOrdersMediationCreateReviewRequestResultCommandCorrectnessResults = typeof ApiGiftCommonCommandsOrdersMediationCreateReviewRequestResultCommandCorrectnessResults[keyof typeof ApiGiftCommonCommandsOrdersMediationCreateReviewRequestResultCommandCorrectnessResults];


/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsBaseTableDTOApiGiftCommonDTOsShopsShopsGetPagedShopPackageDTO
 */
export interface ApiGiftCommonDTOsBaseTableDTOApiGiftCommonDTOsShopsShopsGetPagedShopPackageDTO {
    /**
     * 
     * @type {Array<ApiGiftCommonDTOsShopsShopsGetPagedShopPackageDTO>}
     * @memberof ApiGiftCommonDTOsBaseTableDTOApiGiftCommonDTOsShopsShopsGetPagedShopPackageDTO
     */
    'rows'?: Array<ApiGiftCommonDTOsShopsShopsGetPagedShopPackageDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsBaseTableDTOApiGiftCommonDTOsShopsShopsGetPagedShopPackageDTO
     */
    'totalRows'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryDTO
 */
export interface ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryDTO
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryDTO
     */
    'imageName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryDTO
     */
    'parentId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryDTO
     */
    'orderId'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryRowDTO
 */
export interface ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryRowDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryRowDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryRowDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryRowDTO
     */
    'orderId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryRowDTO
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryRowDTO
     */
    'imageName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryRowDTO
     */
    'imageSrc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryRowDTO
     */
    'parentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryRowDTO
     */
    'parentTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryRowDTO
     */
    'createAt'?: string;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsContentArticlesArticleDTO
 */
export interface ApiGiftCommonDTOsContentArticlesArticleDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsContentArticlesArticleDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsContentArticlesArticleDTO
     */
    'details'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsContentArticlesArticleDTO
     */
    'published'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsContentArticlesArticleDTO
     */
    'imageName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsContentArticlesArticleDTO
     */
    'categoryId'?: string;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsContentArticlesArticleRowDTO
 */
export interface ApiGiftCommonDTOsContentArticlesArticleRowDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsContentArticlesArticleRowDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsContentArticlesArticleRowDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsContentArticlesArticleRowDTO
     */
    'details'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsContentArticlesArticleRowDTO
     */
    'published'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsContentArticlesArticleRowDTO
     */
    'imageName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsContentArticlesArticleRowDTO
     */
    'imageSrc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsContentArticlesArticleRowDTO
     */
    'createAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsContentArticlesArticleRowDTO
     */
    'categoryId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsContentArticlesArticleRowDTO
     */
    'categoryTitle'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsInventoryInventoryShopBasePlanHandleInventoryCategoryShopBasePlanDTO
 */
export interface ApiGiftCommonDTOsInventoryInventoryShopBasePlanHandleInventoryCategoryShopBasePlanDTO {
    /**
     * 
     * @type {ApiGiftCommonEnumsInventoryInventoryShopsInventoryShopBasePlanStatus}
     * @memberof ApiGiftCommonDTOsInventoryInventoryShopBasePlanHandleInventoryCategoryShopBasePlanDTO
     */
    'status'?: ApiGiftCommonEnumsInventoryInventoryShopsInventoryShopBasePlanStatus;
}


/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsInventoryInventoryShopBasePlanInventoryShopBasePlanDTO
 */
export interface ApiGiftCommonDTOsInventoryInventoryShopBasePlanInventoryShopBasePlanDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryShopBasePlanInventoryShopBasePlanDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryShopBasePlanInventoryShopBasePlanDTO
     */
    'shopTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryShopBasePlanInventoryShopBasePlanDTO
     */
    'shopId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryShopBasePlanInventoryShopBasePlanDTO
     */
    'productCategoryTitle'?: string | null;
    /**
     * 
     * @type {ApiGiftCommonEnumsInventoryInventoryShopsInventoryShopBasePlanStatus}
     * @memberof ApiGiftCommonDTOsInventoryInventoryShopBasePlanInventoryShopBasePlanDTO
     */
    'status'?: ApiGiftCommonEnumsInventoryInventoryShopsInventoryShopBasePlanStatus;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryShopBasePlanInventoryShopBasePlanDTO
     */
    'requestAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryShopBasePlanInventoryShopBasePlanDTO
     */
    'handlerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryShopBasePlanInventoryShopBasePlanDTO
     */
    'handlerUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryShopBasePlanInventoryShopBasePlanDTO
     */
    'handleAt'?: string | null;
}


/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsInventoryInventoryShopBasePlanInventoryShopBasePlanRowDTO
 */
export interface ApiGiftCommonDTOsInventoryInventoryShopBasePlanInventoryShopBasePlanRowDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryShopBasePlanInventoryShopBasePlanRowDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryShopBasePlanInventoryShopBasePlanRowDTO
     */
    'shopTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryShopBasePlanInventoryShopBasePlanRowDTO
     */
    'shopId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryShopBasePlanInventoryShopBasePlanRowDTO
     */
    'productCategoryTitle'?: string | null;
    /**
     * 
     * @type {ApiGiftCommonEnumsInventoryInventoryShopsInventoryShopBasePlanStatus}
     * @memberof ApiGiftCommonDTOsInventoryInventoryShopBasePlanInventoryShopBasePlanRowDTO
     */
    'status'?: ApiGiftCommonEnumsInventoryInventoryShopsInventoryShopBasePlanStatus;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryShopBasePlanInventoryShopBasePlanRowDTO
     */
    'createAt'?: string;
}


/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO
 */
export interface ApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO
     */
    'fromId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO
     */
    'toId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO
     */
    'ticketNumber'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO
     */
    'tags'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO
     */
    'from'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO
     */
    'handlerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO
     */
    'memberUserDisplayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO
     */
    'createAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO
     */
    'modifyAt'?: string;
    /**
     * 
     * @type {WorkspacesCommonEnumsSupportTicketsSupportTicketStatus}
     * @memberof ApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO
     */
    'status'?: WorkspacesCommonEnumsSupportTicketsSupportTicketStatus;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO
     */
    'isGood'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO
     */
    'memberUserId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO
     */
    'isMediationRequested'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO
     */
    'mediationRequestDate'?: string | null;
}


/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsOrdersMediationCreateDecisionDTO
 */
export interface ApiGiftCommonDTOsOrdersMediationCreateDecisionDTO {
    /**
     * 
     * @type {ApiGiftCommonCommandsOrdersMediationCreateReviewRequestResultCommandCorrectnessResults}
     * @memberof ApiGiftCommonDTOsOrdersMediationCreateDecisionDTO
     */
    'correctnessResult'?: ApiGiftCommonCommandsOrdersMediationCreateReviewRequestResultCommandCorrectnessResults;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersMediationCreateDecisionDTO
     */
    'ticketId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersMediationCreateDecisionDTO
     */
    'orderProductId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersMediationCreateDecisionDTO
     */
    'sellerLossAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersMediationCreateDecisionDTO
     */
    'buyerLossAmount'?: number;
}


/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto
 */
export interface ApiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto
     */
    'productId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto
     */
    'deliveryKey'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO
 */
export interface ApiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO
     */
    'orderId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO
     */
    'parentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO
     */
    'imageName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO
     */
    'imageSrc'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsShopsProductCategoriesProductCategoryMultiLevelDTO
 */
export interface ApiGiftCommonDTOsShopsProductCategoriesProductCategoryMultiLevelDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsProductCategoriesProductCategoryMultiLevelDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsProductCategoriesProductCategoryMultiLevelDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsProductCategoriesProductCategoryMultiLevelDTO
     */
    'parentId'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsShopsProductCategoriesProductCategoryRowDTO
 */
export interface ApiGiftCommonDTOsShopsProductCategoriesProductCategoryRowDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsProductCategoriesProductCategoryRowDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsProductCategoriesProductCategoryRowDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsShopsProductCategoriesProductCategoryRowDTO
     */
    'orderId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsShopsProductCategoriesProductCategoryRowDTO
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsProductCategoriesProductCategoryRowDTO
     */
    'parentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsProductCategoriesProductCategoryRowDTO
     */
    'parentTitle'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsShopsProductCategoriesProductCategoryRowDTO
     */
    'sellerCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsShopsProductCategoriesProductCategoryRowDTO
     */
    'productCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsProductCategoriesProductCategoryRowDTO
     */
    'imageName'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsShopsProductsProductDTO
 */
export interface ApiGiftCommonDTOsShopsProductsProductDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsProductsProductDTO
     */
    'title': string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsShopsProductsProductDTO
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsProductsProductDTO
     */
    'categoryId': string;
    /**
     * 
     * @type {Array<ApiGiftCommonDTOsShopsProductsProductStructureDTO>}
     * @memberof ApiGiftCommonDTOsShopsProductsProductDTO
     */
    'structures': Array<ApiGiftCommonDTOsShopsProductsProductStructureDTO>;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsProductsProductDTO
     */
    'shortTitle': string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsProductsProductDTO
     */
    'currencyId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsShopsProductsProductDTO
     */
    'realPrice'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsProductsProductDTO
     */
    'imageName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsProductsProductDTO
     */
    'imageSrc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsShopsProductsProductDTO
     */
    'isPrivate'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsShopsProductsProductRowDTO
 */
export interface ApiGiftCommonDTOsShopsProductsProductRowDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsProductsProductRowDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsProductsProductRowDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsShopsProductsProductRowDTO
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsShopsProductsProductRowDTO
     */
    'isPrivate'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsProductsProductRowDTO
     */
    'categoryTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsProductsProductRowDTO
     */
    'createAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsShopsProductsProductRowDTO
     */
    'sellerCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsProductsProductRowDTO
     */
    'imageName'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsShopsProductsProductStructureDTO
 */
export interface ApiGiftCommonDTOsShopsProductsProductStructureDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsProductsProductStructureDTO
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsProductsProductStructureDTO
     */
    'key': string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsShopsProductsProductStructureDTO
     */
    'isUniqueValue'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsShopsShopsBuyerDetailsDTO
 */
export interface ApiGiftCommonDTOsShopsShopsBuyerDetailsDTO {
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsShopsShopsBuyerDetailsDTO
     */
    'averageDailyBuy'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsShopsShopsBuyerDetailsDTO
     */
    'totalBuyAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsShopsShopsBuyerDetailsDTO
     */
    'last30daysAgoBuyAverage'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsShopsBuyerDetailsDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsShopsBuyerDetailsDTO
     */
    'createAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsShopsBuyerDetailsDTO
     */
    'acceptedAt'?: string | null;
    /**
     * 
     * @type {WorkspacesCommonEnumsShopsShopStatus}
     * @memberof ApiGiftCommonDTOsShopsShopsBuyerDetailsDTO
     */
    'status'?: WorkspacesCommonEnumsShopsShopStatus;
    /**
     * 
     * @type {Array<WorkspacesCommonDtosShopMembersShopDetailsMemberDTO>}
     * @memberof ApiGiftCommonDTOsShopsShopsBuyerDetailsDTO
     */
    'members'?: Array<WorkspacesCommonDtosShopMembersShopDetailsMemberDTO> | null;
    /**
     * 
     * @type {Array<ApiGiftCommonDTOsShopsShopsBuyerDetailsOrderProductDTO>}
     * @memberof ApiGiftCommonDTOsShopsShopsBuyerDetailsDTO
     */
    'orderProducts'?: Array<ApiGiftCommonDTOsShopsShopsBuyerDetailsOrderProductDTO> | null;
}


/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsShopsShopsBuyerDetailsOrderProductDTO
 */
export interface ApiGiftCommonDTOsShopsShopsBuyerDetailsOrderProductDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsShopsBuyerDetailsOrderProductDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsShopsShopsBuyerDetailsOrderProductDTO
     */
    'orderCount'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsShopsShopsGetPagedShopPackageDTO
 */
export interface ApiGiftCommonDTOsShopsShopsGetPagedShopPackageDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsShopsGetPagedShopPackageDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsShopsGetPagedShopPackageDTO
     */
    'packageId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsShopsGetPagedShopPackageDTO
     */
    'shopId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsShopsGetPagedShopPackageDTO
     */
    'handledAt'?: string | null;
    /**
     * 
     * @type {WorkspacesCommonEnumsPoliciesPackageStatus}
     * @memberof ApiGiftCommonDTOsShopsShopsGetPagedShopPackageDTO
     */
    'status'?: WorkspacesCommonEnumsPoliciesPackageStatus;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsShopsGetPagedShopPackageDTO
     */
    'handlerDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsShopsGetPagedShopPackageDTO
     */
    'handlerUserId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsShopsShopsGetPagedShopPackageDTO
     */
    'isDisable'?: boolean;
}


/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsShopsShopsHandleShopPackageDTO
 */
export interface ApiGiftCommonDTOsShopsShopsHandleShopPackageDTO {
    /**
     * 
     * @type {WorkspacesCommonEnumsPoliciesPackageStatus}
     * @memberof ApiGiftCommonDTOsShopsShopsHandleShopPackageDTO
     */
    'status'?: WorkspacesCommonEnumsPoliciesPackageStatus;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsShopsHandleShopPackageDTO
     */
    'description'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ApiGiftCommonEnumsInventoryInventoryShopsInventoryShopBasePlanStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5
} as const;

export type ApiGiftCommonEnumsInventoryInventoryShopsInventoryShopBasePlanStatus = typeof ApiGiftCommonEnumsInventoryInventoryShopsInventoryShopBasePlanStatus[keyof typeof ApiGiftCommonEnumsInventoryInventoryShopsInventoryShopBasePlanStatus];


/**
 * 
 * @export
 * @interface ApiGiftCommonQueriesOrdersMediationGetMediationDetailsQueryResponse
 */
export interface ApiGiftCommonQueriesOrdersMediationGetMediationDetailsQueryResponse {
    /**
     * 
     * @type {ApiGiftCommonQueriesOrdersMediationGetMediationDetailsQueryResponseDecisionView}
     * @memberof ApiGiftCommonQueriesOrdersMediationGetMediationDetailsQueryResponse
     */
    'decision'?: ApiGiftCommonQueriesOrdersMediationGetMediationDetailsQueryResponseDecisionView;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonQueriesOrdersMediationGetMediationDetailsQueryResponseDecisionView
 */
export interface ApiGiftCommonQueriesOrdersMediationGetMediationDetailsQueryResponseDecisionView {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonQueriesOrdersMediationGetMediationDetailsQueryResponseDecisionView
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonQueriesOrdersMediationGetMediationDetailsQueryResponseDecisionView
     */
    'creatorUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonQueriesOrdersMediationGetMediationDetailsQueryResponseDecisionView
     */
    'createDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonQueriesOrdersMediationGetMediationDetailsQueryResponseDecisionView
     */
    'sellerLossAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonQueriesOrdersMediationGetMediationDetailsQueryResponseDecisionView
     */
    'buyerLossAmount'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonQueriesOrdersOrdersGetSalesReportQueryResponse
 */
export interface ApiGiftCommonQueriesOrdersOrdersGetSalesReportQueryResponse {
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonQueriesOrdersOrdersGetSalesReportQueryResponse
     */
    'profitPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonQueriesOrdersOrdersGetSalesReportQueryResponse
     */
    'totalPrice'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsContentArticlesArticleRowDTO
 */
export interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsContentArticlesArticleRowDTO {
    /**
     * 
     * @type {Array<ApiGiftCommonDTOsContentArticlesArticleRowDTO>}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsContentArticlesArticleRowDTO
     */
    'rows'?: Array<ApiGiftCommonDTOsContentArticlesArticleRowDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsContentArticlesArticleRowDTO
     */
    'totalRows'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsInventoryInventoryShopBasePlanInventoryShopBasePlanRowDTO
 */
export interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsInventoryInventoryShopBasePlanInventoryShopBasePlanRowDTO {
    /**
     * 
     * @type {Array<ApiGiftCommonDTOsInventoryInventoryShopBasePlanInventoryShopBasePlanRowDTO>}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsInventoryInventoryShopBasePlanInventoryShopBasePlanRowDTO
     */
    'rows'?: Array<ApiGiftCommonDTOsInventoryInventoryShopBasePlanInventoryShopBasePlanRowDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsInventoryInventoryShopBasePlanInventoryShopBasePlanRowDTO
     */
    'totalRows'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsShopsProductCategoriesProductCategoryRowDTO
 */
export interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsShopsProductCategoriesProductCategoryRowDTO {
    /**
     * 
     * @type {Array<ApiGiftCommonDTOsShopsProductCategoriesProductCategoryRowDTO>}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsShopsProductCategoriesProductCategoryRowDTO
     */
    'rows'?: Array<ApiGiftCommonDTOsShopsProductCategoriesProductCategoryRowDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsShopsProductCategoriesProductCategoryRowDTO
     */
    'totalRows'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsShopsProductsProductRowDTO
 */
export interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsShopsProductsProductRowDTO {
    /**
     * 
     * @type {Array<ApiGiftCommonDTOsShopsProductsProductRowDTO>}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsShopsProductsProductRowDTO
     */
    'rows'?: Array<ApiGiftCommonDTOsShopsProductsProductRowDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsShopsProductsProductRowDTO
     */
    'totalRows'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsBaseTableDTOAuthenticationCommonDtosUserUserRowDto
 */
export interface ApiGiftGatewayCommonDTOsBaseTableDTOAuthenticationCommonDtosUserUserRowDto {
    /**
     * 
     * @type {Array<AuthenticationCommonDtosUserUserRowDto>}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOAuthenticationCommonDtosUserUserRowDto
     */
    'rows'?: Array<AuthenticationCommonDtosUserUserRowDto> | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOAuthenticationCommonDtosUserUserRowDto
     */
    'totalRows'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsBaseTitleValueDTO
 */
export interface ApiGiftGatewayCommonDTOsBaseTitleValueDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsBaseTitleValueDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsBaseTitleValueDTO
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialDepositsAcceptDepositDto
 */
export interface ApiGiftGatewayCommonDTOsFinancialDepositsAcceptDepositDto {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialDepositsAcceptDepositDto
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialWithdrawDoneWithdrawDTO
 */
export interface ApiGiftGatewayCommonDTOsFinancialWithdrawDoneWithdrawDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWithdrawDoneWithdrawDTO
     */
    'transactionNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsMarketplaceNotificationsNotificationPermissionRowDTO
 */
export interface ApiGiftGatewayCommonDTOsMarketplaceNotificationsNotificationPermissionRowDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsMarketplaceNotificationsNotificationPermissionRowDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsMarketplaceNotificationsNotificationPermissionRowDTO
     */
    'key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsMarketplaceNotificationsNotificationPermissionRowDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftGatewayCommonDTOsMarketplaceNotificationsNotificationPermissionRowDTO
     */
    'isActive'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO
 */
export interface ApiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO
     */
    'permissionId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO
     */
    'isActive'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsMarketplaceSupportTicketsAddReplyToMediationReviewRequestByDto
 */
export interface ApiGiftGatewayCommonDTOsMarketplaceSupportTicketsAddReplyToMediationReviewRequestByDto {
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftGatewayCommonDTOsMarketplaceSupportTicketsAddReplyToMediationReviewRequestByDto
     */
    'sellerTarget'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsMarketplaceSupportTicketsAddReplyToMediationReviewRequestByDto
     */
    'replyText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsMarketplaceSupportTicketsAddReplyToMediationReviewRequestByDto
     */
    'supportTicketId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiGiftGatewayCommonDTOsMarketplaceSupportTicketsAddReplyToMediationReviewRequestByDto
     */
    'attachmentNames'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsMarketplaceSupportTicketsSupportTicketDetailsDto
 */
export interface ApiGiftGatewayCommonDTOsMarketplaceSupportTicketsSupportTicketDetailsDto {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsMarketplaceSupportTicketsSupportTicketDetailsDto
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsMarketplaceSupportTicketsSupportTicketDetailsDto
     */
    'ticketNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsMarketplaceSupportTicketsSupportTicketDetailsDto
     */
    'createAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsMarketplaceSupportTicketsSupportTicketDetailsDto
     */
    'modifyAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsMarketplaceSupportTicketsSupportTicketDetailsDto
     */
    'fromId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsMarketplaceSupportTicketsSupportTicketDetailsDto
     */
    'from'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsMarketplaceSupportTicketsSupportTicketDetailsDto
     */
    'toId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsMarketplaceSupportTicketsSupportTicketDetailsDto
     */
    'to'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsMarketplaceSupportTicketsSupportTicketDetailsDto
     */
    'inventoryInformation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsMarketplaceSupportTicketsSupportTicketDetailsDto
     */
    'inventoryEnterAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsMarketplaceSupportTicketsSupportTicketDetailsDto
     */
    'viewByBuyerAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsMarketplaceSupportTicketsSupportTicketDetailsDto
     */
    'handlerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsMarketplaceSupportTicketsSupportTicketDetailsDto
     */
    'handlerUserId'?: string | null;
    /**
     * 
     * @type {WorkspacesCommonDtosBaseTitleValueDTOSystemGuid}
     * @memberof ApiGiftGatewayCommonDTOsMarketplaceSupportTicketsSupportTicketDetailsDto
     */
    'department'?: WorkspacesCommonDtosBaseTitleValueDTOSystemGuid;
    /**
     * 
     * @type {WorkspacesCommonEnumsSupportTicketsSupportTicketStatus}
     * @memberof ApiGiftGatewayCommonDTOsMarketplaceSupportTicketsSupportTicketDetailsDto
     */
    'status'?: WorkspacesCommonEnumsSupportTicketsSupportTicketStatus;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftGatewayCommonDTOsMarketplaceSupportTicketsSupportTicketDetailsDto
     */
    'isGood'?: boolean | null;
    /**
     * 
     * @type {Array<WorkspacesCommonDtosSupportTicketsSupportTicketDetailsCommentDTO>}
     * @memberof ApiGiftGatewayCommonDTOsMarketplaceSupportTicketsSupportTicketDetailsDto
     */
    'comments'?: Array<WorkspacesCommonDtosSupportTicketsSupportTicketDetailsCommentDTO> | null;
    /**
     * 
     * @type {Array<WorkspacesCommonDtosSupportTicketsSupportTicketDetailsReplyDTO>}
     * @memberof ApiGiftGatewayCommonDTOsMarketplaceSupportTicketsSupportTicketDetailsDto
     */
    'replies'?: Array<WorkspacesCommonDtosSupportTicketsSupportTicketDetailsReplyDTO> | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsMarketplaceSupportTicketsSupportTicketDetailsDto
     */
    'orderProductId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsMarketplaceSupportTicketsSupportTicketDetailsDto
     */
    'sellPrice'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftGatewayCommonDTOsMarketplaceSupportTicketsSupportTicketDetailsDto
     */
    'isProductRefunded'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftGatewayCommonDTOsMarketplaceSupportTicketsSupportTicketDetailsDto
     */
    'cancelable'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsMarketplaceSupportTicketsSupportTicketDetailsDto
     */
    'mediationRequestDate'?: string | null;
}


/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsShopsShopsShopUserInformationDto
 */
export interface ApiGiftGatewayCommonDTOsShopsShopsShopUserInformationDto {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsShopsShopsShopUserInformationDto
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsShopsShopsShopUserInformationDto
     */
    'userName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftGatewayCommonDTOsShopsShopsShopUserInformationDto
     */
    'hasTwoFactor'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiGiftGatewayCommonDTOsShopsShopsShopUserInformationDto
     */
    'privileges'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiGiftGatewayCommonDTOsShopsShopsShopUserInformationDto
     */
    'roles'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface AuthenticationCommonCommandsEnableTwoFactorAuthenticationCommandResponse
 */
export interface AuthenticationCommonCommandsEnableTwoFactorAuthenticationCommandResponse {
    /**
     * 
     * @type {boolean}
     * @memberof AuthenticationCommonCommandsEnableTwoFactorAuthenticationCommandResponse
     */
    'isVerified'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AuthenticationCommonCommandsEnableTwoFactorAuthenticationCommandResponse
     */
    'recoveryCodes'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface AuthenticationCommonDtosUserUserRowDto
 */
export interface AuthenticationCommonDtosUserUserRowDto {
    /**
     * 
     * @type {string}
     * @memberof AuthenticationCommonDtosUserUserRowDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationCommonDtosUserUserRowDto
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationCommonDtosUserUserRowDto
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationCommonDtosUserUserRowDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationCommonDtosUserUserRowDto
     */
    'registerDate'?: string | null;
    /**
     * 
     * @type {AuthenticationCommonEnumsUserStatus}
     * @memberof AuthenticationCommonDtosUserUserRowDto
     */
    'status'?: AuthenticationCommonEnumsUserStatus;
    /**
     * 
     * @type {boolean}
     * @memberof AuthenticationCommonDtosUserUserRowDto
     */
    'hasTwoFactor'?: boolean | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const AuthenticationCommonEnumsUserStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type AuthenticationCommonEnumsUserStatus = typeof AuthenticationCommonEnumsUserStatus[keyof typeof AuthenticationCommonEnumsUserStatus];


/**
 * 
 * @export
 * @interface AuthenticationCommonQueriesGetRolesByUserIdQueryResponseRoleRowView
 */
export interface AuthenticationCommonQueriesGetRolesByUserIdQueryResponseRoleRowView {
    /**
     * 
     * @type {string}
     * @memberof AuthenticationCommonQueriesGetRolesByUserIdQueryResponseRoleRowView
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationCommonQueriesGetRolesByUserIdQueryResponseRoleRowView
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationCommonQueriesGetRolesByUserIdQueryResponseRoleRowView
     */
    'key'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AuthenticationCommonQueriesGetRolesByUserIdQueryResponseRoleRowView
     */
    'hasRole'?: boolean;
}
/**
 * 
 * @export
 * @interface AuthenticationCommonQueriesGetUpdateUserByIdQueryQueryResponse
 */
export interface AuthenticationCommonQueriesGetUpdateUserByIdQueryQueryResponse {
    /**
     * 
     * @type {string}
     * @memberof AuthenticationCommonQueriesGetUpdateUserByIdQueryQueryResponse
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationCommonQueriesGetUpdateUserByIdQueryQueryResponse
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationCommonQueriesGetUpdateUserByIdQueryQueryResponse
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationCommonQueriesGetUpdateUserByIdQueryQueryResponse
     */
    'email'?: string | null;
    /**
     * 
     * @type {AuthenticationCommonEnumsUserStatus}
     * @memberof AuthenticationCommonQueriesGetUpdateUserByIdQueryQueryResponse
     */
    'status'?: AuthenticationCommonEnumsUserStatus;
    /**
     * 
     * @type {boolean}
     * @memberof AuthenticationCommonQueriesGetUpdateUserByIdQueryQueryResponse
     */
    'isTwoFactorEnabled'?: boolean;
}


/**
 * 
 * @export
 * @interface IdentityCommonDtosChangeRegisterEmailDTO
 */
export interface IdentityCommonDtosChangeRegisterEmailDTO {
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosChangeRegisterEmailDTO
     */
    'newEmail': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosChangeRegisterEmailDTO
     */
    'returnURL'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosChangeRegisterEmailDTO
     */
    'oldEmail'?: string | null;
}
/**
 * 
 * @export
 * @interface IdentityCommonDtosChangeUserStatusDTO
 */
export interface IdentityCommonDtosChangeUserStatusDTO {
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosChangeUserStatusDTO
     */
    'userId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IdentityCommonDtosChangeUserStatusDTO
     */
    'status'?: number;
}
/**
 * 
 * @export
 * @interface IdentityCommonDtosConfirmEmailDTO
 */
export interface IdentityCommonDtosConfirmEmailDTO {
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosConfirmEmailDTO
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosConfirmEmailDTO
     */
    'returnURL'?: string | null;
}
/**
 * 
 * @export
 * @interface IdentityCommonDtosForgotPasswordDTO
 */
export interface IdentityCommonDtosForgotPasswordDTO {
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosForgotPasswordDTO
     */
    'email'?: string | null;
}
/**
 * 
 * @export
 * @interface IdentityCommonDtosRecoveryTwoFactorDTO
 */
export interface IdentityCommonDtosRecoveryTwoFactorDTO {
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosRecoveryTwoFactorDTO
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosRecoveryTwoFactorDTO
     */
    'password'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IdentityCommonDtosRecoveryTwoFactorDTO
     */
    'disableTwoFactor'?: boolean;
}
/**
 * 
 * @export
 * @interface IdentityCommonDtosRegisterUserDTO
 */
export interface IdentityCommonDtosRegisterUserDTO {
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosRegisterUserDTO
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosRegisterUserDTO
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosRegisterUserDTO
     */
    'firstname': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosRegisterUserDTO
     */
    'lastname': string;
}
/**
 * 
 * @export
 * @interface IdentityCommonDtosResetPasswordDTO
 */
export interface IdentityCommonDtosResetPasswordDTO {
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosResetPasswordDTO
     */
    'currentPassword': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosResetPasswordDTO
     */
    'newPassword': string;
}
/**
 * 
 * @export
 * @interface IdentityCommonDtosSignInDTO
 */
export interface IdentityCommonDtosSignInDTO {
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosSignInDTO
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosSignInDTO
     */
    'password'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosSignInDTO
     */
    'totp'?: string | null;
}
/**
 * 
 * @export
 * @interface IdentityCommonDtosUpdateMyUserInformationDTO
 */
export interface IdentityCommonDtosUpdateMyUserInformationDTO {
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosUpdateMyUserInformationDTO
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosUpdateMyUserInformationDTO
     */
    'lastName'?: string | null;
}
/**
 * 
 * @export
 * @interface IdentityCommonDtosUpdateUserInformationDTO
 */
export interface IdentityCommonDtosUpdateUserInformationDTO {
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosUpdateUserInformationDTO
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosUpdateUserInformationDTO
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosUpdateUserInformationDTO
     */
    'lastName'?: string | null;
}
/**
 * 
 * @export
 * @interface IdentityCommonDtosVerifyForgotPasswordDTO
 */
export interface IdentityCommonDtosVerifyForgotPasswordDTO {
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosVerifyForgotPasswordDTO
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosVerifyForgotPasswordDTO
     */
    'newPassowrd': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosVerifyForgotPasswordDTO
     */
    'userId': string;
}
/**
 * 
 * @export
 * @interface WorkspacesCommonDtosBaseKeyValueDTO
 */
export interface WorkspacesCommonDtosBaseKeyValueDTO {
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosBaseKeyValueDTO
     */
    'key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosBaseKeyValueDTO
     */
    'value'?: string | null;
}
/**
 * 
 * @export
 * @interface WorkspacesCommonDtosBaseTableDTOApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO
 */
export interface WorkspacesCommonDtosBaseTableDTOApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO {
    /**
     * 
     * @type {Array<ApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO>}
     * @memberof WorkspacesCommonDtosBaseTableDTOApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO
     */
    'rows'?: Array<ApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof WorkspacesCommonDtosBaseTableDTOApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO
     */
    'totalRows'?: number;
}
/**
 * 
 * @export
 * @interface WorkspacesCommonDtosBaseTableDTOWorkspacesCommonDtosShopsShopRowDTO
 */
export interface WorkspacesCommonDtosBaseTableDTOWorkspacesCommonDtosShopsShopRowDTO {
    /**
     * 
     * @type {Array<WorkspacesCommonDtosShopsShopRowDTO>}
     * @memberof WorkspacesCommonDtosBaseTableDTOWorkspacesCommonDtosShopsShopRowDTO
     */
    'rows'?: Array<WorkspacesCommonDtosShopsShopRowDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof WorkspacesCommonDtosBaseTableDTOWorkspacesCommonDtosShopsShopRowDTO
     */
    'totalRows'?: number;
}
/**
 * 
 * @export
 * @interface WorkspacesCommonDtosBaseTableDTOWorkspacesCommonDtosSupportTicketsSupportTicketDepartmentRowDTO
 */
export interface WorkspacesCommonDtosBaseTableDTOWorkspacesCommonDtosSupportTicketsSupportTicketDepartmentRowDTO {
    /**
     * 
     * @type {Array<WorkspacesCommonDtosSupportTicketsSupportTicketDepartmentRowDTO>}
     * @memberof WorkspacesCommonDtosBaseTableDTOWorkspacesCommonDtosSupportTicketsSupportTicketDepartmentRowDTO
     */
    'rows'?: Array<WorkspacesCommonDtosSupportTicketsSupportTicketDepartmentRowDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof WorkspacesCommonDtosBaseTableDTOWorkspacesCommonDtosSupportTicketsSupportTicketDepartmentRowDTO
     */
    'totalRows'?: number;
}
/**
 * 
 * @export
 * @interface WorkspacesCommonDtosBaseTableDTOWorkspacesCommonDtosSupportTicketsSupportTicketReplyRowDTO
 */
export interface WorkspacesCommonDtosBaseTableDTOWorkspacesCommonDtosSupportTicketsSupportTicketReplyRowDTO {
    /**
     * 
     * @type {Array<WorkspacesCommonDtosSupportTicketsSupportTicketReplyRowDTO>}
     * @memberof WorkspacesCommonDtosBaseTableDTOWorkspacesCommonDtosSupportTicketsSupportTicketReplyRowDTO
     */
    'rows'?: Array<WorkspacesCommonDtosSupportTicketsSupportTicketReplyRowDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof WorkspacesCommonDtosBaseTableDTOWorkspacesCommonDtosSupportTicketsSupportTicketReplyRowDTO
     */
    'totalRows'?: number;
}
/**
 * 
 * @export
 * @interface WorkspacesCommonDtosBaseTableDTOWorkspacesCommonQueriesSupportTicketsGetPagedArchiveSupportTicketsQueryResponseRow
 */
export interface WorkspacesCommonDtosBaseTableDTOWorkspacesCommonQueriesSupportTicketsGetPagedArchiveSupportTicketsQueryResponseRow {
    /**
     * 
     * @type {Array<WorkspacesCommonQueriesSupportTicketsGetPagedArchiveSupportTicketsQueryResponseRow>}
     * @memberof WorkspacesCommonDtosBaseTableDTOWorkspacesCommonQueriesSupportTicketsGetPagedArchiveSupportTicketsQueryResponseRow
     */
    'rows'?: Array<WorkspacesCommonQueriesSupportTicketsGetPagedArchiveSupportTicketsQueryResponseRow> | null;
    /**
     * 
     * @type {number}
     * @memberof WorkspacesCommonDtosBaseTableDTOWorkspacesCommonQueriesSupportTicketsGetPagedArchiveSupportTicketsQueryResponseRow
     */
    'totalRows'?: number;
}
/**
 * 
 * @export
 * @interface WorkspacesCommonDtosBaseTitleValueDTO
 */
export interface WorkspacesCommonDtosBaseTitleValueDTO {
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosBaseTitleValueDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosBaseTitleValueDTO
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface WorkspacesCommonDtosBaseTitleValueDTOSystemGuid
 */
export interface WorkspacesCommonDtosBaseTitleValueDTOSystemGuid {
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosBaseTitleValueDTOSystemGuid
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosBaseTitleValueDTOSystemGuid
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface WorkspacesCommonDtosShopMembersShopDetailsMemberDTO
 */
export interface WorkspacesCommonDtosShopMembersShopDetailsMemberDTO {
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosShopMembersShopDetailsMemberDTO
     */
    'userDisplayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosShopMembersShopDetailsMemberDTO
     */
    'id'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkspacesCommonDtosShopMembersShopDetailsMemberDTO
     */
    'privileges'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface WorkspacesCommonDtosShopsHandleShopRequestDTO
 */
export interface WorkspacesCommonDtosShopsHandleShopRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosShopsHandleShopRequestDTO
     */
    'shopId'?: string;
    /**
     * 
     * @type {WorkspacesCommonEnumsShopsShopStatus}
     * @memberof WorkspacesCommonDtosShopsHandleShopRequestDTO
     */
    'shopStatus'?: WorkspacesCommonEnumsShopsShopStatus;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosShopsHandleShopRequestDTO
     */
    'handleDescription'?: string | null;
}


/**
 * 
 * @export
 * @interface WorkspacesCommonDtosShopsShopRowDTO
 */
export interface WorkspacesCommonDtosShopsShopRowDTO {
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosShopsShopRowDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosShopsShopRowDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosShopsShopRowDTO
     */
    'number'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WorkspacesCommonDtosShopsShopRowDTO
     */
    'memberCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosShopsShopRowDTO
     */
    'ownerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosShopsShopRowDTO
     */
    'ownerUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosShopsShopRowDTO
     */
    'registerAt'?: string;
    /**
     * 
     * @type {WorkspacesCommonEnumsShopsShopStatus}
     * @memberof WorkspacesCommonDtosShopsShopRowDTO
     */
    'status'?: WorkspacesCommonEnumsShopsShopStatus;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosShopsShopRowDTO
     */
    'createAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosShopsShopRowDTO
     */
    'activeAt'?: string | null;
}


/**
 * 
 * @export
 * @interface WorkspacesCommonDtosSupportTicketsChangeDepartmentDTO
 */
export interface WorkspacesCommonDtosSupportTicketsChangeDepartmentDTO {
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsChangeDepartmentDTO
     */
    'supportTicketId'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsChangeDepartmentDTO
     */
    'departmentId'?: string;
}
/**
 * 
 * @export
 * @interface WorkspacesCommonDtosSupportTicketsCreateGlobalSupportTicketDTO
 */
export interface WorkspacesCommonDtosSupportTicketsCreateGlobalSupportTicketDTO {
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsCreateGlobalSupportTicketDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsCreateGlobalSupportTicketDTO
     */
    'ticketText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsCreateGlobalSupportTicketDTO
     */
    'shopId'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsCreateGlobalSupportTicketDTO
     */
    'departmentId'?: string;
}
/**
 * 
 * @export
 * @interface WorkspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO
 */
export interface WorkspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO {
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO
     */
    'commentText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO
     */
    'supportTicketId'?: string;
}
/**
 * 
 * @export
 * @interface WorkspacesCommonDtosSupportTicketsCreateSupportTicketDepartmentDTO
 */
export interface WorkspacesCommonDtosSupportTicketsCreateSupportTicketDepartmentDTO {
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsCreateSupportTicketDepartmentDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof WorkspacesCommonDtosSupportTicketsCreateSupportTicketDepartmentDTO
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof WorkspacesCommonDtosSupportTicketsCreateSupportTicketDepartmentDTO
     */
    'orderID'?: number;
}
/**
 * 
 * @export
 * @interface WorkspacesCommonDtosSupportTicketsSupportTicketArchiveDetailsDTO
 */
export interface WorkspacesCommonDtosSupportTicketsSupportTicketArchiveDetailsDTO {
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsSupportTicketArchiveDetailsDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsSupportTicketArchiveDetailsDTO
     */
    'ticketNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsSupportTicketArchiveDetailsDTO
     */
    'createAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsSupportTicketArchiveDetailsDTO
     */
    'modifyAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsSupportTicketArchiveDetailsDTO
     */
    'to'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsSupportTicketArchiveDetailsDTO
     */
    'from'?: string | null;
    /**
     * 
     * @type {WorkspacesCommonEnumsSupportTicketsSupportTicketStatus}
     * @memberof WorkspacesCommonDtosSupportTicketsSupportTicketArchiveDetailsDTO
     */
    'status'?: WorkspacesCommonEnumsSupportTicketsSupportTicketStatus;
    /**
     * 
     * @type {boolean}
     * @memberof WorkspacesCommonDtosSupportTicketsSupportTicketArchiveDetailsDTO
     */
    'isGood'?: boolean | null;
    /**
     * 
     * @type {Array<WorkspacesCommonDtosSupportTicketsSupportTicketDetailsCommentDTO>}
     * @memberof WorkspacesCommonDtosSupportTicketsSupportTicketArchiveDetailsDTO
     */
    'comments'?: Array<WorkspacesCommonDtosSupportTicketsSupportTicketDetailsCommentDTO> | null;
    /**
     * 
     * @type {Array<WorkspacesCommonDtosSupportTicketsSupportTicketDetailsReplyDTO>}
     * @memberof WorkspacesCommonDtosSupportTicketsSupportTicketArchiveDetailsDTO
     */
    'replies'?: Array<WorkspacesCommonDtosSupportTicketsSupportTicketDetailsReplyDTO> | null;
}


/**
 * 
 * @export
 * @interface WorkspacesCommonDtosSupportTicketsSupportTicketDepartmentRowDTO
 */
export interface WorkspacesCommonDtosSupportTicketsSupportTicketDepartmentRowDTO {
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsSupportTicketDepartmentRowDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsSupportTicketDepartmentRowDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof WorkspacesCommonDtosSupportTicketsSupportTicketDepartmentRowDTO
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof WorkspacesCommonDtosSupportTicketsSupportTicketDepartmentRowDTO
     */
    'orderId'?: number;
}
/**
 * 
 * @export
 * @interface WorkspacesCommonDtosSupportTicketsSupportTicketDetailsCommentDTO
 */
export interface WorkspacesCommonDtosSupportTicketsSupportTicketDetailsCommentDTO {
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsSupportTicketDetailsCommentDTO
     */
    'userDisplayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsSupportTicketDetailsCommentDTO
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsSupportTicketDetailsCommentDTO
     */
    'createAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsSupportTicketDetailsCommentDTO
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface WorkspacesCommonDtosSupportTicketsSupportTicketDetailsReplyDTO
 */
export interface WorkspacesCommonDtosSupportTicketsSupportTicketDetailsReplyDTO {
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsSupportTicketDetailsReplyDTO
     */
    'userDisplayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsSupportTicketDetailsReplyDTO
     */
    'createAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsSupportTicketDetailsReplyDTO
     */
    'description'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkspacesCommonDtosSupportTicketsSupportTicketDetailsReplyDTO
     */
    'attachmentPaths'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkspacesCommonDtosSupportTicketsSupportTicketDetailsReplyDTO
     */
    'attachmentNames'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsSupportTicketDetailsReplyDTO
     */
    'shopId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof WorkspacesCommonDtosSupportTicketsSupportTicketDetailsReplyDTO
     */
    'isSystemic'?: boolean;
}
/**
 * 
 * @export
 * @interface WorkspacesCommonDtosSupportTicketsSupportTicketReplyRowDTO
 */
export interface WorkspacesCommonDtosSupportTicketsSupportTicketReplyRowDTO {
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsSupportTicketReplyRowDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsSupportTicketReplyRowDTO
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsSupportTicketReplyRowDTO
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsSupportTicketReplyRowDTO
     */
    'createAt'?: string;
}
/**
 * 
 * @export
 * @interface WorkspacesCommonDtosSupportTicketsUpdateSupportTicketDepartmentDTO
 */
export interface WorkspacesCommonDtosSupportTicketsUpdateSupportTicketDepartmentDTO {
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsUpdateSupportTicketDepartmentDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsUpdateSupportTicketDepartmentDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof WorkspacesCommonDtosSupportTicketsUpdateSupportTicketDepartmentDTO
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof WorkspacesCommonDtosSupportTicketsUpdateSupportTicketDepartmentDTO
     */
    'orderID'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const WorkspacesCommonEnumsPoliciesPackageStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
} as const;

export type WorkspacesCommonEnumsPoliciesPackageStatus = typeof WorkspacesCommonEnumsPoliciesPackageStatus[keyof typeof WorkspacesCommonEnumsPoliciesPackageStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const WorkspacesCommonEnumsShopsShopStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type WorkspacesCommonEnumsShopsShopStatus = typeof WorkspacesCommonEnumsShopsShopStatus[keyof typeof WorkspacesCommonEnumsShopsShopStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const WorkspacesCommonEnumsSupportTicketsSupportTicketStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type WorkspacesCommonEnumsSupportTicketsSupportTicketStatus = typeof WorkspacesCommonEnumsSupportTicketsSupportTicketStatus[keyof typeof WorkspacesCommonEnumsSupportTicketsSupportTicketStatus];


/**
 * 
 * @export
 * @interface WorkspacesCommonQueriesSupportTicketsGetPagedArchiveSupportTicketsQueryResponseRow
 */
export interface WorkspacesCommonQueriesSupportTicketsGetPagedArchiveSupportTicketsQueryResponseRow {
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonQueriesSupportTicketsGetPagedArchiveSupportTicketsQueryResponseRow
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonQueriesSupportTicketsGetPagedArchiveSupportTicketsQueryResponseRow
     */
    'from'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonQueriesSupportTicketsGetPagedArchiveSupportTicketsQueryResponseRow
     */
    'ticketNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonQueriesSupportTicketsGetPagedArchiveSupportTicketsQueryResponseRow
     */
    'modifyAt'?: string;
    /**
     * 
     * @type {WorkspacesCommonEnumsSupportTicketsSupportTicketStatus}
     * @memberof WorkspacesCommonQueriesSupportTicketsGetPagedArchiveSupportTicketsQueryResponseRow
     */
    'status'?: WorkspacesCommonEnumsSupportTicketsSupportTicketStatus;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonQueriesSupportTicketsGetPagedArchiveSupportTicketsQueryResponseRow
     */
    'title'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof WorkspacesCommonQueriesSupportTicketsGetPagedArchiveSupportTicketsQueryResponseRow
     */
    'isGood'?: boolean | null;
}


/**
 * 
 * @export
 * @interface WorkspacesCommonQueriesSupportTicketsGetSupportTicketConfigQueryResponseConfigView
 */
export interface WorkspacesCommonQueriesSupportTicketsGetSupportTicketConfigQueryResponseConfigView {
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonQueriesSupportTicketsGetSupportTicketConfigQueryResponseConfigView
     */
    'key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonQueriesSupportTicketsGetSupportTicketConfigQueryResponseConfigView
     */
    'value'?: string | null;
}

/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityAccountInformationsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/Account/Informations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IdentityCommonDtosSignInDTO} [identityCommonDtosSignInDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityAccountPost: async (identityCommonDtosSignInDTO?: IdentityCommonDtosSignInDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/Account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(identityCommonDtosSignInDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityAccountInformationsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsShopsShopsShopUserInformationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityAccountInformationsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.identityAccountInformationsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {IdentityCommonDtosSignInDTO} [identityCommonDtosSignInDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityAccountPost(identityCommonDtosSignInDTO?: IdentityCommonDtosSignInDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityAccountPost(identityCommonDtosSignInDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.identityAccountPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityAccountInformationsGet(options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsShopsShopsShopUserInformationDto> {
            return localVarFp.identityAccountInformationsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IdentityCommonDtosSignInDTO} [identityCommonDtosSignInDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityAccountPost(identityCommonDtosSignInDTO?: IdentityCommonDtosSignInDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.identityAccountPost(identityCommonDtosSignInDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public identityAccountInformationsGet(options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).identityAccountInformationsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IdentityCommonDtosSignInDTO} [identityCommonDtosSignInDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public identityAccountPost(identityCommonDtosSignInDTO?: IdentityCommonDtosSignInDTO, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).identityAccountPost(identityCommonDtosSignInDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ArticleApi - axios parameter creator
 * @export
 */
export const ArticleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [title] 
         * @param {boolean} [published] 
         * @param {string} [articleCategoryId] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArticleGet: async (title?: string, published?: boolean, articleCategoryId?: string, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/Article`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (title !== undefined) {
                localVarQueryParameter['Title'] = title;
            }

            if (published !== undefined) {
                localVarQueryParameter['Published'] = published;
            }

            if (articleCategoryId !== undefined) {
                localVarQueryParameter['ArticleCategoryId'] = articleCategoryId;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArticleIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminArticleIdDelete', 'id', id)
            const localVarPath = `/Admin/Article/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArticleIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminArticleIdGet', 'id', id)
            const localVarPath = `/Admin/Article/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ApiGiftCommonDTOsContentArticlesArticleDTO} [apiGiftCommonDTOsContentArticlesArticleDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArticleIdPut: async (id: string, apiGiftCommonDTOsContentArticlesArticleDTO?: ApiGiftCommonDTOsContentArticlesArticleDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminArticleIdPut', 'id', id)
            const localVarPath = `/Admin/Article/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftCommonDTOsContentArticlesArticleDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsContentArticlesArticleDTO} [apiGiftCommonDTOsContentArticlesArticleDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArticlePost: async (apiGiftCommonDTOsContentArticlesArticleDTO?: ApiGiftCommonDTOsContentArticlesArticleDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/Article`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftCommonDTOsContentArticlesArticleDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArticleApi - functional programming interface
 * @export
 */
export const ArticleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ArticleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [title] 
         * @param {boolean} [published] 
         * @param {string} [articleCategoryId] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminArticleGet(title?: string, published?: boolean, articleCategoryId?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsContentArticlesArticleRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminArticleGet(title, published, articleCategoryId, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ArticleApi.adminArticleGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminArticleIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminArticleIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ArticleApi.adminArticleIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminArticleIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftCommonDTOsContentArticlesArticleRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminArticleIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ArticleApi.adminArticleIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {ApiGiftCommonDTOsContentArticlesArticleDTO} [apiGiftCommonDTOsContentArticlesArticleDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminArticleIdPut(id: string, apiGiftCommonDTOsContentArticlesArticleDTO?: ApiGiftCommonDTOsContentArticlesArticleDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminArticleIdPut(id, apiGiftCommonDTOsContentArticlesArticleDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ArticleApi.adminArticleIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsContentArticlesArticleDTO} [apiGiftCommonDTOsContentArticlesArticleDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminArticlePost(apiGiftCommonDTOsContentArticlesArticleDTO?: ApiGiftCommonDTOsContentArticlesArticleDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminArticlePost(apiGiftCommonDTOsContentArticlesArticleDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ArticleApi.adminArticlePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ArticleApi - factory interface
 * @export
 */
export const ArticleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ArticleApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [title] 
         * @param {boolean} [published] 
         * @param {string} [articleCategoryId] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArticleGet(title?: string, published?: boolean, articleCategoryId?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsContentArticlesArticleRowDTO> {
            return localVarFp.adminArticleGet(title, published, articleCategoryId, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArticleIdDelete(id: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminArticleIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArticleIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<ApiGiftCommonDTOsContentArticlesArticleRowDTO> {
            return localVarFp.adminArticleIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {ApiGiftCommonDTOsContentArticlesArticleDTO} [apiGiftCommonDTOsContentArticlesArticleDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArticleIdPut(id: string, apiGiftCommonDTOsContentArticlesArticleDTO?: ApiGiftCommonDTOsContentArticlesArticleDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminArticleIdPut(id, apiGiftCommonDTOsContentArticlesArticleDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsContentArticlesArticleDTO} [apiGiftCommonDTOsContentArticlesArticleDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArticlePost(apiGiftCommonDTOsContentArticlesArticleDTO?: ApiGiftCommonDTOsContentArticlesArticleDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminArticlePost(apiGiftCommonDTOsContentArticlesArticleDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ArticleApi - object-oriented interface
 * @export
 * @class ArticleApi
 * @extends {BaseAPI}
 */
export class ArticleApi extends BaseAPI {
    /**
     * 
     * @param {string} [title] 
     * @param {boolean} [published] 
     * @param {string} [articleCategoryId] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public adminArticleGet(title?: string, published?: boolean, articleCategoryId?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).adminArticleGet(title, published, articleCategoryId, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public adminArticleIdDelete(id: string, options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).adminArticleIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public adminArticleIdGet(id: string, options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).adminArticleIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {ApiGiftCommonDTOsContentArticlesArticleDTO} [apiGiftCommonDTOsContentArticlesArticleDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public adminArticleIdPut(id: string, apiGiftCommonDTOsContentArticlesArticleDTO?: ApiGiftCommonDTOsContentArticlesArticleDTO, options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).adminArticleIdPut(id, apiGiftCommonDTOsContentArticlesArticleDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiGiftCommonDTOsContentArticlesArticleDTO} [apiGiftCommonDTOsContentArticlesArticleDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public adminArticlePost(apiGiftCommonDTOsContentArticlesArticleDTO?: ApiGiftCommonDTOsContentArticlesArticleDTO, options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).adminArticlePost(apiGiftCommonDTOsContentArticlesArticleDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ArticleCategoryApi - axios parameter creator
 * @export
 */
export const ArticleCategoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [title] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArticleCategoryGet: async (title?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/ArticleCategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArticleCategoryIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminArticleCategoryIdDelete', 'id', id)
            const localVarPath = `/Admin/ArticleCategory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArticleCategoryIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminArticleCategoryIdGet', 'id', id)
            const localVarPath = `/Admin/ArticleCategory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryDTO} [apiGiftCommonDTOsContentArticleCategoriesArticleCategoryDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArticleCategoryIdPut: async (id: string, apiGiftCommonDTOsContentArticleCategoriesArticleCategoryDTO?: ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminArticleCategoryIdPut', 'id', id)
            const localVarPath = `/Admin/ArticleCategory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftCommonDTOsContentArticleCategoriesArticleCategoryDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryDTO} [apiGiftCommonDTOsContentArticleCategoriesArticleCategoryDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArticleCategoryPost: async (apiGiftCommonDTOsContentArticleCategoriesArticleCategoryDTO?: ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/ArticleCategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftCommonDTOsContentArticleCategoriesArticleCategoryDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArticleCategoryTitleValueGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/ArticleCategory/TitleValue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArticleCategoryApi - functional programming interface
 * @export
 */
export const ArticleCategoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ArticleCategoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [title] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminArticleCategoryGet(title?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryRowDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminArticleCategoryGet(title, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ArticleCategoryApi.adminArticleCategoryGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminArticleCategoryIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminArticleCategoryIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ArticleCategoryApi.adminArticleCategoryIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminArticleCategoryIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminArticleCategoryIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ArticleCategoryApi.adminArticleCategoryIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryDTO} [apiGiftCommonDTOsContentArticleCategoriesArticleCategoryDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminArticleCategoryIdPut(id: string, apiGiftCommonDTOsContentArticleCategoriesArticleCategoryDTO?: ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminArticleCategoryIdPut(id, apiGiftCommonDTOsContentArticleCategoriesArticleCategoryDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ArticleCategoryApi.adminArticleCategoryIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryDTO} [apiGiftCommonDTOsContentArticleCategoriesArticleCategoryDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminArticleCategoryPost(apiGiftCommonDTOsContentArticleCategoriesArticleCategoryDTO?: ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminArticleCategoryPost(apiGiftCommonDTOsContentArticleCategoriesArticleCategoryDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ArticleCategoryApi.adminArticleCategoryPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminArticleCategoryTitleValueGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGiftGatewayCommonDTOsBaseTitleValueDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminArticleCategoryTitleValueGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ArticleCategoryApi.adminArticleCategoryTitleValueGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ArticleCategoryApi - factory interface
 * @export
 */
export const ArticleCategoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ArticleCategoryApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [title] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArticleCategoryGet(title?: string, options?: AxiosRequestConfig): AxiosPromise<Array<ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryRowDTO>> {
            return localVarFp.adminArticleCategoryGet(title, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArticleCategoryIdDelete(id: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminArticleCategoryIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArticleCategoryIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryRowDTO> {
            return localVarFp.adminArticleCategoryIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryDTO} [apiGiftCommonDTOsContentArticleCategoriesArticleCategoryDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArticleCategoryIdPut(id: string, apiGiftCommonDTOsContentArticleCategoriesArticleCategoryDTO?: ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminArticleCategoryIdPut(id, apiGiftCommonDTOsContentArticleCategoriesArticleCategoryDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryDTO} [apiGiftCommonDTOsContentArticleCategoriesArticleCategoryDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArticleCategoryPost(apiGiftCommonDTOsContentArticleCategoriesArticleCategoryDTO?: ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminArticleCategoryPost(apiGiftCommonDTOsContentArticleCategoriesArticleCategoryDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArticleCategoryTitleValueGet(options?: AxiosRequestConfig): AxiosPromise<Array<ApiGiftGatewayCommonDTOsBaseTitleValueDTO>> {
            return localVarFp.adminArticleCategoryTitleValueGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ArticleCategoryApi - object-oriented interface
 * @export
 * @class ArticleCategoryApi
 * @extends {BaseAPI}
 */
export class ArticleCategoryApi extends BaseAPI {
    /**
     * 
     * @param {string} [title] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleCategoryApi
     */
    public adminArticleCategoryGet(title?: string, options?: AxiosRequestConfig) {
        return ArticleCategoryApiFp(this.configuration).adminArticleCategoryGet(title, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleCategoryApi
     */
    public adminArticleCategoryIdDelete(id: string, options?: AxiosRequestConfig) {
        return ArticleCategoryApiFp(this.configuration).adminArticleCategoryIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleCategoryApi
     */
    public adminArticleCategoryIdGet(id: string, options?: AxiosRequestConfig) {
        return ArticleCategoryApiFp(this.configuration).adminArticleCategoryIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryDTO} [apiGiftCommonDTOsContentArticleCategoriesArticleCategoryDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleCategoryApi
     */
    public adminArticleCategoryIdPut(id: string, apiGiftCommonDTOsContentArticleCategoriesArticleCategoryDTO?: ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryDTO, options?: AxiosRequestConfig) {
        return ArticleCategoryApiFp(this.configuration).adminArticleCategoryIdPut(id, apiGiftCommonDTOsContentArticleCategoriesArticleCategoryDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryDTO} [apiGiftCommonDTOsContentArticleCategoriesArticleCategoryDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleCategoryApi
     */
    public adminArticleCategoryPost(apiGiftCommonDTOsContentArticleCategoriesArticleCategoryDTO?: ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryDTO, options?: AxiosRequestConfig) {
        return ArticleCategoryApiFp(this.configuration).adminArticleCategoryPost(apiGiftCommonDTOsContentArticleCategoriesArticleCategoryDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleCategoryApi
     */
    public adminArticleCategoryTitleValueGet(options?: AxiosRequestConfig) {
        return ArticleCategoryApiFp(this.configuration).adminArticleCategoryTitleValueGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BuyerApi - axios parameter creator
 * @export
 */
export const BuyerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {WorkspacesCommonEnumsShopsShopStatus} [status] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBuyerGet: async (status?: WorkspacesCommonEnumsShopsShopStatus, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/Buyer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBuyerIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminBuyerIdGet', 'id', id)
            const localVarPath = `/Admin/Buyer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBuyerTitleValueGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/Buyer/TitleValue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BuyerApi - functional programming interface
 * @export
 */
export const BuyerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BuyerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {WorkspacesCommonEnumsShopsShopStatus} [status] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBuyerGet(status?: WorkspacesCommonEnumsShopsShopStatus, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspacesCommonDtosBaseTableDTOWorkspacesCommonDtosShopsShopRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBuyerGet(status, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BuyerApi.adminBuyerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBuyerIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftCommonDTOsShopsShopsBuyerDetailsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBuyerIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BuyerApi.adminBuyerIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBuyerTitleValueGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkspacesCommonDtosBaseTitleValueDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBuyerTitleValueGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BuyerApi.adminBuyerTitleValueGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BuyerApi - factory interface
 * @export
 */
export const BuyerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BuyerApiFp(configuration)
    return {
        /**
         * 
         * @param {WorkspacesCommonEnumsShopsShopStatus} [status] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBuyerGet(status?: WorkspacesCommonEnumsShopsShopStatus, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<WorkspacesCommonDtosBaseTableDTOWorkspacesCommonDtosShopsShopRowDTO> {
            return localVarFp.adminBuyerGet(status, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBuyerIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<ApiGiftCommonDTOsShopsShopsBuyerDetailsDTO> {
            return localVarFp.adminBuyerIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBuyerTitleValueGet(options?: AxiosRequestConfig): AxiosPromise<Array<WorkspacesCommonDtosBaseTitleValueDTO>> {
            return localVarFp.adminBuyerTitleValueGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BuyerApi - object-oriented interface
 * @export
 * @class BuyerApi
 * @extends {BaseAPI}
 */
export class BuyerApi extends BaseAPI {
    /**
     * 
     * @param {WorkspacesCommonEnumsShopsShopStatus} [status] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyerApi
     */
    public adminBuyerGet(status?: WorkspacesCommonEnumsShopsShopStatus, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return BuyerApiFp(this.configuration).adminBuyerGet(status, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyerApi
     */
    public adminBuyerIdGet(id: string, options?: AxiosRequestConfig) {
        return BuyerApiFp(this.configuration).adminBuyerIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyerApi
     */
    public adminBuyerTitleValueGet(options?: AxiosRequestConfig) {
        return BuyerApiFp(this.configuration).adminBuyerTitleValueGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DepositApi - axios parameter creator
 * @export
 */
export const DepositApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialDepositsAcceptDepositDto} [apiGiftGatewayCommonDTOsFinancialDepositsAcceptDepositDto] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        adminDepositAcceptPut: async (apiGiftGatewayCommonDTOsFinancialDepositsAcceptDepositDto?: ApiGiftGatewayCommonDTOsFinancialDepositsAcceptDepositDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/Deposit/Accept`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftGatewayCommonDTOsFinancialDepositsAcceptDepositDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} depositId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        adminDepositRejectDepositIdPut: async (depositId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'depositId' is not null or undefined
            assertParamExists('adminDepositRejectDepositIdPut', 'depositId', depositId)
            const localVarPath = `/Admin/Deposit/Reject/{depositId}`
                .replace(`{${"depositId"}}`, encodeURIComponent(String(depositId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DepositApi - functional programming interface
 * @export
 */
export const DepositApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DepositApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialDepositsAcceptDepositDto} [apiGiftGatewayCommonDTOsFinancialDepositsAcceptDepositDto] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async adminDepositAcceptPut(apiGiftGatewayCommonDTOsFinancialDepositsAcceptDepositDto?: ApiGiftGatewayCommonDTOsFinancialDepositsAcceptDepositDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDepositAcceptPut(apiGiftGatewayCommonDTOsFinancialDepositsAcceptDepositDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepositApi.adminDepositAcceptPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} depositId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async adminDepositRejectDepositIdPut(depositId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDepositRejectDepositIdPut(depositId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepositApi.adminDepositRejectDepositIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DepositApi - factory interface
 * @export
 */
export const DepositApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DepositApiFp(configuration)
    return {
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialDepositsAcceptDepositDto} [apiGiftGatewayCommonDTOsFinancialDepositsAcceptDepositDto] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        adminDepositAcceptPut(apiGiftGatewayCommonDTOsFinancialDepositsAcceptDepositDto?: ApiGiftGatewayCommonDTOsFinancialDepositsAcceptDepositDto, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminDepositAcceptPut(apiGiftGatewayCommonDTOsFinancialDepositsAcceptDepositDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} depositId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        adminDepositRejectDepositIdPut(depositId: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminDepositRejectDepositIdPut(depositId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DepositApi - object-oriented interface
 * @export
 * @class DepositApi
 * @extends {BaseAPI}
 */
export class DepositApi extends BaseAPI {
    /**
     * 
     * @param {ApiGiftGatewayCommonDTOsFinancialDepositsAcceptDepositDto} [apiGiftGatewayCommonDTOsFinancialDepositsAcceptDepositDto] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DepositApi
     */
    public adminDepositAcceptPut(apiGiftGatewayCommonDTOsFinancialDepositsAcceptDepositDto?: ApiGiftGatewayCommonDTOsFinancialDepositsAcceptDepositDto, options?: AxiosRequestConfig) {
        return DepositApiFp(this.configuration).adminDepositAcceptPut(apiGiftGatewayCommonDTOsFinancialDepositsAcceptDepositDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} depositId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DepositApi
     */
    public adminDepositRejectDepositIdPut(depositId: string, options?: AxiosRequestConfig) {
        return DepositApiFp(this.configuration).adminDepositRejectDepositIdPut(depositId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InventoryShopBasePlanApi - axios parameter creator
 * @export
 */
export const InventoryShopBasePlanApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ApiGiftCommonEnumsInventoryInventoryShopsInventoryShopBasePlanStatus} [status] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminInventoryShopBasePlanGet: async (status?: ApiGiftCommonEnumsInventoryInventoryShopsInventoryShopBasePlanStatus, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/InventoryShopBasePlan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminInventoryShopBasePlanIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminInventoryShopBasePlanIdGet', 'id', id)
            const localVarPath = `/Admin/InventoryShopBasePlan/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} inventoryShopBasePlanId 
         * @param {ApiGiftCommonDTOsInventoryInventoryShopBasePlanHandleInventoryCategoryShopBasePlanDTO} [apiGiftCommonDTOsInventoryInventoryShopBasePlanHandleInventoryCategoryShopBasePlanDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminInventoryShopBasePlanInventoryShopBasePlanIdPut: async (inventoryShopBasePlanId: string, apiGiftCommonDTOsInventoryInventoryShopBasePlanHandleInventoryCategoryShopBasePlanDTO?: ApiGiftCommonDTOsInventoryInventoryShopBasePlanHandleInventoryCategoryShopBasePlanDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inventoryShopBasePlanId' is not null or undefined
            assertParamExists('adminInventoryShopBasePlanInventoryShopBasePlanIdPut', 'inventoryShopBasePlanId', inventoryShopBasePlanId)
            const localVarPath = `/Admin/InventoryShopBasePlan/{inventoryShopBasePlanId}`
                .replace(`{${"inventoryShopBasePlanId"}}`, encodeURIComponent(String(inventoryShopBasePlanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftCommonDTOsInventoryInventoryShopBasePlanHandleInventoryCategoryShopBasePlanDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InventoryShopBasePlanApi - functional programming interface
 * @export
 */
export const InventoryShopBasePlanApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InventoryShopBasePlanApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ApiGiftCommonEnumsInventoryInventoryShopsInventoryShopBasePlanStatus} [status] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminInventoryShopBasePlanGet(status?: ApiGiftCommonEnumsInventoryInventoryShopsInventoryShopBasePlanStatus, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsInventoryInventoryShopBasePlanInventoryShopBasePlanRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminInventoryShopBasePlanGet(status, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventoryShopBasePlanApi.adminInventoryShopBasePlanGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminInventoryShopBasePlanIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftCommonDTOsInventoryInventoryShopBasePlanInventoryShopBasePlanDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminInventoryShopBasePlanIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventoryShopBasePlanApi.adminInventoryShopBasePlanIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} inventoryShopBasePlanId 
         * @param {ApiGiftCommonDTOsInventoryInventoryShopBasePlanHandleInventoryCategoryShopBasePlanDTO} [apiGiftCommonDTOsInventoryInventoryShopBasePlanHandleInventoryCategoryShopBasePlanDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminInventoryShopBasePlanInventoryShopBasePlanIdPut(inventoryShopBasePlanId: string, apiGiftCommonDTOsInventoryInventoryShopBasePlanHandleInventoryCategoryShopBasePlanDTO?: ApiGiftCommonDTOsInventoryInventoryShopBasePlanHandleInventoryCategoryShopBasePlanDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminInventoryShopBasePlanInventoryShopBasePlanIdPut(inventoryShopBasePlanId, apiGiftCommonDTOsInventoryInventoryShopBasePlanHandleInventoryCategoryShopBasePlanDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventoryShopBasePlanApi.adminInventoryShopBasePlanInventoryShopBasePlanIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InventoryShopBasePlanApi - factory interface
 * @export
 */
export const InventoryShopBasePlanApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InventoryShopBasePlanApiFp(configuration)
    return {
        /**
         * 
         * @param {ApiGiftCommonEnumsInventoryInventoryShopsInventoryShopBasePlanStatus} [status] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminInventoryShopBasePlanGet(status?: ApiGiftCommonEnumsInventoryInventoryShopsInventoryShopBasePlanStatus, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsInventoryInventoryShopBasePlanInventoryShopBasePlanRowDTO> {
            return localVarFp.adminInventoryShopBasePlanGet(status, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminInventoryShopBasePlanIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<ApiGiftCommonDTOsInventoryInventoryShopBasePlanInventoryShopBasePlanDTO> {
            return localVarFp.adminInventoryShopBasePlanIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} inventoryShopBasePlanId 
         * @param {ApiGiftCommonDTOsInventoryInventoryShopBasePlanHandleInventoryCategoryShopBasePlanDTO} [apiGiftCommonDTOsInventoryInventoryShopBasePlanHandleInventoryCategoryShopBasePlanDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminInventoryShopBasePlanInventoryShopBasePlanIdPut(inventoryShopBasePlanId: string, apiGiftCommonDTOsInventoryInventoryShopBasePlanHandleInventoryCategoryShopBasePlanDTO?: ApiGiftCommonDTOsInventoryInventoryShopBasePlanHandleInventoryCategoryShopBasePlanDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminInventoryShopBasePlanInventoryShopBasePlanIdPut(inventoryShopBasePlanId, apiGiftCommonDTOsInventoryInventoryShopBasePlanHandleInventoryCategoryShopBasePlanDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InventoryShopBasePlanApi - object-oriented interface
 * @export
 * @class InventoryShopBasePlanApi
 * @extends {BaseAPI}
 */
export class InventoryShopBasePlanApi extends BaseAPI {
    /**
     * 
     * @param {ApiGiftCommonEnumsInventoryInventoryShopsInventoryShopBasePlanStatus} [status] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryShopBasePlanApi
     */
    public adminInventoryShopBasePlanGet(status?: ApiGiftCommonEnumsInventoryInventoryShopsInventoryShopBasePlanStatus, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return InventoryShopBasePlanApiFp(this.configuration).adminInventoryShopBasePlanGet(status, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryShopBasePlanApi
     */
    public adminInventoryShopBasePlanIdGet(id: string, options?: AxiosRequestConfig) {
        return InventoryShopBasePlanApiFp(this.configuration).adminInventoryShopBasePlanIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} inventoryShopBasePlanId 
     * @param {ApiGiftCommonDTOsInventoryInventoryShopBasePlanHandleInventoryCategoryShopBasePlanDTO} [apiGiftCommonDTOsInventoryInventoryShopBasePlanHandleInventoryCategoryShopBasePlanDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryShopBasePlanApi
     */
    public adminInventoryShopBasePlanInventoryShopBasePlanIdPut(inventoryShopBasePlanId: string, apiGiftCommonDTOsInventoryInventoryShopBasePlanHandleInventoryCategoryShopBasePlanDTO?: ApiGiftCommonDTOsInventoryInventoryShopBasePlanHandleInventoryCategoryShopBasePlanDTO, options?: AxiosRequestConfig) {
        return InventoryShopBasePlanApiFp(this.configuration).adminInventoryShopBasePlanInventoryShopBasePlanIdPut(inventoryShopBasePlanId, apiGiftCommonDTOsInventoryInventoryShopBasePlanHandleInventoryCategoryShopBasePlanDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MediationApi - axios parameter creator
 * @export
 */
export const MediationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [orderProductId] 
         * @param {string} [ticketId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMediationDetailsGet: async (orderProductId?: string, ticketId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/Mediation/Details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (orderProductId !== undefined) {
                localVarQueryParameter['orderProductId'] = orderProductId;
            }

            if (ticketId !== undefined) {
                localVarQueryParameter['ticketId'] = ticketId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} mediationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMediationMediationIdPut: async (mediationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mediationId' is not null or undefined
            assertParamExists('adminMediationMediationIdPut', 'mediationId', mediationId)
            const localVarPath = `/Admin/Mediation/{mediationId}`
                .replace(`{${"mediationId"}}`, encodeURIComponent(String(mediationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsOrdersMediationCreateDecisionDTO} [apiGiftCommonDTOsOrdersMediationCreateDecisionDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMediationPost: async (apiGiftCommonDTOsOrdersMediationCreateDecisionDTO?: ApiGiftCommonDTOsOrdersMediationCreateDecisionDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/Mediation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftCommonDTOsOrdersMediationCreateDecisionDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMediationReleaseMoneyOrderItemIdBuyerPost: async (orderItemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderItemId' is not null or undefined
            assertParamExists('adminMediationReleaseMoneyOrderItemIdBuyerPost', 'orderItemId', orderItemId)
            const localVarPath = `/Admin/Mediation/ReleaseMoney/{orderItemId}/Buyer`
                .replace(`{${"orderItemId"}}`, encodeURIComponent(String(orderItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMediationReleaseMoneyOrderItemIdSellerPost: async (orderItemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderItemId' is not null or undefined
            assertParamExists('adminMediationReleaseMoneyOrderItemIdSellerPost', 'orderItemId', orderItemId)
            const localVarPath = `/Admin/Mediation/ReleaseMoney/{orderItemId}/Seller`
                .replace(`{${"orderItemId"}}`, encodeURIComponent(String(orderItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MediationApi - functional programming interface
 * @export
 */
export const MediationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MediationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [orderProductId] 
         * @param {string} [ticketId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminMediationDetailsGet(orderProductId?: string, ticketId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftCommonQueriesOrdersMediationGetMediationDetailsQueryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminMediationDetailsGet(orderProductId, ticketId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MediationApi.adminMediationDetailsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} mediationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminMediationMediationIdPut(mediationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminMediationMediationIdPut(mediationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MediationApi.adminMediationMediationIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsOrdersMediationCreateDecisionDTO} [apiGiftCommonDTOsOrdersMediationCreateDecisionDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminMediationPost(apiGiftCommonDTOsOrdersMediationCreateDecisionDTO?: ApiGiftCommonDTOsOrdersMediationCreateDecisionDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminMediationPost(apiGiftCommonDTOsOrdersMediationCreateDecisionDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MediationApi.adminMediationPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} orderItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminMediationReleaseMoneyOrderItemIdBuyerPost(orderItemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminMediationReleaseMoneyOrderItemIdBuyerPost(orderItemId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MediationApi.adminMediationReleaseMoneyOrderItemIdBuyerPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} orderItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminMediationReleaseMoneyOrderItemIdSellerPost(orderItemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminMediationReleaseMoneyOrderItemIdSellerPost(orderItemId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MediationApi.adminMediationReleaseMoneyOrderItemIdSellerPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MediationApi - factory interface
 * @export
 */
export const MediationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MediationApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [orderProductId] 
         * @param {string} [ticketId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMediationDetailsGet(orderProductId?: string, ticketId?: string, options?: AxiosRequestConfig): AxiosPromise<ApiGiftCommonQueriesOrdersMediationGetMediationDetailsQueryResponse> {
            return localVarFp.adminMediationDetailsGet(orderProductId, ticketId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} mediationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMediationMediationIdPut(mediationId: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminMediationMediationIdPut(mediationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsOrdersMediationCreateDecisionDTO} [apiGiftCommonDTOsOrdersMediationCreateDecisionDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMediationPost(apiGiftCommonDTOsOrdersMediationCreateDecisionDTO?: ApiGiftCommonDTOsOrdersMediationCreateDecisionDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminMediationPost(apiGiftCommonDTOsOrdersMediationCreateDecisionDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMediationReleaseMoneyOrderItemIdBuyerPost(orderItemId: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminMediationReleaseMoneyOrderItemIdBuyerPost(orderItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMediationReleaseMoneyOrderItemIdSellerPost(orderItemId: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminMediationReleaseMoneyOrderItemIdSellerPost(orderItemId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MediationApi - object-oriented interface
 * @export
 * @class MediationApi
 * @extends {BaseAPI}
 */
export class MediationApi extends BaseAPI {
    /**
     * 
     * @param {string} [orderProductId] 
     * @param {string} [ticketId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediationApi
     */
    public adminMediationDetailsGet(orderProductId?: string, ticketId?: string, options?: AxiosRequestConfig) {
        return MediationApiFp(this.configuration).adminMediationDetailsGet(orderProductId, ticketId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} mediationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediationApi
     */
    public adminMediationMediationIdPut(mediationId: string, options?: AxiosRequestConfig) {
        return MediationApiFp(this.configuration).adminMediationMediationIdPut(mediationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiGiftCommonDTOsOrdersMediationCreateDecisionDTO} [apiGiftCommonDTOsOrdersMediationCreateDecisionDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediationApi
     */
    public adminMediationPost(apiGiftCommonDTOsOrdersMediationCreateDecisionDTO?: ApiGiftCommonDTOsOrdersMediationCreateDecisionDTO, options?: AxiosRequestConfig) {
        return MediationApiFp(this.configuration).adminMediationPost(apiGiftCommonDTOsOrdersMediationCreateDecisionDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediationApi
     */
    public adminMediationReleaseMoneyOrderItemIdBuyerPost(orderItemId: string, options?: AxiosRequestConfig) {
        return MediationApiFp(this.configuration).adminMediationReleaseMoneyOrderItemIdBuyerPost(orderItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediationApi
     */
    public adminMediationReleaseMoneyOrderItemIdSellerPost(orderItemId: string, options?: AxiosRequestConfig) {
        return MediationApiFp(this.configuration).adminMediationReleaseMoneyOrderItemIdSellerPost(orderItemId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * NotificationPermissionApi - axios parameter creator
 * @export
 */
export const NotificationPermissionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminNotificationPermissionGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/NotificationPermission`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<ApiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO>} [apiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminNotificationPermissionPut: async (apiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO?: Array<ApiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/NotificationPermission`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationPermissionApi - functional programming interface
 * @export
 */
export const NotificationPermissionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationPermissionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminNotificationPermissionGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGiftGatewayCommonDTOsMarketplaceNotificationsNotificationPermissionRowDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminNotificationPermissionGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationPermissionApi.adminNotificationPermissionGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<ApiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO>} [apiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminNotificationPermissionPut(apiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO?: Array<ApiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminNotificationPermissionPut(apiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationPermissionApi.adminNotificationPermissionPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * NotificationPermissionApi - factory interface
 * @export
 */
export const NotificationPermissionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationPermissionApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminNotificationPermissionGet(options?: AxiosRequestConfig): AxiosPromise<Array<ApiGiftGatewayCommonDTOsMarketplaceNotificationsNotificationPermissionRowDTO>> {
            return localVarFp.adminNotificationPermissionGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<ApiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO>} [apiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminNotificationPermissionPut(apiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO?: Array<ApiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO>, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminNotificationPermissionPut(apiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationPermissionApi - object-oriented interface
 * @export
 * @class NotificationPermissionApi
 * @extends {BaseAPI}
 */
export class NotificationPermissionApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationPermissionApi
     */
    public adminNotificationPermissionGet(options?: AxiosRequestConfig) {
        return NotificationPermissionApiFp(this.configuration).adminNotificationPermissionGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<ApiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO>} [apiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationPermissionApi
     */
    public adminNotificationPermissionPut(apiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO?: Array<ApiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO>, options?: AxiosRequestConfig) {
        return NotificationPermissionApiFp(this.configuration).adminNotificationPermissionPut(apiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OrderProductApi - axios parameter creator
 * @export
 */
export const OrderProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderProductReportGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/OrderProduct/Report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {number} [timePeriod] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderProductSharePriceStatisticsGet: async (fromDate?: string, toDate?: string, timePeriod?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/OrderProduct/SharePrice/Statistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['FromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['ToDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (timePeriod !== undefined) {
                localVarQueryParameter['TimePeriod'] = timePeriod;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {number} [timePeriod] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderProductStatisticsGet: async (fromDate?: string, toDate?: string, timePeriod?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/OrderProduct/Statistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['FromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['ToDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (timePeriod !== undefined) {
                localVarQueryParameter['TimePeriod'] = timePeriod;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderProductApi - functional programming interface
 * @export
 */
export const OrderProductApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderProductApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrderProductReportGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftCommonQueriesOrdersOrdersGetSalesReportQueryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrderProductReportGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderProductApi.adminOrderProductReportGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {number} [timePeriod] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrderProductSharePriceStatisticsGet(fromDate?: string, toDate?: string, timePeriod?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: number; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrderProductSharePriceStatisticsGet(fromDate, toDate, timePeriod, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderProductApi.adminOrderProductSharePriceStatisticsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {number} [timePeriod] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrderProductStatisticsGet(fromDate?: string, toDate?: string, timePeriod?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: number; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrderProductStatisticsGet(fromDate, toDate, timePeriod, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderProductApi.adminOrderProductStatisticsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OrderProductApi - factory interface
 * @export
 */
export const OrderProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderProductApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderProductReportGet(options?: AxiosRequestConfig): AxiosPromise<ApiGiftCommonQueriesOrdersOrdersGetSalesReportQueryResponse> {
            return localVarFp.adminOrderProductReportGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {number} [timePeriod] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderProductSharePriceStatisticsGet(fromDate?: string, toDate?: string, timePeriod?: number, options?: AxiosRequestConfig): AxiosPromise<{ [key: string]: number; }> {
            return localVarFp.adminOrderProductSharePriceStatisticsGet(fromDate, toDate, timePeriod, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {number} [timePeriod] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderProductStatisticsGet(fromDate?: string, toDate?: string, timePeriod?: number, options?: AxiosRequestConfig): AxiosPromise<{ [key: string]: number; }> {
            return localVarFp.adminOrderProductStatisticsGet(fromDate, toDate, timePeriod, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderProductApi - object-oriented interface
 * @export
 * @class OrderProductApi
 * @extends {BaseAPI}
 */
export class OrderProductApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderProductApi
     */
    public adminOrderProductReportGet(options?: AxiosRequestConfig) {
        return OrderProductApiFp(this.configuration).adminOrderProductReportGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {number} [timePeriod] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderProductApi
     */
    public adminOrderProductSharePriceStatisticsGet(fromDate?: string, toDate?: string, timePeriod?: number, options?: AxiosRequestConfig) {
        return OrderProductApiFp(this.configuration).adminOrderProductSharePriceStatisticsGet(fromDate, toDate, timePeriod, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {number} [timePeriod] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderProductApi
     */
    public adminOrderProductStatisticsGet(fromDate?: string, toDate?: string, timePeriod?: number, options?: AxiosRequestConfig) {
        return OrderProductApiFp(this.configuration).adminOrderProductStatisticsGet(fromDate, toDate, timePeriod, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PasswordApi - axios parameter creator
 * @export
 */
export const PasswordApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [returnURL] 
         * @param {IdentityCommonDtosForgotPasswordDTO} [identityCommonDtosForgotPasswordDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityPasswordForgotPost: async (returnURL?: string, identityCommonDtosForgotPasswordDTO?: IdentityCommonDtosForgotPasswordDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/Password/Forgot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (returnURL !== undefined) {
                localVarQueryParameter['returnURL'] = returnURL;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(identityCommonDtosForgotPasswordDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [returnURL] 
         * @param {IdentityCommonDtosVerifyForgotPasswordDTO} [identityCommonDtosVerifyForgotPasswordDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityPasswordForgotVerifyPost: async (returnURL?: string, identityCommonDtosVerifyForgotPasswordDTO?: IdentityCommonDtosVerifyForgotPasswordDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/Password/Forgot/Verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (returnURL !== undefined) {
                localVarQueryParameter['returnURL'] = returnURL;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(identityCommonDtosVerifyForgotPasswordDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IdentityCommonDtosResetPasswordDTO} [identityCommonDtosResetPasswordDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityPasswordResetPost: async (identityCommonDtosResetPasswordDTO?: IdentityCommonDtosResetPasswordDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/Password/Reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(identityCommonDtosResetPasswordDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PasswordApi - functional programming interface
 * @export
 */
export const PasswordApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PasswordApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [returnURL] 
         * @param {IdentityCommonDtosForgotPasswordDTO} [identityCommonDtosForgotPasswordDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityPasswordForgotPost(returnURL?: string, identityCommonDtosForgotPasswordDTO?: IdentityCommonDtosForgotPasswordDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityPasswordForgotPost(returnURL, identityCommonDtosForgotPasswordDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PasswordApi.identityPasswordForgotPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [returnURL] 
         * @param {IdentityCommonDtosVerifyForgotPasswordDTO} [identityCommonDtosVerifyForgotPasswordDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityPasswordForgotVerifyPost(returnURL?: string, identityCommonDtosVerifyForgotPasswordDTO?: IdentityCommonDtosVerifyForgotPasswordDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityPasswordForgotVerifyPost(returnURL, identityCommonDtosVerifyForgotPasswordDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PasswordApi.identityPasswordForgotVerifyPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {IdentityCommonDtosResetPasswordDTO} [identityCommonDtosResetPasswordDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityPasswordResetPost(identityCommonDtosResetPasswordDTO?: IdentityCommonDtosResetPasswordDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityPasswordResetPost(identityCommonDtosResetPasswordDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PasswordApi.identityPasswordResetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PasswordApi - factory interface
 * @export
 */
export const PasswordApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PasswordApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [returnURL] 
         * @param {IdentityCommonDtosForgotPasswordDTO} [identityCommonDtosForgotPasswordDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityPasswordForgotPost(returnURL?: string, identityCommonDtosForgotPasswordDTO?: IdentityCommonDtosForgotPasswordDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.identityPasswordForgotPost(returnURL, identityCommonDtosForgotPasswordDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [returnURL] 
         * @param {IdentityCommonDtosVerifyForgotPasswordDTO} [identityCommonDtosVerifyForgotPasswordDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityPasswordForgotVerifyPost(returnURL?: string, identityCommonDtosVerifyForgotPasswordDTO?: IdentityCommonDtosVerifyForgotPasswordDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.identityPasswordForgotVerifyPost(returnURL, identityCommonDtosVerifyForgotPasswordDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IdentityCommonDtosResetPasswordDTO} [identityCommonDtosResetPasswordDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityPasswordResetPost(identityCommonDtosResetPasswordDTO?: IdentityCommonDtosResetPasswordDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.identityPasswordResetPost(identityCommonDtosResetPasswordDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PasswordApi - object-oriented interface
 * @export
 * @class PasswordApi
 * @extends {BaseAPI}
 */
export class PasswordApi extends BaseAPI {
    /**
     * 
     * @param {string} [returnURL] 
     * @param {IdentityCommonDtosForgotPasswordDTO} [identityCommonDtosForgotPasswordDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordApi
     */
    public identityPasswordForgotPost(returnURL?: string, identityCommonDtosForgotPasswordDTO?: IdentityCommonDtosForgotPasswordDTO, options?: AxiosRequestConfig) {
        return PasswordApiFp(this.configuration).identityPasswordForgotPost(returnURL, identityCommonDtosForgotPasswordDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [returnURL] 
     * @param {IdentityCommonDtosVerifyForgotPasswordDTO} [identityCommonDtosVerifyForgotPasswordDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordApi
     */
    public identityPasswordForgotVerifyPost(returnURL?: string, identityCommonDtosVerifyForgotPasswordDTO?: IdentityCommonDtosVerifyForgotPasswordDTO, options?: AxiosRequestConfig) {
        return PasswordApiFp(this.configuration).identityPasswordForgotVerifyPost(returnURL, identityCommonDtosVerifyForgotPasswordDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IdentityCommonDtosResetPasswordDTO} [identityCommonDtosResetPasswordDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordApi
     */
    public identityPasswordResetPost(identityCommonDtosResetPasswordDTO?: IdentityCommonDtosResetPasswordDTO, options?: AxiosRequestConfig) {
        return PasswordApiFp(this.configuration).identityPasswordResetPost(identityCommonDtosResetPasswordDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProductApi - axios parameter creator
 * @export
 */
export const ProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductGet: async (pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/Product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminProductIdDelete', 'id', id)
            const localVarPath = `/Admin/Product/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminProductIdGet', 'id', id)
            const localVarPath = `/Admin/Product/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ApiGiftCommonDTOsShopsProductsProductDTO} [apiGiftCommonDTOsShopsProductsProductDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductIdPut: async (id: string, apiGiftCommonDTOsShopsProductsProductDTO?: ApiGiftCommonDTOsShopsProductsProductDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminProductIdPut', 'id', id)
            const localVarPath = `/Admin/Product/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftCommonDTOsShopsProductsProductDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsShopsProductsProductDTO} [apiGiftCommonDTOsShopsProductsProductDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductPost: async (apiGiftCommonDTOsShopsProductsProductDTO?: ApiGiftCommonDTOsShopsProductsProductDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/Product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftCommonDTOsShopsProductsProductDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductApi - functional programming interface
 * @export
 */
export const ProductApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductGet(pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsShopsProductsProductRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductGet(pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductApi.adminProductGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductApi.adminProductIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftCommonDTOsShopsProductsProductDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductApi.adminProductIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {ApiGiftCommonDTOsShopsProductsProductDTO} [apiGiftCommonDTOsShopsProductsProductDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductIdPut(id: string, apiGiftCommonDTOsShopsProductsProductDTO?: ApiGiftCommonDTOsShopsProductsProductDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductIdPut(id, apiGiftCommonDTOsShopsProductsProductDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductApi.adminProductIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsShopsProductsProductDTO} [apiGiftCommonDTOsShopsProductsProductDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductPost(apiGiftCommonDTOsShopsProductsProductDTO?: ApiGiftCommonDTOsShopsProductsProductDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductPost(apiGiftCommonDTOsShopsProductsProductDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductApi.adminProductPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProductApi - factory interface
 * @export
 */
export const ProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductGet(pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsShopsProductsProductRowDTO> {
            return localVarFp.adminProductGet(pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductIdDelete(id: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminProductIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<ApiGiftCommonDTOsShopsProductsProductDTO> {
            return localVarFp.adminProductIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {ApiGiftCommonDTOsShopsProductsProductDTO} [apiGiftCommonDTOsShopsProductsProductDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductIdPut(id: string, apiGiftCommonDTOsShopsProductsProductDTO?: ApiGiftCommonDTOsShopsProductsProductDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminProductIdPut(id, apiGiftCommonDTOsShopsProductsProductDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsShopsProductsProductDTO} [apiGiftCommonDTOsShopsProductsProductDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductPost(apiGiftCommonDTOsShopsProductsProductDTO?: ApiGiftCommonDTOsShopsProductsProductDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminProductPost(apiGiftCommonDTOsShopsProductsProductDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductApi - object-oriented interface
 * @export
 * @class ProductApi
 * @extends {BaseAPI}
 */
export class ProductApi extends BaseAPI {
    /**
     * 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public adminProductGet(pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).adminProductGet(pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public adminProductIdDelete(id: string, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).adminProductIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public adminProductIdGet(id: string, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).adminProductIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {ApiGiftCommonDTOsShopsProductsProductDTO} [apiGiftCommonDTOsShopsProductsProductDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public adminProductIdPut(id: string, apiGiftCommonDTOsShopsProductsProductDTO?: ApiGiftCommonDTOsShopsProductsProductDTO, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).adminProductIdPut(id, apiGiftCommonDTOsShopsProductsProductDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiGiftCommonDTOsShopsProductsProductDTO} [apiGiftCommonDTOsShopsProductsProductDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public adminProductPost(apiGiftCommonDTOsShopsProductsProductDTO?: ApiGiftCommonDTOsShopsProductsProductDTO, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).adminProductPost(apiGiftCommonDTOsShopsProductsProductDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProductCategoryApi - axios parameter creator
 * @export
 */
export const ProductCategoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductCategoryGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/ProductCategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductCategoryIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminProductCategoryIdDelete', 'id', id)
            const localVarPath = `/Admin/ProductCategory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductCategoryIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminProductCategoryIdGet', 'id', id)
            const localVarPath = `/Admin/ProductCategory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ApiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO} [apiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductCategoryIdPut: async (id: string, apiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO?: ApiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminProductCategoryIdPut', 'id', id)
            const localVarPath = `/Admin/ProductCategory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductCategoryMultiLevelGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/ProductCategory/MultiLevel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO} [apiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductCategoryPost: async (apiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO?: ApiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/ProductCategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductCategoryTitleValueGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/ProductCategory/TitleValue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductCategoryApi - functional programming interface
 * @export
 */
export const ProductCategoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductCategoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductCategoryGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsShopsProductCategoriesProductCategoryRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductCategoryGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductCategoryApi.adminProductCategoryGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductCategoryIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductCategoryIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductCategoryApi.adminProductCategoryIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductCategoryIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductCategoryIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductCategoryApi.adminProductCategoryIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {ApiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO} [apiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductCategoryIdPut(id: string, apiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO?: ApiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductCategoryIdPut(id, apiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductCategoryApi.adminProductCategoryIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductCategoryMultiLevelGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGiftCommonDTOsShopsProductCategoriesProductCategoryMultiLevelDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductCategoryMultiLevelGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductCategoryApi.adminProductCategoryMultiLevelGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO} [apiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductCategoryPost(apiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO?: ApiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductCategoryPost(apiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductCategoryApi.adminProductCategoryPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductCategoryTitleValueGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGiftGatewayCommonDTOsBaseTitleValueDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductCategoryTitleValueGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductCategoryApi.adminProductCategoryTitleValueGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProductCategoryApi - factory interface
 * @export
 */
export const ProductCategoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductCategoryApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductCategoryGet(options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsShopsProductCategoriesProductCategoryRowDTO> {
            return localVarFp.adminProductCategoryGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductCategoryIdDelete(id: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminProductCategoryIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductCategoryIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<ApiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO> {
            return localVarFp.adminProductCategoryIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {ApiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO} [apiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductCategoryIdPut(id: string, apiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO?: ApiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminProductCategoryIdPut(id, apiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductCategoryMultiLevelGet(options?: AxiosRequestConfig): AxiosPromise<Array<ApiGiftCommonDTOsShopsProductCategoriesProductCategoryMultiLevelDTO>> {
            return localVarFp.adminProductCategoryMultiLevelGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO} [apiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductCategoryPost(apiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO?: ApiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminProductCategoryPost(apiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductCategoryTitleValueGet(options?: AxiosRequestConfig): AxiosPromise<Array<ApiGiftGatewayCommonDTOsBaseTitleValueDTO>> {
            return localVarFp.adminProductCategoryTitleValueGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductCategoryApi - object-oriented interface
 * @export
 * @class ProductCategoryApi
 * @extends {BaseAPI}
 */
export class ProductCategoryApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCategoryApi
     */
    public adminProductCategoryGet(options?: AxiosRequestConfig) {
        return ProductCategoryApiFp(this.configuration).adminProductCategoryGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCategoryApi
     */
    public adminProductCategoryIdDelete(id: string, options?: AxiosRequestConfig) {
        return ProductCategoryApiFp(this.configuration).adminProductCategoryIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCategoryApi
     */
    public adminProductCategoryIdGet(id: string, options?: AxiosRequestConfig) {
        return ProductCategoryApiFp(this.configuration).adminProductCategoryIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {ApiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO} [apiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCategoryApi
     */
    public adminProductCategoryIdPut(id: string, apiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO?: ApiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO, options?: AxiosRequestConfig) {
        return ProductCategoryApiFp(this.configuration).adminProductCategoryIdPut(id, apiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCategoryApi
     */
    public adminProductCategoryMultiLevelGet(options?: AxiosRequestConfig) {
        return ProductCategoryApiFp(this.configuration).adminProductCategoryMultiLevelGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO} [apiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCategoryApi
     */
    public adminProductCategoryPost(apiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO?: ApiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO, options?: AxiosRequestConfig) {
        return ProductCategoryApiFp(this.configuration).adminProductCategoryPost(apiGiftCommonDTOsShopsProductCategoriesProductCategoryDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCategoryApi
     */
    public adminProductCategoryTitleValueGet(options?: AxiosRequestConfig) {
        return ProductCategoryApiFp(this.configuration).adminProductCategoryTitleValueGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RegisterApi - axios parameter creator
 * @export
 */
export const RegisterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {string} [token] 
         * @param {string} [returnURL] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityRegisterConfirmGet: async (id?: string, token?: string, returnURL?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/Register/Confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }

            if (token !== undefined) {
                localVarQueryParameter['Token'] = token;
            }

            if (returnURL !== undefined) {
                localVarQueryParameter['returnURL'] = returnURL;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [returnURL] 
         * @param {IdentityCommonDtosRegisterUserDTO} [identityCommonDtosRegisterUserDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityRegisterPost: async (returnURL?: string, identityCommonDtosRegisterUserDTO?: IdentityCommonDtosRegisterUserDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/Register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (returnURL !== undefined) {
                localVarQueryParameter['returnURL'] = returnURL;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(identityCommonDtosRegisterUserDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IdentityCommonDtosChangeRegisterEmailDTO} [identityCommonDtosChangeRegisterEmailDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityRegisterPut: async (identityCommonDtosChangeRegisterEmailDTO?: IdentityCommonDtosChangeRegisterEmailDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/Register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(identityCommonDtosChangeRegisterEmailDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RegisterApi - functional programming interface
 * @export
 */
export const RegisterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RegisterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {string} [token] 
         * @param {string} [returnURL] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityRegisterConfirmGet(id?: string, token?: string, returnURL?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityRegisterConfirmGet(id, token, returnURL, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RegisterApi.identityRegisterConfirmGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [returnURL] 
         * @param {IdentityCommonDtosRegisterUserDTO} [identityCommonDtosRegisterUserDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityRegisterPost(returnURL?: string, identityCommonDtosRegisterUserDTO?: IdentityCommonDtosRegisterUserDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityRegisterPost(returnURL, identityCommonDtosRegisterUserDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RegisterApi.identityRegisterPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {IdentityCommonDtosChangeRegisterEmailDTO} [identityCommonDtosChangeRegisterEmailDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityRegisterPut(identityCommonDtosChangeRegisterEmailDTO?: IdentityCommonDtosChangeRegisterEmailDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityRegisterPut(identityCommonDtosChangeRegisterEmailDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RegisterApi.identityRegisterPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RegisterApi - factory interface
 * @export
 */
export const RegisterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RegisterApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {string} [token] 
         * @param {string} [returnURL] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityRegisterConfirmGet(id?: string, token?: string, returnURL?: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.identityRegisterConfirmGet(id, token, returnURL, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [returnURL] 
         * @param {IdentityCommonDtosRegisterUserDTO} [identityCommonDtosRegisterUserDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityRegisterPost(returnURL?: string, identityCommonDtosRegisterUserDTO?: IdentityCommonDtosRegisterUserDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.identityRegisterPost(returnURL, identityCommonDtosRegisterUserDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IdentityCommonDtosChangeRegisterEmailDTO} [identityCommonDtosChangeRegisterEmailDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityRegisterPut(identityCommonDtosChangeRegisterEmailDTO?: IdentityCommonDtosChangeRegisterEmailDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.identityRegisterPut(identityCommonDtosChangeRegisterEmailDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RegisterApi - object-oriented interface
 * @export
 * @class RegisterApi
 * @extends {BaseAPI}
 */
export class RegisterApi extends BaseAPI {
    /**
     * 
     * @param {string} [id] 
     * @param {string} [token] 
     * @param {string} [returnURL] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegisterApi
     */
    public identityRegisterConfirmGet(id?: string, token?: string, returnURL?: string, options?: AxiosRequestConfig) {
        return RegisterApiFp(this.configuration).identityRegisterConfirmGet(id, token, returnURL, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [returnURL] 
     * @param {IdentityCommonDtosRegisterUserDTO} [identityCommonDtosRegisterUserDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegisterApi
     */
    public identityRegisterPost(returnURL?: string, identityCommonDtosRegisterUserDTO?: IdentityCommonDtosRegisterUserDTO, options?: AxiosRequestConfig) {
        return RegisterApiFp(this.configuration).identityRegisterPost(returnURL, identityCommonDtosRegisterUserDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IdentityCommonDtosChangeRegisterEmailDTO} [identityCommonDtosChangeRegisterEmailDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegisterApi
     */
    public identityRegisterPut(identityCommonDtosChangeRegisterEmailDTO?: IdentityCommonDtosChangeRegisterEmailDTO, options?: AxiosRequestConfig) {
        return RegisterApiFp(this.configuration).identityRegisterPut(identityCommonDtosChangeRegisterEmailDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SecurityApi - axios parameter creator
 * @export
 */
export const SecurityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {IdentityCommonDtosConfirmEmailDTO} [identityCommonDtosConfirmEmailDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identitySecurityConfirmEmailPut: async (identityCommonDtosConfirmEmailDTO?: IdentityCommonDtosConfirmEmailDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/Security/ConfirmEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(identityCommonDtosConfirmEmailDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identitySecurityGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/Security`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SecurityApi - functional programming interface
 * @export
 */
export const SecurityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SecurityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {IdentityCommonDtosConfirmEmailDTO} [identityCommonDtosConfirmEmailDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identitySecurityConfirmEmailPut(identityCommonDtosConfirmEmailDTO?: IdentityCommonDtosConfirmEmailDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identitySecurityConfirmEmailPut(identityCommonDtosConfirmEmailDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SecurityApi.identitySecurityConfirmEmailPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identitySecurityGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identitySecurityGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SecurityApi.identitySecurityGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SecurityApi - factory interface
 * @export
 */
export const SecurityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SecurityApiFp(configuration)
    return {
        /**
         * 
         * @param {IdentityCommonDtosConfirmEmailDTO} [identityCommonDtosConfirmEmailDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identitySecurityConfirmEmailPut(identityCommonDtosConfirmEmailDTO?: IdentityCommonDtosConfirmEmailDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.identitySecurityConfirmEmailPut(identityCommonDtosConfirmEmailDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identitySecurityGet(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.identitySecurityGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SecurityApi - object-oriented interface
 * @export
 * @class SecurityApi
 * @extends {BaseAPI}
 */
export class SecurityApi extends BaseAPI {
    /**
     * 
     * @param {IdentityCommonDtosConfirmEmailDTO} [identityCommonDtosConfirmEmailDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecurityApi
     */
    public identitySecurityConfirmEmailPut(identityCommonDtosConfirmEmailDTO?: IdentityCommonDtosConfirmEmailDTO, options?: AxiosRequestConfig) {
        return SecurityApiFp(this.configuration).identitySecurityConfirmEmailPut(identityCommonDtosConfirmEmailDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecurityApi
     */
    public identitySecurityGet(options?: AxiosRequestConfig) {
        return SecurityApiFp(this.configuration).identitySecurityGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SellerApi - axios parameter creator
 * @export
 */
export const SellerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {WorkspacesCommonEnumsShopsShopStatus} [status] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSellerGet: async (status?: WorkspacesCommonEnumsShopsShopStatus, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/Seller`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SellerApi - functional programming interface
 * @export
 */
export const SellerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SellerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {WorkspacesCommonEnumsShopsShopStatus} [status] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSellerGet(status?: WorkspacesCommonEnumsShopsShopStatus, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspacesCommonDtosBaseTableDTOWorkspacesCommonDtosShopsShopRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSellerGet(status, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SellerApi.adminSellerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SellerApi - factory interface
 * @export
 */
export const SellerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SellerApiFp(configuration)
    return {
        /**
         * 
         * @param {WorkspacesCommonEnumsShopsShopStatus} [status] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSellerGet(status?: WorkspacesCommonEnumsShopsShopStatus, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<WorkspacesCommonDtosBaseTableDTOWorkspacesCommonDtosShopsShopRowDTO> {
            return localVarFp.adminSellerGet(status, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SellerApi - object-oriented interface
 * @export
 * @class SellerApi
 * @extends {BaseAPI}
 */
export class SellerApi extends BaseAPI {
    /**
     * 
     * @param {WorkspacesCommonEnumsShopsShopStatus} [status] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerApi
     */
    public adminSellerGet(status?: WorkspacesCommonEnumsShopsShopStatus, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return SellerApiFp(this.configuration).adminSellerGet(status, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ShopApi - axios parameter creator
 * @export
 */
export const ShopApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {WorkspacesCommonEnumsShopsShopStatus} [status] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminShopGet: async (status?: WorkspacesCommonEnumsShopsShopStatus, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/Shop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WorkspacesCommonDtosShopsHandleShopRequestDTO} [workspacesCommonDtosShopsHandleShopRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminShopPut: async (workspacesCommonDtosShopsHandleShopRequestDTO?: WorkspacesCommonDtosShopsHandleShopRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/Shop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workspacesCommonDtosShopsHandleShopRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminShopTitleValueGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/Shop/TitleValue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShopApi - functional programming interface
 * @export
 */
export const ShopApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShopApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {WorkspacesCommonEnumsShopsShopStatus} [status] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminShopGet(status?: WorkspacesCommonEnumsShopsShopStatus, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspacesCommonDtosBaseTableDTOWorkspacesCommonDtosShopsShopRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminShopGet(status, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShopApi.adminShopGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {WorkspacesCommonDtosShopsHandleShopRequestDTO} [workspacesCommonDtosShopsHandleShopRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminShopPut(workspacesCommonDtosShopsHandleShopRequestDTO?: WorkspacesCommonDtosShopsHandleShopRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminShopPut(workspacesCommonDtosShopsHandleShopRequestDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShopApi.adminShopPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminShopTitleValueGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkspacesCommonDtosBaseTitleValueDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminShopTitleValueGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShopApi.adminShopTitleValueGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ShopApi - factory interface
 * @export
 */
export const ShopApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShopApiFp(configuration)
    return {
        /**
         * 
         * @param {WorkspacesCommonEnumsShopsShopStatus} [status] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminShopGet(status?: WorkspacesCommonEnumsShopsShopStatus, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<WorkspacesCommonDtosBaseTableDTOWorkspacesCommonDtosShopsShopRowDTO> {
            return localVarFp.adminShopGet(status, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WorkspacesCommonDtosShopsHandleShopRequestDTO} [workspacesCommonDtosShopsHandleShopRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminShopPut(workspacesCommonDtosShopsHandleShopRequestDTO?: WorkspacesCommonDtosShopsHandleShopRequestDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminShopPut(workspacesCommonDtosShopsHandleShopRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminShopTitleValueGet(options?: AxiosRequestConfig): AxiosPromise<Array<WorkspacesCommonDtosBaseTitleValueDTO>> {
            return localVarFp.adminShopTitleValueGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShopApi - object-oriented interface
 * @export
 * @class ShopApi
 * @extends {BaseAPI}
 */
export class ShopApi extends BaseAPI {
    /**
     * 
     * @param {WorkspacesCommonEnumsShopsShopStatus} [status] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    public adminShopGet(status?: WorkspacesCommonEnumsShopsShopStatus, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return ShopApiFp(this.configuration).adminShopGet(status, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkspacesCommonDtosShopsHandleShopRequestDTO} [workspacesCommonDtosShopsHandleShopRequestDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    public adminShopPut(workspacesCommonDtosShopsHandleShopRequestDTO?: WorkspacesCommonDtosShopsHandleShopRequestDTO, options?: AxiosRequestConfig) {
        return ShopApiFp(this.configuration).adminShopPut(workspacesCommonDtosShopsHandleShopRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    public adminShopTitleValueGet(options?: AxiosRequestConfig) {
        return ShopApiFp(this.configuration).adminShopTitleValueGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ShopPolicyApi - axios parameter creator
 * @export
 */
export const ShopPolicyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {WorkspacesCommonEnumsPoliciesPackageStatus} [status] 
         * @param {string} [shopId] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminShopPolicyGet: async (status?: WorkspacesCommonEnumsPoliciesPackageStatus, shopId?: string, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/ShopPolicy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (shopId !== undefined) {
                localVarQueryParameter['ShopId'] = shopId;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} shopPackageId 
         * @param {ApiGiftCommonDTOsShopsShopsHandleShopPackageDTO} [apiGiftCommonDTOsShopsShopsHandleShopPackageDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminShopPolicyShopPackageIdPut: async (shopPackageId: string, apiGiftCommonDTOsShopsShopsHandleShopPackageDTO?: ApiGiftCommonDTOsShopsShopsHandleShopPackageDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shopPackageId' is not null or undefined
            assertParamExists('adminShopPolicyShopPackageIdPut', 'shopPackageId', shopPackageId)
            const localVarPath = `/Admin/ShopPolicy/{shopPackageId}`
                .replace(`{${"shopPackageId"}}`, encodeURIComponent(String(shopPackageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftCommonDTOsShopsShopsHandleShopPackageDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShopPolicyApi - functional programming interface
 * @export
 */
export const ShopPolicyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShopPolicyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {WorkspacesCommonEnumsPoliciesPackageStatus} [status] 
         * @param {string} [shopId] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminShopPolicyGet(status?: WorkspacesCommonEnumsPoliciesPackageStatus, shopId?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftCommonDTOsBaseTableDTOApiGiftCommonDTOsShopsShopsGetPagedShopPackageDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminShopPolicyGet(status, shopId, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShopPolicyApi.adminShopPolicyGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} shopPackageId 
         * @param {ApiGiftCommonDTOsShopsShopsHandleShopPackageDTO} [apiGiftCommonDTOsShopsShopsHandleShopPackageDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminShopPolicyShopPackageIdPut(shopPackageId: string, apiGiftCommonDTOsShopsShopsHandleShopPackageDTO?: ApiGiftCommonDTOsShopsShopsHandleShopPackageDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminShopPolicyShopPackageIdPut(shopPackageId, apiGiftCommonDTOsShopsShopsHandleShopPackageDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShopPolicyApi.adminShopPolicyShopPackageIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ShopPolicyApi - factory interface
 * @export
 */
export const ShopPolicyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShopPolicyApiFp(configuration)
    return {
        /**
         * 
         * @param {WorkspacesCommonEnumsPoliciesPackageStatus} [status] 
         * @param {string} [shopId] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminShopPolicyGet(status?: WorkspacesCommonEnumsPoliciesPackageStatus, shopId?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<ApiGiftCommonDTOsBaseTableDTOApiGiftCommonDTOsShopsShopsGetPagedShopPackageDTO> {
            return localVarFp.adminShopPolicyGet(status, shopId, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} shopPackageId 
         * @param {ApiGiftCommonDTOsShopsShopsHandleShopPackageDTO} [apiGiftCommonDTOsShopsShopsHandleShopPackageDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminShopPolicyShopPackageIdPut(shopPackageId: string, apiGiftCommonDTOsShopsShopsHandleShopPackageDTO?: ApiGiftCommonDTOsShopsShopsHandleShopPackageDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminShopPolicyShopPackageIdPut(shopPackageId, apiGiftCommonDTOsShopsShopsHandleShopPackageDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShopPolicyApi - object-oriented interface
 * @export
 * @class ShopPolicyApi
 * @extends {BaseAPI}
 */
export class ShopPolicyApi extends BaseAPI {
    /**
     * 
     * @param {WorkspacesCommonEnumsPoliciesPackageStatus} [status] 
     * @param {string} [shopId] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopPolicyApi
     */
    public adminShopPolicyGet(status?: WorkspacesCommonEnumsPoliciesPackageStatus, shopId?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return ShopPolicyApiFp(this.configuration).adminShopPolicyGet(status, shopId, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} shopPackageId 
     * @param {ApiGiftCommonDTOsShopsShopsHandleShopPackageDTO} [apiGiftCommonDTOsShopsShopsHandleShopPackageDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopPolicyApi
     */
    public adminShopPolicyShopPackageIdPut(shopPackageId: string, apiGiftCommonDTOsShopsShopsHandleShopPackageDTO?: ApiGiftCommonDTOsShopsShopsHandleShopPackageDTO, options?: AxiosRequestConfig) {
        return ShopPolicyApiFp(this.configuration).adminShopPolicyShopPackageIdPut(shopPackageId, apiGiftCommonDTOsShopsShopsHandleShopPackageDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SupportTicketApi - axios parameter creator
 * @export
 */
export const SupportTicketApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {WorkspacesCommonDtosSupportTicketsChangeDepartmentDTO} [workspacesCommonDtosSupportTicketsChangeDepartmentDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSupportTicketChangeDepartmentPut: async (workspacesCommonDtosSupportTicketsChangeDepartmentDTO?: WorkspacesCommonDtosSupportTicketsChangeDepartmentDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/SupportTicket/ChangeDepartment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workspacesCommonDtosSupportTicketsChangeDepartmentDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WorkspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO} [workspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSupportTicketCommentPost: async (workspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO?: WorkspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/SupportTicket/Comment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [anySideShopId] 
         * @param {boolean} [isActive] 
         * @param {string} [supportDepartmentId] 
         * @param {boolean} [hasDepartment] 
         * @param {boolean} [onlyMediationRequested] 
         * @param {WorkspacesCommonEnumsSupportTicketsSupportTicketStatus} [status] 
         * @param {string} [ticketNumber] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSupportTicketGet: async (anySideShopId?: string, isActive?: boolean, supportDepartmentId?: string, hasDepartment?: boolean, onlyMediationRequested?: boolean, status?: WorkspacesCommonEnumsSupportTicketsSupportTicketStatus, ticketNumber?: string, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/SupportTicket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (anySideShopId !== undefined) {
                localVarQueryParameter['AnySideShopId'] = anySideShopId;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['IsActive'] = isActive;
            }

            if (supportDepartmentId !== undefined) {
                localVarQueryParameter['SupportDepartmentId'] = supportDepartmentId;
            }

            if (hasDepartment !== undefined) {
                localVarQueryParameter['HasDepartment'] = hasDepartment;
            }

            if (onlyMediationRequested !== undefined) {
                localVarQueryParameter['OnlyMediationRequested'] = onlyMediationRequested;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (ticketNumber !== undefined) {
                localVarQueryParameter['TicketNumber'] = ticketNumber;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WorkspacesCommonDtosSupportTicketsCreateGlobalSupportTicketDTO} [workspacesCommonDtosSupportTicketsCreateGlobalSupportTicketDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSupportTicketGlobalPost: async (workspacesCommonDtosSupportTicketsCreateGlobalSupportTicketDTO?: WorkspacesCommonDtosSupportTicketsCreateGlobalSupportTicketDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/SupportTicket/Global`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workspacesCommonDtosSupportTicketsCreateGlobalSupportTicketDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSupportTicketIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminSupportTicketIdGet', 'id', id)
            const localVarPath = `/Admin/SupportTicket/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {WorkspacesCommonEnumsSupportTicketsSupportTicketStatus} supportTicketStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSupportTicketIdSupportTicketStatusPut: async (id: string, supportTicketStatus: WorkspacesCommonEnumsSupportTicketsSupportTicketStatus, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminSupportTicketIdSupportTicketStatusPut', 'id', id)
            // verify required parameter 'supportTicketStatus' is not null or undefined
            assertParamExists('adminSupportTicketIdSupportTicketStatusPut', 'supportTicketStatus', supportTicketStatus)
            const localVarPath = `/Admin/SupportTicket/{id}/{supportTicketStatus}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"supportTicketStatus"}}`, encodeURIComponent(String(supportTicketStatus)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsMarketplaceSupportTicketsAddReplyToMediationReviewRequestByDto} [apiGiftGatewayCommonDTOsMarketplaceSupportTicketsAddReplyToMediationReviewRequestByDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSupportTicketReplyPost: async (apiGiftGatewayCommonDTOsMarketplaceSupportTicketsAddReplyToMediationReviewRequestByDto?: ApiGiftGatewayCommonDTOsMarketplaceSupportTicketsAddReplyToMediationReviewRequestByDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/SupportTicket/Reply`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftGatewayCommonDTOsMarketplaceSupportTicketsAddReplyToMediationReviewRequestByDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SupportTicketApi - functional programming interface
 * @export
 */
export const SupportTicketApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SupportTicketApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {WorkspacesCommonDtosSupportTicketsChangeDepartmentDTO} [workspacesCommonDtosSupportTicketsChangeDepartmentDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSupportTicketChangeDepartmentPut(workspacesCommonDtosSupportTicketsChangeDepartmentDTO?: WorkspacesCommonDtosSupportTicketsChangeDepartmentDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSupportTicketChangeDepartmentPut(workspacesCommonDtosSupportTicketsChangeDepartmentDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupportTicketApi.adminSupportTicketChangeDepartmentPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {WorkspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO} [workspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSupportTicketCommentPost(workspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO?: WorkspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSupportTicketCommentPost(workspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupportTicketApi.adminSupportTicketCommentPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [anySideShopId] 
         * @param {boolean} [isActive] 
         * @param {string} [supportDepartmentId] 
         * @param {boolean} [hasDepartment] 
         * @param {boolean} [onlyMediationRequested] 
         * @param {WorkspacesCommonEnumsSupportTicketsSupportTicketStatus} [status] 
         * @param {string} [ticketNumber] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSupportTicketGet(anySideShopId?: string, isActive?: boolean, supportDepartmentId?: string, hasDepartment?: boolean, onlyMediationRequested?: boolean, status?: WorkspacesCommonEnumsSupportTicketsSupportTicketStatus, ticketNumber?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspacesCommonDtosBaseTableDTOApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSupportTicketGet(anySideShopId, isActive, supportDepartmentId, hasDepartment, onlyMediationRequested, status, ticketNumber, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupportTicketApi.adminSupportTicketGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {WorkspacesCommonDtosSupportTicketsCreateGlobalSupportTicketDTO} [workspacesCommonDtosSupportTicketsCreateGlobalSupportTicketDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSupportTicketGlobalPost(workspacesCommonDtosSupportTicketsCreateGlobalSupportTicketDTO?: WorkspacesCommonDtosSupportTicketsCreateGlobalSupportTicketDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSupportTicketGlobalPost(workspacesCommonDtosSupportTicketsCreateGlobalSupportTicketDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupportTicketApi.adminSupportTicketGlobalPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSupportTicketIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsMarketplaceSupportTicketsSupportTicketDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSupportTicketIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupportTicketApi.adminSupportTicketIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {WorkspacesCommonEnumsSupportTicketsSupportTicketStatus} supportTicketStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSupportTicketIdSupportTicketStatusPut(id: string, supportTicketStatus: WorkspacesCommonEnumsSupportTicketsSupportTicketStatus, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSupportTicketIdSupportTicketStatusPut(id, supportTicketStatus, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupportTicketApi.adminSupportTicketIdSupportTicketStatusPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsMarketplaceSupportTicketsAddReplyToMediationReviewRequestByDto} [apiGiftGatewayCommonDTOsMarketplaceSupportTicketsAddReplyToMediationReviewRequestByDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSupportTicketReplyPost(apiGiftGatewayCommonDTOsMarketplaceSupportTicketsAddReplyToMediationReviewRequestByDto?: ApiGiftGatewayCommonDTOsMarketplaceSupportTicketsAddReplyToMediationReviewRequestByDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSupportTicketReplyPost(apiGiftGatewayCommonDTOsMarketplaceSupportTicketsAddReplyToMediationReviewRequestByDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupportTicketApi.adminSupportTicketReplyPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SupportTicketApi - factory interface
 * @export
 */
export const SupportTicketApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SupportTicketApiFp(configuration)
    return {
        /**
         * 
         * @param {WorkspacesCommonDtosSupportTicketsChangeDepartmentDTO} [workspacesCommonDtosSupportTicketsChangeDepartmentDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSupportTicketChangeDepartmentPut(workspacesCommonDtosSupportTicketsChangeDepartmentDTO?: WorkspacesCommonDtosSupportTicketsChangeDepartmentDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminSupportTicketChangeDepartmentPut(workspacesCommonDtosSupportTicketsChangeDepartmentDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WorkspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO} [workspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSupportTicketCommentPost(workspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO?: WorkspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminSupportTicketCommentPost(workspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [anySideShopId] 
         * @param {boolean} [isActive] 
         * @param {string} [supportDepartmentId] 
         * @param {boolean} [hasDepartment] 
         * @param {boolean} [onlyMediationRequested] 
         * @param {WorkspacesCommonEnumsSupportTicketsSupportTicketStatus} [status] 
         * @param {string} [ticketNumber] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSupportTicketGet(anySideShopId?: string, isActive?: boolean, supportDepartmentId?: string, hasDepartment?: boolean, onlyMediationRequested?: boolean, status?: WorkspacesCommonEnumsSupportTicketsSupportTicketStatus, ticketNumber?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<WorkspacesCommonDtosBaseTableDTOApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO> {
            return localVarFp.adminSupportTicketGet(anySideShopId, isActive, supportDepartmentId, hasDepartment, onlyMediationRequested, status, ticketNumber, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WorkspacesCommonDtosSupportTicketsCreateGlobalSupportTicketDTO} [workspacesCommonDtosSupportTicketsCreateGlobalSupportTicketDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSupportTicketGlobalPost(workspacesCommonDtosSupportTicketsCreateGlobalSupportTicketDTO?: WorkspacesCommonDtosSupportTicketsCreateGlobalSupportTicketDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminSupportTicketGlobalPost(workspacesCommonDtosSupportTicketsCreateGlobalSupportTicketDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSupportTicketIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsMarketplaceSupportTicketsSupportTicketDetailsDto> {
            return localVarFp.adminSupportTicketIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {WorkspacesCommonEnumsSupportTicketsSupportTicketStatus} supportTicketStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSupportTicketIdSupportTicketStatusPut(id: string, supportTicketStatus: WorkspacesCommonEnumsSupportTicketsSupportTicketStatus, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminSupportTicketIdSupportTicketStatusPut(id, supportTicketStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsMarketplaceSupportTicketsAddReplyToMediationReviewRequestByDto} [apiGiftGatewayCommonDTOsMarketplaceSupportTicketsAddReplyToMediationReviewRequestByDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSupportTicketReplyPost(apiGiftGatewayCommonDTOsMarketplaceSupportTicketsAddReplyToMediationReviewRequestByDto?: ApiGiftGatewayCommonDTOsMarketplaceSupportTicketsAddReplyToMediationReviewRequestByDto, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminSupportTicketReplyPost(apiGiftGatewayCommonDTOsMarketplaceSupportTicketsAddReplyToMediationReviewRequestByDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SupportTicketApi - object-oriented interface
 * @export
 * @class SupportTicketApi
 * @extends {BaseAPI}
 */
export class SupportTicketApi extends BaseAPI {
    /**
     * 
     * @param {WorkspacesCommonDtosSupportTicketsChangeDepartmentDTO} [workspacesCommonDtosSupportTicketsChangeDepartmentDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportTicketApi
     */
    public adminSupportTicketChangeDepartmentPut(workspacesCommonDtosSupportTicketsChangeDepartmentDTO?: WorkspacesCommonDtosSupportTicketsChangeDepartmentDTO, options?: AxiosRequestConfig) {
        return SupportTicketApiFp(this.configuration).adminSupportTicketChangeDepartmentPut(workspacesCommonDtosSupportTicketsChangeDepartmentDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO} [workspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportTicketApi
     */
    public adminSupportTicketCommentPost(workspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO?: WorkspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO, options?: AxiosRequestConfig) {
        return SupportTicketApiFp(this.configuration).adminSupportTicketCommentPost(workspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [anySideShopId] 
     * @param {boolean} [isActive] 
     * @param {string} [supportDepartmentId] 
     * @param {boolean} [hasDepartment] 
     * @param {boolean} [onlyMediationRequested] 
     * @param {WorkspacesCommonEnumsSupportTicketsSupportTicketStatus} [status] 
     * @param {string} [ticketNumber] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportTicketApi
     */
    public adminSupportTicketGet(anySideShopId?: string, isActive?: boolean, supportDepartmentId?: string, hasDepartment?: boolean, onlyMediationRequested?: boolean, status?: WorkspacesCommonEnumsSupportTicketsSupportTicketStatus, ticketNumber?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return SupportTicketApiFp(this.configuration).adminSupportTicketGet(anySideShopId, isActive, supportDepartmentId, hasDepartment, onlyMediationRequested, status, ticketNumber, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkspacesCommonDtosSupportTicketsCreateGlobalSupportTicketDTO} [workspacesCommonDtosSupportTicketsCreateGlobalSupportTicketDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportTicketApi
     */
    public adminSupportTicketGlobalPost(workspacesCommonDtosSupportTicketsCreateGlobalSupportTicketDTO?: WorkspacesCommonDtosSupportTicketsCreateGlobalSupportTicketDTO, options?: AxiosRequestConfig) {
        return SupportTicketApiFp(this.configuration).adminSupportTicketGlobalPost(workspacesCommonDtosSupportTicketsCreateGlobalSupportTicketDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportTicketApi
     */
    public adminSupportTicketIdGet(id: string, options?: AxiosRequestConfig) {
        return SupportTicketApiFp(this.configuration).adminSupportTicketIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {WorkspacesCommonEnumsSupportTicketsSupportTicketStatus} supportTicketStatus 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportTicketApi
     */
    public adminSupportTicketIdSupportTicketStatusPut(id: string, supportTicketStatus: WorkspacesCommonEnumsSupportTicketsSupportTicketStatus, options?: AxiosRequestConfig) {
        return SupportTicketApiFp(this.configuration).adminSupportTicketIdSupportTicketStatusPut(id, supportTicketStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiGiftGatewayCommonDTOsMarketplaceSupportTicketsAddReplyToMediationReviewRequestByDto} [apiGiftGatewayCommonDTOsMarketplaceSupportTicketsAddReplyToMediationReviewRequestByDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportTicketApi
     */
    public adminSupportTicketReplyPost(apiGiftGatewayCommonDTOsMarketplaceSupportTicketsAddReplyToMediationReviewRequestByDto?: ApiGiftGatewayCommonDTOsMarketplaceSupportTicketsAddReplyToMediationReviewRequestByDto, options?: AxiosRequestConfig) {
        return SupportTicketApiFp(this.configuration).adminSupportTicketReplyPost(apiGiftGatewayCommonDTOsMarketplaceSupportTicketsAddReplyToMediationReviewRequestByDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SupportTicketArchiveApi - axios parameter creator
 * @export
 */
export const SupportTicketArchiveApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSupportTicketArchiveGet: async (pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/SupportTicketArchive`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSupportTicketArchiveIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminSupportTicketArchiveIdGet', 'id', id)
            const localVarPath = `/Admin/SupportTicketArchive/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SupportTicketArchiveApi - functional programming interface
 * @export
 */
export const SupportTicketArchiveApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SupportTicketArchiveApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSupportTicketArchiveGet(pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspacesCommonDtosBaseTableDTOWorkspacesCommonQueriesSupportTicketsGetPagedArchiveSupportTicketsQueryResponseRow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSupportTicketArchiveGet(pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupportTicketArchiveApi.adminSupportTicketArchiveGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSupportTicketArchiveIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspacesCommonDtosSupportTicketsSupportTicketArchiveDetailsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSupportTicketArchiveIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupportTicketArchiveApi.adminSupportTicketArchiveIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SupportTicketArchiveApi - factory interface
 * @export
 */
export const SupportTicketArchiveApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SupportTicketArchiveApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSupportTicketArchiveGet(pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<WorkspacesCommonDtosBaseTableDTOWorkspacesCommonQueriesSupportTicketsGetPagedArchiveSupportTicketsQueryResponseRow> {
            return localVarFp.adminSupportTicketArchiveGet(pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSupportTicketArchiveIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<WorkspacesCommonDtosSupportTicketsSupportTicketArchiveDetailsDTO> {
            return localVarFp.adminSupportTicketArchiveIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SupportTicketArchiveApi - object-oriented interface
 * @export
 * @class SupportTicketArchiveApi
 * @extends {BaseAPI}
 */
export class SupportTicketArchiveApi extends BaseAPI {
    /**
     * 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportTicketArchiveApi
     */
    public adminSupportTicketArchiveGet(pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return SupportTicketArchiveApiFp(this.configuration).adminSupportTicketArchiveGet(pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportTicketArchiveApi
     */
    public adminSupportTicketArchiveIdGet(id: string, options?: AxiosRequestConfig) {
        return SupportTicketArchiveApiFp(this.configuration).adminSupportTicketArchiveIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SupportTicketCartableApi - axios parameter creator
 * @export
 */
export const SupportTicketCartableApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSupportTicketCartableGet: async (pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/SupportTicketCartable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSupportTicketCartableReviewIdPut: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminSupportTicketCartableReviewIdPut', 'id', id)
            const localVarPath = `/Admin/SupportTicketCartable/Review/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SupportTicketCartableApi - functional programming interface
 * @export
 */
export const SupportTicketCartableApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SupportTicketCartableApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSupportTicketCartableGet(pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspacesCommonDtosBaseTableDTOWorkspacesCommonDtosSupportTicketsSupportTicketReplyRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSupportTicketCartableGet(pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupportTicketCartableApi.adminSupportTicketCartableGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSupportTicketCartableReviewIdPut(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSupportTicketCartableReviewIdPut(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupportTicketCartableApi.adminSupportTicketCartableReviewIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SupportTicketCartableApi - factory interface
 * @export
 */
export const SupportTicketCartableApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SupportTicketCartableApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSupportTicketCartableGet(pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<WorkspacesCommonDtosBaseTableDTOWorkspacesCommonDtosSupportTicketsSupportTicketReplyRowDTO> {
            return localVarFp.adminSupportTicketCartableGet(pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSupportTicketCartableReviewIdPut(id: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminSupportTicketCartableReviewIdPut(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SupportTicketCartableApi - object-oriented interface
 * @export
 * @class SupportTicketCartableApi
 * @extends {BaseAPI}
 */
export class SupportTicketCartableApi extends BaseAPI {
    /**
     * 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportTicketCartableApi
     */
    public adminSupportTicketCartableGet(pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return SupportTicketCartableApiFp(this.configuration).adminSupportTicketCartableGet(pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportTicketCartableApi
     */
    public adminSupportTicketCartableReviewIdPut(id: string, options?: AxiosRequestConfig) {
        return SupportTicketCartableApiFp(this.configuration).adminSupportTicketCartableReviewIdPut(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SupportTicketConfigApi - axios parameter creator
 * @export
 */
export const SupportTicketConfigApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSupportTicketConfigGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/SupportTicketConfig`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<WorkspacesCommonDtosBaseKeyValueDTO>} [workspacesCommonDtosBaseKeyValueDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSupportTicketConfigPut: async (workspacesCommonDtosBaseKeyValueDTO?: Array<WorkspacesCommonDtosBaseKeyValueDTO>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/SupportTicketConfig`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workspacesCommonDtosBaseKeyValueDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SupportTicketConfigApi - functional programming interface
 * @export
 */
export const SupportTicketConfigApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SupportTicketConfigApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSupportTicketConfigGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkspacesCommonQueriesSupportTicketsGetSupportTicketConfigQueryResponseConfigView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSupportTicketConfigGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupportTicketConfigApi.adminSupportTicketConfigGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<WorkspacesCommonDtosBaseKeyValueDTO>} [workspacesCommonDtosBaseKeyValueDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSupportTicketConfigPut(workspacesCommonDtosBaseKeyValueDTO?: Array<WorkspacesCommonDtosBaseKeyValueDTO>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSupportTicketConfigPut(workspacesCommonDtosBaseKeyValueDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupportTicketConfigApi.adminSupportTicketConfigPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SupportTicketConfigApi - factory interface
 * @export
 */
export const SupportTicketConfigApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SupportTicketConfigApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSupportTicketConfigGet(options?: AxiosRequestConfig): AxiosPromise<Array<WorkspacesCommonQueriesSupportTicketsGetSupportTicketConfigQueryResponseConfigView>> {
            return localVarFp.adminSupportTicketConfigGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<WorkspacesCommonDtosBaseKeyValueDTO>} [workspacesCommonDtosBaseKeyValueDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSupportTicketConfigPut(workspacesCommonDtosBaseKeyValueDTO?: Array<WorkspacesCommonDtosBaseKeyValueDTO>, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminSupportTicketConfigPut(workspacesCommonDtosBaseKeyValueDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SupportTicketConfigApi - object-oriented interface
 * @export
 * @class SupportTicketConfigApi
 * @extends {BaseAPI}
 */
export class SupportTicketConfigApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportTicketConfigApi
     */
    public adminSupportTicketConfigGet(options?: AxiosRequestConfig) {
        return SupportTicketConfigApiFp(this.configuration).adminSupportTicketConfigGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<WorkspacesCommonDtosBaseKeyValueDTO>} [workspacesCommonDtosBaseKeyValueDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportTicketConfigApi
     */
    public adminSupportTicketConfigPut(workspacesCommonDtosBaseKeyValueDTO?: Array<WorkspacesCommonDtosBaseKeyValueDTO>, options?: AxiosRequestConfig) {
        return SupportTicketConfigApiFp(this.configuration).adminSupportTicketConfigPut(workspacesCommonDtosBaseKeyValueDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SupportTicketDepartmentApi - axios parameter creator
 * @export
 */
export const SupportTicketDepartmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSupportTicketDepartmentGet: async (pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/SupportTicketDepartment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSupportTicketDepartmentIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminSupportTicketDepartmentIdDelete', 'id', id)
            const localVarPath = `/Admin/SupportTicketDepartment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WorkspacesCommonDtosSupportTicketsCreateSupportTicketDepartmentDTO} [workspacesCommonDtosSupportTicketsCreateSupportTicketDepartmentDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSupportTicketDepartmentPost: async (workspacesCommonDtosSupportTicketsCreateSupportTicketDepartmentDTO?: WorkspacesCommonDtosSupportTicketsCreateSupportTicketDepartmentDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/SupportTicketDepartment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workspacesCommonDtosSupportTicketsCreateSupportTicketDepartmentDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WorkspacesCommonDtosSupportTicketsUpdateSupportTicketDepartmentDTO} [workspacesCommonDtosSupportTicketsUpdateSupportTicketDepartmentDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSupportTicketDepartmentPut: async (workspacesCommonDtosSupportTicketsUpdateSupportTicketDepartmentDTO?: WorkspacesCommonDtosSupportTicketsUpdateSupportTicketDepartmentDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/SupportTicketDepartment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workspacesCommonDtosSupportTicketsUpdateSupportTicketDepartmentDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSupportTicketDepartmentTitleValueGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/SupportTicketDepartment/TitleValue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSupportTicketDepartmentUpdateIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminSupportTicketDepartmentUpdateIdGet', 'id', id)
            const localVarPath = `/Admin/SupportTicketDepartment/Update/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SupportTicketDepartmentApi - functional programming interface
 * @export
 */
export const SupportTicketDepartmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SupportTicketDepartmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSupportTicketDepartmentGet(pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspacesCommonDtosBaseTableDTOWorkspacesCommonDtosSupportTicketsSupportTicketDepartmentRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSupportTicketDepartmentGet(pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupportTicketDepartmentApi.adminSupportTicketDepartmentGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSupportTicketDepartmentIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSupportTicketDepartmentIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupportTicketDepartmentApi.adminSupportTicketDepartmentIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {WorkspacesCommonDtosSupportTicketsCreateSupportTicketDepartmentDTO} [workspacesCommonDtosSupportTicketsCreateSupportTicketDepartmentDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSupportTicketDepartmentPost(workspacesCommonDtosSupportTicketsCreateSupportTicketDepartmentDTO?: WorkspacesCommonDtosSupportTicketsCreateSupportTicketDepartmentDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSupportTicketDepartmentPost(workspacesCommonDtosSupportTicketsCreateSupportTicketDepartmentDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupportTicketDepartmentApi.adminSupportTicketDepartmentPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {WorkspacesCommonDtosSupportTicketsUpdateSupportTicketDepartmentDTO} [workspacesCommonDtosSupportTicketsUpdateSupportTicketDepartmentDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSupportTicketDepartmentPut(workspacesCommonDtosSupportTicketsUpdateSupportTicketDepartmentDTO?: WorkspacesCommonDtosSupportTicketsUpdateSupportTicketDepartmentDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSupportTicketDepartmentPut(workspacesCommonDtosSupportTicketsUpdateSupportTicketDepartmentDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupportTicketDepartmentApi.adminSupportTicketDepartmentPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSupportTicketDepartmentTitleValueGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkspacesCommonDtosBaseTitleValueDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSupportTicketDepartmentTitleValueGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupportTicketDepartmentApi.adminSupportTicketDepartmentTitleValueGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSupportTicketDepartmentUpdateIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspacesCommonDtosSupportTicketsUpdateSupportTicketDepartmentDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSupportTicketDepartmentUpdateIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupportTicketDepartmentApi.adminSupportTicketDepartmentUpdateIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SupportTicketDepartmentApi - factory interface
 * @export
 */
export const SupportTicketDepartmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SupportTicketDepartmentApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSupportTicketDepartmentGet(pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<WorkspacesCommonDtosBaseTableDTOWorkspacesCommonDtosSupportTicketsSupportTicketDepartmentRowDTO> {
            return localVarFp.adminSupportTicketDepartmentGet(pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSupportTicketDepartmentIdDelete(id: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminSupportTicketDepartmentIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WorkspacesCommonDtosSupportTicketsCreateSupportTicketDepartmentDTO} [workspacesCommonDtosSupportTicketsCreateSupportTicketDepartmentDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSupportTicketDepartmentPost(workspacesCommonDtosSupportTicketsCreateSupportTicketDepartmentDTO?: WorkspacesCommonDtosSupportTicketsCreateSupportTicketDepartmentDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminSupportTicketDepartmentPost(workspacesCommonDtosSupportTicketsCreateSupportTicketDepartmentDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WorkspacesCommonDtosSupportTicketsUpdateSupportTicketDepartmentDTO} [workspacesCommonDtosSupportTicketsUpdateSupportTicketDepartmentDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSupportTicketDepartmentPut(workspacesCommonDtosSupportTicketsUpdateSupportTicketDepartmentDTO?: WorkspacesCommonDtosSupportTicketsUpdateSupportTicketDepartmentDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminSupportTicketDepartmentPut(workspacesCommonDtosSupportTicketsUpdateSupportTicketDepartmentDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSupportTicketDepartmentTitleValueGet(options?: AxiosRequestConfig): AxiosPromise<Array<WorkspacesCommonDtosBaseTitleValueDTO>> {
            return localVarFp.adminSupportTicketDepartmentTitleValueGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSupportTicketDepartmentUpdateIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<WorkspacesCommonDtosSupportTicketsUpdateSupportTicketDepartmentDTO> {
            return localVarFp.adminSupportTicketDepartmentUpdateIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SupportTicketDepartmentApi - object-oriented interface
 * @export
 * @class SupportTicketDepartmentApi
 * @extends {BaseAPI}
 */
export class SupportTicketDepartmentApi extends BaseAPI {
    /**
     * 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportTicketDepartmentApi
     */
    public adminSupportTicketDepartmentGet(pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return SupportTicketDepartmentApiFp(this.configuration).adminSupportTicketDepartmentGet(pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportTicketDepartmentApi
     */
    public adminSupportTicketDepartmentIdDelete(id: string, options?: AxiosRequestConfig) {
        return SupportTicketDepartmentApiFp(this.configuration).adminSupportTicketDepartmentIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkspacesCommonDtosSupportTicketsCreateSupportTicketDepartmentDTO} [workspacesCommonDtosSupportTicketsCreateSupportTicketDepartmentDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportTicketDepartmentApi
     */
    public adminSupportTicketDepartmentPost(workspacesCommonDtosSupportTicketsCreateSupportTicketDepartmentDTO?: WorkspacesCommonDtosSupportTicketsCreateSupportTicketDepartmentDTO, options?: AxiosRequestConfig) {
        return SupportTicketDepartmentApiFp(this.configuration).adminSupportTicketDepartmentPost(workspacesCommonDtosSupportTicketsCreateSupportTicketDepartmentDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkspacesCommonDtosSupportTicketsUpdateSupportTicketDepartmentDTO} [workspacesCommonDtosSupportTicketsUpdateSupportTicketDepartmentDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportTicketDepartmentApi
     */
    public adminSupportTicketDepartmentPut(workspacesCommonDtosSupportTicketsUpdateSupportTicketDepartmentDTO?: WorkspacesCommonDtosSupportTicketsUpdateSupportTicketDepartmentDTO, options?: AxiosRequestConfig) {
        return SupportTicketDepartmentApiFp(this.configuration).adminSupportTicketDepartmentPut(workspacesCommonDtosSupportTicketsUpdateSupportTicketDepartmentDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportTicketDepartmentApi
     */
    public adminSupportTicketDepartmentTitleValueGet(options?: AxiosRequestConfig) {
        return SupportTicketDepartmentApiFp(this.configuration).adminSupportTicketDepartmentTitleValueGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportTicketDepartmentApi
     */
    public adminSupportTicketDepartmentUpdateIdGet(id: string, options?: AxiosRequestConfig) {
        return SupportTicketDepartmentApiFp(this.configuration).adminSupportTicketDepartmentUpdateIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TakeoutInventoryApi - axios parameter creator
 * @export
 */
export const TakeoutInventoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} deliveryKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TakeoutInventoryGet: async (deliveryKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryKey' is not null or undefined
            assertParamExists('v2TakeoutInventoryGet', 'deliveryKey', deliveryKey)
            const localVarPath = `/v2/TakeoutInventory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (deliveryKey !== undefined) {
                localVarQueryParameter['deliveryKey'] = deliveryKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto} [apiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TakeoutInventoryPost: async (apiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto?: ApiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/TakeoutInventory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} takeoutInventoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TakeoutInventoryTakeoutInventoryIdDelete: async (takeoutInventoryId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoutInventoryId' is not null or undefined
            assertParamExists('v2TakeoutInventoryTakeoutInventoryIdDelete', 'takeoutInventoryId', takeoutInventoryId)
            const localVarPath = `/v2/TakeoutInventory/{takeoutInventoryId}`
                .replace(`{${"takeoutInventoryId"}}`, encodeURIComponent(String(takeoutInventoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TakeoutInventoryApi - functional programming interface
 * @export
 */
export const TakeoutInventoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TakeoutInventoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} deliveryKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2TakeoutInventoryGet(deliveryKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2TakeoutInventoryGet(deliveryKey, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TakeoutInventoryApi.v2TakeoutInventoryGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto} [apiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2TakeoutInventoryPost(apiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto?: ApiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2TakeoutInventoryPost(apiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TakeoutInventoryApi.v2TakeoutInventoryPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} takeoutInventoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2TakeoutInventoryTakeoutInventoryIdDelete(takeoutInventoryId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2TakeoutInventoryTakeoutInventoryIdDelete(takeoutInventoryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TakeoutInventoryApi.v2TakeoutInventoryTakeoutInventoryIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TakeoutInventoryApi - factory interface
 * @export
 */
export const TakeoutInventoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TakeoutInventoryApiFp(configuration)
    return {
        /**
         * 
         * @param {string} deliveryKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TakeoutInventoryGet(deliveryKey: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.v2TakeoutInventoryGet(deliveryKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto} [apiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TakeoutInventoryPost(apiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto?: ApiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.v2TakeoutInventoryPost(apiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} takeoutInventoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TakeoutInventoryTakeoutInventoryIdDelete(takeoutInventoryId: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.v2TakeoutInventoryTakeoutInventoryIdDelete(takeoutInventoryId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TakeoutInventoryApi - object-oriented interface
 * @export
 * @class TakeoutInventoryApi
 * @extends {BaseAPI}
 */
export class TakeoutInventoryApi extends BaseAPI {
    /**
     * 
     * @param {string} deliveryKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TakeoutInventoryApi
     */
    public v2TakeoutInventoryGet(deliveryKey: string, options?: AxiosRequestConfig) {
        return TakeoutInventoryApiFp(this.configuration).v2TakeoutInventoryGet(deliveryKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto} [apiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TakeoutInventoryApi
     */
    public v2TakeoutInventoryPost(apiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto?: ApiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto, options?: AxiosRequestConfig) {
        return TakeoutInventoryApiFp(this.configuration).v2TakeoutInventoryPost(apiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} takeoutInventoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TakeoutInventoryApi
     */
    public v2TakeoutInventoryTakeoutInventoryIdDelete(takeoutInventoryId: string, options?: AxiosRequestConfig) {
        return TakeoutInventoryApiFp(this.configuration).v2TakeoutInventoryTakeoutInventoryIdDelete(takeoutInventoryId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TwoFactorApi - axios parameter creator
 * @export
 */
export const TwoFactorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityTwoFactorDisablePut: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/TwoFactor/Disable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityTwoFactorEnablePut: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/TwoFactor/Enable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityTwoFactorGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/TwoFactor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TwoFactorApi - functional programming interface
 * @export
 */
export const TwoFactorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TwoFactorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityTwoFactorDisablePut(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityTwoFactorDisablePut(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TwoFactorApi.identityTwoFactorDisablePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityTwoFactorEnablePut(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticationCommonCommandsEnableTwoFactorAuthenticationCommandResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityTwoFactorEnablePut(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TwoFactorApi.identityTwoFactorEnablePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityTwoFactorGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityTwoFactorGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TwoFactorApi.identityTwoFactorGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TwoFactorApi - factory interface
 * @export
 */
export const TwoFactorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TwoFactorApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityTwoFactorDisablePut(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.identityTwoFactorDisablePut(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityTwoFactorEnablePut(options?: AxiosRequestConfig): AxiosPromise<AuthenticationCommonCommandsEnableTwoFactorAuthenticationCommandResponse> {
            return localVarFp.identityTwoFactorEnablePut(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityTwoFactorGet(options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.identityTwoFactorGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TwoFactorApi - object-oriented interface
 * @export
 * @class TwoFactorApi
 * @extends {BaseAPI}
 */
export class TwoFactorApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwoFactorApi
     */
    public identityTwoFactorDisablePut(options?: AxiosRequestConfig) {
        return TwoFactorApiFp(this.configuration).identityTwoFactorDisablePut(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwoFactorApi
     */
    public identityTwoFactorEnablePut(options?: AxiosRequestConfig) {
        return TwoFactorApiFp(this.configuration).identityTwoFactorEnablePut(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwoFactorApi
     */
    public identityTwoFactorGet(options?: AxiosRequestConfig) {
        return TwoFactorApiFp(this.configuration).identityTwoFactorGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TwoFactorRecoveryApi - axios parameter creator
 * @export
 */
export const TwoFactorRecoveryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityTwoFactorRecoveryDisablePut: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/TwoFactorRecovery/Disable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IdentityCommonDtosRecoveryTwoFactorDTO} [identityCommonDtosRecoveryTwoFactorDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityTwoFactorRecoverySignInPut: async (identityCommonDtosRecoveryTwoFactorDTO?: IdentityCommonDtosRecoveryTwoFactorDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/TwoFactorRecovery/SignIn`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(identityCommonDtosRecoveryTwoFactorDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TwoFactorRecoveryApi - functional programming interface
 * @export
 */
export const TwoFactorRecoveryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TwoFactorRecoveryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityTwoFactorRecoveryDisablePut(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityTwoFactorRecoveryDisablePut(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TwoFactorRecoveryApi.identityTwoFactorRecoveryDisablePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {IdentityCommonDtosRecoveryTwoFactorDTO} [identityCommonDtosRecoveryTwoFactorDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityTwoFactorRecoverySignInPut(identityCommonDtosRecoveryTwoFactorDTO?: IdentityCommonDtosRecoveryTwoFactorDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityTwoFactorRecoverySignInPut(identityCommonDtosRecoveryTwoFactorDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TwoFactorRecoveryApi.identityTwoFactorRecoverySignInPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TwoFactorRecoveryApi - factory interface
 * @export
 */
export const TwoFactorRecoveryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TwoFactorRecoveryApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityTwoFactorRecoveryDisablePut(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.identityTwoFactorRecoveryDisablePut(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IdentityCommonDtosRecoveryTwoFactorDTO} [identityCommonDtosRecoveryTwoFactorDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityTwoFactorRecoverySignInPut(identityCommonDtosRecoveryTwoFactorDTO?: IdentityCommonDtosRecoveryTwoFactorDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.identityTwoFactorRecoverySignInPut(identityCommonDtosRecoveryTwoFactorDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TwoFactorRecoveryApi - object-oriented interface
 * @export
 * @class TwoFactorRecoveryApi
 * @extends {BaseAPI}
 */
export class TwoFactorRecoveryApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwoFactorRecoveryApi
     */
    public identityTwoFactorRecoveryDisablePut(options?: AxiosRequestConfig) {
        return TwoFactorRecoveryApiFp(this.configuration).identityTwoFactorRecoveryDisablePut(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IdentityCommonDtosRecoveryTwoFactorDTO} [identityCommonDtosRecoveryTwoFactorDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwoFactorRecoveryApi
     */
    public identityTwoFactorRecoverySignInPut(identityCommonDtosRecoveryTwoFactorDTO?: IdentityCommonDtosRecoveryTwoFactorDTO, options?: AxiosRequestConfig) {
        return TwoFactorRecoveryApiFp(this.configuration).identityTwoFactorRecoverySignInPut(identityCommonDtosRecoveryTwoFactorDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserDisableTwoFactorUserIdPut: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('adminUserDisableTwoFactorUserIdPut', 'userId', userId)
            const localVarPath = `/Admin/User/DisableTwoFactor/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [name] 
         * @param {string} [email] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserGet: async (id?: string, name?: string, email?: string, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/User`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }

            if (email !== undefined) {
                localVarQueryParameter['Email'] = email;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserRolesUserIdGet: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('adminUserRolesUserIdGet', 'userId', userId)
            const localVarPath = `/Admin/User/Roles/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserRolesUserIdPut: async (userId: string, requestBody?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('adminUserRolesUserIdPut', 'userId', userId)
            const localVarPath = `/Admin/User/Roles/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IdentityCommonDtosChangeUserStatusDTO} [identityCommonDtosChangeUserStatusDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserStatusPut: async (identityCommonDtosChangeUserStatusDTO?: IdentityCommonDtosChangeUserStatusDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/User/Status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(identityCommonDtosChangeUserStatusDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserDisableTwoFactorUserIdPut(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserDisableTwoFactorUserIdPut(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.adminUserDisableTwoFactorUserIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [name] 
         * @param {string} [email] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserGet(id?: string, name?: string, email?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOAuthenticationCommonDtosUserUserRowDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserGet(id, name, email, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.adminUserGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserRolesUserIdGet(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AuthenticationCommonQueriesGetRolesByUserIdQueryResponseRoleRowView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserRolesUserIdGet(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.adminUserRolesUserIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserRolesUserIdPut(userId: string, requestBody?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserRolesUserIdPut(userId, requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.adminUserRolesUserIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {IdentityCommonDtosChangeUserStatusDTO} [identityCommonDtosChangeUserStatusDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserStatusPut(identityCommonDtosChangeUserStatusDTO?: IdentityCommonDtosChangeUserStatusDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserStatusPut(identityCommonDtosChangeUserStatusDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.adminUserStatusPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserDisableTwoFactorUserIdPut(userId: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminUserDisableTwoFactorUserIdPut(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [name] 
         * @param {string} [email] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserGet(id?: string, name?: string, email?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOAuthenticationCommonDtosUserUserRowDto> {
            return localVarFp.adminUserGet(id, name, email, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserRolesUserIdGet(userId: string, options?: AxiosRequestConfig): AxiosPromise<Array<AuthenticationCommonQueriesGetRolesByUserIdQueryResponseRoleRowView>> {
            return localVarFp.adminUserRolesUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserRolesUserIdPut(userId: string, requestBody?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminUserRolesUserIdPut(userId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IdentityCommonDtosChangeUserStatusDTO} [identityCommonDtosChangeUserStatusDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserStatusPut(identityCommonDtosChangeUserStatusDTO?: IdentityCommonDtosChangeUserStatusDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminUserStatusPut(identityCommonDtosChangeUserStatusDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public adminUserDisableTwoFactorUserIdPut(userId: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).adminUserDisableTwoFactorUserIdPut(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {string} [name] 
     * @param {string} [email] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public adminUserGet(id?: string, name?: string, email?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).adminUserGet(id, name, email, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public adminUserRolesUserIdGet(userId: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).adminUserRolesUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {Array<string>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public adminUserRolesUserIdPut(userId: string, requestBody?: Array<string>, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).adminUserRolesUserIdPut(userId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IdentityCommonDtosChangeUserStatusDTO} [identityCommonDtosChangeUserStatusDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public adminUserStatusPut(identityCommonDtosChangeUserStatusDTO?: IdentityCommonDtosChangeUserStatusDTO, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).adminUserStatusPut(identityCommonDtosChangeUserStatusDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserInformationApi - axios parameter creator
 * @export
 */
export const UserInformationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {IdentityCommonDtosUpdateUserInformationDTO} [identityCommonDtosUpdateUserInformationDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserInformationPut: async (identityCommonDtosUpdateUserInformationDTO?: IdentityCommonDtosUpdateUserInformationDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/UserInformation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(identityCommonDtosUpdateUserInformationDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserInformationUserIdGet: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('adminUserInformationUserIdGet', 'userId', userId)
            const localVarPath = `/Admin/UserInformation/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityUserInformationGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/UserInformation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IdentityCommonDtosUpdateMyUserInformationDTO} [identityCommonDtosUpdateMyUserInformationDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityUserInformationPut: async (identityCommonDtosUpdateMyUserInformationDTO?: IdentityCommonDtosUpdateMyUserInformationDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/UserInformation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(identityCommonDtosUpdateMyUserInformationDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserInformationApi - functional programming interface
 * @export
 */
export const UserInformationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserInformationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {IdentityCommonDtosUpdateUserInformationDTO} [identityCommonDtosUpdateUserInformationDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserInformationPut(identityCommonDtosUpdateUserInformationDTO?: IdentityCommonDtosUpdateUserInformationDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserInformationPut(identityCommonDtosUpdateUserInformationDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserInformationApi.adminUserInformationPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserInformationUserIdGet(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticationCommonQueriesGetUpdateUserByIdQueryQueryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserInformationUserIdGet(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserInformationApi.adminUserInformationUserIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityUserInformationGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticationCommonQueriesGetUpdateUserByIdQueryQueryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityUserInformationGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserInformationApi.identityUserInformationGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {IdentityCommonDtosUpdateMyUserInformationDTO} [identityCommonDtosUpdateMyUserInformationDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityUserInformationPut(identityCommonDtosUpdateMyUserInformationDTO?: IdentityCommonDtosUpdateMyUserInformationDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityUserInformationPut(identityCommonDtosUpdateMyUserInformationDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserInformationApi.identityUserInformationPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserInformationApi - factory interface
 * @export
 */
export const UserInformationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserInformationApiFp(configuration)
    return {
        /**
         * 
         * @param {IdentityCommonDtosUpdateUserInformationDTO} [identityCommonDtosUpdateUserInformationDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserInformationPut(identityCommonDtosUpdateUserInformationDTO?: IdentityCommonDtosUpdateUserInformationDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminUserInformationPut(identityCommonDtosUpdateUserInformationDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserInformationUserIdGet(userId: string, options?: AxiosRequestConfig): AxiosPromise<AuthenticationCommonQueriesGetUpdateUserByIdQueryQueryResponse> {
            return localVarFp.adminUserInformationUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityUserInformationGet(options?: AxiosRequestConfig): AxiosPromise<AuthenticationCommonQueriesGetUpdateUserByIdQueryQueryResponse> {
            return localVarFp.identityUserInformationGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IdentityCommonDtosUpdateMyUserInformationDTO} [identityCommonDtosUpdateMyUserInformationDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityUserInformationPut(identityCommonDtosUpdateMyUserInformationDTO?: IdentityCommonDtosUpdateMyUserInformationDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.identityUserInformationPut(identityCommonDtosUpdateMyUserInformationDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserInformationApi - object-oriented interface
 * @export
 * @class UserInformationApi
 * @extends {BaseAPI}
 */
export class UserInformationApi extends BaseAPI {
    /**
     * 
     * @param {IdentityCommonDtosUpdateUserInformationDTO} [identityCommonDtosUpdateUserInformationDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInformationApi
     */
    public adminUserInformationPut(identityCommonDtosUpdateUserInformationDTO?: IdentityCommonDtosUpdateUserInformationDTO, options?: AxiosRequestConfig) {
        return UserInformationApiFp(this.configuration).adminUserInformationPut(identityCommonDtosUpdateUserInformationDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInformationApi
     */
    public adminUserInformationUserIdGet(userId: string, options?: AxiosRequestConfig) {
        return UserInformationApiFp(this.configuration).adminUserInformationUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInformationApi
     */
    public identityUserInformationGet(options?: AxiosRequestConfig) {
        return UserInformationApiFp(this.configuration).identityUserInformationGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IdentityCommonDtosUpdateMyUserInformationDTO} [identityCommonDtosUpdateMyUserInformationDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInformationApi
     */
    public identityUserInformationPut(identityCommonDtosUpdateMyUserInformationDTO?: IdentityCommonDtosUpdateMyUserInformationDTO, options?: AxiosRequestConfig) {
        return UserInformationApiFp(this.configuration).identityUserInformationPut(identityCommonDtosUpdateMyUserInformationDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WithdrawApi - axios parameter creator
 * @export
 */
export const WithdrawApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} withdrawId 
         * @param {ApiGiftGatewayCommonDTOsFinancialWithdrawDoneWithdrawDTO} [apiGiftGatewayCommonDTOsFinancialWithdrawDoneWithdrawDTO] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        adminWithdrawDoneWithdrawIdPut: async (withdrawId: string, apiGiftGatewayCommonDTOsFinancialWithdrawDoneWithdrawDTO?: ApiGiftGatewayCommonDTOsFinancialWithdrawDoneWithdrawDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'withdrawId' is not null or undefined
            assertParamExists('adminWithdrawDoneWithdrawIdPut', 'withdrawId', withdrawId)
            const localVarPath = `/Admin/Withdraw/Done/{withdrawId}`
                .replace(`{${"withdrawId"}}`, encodeURIComponent(String(withdrawId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftGatewayCommonDTOsFinancialWithdrawDoneWithdrawDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} withdrawId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        adminWithdrawRejectWithdrawIdPut: async (withdrawId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'withdrawId' is not null or undefined
            assertParamExists('adminWithdrawRejectWithdrawIdPut', 'withdrawId', withdrawId)
            const localVarPath = `/Admin/Withdraw/Reject/{withdrawId}`
                .replace(`{${"withdrawId"}}`, encodeURIComponent(String(withdrawId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WithdrawApi - functional programming interface
 * @export
 */
export const WithdrawApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WithdrawApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} withdrawId 
         * @param {ApiGiftGatewayCommonDTOsFinancialWithdrawDoneWithdrawDTO} [apiGiftGatewayCommonDTOsFinancialWithdrawDoneWithdrawDTO] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async adminWithdrawDoneWithdrawIdPut(withdrawId: string, apiGiftGatewayCommonDTOsFinancialWithdrawDoneWithdrawDTO?: ApiGiftGatewayCommonDTOsFinancialWithdrawDoneWithdrawDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminWithdrawDoneWithdrawIdPut(withdrawId, apiGiftGatewayCommonDTOsFinancialWithdrawDoneWithdrawDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WithdrawApi.adminWithdrawDoneWithdrawIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} withdrawId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async adminWithdrawRejectWithdrawIdPut(withdrawId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminWithdrawRejectWithdrawIdPut(withdrawId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WithdrawApi.adminWithdrawRejectWithdrawIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WithdrawApi - factory interface
 * @export
 */
export const WithdrawApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WithdrawApiFp(configuration)
    return {
        /**
         * 
         * @param {string} withdrawId 
         * @param {ApiGiftGatewayCommonDTOsFinancialWithdrawDoneWithdrawDTO} [apiGiftGatewayCommonDTOsFinancialWithdrawDoneWithdrawDTO] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        adminWithdrawDoneWithdrawIdPut(withdrawId: string, apiGiftGatewayCommonDTOsFinancialWithdrawDoneWithdrawDTO?: ApiGiftGatewayCommonDTOsFinancialWithdrawDoneWithdrawDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminWithdrawDoneWithdrawIdPut(withdrawId, apiGiftGatewayCommonDTOsFinancialWithdrawDoneWithdrawDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} withdrawId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        adminWithdrawRejectWithdrawIdPut(withdrawId: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminWithdrawRejectWithdrawIdPut(withdrawId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WithdrawApi - object-oriented interface
 * @export
 * @class WithdrawApi
 * @extends {BaseAPI}
 */
export class WithdrawApi extends BaseAPI {
    /**
     * 
     * @param {string} withdrawId 
     * @param {ApiGiftGatewayCommonDTOsFinancialWithdrawDoneWithdrawDTO} [apiGiftGatewayCommonDTOsFinancialWithdrawDoneWithdrawDTO] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof WithdrawApi
     */
    public adminWithdrawDoneWithdrawIdPut(withdrawId: string, apiGiftGatewayCommonDTOsFinancialWithdrawDoneWithdrawDTO?: ApiGiftGatewayCommonDTOsFinancialWithdrawDoneWithdrawDTO, options?: AxiosRequestConfig) {
        return WithdrawApiFp(this.configuration).adminWithdrawDoneWithdrawIdPut(withdrawId, apiGiftGatewayCommonDTOsFinancialWithdrawDoneWithdrawDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} withdrawId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof WithdrawApi
     */
    public adminWithdrawRejectWithdrawIdPut(withdrawId: string, options?: AxiosRequestConfig) {
        return WithdrawApiFp(this.configuration).adminWithdrawRejectWithdrawIdPut(withdrawId, options).then((request) => request(this.axios, this.basePath));
    }
}



