import React, { FormEvent, RefObject } from "react";
import { Button, Card, Form, Spinner } from "react-bootstrap";
import { Gear, ColumnsGap, ChatRight, BarChart, BarChartLine } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { PRO } from "../../../../constants/ConstantsPolicies";
import InventoryProductOverviewService, { IConfig, IPricingStatics, IUpdateProductInventoryConfigDTO } from "../../../../services/inventory/InventoryProductOverviewService";
import ShopPolicies from "../../../shared/policy/ShopPolicies/ShopPolicies";
import { InventoryAutoBasePriceMethodEnum } from "../../Enums/InventoryAutoBasePriceMethodEnum";
import { InventoryDeliveryDelayEnum } from "../../Enums/InventoryDeliveryDelayEnum";
import { InventoryShopBasePlanStatusEnum } from "../../Enums/InventoryShopBasePlanStatusEnum";
import { InventoryShopPlanDeliveryEnum } from "../../Enums/InventoryShopPlanDeliveryEnum";
import GeneralConfig from "./GeneralConfig/GeneralConfig";
import IState, { IProps } from "./InventoryProductPlansConfig.models";
import PlanningConfig from "./PlanningConfig/PlanningConfig";
import PriceComparison from "./PriceComparison";

export default class InventoryProductPlansConfig extends React.Component<IProps, IState> {

    inventoryProductOverviewService: InventoryProductOverviewService
    refShopPolicies: RefObject<ShopPolicies>
    refGeneralConfig: RefObject<GeneralConfig>
    refPlanningConfig: RefObject<PlanningConfig>
    constructor(props: IProps) {
        super(props)
        this.state = {
            basePrice: 0, currentMode: 'Basic', hasChangeCurrentMode: false, isProMode: false, key: 'basic', isLoading: false, loading: false, customCount: null,
            updateConfig: {
                deliveryConfig: { enableParticipateOffers: false },
                inventoryConfig: {
                    isActiveMinimumAlert: false, isActiveMinimumCalculated: false, minimumAlertCustomCount: 0
                    , averagePrice: 0, averagePriceWithCurrencyRate: 0
                },
                isPro: false, pricingConfig: { isAutoBasePrice: false, baseCustomPrice: '0', lastAutoBasePriceAmount: 0, lastAveragePrice: 0, minimumBasePrice: '0' },
                systemDetails: { productId: '', productTitle: '', shopId: '', status: null, isMainCurrency: false, sharePercent: 0 },
            }
        }
        this.inventoryProductOverviewService = new InventoryProductOverviewService()
        this.refShopPolicies = React.createRef()
        this.refGeneralConfig = React.createRef()
        this.refPlanningConfig = React.createRef()
    }

    componentDidMount() {
        this.init()
    }

    init = () => {
        this.setState({ loading: false })
        if (this.props.orderingPlan.pricingConfig === null || this.props.orderingPlan.inventoryConfig === null) this.createManualData()
        else this.setState({
            updateConfig: this.props.orderingPlan,
            isProMode: this.props.orderingPlan.isPro, currentMode: this.props.orderingPlan.isPro ? 'Pro' : 'Basic'
        }, () => {
            this.getBasePrice()
            this.getCustomCount()
        })
    }

    createManualData = () => {
        let pricingStaticsData: IPricingStatics[] = []
        pricingStaticsData.push(
            { countFrom: 1, countTo: 1000, isActive: false, profitPercent: '1', },
            { countFrom: 1001, countTo: 2000, isActive: false, profitPercent: '0.75' },
            { countFrom: 2001, countTo: 3000, isActive: false, profitPercent: '0.5' },
            { countFrom: 3001, countTo: 4000, isActive: false, profitPercent: '0.25' }
        )

        let newData: IConfig = {
            deliveryConfig: {
                delayType: InventoryDeliveryDelayEnum.Hourly,
                enableParticipateOffers: false,
            },
            inventoryConfig: {
                isActiveMinimumAlert: false,
                isActiveMinimumCalculated: false,
                minimumAlertCustomCount: 0,
                averagePrice: 0,
                averagePriceWithCurrencyRate: 0
            },
            isPro: false,
            pricingConfig: {
                baseCustomPrice: '0',
                lastAutoBasePriceAmount: 0,
                lastAveragePrice: 0, minimumBasePrice: '0',
                isAutoBasePrice: false
            },
            systemDetails: {
                productId: this.props.orderingPlan.systemDetails.productId,
                productTitle: this.props.orderingPlan.systemDetails.productTitle,
                shopId: this.props.orderingPlan.systemDetails.shopId,
                status: this.props.orderingPlan.systemDetails.status,
                isMainCurrency: this.props.orderingPlan.systemDetails.isMainCurrency,
                sharePercent: this.props.orderingPlan.systemDetails.sharePercent,
            },
            plansConfig: {
                pricingStatics: pricingStaticsData
            }
        }

        this.setState({ updateConfig: newData }, () => {
            this.getBasePrice()
            this.getCustomCount()
        })
        this.forceUpdate()
    }

    submit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();


        let generalConfig = this.refGeneralConfig.current?.state.updateConfig
        let planning: any[] = []
        if (this.refPlanningConfig.current?.state.isMultiPlan)
            planning = this.refPlanningConfig.current?.state.plans
        else {
            let activePlan = this.refPlanningConfig.current?.state.plans[0];
            activePlan.isActive = true;
            planning.push(activePlan)
        }

        if (this.refPlanningConfig.current?.state.isMultiPlan && this.refPlanningConfig.current?.state.plans.filter(plan => plan.isActive === true).length < 2) {
            toast.error('You must select atlest 2 plans , since you activated multi plan mode.')
            return;
        }


        if (generalConfig !== undefined) {
            let data: IUpdateProductInventoryConfigDTO = {
                baseCustomPrice: Number(this.state.updateConfig.pricingConfig.baseCustomPrice),
                delivery: generalConfig?.delivery,
                isAutoBasePrice: !this.state.isProMode ? false : this.state.updateConfig.pricingConfig.isAutoBasePrice,
                minimumAlert: { customCount: Number(generalConfig?.minimumAlert.customCount), isActive: generalConfig?.minimumAlert.isActive, isAutoCalculatedCount: generalConfig?.minimumAlert.isAutoCalculatedCount },
                minimumBasePrice: Number(this.state.updateConfig.pricingConfig.minimumBasePrice),
                plansConfig: {
                    plans: planning.map(plan => { return { ...plan, profitPercent: Number(plan.profitPercent) } }) as IPricingStatics[],
                },
                isPro: this.state.isProMode,
                productId: this.props.productId
            }

            this.setState({ isLoading: true })
            this.inventoryProductOverviewService.updateProductInventoryConfig(data).then(() => {
                if (this.props.orderingPlan.systemDetails.status == null) toast.success('Your sell plan requested successfully')
                else toast.success('Your sell plan updated successfully')
                this.props.onUpdated();
            }).finally(() => {
                this.setState({ isLoading: false })
            })
        }
    }

    getCustomCount = () => {
        if (!this.state.updateConfig.inventoryConfig.isActiveMinimumCalculated) {
            this.inventoryProductOverviewService.customCount(this.props.productId).then((response) => {
                this.setState({ customCount: response })
            })
        } else this.setState({ customCount: null })
    }

    getBasePrice = () => {
        const { updateConfig } = this.state
        let basePrice = 0;

        if (this.state.updateConfig.pricingConfig.isAutoBasePrice && this.state.isProMode) {
            let price = this.getAutoBasePrice()

            if (updateConfig.pricingConfig.minimumBasePrice != null && (price < Number(updateConfig.pricingConfig.minimumBasePrice)))
                basePrice = Number(updateConfig.pricingConfig.minimumBasePrice)
            else basePrice = price

        } else
            basePrice = Number(updateConfig.pricingConfig.baseCustomPrice)

        this.setState({ basePrice: basePrice, loading: true })
    }

    getAutoBasePrice = () => {
        return this.props.orderingPlan.inventoryConfig ? this.props.orderingPlan.pricingConfig.lastAutoBasePriceAmount : 0
    }

    updatePrice = (value: string) => {
        this.setState(prevState => { return { updateConfig: { ...prevState.updateConfig, pricingConfig: { ...prevState.updateConfig.pricingConfig, baseCustomPrice: value } } } }, () => {
            this.getBasePrice()
        })
    }

    updateMinimumBasePrice = (value: string) => {
        this.setState(prevState => { return { updateConfig: { ...prevState.updateConfig, pricingConfig: { ...prevState.updateConfig.pricingConfig, minimumBasePrice: value } } } }, () => {
            this.getBasePrice()
        })
    }

    updateIsAutoBasePrice = () => {
        this.setState(prevState => { return { updateConfig: { ...prevState.updateConfig, pricingConfig: { ...prevState.updateConfig.pricingConfig, isAutoBasePrice: !this.state.updateConfig.pricingConfig.isAutoBasePrice } } } }, () => {
            this.getBasePrice()
        })
    }

    toggleMode = (checked: boolean) => this.setState({ isProMode: checked, currentMode: checked ? 'Pro' : 'Basic', hasChangeCurrentMode: !this.state.hasChangeCurrentMode })

    render(): React.ReactNode {
        const { basePrice, updateConfig, isProMode, loading } = this.state
        const { productId } = this.props
        return <div>
            <Form onSubmit={this.submit}>
                <Card>
                    <Card.Header>Plans</Card.Header>
                    <Card.Body>
                        {loading &&
                            <>
                                <div className="d-flex">
                                    <h5 className="text-muted"><Gear /> General (<span className="text-muted">{this.state.currentMode}</span>)</h5>
                                    <Form.Check
                                        id="switch"
                                        type="switch"
                                        label="Pro"
                                        className="ml-1"
                                        checked={this.state.isProMode}
                                        onChange={(e) => e.target.checked ? this.refShopPolicies.current?.actionAfterCheckPolicy(e.target.checked) : this.toggleMode(e.target.checked)}
                                    />
                                </div>
                                <GeneralConfig updateIsAutoBasePrice={this.updateIsAutoBasePrice} updatePrice={this.updatePrice} updateMinimumBasePrice={this.updateMinimumBasePrice} ref={this.refGeneralConfig} basePrice={basePrice} deliveryConfig={updateConfig.deliveryConfig}
                                    pricingConfig={updateConfig.pricingConfig} isMainCurrency={updateConfig.systemDetails.isMainCurrency}
                                    inventoryConfig={updateConfig.inventoryConfig} productId={productId} isActivePro={isProMode} />
                                <h5 className="text-muted pt-5"><ColumnsGap /> Sale Plan</h5>
                                <PlanningConfig ref={this.refPlanningConfig} plans={updateConfig.plansConfig?.pricingStatics ?? []}
                                    sharedPercent={updateConfig.systemDetails.sharePercent}
                                    basePrice={basePrice} isActivePro={this.state.isProMode} 
                                    productId={this.props.productId}/>
                                <ShopPolicies ref={this.refShopPolicies} policy={PRO} action={(data) => this.toggleMode(data as boolean)} />
                                <div className="pt-3">
                                </div>
                               
                            </>
                        }
                    </Card.Body>
                    <Card.Footer>
                        {
                            this.state.isLoading ? <Button variant="light" disabled size="sm"><Spinner size="sm" animation="border" /></Button> :
                                <div className="d-flex justify-content-between">
                                    <Button variant="success" size="sm" type="submit">
                                        {!this.state.hasChangeCurrentMode ? 'Save Changes' : `Switch To ${this.state.currentMode}`}
                                    </Button>
                                    <Button variant="outline-danger" size="sm" onClick={() => this.props.changeStatus(InventoryShopBasePlanStatusEnum.Closed)}>Close sales</Button>
                                </div>
                        }
                    </Card.Footer>
                </Card>
            </Form>
        </div>
    }
}