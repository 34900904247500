import React from "react"
import { Container } from "react-bootstrap"
import Giftcards from '../../assets/images/content/Giftcards.jpg'
import FinancialServicesComponent from "../financial/FinancialServices/FinancialServices/FinancialServices"
import FrequentlyAskedQuestions from "../management/FrequentlyAskedQuestions/FrequentlyAskedQuestions"
import GuidComponent from "../management/Guid/Guid"
import SuggestionsComponent from "../products/Suggestions/Suggestions"
import HeaderComponent from "./HeaderComponent/HeaderComponent"

export default class DashboardComponent extends React.PureComponent<{}, {}> {
    render(): React.ReactNode {
        return <>
            <Container>
                <HeaderComponent />
                <SuggestionsComponent />
                <FinancialServicesComponent />
                <FrequentlyAskedQuestions />
                <GuidComponent />
            </Container>
            {this.giftCardsGroupView()}
        </>
    }

    giftCardsGroupView() {
        return <>
            <img
                src={Giftcards}
                width="100%"
                alt=""
                className="pt-7"
            />
        </>
    }
}