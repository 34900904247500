import React, { RefObject } from "react";
import { Col, Form, Row } from "react-bootstrap";
import WalletService from "../../../../services/financials/WalletService";
import { GridColumn, GridColumnTypes } from "../../../Grid/Grid.models";
import PeriodDatePicker from "../../../shared/PeriodDatePicker/PeriodDatePicker";
import walletTransactionStatusDisplayBadge, { walletTransactionStatusDisplayCaption, WalletTransactionStatusEnum } from "../../Enums/WalletTransactionStatusEnum";
import IState, { IWalletTransactionsFilters } from "./WalletTransactionReport.models";
import moment from "moment";
import DynamicGrid from "../../../shared/DynamicGrid/DynamicGrid";
import Amount from "../../../utilities/Amount/Amount";

export default class WalletTransactionReport extends React.Component<{ status?: WalletTransactionStatusEnum | null, walletId?: string }, IState> {
    dynamicGrid: RefObject<DynamicGrid<IWalletTransactionsFilters>>
    periodDatePicker: RefObject<PeriodDatePicker>
    walletService: WalletService

    constructor(props: any) {
        super(props);
        this.dynamicGrid = React.createRef();
        this.walletService = new WalletService();
        this.state = {
            isLoaded: false,
            wallets: []
        }
        this.periodDatePicker = React.createRef();
    }

    componentDidMount = () => {
        this.getWalletList();
    }

    getWalletList = () => this.walletService.getBalanceTitleValues().then(response => {
        this.setState({ wallets: response })
    })


    render(): React.ReactNode {
        let gridColumns: GridColumn[] = [
            { title: 'Amount', key: 'amount' },
            { title: 'Status', key: 'status' },
            { title: 'Description', key: 'description' },
            { title: 'Member', key: 'memberName' },
            { title: 'CreateAt', key: 'createAt', type: GridColumnTypes.Date },
            { title: 'Balance', key: 'balance', type: GridColumnTypes.Amount },
        ];

        return <DynamicGrid<IWalletTransactionsFilters> ref={this.dynamicGrid}
            columns={gridColumns}
            defaultFilters={{ walletId: this.props.walletId, status: this.props.status }}
            onFetch={this.walletService.getWalletTransactions}
            onExportClick={this.walletService.downloadExcelWalletTransactions}
            refactorFilterDisplay={this.renderCustomFilterView}
            filtersForm={this.filtersView}
            refactorRow={(row) => {
                return { ...row, status: walletTransactionStatusDisplayBadge(row.status), amount: <span style={{ color: row.amount > 0 ? 'green' : 'red' }}>{row.amount > 0 && '+'}<Amount formatMoney={row.amount > 0} value={row.amount} /></span> }
            }} />
    }


    renderCustomFilterView = (key: string, value: string) => {
        switch (key) {
            case 'walletId':
                return this.state.wallets.filter(item => item.value === value)[0]?.title
            case 'fromDate':
            case 'toDate':
                return moment(value).format('YYYY-MM-DD')
            case 'status':
                return walletTransactionStatusDisplayCaption(Number(value))
            default: return value
        }
    }

    filtersView = (filters: IWalletTransactionsFilters, onChange: (newFilters: IWalletTransactionsFilters) => void): JSX.Element => {
        const { wallets } = this.state
        return <>

            <PeriodDatePicker
                fromDate={filters.fromDate}
                toDate={filters.toDate}
                onChangeFromDate={(date) => onChange({ ...filters, fromDate: date })}
                onChangeToDate={(date) => onChange({ ...filters, toDate: date })}
            />
            <Row className="mt-2">
                <Col md="6" sm="12">
                    <Form.Group>
                        <Form.Label>Status</Form.Label>
                        <Form.Select aria-label=">Select status"
                            id="newStatus" value={filters.status}
                            onChange={(e) => onChange({ ...filters, status: parseInt(e.target.value) as WalletTransactionStatusEnum })}>
                            {Object.keys(WalletTransactionStatusEnum).map(key => typeof WalletTransactionStatusEnum[key as any] !== 'number' && (
                                <option value={key} key={key}>{WalletTransactionStatusEnum[key as any]}</option>
                            ))}
                            <option value="" selected>All</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col md="6" sm="12">
                    <Form.Group>
                        <Form.Label>Wallet</Form.Label>
                        <Form.Select aria-label=">Select Wallet" value={filters.walletId}
                            onChange={(e) => onChange({ ...filters, walletId: e.target.value })}>
                            {
                                wallets.map((item) => <option value={item.value} key={item.value}>{item.title}</option>)
                            }
                            <option value="" selected>All</option>
                        </Form.Select>
                    </ Form.Group>
                </Col>
            </Row>
            <hr />
            <Row>
                <Col md="12" sm="12">
                    <Form.Group>
                        <Form.Label>Amount</Form.Label>
                        <Form.Control value={filters.amount} onChange={(e) => {
                            onChange({ ...filters, amount: Number(e.target.value) })
                        }} type="number" name="Amount" placeholder="Enter Amount" />
                    </Form.Group>
                </Col>
            </Row>
        </>
    }
}