import React from 'react';
import './ExpressCategoryCatalog.scss'
import { ProductCategoryTitleView } from '../../../services/catalog/ProductCatalogService';
import Banners from '../../dashboard/Banners/Banners';
import { Button, Card, Col, Placeholder, Row } from 'react-bootstrap';
import ExpressCategoryCatalogItem from './CateqoryCatalopItem/ExpressCateqoryCatalopItem';
import CatalogImages from '../Catalog/CatalogImages';

interface IProps {
    categories: ProductCategoryTitleView[],
    isLoading: boolean
}

interface ICategoryItemView extends ProductCategoryTitleView {
    hasSubMenu: boolean
}

interface IState {
    selectedCategory?: {
        title: string;
        parentId?: string;
        parentTitle?: string;
        id: string;
    };
    menuItems: ICategoryItemView[]
}

export default class ExpressCategoryCatalog extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props);
        this.state = { menuItems: [] };
    }

    componentDidMount() {
        this.setCategoriesByParent(null)
    }

    componentDidUpdate(prevProps: Readonly<{ categories: ProductCategoryTitleView[]; onNavigate?: () => void; fixMargin?: boolean; }>, prevState: Readonly<IState>, snapshot?: any): void {
        if (prevProps.categories != this.props.categories)
            this.setCategoriesByParent(null)
    }

    setCategoriesByParent = (id: string | null) => {
        let { categories } = this.props
        let mainCategories = categories.map((category) => {
            return {
                id: category.id,
                title: category.title,
                parentId: category.parentId,
                hasSubMenu: categories.filter(menuItem => menuItem.parentId === category.id).length > 0
            } as ICategoryItemView;
        });
        let menuItems = mainCategories.filter(item => item.parentId == id)
        let category = mainCategories.filter(item => item.id == id)[0]
        let parentTitle = category && mainCategories.filter(item => item.id == category.parentId)[0]?.title

        this.setState({ menuItems: menuItems, selectedCategory: id && { parentTitle, ...category } })
    }

    render(): React.ReactNode {
        let { selectedCategory } = this.state

        return <div>
            <Banners />
            {this.props.isLoading ?
                <Row>
                    {
                        Array.from(Array(12), (e, i) => <Col xs="12" md="6" lg="4" xl="3" xxl="2"><div className={"CategoryCatalogItem"}
                            key={'ProductCatalogItem' + i}>
                            <Placeholder animation="glow" className="product-image">
                                <Placeholder xs={12} style={{ height: '200px', borderRadius: '8px 8px 0 0' }} />
                            </Placeholder>
                            <Placeholder animation="glow">
                                <Placeholder xs={12} size='lg' />
                            </Placeholder>
                            <div className="bottom-section p-2">
                                <div style={{ color: "#ababab" }}>
                                    <Placeholder as={Card.Text} animation="glow">
                                        <Placeholder xs={12} />
                                    </Placeholder>
                                </div>
                            </div>
                        </div></Col>)
                    }
                </Row> : <Row>
                    <Col xs="12" md="12" lg="12" xl="12" xxl="12" style={{ height: '45px' }} className='pt-3'>
                        {
                            selectedCategory && <>
                                <Button onClick={() => this.setCategoriesByParent(null)} variant="link" className='text-coal underline-less'>All </Button>
                                <span className='text-coal'>/</span>
                                {
                                    selectedCategory.parentId != null &&
                                    <><Button onClick={() => this.setCategoriesByParent(selectedCategory.parentId)} variant="link" className='text-coal underline-less'>{this.state.selectedCategory.parentTitle}</Button>
                                        <span className='text-coal'>/</span>
                                    </>
                                }
                                <Button disabled variant="link" className='underline-less'>{selectedCategory.title}</Button>
                            </>
                        }
                    </Col>
                    {
                        this.state.menuItems.map((item) => <Col key={item.id} xs="12" md="6" lg="4" xl="3" xxl="2">
                            <ExpressCategoryCatalogItem title={item.title}
                                imageSrc={CatalogImages(item.id)}
                                imagePath={CatalogImages(item.id)}
                                categoryId={item.id}
                                key={item.id}
                                hasSubMenu={item.hasSubMenu}
                                onClick={() => this.setCategoriesByParent(item.id)}
                            />
                        </Col>)
                    }
                </Row>
            }
        </div>
    }
}