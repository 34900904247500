import React, { RefObject } from "react";
import ShopService, { ShopListPagination } from "../../../services/shops/ShopService";
import Grid from "../../Grid/Grid";
import { GridAction, GridColumn, GridColumnTypes } from "../../Grid/Grid.models";
import { PaginationQuery } from "../../Grid/Pagination/Pagination.models";
import shopStatusDisplayBadge from "../ShopStatusEnum";

export default class ShopList extends React.Component<{ onLoad?: (pendingCount: number, acceptedCount: number) => void, gridActions?: GridAction[], gridColumns?: GridColumn[], shopStatus: number | null }> {
    shopService: ShopService;
    grid: RefObject<Grid>;
    query: ShopListPagination;
    constructor(props: any) {
        super(props);
        this.shopService = new ShopService();
        this.grid = React.createRef();
        this.query = { pageskip: 1, pagesize: 10 };
        if (this.props.shopStatus !== null)
            this.query.status = this.props.shopStatus
    }

    componentDidMount() {
        this.loadShops();
    }

    loadShops = () => {
        this.shopService.getShops(this.query).then((shopGrid) => {

            this.grid.current?.load(shopGrid.rows.map((row) => { return { ...row, status: shopStatusDisplayBadge(row.status) } }), shopGrid.totalRows);
            this.props.onLoad !== undefined && this.props?.onLoad(shopGrid.rows.filter(shop => shop.status == 0).length, shopGrid.rows.filter(shop => shop.status == 1).length);
        })
    }

    resetSpinnerGrid = () => {
        this.grid.current?.resetSpinner()
    }

    changePage = (query: PaginationQuery = this.query) => {
        this.query.pagesize = query.pagesize;
        this.query.pageskip = query.pageskip;

        this.loadShops()
    }

    render(): React.ReactNode {
        let gridColumns: GridColumn[] = [
            { title: 'Title', key: 'title' },
            { title: 'Number', key: 'number' },
            { title: 'Status', key: 'status' },
            { title: 'Date', key: 'createAt', type: GridColumnTypes.Date },
        ];

        return (<div>
            <Grid ref={this.grid} columns={this.props?.gridColumns || gridColumns} actions={this.props?.gridActions} onChangePage={this.changePage}></Grid>
        </div>)
    }
}