import React from "react";
import { Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { OrderProductStatusEnum } from "../Enums/OrderProductStatusEnum";
import OrderItemReportModal from "../OrderItemReportModal/OrderItemReportModal";

export default class OrderReviewRequest extends React.Component<{ orderItemId: string, reviewRequestSupportTicketId?: string, onReported?: () => void, status: OrderProductStatusEnum }, { showModal: boolean }> {

    constructor(props: any) {
        super(props);
        this.state = { showModal: false };

    }
    componentDidMount() {

    }
    onReported = () => {
        this.setState({ showModal: false });
        this.props.onReported();
    }
    openReportModal = () => {
        this.setState({ showModal: true });
    }
    render(): React.ReactNode {
        return <>
            {
                this.props.reviewRequestSupportTicketId ?
                    <NavLink to={`/support-ticket?id=${this.props.reviewRequestSupportTicketId}`}>Show Report</NavLink>
                    : <Button variant="outline-danger" size="sm" onClick={this.openReportModal}>Report</Button>
            }
            <OrderItemReportModal productId={this.props.orderItemId} onReported={this.onReported} status={this.props.status}
                closeModal={() => this.setState({ showModal: false })}
                showModal={this.state.showModal} />
        </>
    }
}