export function productRequestMessageTemplate(
  product: any,
  productGridData: {title: string}[],
) {
  return `<p>The request to create a product is submitted with the following specifications:</p><br/><p>Title: <strong>${
    product.title
  }</strong></p><p>Real Price: <strong>${
    product.price
  }</strong></p><p>Country: <strong>${
    product.country == "other"? product.otherCountry: product.country
  }</strong></p><p>Category: <strong>${
    product.category == "other"? product.otherCategory: product.category
  }</strong></p><p>Currency: <strong>${
    product.currency == "other"? product.otherCurrency: product.currency
  }</strong></p><p>How To Use: <strong>${
    product.descriptionUse
  }</strong></p><p>Description: <strong>${
    product.description
  }</strong></p><p>Website: <strong>${
    product.url
  }</strong></p><p>Structure Code: <strong>${productGridData.map((item) => {
    return 'Title: ' + item.title + ' '
  })}</strong></p><img src="https://api.apigift.com/FileManagement?folder=products&fileName=${
    product.imageName
  }" />`
}
