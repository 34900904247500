import MastercardUSA from '../../../assets/images/creditCards/MastercardUSA.webp'
import MastercardUK from '../../../assets/images/creditCards/MastercardUK.webp'
import MastercardEUR from '../../../assets/images/creditCards/MastercardEUR.webp'
import VisacardUK from '../../../assets/images/creditCards/VisacardUK.webp'
import VisacardCanada from '../../../assets/images/creditCards/VisacardCanada.webp'
import VisacardEUR from '../../../assets/images/creditCards/VisacardEUR.webp'

interface ICardView {
    title: string,
    image: any,
    subTitle?: string
}
export const VirtualCreditCards: ICardView[] = [
    {
        image: MastercardUSA,
        title: 'Mastercard',
        subTitle: 'Virtual - USA'
    },
    {
        image: MastercardUK,
        title: 'Mastercard',
        subTitle: 'Virtual - UK'
    },
    {
        image: MastercardEUR,
        title: 'Mastercard',
        subTitle: 'Virtual - EUR'
    },
    {
        image: VisacardUK,
        title: 'Visacard',
        subTitle: 'Virtual - UK'
    },
    {
        image: VisacardCanada,
        title: 'Visacard',
        subTitle: 'Virtual - Canada'
    },
    {
        image: VisacardEUR,
        title: 'Visacard',
        subTitle: 'Virtual - EUR'
    },
]
