import { InventoryDeliveryDelayEnum } from "../../components/inventory/Enums/InventoryDeliveryDelayEnum";
import { InventoryShopBasePlanStatusEnum } from "../../components/inventory/Enums/InventoryShopBasePlanStatusEnum";
import { mapToDataOnly } from "../common/HttpUtility";
import { SelectionView } from "../common/Models";
import { InventoryProductApiFactory, InventoryShopCollectionApiFactory, OrderingPlanApiFactory, ProductApiFactory } from "../../generated-api";

export default class InventoryProductOverviewService {
    getOrderingPlan(productId: string): Promise<IConfig> {
        return mapToDataOnly<IConfig>(OrderingPlanApiFactory().sellerOrderingPlanProductIdGet(productId));
    }

    customCount(productId: string): Promise<number> {
        return mapToDataOnly<number>(InventoryProductApiFactory().sellerInventoryProductCustomCountProductIdGet(productId));
    }

    getDeficits(productId: string): Promise<IDeficitDTO[]> {
        return mapToDataOnly<IDeficitDTO[]>(InventoryShopCollectionApiFactory().sellerInventoryShopCollectionDeficitsGet(productId));
    }

    getUniqueBuyOrderNumber(queryString: IGetUniqueBuyOrderNumberQueryString): Promise<IUniqueSellerOrderNumberDTO> {
        return mapToDataOnly<IUniqueSellerOrderNumberDTO>(
            InventoryShopCollectionApiFactory().sellerInventoryShopCollectionOrderNumberGet(queryString.productId, queryString.sourceId, queryString.orderNumber))
    }

    getProductStructureTitleValues(productId: string): Promise<SelectionView[]> {
        return mapToDataOnly<SelectionView[]>(ProductApiFactory().sellerProductStructureTitleValueProductIdGet(productId));
    }

    updateProductInventoryConfig(dto: IUpdateProductInventoryConfigDTO): Promise<any> {
        return mapToDataOnly(OrderingPlanApiFactory()
            .sellerOrderingPlanPost({ ...dto, plansConfig: { plans: dto.plansConfig.plans.map(item => { return { ...item, profitPercent: Number(item.profitPercent) } }) } }));
    }

    updateInventoryConfigStatus(productId: string, dto: { status: InventoryShopBasePlanStatusEnum }): Promise<any> {
        return mapToDataOnly(InventoryProductApiFactory().sellerInventoryProductStatusProductIdPut(productId, dto));
    }
}

export interface IConfig {
    deliveryConfig: IConfigDeliveryDTO
    inventoryConfig: InventoryConfig
    isPro: boolean
    plansConfig?: IPlansConfig
    pricingConfig: IPricingConfig
    systemDetails: ISystemDetails
}

export interface ISystemDetails {
    sharePercent: number
    productId: string
    productTitle: string
    shopId: string
    isMainCurrency: boolean
    status: InventoryShopBasePlanStatusEnum | null
}

export interface IPricingConfig {
    baseCustomPrice: string
    isAutoBasePrice: boolean
    lastAutoBasePriceAmount: number
    lastAveragePrice: number
    minimumBasePrice: string
}

export interface IPlansConfig {
    pricingStatics: IPricingStatics[]
}

export interface IPricingStatics {
    isActive?: boolean;
    countFrom?: number;
    countTo?: number;
    profitPercent?: string;
}

export interface InventoryConfig {
    isActiveMinimumAlert: boolean
    isActiveMinimumCalculated: boolean
    minimumAlertCustomCount: number
    averagePrice: number
    averagePriceWithCurrencyRate: number

}

export interface IConfigDeliveryDTO {
    delayFromNumber?: number;
    delayToNumber?: number;
    delayType?: InventoryDeliveryDelayEnum;
    enableParticipateOffers?: boolean;
}

interface IGetUniqueBuyOrderNumberQueryString {
    productId: string;
    sourceId: string;
    orderNumber: string;
}

interface IUniqueSellerOrderNumberDTO {
    buyPrice: number;
    orderDate: string;
    sourceId: string;
}

export interface IUpdateProductInventoryConfigDTO {
    minimumAlert: IConfigMinimumAlertDTO;
    baseCustomPrice: number;
    minimumBasePrice: number | null;
    isAutoBasePrice: boolean;
    productId: string;
    plansConfig: {
        plans: IPricingStatics[],
    };
    delivery: IConfigDeliveryDTO;
    isPro: boolean;
}

export interface IConfigMinimumAlertDTO {
    isActive?: boolean;
    isAutoCalculatedCount?: boolean;
    customCount?: number;
}

export interface IDeficitDTO {
    sourceTitle: string;
    sourceId: string;
    orderNumber: string;
    count: number;
}