import React from "react";
import { Alert, Button, Spinner, Table } from "react-bootstrap";
import OrdersService from "../../../services/orders/OrdersService";
import HybridMoment from "../../utilities/HybridMoment";
import authenticationContext from "../../../contexts/AuthenticationContext/AuthenticationContext";
import TOTPForm from "../../identity/Security/TwoFactor/TOTPForm/TOTPForm";
import OrderProductStatusBadge, { OrderProductStatusEnum } from "../Enums/OrderProductStatusEnum";
import ShopAuthenticated from "../../shared/ShopAuthenticated/ShopAuthenticated";
import { ORDER_INFORMATION } from "../../../constants/ConstantsPermissions";
import OrderReviewRequest from "../OrderReviewRequest/OrderReviewRequest";
import Note from "../../shared/Note/Note";

interface OrderItemView {
    deliveredAt: string
    reviewRequestSupportTicketId: string
    id: string
    informations: string
    isSold: boolean
    orderType: number
    status: number
    title: string
}

interface OrderItemsState {
    items: OrderItemView[];
    isSafeMode: boolean;
    showOtpForm: boolean;
    loading: boolean;
}

export default class OrderItems extends React.Component<{ orderId: string, disableShowCode: boolean, hideAlert?: boolean, skipSafeMode?: boolean }, OrderItemsState> {
    ordersService: OrdersService;
    static contextType = authenticationContext;
    context!: React.ContextType<typeof authenticationContext>;
    constructor(props: any) {
        super(props);
        this.state = { items: [], isSafeMode: false, showOtpForm: false, loading: false };
        this.ordersService = new OrdersService();
    }
    componentDidMount() {
        this.loadTable((this.props.skipSafeMode && !this.context.twoFactorAvtive) ? true : false);
    }
    changeSafeMode = () => {
        if (!this.state.isSafeMode && this.context.twoFactorAvtive)
            this.setState({ showOtpForm: true });
        else
            this.loadTable(!this.state.isSafeMode);
    }
    loadTable(isSafeMode: boolean) {
        this.setState({ loading: true });
        this.ordersService.getOrderItems(this.props.orderId, isSafeMode).then((grid: any) => {
            this.setState({ items: grid.rows });
            this.setState({ isSafeMode: isSafeMode })
        }).catch((error) => {
            alert('permission denied');
        }).finally(() => {
            this.setState({ loading: false });
        });
    }
    onOTPset = () => {
        this.setState({ showOtpForm: false });
        this.loadTable(true);
    }
    render(): React.ReactNode {
        return (<>
            <ShopAuthenticated permission={ORDER_INFORMATION}>
                {
                    !this.props.hideAlert && <>
                        {
                            this.props.disableShowCode ? <>
                                <Alert variant="warning">
                                    This order added to inventory automatically, so you can see full information in <b>Inventories</b> section only!.
                                </Alert>
                            </> : <>
                                <Note>
                                    Click on <b>Show Code</b>  button to see full product information
                                </Note>
                                <Button size="sm" variant="warning" onClick={this.changeSafeMode}>
                                    {this.state.isSafeMode ? 'Hide Code' : 'Show Code'}
                                </Button>
                            </>
                        }
                    </>
                }

            </ShopAuthenticated>
            <Table striped hover responsive size="lg">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Title</th>
                        <th>Status</th>
                        <th>Information</th>
                        <th>Delivery Time</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.loading ? (<tr><td colSpan={5} align="center"><Spinner animation="grow" /></td></tr>) :
                            this.state.items.map((item, index) =>
                                <tr>
                                    <td>{index + 1}</td>
                                    <td>{item.title}</td>
                                    <td>{OrderProductStatusBadge(item.status)}</td>
                                    <td>{item.informations && this.informationNormalize(item.informations)}</td>
                                    <td>{item.deliveredAt && HybridMoment(item.deliveredAt)}</td>
                                    <td>
                                        <OrderReviewRequest orderItemId={item.id} reviewRequestSupportTicketId={item.reviewRequestSupportTicketId}
                                            status={item.status as OrderProductStatusEnum}
                                            onReported={() => { this.loadTable(this.state.isSafeMode) }} />
                                        {
                                            !this.props.disableShowCode && (this.state.isSafeMode ?
                                                <Button variant="outline-danger" size="sm" style={{ marginLeft: '5px' }} onClick={this.changeSafeMode}>Hide Code</Button>
                                                : <Button variant="outline-warning" size="sm" style={{ marginLeft: '5px' }} onClick={this.changeSafeMode}>Show Code</Button>)
                                        }
                                    </td>
                                </tr>)}
                </tbody>
            </Table >
            <TOTPForm showModal={this.state.showOtpForm} onSubmit={this.onOTPset} onHide={() => this.setState({ showOtpForm: false })} />
        </>
        );
    }
    informationNormalize(information: string) {
        information = information.replace('["', '').replace('"]', '').replace('","', ' , ');
        return information.indexOf(',') >= 0 ? information.split(',').map((info) => <span>{info}<br /></span>) : information;
    }
}