import { PaginationQuery } from "./Pagination/Pagination.models";

export default interface GridProps {
    columns: GridColumn[];
    actions?: GridAction[];
    rowClassName?: (row: any) => string;
    onChangePage?: (query: PaginationQuery) => void;
}

export interface GridState {
    columns: GridColumn[];
    rows: Record<string, any>[];
    totalRows: number;
    actions: GridAction[];
    startIndex: number;
    spinnerPoint: number[];
    loading: boolean
    pageNumber: number
}

export interface GridColumn {
    title: string;
    key: string;
    tooltip?: string;
    align?: string;
    type?: GridColumnTypes;
}


export interface GridResult<T> {
    rows: T[];
    totalRows: number;
}


export interface GridAction {
    render?: (row: any) => JSX.Element;
    caption?: string;
    variant?: string;
    generateLink?: (row: any) => string;
    onClick?: (row: any) => void;
    checkIsDisabled?: (row: any) => boolean;
    permission?: string;
    skipSpinner?: boolean
}
export enum GridColumnTypes {
    String = 0,
    Date = 1,
    DateOnly = 4,
    TimeOnly = 5,
    Enum = 2,
    Amount = 3,
    BadgeList = 6,
}