import { Badge } from "react-bootstrap";
export enum ExchangeStatusEnum{
    Pending,
    Done,
    Reject
}

export function exchangeStatusDisplayCaption(status: number) {
    let types = ['Pending', 'Done', 'Reject'];
    return types.length <= status ? status : types[status];
}

export function exchangeStatusColor(status: number) {
    let types = ['warning', 'success', 'danger'];
    return types.length <= status ? 'dark' : types[status];
}

export default function exchangeStatusDisplayBadge(status: number) {
    return <Badge bg={exchangeStatusColor(status)}>{exchangeStatusDisplayCaption(status)}</Badge>;
}