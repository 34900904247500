import React from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { LocalCurrencyService } from "../../../../services/financials/LocalCurrencyService";
import InventorySourceTransactionService from "../../../../services/inventory/InventorySourceTransactionService";
import { IProps, IState } from "./ModifyInventorySourceTransaction.models";

export default class InventorySourceTransactionList extends React.PureComponent<IProps, IState> {
    localCurrencyService: LocalCurrencyService
    inventorySourceTransactionService: InventorySourceTransactionService
    constructor(props: any) {
        super(props)
        this.state = { pendingRequest: false, showModal: false, source: { description: '', inventorySourceTransactionId: '', shopAmountValue: 0, sourceAmountValue: 0, transactionAt: new Date() }, loading: false, currencies: [] }
        this.inventorySourceTransactionService = new InventorySourceTransactionService();
        this.localCurrencyService = new LocalCurrencyService();
    }

    onOpen = () => {
        if (this.props.sourceId !== undefined && this.props.sourceId !== null) {
            this.getById(this.props.sourceId);
        }
    }
    getCurrencies() {
        this.localCurrencyService.getTitleValues().then((response) => {
            this.setState((prevState) => { return { currencies: response, source: { ...prevState.source, shopCurrencyId: response[0].value, sourceCurrencyId: response[0].value } } })
        })
    }

    getById = (id: string) => {
        this.setState({ loading: true, showModal: true });
        this.inventorySourceTransactionService.getById(id).then((response) => {
            this.setState({ source: response, loading: false })
        });
    }

    clear = () => {
        this.setState({ showModal: false, source: { description: '', inventorySourceTransactionId: '', shopAmountValue: 0, sourceAmountValue: 0, transactionAt: new Date() }, loading: false });
        this.props.onClose();
    }

    submit = () => {
        const { source } = this.state;
        if (source.shopAmountValue === null || source.sourceAmountValue === null || source.transactionAt === null) return toast.error('Please fill all required fields that have star.')

        this.setState({ pendingRequest: true })
        this.inventorySourceTransactionService.update(this.state.source).then((response) => {
            this.setState({ pendingRequest: false, showModal: false })
            this.props.onClose();
            this.props.onSubmit();
        });
    }

    render(): React.ReactNode {
        const { loading, source,  pendingRequest } = this.state
        return (
            <Modal size="lg"
                show={true}
                onHide={this.clear}
                backdrop="static"
                onShow={this.onOpen}
            >
                <Modal.Header closeButton>
                    <Modal.Title> Edit Source Transaction</Modal.Title>
                </Modal.Header>
                <Modal.Body> {
                    loading ? <Spinner animation="grow" /> :
                        <>
                            <Row>
                                <Col md="6" sm="12">
                                    <Form.Group controlId="sourceAmountValue">
                                        <Form.Label>Source Amount:</Form.Label>
                                        <Form.Control type="number" value={source.sourceAmountValue} onChange={e => this.setState((prevState) => { return { source: { ...prevState.source, sourceAmountValue: Number(e.target.value) } } })} />
                                    </Form.Group>
                                </Col>
                                <Col md="6" sm="12">
                                    <Form.Label>Shop Amount:</Form.Label>
                                    <Form.Control type="number" value={source.shopAmountValue} onChange={e => this.setState((prevState) => { return { source: { ...prevState.source, shopAmountValue: Number(e.target.value) } } })} />
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12" sm="12">
                                    <Form.Label>Description:</Form.Label>
                                    <Form.Control as="textarea" rows={3} placeholder="Enter description"
                                        value={source.description}
                                        onChange={e => this.setState((prevState) => { return { source: { ...prevState.source, description: e.target.value } } })} />
                                </Col>
                            </Row>
                        </>
                }
                </Modal.Body>
                <Modal.Footer>
                    {pendingRequest ? <Button variant='success' disabled><Spinner animation='border' /></Button> :
                        <Button variant='success' onClick={this.submit}>Submit</Button>}
                </Modal.Footer>
            </Modal>
        )
    }
}