import { mapToDataOnly } from "../../common/HttpUtility"
import { SelectionView } from "../../common/Models"
import { ArticleCategoryApiFactory } from "../../../generated-api/admin"

export default class AdminArticleCategoryService {
    getPaged(): Promise<any> {
        return mapToDataOnly<any>(ArticleCategoryApiFactory().adminArticleCategoryGet())
    }

    update(id: string, dto: IArticleCategoryDTO): Promise<any> {
        return mapToDataOnly(ArticleCategoryApiFactory().adminArticleCategoryIdPut(id, dto));
    }

    delete(id: string): Promise<any> {
        return mapToDataOnly(ArticleCategoryApiFactory().adminArticleCategoryIdDelete(id));
    }

    add(dto: IArticleCategoryDTO): Promise<any> {
        return mapToDataOnly(ArticleCategoryApiFactory().adminArticleCategoryPost(dto));
    }

    getById(id: string): Promise<IArticleCategoryDTO> {
        return mapToDataOnly<IArticleCategoryDTO>(ArticleCategoryApiFactory().adminArticleCategoryIdGet(id))
    }

    getTitleValues(): Promise<SelectionView[]> {
        return mapToDataOnly<SelectionView[]>(ArticleCategoryApiFactory().adminArticleCategoryTitleValueGet());
    }
}

export interface IArticleCategoryDTO {
    title: string
    isActive: boolean
    imageName: string
    parentId: string
    orderId: number
    imageSrc: string
}