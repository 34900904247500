//privilage
export const ORDER_MENU = "ORDER_MENU";
export const FULL_ACCESS = "ADMINISTRATOR";
export const INVENTORY_MENU = "INVENTORY_MENU";
export const FINANCIAL_MENU = "FINANCIAL_MENU";
export const API_CONFIG_MENU = "API_CONFIG_MENU";
export const CURRENCIES_MENU = "CURRENCIES_MENU";
export const ORDER_INFORMATION = "ORDER_ACCESS";
export const HELP_CENTER_MENU = "HELP_CENTER_MENU";
export const SECRET_INFORMATION = "SECRET_INFORMATION";

//role
export const SUPERADMIN = "SUPERADMIN";
export const ADMIN = "ADMIN";
export const SUPPORT = "SUPPORT";
export const CONTENT_WRITER = "CONTENT_WRITER";
export const MEDIATOR = "MEDIATOR";