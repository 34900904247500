import { Badge } from "react-bootstrap";

export enum OrderStatusEnum {
    Pending,
    Done,
    Payment,
    Canceled,
    Refund,
    Pricing,
    PayToSeller
}

export function orderStatusDisplayCaption(status: number) {
    let types = ['Pending', 'Done', 'Payment', 'Canceled', 'Refund', 'Pricing', 'PayToSeller'];
    return types.length <= status ? status : types[status];
}

export function orderStatusColor(status: number) {
    let types = ['warning', 'success', 'dark', 'danger', 'info', 'dark', 'dark'];
    return types.length <= status ? 'dark' : types[status];
}

export default function orderStatusDisplayBadge(status: number) {
    return <Badge bg={orderStatusColor(status)}>{orderStatusDisplayCaption(status)}</Badge>;
}

export function orderDetailsProductStatusDisplayCaption(status: boolean) {
    let types = ['Completed', 'InProgress'];
    return status ? types[0] : types[1];
}

export function orderDetailsProductStatusColor(status: boolean) {
    let types = ['success', 'warning'];
    return status ? types[0] : types[1];
}

export function orderDetailsProductStatusDisplayBadge(status: boolean) {
    return <Badge bg={orderDetailsProductStatusColor(status)}>{orderDetailsProductStatusDisplayCaption(status)}</Badge>;
}