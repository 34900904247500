import { mapToDataOnly } from '../../common/HttpUtility'
import { SupportTicketConfigApiFactory } from '../../../generated-api/admin';

export interface configDto {
  key: string; value: any
}

export default class AdminSupportTicketConfigService {
  get(): Promise<{ key: string; value: any }[]> {
    return mapToDataOnly<{ key: string; value: any }[]>(
      SupportTicketConfigApiFactory().adminSupportTicketConfigGet()
    )
  }

  set(dto: configDto[]): Promise<void> {
    return mapToDataOnly<void>(
      SupportTicketConfigApiFactory().adminSupportTicketConfigPut(dto)
    )
  }
}
