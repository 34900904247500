import { Badge } from "react-bootstrap";

export enum FinancialAccountStatusEnum {
    Unverified,
    Verified,
}

export function financialAccountStatusDisplayCaption(status: number) {
    let types = ['Unverified', 'Verified'];
    return types.length <= status ? status : types[status];
}

export function financialAccountStatusColor(status: number) {
    let types = ['danger', 'success'];
    return types.length <= status ? 'dark' : types[status];
}

export default function financialAccountDisplayBadge(status: number) {
    return <Badge bg={financialAccountStatusColor(status)}>{financialAccountStatusDisplayCaption(status)}</Badge>;
}