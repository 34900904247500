import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import AdminArticles from "./Articles/AdminArticleList";
import AdminArticleCategory from "./Category/AdminArticleCategory";

export default class AdminArticle extends React.Component<{ activeTab: string | null }, { navigateToTab?: string }> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    componentDidUpdate(prevProps: Readonly<{ activeTab: string | null; }>, prevState: Readonly<{ navigateToTab?: string; }>): void {
        if (prevProps.activeTab !== this.props.activeTab)
            this.setState({ navigateToTab: undefined })
    }

    handleSelect = (key) => this.setState({ navigateToTab: key });

    render(): React.ReactNode {
        return <>
            {this.state.navigateToTab && this.state.navigateToTab !== this.props.activeTab ? <Navigate to={"/admin/article/?activeTab=" + this.state.navigateToTab} /> : null}
            <Tabs activeKey={this.props.activeTab ?? "articles"} onSelect={this.handleSelect} id="article-tabs" className="mb-3" mountOnEnter={true} unmountOnExit={true}>
                <Tab eventKey="articles" title="Articles">
                    <AdminArticles />
                </Tab>
                <Tab eventKey="category" title="Category">
                    <AdminArticleCategory />
                </Tab>  
            </Tabs>
        </>
    }
}
