import React from "react";
import authenticationContext from "../../../contexts/AuthenticationContext/AuthenticationContext";
import AccessDenied from "../AccessDenied/AccessDenied";
import { FULL_ACCESS } from "../../../constants/ConstantsPermissions"

export default class ShopAuthenticated extends React.Component<{ permission: string, showAccessDenied?: boolean, reverse?: boolean }> {
    static contextType = authenticationContext;
    context!: React.ContextType<typeof authenticationContext>;
    constructor(props: any) {
        super(props)
    }

    render(): React.ReactNode {
        let { permission } = this.props
        const privileges = this.context.privileges;


        if (privileges?.length) {
             var accessGranted = privileges.includes(permission) || privileges.includes(FULL_ACCESS);
            accessGranted = this.props.reverse ? !accessGranted : accessGranted;

            if (accessGranted)
                return this.props.children

            if (this.props.showAccessDenied === true)
                return <AccessDenied />
        }

        return null
    }
}
