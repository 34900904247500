import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './Editor.scss';

export default class EditorComponent extends React.Component<{ text?: string, onChange?: (text: string) => void, isImageEditor?: boolean }, { editorState: any }> {
    constructor(props: any) {
        super(props);
        this.state = { editorState: ''}
    }

    componentDidMount() {
        this.setState({editorState: this.props.text})
    }

    modules = {
        toolbar: [
          [{ 'header': [1, 2, false] }],
          ['bold', 'italic', 'underline', 'blockquote'],
          ['link', this.props.isImageEditor && 'image'],
          ['clean']
        ],
    }
    
    formats = [
        'header', 'bold', 'italic', 'underline',
        'blockquote', 'link', this.props.isImageEditor && 'image'
    ]

    onChange = (editorState: any) => {
        this.setState({ editorState: editorState })
        this.props.onChange && this.props.onChange(editorState)
    }

    render(): React.ReactNode {
        return <ReactQuill theme="snow"
            modules={this.modules}
            formats={this.formats}
            value={this.state.editorState}
            onChange={(value) => this.onChange(value)}
        />
    }
}