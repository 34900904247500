import { mapToDataOnly } from "../common/HttpUtility";
import { ShopConfigApiFactory } from "../../generated-api";

export default class ConfigurationService {
    get(): Promise<IGetShopApiConfigDTO> {
        return mapToDataOnly<IGetShopApiConfigDTO>(ShopConfigApiFactory().buyerShopConfigGet())
    }

    getFullInformation(): Promise<IGetShopApiConfigDTO> {
        return mapToDataOnly<IGetShopApiConfigDTO>(ShopConfigApiFactory().buyerShopConfigFullInformationGet())
    }

    activeShop(): Promise<any> {
        return mapToDataOnly<any>(ShopConfigApiFactory().buyerShopConfigPost())
    }

    updateWebHooks(body: ISetShopApiConfigDTO): Promise<any> {
        return mapToDataOnly<any>(ShopConfigApiFactory().buyerShopConfigPut(body))
    }

    reGenerate(): Promise<any> {
        return mapToDataOnly<any>(ShopConfigApiFactory().buyerShopConfigSecretKeyGeneratorPut())
    }

    reSendProductList(): Promise<any> {
        return mapToDataOnly<any>(ShopConfigApiFactory().buyerShopConfigPost())
    }
}

export interface IGetShopApiConfigDTO {
    title: string
    apiKey: string
    secretKey: string
    orderProductChangeStatusWebHookPath: string
    productChangeStatusWebHookPath: string
    walletDepositWebHookPath: string
    takeoutInventoryWebHookPath: string
    takeoutInventoryRefundedWebHookPath: string
    sellReviewRequestCreatedWebHookPath: string
    supportTicketUpdatedWebHookPath: string
    webHookSecurityHeaderKey: string
    webHookSecurityHeaderValue: string
}

export interface ISetShopApiConfigDTO {
    orderProductChangeStatusWebHookPath: string
    productChangeStatusWebHookPath: string
    walletDepositWebHookPath: string
    webHookSecurityHeaderValue: string
    takeoutInventoryWebHookPath: string
    sellReviewRequestCreatedWebHookPath: string
    supportTicketUpdatedWebHookPath: string
    takeoutInventoryRefundedWebHookPath: string
}