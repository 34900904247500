import { mapToDataOnly } from "../common/HttpUtility";
import { IOrderItemReportDto } from "../../components/helpCenter/Reports/Details/ReportDetails.models";
import { IReviewRequestPaginationFilters, IShopReportSupportTicketQueryString, SupportTicketStatus } from "../../components/helpCenter/Reports/ReportsList/ReportsList.models";
import { PaginationQuery } from "../../components/Grid/Pagination/Pagination.models";
import { GridResult } from "../../components/Grid/Grid.models";
import { ReviewRequestApiFactory } from "../../generated-api";

export default class ReviewRequestService {
    accept(ticketId: string): Promise<void> {
        return mapToDataOnly(ReviewRequestApiFactory().reviewRequestIdAcceptPut(ticketId));
    }

    startMediation(ticketId: string): Promise<void> {
        return mapToDataOnly(ReviewRequestApiFactory().reviewRequestIdStartMediationPut(ticketId));
    }
    getById(ticketId: string): Promise<IOrderItemReportDto> {
        return mapToDataOnly<IOrderItemReportDto>(ReviewRequestApiFactory().reviewRequestIdSellGet(ticketId));
    }

    getSellPaginated(filters: IReviewRequestPaginationFilters, paginationQuery: PaginationQuery): Promise<GridResult<IShopReportSupportTicketDTO>> {
        return mapToDataOnly<GridResult<IShopReportSupportTicketDTO>>(ReviewRequestApiFactory().reviewRequestSellGet(filters.isActive, filters.status, filters.showOnlyPendings, filters.ticketNumber, paginationQuery.pageskip - 1, paginationQuery.pagesize));
    }

    getBuyPaginated(query: IShopReportSupportTicketQueryString): Promise<GridResult<{
        createAt: string
        id: string
        memberDisplayName: string
        modifyAt: string
        status: number
        title: string,
        tags: string[]
    }>> {
        return mapToDataOnly<GridResult<any>>(ReviewRequestApiFactory().reviewRequestBuyGet(query.isActive, query.status, undefined, query.pageskip - 1, query.pagesize));
    }

    getCount(): Promise<ISupportTicketCountDTO> {
        const status: SupportTicketStatus = SupportTicketStatus.TargetPending;
        return mapToDataOnly<ISupportTicketCountDTO>(ReviewRequestApiFactory().reviewRequestSellCountGet(status));
    }
}

interface IShopReportSupportTicketDTO {
    id: string
    title: string
    userDisplayName: string
    modifyAt: string
    createAt: string
    status: SupportTicketStatus,
    isMediationRequested: boolean,
    tags: string[]
}


interface ISupportTicketCountDTO {
    count: number,
    forceCount: number
}
