import React, { RefObject } from "react";
import './BuyOffers.scss'
import { Button, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import Note from "../../../shared/Note/Note";
import OffersService, { OfferRowDto, OwnedOfferDto } from "../../../../services/orders/OffersService";
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import { PRO } from "../../../../constants/ConstantsPolicies";
import ShopPolicies from "../../../shared/policy/ShopPolicies/ShopPolicies";
import inventoryShopBasePlanStatusDisplayBadge from "../../../inventory/Enums/InventoryShopBasePlanStatusEnum";
import DynamicGrid from "../../../shared/DynamicGrid/DynamicGrid";
import { GridAction, GridColumn } from "../../../Grid/Grid.models";
interface IProps {
    onClickSell: (productId: string) => void, categoryId: string
}
export default class BuyOffers extends React.Component<IProps, { offers: OfferRowDto[] }> {
    dynamicGrid: RefObject<DynamicGrid<any>>;
    offersService: OffersService;
    constructor(props: any) {
        super(props);
        this.dynamicGrid = React.createRef()
        this.offersService = new OffersService();
        this.state = { offers: [] }
    }

    componentDidMount(): void {
    }



    componentDidUpdate(prevProps: IProps) {
        if ((prevProps.categoryId !== this.props.categoryId)) {
            this.dynamicGrid.current.applyFilters(()=>{
                this.dynamicGrid.current.fetch();
            });
        }
    }
    cancelOffer = (id: string) => {
        if (window.confirm('Are you sure your offer will be cancelled?'))
            this.offersService.cancel(id).then((response) => {
                toast.success('Your offer canceled')
            });
    }
    render(): React.ReactNode {
        let gridColumns: GridColumn[] = [
            { title: 'Product', key: 'productTitle', tooltip: 'Product Title' },
            { title: 'Your Available', key: 'availableInventory', tooltip: 'You have some available inventory, ready to sell on this offer!' },
            { title: 'Quantity', key: 'quantity', tooltip: 'Offer Quantity' },
            { title: 'Acceptable', key: 'acceptable', tooltip: 'Your Price is less than offer price (Acceptable count)' },
            { title: 'In-Comming Count', key: 'inCommingCount', tooltip: 'Your have some in comming order from your sources' },
        ];

        var gridActions: GridAction[] = [{ caption: 'Sell', variant: 'danger', onClick: (row) => this.props.onClickSell(row.productId), skipSpinner: true }]

        return <div className="BuyOffers">

            <h4 style={{ backgroundColor: '#52a846', color: '#fff', padding: '10px 30px', borderRadius: '10px 10px 0 0' }}>Buy Offers</h4>
            <Note>This is a list of offers ready for buyers, you can sell to them!</Note>

            <DynamicGrid<any>
                skipUpdateFilters
                ref={this.dynamicGrid}
                columns={gridColumns}
                actions={gridActions}
                defaultPagesize={20}
                onFetch={this.offersService.getBuyOffersPagination}
                defaultFilters={{ categoryId: this.props.categoryId }}
                refactorRow={(row) => {
                    return {
                        ...row,
                        productTitle: <>{inventoryShopBasePlanStatusDisplayBadge(row.orderingPlanStatus)} {row.productTitle}</>,
                        acceptable: row.quantity - row.outOfPriceRangeCount - row.sellerBlockCount - row.buyerBlockCount
                        // statusBadge: offerStatusEnumDisplayBadge(row.status),
                    }
                }} />
        </div>
    }
}