import React from "react"
import './SlideView.scss'
import Nine from "../../../../assets/images/content/9.webp"
import Eight from "../../../../assets/images/content/8.webp"
import Seven from "../../../../assets/images/content/7.webp"
import Six from "../../../../assets/images/content/6.webp"
import Five from "../../../../assets/images/content/5.webp"
import Four from "../../../../assets/images/content/4.webp"
import Three from "../../../../assets/images/content/3.webp"
import Two from "../../../../assets/images/content/2.webp"
import One from "../../../../assets/images/content/1.webp"

export default class SlideView extends React.PureComponent<{}, {}> {
    render(): React.ReactNode {
        let images = [One, Two, Three, Four, Five, Six, Seven, Eight, Nine]
        return <div className="slide-view">
            <div className="fade-in">
                <div className="area-images">
                    {
                        images.map((image, index) => {
                            return <img
                                key={index}
                                src={image}
                                alt=""
                            />
                        })
                    }
                </div>
            </div>
        </div>
    }
}