import { mapToDataOnly } from "../common/HttpUtility";
import IUpdateUserInformation from '../../models/dtos/identity/IUpdateUserInformationDTO'
import { IUserInformationDTO } from "../../models/dtos/identity/IUserInformationDTO";
import { IChangePasswordDTO } from "../../models/dtos/identity/IChangePasswordDTO";
import { AccountApiFactory, PasswordApiFactory, RegisterApiFactory, SecurityApiFactory, SocialNetworkApiFactory, UserInformationApiFactory } from "../../generated-api";

export default class IdentityService {
    login(username: string, password: string, totp?: string | undefined): Promise<any> {
        return AccountApiFactory().identityAccountPost({
            userName: username,
            password: password,
            totp: totp
        });
    }
    forgotPassword(email: string, returnUrl: string): Promise<{}> {
        return mapToDataOnly(PasswordApiFactory().identityPasswordForgotPost(returnUrl, { email }));
    }

    resetPassword(data: {
        token: string,
        newPassowrd: string,
        userId: string
    }, returnUrl: string): Promise<{}> {
        return mapToDataOnly(PasswordApiFactory().identityPasswordForgotVerifyPost(returnUrl, data));
    }

    register(registerInformation: {
        password: string,
        email: string
        firstname: string
        lastname: string
    }, returnUrl: string): Promise<{}> {
        return mapToDataOnly(RegisterApiFactory().identityRegisterUserPost(returnUrl, registerInformation));
    }

    confirmationEmail(email: string, returnURL: string): Promise<{}> {
        return mapToDataOnly(SecurityApiFactory().identitySecurityConfirmEmailPut({ email, returnURL }));
    }

    getUserInformation(): Promise<any> {
        return AccountApiFactory().identityAccountInformationsGet();
    }

    getUserInformationComplete(): Promise<IUserInformationDTO> {
        return mapToDataOnly<IUserInformationDTO>(UserInformationApiFactory().identityUserInformationGet());
    }

    updateUserInformation(userInformation: IUpdateUserInformation): Promise<any> {
        return mapToDataOnly(UserInformationApiFactory().identityUserInformationPut(userInformation));
    }

    changePassword(data: IChangePasswordDTO): Promise<any> {
        return mapToDataOnly(PasswordApiFactory().identityPasswordResetPost(data));
    }

    getUserSecurityInformation(): Promise<ISecurityInformation> {
        return mapToDataOnly<ISecurityInformation>(SecurityApiFactory().identitySecurityGet());
    }

    generateTelegramBotToken(): Promise<string> {
        return mapToDataOnly<string>(SocialNetworkApiFactory().identitySocialNetworkGet())
    }
}

interface ISecurityInformation {
    isTwoFactorEnabled: boolean
    isEmailConfirmed: boolean
}