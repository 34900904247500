import { useSearchParams } from "react-router-dom"
import AdminCashier from "../../../components/admin/financials/AdminCashier"
import AdminAuthenticated from "../../../components/shared/AdminAuthenticated/AdminAuthenticated"
import { ADMIN, SUPERADMIN } from "../../../constants/ConstantsPermissions"

export default function AdminFinancialsRoute() {
    const [searchParams] = useSearchParams()
    let activeTab = searchParams.get("activeTab")
    let ownerId = searchParams.get("ownerId")

    return <AdminAuthenticated roles={[SUPERADMIN, ADMIN]}><AdminCashier activeTab={activeTab} ownerId={ownerId} /></AdminAuthenticated>
}