import React from "react"
import { Outlet } from "react-router-dom"
import FooterComponent from "../Footer/Footer"
import NavBarComponent from "../NavBar/NavBarComponent"
import './DefaultLayout.scss'

export default class DefaultLayout extends React.PureComponent<{}, {}> {
    render(): React.ReactNode {
        return (<div id="landing" className="website">
            <NavBarComponent />
            <div style={{ minHeight: 'calc(100vh - 270px)' }}><Outlet /></div>
            <FooterComponent />
        </div>)
    }
}