import React from "react";
import authenticationContext from "../../../contexts/AuthenticationContext/AuthenticationContext";
import AccessDenied from "../AccessDenied/AccessDenied";

export default class AdminAuthenticated extends React.Component<{roles: string[], showAccessDenied?: boolean}> {
    static contextType = authenticationContext;
    context!: React.ContextType<typeof authenticationContext>;
    constructor(props: any) {
        super(props)
    }

    render(): React.ReactNode {
        const userRoles = this.context.roles;

        if (this.props.roles?.length > 0 && this.props.roles.filter(role => userRoles.includes(role)).length > 0)
            return this.props.children
        
        else if (this.props.showAccessDenied === true)
            return <AccessDenied />
        
        else return null
    }
}
