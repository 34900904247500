import React, { FormEvent, RefObject } from "react";
import { Alert, Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import ShopService from "../../../services/shops/ShopService";
import Grid from "../../Grid/Grid";
import ShopList from "../ShopList/ShopList";

export default class CreateShopOnly extends React.Component<{}, State> {
    shopService: ShopService;
    constructor(props: any) {
        super(props);
        this.state = { title: '', address: '', email: '', siteAddreess: '', tell: '', redirectToStore: false, requestPending: false };
        this.shopService = new ShopService();
    }
    componentDidMount(): void {
        this.shopService.getTitles().then(shops => {
            if (shops.length > 0)
                this.setState({ redirectToStore: true })
        })
    }
    submitForm = (e: FormEvent) => {
        e.preventDefault();
        if (this.state.requestPending)
            return

        this.setState({ requestPending: true })

        // this.shopService.create({ title: this.state.title, address: this.state.address, email: this.state.email, siteAddreess: this.state.siteAddreess, tell: this.state.tell }).then(() => {
        //     toast.success('Your shop created successfully');
        //     window.location.reload();
        // }).finally(() => this.setState({ requestPending: false }));
    }
    render(): React.ReactNode {
        return <div>
            <h3>Create Store</h3><hr />
            {this.state.redirectToStore && <Navigate to="/marketplace" replace />}

            <Form onSubmit={this.submitForm}>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <Form.Group controlId="createShop.title">
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control type="text" value={this.state.title}
                                        placeholder="Enter your shop title"
                                        onChange={(e) => this.setState({ title: e.target.value })} />
                                </Form.Group>
                            </Col>


                            <Col>
                                <Form.Group controlId="createShop.email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="text" value={this.state.email}
                                        placeholder="Enter your shop email"
                                        onChange={(e) => this.setState({ email: e.target.value })} />
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group controlId="createShop.siteAddreess">
                                    <Form.Label>Site Url</Form.Label>
                                    <Form.Control type="text" value={this.state.siteAddreess}
                                        placeholder="Enter your shop site url"
                                        onChange={(e) => this.setState({ siteAddreess: e.target.value })} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>

                            <Col md={8}>
                                <Form.Group controlId="createShop.address">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control type="text" value={this.state.address}
                                        placeholder="Enter your shop physical address"
                                        onChange={(e) => this.setState({ address: e.target.value })} />
                                </Form.Group>
                            </Col>
                            <Col md={4}>

                                <Form.Group controlId="createShop.tell">
                                    <Form.Label>Tell</Form.Label>
                                    <Form.Control type="text" value={this.state.tell}
                                        placeholder="Enter your shop tell"
                                        onChange={(e) => this.setState({ tell: e.target.value })} />
                                </Form.Group>

                            </Col>

                            <Col></Col>
                        </Row>
                        {this.state.requestPending ? <Button variant="success" className="mt-2" disabled>
                            <Spinner animation="border" />
                        </Button> :
                            <Button disabled={this.state.requestPending} variant="success" type="submit">
                                Create
                            </Button>}
                    </Col>
                    <Col>
                        <Alert variant="success">
                            <Alert.Heading>Create your store</Alert.Heading>
                            <p>
                                To use the gift card sales service, you must first create your store (it does not matter if you are a buyer or a seller),<br />
                                So create your store and then enter the world of gift cards!
                            </p>
                            <hr />
                            <p>
                                This information is kept confidential and will never be shown to others (even your store title).
                            </p>
                        </Alert>
                    </Col>
                </Row>


            </Form>
        </div>
    }
}

interface State {
    address: string
    email: string
    siteAddreess: string
    tell: string
    title: string
    redirectToStore: boolean
    requestPending: boolean
}