import { GridResult } from "../../components/Grid/Grid.models";
import { PaginationQuery } from "../../components/Grid/Pagination/Pagination.models";
import { mapToDataOnly } from "../common/HttpUtility";
import { SelectionView } from "../common/Models";
import { InventorySourceApiFactory } from "../../generated-api";

export default class InventorySourceService {
    getPaged(queryString: PaginationQuery): Promise<GridResult<InventorySourceDTO>> {
        return mapToDataOnly<GridResult<InventorySourceDTO>>(InventorySourceApiFactory()
            .sellerInventorySourceGet(queryString.pageskip - 1, queryString.pagesize));
    }

    getById(id: string): Promise<InventorySourceDTO> {
        return mapToDataOnly<InventorySourceDTO>(InventorySourceApiFactory().sellerInventorySourceUpdateInventorySourceIdGet(id));
    }

    create(dto: ICreateInventorySourceDTO): Promise<any> {
        return mapToDataOnly(InventorySourceApiFactory().sellerInventorySourcePost(dto));
    }

    update(dto: IUpdateInventorySourceDTO): Promise<any> {
        return mapToDataOnly(InventorySourceApiFactory().sellerInventorySourcePut(dto));
    }

    delete(id: string): Promise<any> {
        return mapToDataOnly(InventorySourceApiFactory().sellerInventorySourceInventorySourceIdDelete(id));
    }

    getDetails(sourceId: string): Promise<InventorySourceDTO> {
        return mapToDataOnly<InventorySourceDTO>(InventorySourceApiFactory().sellerInventorySourceInventorySourceIdGet(sourceId));
    }

    getTitleValues(): Promise<SelectionView[]> {
        return mapToDataOnly<SelectionView[]>(InventorySourceApiFactory().sellerInventorySourceTitleValueGet());
    }
}

export interface InventorySourceDTO {
    id: string,
    title: string,
    baseCurrencyBalance: number,
    description: string,
}

export interface ICreateInventorySourceDTO {
    title: string;
    description: string;
}

export interface IUpdateInventorySourceDTO extends ICreateInventorySourceDTO {
    id: string;
}