import { Badge } from "react-bootstrap";

export enum ShopStatusEnum {
    Pending,
    Accepted,
    None,
    Rejected,
}

export function shopStatusDisplayCaption(status: number) {
    let types = ['Pending', 'Accepted', 'none', 'Rejected'];
    return types.length <= status ? status : types[status];
}

export function shopStatusColor(status: number) {
    let types = ['warning', 'success', 'dark', 'danger'];
    return types.length <= status ? 'dark' : types[status];
}

export default function shopStatusDisplayBadge(status: number) {
    return <Badge bg={shopStatusColor(status)}>{shopStatusDisplayCaption(status)}</Badge>;
}