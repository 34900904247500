import { Badge } from "react-bootstrap";
export enum InventoryShopBasePlanStatusEnum {
    Canceled,
    Pending,
    Open,
    Closed,
    Suspended,
    Rejected
}

export function inventoryShopBasePlanStatusDisplayCaption(status: number) {
    let types = ['Canceled', 'Pending', 'Open', 'Closed', 'Suspended', 'Rejected'];
    return types.length <= status ? status : types[status];
}

export function inventoryShopBasePlanStatusColor(status: number) {
    let types = ['danger', 'warning', 'success', 'danger', 'info', 'danger'];
    return types.length <= status ? 'dark' : types[status];
}

export default function inventoryShopBasePlanStatusDisplayBadge(status: number | null) {
    return <Badge bg={status === null ? 'secondary' : inventoryShopBasePlanStatusColor(status)}>{status === null ? 'Not started' :inventoryShopBasePlanStatusDisplayCaption(status)}</Badge>;
}