import React from "react";
import { Placeholder } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { Wallet } from "react-bootstrap-icons";
import WalletService from "../../../../services/financials/WalletService";
import authenticationContext from "../../../../contexts/AuthenticationContext/AuthenticationContext";
import Amount from "../../../utilities/Amount/Amount";
import './WalletOverview.scss'
import { FINANCIAL_MENU, FULL_ACCESS } from "../../../../constants/ConstantsPermissions";

export class WalletOverview extends React.PureComponent<{}, { balance: number, pendingBalance: number, loading: boolean }> {
    walletService: WalletService;
    static contextType = authenticationContext;
    context!: React.ContextType<typeof authenticationContext>;
    constructor(props: any) {
        super(props);
        this.walletService = new WalletService();
        this.state = { balance: 0, pendingBalance: 0, loading: true };
    }
    componentDidMount() {
        this.loadOverview();
    }
    loadOverview() {
        this.setState({ loading: true });
        this.walletService.getOverview().then((overview) => {
            this.setState({ balance: overview.balance, pendingBalance: overview.pendingBalance });
        }).finally(() => {
            this.setState({ loading: false });
        });
    }
    render(): React.ReactNode {
        return (
             <NavLink to={'/financials/?defaultActiveKey=wallets'} className={this.context.privileges.includes(FULL_ACCESS) || this.context.privileges.includes(FINANCIAL_MENU) ? '' : 'disabled'}>
                <div className="wallet-overview">
                    <div className="icon">
                        <Wallet></Wallet>
                    </div>
                    <div className="amount-area">
                        {
                            this.state.loading ? <Placeholder animation="glow">
                                Wallet Balance
                                <Placeholder style={{ height: '2vh', width: '100%' }} />
                            </Placeholder> : <>
                                Wallet Balance
                                <div className="amount"><Amount value={this.state.balance} />$ {this.state.pendingBalance > 0 && <span title="Pending">+<Amount value={this.state.pendingBalance} />$</span>}</div>
                            </>
                        }
                    </div>
                </div>
             </NavLink>
            );
    }
}