import React from "react"
import SliderComponent from "./Slider/Slider"
import { Games } from "../../constants/Data/GamesData"
import { DailyPracticals } from "../../constants/Data/DailyPracticalsData"
import { MusicVideosData } from "../../constants/Data/MusicAndVideo"
import RedirectView from "../../shared/RedirectView/RedirectView"
import SectionTitle from "../../shared/SectionView/SectionTitle/SectionTitle"

export default class SuggestionsComponent extends React.PureComponent<{}, {}> {
    render(): React.ReactNode {
        return (<div className="mt-lg">
            <SectionTitle title="Some of our most leading Gift Cards" />
            <SliderComponent title="Gaming" data={Games} />
            <SliderComponent title="Daily practical" dir="rtl" data={DailyPracticals} />
            <SliderComponent redirectButton title="Music and video" data={MusicVideosData} />
            {this.redirectActionViewMobile()}
        </div>)
    }

    redirectActionViewMobile() {
        return <>
            <div className="mt-5 mobile">
                <RedirectView title="See more Gift Cards on API Gift just right now! " />
            </div>
        </>
    }
}