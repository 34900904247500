import React from "react";
import IdentityService from "../../../services/identity/IdentityService";
import NavMenu from "./NavMenu/NavMenu";
import './Header.scss';
import Profile from "../Profile/Profile";
import ShopChanger from "../ShopChanger/ShopChanger";
import LayoutContext from "../../../contexts/LayoutContext/LayoutContext";
import { WalletOverview } from "./WalletOverview/WalletOverview";
import Alerts from "../../dashboard/Alerts/Alerts";
import CapitalsOverviewToggle from "../CapitalsOverview/CapitalsOverviewToggle";
import Cart from "../../shopping/Cart/Cart";

export default class Header extends React.Component<{ onToggleSidebar(): void, hideMenu: boolean, isMobile: boolean }, { showDropDown: boolean }> {
    identityService: IdentityService;
    walletOverview: React.RefObject<WalletOverview>;
    static contextType = LayoutContext;
    context!: React.ContextType<typeof LayoutContext>;
    cart: React.RefObject<Cart>;
    constructor(props: any) {
        super(props);
        this.identityService = new IdentityService();
        this.state = { showDropDown: false };
        this.walletOverview = React.createRef()
        this.cart = React.createRef()
    }

    componentDidMount(): void {
        this.context.onAddToCartSubject.subscribe(() => {
            this.walletOverview.current?.loadOverview();
        })

        if (this.cart.current)
            this.context.loadCart = this.cart.current?.openCart;
    }

    componentDidUpdate(): void {
        if (this.cart.current)
            this.context.loadCart = this.cart.current?.openCart;
    }

    mobileView = () => {
        return <header>
            <div className="nav-area">
                {
                    !this.props.hideMenu && <>
                        <NavMenu><WalletOverview></WalletOverview></NavMenu>
                    </>
                }
            </div>
            <div className="shop-overview-selection">
                <ShopChanger />
            </div>

            <div className="profile">
                <Profile />
            </div>
            <div className="header-alerts">
                <Alerts />
            </div>
            <div className="header-cart">
                <Cart ref={this.cart} />
            </div>
        </header>;
    }

    defaultView = () => {
        return <header>
            <div className="shop-overview-selection">
                <ShopChanger />
            </div>
            <CapitalsOverviewToggle />
            <div className="shop-overview-wallet">
                <WalletOverview ref={this.walletOverview}></WalletOverview>
            </div>
            <div className="nav-area">
                {
                    !this.props.hideMenu && <NavMenu />
                }
            </div>
            <div className="profile">
                <Profile />
            </div>
            <div className="header-alerts">
                <Alerts />
            </div>
            <div className="header-cart">
                <Cart ref={this.cart} />
            </div>
        </header>;
    }

    render(): React.ReactNode {
        return this.props.isMobile ? this.mobileView() : this.defaultView();
    }
}