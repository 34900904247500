import { GridResult } from "../../components/Grid/Grid.models";
import { PaginationQuery } from "../../components/Grid/Pagination/Pagination.models";
import { mapToDataOnly } from "../common/HttpUtility";
import { ShopMemberInviteApiFactory } from "../../generated-api";

export default class ShopMemberInvitationService {
    get(filters: { onlyPendings: boolean, email?: string}, query: PaginationQuery): Promise<GridResult<ShopMemberInvitationRowDto>> {
        return mapToDataOnly<GridResult<ShopMemberInvitationRowDto>>(ShopMemberInviteApiFactory().marketPlaceShopMemberInviteGet(filters.onlyPendings, filters.email, query.pageskip - 1, query.pagesize));
    }

    revoke(id: string): Promise<{}> {
        return mapToDataOnly<{}>(ShopMemberInviteApiFactory().marketPlaceShopMemberInviteRevokeIdDelete(id));
    }
}

interface ShopMemberInvitationRowDto {
    id: string;
    email: string;
    createDate: string;
    rejected: boolean;
}