import React from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import IState, { IProps } from "./AddCredit.models";
import AdminCreditsService from "../../../../../services/admin/financials/AdminCreditsService";
import { toast } from "react-toastify";
import AdminWalletsServices from "../../../../../services/admin/financials/AdminWalletsServices";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { SelectionView } from "../../../../../services/common/Models";
import ValidAmount from "../../../../utilities/Amount/ValidAmount";

export default class AddCreditModal extends React.Component<IProps, IState> {
    adminCreditsService: AdminCreditsService
    adminWalletsServices: AdminWalletsServices
    constructor(props: any) {
        super(props);
        this.state = { pendingRequest: false, amount: '0', description: '', walletsTitleValues: [], walletId: '' }
        this.adminCreditsService = new AdminCreditsService()
        this.adminWalletsServices = new AdminWalletsServices()
    }

    componentDidMount(): void {
        if (this.props.walletId) this.setState({ walletId: this.props.walletId })
        this.adminWalletsServices.getTitleValues().then((response) => {
            this.setState({ walletsTitleValues: response })
        })
    }

    submit = () => {
        let { amount, walletId, description } = this.state
        let body = {
            amount: this.props.isNegativeCredit ? Number(amount) * -1 : Number(amount),
            description,
            isActive: true,
            walletId
        }

        this.setState({ pendingRequest: true })
        this.adminCreditsService.add(body).then((response) => {
            toast.success("Credit created successfully ")
            this.props.onClose()
            this.props.reload()
        }).finally(() => this.setState({ pendingRequest: false }))
    }

    render(): React.ReactNode {
        let { pendingRequest, amount, description, walletsTitleValues, walletId } = this.state
        let { onClose } = this.props

        return <Modal show={true} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>{this.props.isNegativeCredit? 'Create Negative Credit' : 'Create Credit'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col className="mt-3" md="12" sm="12" >
                            <Form.Label>Wallet:</Form.Label>
                            {
                                this.props.walletId ? walletsTitleValues.length > 0 && walletsTitleValues.filter(wallet => wallet.value === this.props.walletId)[0].title :
                                    <ReactSearchAutocomplete<SelectionView>
                                        items={walletsTitleValues}
                                        fuseOptions={{ keys: ["title"] }}
                                        resultStringKeyName="title"
                                        inputSearchString={(walletsTitleValues.length > 0 && walletId) ? walletsTitleValues.filter(wallet => wallet.value === walletId)[0].title : undefined}
                                        showClear={false}
                                        showIcon={false}
                                        styling={{ borderRadius: "3px", border: "1px solid #ced4da", boxShadow: "unset", placeholderColor: "#000", height: '35px', fontSize: "15px", fontFamily: "Roboto-Regular" }}
                                        onSelect={(item) => this.setState({ walletId: item.value })}
                                        autoFocus={false}
                                        showItemsOnFocus={false}
                                        showNoResults={true}
                                    />
                            }
                        </Col>
                        <Col className="mt-3" md="12" sm="12">
                            <Form.Label>Amount:</Form.Label>
                            <Form.Control
                                type="text"
                                inputMode='decimal' value={amount} onChange={e => ValidAmount(e.target.value) && this.setState({ amount: e.target.value })} />
                        </Col>
                        <Col className="mt-3" md="12" sm="12">
                            <Form.Label>Description:</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="Enter description"
                                value={description}
                                onChange={e => this.setState({ description: e.target.value })} />
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                {pendingRequest ? <Button variant='success' disabled><Spinner size="sm" animation='border' /></Button> :
                    <Button variant='success' onClick={this.submit}>Create</Button>}
            </Modal.Footer>
        </Modal>
    }
}