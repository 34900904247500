import React from "react";
import { Subject } from 'rxjs';

export interface ILayoutContext {
    onAddToCartSubject: Subject<string>;
    loadCart: () => void;
    onReviewRequestChangedStatus:()=>void;
    reloadSideBar: () => void
}

const LayoutContext = React.createContext<ILayoutContext>({
    onAddToCartSubject: new Subject(),
    loadCart: () => { },
    onReviewRequestChangedStatus: () => { },
    reloadSideBar: () => { },
});

export default LayoutContext;