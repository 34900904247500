export default class FAQService {
    get(): Promise<FaqDto[]> {

        let sections: FaqDto[] = [
            {
                title: 'Orders',
                rank: 0,
                questions: [
                    {
                        text: 'Orders are placed in a specific currency?',
                        rank: 0,
                        answer: 'Yes, Orders are based on USD (other currencies can be converted).'
                    },
                    {
                        text: 'Is it possible to buy with cryptocurrencies?',
                        rank: 1,
                        answer: 'You can transfer your cryptocurrency to your store wallet and then convert it to USD.'
                    },
                    {
                        text: 'What is the delivery process?',
                        rank: 2,
                        answer: 'Your order will be notified via email once you register and complete it, then you can view it via the order section or download it.'
                    },
                    {
                        text: 'How long does it take to deliver orders?',
                        rank: 3,
                        answer: 'The delivery time depends directly on the seller\'s policies, but the seller specifies the delivery time for each product at the time of purchase. If "immediate" displays next to the product delivery time, your order will be sent to you immediately.'
                    },
                    {
                        text: 'When the delivery time exceeds the time displayed at the time of purchase, can the request be canceled?',
                        rank: 4,
                        answer: 'Yes, if the time is too long, then you can register a cancellation request, and the seller is obliged to confirm it and return the amount to your account.'
                    },
                    {
                        text: 'What is the best way to trust different sellers of products?',
                        rank: 5,
                        answer: 'Currently, we have created a mechanism to trust sellers that makes it easier for us and you to trust them. The money of orders for sellers is held for a certain period of time (currently 48 hours), and afterward, if there is no complaint in that free order, you will be refunded.'
                    },
                    {
                        text: 'How can I follow up if there is a problem with the order?',
                        rank: 6,
                        answer: 'You can report your problem by going to the orders section. There, you can select the report option (in front of each item on the order).'
                    },
                    {
                        text: 'How can I follow up on my issues?',
                        rank: 7,
                        answer: 'You can raise your issues through the support department throughout the week, and our colleagues in the support department will respond quickly.'
                    },
                    {
                        text: 'What can I do if the seller does not cooperate in solving the order problem?',
                        rank: 8,
                        answer: 'If the sellers cannot satisfy the buyer, in addition to the fact that you can record your satisfaction with their response in the same ticket, you can also raise your issues with our support. We will be the arbiter between the buyer and the seller until your issues are resolved.'
                    }
                ]
            },
            {
                title: 'Shopping',
                rank: 1,
                questions: [
                    {
                        text: 'Is it possible to add a product not listed in the product list that I need?',
                        rank: 0,
                        answer: 'Yes, you can submit your request from the online support section. Our partners will review your request and if it is approved, the desired product will be added.'
                    },
                    {
                        text: 'Is the product stored safely?',
                        rank: 1,
                        answer: 'Yes, from the moment products are registered in the system, products are stored in a secure location using sophisticated encryption.'
                    },
                    {
                        text: 'How are products pricing?',
                        rank: 2,
                        answer: 'There can be different prices for different numbers of purchases based on the seller\'s policies for that product.'
                    },
                    {
                        text: 'What are the differences between the sellers of each product?',
                        rank: 3,
                        answer: 'Each product has its own sales policy that differs based on: price, delivery time, number of sales, inventory.'
                    },
                    {
                        text: 'Is it possible to sell products here?',
                        rank: 4,
                        answer: 'Yes, from the stores section, you can request to upgrade your store to the level of sellers.'
                    },
                    {
                        text: 'Will I still be able to use the shopping facilities if the store is upgraded to a seller?',
                        rank: 5,
                        answer: 'Yes, it is possible to buy and sell a hybrid for your store in this case.'
                    },
                    {
                        text: 'Is it possible to purchase automatically from my website using the web service?',
                        rank: 6,
                        answer: 'Yes, The API documents are available in the API section of the site menu.'
                    }
                ]
            },
            {
                title: 'Financials',
                rank: 2,
                questions: [
                    {
                        text: 'Is it possible to convert other currencies to USD?',
                        rank: 0,
                        answer: 'You can convert currencies between one currency and another.'
                    },
                    {
                        text: 'Currency exchange rates are determined in what way?',
                        rank: 1,
                        answer: 'Rates are calculated using international rates, however, these rates are visible at the time of application.'
                    },
                    {
                        text: 'Where can I view my transaction history?',
                        rank: 2,
                        answer: 'You can view each deposit and withdrawal separately in the wallet transactions section.'
                    },
                    {
                        text: 'Is it possible to deposit money into my wallet on this site?',
                        rank: 3,
                        answer: 'You can choose to deposit using any method (in front of the wallets) by going to the wallets section.'
                    },
                    {
                        text: 'What is the time it takes for wallet deposits to be approved?',
                        rank: 4,
                        answer: 'After selecting the payment method, you will see the approximate time needed to determine the transaction.'
                    },
                    {
                        text: 'When I need to withdraw money from my wallet, can I do so?',
                        rank: 5,
                        answer: 'Yes, in the wallet section, select the withdrawal option (for each wallet).'
                    },
                    {
                        text: 'Is the wallet balance associated with the store?',
                        rank: 6,
                        answer: 'Yes, the wallet balance is based on your current store (selected from the menu on the site) and changes as the store changes.'
                    },
                    {
                        text: 'Why is local currency used? Is it possible to activate it?',
                        rank: 7,
                        answer: 'When you deposit currency into your store wallet, you can also enter the purchase price of the currency when choosing the local currency of the store. Therefore, in the statistics section, you can also see your balance in your local currency.'
                    },
                    {
                        text: 'There are discrepancies in my wallet transactions. How can I track them?',
                        rank: 8,
                        answer: 'Our systems and human resources continuously monitor transactions to prevent any errors in exchanges, but if you see discrepancies, please contact our support team.'
                    },
                    {
                        text: 'Is it possible to add another currency wallet?',
                        rank: 9,
                        answer: 'Yes, in the settings section of the wallets page, you can add other currency wallets to your store.'
                    }
                ]
            },
            {
                title: 'User Account',
                rank: 3,
                questions: [
                    {
                        text: 'Is it possible to register multiple stores at the same time?',
                        rank: 0,
                        answer: 'Yes, it is embedded in the system and you can register a new store if needed, and all items, including orders, financial resources, support, etc., will be defined based on the store of your choice (change through the site menu).'
                    },
                    {
                        text: 'Can others manage my store if I give them permission?',
                        rank: 1,
                        answer: 'Yes, your store staff can be invited and given the necessary permissions.'
                    },
                    {
                        text: 'Is my account secure?',
                        rank: 2,
                        answer: 'As well as the password that is used by default, you can activate the two-step verification system and enhance the security of your account.'
                    },
                    {
                        text: 'If two-step verification is activated, when is it necessary to use it?',
                        rank: 3,
                        answer: 'When logging in to your account, viewing order details, withdrawing money from wallets, receiving API connection tokens, and generally confidential items requires a two-step verification code. '
                    }
                ]
            }
        ];

        return Promise.resolve(sections);
    }
}

interface FaqDto {
    title: string,
    rank: number,
    questions: {
        text: string,
        rank: number,
        answer: string
    }[]
}