import React from "react";
export interface IAuthenticationContext {
    isAuthentication: () => boolean;
    login: (token: string) => void;
    logout: () => void;
    twoFactorAvtive: boolean;
    privileges: string[];
    roles: string[];
    policies: string[];
    role: string;
    user: { userName: string, displayName: string };
}
const AuthenticationContext = React.createContext<IAuthenticationContext>({
    login: () => { },
    logout: () => { },
    isAuthentication: () => false,
    twoFactorAvtive: false,
    privileges: [],
    roles: [],
    policies: [],
    role: 'buyer',
    user: { userName: '', displayName: '' }
});

export default AuthenticationContext;
