import React from "react"
import { Alert, Button, Card, Placeholder, Spinner, Table } from "react-bootstrap"
import { toast } from "react-toastify"
import ProductCatalogService from "../../../../services/catalog/ProductCatalogService";
import Amount from "../../../utilities/Amount/Amount";

export default class PriceComparison extends React.PureComponent<{ productId: string, apiPlanPrice: number, oneUnitPlanPrice: number, hideOwnedPlan?: boolean }, { sellers: SellerPrice[], inventoryCount: number, isLoading: boolean }> {
    productCatalogService: ProductCatalogService;
    constructor(props: any) {
        super(props)
        this.productCatalogService = new ProductCatalogService()
        this.state = { sellers: [], inventoryCount: 0, isLoading: true }
    }

    componentDidMount() {
        this.productCatalogService.getPrices(this.props.productId).then((productPrices) => {
            var sellers = productPrices.sellers.map((seller) => {
                return {
                    title: seller.title,
                    ApiPlanPrice: seller.plans.filter(plan => plan.isApiPlan)[0].price,
                    inventoryCount: seller.inventoryCount,
                    oneUnitPlanPrice: seller.plans[0].price,
                    isOwnedPlan: false
                } as SellerPrice
            });

            if (!this.props.hideOwnedPlan && productPrices.ownedSellPlan)
                sellers.push({
                    title: 'You',
                    ApiPlanPrice: this.props.apiPlanPrice,
                    oneUnitPlanPrice: this.props.oneUnitPlanPrice,
                    isOwnedPlan: true,
                    inventoryCount: productPrices.ownedSellPlan.availableInventoryCount
                });

            this.setState({ sellers: sellers, inventoryCount: productPrices.ownedSellPlan ? productPrices.ownedSellPlan.availableInventoryCount : 0, isLoading: false })
        });
    }


    render(): React.ReactNode {
        const sellers = this.state.sellers;
        return <Table size='sm'>
            <thead>
                <th>Seller</th>
                <th>Price (Plan API)</th>
                <th>Price (Plan one-unit)</th>
                <th>Inventory Count</th>
            </thead>

            <tbody>
                {
                    this.state.isLoading ?
                        Array.from(Array(5), (e, i) => <tr>
                            <td>
                                <Placeholder animation="glow" className="product-image">
                                    <Placeholder xs={12} />
                                </Placeholder>
                            </td>
                            <td>
                                <Placeholder animation="glow" className="product-image">
                                    <Placeholder xs={12} />
                                </Placeholder>
                            </td>
                            <td>
                                <Placeholder animation="glow" className="product-image">
                                    <Placeholder xs={12} />
                                </Placeholder>
                            </td>
                            <td>
                                <Placeholder animation="glow" className="product-image">
                                    <Placeholder xs={12} />
                                </Placeholder>
                            </td>
                        </tr>) : sellers.sort((a, b) => a.ApiPlanPrice - b.ApiPlanPrice).map(seller => <tr style={{
                            color: seller.isOwnedPlan === true
                                ? 'green' : '#000000'
                        }}>
                            <td>{seller.title}</td>
                            <td><Amount value={seller.ApiPlanPrice} /> </td>
                            <td><Amount value={seller.oneUnitPlanPrice} /> </td>
                            <td>{seller.inventoryCount === 0 ? 'Offer Only' : !seller.inventoryCount ? 'Variable' : seller.inventoryCount}</td>
                        </tr>)
                }
            </tbody>
        </Table>
    }
}

interface SellerPrice {
    isOwnedPlan: boolean,
    oneUnitPlanPrice: number,
    ApiPlanPrice: number,
    inventoryCount?: number,
    title: string
}