import React from "react";
import { Tab, Tabs } from "react-bootstrap"
import ReportsList from "./ReportsList/ReportsList";
import { SupportTicketStatus } from "./ReportsList/ReportsList.models";
import Note from "../../shared/Note/Note";

export default class Reports extends React.PureComponent<{ defaultActiveKey?: string | null }, {}> {
    render(): React.ReactNode {
        return (<div>
            <Note>Your customer reports and any issues regarding each order item sold by you will be displayed in this section. you can answer them</Note>
            <Tabs defaultActiveKey={this.props.defaultActiveKey === null ? 'unresponded' : this.props.defaultActiveKey} id="orders-tabs" className="mb-3" mountOnEnter={true} unmountOnExit={true}>

                <Tab eventKey="unresponded" title="Pending">
                    <ReportsList status={SupportTicketStatus.TargetPending} pageSize={10} />
                </Tab>
                <Tab eventKey="responded" title="User Pending">
                    <ReportsList status={SupportTicketStatus.UserPending} pageSize={10} />
                </Tab>
                <Tab eventKey="closed" title="Closed">
                    <ReportsList status={SupportTicketStatus.Closed} pageSize={10} />
                </Tab>
                <Tab eventKey="archive" title="Archive">
                    <ReportsList isActive={false} pageSize={10} />
                </Tab>
                <Tab eventKey="all" title="All">
                    <ReportsList isActive={true} pageSize={10} />
                </Tab>
            </Tabs>
        </div>)
    }
}