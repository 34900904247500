import React from "react";
import './OwnedOffersBoard.scss'
import { Button, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import Note from "../../../shared/Note/Note";
import OffersService, { OwnedOfferDto } from "../../../../services/orders/OffersService";
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
interface IProps{
    categoryId?: string 
}
export default class OwnedOffersBoard extends React.Component<IProps, { offers: OwnedOfferDto[] }> {
    offersService: OffersService;
    constructor(props: any) {
        super(props);
        this.offersService = new OffersService();
        this.state = { offers: [] }
    }

    componentDidMount(): void {
        this.loadBoard();
    }

    loadBoard = () => {
        this.offersService.getActiveOffers(this.props.categoryId).then((response) => {
            this.setState({ offers: response.rows })
        });
    }
    

    componentDidUpdate(prevProps: IProps) {
        if ((prevProps.categoryId !== this.props.categoryId))
            this.loadBoard();
    }

    cancelOffer = (id: string) => {
        if (window.confirm('Are you sure your offer will be cancelled?'))
            this.offersService.cancel(id).then((response) => {
                toast.success('Your offer canceled')
                this.loadBoard();
            });
    }
    render(): React.ReactNode {

        return <div className="OwnedOffersBoard">
           
            <h4 style={{ backgroundColor: '#ccc', color: '#fff', padding: '10px 30px', borderRadius: '10px 10px 0 0' }}>Your Active Offers</h4>
            <Note>Your offers pending and show for all sellers. also you can see all your offers <Link to="/orders/buy/?tab=offers">here</Link></Note>

            <Table size="md">
                <thead>
                    <tr>
                        <th>
                            <OverlayTrigger
                                key="top"
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip-top">
                                        Product Title
                                    </Tooltip>
                                }
                            >
                                <span>Product</span>
                            </OverlayTrigger>
                        </th>
                        <th>
                            <OverlayTrigger
                                key="top"
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip-top">
                                        Quantity item in offers
                                    </Tooltip>
                                }>
                                <span>
                                    Quantity
                                </span>
                            </OverlayTrigger>
                        </th>
                        <th>
                            <OverlayTrigger
                                key="top"
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip-top">
                                        Accepted Count item in offers
                                    </Tooltip>
                                }>
                                <span>
                                    Accepted
                                </span>
                            </OverlayTrigger>
                        </th>

                        <th>
                            <OverlayTrigger
                                key="top"
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip-top">
                                        Your maximum price in this offer (don't share with seller)
                                    </Tooltip>
                                }>
                                <span>
                                    Max Price
                                </span>
                            </OverlayTrigger>
                        </th>
                        <th>
                            <OverlayTrigger
                                key="top"
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip-top">
                                        Offer exipre at this time
                                    </Tooltip>
                                }>
                                <span>
                                    Expire At
                                </span>
                            </OverlayTrigger>
                        </th>
                        <th>

                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.offers.length == 0 ? <tr>
                            <td colSpan={5} style={{ textAlign: 'center' }}>offer not registered</td>
                        </tr> :
                            this.state.offers.map(offer => <tr>
                                <td style={{ color: '#198754' }}>

                                    {offer.productTitle}
                                </td>
                                <td>
                                    {offer.quantity}
                                </td>
                                <td>
                                    {offer.acceptedCount}
                                </td>
                                <td>
                                    {offer.price}
                                </td>
                                <td>
                                    {offer.expireAt ?? '-'}
                                </td>
                                <td>
                                    <Button variant="outline-danger" size="sm" onClick={() => this.cancelOffer(offer.id)}>Cancel</Button>
                                </td>
                            </tr>)
                    }

                </tbody>
            </Table >
        </div>
    }
}