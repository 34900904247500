import React, { RefObject, useEffect, useRef, useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { CaretDown, CaretDownFill, CaretUp, CaretUpFill, Lightbulb, LightbulbFill, Server } from "react-bootstrap-icons";
import { NavLink } from "react-router-dom";
import { SELLER } from "../../../../../constants/ConstantsPolicies";
import inventoryShopBasePlanStatusDisplayBadge, { inventoryShopBasePlanStatusDisplayCaption, InventoryShopBasePlanStatusEnum } from "../../../../inventory/Enums/InventoryShopBasePlanStatusEnum";
import ShopPolicies from "../../../../shared/policy/ShopPolicies/ShopPolicies";
import './ExpressProductCatalogItem.scss'

export interface ExpressProductCatalogItemView {
    title: string
    currencyId: string
    imageSrc: string | null
    imagePath: string | null
    productId: string
    realPrice: number
    sellers: any[],
    buySuggestionCount: number
    requiredCount: number
    deficitsCount: number
    status: InventoryShopBasePlanStatusEnum;
}

export interface ExpressProductCatalogItemProps {
    title: string
    currencyId: string
    imageSrc: string | null
    imagePath?: string | null
    productId: string
    realPrice: number
    onClickBuy: () => void
    onClickSell: () => void
}

export default class ExpressProductCatalogItem extends React.Component<ExpressProductCatalogItemProps, ExpressProductCatalogItemView> {
    refPolicies: RefObject<ShopPolicies>
    constructor(props: any) {
        super(props);
        this.refPolicies = React.createRef()
        this.state = {
            currencyId: '', imageSrc: null, productId: '', realPrice: 0, title: '',  sellers: [], imagePath: null,
            buySuggestionCount: 0, requiredCount: 0, deficitsCount: 0, status: InventoryShopBasePlanStatusEnum.Closed
        };
    }

    componentDidMount() {

    }

    render(): React.ReactNode {
        let item = this.props;

        return <div className={"ProductCatalogItem "}
            key={item.productId}>
            <img src={item.imagePath == null ? '/images/product-none.png' : item.imagePath} className="product-image" />
            <div className="product-title">{item.title}</div>
            <div className="bottom-section">
                <div>
                    <ShopPolicies policy={SELLER} access={false}>
                        <div onClick={() => this.refPolicies.current?.actionAfterCheckPolicy('seller')}
                            className="button outline" style={{ opacity: 0.5 }}><span>Sell</span> (Restricted)</div>
                    </ShopPolicies>
                    <ShopPolicies policy={SELLER}>
                        <div onClick={this.props.onClickSell} className="button outline"><span>Sell</span></div>
                    </ShopPolicies>

                </div>

                <div>
                    <div className="button"
                        onClick={this.props.onClickBuy}><span>Buy</span></div>
                </div>
            </div>


            <ShopPolicies ref={this.refPolicies} action={(data) => { }} policy={SELLER}
                modalConfig={{ description: `It looks like you do'nt have access to the seller features`, small: true }} />
        </div>
    }
}