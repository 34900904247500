import { CashPaymentStatusEnum } from "../../components/financials/Enums/CashPaymentStatusEnum";
import { GridResult } from "../../components/Grid/Grid.models";
import { PaginationQuery } from "../../components/Grid/Pagination/Pagination.models";
import { mapToDataOnly } from "../common/HttpUtility";
import { CashPaymentApiFactory, DepositApiFactory } from "../../generated-api/financials";

export default class DepositService {
    get(filters: IDepositsPaginationFilters, paginationQuery: PaginationQuery): Promise<GridResult<ICashPayment>> {
        return mapToDataOnly<GridResult<ICashPayment>>(
            CashPaymentApiFactory().apiV1CashPaymentGet(filters.accountId, paginationQuery.pageskip - 1, paginationQuery.pagesize))
    }

    delete(id: string) {
        return mapToDataOnly<{}>(CashPaymentApiFactory().apiV1CashPaymentIdDelete(id));
    }

    inquiry(hash: string): Promise<{ isExisting: boolean, details: TransactionInquiryView }> {
        return mapToDataOnly<{ isExisting: boolean, details: TransactionInquiryView }>(CashPaymentApiFactory().apiV1CashPaymentInquiryHashGet(hash));
    }

    create(dto: CreateDepositDto): Promise<{ paymentUrl: string }> {
        return mapToDataOnly<any>(DepositApiFactory().apiV1DepositPost(dto));
    }

    verify(depositId: string): Promise<{ verified: boolean, amount: number }> {
        return mapToDataOnly<any>(DepositApiFactory().apiV1DepositIdPut(depositId));
    }
}

export interface IDepositsPaginationFilters {
    accountId?: string
}
interface TransactionInquiryView {
    amount: number;
    token: string;
    toWalletAddress: string;
    isConfirm: boolean;
    fromWalletAddress: string;
    transactionDate: string;
}
interface ICashPayment {
    id: string,
    status: CashPaymentStatusEnum,
    invoiceId: string,
    amount: number,
    transactionNumber: string,
    crreateAt: Date,
    cashPaymentAccountTitle: string,
    purchasePrice: number,
    purchaseCurrency: string
}


interface CreateDepositDto {
    amount: number
    autoExchangeRequestWalletId: string
    cashPaymentAccountId: string
    description: string
    purchasePrice?: number
    callbackUrl: string
    refrenceNumber: string
}
