import React, { FormEvent } from "react"
import { Button, Col, Form, Row, Spinner } from "react-bootstrap"
import IState, { IProps } from "./AdminCreateSellerShop.models"
import AdminSellerService from "../../../../../services/admin/shops/AdminSellerService"
import { ReactSearchAutocomplete } from "react-search-autocomplete"
import { SelectionView } from "../../../../../services/common/Models"
import AdminUserService from "../../../../../services/admin/users/AdminUserService"
import { Navigate, NavLink } from "react-router-dom"
import { toast } from "react-toastify"

export default class AdminCreateSellerShop extends React.Component<IProps, IState> {
    adminSellerService: AdminSellerService
    adminUserService: AdminUserService

    constructor(props: any) {
        super(props)
        this.state = { pendingRequest: false, address: '', email: '', siteAddreess: '', tell: '', title: '', ownerUserId: null, users: [] }

        this.adminSellerService = new AdminSellerService()
        this.adminUserService = new AdminUserService()
    }

    componentDidMount(): void {
        this.getUsers()
    }

    getUsers = () => {
        this.adminUserService.getTitleValues().then((response) => {
            this.setState({ users: response })
        })
    }

    submit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();

        this.setState({ pendingRequest: true })
        let { address, email, siteAddreess, tell, title, ownerUserId } = this.state

        this.adminSellerService.create(ownerUserId, { address, email, siteAddreess, tell, title }).then((response) => {
            toast.success('Shop created successfully.')
            this.setState({ navigateTo: '/admin/market?activeTab=sellers' })
        }).finally(() => this.setState({ pendingRequest: false }))
    }

    render(): React.ReactNode {
        let { navigateTo, pendingRequest, address, email, siteAddreess, tell, title, ownerUserId } = this.state

        return <div>
            {navigateTo && <Navigate to={navigateTo} />}
            <Form className="mt-2" onSubmit={this.submit}>
                <Row>
                    <Col md="4" sm="12">
                        <h3>Create Seller Shop</h3>
                    </Col>
                </Row>
                <Row className="mb-3 mt-3">
                    <Col md="4" sm="12" style={{ zIndex: '1' }}>
                        <Form.Label>Owner</Form.Label>
                        <ReactSearchAutocomplete<SelectionView>
                            items={this.state.users}
                            fuseOptions={{ keys: ["title"] }}
                            resultStringKeyName="title"
                            inputSearchString={ownerUserId && this.state.users.filter(user => user.value === ownerUserId)[0].title}
                            showClear={false}
                            showIcon={false}
                            styling={{ borderRadius: "3px", border: "1px solid #ced4da", boxShadow: "unset", placeholderColor: "#000", height: '35px', fontSize: "15px", fontFamily: "Roboto-Regular" }}
                            onSelect={(item) => this.setState({ ownerUserId: item.value })}
                            autoFocus={false}
                            showItemsOnFocus={false}
                            showNoResults={false}
                        />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md="4" sm="12">
                        <Form.Group controlId="createShop.title">
                            <Form.Label>Title</Form.Label>
                            <Form.Control type="text" value={title}
                                placeholder="Enter your shop title"
                                onChange={(e) => this.setState({ title: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col md="4" sm="12">
                        <Form.Group controlId="createShop.email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="text" value={email}
                                placeholder="Enter your shop email"
                                onChange={(e) => this.setState({ email: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col md="4" sm="12">
                        <Form.Group controlId="createShop.siteAddreess">
                            <Form.Label>Site Url</Form.Label>
                            <Form.Control type="text" value={siteAddreess}
                                placeholder="Enter your shop site url"
                                onChange={(e) => this.setState({ siteAddreess: e.target.value })} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md="8" sm="12">
                        <Form.Group controlId="createShop.address">
                            <Form.Label>Address</Form.Label>
                            <Form.Control type="text" value={address}
                                placeholder="Enter your shop physical address"
                                onChange={(e) => this.setState({ address: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col md="4" sm="12">

                        <Form.Group controlId="createShop.tell">
                            <Form.Label>Tell</Form.Label>
                            <Form.Control type="text" value={tell}
                                placeholder="Enter your shop tell"
                                onChange={(e) => this.setState({ tell: e.target.value })} />
                        </Form.Group>

                    </Col>
                </Row>
                <Row>
                    <Col md="12" sm="12" className="mt-2">
                        {
                            pendingRequest ?
                                <Button variant="success" type="submit" disabled>
                                    <Spinner animation="border" />
                                </Button> :
                                <Button disabled={!ownerUserId} variant="success" type="submit">Create</Button>
                        }
                        <NavLink to={'/admin/market?activeTab=sellers'}><Button variant="outline-info" className="m-2">Sellers</Button></NavLink>
                    </Col>
                </Row>
            </Form>
        </div>
    }
}