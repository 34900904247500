import { Badge } from "react-bootstrap";

export enum PackageStatusEnum {
    Pending,
    Activated,
    Disabled,
    Rejected,
}

export function packageStatusDisplayCaption(status: number) {
    let types = ['Pending', 'Activated', 'Disabled', 'Rejected'];
    return types.length <= status ? status : types[status];
}

export function packageStatusColor(status: number) {
    let types = ['warning', 'success', 'dark', 'danger'];
    return types.length <= status ? 'dark' : types[status];
}

export default function packageStatusDisplayBadge(status: number) {
    return <Badge bg={packageStatusColor(status)}>{packageStatusDisplayCaption(status)}</Badge>;
}