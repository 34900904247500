import React, { RefObject } from "react";
import { Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import ShopService from "../../../services/shops/ShopService";
import { GridAction, GridColumn, GridColumnTypes } from "../../Grid/Grid.models";
import Note from "../../shared/Note/Note";
import ShopList from "../ShopList/ShopList";
import IState, { IProps } from './MyShopsManagement.models'

export default class MyShopsManagement extends React.Component<IProps, IState> {
    shopService: ShopService;
    shops: RefObject<ShopList>;
    constructor(props: IProps) {
        super(props);
        this.shopService = new ShopService();
        this.shops = React.createRef();
        this.state = { showModal: false, shopSelectedId: '' }
    }

    resetSpinnerGrid = () => {
        this.shops.current?.resetSpinnerGrid()
    }

    render(): React.ReactNode {
        return (<>
            <div>
                <h3> Pending Shops <NavLink to="/create-shop"><Button className="m-1" variant="success" size="sm">New Shop</Button></NavLink></h3>
                <Note>Your stores that are waiting for approval will be placed in this section.</Note>
                <hr />
                {this.shopLisView()}
            </div>
        </>)
    }

    shopLisView() {
        let gridColumns: GridColumn[] = [
            { title: 'Title', key: 'title' },
            { title: 'Created', key: 'createAt', type: GridColumnTypes.Date },
            { title: 'Activated', key: 'activeAt', type: GridColumnTypes.Date },
            { title: 'Number', key: 'number' },
            { title: 'Status', key: 'status' },
        ];

        let gridActions: GridAction[] = [
            {
                caption: 'Update Request', variant: 'warning', generateLink: (row) => `/create-shop/?shopId=${row.id}`
            }
        ];

        return (
            <div>
                <ShopList ref={this.shops} shopStatus={0} gridColumns={gridColumns} gridActions={gridActions} />
            </div>
        )
    }
}