import React, { FormEvent, RefObject } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import ShopService, { UpdateShopDTO } from "../../../services/shops/ShopService";
import ShopList from "../ShopList/ShopList";
import ICreateShopState, { ICreateShopProps } from "./CreateShop.models";
import { Navigate, NavLink } from "react-router-dom";
import { AxiosError } from "axios";

export default class CreateShop extends React.Component<ICreateShopProps, ICreateShopState> {
    shopService: ShopService;
    shopList: RefObject<ShopList>;
    constructor(props: any) {
        super(props);
        this.state = {
            title: '', address: '', email: '', siteAddreess: '', tell: '', checkedConfirmPolicy: false, redirect: false,
            loading: false, requestPending: false,
        };
        this.shopService = new ShopService();
        this.shopList = React.createRef();
    }

    componentDidMount() {
        if (this.props.shopeId !== null && this.props.shopeId !== undefined) {
            this.setState({ loading: true })
            this.shopService.getCurrent().then((response) => {
                this.mapData(response)
            }).finally(() => {
                this.setState({ loading: false })
            })
        }
    }

    mapData = (response: UpdateShopDTO) => {
        this.setState({
            address: response.address,
            email: response.email,
            siteAddreess: response.siteAddreess,
            tell: response.tell,
            title: response.title,
        })
    }

    submitForm = (e: FormEvent) => {
        e.preventDefault();
        if (this.state.loading || this.state.requestPending)
            return;
        this.setState({ requestPending: true })

        if (this.props.shopeId !== null && this.props.shopeId !== undefined) {
            this.shopService.update({ title: this.state.title, address: this.state.address, email: this.state.email, tell: this.state.tell, shopId: this.props.shopeId, siteAddreess: this.state.siteAddreess }).then(() => {
                toast.success('Your shop Updated successfully');
                this.setState({ redirect: true })
            }).finally(() => {
                this.setState({ requestPending: false })
            });
        } else {
            this.shopService.create({ title: this.state.title, address: this.state.address, email: this.state.email, siteAddreess: this.state.siteAddreess, tell: this.state.tell }).then(() => {
                toast.success('Your shop created successfully');
                this.setState({ redirect: true })
            }).catch((error) => {
                if (error.response.status == 400)
                    toast.error(error.response.data.message);
            }).finally(() => {
                this.setState({ requestPending: false })
            });
        }
    }

    render(): React.ReactNode {
        const { address, email, tell, siteAddreess, title, checkedConfirmPolicy, redirect, requestPending } = this.state
        return <div>
            {this.props.shopeId === null || this.props.shopeId === undefined && <><h3>Create Shop</h3><hr /></>}
            {redirect && (
                <Navigate to="/new-shops" replace={true} />
            )}
            <Form onSubmit={this.submitForm}>
                <Row>
                    <Col>
                        <Row>
                            <Col md="4" sm="12">
                                <Form.Group controlId="createShop.title">
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control type="text" value={title}
                                        placeholder="Enter your shop title"
                                        onChange={(e) => this.setState({ title: e.target.value })} />
                                </Form.Group>
                            </Col>
                            <Col md="4" sm="12">
                                <Form.Group controlId="createShop.email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="text" value={email}
                                        placeholder="Enter your shop email"
                                        onChange={(e) => this.setState({ email: e.target.value })} />
                                </Form.Group>
                            </Col>
                            <Col md="4" sm="12">
                                <Form.Group controlId="createShop.siteAddreess">
                                    <Form.Label>Site Url</Form.Label>
                                    <Form.Control type="text" value={siteAddreess}
                                        placeholder="Enter your shop site url"
                                        onChange={(e) => this.setState({ siteAddreess: e.target.value })} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="8" sm="12">
                                <Form.Group controlId="createShop.address">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control type="text" value={address}
                                        placeholder="Enter your shop physical address"
                                        onChange={(e) => this.setState({ address: e.target.value })} />
                                </Form.Group>
                            </Col>
                            <Col md="4" sm="12">

                                <Form.Group controlId="createShop.tell">
                                    <Form.Label>Tell</Form.Label>
                                    <Form.Control type="text" value={tell}
                                        placeholder="Enter your shop tell"
                                        onChange={(e) => this.setState({ tell: e.target.value })} />
                                </Form.Group>

                            </Col>
                            <Col md="12" sm="12">
                                {
                                    (this.props.shopeId === null || this.props.shopeId === undefined) &&
                                    <Form.Group controlId="isConfirmPolicy">
                                        <Form.Check>
                                            <Form.Check.Label> As Shop Owner, This
                                                <NavLink to="/policy" className="p-1">
                                                    Policy
                                                </NavLink>
                                                is confirmed for me
                                                <Form.Check.Input checked={checkedConfirmPolicy} onChange={() => this.setState({ checkedConfirmPolicy: !this.state.checkedConfirmPolicy })} type="checkbox" />
                                            </Form.Check.Label>
                                        </Form.Check>
                                    </Form.Group>
                                }
                            </Col>
                        </Row>
                        {
                            this.state.requestPending || this.state.loading ?
                                <Button variant="success" className="mt-2" type="submit" disabled>
                                    <Spinner animation="border" />
                                </Button> :
                                <Button variant="success" className="mt-2" type="submit" disabled={(this.props.shopeId === null || this.props.shopeId === undefined) && checkedConfirmPolicy === false || requestPending}>
                                    {this.props.shopeId !== null && this.props.shopeId !== undefined ? 'Update' : 'Create'}
                                </Button>
                        }
                    </Col>
                </Row>
            </Form>
        </div>
    }
}