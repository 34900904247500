import React from "react";
import { Col, Placeholder, Row } from "react-bootstrap";
import './Banners.scss'

export default class Banners extends React.Component<{}, { loaded: boolean }> {
    constructor(props: any) {
        super(props);
        this.state = { loaded: false };
    }
    render(): React.ReactNode {
        return (<div className="banners">
            <Row>
                <Col xs="12" lg="6">
                    <div className="banner">
                        {
                            this.state.loaded ? null : <Placeholder animation="glow" style={{ width: '50%' }}>
                                <Placeholder style={{ height: '135px', borderRadius: '8px', width: '100%' }} />
                            </Placeholder>
                        }
                        <img src="/images/banner-wide-1.jpg"
                            style={{ display: this.state.loaded ? 'block' : 'none' }}
                            onLoad={() => this.setState({ loaded: true })}
                        />
                    </div>
                </Col>


                <Col xs="12" lg="6">
                    <div className="banner">
                        {
                            this.state.loaded ? null : <Placeholder animation="glow" style={{ width: '50%' }}>
                                <Placeholder style={{ height: '135px', borderRadius: '8px', width: '100%' }} />
                            </Placeholder>
                        }
                        <img src="/images/banner-wide-2.jpg"
                            style={{ display: this.state.loaded ? 'block' : 'none' }}
                            onLoad={() => this.setState({ loaded: true })}
                        />
                    </div>
                </Col>
            </Row>
        </div>);
    }
}