import React from "react";
import { Alert, Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import SupportTicketsService from "../../../../services/helpCenter/SupportTicketService";
import { NavLink } from "react-router-dom";

export default class CreateReportModal extends React.Component<{ title?: string, onClose: () => void }, { description: string, departmentId: string, pendingRequest: boolean, ticketId?: string }> {
    supportTicketsService: SupportTicketsService
    constructor(props: any) {
        super(props);
        this.state = { description: '', pendingRequest: false, departmentId: '' }
        this.supportTicketsService = new SupportTicketsService()
    }

    componentDidMount(): void {
        this.supportTicketsService.getDepartments().then((departments) => this.setState({ departmentId: departments[0].value }))
    }

    submit = () => {
        let { description, departmentId } = this.state
        let { title } = this.props

        this.setState({ pendingRequest: true })
        this.supportTicketsService.create(`Report -${title}`, description, departmentId).then((ticketId) => {
            this.setState({ ticketId })
        }).finally(() => this.setState({ pendingRequest: false }))
    }

    render(): React.ReactNode {
        let { onClose } = this.props
        let { description, pendingRequest, ticketId } = this.state
        let { title } = this.props

        return <Modal show={true} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Report - {title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col className="mt-3" md="12" sm="12">
                            <Form.Label>Description:</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="Enter description"
                                value={description}
                                onChange={e => this.setState({ description: e.target.value })} />
                        </Col>
                        {ticketId &&
                            <Col className="mt-3" md="12" sm="12">

                                <Alert variant="success">
                                    <p>Your report has been successfully submitted.</p>
                                    <NavLink to={`/support-ticket?id=${ticketId}`}><Button size="sm" variant='success'>Show Ticket</Button></NavLink>
                                </Alert>
                            </Col>
                        }
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                {pendingRequest ? <Button variant='success' disabled><Spinner size="sm" animation='border' /></Button> :
                    <Button variant='success' disabled={ticketId != undefined} onClick={this.submit}>Send</Button>}
            </Modal.Footer>
        </Modal>
    }
}