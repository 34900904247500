import React from "react";
import { Button, Col, Form, Spinner, Table } from "react-bootstrap";
import { NotificationPermissionRowDto } from "../../../../services/notifications/NotificationPermissionService";

interface INotificationPermissaionState {
    isActiveAll: boolean
    permissions: NotificationPermissionRowDto[]
}

export default class NotificationPermissionsList extends React.PureComponent<{ onSubmit: (newPermissions: NotificationPermissionRowDto[]) => void, pendingRequest: boolean, permissions: NotificationPermissionRowDto[] }, INotificationPermissaionState> {
    constructor(props: any) {
        super(props);
        this.state = { permissions: [], isActiveAll: false };
    }

    componentDidMount(): void {
        this.setState({ permissions: this.props.permissions, isActiveAll: this.props.permissions.filter(item => item.isActive).length == this.props.permissions.length })
    }

    toggleSelectAll = () => {
        let updatedPermissions = this.state.permissions.map((privilege) => ({
            ...privilege,
            isActive: !this.state.isActiveAll,
        }));

        this.setState({ isActiveAll: !this.state.isActiveAll, permissions: updatedPermissions })
    }

    selectRow = (row: NotificationPermissionRowDto) => {
        let tmpPrivileges: NotificationPermissionRowDto[] = this.state.permissions
        tmpPrivileges.filter((item) => item.id === row.id).map((privilege) => {
            return privilege.isActive = !privilege.isActive
        })

        this.setState({ permissions: tmpPrivileges, isActiveAll: tmpPrivileges.filter(item => !item.isActive).length > 0 ? false : true })
        this.forceUpdate()
    }

    render(): React.ReactNode {
        return (
            <>
                <Table hover responsive size="lg" className="mt-5">
                    <thead>
                        <tr>
                            <th>Title</th>
                            <th><Form.Check type="checkbox" checked={this.state.isActiveAll} onChange={() => this.toggleSelectAll()} /></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.permissions.map((permission) =>
                                <tr key={permission.id}>
                                    <td>{permission.title}</td>
                                    <td><Form.Check type="checkbox" checked={permission.isActive} onChange={() => this.selectRow(permission)} /></td>
                                </tr>)
                        }
                    </tbody>
                </Table>
                <Col md="12" sm="12" className="mt-3">
                    {this.props.pendingRequest ? <Button variant='success' disabled><Spinner size="sm" animation='border' /></Button> :
                        <Button variant='success' onClick={() => this.props.onSubmit(this.state.permissions)}>Update</Button>}
                </Col>
            </>
        )
    }
}