import React, { RefObject } from "react";
import ISearchState, { ISearchProps } from "./Search.models";
import SearchService, { SearchSuggestionDto, SuggestionType } from "../../../services/catalog/SearchService";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { Navigate } from "react-router-dom";

export default class Search extends React.Component<ISearchProps, ISearchState> {
    searchService: SearchService
    searchAutocomplete: RefObject<{}>;

    constructor(props: any) {
        super(props)
        this.state = { suggestions: [], text: '', loading: false, hasSelected: false }
        this.searchService = new SearchService()
        this.searchAutocomplete = React.createRef()
    }

    componentDidMount(): void {
        let self = this

        document.querySelector('#search').addEventListener('keypress', function (e: any) {
            if (e.key === 'Enter') {
                if (!self.state.hasSelected && self.state.suggestions.filter(suggestion => suggestion.title == self.state.text).length == 0)
                    self.setState({ searchQuery: self.state.text, selectedItem: undefined })
            }
        });
    }

    search = (query: string) => {
        this.setState({ loading: true, text: query, hasSelected: false, selectedItem: undefined, searchQuery: undefined })

        this.searchService.getSuggestion(query).then((response) => {
            this.setState({
                suggestions: response.sort((p1, p2) => {
                    if (p1.type < p2.type) return -1;
                    if (p1.type > p2.type) return 1;
                    return 0;
                })
            })
        }).finally(() => this.setState({ loading: false }))
    }

    onSelect = (item: SearchSuggestionDto) => {
        if (item != this.state.selectedItem)
            this.setState({ hasSelected: true, selectedItem: item })
    }

    formatResult = (item: SearchSuggestionDto) => {
        return (
            <div className="d-flex align-items-center">
                {
                    item.type == SuggestionType.Category ?
                        <span> Category - {item.title}</span> : <span> Product - {item.title}</span>
                }
            </div>
        )
    }

    render(): React.ReactNode {
        let { suggestions, selectedItem, searchQuery } = this.state

        return <div id="search">
            {
                selectedItem && <>
                    {selectedItem.type == SuggestionType.Category ? selectedItem.value != this.props.categoryId && <Navigate to={`/marketplace/?categoryId=${selectedItem.value}&mode=${this.props.catalogMode}`} replace={true} /> :
                        selectedItem.type == SuggestionType.Product ? selectedItem.value != this.props.productId && <Navigate to={`/marketplace/?productId=${selectedItem.value}&mode=${this.props.catalogMode}`} replace={true} /> : null
                    }
                </>
            }
            {selectedItem == undefined && searchQuery != undefined && searchQuery != this.props.search && <Navigate to={`/marketplace/?search=${searchQuery}&mode=${this.props.catalogMode}`} replace={true} />}
            <ReactSearchAutocomplete<SearchSuggestionDto>
                items={suggestions}
                fuseOptions={{ keys: ["title"] }}
                resultStringKeyName="title"
                showIcon={true}
                inputSearchString={this.props.search && this.props.search}
                placeholder="Search in "
                styling={{ color: '#1b3a8c', backgroundColor: '#f8f9fa', borderRadius: "25px", border: "1px solid #1b3a8c", boxShadow: "unset", iconColor: '#1b3a8c', placeholderColor: "#1b3a8c", height: '46px', fontSize: "15px", fontFamily: "Roboto-Regular" }}
                onSelect={this.onSelect}
                onSearch={this.search}
                autoFocus={false}
                showItemsOnFocus={false}
                showNoResults={false}
                formatResult={this.formatResult}
            />
        </div>
    }
}