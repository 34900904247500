import React from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { IProductCatalogColumnView } from "./TradeProductCatalog.models";


export interface ITableCatalogConfig {
    selectedGridColumnKeys: string[],
    coloredRow: boolean,
    showOpenProductsOnly: boolean
}

interface IProps {
    show: boolean,
    tableConfig: ITableCatalogConfig,
    onChange: (config: ITableCatalogConfig) => void,
    onClose: () => void,
    gridColumns: IProductCatalogColumnView[]
}

export default class TradeProductCatalogConfig extends React.Component<IProps> {
    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        var selectedColumnKeys = this.props.gridColumns.filter(gridColumn => gridColumn.selectedByDefault === true).map(gridColumn => gridColumn.key);

        if (localStorage.getItem('dashboard-grid-columns'))
            selectedColumnKeys = JSON.parse(localStorage.getItem('dashboard-grid-columns'));

        if (localStorage.getItem('dashboard-show-open-products-only'))
            this.props.onChange({ ...this.props.tableConfig, showOpenProductsOnly: JSON.parse(localStorage.getItem('dashboard-show-open-products-only')) });

        this.props.onChange({ ...this.props.tableConfig, selectedGridColumnKeys: selectedColumnKeys })
    }


    toggleColumn = (key: string) => {
        var keys = this.props.tableConfig.selectedGridColumnKeys;
        if (keys.filter(showGridColumnKey => showGridColumnKey == key).length > 0)
            keys.splice(keys.indexOf(key), 1)
        else
            keys.push(key)

        this.props.onChange({ ...this.props.tableConfig, selectedGridColumnKeys: keys });
        localStorage.setItem('dashboard-grid-columns', JSON.stringify(keys));
    }
    toggleColoredRow = () => {
        localStorage.setItem('dashboard-colored-row', JSON.stringify(!this.props.tableConfig.coloredRow));
        this.props.onChange({ ...this.props.tableConfig, coloredRow: !this.props.tableConfig.coloredRow });
    }

    toggleShowOpenProductsOnly = () => {
        localStorage.setItem('dashboard-show-open-products-only', JSON.stringify(!this.props.tableConfig.showOpenProductsOnly));
        this.props.onChange({ ...this.props.tableConfig, showOpenProductsOnly: !this.props.tableConfig.showOpenProductsOnly });
    }

    render(): React.ReactNode {
        return <Modal show={this.props.show} onHide={this.props.onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Table Config</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Columns</h4>
                <ul>
                    {
                        this.props.gridColumns.map((column) => <li style={{ display: 'block' }}>
                            <Form.Check name="columns" id={'column-check-box-' + column.key}
                                checked={this.props.tableConfig.selectedGridColumnKeys.filter(gridColumnKey => gridColumnKey == column.key).length > 0}
                                onChange={() => this.toggleColumn(column.key)}
                                type="checkbox" label={column.description === undefined ? column.title : <>{column.title} - <span style={{ fontSize: '14px', color: 'gray' }}> {column.description}</span></>} />
                        </li>)
                    }

                </ul>
                <h4>Other</h4>
                <Form.Check name="row-coloring-active" id={'row-coloring-active'}
                    checked={this.props.tableConfig.coloredRow}
                    onChange={this.toggleColoredRow}
                    type="checkbox" label={'Colored row'} />

                <Form.Check name="show-open-products-only" id={'show-open-products-only'}
                    checked={this.props.tableConfig.showOpenProductsOnly}
                    onChange={this.toggleShowOpenProductsOnly}
                    type="checkbox" label={'Show open products only'} />
            </Modal.Body>
        </Modal>
    }
    hasPolicy = (policy: string) => this.context.policies?.includes(policy)


}