import React, { RefObject } from "react";
import { Form } from "react-bootstrap";
import { GridAction, GridColumn, GridColumnTypes} from "../../../Grid/Grid.models";
import DynamicGrid from "../../../shared/DynamicGrid/DynamicGrid";
import supportTicketStatusDisplayBadge from "../../SupportTickets/SupportTicketStatusEnum";
import IReportsState, { IReportsProps, IReviewRequestPaginationFilters } from "./ReportsList.models";
import ReviewRequestService from "../../../../services/orders/ReviewRequestService";

export default class ReportsList extends React.PureComponent<IReportsProps, IReportsState> {
    dynamicGrid: RefObject<DynamicGrid<IReviewRequestPaginationFilters>>
    reviewRequestService: ReviewRequestService
    constructor(props: any) {
        super(props);
        this.dynamicGrid = React.createRef();
        this.state = {};
        this.reviewRequestService = new ReviewRequestService()
    }


    rowClass(row: any) {
        return (row.isMediationRequested === true && row.status !== 3) ? "bg-danger" : "";
    }


    renderCustomFilterView = (key: string, value: string) => {
        switch (key) {
            case 'status':
                return supportTicketStatusDisplayBadge(Number(value))
            case 'isActive':
                return value ? 'Active' : 'Not Active'
            default: return value
        }
    }

    render(): React.ReactNode {

        let gridColumns: GridColumn[] = [
            { title: 'Ticket Number', key: 'ticketNumber' },
            { title: 'Title', key: 'title' },
            { title: 'User', key: 'userDisplayName' },
            { title: 'Created', key: 'createAt', type: GridColumnTypes.DateOnly },
            { title: 'Status', key: 'statusBadge' },
            { title: 'Tags', key: 'tags', type: GridColumnTypes.BadgeList },
        ]

        let gridActions: GridAction[] = [
            {
                caption: 'Details', variant: 'outline-info', generateLink: (row) => `/support-ticket-report-view/?ticketId=${row.id}`,
            }
        ]

        return <DynamicGrid<IReviewRequestPaginationFilters> ref={this.dynamicGrid}
            columns={gridColumns}
            onFetch={this.reviewRequestService.getSellPaginated}
            onFetched={(result) => this.setState({ hasData: result.totalRows > 0 })}
            defaultFilters={{ isActive: this.props.isActive, status: this.props.status, showOnlyPendings: this.props.showOnlyPendings }}
            actions={gridActions}
            filtersForm={this.filtersView}
            rowClassName={this.rowClass}
            refactorFilterDisplay={this.renderCustomFilterView}
            refactorRow={(row) => {
                return { ...row, statusBadge: supportTicketStatusDisplayBadge(row.status) }
            }} />
    }

    filtersView = (filters: IReviewRequestPaginationFilters, onChange: (newFilters: IReviewRequestPaginationFilters) => void) => {
        return <>
            <Form.Group>
                <Form.Label>Ticket Number</Form.Label>
                <Form.Control value={filters.ticketNumber} onChange={(e) => onChange({ ...filters, ticketNumber: e.target.value })}
                    type="text" placeholder="Enter Ticket Number" />
            </Form.Group>
        </>
    }
}