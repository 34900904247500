import React from "react"
import { Button, Col, Container, Form, Row } from "react-bootstrap"
import { ContactUsData } from '../../constants/Content/ContactUs'
import SectionView from "../../shared/SectionView/SectionView"

export default class ContactUs extends React.PureComponent<{}, { name: string, email: string, message: string }> {
    constructor(props: any) {
        super(props)
        this.state = { email: '', message: '', name: '' }
    }
    render(): React.ReactNode {
        return <div className="contact-us">
            <Container className="pt-7">
                <SectionView title={ContactUsData.title} description={ContactUsData.description+'<a href="mailto:info@apigift.com">info@apigift.com</a>'} />
            </Container>
        </div>
    }
}