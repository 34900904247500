import { Badge } from "react-bootstrap";
export enum CashOutStatusEnum {
    Pending,
    Rejected,
    Done,
    Accepted,
    Canceled
}

export function cashOutStatusDisplayCaption(status: number) {
    let types = ['Pending', 'Reject', 'Done', 'Accepted', 'Canceled'];
    return types.length <= status ? status : types[status];
}

export function cashOutStatusColor(status: number) {
    let types = ['warning', 'danger', 'success', 'primary', 'danger'];
    return types.length <= status ? 'dark' : types[status];
}

export default function cashOutStatusDisplayBadge(status: number) {
    return <Badge bg={cashOutStatusColor(status)}>{cashOutStatusDisplayCaption(status)}</Badge>;
}