import React from "react"
import AdminSellerShops from "../shops/sellers/AdminSellers"

export default class AdminMarket extends React.Component<{
    defaultActiveKey?: string
}, {}> {
    render(): React.ReactNode {
        return (<>
            <AdminSellerShops />
        </>)
    }
}