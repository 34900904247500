import React from "react";
import IState, { IProps } from "./AllCategoriesView.models";
import Moment from 'moment';
import { toast } from "react-toastify";
import inventoryShopBasePlanStatusDisplayBadge, { InventoryShopBasePlanStatusEnum } from "../../Enums/InventoryShopBasePlanStatusEnum";
import CardView from "../../../shared/Card/CardView";
import ProductCategoryService from "../../../../services/category/ProductCategoryService";
import { Button, Table } from "react-bootstrap";

export default class AllCategoriesView extends React.PureComponent<IProps, IState> {
    productCategoryService: ProductCategoryService
    constructor(props: any) {
        super(props)
        this.productCategoryService = new ProductCategoryService()
        this.state = { showModal: false }
    }

    openSellRequest = (productCategoryId: string) => {
        if (window.confirm('Are you sure you want to open sell request?')) {
            this.productCategoryService.openSellRequest(productCategoryId).then(() => {
                toast.success('Request sell created successfully')
                this.props.reload()
            })
        }
    }

    renderActionView = (status: InventoryShopBasePlanStatusEnum | null, productCategoryId: string) => {
        if (InventoryShopBasePlanStatusEnum.Closed)
            switch (status) {
                case InventoryShopBasePlanStatusEnum.Closed:
                    return <div><b>(You can add this in edit mode)</b>{inventoryShopBasePlanStatusDisplayBadge(status)}</div>
                case InventoryShopBasePlanStatusEnum.Pending:
                case InventoryShopBasePlanStatusEnum.Suspended:
                    return inventoryShopBasePlanStatusDisplayBadge(status)
                default:
                    return <Button variant="success" onClick={() => this.openSellRequest(productCategoryId)} size="sm" style={{ whiteSpace: 'nowrap' }}>Open Request</Button>
            }
    }

    render(): React.ReactNode {
        return <>{this.allCategoriesView()}</>
    }

    allCategoriesView() {
        return <CardView card={{ header: 'All Category' }} >
            <Table striped hover responsive size="lg">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Title</th>
                        <th>Last Reject Time</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.categories.map(((category, index) => {
                        return <tr key={category.id}>
                            <td>{index + 1}</td>
                            <td>{category.title}</td>
                            <td>{category.rejectedAt ? (Moment(category.rejectedAt).utc().format('YYYY/MM/DD HH:mm')) : '-'}</td>
                            <td className="text-end text-center">{this.renderActionView(category.status, category.id)}</td>
                        </tr>
                    }))}
                </tbody>
            </Table>
        </CardView>
    }
}