import { GridResult } from "../../components/Grid/Grid.models";
import { PaginationQuery } from "../../components/Grid/Pagination/Pagination.models";
import { HoldApiFactory } from "../../generated-api/financials";
import { mapToDataOnly } from "../common/HttpUtility";

export default class HoldService {
    get(filters: { walletId: string }, paginationQuery: PaginationQuery): Promise<GridResult<HoldRowDTO>> {
        return mapToDataOnly<GridResult<HoldRowDTO>>(HoldApiFactory().apiV1HoldGet(filters.walletId, paginationQuery.pageskip - 1, paginationQuery.pagesize));
    }
}

export interface HoldRowDTO {
    id: string;
    ownerId: string | null;
    ownerTitle: string | null;
    amountValue: number;
    createDate: string;
    creatorUserId: string;
    description: string;
    creatorUserName: string;
}