import React from "react";
import { Button, Table } from "react-bootstrap";
import { CaretDownFill, CaretUpFill } from "react-bootstrap-icons";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import AdminArticleCategoryService from "../../../../services/admin/articles/AdminArticleCategoryService";
import IState, { IArticleCategory } from "./AdminArticleCategoty.models";

export default class AdminArticleCategory extends React.Component<{}, IState> {
    adminArticleCategoryService: AdminArticleCategoryService
    constructor(props: any) {
        super(props);
        this.state = {articleCategories: [], loading: false};
        this.adminArticleCategoryService = new AdminArticleCategoryService()
    }

    componentDidMount() {
        this.getColumns()
    }

    getColumns = () => {
        this.setState({ loading: true })
        this.adminArticleCategoryService.getPaged().then((response) => {

            let data: IArticleCategory[] = response.filter((articleCategory: IArticleCategory) => articleCategory.parentId == null).map(category => {
                return {
                    ...category,
                    isShow: false,
                    childs: response.filter(child => child.parentId == category.id).map(child => {
                        return {
                            ...child,
                            isShow: false,
                            childs: response.filter(item => item.parentId == child.id).map(item => {
                                return {
                                    ...item,
                                    isShow: false,
                                    childs: []
                                }
                            })
                        }
                    })
                }
            })
            this.setState({ articleCategories: data, loading: false })
        })
    }

    showChild = (index: number, childIndex?: number) => {
        let temp = this.state.articleCategories;

        if (childIndex != undefined)
            temp[index].childs[childIndex].isShow = !temp[index].childs[childIndex].isShow
        else
            temp[index].isShow = !temp[index].isShow

        this.setState({ articleCategories: temp })
        this.forceUpdate()
    }

    requestToRemoveRow = (id: string) => {
        if (window.confirm('Are you sure? This Article category will be deleted permanently')) {
            this.adminArticleCategoryService.delete(id).then(() => {
                toast.success("the article category deleted successfully")
                this.getColumns()
            })
        }
    }

    departmentStatusDisplay = (status: boolean) => {
        return <span>{status ? 'Active' : 'Inactive'}</span>
    }

    render(): React.ReactNode {
        return <>
            <NavLink to={'/admin/create-articleCategory'}><Button variant="success" className="mt-4 mb-4">Add</Button></NavLink>
            <Table style={{ textAlign: "center", verticalAlign: "middle" }} size="lg" responsive>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Title</th>
                        <th>Is Active</th>
                        <th>Parent Title</th>
                        <th>Order</th>
                        <th>Childs</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.articleCategories.map((articleCategory, index) => {
                        return (
                            <>
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{articleCategory.title}</td>
                                    <td>{this.departmentStatusDisplay(articleCategory.isActive)}</td>
                                    <td>{articleCategory.parentTitle}</td>
                                    <td>{articleCategory.orderId}</td>
                                    <td>{articleCategory.childs.length}</td>
                                    <td className="text-center" style={{ verticalAlign: 'middle' }}>
                                        <NavLink to={`/admin/create-articleCategory?articleCategoryId=${articleCategory.id}`}><Button className="mr-1" size="sm" variant="outline-warning">Edit</Button></NavLink>
                                        <Button className="mr-1" size="sm" variant="outline-danger" onClick={() => this.requestToRemoveRow(articleCategory.id)}>Delete</Button>
                                        {articleCategory.childs.length > 0 && (articleCategory.isShow ? <CaretUpFill onClick={() => this.showChild(index)} /> : <CaretDownFill onClick={() => this.showChild(index)} />)}
                                    </td>
                                </tr>
                                {articleCategory.childs.map((child, childIndex) => {
                                    if (articleCategory.isShow)
                                        return (
                                            <>
                                                <tr>
                                                    <td>{ }</td>
                                                    <td>{child.title}</td>
                                                    <td>{this.departmentStatusDisplay(child.isActive)}</td>
                                                    <td>{child.parentTitle}</td>
                                                    <td>{child.orderId}</td>
                                                    <td>{child.childs.length}</td>
                                                    <td className="text-center" style={{ verticalAlign: 'middle' }}>
                                                        <NavLink to={`/admin/category-manage?categoryId=${child.id}`}><Button className="mr-1" size="sm" variant="outline-warning">Edit</Button></NavLink>
                                                        <Button className="mr-1" size="sm" variant="outline-danger" onClick={() => this.requestToRemoveRow(child.id)}>Delete</Button>
                                                        {child.childs.length > 0 && (child.isShow ? <CaretUpFill onClick={() => this.showChild(index, childIndex)} /> : <CaretDownFill onClick={() => this.showChild(index, childIndex)} />)}
                                                    </td>
                                                </tr>
                                                {child.childs.map(sub_child => {
                                                    if (child.isShow)
                                                        return (
                                                            <tr>
                                                                <td>{ }</td>
                                                                <td>{sub_child.title}</td>
                                                                <td>{this.departmentStatusDisplay(sub_child.isActive)}</td>
                                                                <td>{sub_child.parentTitle}</td>
                                                                <td>{sub_child.orderId}</td>
                                                                <td>{sub_child.childs.length}</td>
                                                                <td className="text-center" style={{ verticalAlign: 'middle' }}>
                                                                    <NavLink to={`/admin/category-manage?categoryId=${sub_child.id}`}><Button className="mr-1" size="sm" variant="outline-warning">Edit</Button></NavLink>
                                                                    <Button className="mr-1" size="sm" variant="outline-danger" onClick={() => this.requestToRemoveRow(sub_child.id)}>Delete</Button>
                                                                </td>
                                                            </tr>
                                                        )
                                                })}
                                            </>
                                        )
                                })}
                            </>
                        )
                    })}
                </tbody>
            </Table>
            {this.state.articleCategories.length == 0 && <div className="d-flex justify-content-center">not exist any data.</div>}
        </>
    }
}
