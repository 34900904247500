import React, { FormEvent } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { LocalCurrencyService } from "../../../../services/financials/LocalCurrencyService";
import InventorySourceTransactionService from "../../../../services/inventory/InventorySourceTransactionService";
import IState, { IProps } from "./CreateInventorySourceTransaction.models";
import DatePicker from "react-datepicker";
export default class CreateInventorySourceTransaction extends React.Component<IProps, IState> {
    localCurrencyService: LocalCurrencyService
    inventorySourceTransactionService: InventorySourceTransactionService
    constructor(props: IProps) {
        super(props)
        this.state = { isLoading: false, transaction: { description: '', inventorySourceId: this.props.sourceId as string, isToSource: false, shopAmountValue: 0, shopCurrencyId: '', sourceAmountValue: 0, sourceCurrencyId: '', transactionAt: new Date() }, currencies: [] }
        this.localCurrencyService = new LocalCurrencyService()
        this.inventorySourceTransactionService = new InventorySourceTransactionService()
    }

    componentDidMount() {
        this.getCurrencies()
    }

    getCurrencies() {
        this.localCurrencyService.getTitleValues().then((response) => {
            this.setState((prevState) => { return { currencies: response, transaction: { ...prevState.transaction, shopCurrencyId: response[0].value, sourceCurrencyId: response[0].value } } })
        })
    }

    submitForm = (e: FormEvent) => {
        e.preventDefault();

        this.setState({ isLoading: true })
        this.inventorySourceTransactionService.create(this.state.transaction).then(() => {
            toast.success("The source transaction added successfully")
        }).finally(() => {
            this.setState({ isLoading: false })
        })
    }

    render(): React.ReactNode {
        const { transaction, currencies, isLoading } = this.state
        return <div>
            <h3>{this.props.sourceTransactionId === null || this.props.sourceTransactionId === undefined ? 'Create' : 'Update'}</h3><hr />
            <Form onSubmit={this.submitForm}>
                <Row>
                    <Col md="6" sm="12">
                        <Form.Group controlId="isToSource">
                            <Form.Check type="checkbox" label="Pay To Source" checked={transaction.isToSource} onChange={e => this.setState((prevState) => { return { transaction: { ...prevState.transaction, isToSource:!transaction.isToSource } } })} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md="6" sm="12">
                        <Form.Group controlId="sourceAmountValue">
                            <Form.Label>Source Amount:</Form.Label>
                            <Form.Control type="number" value={transaction.sourceAmountValue} onChange={e => this.setState((prevState) => { return { transaction: { ...prevState.transaction, sourceAmountValue: Number(e.target.value) } } })} />
                        </Form.Group>
                    </Col>
                    <Col md="6" sm="12">
                        <Form.Group controlId="sourceCurrencyId">
                            <Form.Label>Source Currency:</Form.Label>
                            <Form.Select aria-label=">Select source currency" id="currencyId" value={transaction.sourceCurrencyId}
                                onChange={e => this.setState((prevState) => { return { transaction: { ...prevState.transaction, sourceCurrencyId: e.target.value } } })}>
                                {
                                    currencies.map((item) => <option value={item.value} key={item.value}>{item.title}</option>)
                                }
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md="6" sm="12">
                        <Form.Label>Shop Amount:</Form.Label>
                        <Form.Control type="number" value={transaction.shopAmountValue} onChange={e => this.setState((prevState) => { return { transaction: { ...prevState.transaction, shopAmountValue: Number(e.target.value) } } })} />
                    </Col>
                    <Col md="6" sm="12">
                        <Form.Label>Shop Currency:</Form.Label>
                        <Form.Select aria-label=">Select source currency" value={transaction.shopCurrencyId}
                            onChange={e => this.setState((prevState) => { return { transaction: { ...prevState.transaction, shopCurrencyId: e.target.value } } })}>
                            {
                                currencies.map((item) => <option value={item.value} key={item.value}>{item.title}</option>)
                            }
                        </Form.Select>
                    </Col>
                </Row>
                <Row>
                    <Col md="6" sm="12">
                        <Form.Label>Description:</Form.Label>
                        <Form.Control as="textarea" rows={3} placeholder="Enter description"
                            value={transaction.description}
                            onChange={e => this.setState((prevState) => { return { transaction: { ...prevState.transaction, description: e.target.value } } })} />
                    </Col>
                    <Col md="6" sm="12">
                        <Form.Group controlId="createShop.title">
                            <Form.Label>Transaction Date:</Form.Label>
                                <DatePicker selected={transaction.transactionAt}
                                    onChange={(date: Date) => this.setState((prevState) => { return { transaction: { ...prevState.transaction, transactionAt: date } } })}
                                    className="form-control"
                                />
                            </Form.Group>
                    </Col>
                </Row>
                <div className="mt-2">
                    {
                        isLoading ? <Button variant="success" disabled><Spinner animation="border" size="sm" /></Button> :
                            <Button variant="success" type="submit">
                                {this.props.sourceTransactionId !== null && this.props.sourceTransactionId !== undefined ? 'Save Changes' : 'Save'}
                            </Button>
                    }
                    <NavLink to={`/inventory-source-transaction/?id=${this.props.sourceId}`}><Button variant="outline-info" className="m-2">Transaction List</Button></NavLink>
                </div>
            </Form>
        </div>
    }
}