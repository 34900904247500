import React, { forwardRef, RefObject } from "react";
import { Calendar } from "react-bootstrap-icons";
import { Col, Form, Row } from "react-bootstrap";
import moment from 'moment';
import OrderTypeDisplayBadge from "../Enums/OrderTypeEnum";
import { orderTypeDisplayCaption, OrderTypeEnum } from "../Enums/OrderTypeEnum";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import DatePicker from "react-datepicker";
import InputGroupView from "../../inventory/InventoryPlans/InputGroupView/InputGroupView";
import OrderProductStatusBadge, { OrderProductStatusDisplayCaption, OrderProductStatusEnum } from "../Enums/OrderProductStatusEnum";
import { GridAction, GridColumn, GridColumnTypes } from "../../Grid/Grid.models";
import { BuyOrderListItem } from "../Orders/OrdersList/OrdersList.models";
import OrderReviewRequest from "../OrderReviewRequest/OrderReviewRequest";
import OrdersService from "../../../services/orders/OrdersService";
import { SelectionView } from "../../../services/common/Models";
import IState, { IBuyOrderItemsFilters } from "./OrderItemsReport.models";
import DynamicGrid from "../../shared/DynamicGrid/DynamicGrid";
import PeriodDatePicker from "../../shared/PeriodDatePicker/PeriodDatePicker";

export default class OrderItemsReport extends React.PureComponent<{}, IState> {
    dynamicGrid: RefObject<DynamicGrid<IBuyOrderItemsFilters>>;
    ordersService: OrdersService;
    constructor(props: any) {
        super(props);
        this.dynamicGrid = React.createRef();
        this.ordersService = new OrdersService();
        this.state = {
            products: []
        };
    }

    componentDidMount() {
        this.ordersService.getProductsTitleValues().then((response) => {
            this.setState({ products: response })
        })
    }


    informationView(informations: string[]) {
        return (
            informations.map((information, key) => {
                return (<span key={key}>
                    {information}
                    <br />
                </span>)
            })
        )
    }


    renderCustomFilterView = (key: string, value: string) => {
        switch (key) {
            case 'status':
                return OrderProductStatusDisplayCaption(Number(value))
            case 'orderType':
                return orderTypeDisplayCaption(Number(value))
            case 'productId':
                return this.state.products.filter(item => item.value === value)[0].title
            case 'deliveryDate':
            case 'fromDate':
            case 'toDate':
                return moment(value).format('YYYY-MM-DD')
            default: return value
        }
    }

    render(): React.ReactNode {
        let gridColumns: GridColumn[] = [
            { title: 'Title', key: 'title' },
            { title: 'Order Number', key: 'orderNumber' },
            { title: 'Order Date', key: 'orderDate', type: GridColumnTypes.Date },
            { title: 'Seller', key: 'sellerShopTitle' },
            { title: 'Status', key: 'statusBadge' },
            { title: 'Type', key: 'orderType' },
            { title: 'Member', key: 'member' },
            { title: 'Information', key: 'informations' },
        ];

        let gridActions: GridAction[] = [
            {
                render: (row: BuyOrderListItem) =>
                    <OrderReviewRequest orderItemId={row.productId}
                        reviewRequestSupportTicketId={row.reviewRequestSupportTicketId}
                        onReported={this.dynamicGrid.current.fetch}
                        status={row.status as OrderProductStatusEnum} />
            },
        ];

        return <DynamicGrid<IBuyOrderItemsFilters> ref={this.dynamicGrid}
            columns={gridColumns}
            actions={gridActions}
            onFetch={this.ordersService.getBuyOrderItems}
            onExportClick={this.ordersService.downloadExcelBuyOrderItems}
            refactorFilterDisplay={this.renderCustomFilterView}
            filtersForm={this.filtersView}
            refactorRow={(row) => {
                return {
                    ...row,
                    statusBadge: OrderProductStatusBadge(row.status),
                    orderType: OrderTypeDisplayBadge(row.orderType),
                    informations: this.informationView(row.informations)
                }
            }} />
    }


    filtersView = (filters: IBuyOrderItemsFilters, onChange: (newFilters: IBuyOrderItemsFilters) => void): JSX.Element => {
        return <>
            <PeriodDatePicker
                fromDate={filters.fromDate}
                toDate={filters.toDate}
                onChangeFromDate={(date) => onChange({ ...filters, fromDate: date })}
                onChangeToDate={(date) => onChange({ ...filters, toDate: date })}
            />
            <Row className="mt-2">
                <Col md="6" sm="12">
                    <Form.Group>
                        <Form.Label>Status</Form.Label>
                        <Form.Select aria-label=">Select status"
                            id="status" value={filters.status}
                            onChange={(e) => onChange({ ...filters, status: e.target.value })}>
                            {Object.keys(OrderProductStatusEnum).map(key => typeof OrderProductStatusEnum[key as any] !== 'number' && (
                                <option value={key} key={key}>{OrderProductStatusEnum[key as any]}</option>
                            ))}
                            <option value="" selected>All</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col md="6" sm="12">
                    <Form.Group>
                        <Form.Label>Type</Form.Label>
                        <Form.Select aria-label=">Select type"
                            id="type" value={filters.orderType}
                            onChange={(e) => onChange({ ...filters, orderType: e.target.value })}>
                            {Object.keys(OrderTypeEnum).map(key => typeof OrderTypeEnum[key as any] !== 'number' && (
                                <option value={key} key={key}>{OrderTypeEnum[key as any]}</option>
                            ))}
                            <option value="" selected>All</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>
            <hr />
            <Row className="mt-2">
                <Col md="6" sm="12">
                    <Form.Label>Delivery Date</Form.Label>
                    <DatePicker selected={filters.deliveryDate}
                        showTimeSelect
                        customInput={<CustomInput />}
                        onChange={(date: Date) => onChange({ ...filters, deliveryDate: date })}
                        className="form-control"
                    />
                </Col>
                <Col md="6" sm="12">
                    <Form.Label>Product</Form.Label>
                    <ReactSearchAutocomplete<SelectionView>
                        items={this.state.products}
                        fuseOptions={{ keys: ["title"] }}
                        resultStringKeyName="title"
                        inputSearchString={filters.productId && this.state.products.filter(product => product.value === filters.productId)[0].title}
                        showClear={false}
                        showIcon={false}
                        styling={{ borderRadius: "3px", border: "1px solid #ced4da", boxShadow: "unset", placeholderColor: "#000", height: '35px', fontSize: "15px", fontFamily: "Roboto-Regular" }}
                        onSelect={(item) => onChange({ ...filters, productId: item.value })}
                        autoFocus={false}
                        showItemsOnFocus={false}
                        showNoResults={false}
                    />
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md="6" sm="12">
                    <Form.Group>
                        <Form.Label>Title</Form.Label>
                        <Form.Control value={filters.title} onChange={e => onChange({ ...filters, title: e.target.value })} type="text"
                            placeholder="Enter title" />
                    </Form.Group>
                </Col>
                <Col md="6" sm="12">
                    <Form.Group>
                        <Form.Label>Order Number</Form.Label>
                        <Form.Control value={filters.orderNumber} onChange={(e) => {
                            onChange({ ...filters, orderNumber: e.target.value })
                        }} placeholder="Enter Order Number" />
                    </Form.Group>
                </Col>
                <Col md="12" sm="12" className="mt-2">
                    <Form.Group>
                        <Form.Label>
                            <span> Information </span>
                        </Form.Label>
                        <Form.Control value={filters.information} onChange={e => onChange({ ...filters, information: e.target.value })} type="text" name="title" placeholder="Enter information" />
                    </Form.Group>
                </Col>
            </Row>
        </>
    }
}

const CustomInput = forwardRef((props: any, ref: any) => {
    return (
        <InputGroupView textColor="muted" renderMarkView={<Calendar />} onClick={props.onClick}>
            <Form.Control aria-describedby="basic-addon1" {...props} ref={ref} />
        </InputGroupView>
    )
});