import React from "react";
import { Button, Col, Row, Spinner, Table } from "react-bootstrap";
import InventoryCategoryService from "../../../services/inventory/InventoryCategoryService";
import RequestCreateCategoryModal from "../../categories/RequestCreateCategory/RequestCreateCategoryModal";
import CardView from "../../shared/Card/CardView";
import { InventoryShopBasePlanStatusEnum } from "../Enums/InventoryShopBasePlanStatusEnum";
import ProductCategoryService from "../../../services/category/ProductCategoryService";
import AllCategoriesView from "./AllCategoriesView/AllCategoriesView";
import CategoriesListView from "./CategoriesListView/CategoriesListView";
import { toast } from "react-toastify";
import IState from "./EditCategoryInventoryProductPlan.models";

export default class EditCategoryInventoryProductPlan extends React.PureComponent<{}, IState> {
    inventoryCategoryService: InventoryCategoryService
    productCategoryService: ProductCategoryService
    constructor(props: any) {
        super(props)
        this.inventoryCategoryService = new InventoryCategoryService()
        this.productCategoryService = new ProductCategoryService()
        this.state = { showModal: false, categories: [], activeCategories: [], closeCategories: [], isEditMode: false, isLoading: false, pendingRequest: false }
    }

    componentDidMount() { this.init() }

    init = () => {
        this.setState({ isLoading: true })
        this.inventoryCategoryService.getAll().then((response) => {
            this.setState({ categories: response, isLoading: false, isEditMode: false }, () => { this.setTableData() })
        })
    }

    setTableData = () => {
        this.setState({
            activeCategories: this.state.categories.filter(item => item.status === InventoryShopBasePlanStatusEnum.Open),
            closeCategories: this.state.categories.filter(item => item.status === InventoryShopBasePlanStatusEnum.Closed),
        })
    }

    toggleStatus = (row: any, isOpen: boolean) => {
        let tempCloseCategories = this.state.closeCategories
        let tempActiveCategories = this.state.activeCategories
        let index;

        if (isOpen) {
            index = tempCloseCategories.map(category => { return category.id }).indexOf(row.id)
            tempCloseCategories.splice(index, 1)
            tempActiveCategories.push(row)
        } else {
            index = tempActiveCategories.map(category => { return category.id }).indexOf(row.id)
            tempActiveCategories.splice(index, 1)
            tempCloseCategories.push(row)
        }

        this.setState({ activeCategories: tempActiveCategories, closeCategories: tempCloseCategories })
        this.forceUpdate()
    }

    cancelEdit = () => {
        this.setState({ isEditMode: false })
        this.setTableData()
    }

    saveChanges = () => {
        var sellerInventoryProductCategoryIds: string[] = this.state.activeCategories.filter(item => item.status != null).map(category => { return category.id })

        this.inventoryCategoryService.update(sellerInventoryProductCategoryIds).then(() => {
            toast.success('Your changes updated successfully')
            this.init()
        })
    }

    render(): React.ReactNode {
        return <>
            <Row>
                <Col md="6" sm="12">
                    <h3>Edit Inventory Categories</h3>
                </Col>
                <Col md="6" sm="12" className="text-end">
                    <Button variant="outline-success" size="sm" className="m-1" onClick={() => this.setState({ showModal: true })}>Request Create Category</Button>
                </Col>
            </Row>
            {this.state.isLoading ? <div className="text-center"><Spinner animation="border" /></div> :
                <Row className="pt-4">
                    <Col md="6" sm="12">
                        <CardView card={{ header: 'Active Category' }} renderHeader={(<>
                            {
                                this.state.isEditMode ?
                                    <div>
                                        {this.state.pendingRequest ? <Button style={{ float: 'right' }} variant="success" size="sm" disabled><Spinner size="sm" animation="border" /></Button>
                                            : <Button style={{ float: 'right' }} variant="success" size="sm" onClick={() => this.saveChanges()}>Save Changes</Button>}
                                        <Button style={{ float: 'right' }} variant="danger" size="sm" className="mr-1" onClick={() => this.cancelEdit()}>Cancel</Button>
                                    </div> : <Button style={{ float: 'right' }} variant="warning" size="sm" onClick={() => this.setState({ isEditMode: true })}>Edit</Button>
                            }
                        </>)}>
                            <CategoriesListView action={{ onClick: (row) => this.toggleStatus(row, false), caption: 'Closed', variant: 'danger' }} categories={this.state.activeCategories} isEditMode={this.state.isEditMode} />
                        </CardView>
                    </Col>
                    <Col md="6" sm="12">
                        {this.state.categories.length > 0 &&
                            <>{
                                !this.state.isEditMode ?
                                    <AllCategoriesView reload={this.init} categories={this.state.categories.filter(item => item.status !== InventoryShopBasePlanStatusEnum.Open)} />
                                    : <CardView card={{ header: 'Closeds' }}>
                                        <CategoriesListView action={{ onClick: (row) => this.toggleStatus(row, true), caption: 'Open', variant: 'success' }} categories={this.state.closeCategories} isEditMode={this.state.isEditMode} />
                                    </CardView>
                            }</>
                        }
                    </Col>
                </Row>}
            {this.state.showModal && <RequestCreateCategoryModal closeModal={() => this.setState({ showModal: false })} />}
        </>
    }
}