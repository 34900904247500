import { Route } from "react-router-dom";
import DefaultLayout from "../../components/layout/DefaultLayout";
import APIRoute from "../api/APIRoute";

export default function ApiRoutes() {
    return (
        <>
            <Route element={<DefaultLayout />}>
                <Route path="/rest-config" element={<APIRoute />}/>
            </Route>
        </>
    )
}