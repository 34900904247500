import Moment from 'moment';

export default function HubridMoment(moment: string) {
    if (moment === null || moment === undefined)
        return null;
    else
        return <span title={Moment(moment).utc().format('YYYY-MM-DD HH:mm')}>{Moment(moment).local().format('YYYY-MM-DD HH:mm')}</span>;
}

export function ToLocaleDate(date: Date) {
    return date ? date.toISOString() : undefined
 }