import { FinancialAccountStatusEnum } from "../../components/financials/Enums/FinancialAccountStatusEnum";
import { GridResult } from "../../components/Grid/Grid.models";
import { PaginationQuery } from "../../components/Grid/Pagination/Pagination.models";
import { mapToDataOnly } from "../common/HttpUtility"
import { SelectionView } from "../common/Models";
import { FinancialAccountApiFactory, FinancialAccountTypeApiFactory } from "../../generated-api/financials";

export default class FinancialManagementService {
    getPaged(filters: IExternalWalletsPaginationFilters, paginationQuery: PaginationQuery): Promise<GridResult<IFinancialAccountRowDTO>> {
        return mapToDataOnly<GridResult<IFinancialAccountRowDTO>>(FinancialAccountApiFactory().apiV1FinancialAccountGet(paginationQuery.pageskip - 1, paginationQuery.pagesize))
    }

    getById(financialAccountId: string): Promise<IFinancialAccountRowDTO> {
        return mapToDataOnly<IFinancialAccountRowDTO>(FinancialAccountApiFactory().apiV1FinancialAccountFinancialIdGet(financialAccountId));
    }

    getTypesTitleValues(): Promise<SelectionView[]> {
        return mapToDataOnly<SelectionView[]>(FinancialAccountTypeApiFactory().apiV1FinancialAccountTypeGet());
    }

    create(dto: ICreateFinancialAccountDTO): Promise<{}> {
        return mapToDataOnly<{}>(FinancialAccountApiFactory().apiV1FinancialAccountPost(dto));
    }

    update(financialAccountId: string, dto: IUpdateFinancialAccountDTO): Promise<{}> {
        return mapToDataOnly<{}>(FinancialAccountApiFactory().apiV1FinancialAccountIdPut(financialAccountId, dto));
    }

    delete(financialAccountId: string): Promise<{}> {
        return mapToDataOnly<{}>(FinancialAccountApiFactory().apiV1FinancialAccountFinancialIdDelete(financialAccountId));
    }

    getVerifyAmount(financialAccountId: string): Promise<{ amount: number, expireAt: string, cashPaymentAccountNumber: string }> {
        return mapToDataOnly<{ amount: number, expireAt: string, cashPaymentAccountNumber: string }>
            (FinancialAccountApiFactory().apiV1FinancialAccountGenerateVerifyAmountFinancialIdGet(financialAccountId))
    }

    checkPayment(transactionNumber: string, financialAccountId: string): Promise<any> {
        let body = {
            financialAccountId,
            transactionNumber,
        }

        return mapToDataOnly<any>(FinancialAccountApiFactory().apiV1FinancialAccountCheckVerifiedPut(body));
    }
}

export interface IExternalWalletsPaginationFilters { }

interface IFinancialAccountRowDTO {
    id: string;
    createAt: string;
    status: FinancialAccountStatusEnum;
    accountIdentity: string;
    nickName: string;
    typeTitle: string;
}

interface ICreateFinancialAccountDTO {
    nickName: string,
    financialAccountTypeId: string,
    accountIdentity: string
}

interface IUpdateFinancialAccountDTO {
    nickName: string
}