import React from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import InventoryShopCollectionService from "../../../../services/inventory/InventoryShopCollectionService";
import IState, { IProps } from "./EditDeficitModal.models";

export class EditDeficitModal extends React.PureComponent<IProps, IState> {
    inventoryShopCollectionService: InventoryShopCollectionService
    constructor(props: IProps) {
        super(props)
        this.state = { count: 0, isLoading: false }
        this.inventoryShopCollectionService = new InventoryShopCollectionService()
    }

    componentDidMount() {
        this.setState({ count: this.props.count })
    }

    update = () => {
        this.setState({ isLoading: true })
        this.inventoryShopCollectionService.updateDeficitCount({ deficitCount: this.state.count, shopCollectionId: this.props.collectionId }).then(() => {
            toast.success('Collection deficit has been updated successfully')
            this.setState({ isLoading: false })
            this.props.reload && this.props.reload()
            this.props.closeModal()
        })
    }

    render(): React.ReactNode {
        return (
            <Modal size="sm"
                show={true}
                onHide={this.props.closeModal}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Deficit</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="sourceAmountValue">
                        <Form.Label>New Deficit Count:</Form.Label>
                        <Form.Control type="number" value={this.state.count} onChange={e => this.setState({ count: Number(e.target.value) })} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    {
                        this.state.isLoading ? <Button size="sm" variant="success" disabled><Spinner animation="border" size="sm" /></Button> :
                            <Button variant="success" size="sm" type="submit" onClick={this.update}>Update</Button>
                    }
                </Modal.Footer>
            </Modal>
        )
    }
}