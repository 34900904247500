import React, { RefObject } from "react"
import AdminWithdrawalsService from "../../../../services/admin/financials/AdminWithdrawalsService"
import cashOutStatusDisplayBadge, { CashOutStatusEnum } from "../../../financials/Enums/CashOutStatusEnum"
import { GridAction, GridColumn, GridColumnTypes } from "../../../Grid/Grid.models"
import IState, { IAdminWithdrawalsFilters } from "./AdminWithdrawals.models"
import AdminWithdrawDetails from "./AdminWithdrawDetails/AdminWithdrawDetails"
import DynamicGrid from "../../../shared/DynamicGrid/DynamicGrid"
import BlockchainVerificationImg from '../../../../assets/images/content/blockchain-verification.png'

export default class AdminWithdrawals extends React.PureComponent<{ status?: CashOutStatusEnum, unreviewedOnly?: boolean }, IState> {
    adminWithdrawalsService: AdminWithdrawalsService
    dynamicGrid: RefObject<DynamicGrid<IAdminWithdrawalsFilters>>;
    constructor(props: any) {
        super(props);
        this.dynamicGrid = React.createRef()
        this.adminWithdrawalsService = new AdminWithdrawalsService()
        this.state = {}
    }

    renderCustomFilterView = (key: string, value: string) => {
        switch (key) {
            case 'status':
                return cashOutStatusDisplayBadge(Number(value))
            default: return value
        }
    }

    markAsReview = (id: string) => {
        this.adminWithdrawalsService.review(id).then(() => {
            this.dynamicGrid.current?.fetch();
        })
    }

    verificationDisplay = (isVerification: boolean) => {
        return isVerification && <div className="text-center"><img src={BlockchainVerificationImg}/></div>
    }

    render(): React.ReactNode {
        let gridColumns: GridColumn[] = [
            { title: 'Shop', key: 'shopTitle' },
            { title: 'Amount', key: 'amount', type: GridColumnTypes.Amount },
            { title: 'Request At', key: 'requestAt', type: GridColumnTypes.Date },
            { title: 'Send At', key: 'handleAt', type: GridColumnTypes.Date },
            { title: 'Status', key: 'statusBadge' },
            { title: 'Blockchain Verification', key: 'isBlockchainVerification', align: 'center'},
        ]

        var gridActions: GridAction[] = [
            { caption: 'Details', variant: 'outline-info', onClick: (row) => this.setState({ showDetailsId: row.id }), skipSpinner: true }
        ]

        if (this.props.unreviewedOnly === true)
            gridActions.push({ caption: 'Mark as review', variant: 'outline-primary', onClick: (row) => this.markAsReview(row.id), skipSpinner: true })

        return (<>
            <AdminWithdrawDetails id={this.state.showDetailsId} onClose={() => this.setState({ showDetailsId: undefined })}
                onSubmit={() => {
                    this.dynamicGrid.current?.fetch();
                }} />

            <DynamicGrid<IAdminWithdrawalsFilters> ref={this.dynamicGrid}
                columns={gridColumns}
                onFetch={this.adminWithdrawalsService.get}
                hideFilterBar
                defaultFilters={{ status: this.props.status, unreviewedOnly: this.props.unreviewedOnly }}
                actions={gridActions}
                refactorFilterDisplay={this.renderCustomFilterView}
                refactorRow={(row) => {
                    return { ...row, statusBadge: cashOutStatusDisplayBadge(row.status), isBlockchainVerification: this.verificationDisplay(row.isBlockchainVerification) }
                }} />
        </>)
    }

    // filtersView = () => {
    //     return <>
    //         <FilterBar onApply={this.applyFilters}
    //             onOpened={() => this.setState({ editFilters: { ...this.state.filters } })}
    //             filters={this.state.filters}
    //             onRemoveFilter={this.removeFilter}>
    //             <Form.Group>
    //                 <Form.Label>Financial Account</Form.Label>
    //                 <Form.Control value={this.state.editFilters.shopId} onChange={(e) => {
    //                     this.setState({ editFilters: { ...this.state.editFilters, shopId: e.target.value } })
    //                 }} type="text" placeholder="Enter Financial Account" />
    //             </Form.Group>
    //         </FilterBar>
    //     </>
    // }
}