import React, { RefObject } from "react"
import AdminUserService from "../../../services/admin/users/AdminUserService"
import IUserState, { IProps, IUserFilter } from "./AdminUsersList.models"
import DynamicGrid from "../../shared/DynamicGrid/DynamicGrid"
import { GridAction, GridColumn, GridColumnTypes } from "../../Grid/Grid.models"
import userStatusDisplayBadge from "../../identity/Enums/UserStatusEnum"
import { Col, Form, Row } from "react-bootstrap"
import AdminUserRoleAssignmentModal from "./EditUserRole/AdminUserRoleAssignmentModal"

export default class AdminUsersList extends React.Component<IProps, IUserState> {
    dynamicGrid: RefObject<DynamicGrid<IUserFilter>>
    userService: AdminUserService

    constructor(props: any) {
        super(props)
        this.state = { isOpenRoleAssignmentModal: false }
        this.dynamicGrid = React.createRef()
        this.userService = new AdminUserService()
    }

    render(): React.ReactNode {
        let gridColumns: GridColumn[] = [
            { title: "Display Name", key: "displayName" },
            { title: "Email", key: "email" },
            { title: "Status", key: "status" },
            { title: "Register At", key: "registerDate", type: GridColumnTypes.DateOnly },
        ]

        let gridActions: GridAction[] = [
            {
                caption: "Role Assignment",
                variant: "outline-info",
                skipSpinner: true,
                onClick: (row) => this.setState({ selectedUserId: row.id, isOpenRoleAssignmentModal: true})
            }
        ]

        let { selectedUserId, isOpenRoleAssignmentModal } = this.state

        return (
            <>
                <DynamicGrid<IUserFilter>
                    ref={this.dynamicGrid}
                    columns={gridColumns}
                    onFetch={this.userService.getPaged}
                    actions={gridActions}
                    defaultFilters={{ id: this.props.id }}
                    hiddenFilterkeys={["id"]}
                    filtersForm={this.filtersView}
                    refactorRow={(row) => {
                        return { ...row, status: userStatusDisplayBadge(row.status) }
                    }}
                />
                {isOpenRoleAssignmentModal && <AdminUserRoleAssignmentModal userId={selectedUserId} onClose={() => this.setState({ isOpenRoleAssignmentModal: false, selectedUserId: undefined })} />}
            </>
        )
    }

    filtersView = (filters: IUserFilter, onChange: (newFilters: IUserFilter) => void): JSX.Element => {
        return <>
            <Row className="mt-2">
                <Col md="12" sm="12">
                    <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Name"
                            value={filters.name}
                            onChange={(e) => onChange({ ...filters, name: e.target.value })} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Email"
                            value={filters.email}
                            onChange={(e) => onChange({ ...filters, email: e.target.value })} />
                    </Form.Group>
                </Col>
            </Row>
        </>
    }
}