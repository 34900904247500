import React, { RefObject } from "react"
import { Button, Col, Row, Spinner } from "react-bootstrap"
import IState, { IProps } from "./AdminWalletOverview.models"
import AdminWalletsServices from "../../../../services/admin/financials/AdminWalletsServices"
import CardView from "../../../shared/Card/CardView"
import FormatMoney from "../../../utilities/FormatMoney"
import AdminWalletTransferFund from "../AdminWallets/Transfer/AdminWalletTransferFund"
import AdminExchangeModal from "../AdminWallets/Exchange/AdminExchangeModal"
import AdminCreateGlobalWalletModal from "./Create/AdminCreateGlobalWalletModal"
import DynamicGrid from "../../../shared/DynamicGrid/DynamicGrid"
import { GridAction, GridColumn, GridColumnTypes } from "../../../Grid/Grid.models"

export default class AdminWalletOverview extends React.Component<IProps, IState> {
  walletsServices: AdminWalletsServices
  dynamicGrid: RefObject<DynamicGrid<{}>>;

  constructor(props: any) {
    super(props)

    this.dynamicGrid = React.createRef()
    this.walletsServices = new AdminWalletsServices()
    this.state = { isLoading: false, titleValues: [], walletsStatistics: { average: 0, negativeAverage: 0, positiveAverage: 0 }, isOpenCreateGlobalWalletModal: false }
  }

  componentDidMount(): void {
    this.loadData()
  }

  loadData = () => {
    this.setState({ isLoading: true })

    this.walletsServices.getOverviewStatistic().then((response) => {
      this.setState({ walletsStatistics: response })
    }).finally(() => this.setState({ isLoading: false }))
  }

  render(): React.ReactNode {
    let { isLoading } = this.state

    let gridColumns: GridColumn[] = [
      { title: 'Title', key: 'globalKey' },
      { title: "Currency", key: "currencyTitle" },
      { title: "Balance", key: "balance", type: GridColumnTypes.Amount },
    ]

    var gridActions: GridAction[] = [
      { caption: 'Transactions', variant: 'outline-success', skipSpinner: true, generateLink: (row) => `/admin/walletTransactions/?id=${row.id}` },
      { caption: 'Transfer', variant: 'outline-primary', skipSpinner: true, onClick: (row) => this.setState({ transferWalletId: row.id }) },
      { caption: 'Exchange', variant: 'outline-info', skipSpinner: true, onClick: (row) => this.setState({ exchangeWalletId: row.id }) },
    ]

    return (
      isLoading ? <div className="text-center mt-5"><Spinner animation="border" /></div>
        : <>
          <span className="title">Wallets Statistics</span>
          <hr />
          {this.walletsStatisticsView()}
          <span className="title">Global Wallet Balance</span>
          <hr />
          <Button variant="success" className="mb-3" onClick={() => this.setState({ isOpenCreateGlobalWalletModal: true })}>Add</Button>
          <Row className="mb-4">
            <Col className="mb-2" md="12" sm="12">
              <DynamicGrid<{}>
                ref={this.dynamicGrid}
                columns={gridColumns}
                actions={gridActions}
                hideFilterBar
                onFetch={this.walletsServices.getPaged}
                size="sm"
                defaultFilters={{ onlyGlobalWallet: true }}
              />
            </Col>
          </Row>
          {this.state.transferWalletId !== undefined &&
            <AdminWalletTransferFund reload={this.loadData} walletId={this.state.transferWalletId} onClose={() => this.setState({ transferWalletId: undefined })} />}

          {this.state.exchangeWalletId !== undefined &&
            <AdminExchangeModal reload={this.loadData} walletId={this.state.exchangeWalletId} onClose={() => this.setState({ exchangeWalletId: undefined })} />}
          {
            this.state.isOpenCreateGlobalWalletModal &&
            <AdminCreateGlobalWalletModal onSubmit={() => this.dynamicGrid.current?.fetch()} onClose={() => this.setState({ isOpenCreateGlobalWalletModal: false })} />
          }
        </>
    )
  }

  walletsStatisticsView = () => {
    let { walletsStatistics } = this.state

    return <Row className="mb-4">
      <Col className="mb-2" md="4" sm="12">
        <CardView small card={{ header: "Negative Wallets Sum", bg: 'light' }}>
          <Row>
            <Col md="6" sm="12" className="p-0 d-flex align-items-center">
              <span>Balance:</span>
              <Button className="danger" variant="link" onClick={this.props.onClickedWalletsStatistics}>
                <b className="ms-2">{FormatMoney(walletsStatistics.negativeAverage)}</b>
              </Button>
            </Col>
          </Row>
        </CardView>
      </Col>
      <Col className="mb-2" md="4" sm="12">
        <CardView small card={{ header: "Positive Wallets Sum", bg: 'light' }}>
          <Row>
            <Col md="6" sm="12" className="p-0 d-flex align-items-center">
              <span>Balance:</span>
              <Button className="green" variant="link" onClick={this.props.onClickedWalletsStatistics}>
                <b className="ms-2">{FormatMoney(walletsStatistics.positiveAverage)}</b>
              </Button>
            </Col>
          </Row>
        </CardView>
      </Col>
      <Col className="mb-2" md="4" sm="12">
        <CardView small card={{ header: "Wallets Sum", bg: 'light' }}>
          <Row>
            <Col md="6" sm="12" className="p-0 d-flex align-items-center">
              <span>Balance:</span>
              <Button className={walletsStatistics.average > 0 ? "green" : "danger"} variant="link" onClick={this.props.onClickedWalletsStatistics}>
                <b className="ms-2">{FormatMoney(walletsStatistics.average)}</b>
              </Button>
            </Col>
          </Row>
        </CardView>
      </Col>
    </Row>
  }
}