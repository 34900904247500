import React, { RefObject } from "react";
import { Button, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import InventorySourceService from "../../../../services/inventory/InventorySourceService";
import Grid from "../../../Grid/Grid";
import { GridAction, GridColumn, GridColumnTypes } from "../../../Grid/Grid.models";
import { PaginationQuery } from "../../../Grid/Pagination/Pagination.models";
import IState, { IProps } from "./InventorySourceList.models";

const CreateInventorySourceModal = React.lazy(() => import("../CreateInventorySource/CreateInventorySourceModal"))

export default class InventorySourceList extends React.PureComponent<IProps, IState> {
    grid: RefObject<Grid>
    inventorySourceService: InventorySourceService
    constructor(props: any) {
        super(props)
        this.grid = React.createRef()
        this.state = { paginationQuery: { pageskip: 1, pagesize: 10 }, showModal: false }
        this.inventorySourceService = new InventorySourceService()
    }

    componentDidMount = () => {
        this.reloadGrid();
    }

    reloadGrid() {
        this.inventorySourceService.getPaged({ ...this.state.paginationQuery }).then((gridResult) => {
            this.grid.current?.load(gridResult.rows, gridResult.totalRows);
        })
    }

    changePage = (query: PaginationQuery) => {
        this.setState({ paginationQuery: query }, () => this.reloadGrid());
    }

    openCreateSourceModal = (id?: string) => {
        this.setState({ showModal: true, sourceId: id })
    }

    removeSource = (id: string) => {
        if (window.confirm('Are you sure ? This source will be deleted permanently')) {
            this.inventorySourceService.delete(id).then(() => {
                toast.success('The source deleted successfully')
                this.reloadGrid()
            })
        }

        this.grid.current?.resetSpinner()
    }

    render(): React.ReactNode {
        let gridColumns: GridColumn[] = [
            { title: 'Title', key: 'title' },
            { title: 'Description', key: 'description' },
            { title: 'Base Balance', key: 'baseCurrencyBalance', type: GridColumnTypes.Amount },
        ];

        let gridActions: GridAction[] = [
            {
                caption: 'Details', variant: 'info', generateLink: (row) => `/inventory-source-transaction/?id=${row.id}`
            },
            {
                caption: 'Edit', variant: 'warning', onClick: (row) => {
                    this.openCreateSourceModal(row.id)
                    this.grid.current?.resetSpinner()
                }
            },
            {
                caption: 'Delete', variant: 'danger', onClick: (row) => { this.removeSource(row.id) }
            },
        ];

        return (<div>
            <Button className="m-1" variant="success" size="sm" onClick={() => this.openCreateSourceModal()}>Add</Button>
            <Grid ref={this.grid} columns={gridColumns} actions={gridActions} onChangePage={this.changePage}></Grid>
            <React.Suspense fallback={<Spinner animation="grow"></Spinner>}>
                {this.state.showModal && <CreateInventorySourceModal close={() => this.setState({ showModal: false, sourceId: null })} sourceId={this.state.sourceId} onSubmit={() => { this.reloadGrid() }} />}
            </React.Suspense>
        </div>)
    }
}