import { mapToDataOnly } from "../common/HttpUtility";
import { NotificationApiFactory } from "../../generated-api";

export default class NotificationService {
    getList(): Promise<{ notifications: NotificationView[] }> {
        return mapToDataOnly<{ notifications: NotificationView[] }>(NotificationApiFactory().marketPlaceNotificationGet(1));
    }

    seenAll(): Promise<any> {
        return mapToDataOnly<any>(NotificationApiFactory().marketPlaceNotificationSeenAllPut());
    }

    getUnseenCount(): Promise<number> {
        return mapToDataOnly<number>(NotificationApiFactory().marketPlaceNotificationUnseenCountGet());
    }
}

export class NotificationView {
    id: string
    createDate: string
    subject: string
    message: string
    seen: boolean
    channel: MessageChannelType
}

enum MessageChannelType {
    Sms,
    Telegram,
    Email,
    System
}