import React from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import IState, { IProps } from './ImportFromExcel.models'

export default class ImportFromExcel extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = { text: '' }
    }

    submit = () => {
        this.props.onPaste(this.state.text)
        this.props.closeModal()
    }

    render(): React.ReactNode {
        const { closeModal } = this.props
        return (
            <Modal
                show={true}
                onHide={closeModal}
                backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Import From Excel</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        this.props.colTitles.length == 1 ?
                            <p>Copy and paste {<span className='text-danger underline'>{this.props.colTitles[0]} column only</span>} from excel file</p>
                            : <p>Copy and paste {this.props.colTitles.map(title => <span className='text-danger'>{title}, </span>)} columns from excel file</p>
                    }
                    <Form.Control className="mb-3 mt-3" as="textarea" rows={3} placeholder="Copy(from excel) and paste here"
                        value={this.state.text} onChange={(e) => this.setState({ text: e.target.value })} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='success' onClick={this.submit}>Import</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}