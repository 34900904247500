import ShopAuthenticated from "../../components/shared/ShopAuthenticated/ShopAuthenticated";
import ShopManagement from "../../components/shops/ShopManagement/ShopManagement";
import { FULL_ACCESS } from "../../constants/ConstantsPermissions";

export default function ManagementRoute() {
    return <>
        <ShopAuthenticated showAccessDenied permission={FULL_ACCESS}>
            <ShopManagement/>
        </ShopAuthenticated>
    </>
}