import React, { RefObject } from "react";
import { Button, Modal, Spinner, Tab, Tabs, Toast } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PRO, SELLER } from "../../../constants/ConstantsPolicies";
import InventoryProductOverviewService, { IConfig } from "../../../services/inventory/InventoryProductOverviewService";
import OrdersService from "../../../services/orders/OrdersService";
import Deficits from "../../dashboard/Deficits/Deficits";
import { OrderStatusEnum } from "../../orders/OrderEnums";
import OrderReport from "../../orders/Orders/Report/OrderReport";
import SellOrders from "../../orders/Orders/sell-orders/SellOrders";
import PageTitle from "../../shared/PageTitle/PageTitle";
import ShopPolicies from "../../shared/policy/ShopPolicies/ShopPolicies";
import CreateInventory from "../CreateInventory/CreateInventory";
import InventoryList from "../InventoriesList/InventoryList";
import InventoryPlans from "../InventoryPlans/InventoryPlans";

export default class Sell extends React.Component<{ onClose: () => void, productId: string }, { config: IConfig }> {
    refShopPolicies: RefObject<ShopPolicies>
    inventoryProductOverviewService: InventoryProductOverviewService;
    ordersService: OrdersService;
    constructor(props: any) {
        super(props)
        this.state = { config: undefined }
        this.refShopPolicies = React.createRef();
        this.inventoryProductOverviewService = new InventoryProductOverviewService();
        this.ordersService = new OrdersService();
    }
    componentDidMount(): void {
        this.loadPlan();
    }

    loadPlan = () => {
        this.inventoryProductOverviewService.getOrderingPlan(this.props.productId).then((response) => {
            this.setState({ config: response })
        })
    }
    render(): React.ReactNode {
        if (this.state.config === undefined)
            return <Modal size="xl"
                className='ProductCatalogSellers'
                show={true}
                backdrop="static"
                onHide={this.props.onClose}>
                <Modal.Body>
                    <Spinner animation="grow" />
                </Modal.Body>
            </Modal>

        return <Modal size="xl"
            className='ProductCatalogSellers'
            show={true}
            backdrop="static"
            onHide={this.props.onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Sell - {this.state.config.systemDetails.productTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs className="mb-3" mountOnEnter={true} unmountOnExit={true}>
                    {
                        this.state.config.plansConfig &&
                        <Tab eventKey="add" title="Add">
                            <CreateInventory productId={this.props.productId} />
                        </Tab>
                    }
                    <Tab eventKey="plan" title="Plan">
                        <InventoryPlans productId={this.props.productId} onUpdated={this.loadPlan} />
                    </Tab>
                    <Tab eventKey="inventories" title="Inventories">
                        <InventoryList defaultFilter={{ productId: this.props.productId }} hideDontShowOnlyinLockRow />
                    </Tab>
                    <Tab eventKey="incoming" title="Incoming">
                        <Deficits productId={this.props.productId} />
                    </Tab>
                    <Tab eventKey="outgoing" title="Outgoing">
                        <SellOrders defaultFilters={{ status: OrderStatusEnum.Pending, productId: this.props.productId }} />
                    </Tab>
                </Tabs>
            </Modal.Body>
        </Modal>

    }
}