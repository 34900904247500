import React, { Component, RefObject } from "react";
import { Button, Col, Modal, ModalFooter, Row, Spinner } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import OrdersService, { SellerOrderDetailsDTO, SellerOrderDetailsProductDTO, SellerOrderProductDetailsRowDTO } from "../../../../../services/orders/OrdersService";
import Moment from 'moment';
import orderStatusDisplayBadge, { orderDetailsProductStatusDisplayBadge } from "../../../OrderEnums";
import Amount from "../../../../utilities/Amount/Amount";
import Grid from "../../../../Grid/Grid";
import { GridAction, GridColumn, GridColumnTypes, GridResult } from "../../../../Grid/Grid.models";
import { toast } from "react-toastify";
import OrderProductStatusBadge, { OrderProductStatusEnum } from "../../../Enums/OrderProductStatusEnum";

interface IProps {
    orderId: string,
    onClose: () => void
}

interface IState {
    details: SellerOrderDetailsDTO,
    pageNumber: number,
    productDetail: GridResult<SellerOrderProductDetailsRowDTO>,
    productDetailTitle: string
    isLoading: boolean
}

export default class SellOrderDetails extends Component<IProps, IState>{

    grid: RefObject<Grid>
    productGrid: RefObject<Grid>
    ordersService: OrdersService
    constructor(props: any) {
        super(props);
        this.ordersService = new OrdersService();
        this.grid = React.createRef();
        this.productGrid = React.createRef();
        this.state = {
            isLoading: true,
            details: { buyerId: '', buyerTitle: '', orderDate: new Date(), orderNumber: '', products: [], status: 0, totalAmount: 0, totalCashoutAmount: 0, totalRefund: 0, totalShare: 0 },
            pageNumber: 1,
            productDetail: { rows: [], totalRows: 0 },
            productDetailTitle: ''
        }
    }

    componentDidMount(): void {
        this.ordersService.getSellOrderDetails(this.props.orderId).then(response => {
            this.setState({ details: response, isLoading: false });

            let newRows = response.products.map((row) => {
                return { ...row, isComplete: orderDetailsProductStatusDisplayBadge(row.isComplete) }
            });
            this.grid.current?.load(newRows, newRows.length)
            this.grid.current?.resetSpinner();
        })
    }

    loadProductDetails = (row: any) => {
        this.ordersService.getSellOrderDetailProductInfo({ pagesize: 10, pageskip: 1, orderId: this.props.orderId, productId: row.productId }).then(response => {
            this.setState({ productDetail: response, productDetailTitle: row.title });

            let newRows = response.rows.map((row) => {
                return { ...row, statusBadge: OrderProductStatusBadge(row.status) }
            });
            this.productGrid.current?.load(newRows, response.totalRows)
            this.productGrid.current?.resetSpinner();
        })
    }


    changePage = (page: number, row?: any) => {
        this.setState({ pageNumber: page });

        if (page === 2)
            this.loadProductDetails(row);

        this.grid.current?.resetSpinner();
        this.productGrid.current?.resetSpinner();
    }

    render(): React.ReactNode {
        const { details, pageNumber } = this.state

        return this.state.isLoading ? <Modal size="xl"
            show={true}
            onHide={this.props.onClose}
            backdrop="static">
            <Modal.Body>
                <Spinner animation="grow" />
            </Modal.Body>
        </Modal>
            :
            <Modal size="xl"
                show={true}
                onHide={this.props.onClose}
                backdrop="static"
            >

                <Modal.Header closeButton>
                    <Modal.Title>Order Details ({details.orderNumber} - {details.buyerTitle})</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        (pageNumber === 1 ?
                            <>
                                {this.detailsView(details)}
                            </>
                            : <>

                            </>)
                    }
                    {details.products.length > 0 && this.gridView(pageNumber === 1)}
                    {this.productGridView(pageNumber === 2)}
                </Modal.Body>
                {
                    (pageNumber === 1 ?
                        <>
                        </>
                        : <>
                            <Modal.Footer>
                                <Button size="sm" variant="warning" onClick={() => this.changePage(1, null)}>Back To Order</Button>
                            </Modal.Footer>
                        </>)
                }
            </Modal>
    }

    detailsView = (details: SellerOrderDetailsDTO) => {
        const totalCashAmountAfterRefund = details.totalCashoutAmount - details.totalRefund;
        return <>
            <Row>
                {this.colView("Order Number", details.orderNumber)}
                {this.colView("Order Date", Moment(details.orderDate).utc().format('YYYY/MM/DD HH:mm'))}
            </Row>
            <Row>
                {this.colView("Status", orderStatusDisplayBadge(details.status))}
                {this.colView("Buyer Name", details.buyerTitle)}
            </Row>
            <Row>
                {this.colView("Total Amount", <Amount formatMoney={details.totalAmount > 0} value={details.totalAmount} />)}
                {this.colView("Total Share Amount", <Amount formatMoney={details.totalShare > 0} value={details.totalShare} />)}
            </Row>
            <Row>
                {this.colView("Total Cashout Amount", <Amount formatMoney={details.totalCashoutAmount > 0} value={details.totalCashoutAmount} />)}
                {this.colView("Total Refund Amount", <Amount formatMoney={details.totalRefund > 0} value={details.totalRefund} />)}
            </Row>
            <Row>
                {this.colView("Total Cashout Amount (After Refund)", <Amount formatMoney={totalCashAmountAfterRefund > 0} value={totalCashAmountAfterRefund} />)}
            </Row>
        </>
    }

    colView = (title: string, value: any) => {
        return <Col sm={12} md={6}>
            {title}: <b>{value}</b>
        </Col>
    }

    gridView = (show: boolean) => {
        let gridColumns: GridColumn[] = [
            { title: 'ProductTitle', key: 'title' },
            { title: 'Unit Price', key: 'unitPrice', type: GridColumnTypes.Amount },
            { title: 'Count', key: 'count' },
            { title: 'Amount', key: 'amount', type: GridColumnTypes.Amount },
            { title: 'Share', key: 'shareAmount', type: GridColumnTypes.Amount },
            { title: 'Cashout', key: 'cashoutAmount', type: GridColumnTypes.Amount },
            { title: 'Refunded', key: 'refundCount' },
            { title: 'Pending', key: 'pendingCount' },
            { title: 'Delivered', key: 'deliveredCount' },
            { title: 'Status', key: 'isComplete' },
        ]
        let gridActions: GridAction[] = [
            {
                caption: 'Details', variant: 'outline-info', onClick: (row) => this.changePage(2, row)
            }

        ]
        return (<div style={show ? {} : { display: "none" }}>
            <h3>Products</h3>
            <Grid ref={this.grid} columns={gridColumns} actions={gridActions}></Grid>
        </div>)
    }

    productGridView = (show: boolean) => {
        let gridColumns: GridColumn[] = [
            { title: 'Informations', key: 'informations' },
            { title: 'Delivery Date', key: 'deliveryAt', type: GridColumnTypes.Date },
            { title: 'Status', key: 'statusBadge' },
        ]
        let gridActions: GridAction[] = [
        ]

        return (<div style={show ? {} : { display: "none" }}>
            <h3>Product Details</h3>
            <Grid ref={this.productGrid} columns={gridColumns} actions={gridActions}></Grid>
        </div>)
    }
}