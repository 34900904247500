import React from "react"
import { Col, Row } from "react-bootstrap"
import HorizontalCardView from "../../../shared/Cards/HorizontalCardView/HorizontalCardView"
import RedirectView from "../../../shared/RedirectView/RedirectView"
import './CreditCards.scss'

interface ICardView {
    title: string,
    image: any,
    subTitle?: string
}
export default class CreditCards extends React.PureComponent<{ title?: string, data: ICardView[], redirectButton?: boolean }, {}> {
    render(): React.ReactNode {
        return (<div className="mt-5 mb-5 credit-card">
            <div className="font-bold fs-3 pb-3 text-black">{this.props.title}</div>
            <Row className="pa-md">
                {this.renderCardsView()}
                {
                    this.props.redirectButton && <Col md="12" lg="12" sm="12" className="mt-5">
                        <RedirectView title="See more Financial Services on API Gift just right now!" />
                    </Col>
                }
            </Row>
        </div>)
    }

    renderCardsView() {
        return <>
            {
                this.props.data.map((item, index) => {
                    return <Col md="12" lg="6" sm="12" className="pt-2 pb-2" key={index}>
                        <HorizontalCardView title={item.title} subTitle={item.subTitle} image={item.image} />
                    </Col>
                })
            }
        </>
    }
}