import { Route } from "react-router-dom";
import DefaultLayout from "../../components/layout/DefaultLayout";
import SecurityRoute from "../identity/SecurityRoute";
import UserPrivilegeRoute from "../identity/UserPrivilegeRoute";
import UserProfileRoute from "../identity/UserProfileRoute";

export function IdentityRoutes() {
    return (
        <>
            <Route element={<DefaultLayout />}>
                <Route path="/user-profile" element={<UserProfileRoute />}/>
                <Route path="/security" element={<SecurityRoute />}/>
                <Route path="/user-privilege" element={<UserPrivilegeRoute />}/>
            </Route>
        </>
    )
}