import React from "react";
import { Alert, Tab, Tabs } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import PageTitle from "../../shared/PageTitle/PageTitle";
import Articles from "../Articles/Articles";
import FAQ from "../FAQ/FAQ";
import SupportTicketList from "../SupportTickets/SupportTicketList";
import SupportTickets from "../SupportTickets/SupportTickets";
import Note from "../../shared/Note/Note";
import { PRO, SELLER } from "../../../constants/ConstantsPolicies";
import authenticationContext from "../../../contexts/AuthenticationContext/AuthenticationContext";

export default class HelpCenter extends React.Component<{ activeTab?: string | null, categoryId?: string }, { navigateToTab?: string }> {
    static contextType = authenticationContext;
    context!: React.ContextType<typeof authenticationContext>;
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    componentDidUpdate(prevProps: Readonly<{ activeTab: string | null; }>, prevState: Readonly<{ navigateToTab?: string; }>, snapshot?: any): void {
        if (prevProps.activeTab !== this.props.activeTab)
            this.setState({ navigateToTab: undefined })
    }

    handleSelect = (key) => this.setState({ navigateToTab: key });

    render(): React.ReactNode {
        return <div className="HelpCenter">
            <PageTitle title="Help Center" />
            <Note>
                We will help you through FAQ, articles and finally direct message with us.
            </Note>
            {/* <Alert variant="warning">
                Your buy review requests move to order section <b>{this.context.policies?.includes(SELLER) ? "Orders -> Buy -> Review Requests" : "Orders -> Review Requests"}</b>.<br />
                This section use for contact us (API Gift) only.
            </Alert> */}
            <hr />
            {this.state.navigateToTab && this.state.navigateToTab !== this.props.activeTab ? <Navigate to={"/helpCenter?activeTab=" + this.state.navigateToTab} /> : null}
            <Tabs defaultActiveKey={this.props.activeTab ?? 'supportTickets'} onSelect={this.handleSelect} id="financials-tabs" className="mb-3" mountOnEnter={true} unmountOnExit={true}>
                <Tab eventKey="supportTickets" title="Support Tickets">
                    <SupportTickets globalTicketOnly />
                </Tab>
                <Tab eventKey="faq" title="FAQ">
                    <FAQ />
                </Tab>
                <Tab eventKey="articles" title="Articles">
                    <Articles categoryId={this.props.categoryId} />
                </Tab>
            </Tabs>
        </div>
    }
}