import React from 'react';
import { Modal, Spinner, Row, Col, Button } from 'react-bootstrap'
import { IProps, IState } from './ApigiftMediationModal.models';
import ReviewRequestService from '../../../../services/orders/ReviewRequestService';

export default class ApigiftMediationModal extends React.Component<IProps, IState> {
    reviewRequestService: ReviewRequestService
    constructor(props: IProps) {
        super(props);
        this.reviewRequestService = new ReviewRequestService();
        this.state = { pendingRequest: false }
    }

    componentDidMount() {
    }

    submit = () => {
        this.setState({ pendingRequest: true })
        this.reviewRequestService.startMediation(this.props.ticketId).then(response => {
            this.setState({ pendingRequest: false })
            this.props.onHandleSubmit();
            this.props.closeModal();
        });
    }


    render(): React.ReactNode {
        const { pendingRequest } = this.state
        const { closeModal } = this.props
        return (
            <Modal size="lg"
                show={true}
                onHide={closeModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Apigift Mediation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>
                        When you request APIGIFT to mediate into the problem, you and opposite side have to send your proofs into the current ticket.
                        Coz you asked for mediation first, it doesn't mean you have the all right, ApiGift only checks proofs and let you guys peace with each other; otherwise as proofs shows we will try to see whos right.
                        After mediation , nor you neither your opposite can't cancel the mediation, it will progress till the peace.
                        <span style={{ color: "red", display: "block", marginBottom: '10px' }}>(same as product price will be hold from buyer shop and double of it from seller shop, be aware that money will be held for 30 days, even after your fight get peace.)</span>
                        <br />
                        *By the way the best way is first you communicate with your opposite and when you got sure that there's no way to peace together then you request mediation from us.

                        If you did read carefully and accepted rules, send the request.

                    </span>
                </Modal.Body>
                <Modal.Footer>
                    {pendingRequest ? <Button variant='success' disabled><Spinner animation='border' /></Button> :
                        <Button variant='success' onClick={this.submit}>Send Request</Button>}
                </Modal.Footer>
            </Modal>
        )
    }
}