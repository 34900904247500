import React, { RefObject } from "react"
import { GridAction, GridColumn, GridColumnTypes } from "../../../Grid/Grid.models"
import DynamicGrid from "../../../shared/DynamicGrid/DynamicGrid"
import IState, { IProps, IShopFilters } from "./AdminShopsList.models"
import AdminShopService from "../../../../services/admin/shops/AdminShopService"
import shopStatusDisplayBadge, { ShopStatusEnum } from "../../../shops/ShopStatusEnum"
import { NavLink } from "react-router-dom"

export default class ShopsList extends React.Component<IProps, IState> {
  dynamicGrid: RefObject<DynamicGrid<IShopFilters>>
  shopService: AdminShopService

  constructor(props: any) {
    super(props)
    this.state = { isLoading: false }
    this.dynamicGrid = React.createRef()
    this.shopService = new AdminShopService()
  }

  componentDidMount = () => {
    this.dynamicGrid.current.fetch(this.dynamicGrid.current.state.pageSize)
  }

  accept = (id) => {
    this.setState({ isLoading: true })
    this.shopService
      .accept(id)
      .then(() => { })
      .finally(() => {
        this.dynamicGrid.current.resetSpinner()
        this.setState({ isLoading: false })
      })
  }

  renderOwnerView = (userid: string, username: string) => { return <NavLink to={"/admin/users?id=" + userid}>{username}</NavLink> }

  render(): React.ReactNode {
    let gridColumns: GridColumn[] = [
      { title: "Shop", key: "title" },
      { title: "Owner", key: "ownerName" },
      { title: "Members", key: "memberCount" },
      { title: "Number", key: "number" },
      { title: "Created", key: "registerAt", type: GridColumnTypes.DateOnly },
    ]
    let gridActions: GridAction[] = []

    if (this.props.status == ShopStatusEnum.Accepted)
      gridActions.push({
        caption: "Wallet",
        variant: "outline-info",
        skipSpinner: true,
        generateLink: (row) => `/admin/financials?activeTab=wallets&ownerId=${row.id}`
      })

    if (this.props.status == ShopStatusEnum.Pending)
      gridActions.push({
        caption: "Accept",
        variant: "outline-success",
        skipSpinner: this.state.isLoading,
        onClick: (row) => {
          this.accept(row.id)
        },
      })

    return (
      <>
        <DynamicGrid<IShopFilters>
          ref={this.dynamicGrid}
          columns={gridColumns}
          onFetch={this.shopService.get}
          hideFilterBar
          defaultFilters={{ status: this.props.status, type: this.props.type }}
          actions={gridActions}
          refactorRow={(row) => {
            return {
              ...row, status: shopStatusDisplayBadge(row.status), ownerName: this.renderOwnerView(row.ownerUserId, row.ownerName)
            }
          }}
        />
      </>
    )
  }
}
