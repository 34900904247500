import React from 'react';
import { Alert, Button, Col, Form, Modal, Row, Spinner, Tab, Table, Tabs } from 'react-bootstrap';
import { toast } from 'react-toastify';
import BuyPlanService, { BuyPlanStatus } from '../../../services/catalog/BuyPlanService';
import { Cart3, Save } from 'react-bootstrap-icons';
import ProductCatalog from '../ProductCatalog/ProductCatalog';
import { UpdateBuyPlanDto } from '../../../services/catalog/BuyPlanService';
import Note from '../../shared/Note/Note';
import ValidAmount from '../../utilities/Amount/ValidAmount';


interface Props {
    productId: string
}

interface IStates {
    customLimitPolicy: boolean;
    allowAllSellers: boolean;
    allowedSellerShopIds: string[];
    denySellerShopIds: string[];
    maxPrice: string;
    minimumCount: number;
    maximumCount: number;
    plannedDayCount: number;
    status: BuyPlanStatus;
    loading: boolean;
    outDailyAverage: number;
}

export default class AutoBuyPlan extends React.Component<Props, IStates> {
    buyPlanService: BuyPlanService;
    constructor(props: any) {
        super(props);
        this.state = {
            loading: false,
            customLimitPolicy: false,
            allowAllSellers: true,
            allowedSellerShopIds: [],
            denySellerShopIds: [],
            maxPrice: '0',
            minimumCount: 0,
            maximumCount: 100,
            plannedDayCount: 1,
            outDailyAverage: 0,
            status: BuyPlanStatus.PendingOnly
        }
        this.buyPlanService = new BuyPlanService();
    }
    componentDidMount(): void {
        this.buyPlanService.get(this.props.productId)
            .then((response) => {
                this.setState({
                    customLimitPolicy: response.customLimitPolicy,
                    allowAllSellers: response.allowAllSellers,
                    allowedSellerShopIds: response.allowedSellerShopIds,
                    denySellerShopIds: response.denySellerShopIds,
                    maxPrice: String(response.maxPrice),
                    minimumCount: response.minimumCount,
                    maximumCount: response.maximumCount,
                    plannedDayCount: response.plannedDayCount,
                    status: response.status,
                    outDailyAverage: response.outDailyAverage
                })
            })
    }
    onSave = () => {
        this.buyPlanService.update({
            customLimitPolicy: this.state.customLimitPolicy,
            allowAllSellers: this.state.allowAllSellers,
            allowedSellerShopIds: this.state.allowedSellerShopIds,
            denySellerShopIds: this.state.denySellerShopIds,
            maxPrice: Number(this.state.maxPrice),
            minimumCount: this.state.minimumCount,
            maximumCount: this.state.maximumCount,
            plannedDayCount: this.state.plannedDayCount,
            status: this.state.status,
            productId: this.props.productId
        })
            .then((response) => {
                toast.success('Your buy plan saved')
            })
    }
    noteView = (status: BuyPlanStatus) => {
        switch (status) {
            case BuyPlanStatus.PendingOnly:
                return <Note>In case of need (selling by web service or selling to others), the order will be registered immediately</Note>

            case BuyPlanStatus.AutoMinimum:
                return <Note>The minimum number of product stock (daily) is automatically calculated and supplied as needed</Note>

            case BuyPlanStatus.CustomMinimum:
                return <Note>Specify the minimum number (daily) of product stock to always be in stock</Note>

        }
    }
    render(): React.ReactNode {
        return <div>
            <Row className="mt-2">
                <Col md="3" sm="12" className="d-flex align-items-center">Maximum Buy Price:</Col>
                <Col md="6" sm="12">
                    <Form.Control
                        type="text"
                        inputMode='decimal' value={this.state.maxPrice}
                        onChange={(e) => ValidAmount(e.target.value) && this.setState({ maxPrice: e.target.value })} />
                </Col>
            </Row>
            <hr />
            <div>
                {Object.keys(BuyPlanStatus).map(key => typeof BuyPlanStatus[key as any] !== 'number' && (
                    <Form.Check inline name={'delay-' + key} onChange={(e) => this.setState({ status: Number(e.target.value) })}
                        id={'delay-' + key} type="radio" value={key} label={BuyPlanStatus[key as any]} checked={this.state.status === Number(key)} />
                ))}</div>
            {
                this.noteView(this.state.status)
            }

            {
                this.state.status == BuyPlanStatus.AutoMinimum && <>

                    <Row className="mt-2">
                        <Col md="3" sm="12" className="d-flex align-items-center">Auto Minimum (Now):</Col>
                        <Col md="6" sm="12">
                            {this.state.outDailyAverage}
                        </Col>
                    </Row>

                    <Row className="mt-2">
                        <Col md="3" sm="12" className="d-flex align-items-center">Minimum Count:</Col>
                        <Col md="6" sm="12">
                            <Form.Control type="number"
                                value={this.state.minimumCount}
                                onChange={(e) => this.setState({ minimumCount: parseFloat(e.target.value) })} />
                        </Col>
                    </Row>

                    <Row className="mt-2">
                        <Col md="3" sm="12" className="d-flex align-items-center">Maximum Count:</Col>
                        <Col md="6" sm="12">
                            <Form.Control
                                type='number' 
                                value={this.state.maximumCount}
                                onChange={(e) => this.setState({ maximumCount: parseFloat(e.target.value) })} />
                        </Col>
                    </Row>
                    <hr></hr>
                    <Row className="mt-2">
                        <Col md="3" sm="12" className="d-flex align-items-center">Planning for day(s):</Col>
                        <Col md="6" sm="12">
                            <Form.Control type="number"
                                value={this.state.plannedDayCount}
                                onChange={(e) => this.setState({ plannedDayCount: parseFloat(e.target.value) })} />
                        </Col>
                    </Row>
                </>
            }

            {
                this.state.status == BuyPlanStatus.CustomMinimum && <>
                    <Row className="mt-2">
                        <Col md="3" sm="12" className="d-flex align-items-center">Minimum Count:</Col>
                        <Col md="6" sm="12">
                            <Form.Control type="number" value={this.state.minimumCount}
                                onChange={(e) => this.setState({ minimumCount: parseFloat(e.target.value) })} />
                        </Col>
                    </Row>
                </>
            }

            <hr />
            {this.state.loading ? <Button variant='success' disabled><Spinner animation='border' /></Button> :
                <Button variant='success' onClick={this.onSave}> <Save /> Save Changes</Button>}
        </div>
    }
}