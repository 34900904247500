import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import AdminSupportTicketDepartement from "./departement/AdminSupportTicketDepartement";
import AdminTicketsList from "./TicketList/AdminTicketsList";
import { ADMIN, SUPERADMIN, SUPPORT } from "../../../constants/ConstantsPermissions";
import AuthenticationContext from "../../../contexts/AuthenticationContext/AuthenticationContext";
import { SupportTicketStatus } from "../../helpCenter/SupportTickets/SupportTicketStatusEnum";

export default class AdminSupportTicket extends React.Component<{ activeTab: string | null, onlynegative?: boolean | null }, { navigateToTab?: string, onlyNegativeWallet?: boolean }> {
    static contextType = AuthenticationContext;
    context!: React.ContextType<typeof AuthenticationContext>;
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    componentDidUpdate(prevProps: Readonly<{ activeTab: string | null; }>, prevState: Readonly<{ navigateToTab?: string; }>, snapshot?: any): void {
        if (prevProps.activeTab !== this.props.activeTab)
            this.setState({ navigateToTab: undefined })
    }

    handleSelect = (key) => this.setState({ navigateToTab: key });

    checkAccess = (roles: string[]) => this.context.roles?.length > 0 && roles.filter(role => this.context.roles.includes(role)).length > 0

    initialTab = () => {
        return (<Tab eventKey="initial" title="Initial">
            <Tabs id="initial-tabs" className="mb-3" mountOnEnter={true} unmountOnExit={true}>
                <Tab eventKey="cartable" title="Cartable">
                    <AdminTicketsList hasSelecteDepartment isActive={true} status={SupportTicketStatus.TargetPending} hasDepartment={false} />
                </Tab>
                <Tab eventKey="active" title="Active">
                    <AdminTicketsList hasDepartment={false} isActive={true} />
                </Tab>
                <Tab eventKey="archive" title="Archive">
                    <AdminTicketsList hasDepartment={false} isActive={false} />
                </Tab>
            </Tabs>
        </Tab>)
    }

    render(): React.ReactNode {
        return <>
            {this.state.navigateToTab && this.state.navigateToTab !== this.props.activeTab ? <Navigate to={"/admin/support-ticket/?activeTab=" + this.state.navigateToTab} /> : null}
            <Tabs activeKey={this.props.activeTab ?? (this.checkAccess([ADMIN, SUPERADMIN]) ? "tickets" : "initial")} onSelect={this.handleSelect} id="support-ticket-tabs" className="mb-3" mountOnEnter={true} unmountOnExit={true}>
                {this.checkAccess([ADMIN, SUPERADMIN]) &&
                    <Tab eventKey="tickets" title="Tickets">
                        <Tabs id="credit-tabs" className="mb-3" mountOnEnter={true} unmountOnExit={true}>
                            {this.initialTab()}
                            <Tab eventKey="departement" title="Departement">
                                <Tabs id="inital-tabs" className="mb-3" mountOnEnter={true} unmountOnExit={true}>
                                    <Tab eventKey="active" title="Active">
                                        <AdminTicketsList hasDepartment={true} isActive={true} />
                                    </Tab>
                                    <Tab eventKey="archive" title="Archive">
                                        <AdminTicketsList hasDepartment={true} isActive={false} />
                                    </Tab>
                                </Tabs>
                            </Tab>
                        </Tabs>
                    </Tab>
                }
                {this.checkAccess([ADMIN, SUPERADMIN]) &&
                    <Tab eventKey="departement" title="Departement">
                        <AdminSupportTicketDepartement />
                    </Tab>
                }
                {this.checkAccess([SUPPORT]) && this.initialTab()}
            </Tabs>
        </>
    }
}
