import React, { RefObject } from "react";
import { GridAction, GridColumn, GridColumnTypes } from "../../../Grid/Grid.models";
import authenticationContext from "../../../../contexts/AuthenticationContext/AuthenticationContext";
import DynamicGrid from "../../../shared/DynamicGrid/DynamicGrid";
import HoldService from "../../../../services/financials/HoldService";
import { Button, Modal, NavLink } from "react-bootstrap";

export default class HoldListModal extends React.PureComponent<{ walletId: string, onClose: () => void }> {
    holdService: HoldService;

    static contextType = authenticationContext;
    context!: React.ContextType<typeof authenticationContext>;
    dynamicGrid: RefObject<DynamicGrid<{ walletId: string }>>
    constructor(props: any) {
        super(props);
        this.dynamicGrid = React.createRef();
        this.holdService = new HoldService();
    }

    render(): React.ReactNode {
        let gridColumns: GridColumn[] = [
            { title: 'Amount', key: 'amountValue', type: GridColumnTypes.Amount },
            { title: 'Description', key: 'description' },
            { title: 'ReferenceLink', key: 'referenceLink' },
            { title: 'CreateDate ', key: 'createDate ', type: GridColumnTypes.Date },
        ];
        let gridActions: GridAction[] = [];

        return (<div>
            <Modal size="lg" show={true} onHide={this.props.onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Holds</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <DynamicGrid<{ walletId: string }> ref={this.dynamicGrid}
                            columns={gridColumns}
                            actions={gridActions}
                            hideFilterBar
                            defaultFilters={{ walletId: this.props.walletId }}
                            onFetch={this.holdService.get}
                            refactorRow={(row) => {
                                return { ...row, referenceLink: row.referenceLink && <NavLink href={row.referenceLink}><Button size="sm">Reference</Button></NavLink> }
                            }}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </div >)
    }
}