import { InventoryShopBasePlanStatusEnum } from "../../components/inventory/Enums/InventoryShopBasePlanStatusEnum"
import { mapToDataOnly } from "../common/HttpUtility"
import { InventoryCategoryApiFactory } from "../../generated-api"

export default class InventoryCategoryService {
    getDetails(categoryId: string): Promise<InventoryCategoryDetailsDTO> {
        return mapToDataOnly<InventoryCategoryDetailsDTO>(InventoryCategoryApiFactory().sellerInventoryCategoryCategoryIdGet(categoryId))
    }

    getAll(): Promise<ISellerInventoryProductCategoryDTO[]> {
        return mapToDataOnly<ISellerInventoryProductCategoryDTO[]>(InventoryCategoryApiFactory().sellerInventoryCategoryGet())
    }

    update(productCategories: string[]): Promise<any> {
        return mapToDataOnly<any>(InventoryCategoryApiFactory().sellerInventoryCategoryPost(productCategories))
    }
}

interface InventoryCategoryDetailsDTO {
    categoryTitle: string
}

export interface ISellerInventoryProductCategoryDTO {
    id: string;
    title: string;
    status: InventoryShopBasePlanStatusEnum | null;
    rejectedAt: string | null;
}