
import { useSearchParams } from 'react-router-dom'
import AdminArticle from '../../../components/admin/article/AdminArticle'
import AdminAuthenticated from '../../../components/shared/AdminAuthenticated/AdminAuthenticated'
import { ADMIN, CONTENT_WRITER, SUPERADMIN } from '../../../constants/ConstantsPermissions'

export default function AdminArticlesRoute() {
    const [searchParams] = useSearchParams()
    let activeTab = searchParams.get("activeTab")
    return <AdminAuthenticated roles={[SUPERADMIN, ADMIN, CONTENT_WRITER]}><AdminArticle activeTab={activeTab} />  </AdminAuthenticated>
}