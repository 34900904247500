import { PaginationQuery } from "../../../Grid/Pagination/Pagination.models";

export default interface IReportsState {
    hasData?:boolean
}

export interface IReportsProps {
    isActive?: boolean
    showOnlyPendings?: boolean
    pageSize: number,
    status?: SupportTicketStatus,
}

export enum SupportTicketStatus {
    Pending,
    UserPending,
    TargetPending,
    Closed
}

export interface IReviewRequestPaginationFilters  {
    isActive?: boolean
    status?: SupportTicketStatus,
    showOnlyPendings?: boolean
    ticketNumber?: string
}


export interface IShopReportSupportTicketQueryString extends PaginationQuery {
    isActive?: boolean
    status?: SupportTicketStatus,
    showOnlyPendings?: boolean
    ticketNumber?: string
}