import axios from "axios"
import React from "react"
import './ShopChanger.scss'
import ShopService from "../../../services/shops/ShopService"
import authenticationContext from "../../../contexts/AuthenticationContext/AuthenticationContext"
import { ADMIN, CONTENT_WRITER, MEDIATOR, SUPERADMIN, SUPPORT } from "../../../constants/ConstantsPermissions"
import { Shop, Person } from "react-bootstrap-icons"
import ShopsSelection from "../../shops/ShopsSelection/ShopsSelection"

interface IShop {
    title: string, value: string
}

export default class ShopChanger extends React.Component<{}, { shops: IShop[], selectedShopId: string }> {
    shopService: ShopService;
    static contextType = authenticationContext;
    context!: React.ContextType<typeof authenticationContext>;
    constructor(props: any) {
        super(props);
        this.shopService = new ShopService();
        this.state = { shops: [], selectedShopId: '' };
    }

    componentDidMount() {
        let activeshop = localStorage.getItem('activeshop');

        if (activeshop !== undefined && activeshop !== null && activeshop !== '') {
            this.setState({ selectedShopId: activeshop });
        }
    }

    generatePathNameByRole = (roles: string[]) => {
        if (roles.includes(ADMIN) || roles.includes(SUPERADMIN))
            return '/admin/dashboard';
        else if (roles.includes(SUPPORT))
            return '/admin/support-ticket';
        else if (roles.includes(MEDIATOR))
            return '/admin/review-request';
        else if (roles.includes(CONTENT_WRITER))
            return '/admin/article';
        else return '/dashboard';
    }

    onLoad = (shops: IShop[]) => {
        if (!this.state.selectedShopId && shops.length > 0)
            this.change(shops[0]);
    }

    change = (shop: IShop) => {
        localStorage.setItem('activeshop', shop.value);
        this.setState({ selectedShopId: shop.value });

        if (shop.value == 'admin') return window.location.pathname = this.generatePathNameByRole(this.context.roles)
        else if (window.location.pathname.includes('admin'))
            window.location.pathname = '/marketplace'
        else window.location.reload();

        axios.defaults.headers.common['activeshop'] = `${shop.value}`;
    }

    formatResult = (item) => {
        return (
            <div className="d-flex align-items-center">
                {item.value == 'admin' ? <Person /> : <Shop />}
                <span className="ms-2">{item.title}</span>
            </div>
        )
    }

    render(): React.ReactNode {
        var additionalItem = undefined;
        if (this.context.roles.map(item => [ADMIN, SUPERADMIN, MEDIATOR, CONTENT_WRITER, SUPPORT].includes(item)).length > 0)
            additionalItem = { title: 'Admin', value: 'admin' };

        return <div className="shop-area">
            <div className="shop-selection">
                <ShopsSelection selectedShopId={this.state.selectedShopId} onChanged={this.change} onLoad={this.onLoad} additionalItem={additionalItem} />
            </div>
        </div>
    }
}