import { IWithdrawalsPaginationFilters } from "../../components/financials/cashOutes/CashOutsList/CashOutsList.models";
import { CashOutStatusEnum } from "../../components/financials/Enums/CashOutStatusEnum";
import { GridResult } from "../../components/Grid/Grid.models"
import { PaginationQuery } from "../../components/Grid/Pagination/Pagination.models";
import { mapToDataOnly } from "../common/HttpUtility"
import { WithdrawApiFactory } from "../../generated-api/financials";

export default class CashOutsService {
    getPaged(filters: IWithdrawalsPaginationFilters, paginationQuery: PaginationQuery): Promise<GridResult<IShopCashOutRowDTO>> {
        return mapToDataOnly<GridResult<IShopCashOutRowDTO>>(WithdrawApiFactory().apiV1WithdrawGet(filters.status, filters.transactionNumber, paginationQuery.pageskip - 1, paginationQuery.pagesize))
    }

    create(dto: IAddCashOutDTO): Promise<{}> {
        return mapToDataOnly<{}>(WithdrawApiFactory().apiV1WithdrawPost(dto));
    }

    cancel(id: string): Promise<{}> {
        return mapToDataOnly<{}>(WithdrawApiFactory().apiV1WithdrawCancelWithdrawIdPut(id));
    }

    getFee(): Promise<WithdrawFeeDTO> {
        return mapToDataOnly<WithdrawFeeDTO>(WithdrawApiFactory().apiV1WithdrawFeeGet());
    }
}

interface IShopCashOutRowDTO {
    id: string
    amount: number
    financialAccount: string
    sendAt: string | null
    requestAt: string
    status: CashOutStatusEnum
}

interface WithdrawFeeDTO {
    value: number
}

interface IAddCashOutDTO {
    financialAccountId: string;
    amount: number;
    description: string;
    inventorySourceId: string | null;
}