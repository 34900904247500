import React from "react";
import { Card, Col, Placeholder, Row } from "react-bootstrap";
import { ProductCatalogDto } from "../../../../services/catalog/ProductCatalogService";
import OrderProductService, { IExpressProductCatalogDto, ProductDetails } from "../../../../services/orders/OrderProductService";
import ProductCatalogItem from "./ExpressProductCatalogItem/ExpressProductCatalogItem";
import Banners from "../../../dashboard/Banners/Banners";


interface IProps {
    categoryId?: string
    productId?: string
    search?: string
    generalProductImage: string
    onClickBuy: (productId: string, title: string) => void
    onClickSell: (productId: string) => void
}

export default class ExpressProductCatalog extends React.Component<IProps, { isLoading: boolean, items: IExpressProductCatalogDto[] }> {
    orderProductService: OrderProductService
    constructor(props: any) {
        super(props);
        this.orderProductService = new OrderProductService()
        this.state = { isLoading: false, items: [] }
    }

    componentDidMount(): void {
        this.loadCatalog();
    }

    
    componentDidUpdate(prevProps: IProps) {
        if ((prevProps.categoryId !== this.props.categoryId) || (prevProps.search !== this.props.search) || (prevProps.productId !== this.props.productId))
            this.loadCatalog();
    }

    loadCatalog = () => {
        this.setState({ isLoading: true });
        this.orderProductService.getExpressCatalog({
            productCategoryId: this.props.categoryId,
            productTitleSearch: this.props.search,
            productId: this.props.productId,
        }).then((response) => {
            this.setState({
                isLoading: false,
                items: response.products
            })
        });
    }

    render(): React.ReactNode {

        return <>
            <Banners />

            {this.state.isLoading ?
                <Row>
                    {
                        Array.from(Array(10), (e, i) => <Col xs="12" md="6" lg="4" xl="3" xxl="3"><div className={"ProductCatalogItem"}
                            key={'ProductCatalogItem' + i}>
                            <Placeholder animation="glow" className="product-image">
                                <Placeholder xs={12} style={{ height: '200px', borderRadius: '8px 8px 0 0' }} />
                            </Placeholder>
                            <Placeholder animation="glow">
                                <Placeholder xs={12} size='lg' />
                            </Placeholder>
                            <div className="bottom-section">

                                <div style={{ color: "#ababab" }}>
                                    <Placeholder as={Card.Text} animation="glow">
                                        <Placeholder xs={8} />
                                    </Placeholder>
                                </div>
                                <div style={{ textAlign: 'right' }}>
                                    <Placeholder as={Card.Text} animation="glow">
                                        <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                                    </Placeholder>
                                </div>
                            </div>
                            <div className="bottom-section">
                                <div>
                                    <div className="button placeholder"></div>
                                </div>
                                <div>
                                    <div className="button placeholder"></div>
                                </div>
                            </div>

                        </div></Col>)
                    }
                </Row> : <Row>
                    {
                        this.state.items.map((item) => <Col xs="12" md="6" lg="4" xl="3" xxl="3">
                            <ProductCatalogItem title={item.title}
                                onClickBuy={() => this.props.onClickBuy(item.id,item.title)}
                                onClickSell={() => this.props.onClickSell(item.id)}
                                imageSrc={this.props.generalProductImage}
                                imagePath={item.imageSrc}
                                currencyId={item.currencyId}
                                productId={item.id} realPrice={item.realPrice} key={item.id} />
                        </Col>)
                    }
                </Row>
            }
        </>
    }

    getBestPrice(product: ProductCatalogDto) {
        return product.sellers.length > 0 ?
            Math.min(...product.sellers.map((seller) => seller.plans.length > 0 ? seller.plans[0].price : 0)) : null;
    }
}