import { useSearchParams } from "react-router-dom";
import Inventory from "../../components/inventory/Inventory";
import ShopAuthenticated from "../../components/shared/ShopAuthenticated/ShopAuthenticated";
import { INVENTORY_MENU } from "../../constants/ConstantsPermissions";

export default function InventoriesRoute() {
    const [searchParams] = useSearchParams();
    let activeTab = searchParams.get("activeTab")
    let productId = searchParams.get("productId")
    let shopCollectionId = searchParams.get("shopcollectionid")
    let defaultActiveSubKey = searchParams.get("defaultActiveSubKey")
    return <ShopAuthenticated showAccessDenied permission={INVENTORY_MENU}><Inventory defaultActiveKey={activeTab} productId={productId} shopCollectionId={shopCollectionId} defaultActiveSubKey={defaultActiveSubKey} /></ShopAuthenticated>
}