import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import TwoFactorService from "../../../../../services/identity/TwoFactorService";
import TOTPForm from "../TOTPForm/TOTPForm";
import TwoFactorStepsModal from "../TwoFactorSteps/TwoFactorStepsModal";
import IState, { IProps } from "./TwoFactorVerification.models";
import './TwoFactorVerification.scss'

export default class TwoFactorVerification extends React.PureComponent<IProps, IState> {
    twoFactorService: TwoFactorService
    constructor(props: IProps) {
        super(props);
        this.state = { showModal: false, activeOTPMode: false, loading: false };
        this.twoFactorService = new TwoFactorService()
    }

    closeModal = () => {
        this.setState({ showModal: false })
    }

    openTotpForm = () => {
        this.setState({ activeOTPMode: true });
    }

    twoFactorHandler = () => {
        if (!this.props.isTwoFactorEnabled) this.setState({ showModal: true })
        else if (window.confirm('Do you sure to disable the two factor verification ?')) this.openTotpForm()
    }

    disableTwoFactor = (totp: string) => {
        this.twoFactorService.disable(totp).then(() => {
            toast.success("Two-factor has been disabaled successfully")
            this.setState({ activeOTPMode: false })
            this.props.reloadPage()
        })
    }

    render(): React.ReactNode {
        const { isTwoFactorEnabled } = this.props
        return (
            <div className="p-3 mt-3">
                <h3>Two-factor verification 
                    <label className={!isTwoFactorEnabled ? 'text-danger p-1' : 'text-success p-1'}>
                        {!isTwoFactorEnabled ? '(Disable - Your security is in low level)' : '(Enable)'}
                    </label>
                </h3>
                <Row>
                    <Col md="12" sm="12">
                        <label className="sub-title">Add an extra layer of Security to your account, 2FA Authentication protects your account by requiring an additional code where need more security. (i.e while you login)</label>
                    </Col>
                    <Col md="12" sm="12">
                        <Button className="mt-3" variant={isTwoFactorEnabled ? 'danger' : 'success'} onClick={this.twoFactorHandler}>
                            {!isTwoFactorEnabled ? 'SetUp' : 'Disable'}
                        </Button>
                    </Col>
                </Row>
                {this.state.showModal && <TwoFactorStepsModal onHandle={this.props.reloadPage} closeModal={this.closeModal} showModal={this.state.showModal} />}
                <TOTPForm onHandle={this.props.reloadPage} hasRecovery={true} showModal={this.state.activeOTPMode} onSubmit={this.disableTwoFactor} onHide={() => this.setState({ activeOTPMode: false })} loading={this.state.loading} />
            </div>
        )
    }
}