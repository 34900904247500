interface IFAQView {
    question: string,
    answer: string,
}
export const FAQData: IFAQView[] = [
    {
        question: 'Earn money at API Gift!',
        answer: `API Gift allows you to create a shop and buy Gift Cards as many as you need, and then sell them to your own customers and earn money! Everything you need is a shop, which you can create it in your profile!
        You can also connect your gift card store website to the API Gift via our API Gateway and sell the gift cards through your own website! This is so useful for those who have a store or a community, or people who just want to have an additional revenue. It's so amazing! isn't it?`,
    },
    {
        question: 'Yes, Low-priced Gift Cards, But how?',
        answer: `API Gift provides various types of Gift Cards at the minimum price possible. Because there are No middlemen in the process of preparation of our products. Another reason is that we sell every Gift Card directly and there's not anything between you, dear customers and API Gift. API Gift made a competition between sellers in itself market to ensure that prices are always at the minimum possible!`,
    },
    {
        question: 'How do I make sure your products work properly?',
        answer: `All of our commercial connections are safe and reliable, because we work with reliable people and companies. A powerful team of expert engineers are always analyzing production process at the back-end. Also API Gift is the source of many other Gift Card marketplaces and personal sellers. We're hosting plenty of people who sell and buy Gift Cards and our other services entire the world. We're trusted by thousands of people, stores, and businesses from small to big sizes!`,
    },
    {
        question: 'Is API Gift safe and reliable?',
        answer: `We always keep an eye on the security and maintenance of the system. Several security barriers are designed by us to keep your credit, information and shop safe from data attackers. Also you have the ability to set policies for your staffs in your shop because at API Gift it's possible that several people work on a shop. However it's too easy for yourself to login/sign-up, and modify your data in your shop. Thanks to our connoisseur security and maintenance team, We've got your back! so you shouldn't be worry about security issues at all!`,
    },
]
