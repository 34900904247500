import React, { RefObject } from "react"
import { Modal, Button, OverlayTrigger, Spinner, Table, Tooltip, Form } from "react-bootstrap"
import OrderProductService, { ProductDetails } from "../../../../services/orders/OrderProductService"
import IState, { IProductCatalogColumnView, IProps, ProductComparePrice } from "./TradeProductCatalog.models"
import { NavLink } from "react-router-dom"
import { SELLER } from "../../../../constants/ConstantsPolicies"
import inventoryShopBasePlanStatusDisplayBadge, { InventoryShopBasePlanStatusEnum } from "../../../inventory/Enums/InventoryShopBasePlanStatusEnum"
import Amount from "../../../utilities/Amount/Amount"
import './TradeProductCatalog.scss'
import ShopPolicies from "../../../shared/policy/ShopPolicies/ShopPolicies"
import { CheckCircleFill, Gear, Link, QuestionCircle, SortUp, Stack } from "react-bootstrap-icons"
import TradeProductCatalogConfig from "./TradeProductCatalogConfig"
import AuthenticationContext from "../../../../contexts/AuthenticationContext/AuthenticationContext"
import BuyOffers from "../../../orders/offers/BuyOffers/BuyOffers"
import OwnedOffersBoard from "../../../orders/offers/OwnedOffersBoard/OwnedOffersBoard"
import Note from "../../../shared/Note/Note"
import Pagination from "../../../Grid/Pagination/Pagination"

export default class TradeProductCatalog extends React.PureComponent<IProps, IState> {
    refPolicies: RefObject<ShopPolicies>
    orderProductService: OrderProductService
    static contextType = AuthenticationContext;
    context!: React.ContextType<typeof AuthenticationContext>;
    constructor(props: IProps) {
        super(props)
        this.refPolicies = React.createRef()
        this.orderProductService = new OrderProductService()
        this.state = {
            isLoading: false,
            pageSize: 10,
            sortByKey: "realPrice",
            sortItems: [
                { title: 'Buy Pending Count', key: 'buyPendingCount' },
                { title: 'Suggestion', key: 'buySuggestionCount' },
                { title: 'Real Price', key: 'realPrice' },
                { title: 'Available', key: 'availableInventory' },
            ],
            productPrices: [],
            showSortModal: false,
            onlyAvailable: false,
            pageSkip: 0,
            products: [],
            totalRows: 10,
            gridColumns: [
                { title: 'Available', key: 'availableInventory', selectedByDefault: true, shopPolicy: 'SELLER', description: 'This is your available stock in inventory', color: 'rgb(54 157 13)' },
                { title: 'Incoming', key: 'buyPendingCount', selectedByDefault: true, description: 'This is sum of the Deficits and Owned buy offers' },
                { title: 'Suggestion', key: 'buySuggestionCount', selectedByDefault: true, description: ' buy suggestion count', shopPolicy: 'SELLER' },
                { key: 'realPrice', title: 'Real Price' },
            ],
            tableConfig: {
                coloredRow: false,
                showOpenProductsOnly: false,
                selectedGridColumnKeys: []
            },
            fullscreen: false,
            showConfigModal: false,
        }
    }

    componentDidMount() {



        var gridColumns = this.state.gridColumns
            .filter(gridColumn => gridColumn.shopPolicy === undefined || this.hasPolicy(gridColumn.shopPolicy));

        this.setState({ gridColumns: gridColumns }, this.loadCatalog)
    }


    componentDidUpdate(prevProps: IProps) {
        if ((prevProps.categoryId !== this.props.categoryId) || (prevProps.search !== this.props.search) || (prevProps.productId !== this.props.productId))
            this.loadCatalog();
    }

    loadCatalog = (pageSkip: number = undefined, size: number = undefined, sortBy: string = undefined) => {

        var sortByKey = this.props.defaultSortKey;
        if (localStorage.getItem('dashboard-sort-by-key')) {
            sortByKey = JSON.parse(localStorage.getItem('dashboard-sort-by-key'));
            if (this.state.sortItems.filter(item => item.key == sortByKey).length == 0)
                sortByKey = 'buyPendingCount';
        }

        pageSkip = pageSkip ?? this.state.pageSkip;
        size = size ?? this.state.pageSize;
        const categoryId = this.props.categoryId;
        this.setState({ pageSkip: pageSkip, pageSize: size, isLoading: true });
        this.orderProductService.getStatisticsList({
            productCategoryId: categoryId,
            sortBy: sortByKey,
            search: this.props.search,
            productId: this.props.productId,
            onlyAvailable: this.state.onlyAvailable,
            pageSize: size,
            pageSkip: pageSkip
        }).then((response) => {
            if (categoryId == this.props.categoryId) {

                this.setState({
                    isLoading: false,
                    products: response.products,
                    totalRows: response.totalRows
                })
                for (var product of response.products)
                    this.orderProductService.getProductPriceCompare([product.productId]).then((response) => {
                        this.setState({ productPrices: [...this.state.productPrices, response[0]] })
                    })
            }
        });
    }

    changeSortByKey = (key: string) => {
        localStorage.setItem('dashboard-sort-by-key', JSON.stringify(key));
        this.setState({ sortByKey: key, showSortModal: false }, this.loadCatalog)
    }

    changeOnlyAvailable = () => {
        this.setState({ onlyAvailable: !this.state.onlyAvailable }, this.loadCatalog)
    }

    hasPolicy = (policy: string) => this.context.policies?.includes(policy)
    getRowColor(product: ProductDetails) {
        var color = '';

        if (this.state.tableConfig.coloredRow === true && this.props.catalogMode === 'trade') {

            if (product.minimumInventory &&
                product.minimumInventory > product.availableInventory && product.status === InventoryShopBasePlanStatusEnum.Open)
                color = 'rgb(255, 219, 154)';

        }

        return color;
    }
    mainView = () => {


        var columns = this.state.gridColumns.filter(column => this.state.tableConfig.selectedGridColumnKeys.filter(selectedGridColumnKey => selectedGridColumnKey == column.key).length > 0);
        var products = this.state.products;
        if (this.state.tableConfig.showOpenProductsOnly)
            products = products.filter(product => product.status == InventoryShopBasePlanStatusEnum.Open);
        return <div className="suggestion-order">
            {
                <TradeProductCatalogConfig gridColumns={this.state.gridColumns} show={this.state.showConfigModal}
                    tableConfig={this.state.tableConfig}
                    onClose={() => this.setState({ showConfigModal: false })} onChange={(config) => this.setState({ tableConfig: config })} />
            }

            {this.state.showSortModal &&
                <Modal size="sm" show onHide={() => this.setState({ showSortModal: false })}>
                    <Modal.Header closeButton>
                        <Modal.Title>Catalog Sort</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ShopPolicies policy={SELLER} >
                            <div className="catalog-sort-input">
                                <Form.Group>
                                    <Form.Label>Sort by</Form.Label>
                                    <Form.Select value={this.state.sortByKey} onChange={(e) => {
                                        this.changeSortByKey(e.target.value);
                                    }}>
                                        {
                                            this.state.sortItems.map(gridColumn => (<option key={gridColumn.key} value={gridColumn.key}>{gridColumn.title}</option>))
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </div>
                        </ShopPolicies>
                    </Modal.Body>
                </Modal>
            }


            {this.state.isLoading ?
                <Spinner animation="grow" /> :
                <Table size="md">
                    <thead>
                        <tr>
                            <th>
                                <OverlayTrigger
                                    key="top"
                                    placement="top"
                                    overlay={
                                        <Tooltip id="tooltip-top">
                                            Product Title
                                        </Tooltip>
                                    }
                                >
                                    <span>Product</span>
                                </OverlayTrigger>
                            </th>
                            <ShopPolicies policy={SELLER}>
                                <th>
                                    <OverlayTrigger
                                        key="top"
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip-top">
                                                Your sell status for this product
                                            </Tooltip>
                                        }
                                    >
                                        <span>Sell Status</span>
                                    </OverlayTrigger>
                                </th>
                            </ShopPolicies>

                            {
                                columns.map(column => column.description === undefined ? <th>{column.title}</th> :

                                    <th>
                                        <OverlayTrigger
                                            key="top"
                                            placement="top"
                                            overlay={
                                                <Tooltip id="tooltip-top">
                                                    {column.description}
                                                </Tooltip>
                                            }>
                                            <span style={{ color: column.color }}>
                                                {column.title}
                                            </span>
                                        </OverlayTrigger>
                                    </th>

                                )
                            }
                            <th>
                                Your Price
                            </th>
                            <th>
                                Other Price (Minimum)
                            </th>
                            <th>
                                <div>
                                    {
                                        this.state.tableConfig.coloredRow === true &&
                                        <OverlayTrigger
                                            key="top"
                                            placement="top"
                                            overlay={
                                                <Tooltip id="tooltip-top">
                                                    background colors following this rules: red = pendings , orange = products with 0 available and products with available count less than minimum count and rest of the products background are white
                                                </Tooltip>
                                            }
                                        >
                                            <span className="text-info" style={{ fontSize: '15px' }}>
                                                Colored Rows <QuestionCircle size="20px" />
                                            </span>
                                        </OverlayTrigger>
                                    }
                                    <span style={{ marginLeft: '10px', cursor: 'pointer', float: 'right' }}>
                                        <div style={{ display: 'inline-block' }}>
                                            <div className="sort-button" onClick={() => this.setState({ showSortModal: true })}>
                                                <SortUp />
                                                <label >{this.state.sortItems.filter(item => item.key == this.state.sortByKey)[0]?.title}</label>
                                            </div>
                                            <div className={`sort-button ${this.state.onlyAvailable ? "text-success" : ""}`} onClick={this.changeOnlyAvailable}>
                                                <Stack size="20px" />
                                                <label className={`ps-2 ${this.state.onlyAvailable ? "text-success" : ""}`}>Available</label>
                                            </div>
                                        </div>
                                        <Gear onClick={() => this.setState({ showConfigModal: true })} size="30px" color="#555555" />
                                    </span>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            products.map((row, index) => {
                                var productPrice: ProductComparePrice = undefined;
                                if (this.state.productPrices.filter(product => product.productId == row.productId).length > 0)
                                    productPrice = this.state.productPrices.filter(product => product.productId == row.productId)[0]
                                return <tr style={{ backgroundColor: this.getRowColor(row) }} key={index}>
                                    <td title={row.title}>
                                        {row.title.slice(0, 25)}
                                        {row.title.length >= 25 ? '...' : null}
                                    </td>
                                    <ShopPolicies policy={SELLER}>
                                        <td>
                                            {
                                                !this.state.tableConfig.showOpenProductsOnly &&
                                                inventoryShopBasePlanStatusDisplayBadge(row.status)

                                            }
                                        </td>
                                    </ShopPolicies>
                                    {
                                        columns.map(column => <td style={{ color: column.dynamicColor ? column.dynamicColor(row) : column.color }}>
                                            {
                                                column.valueType === 'amount' ? <Amount value={row[column.key]} /> : row[column.key]
                                            }
                                        </td>)
                                    }
                                    <td>
                                        {productPrice
                                            ? <>{productPrice.yourPrice} {(productPrice.yourPrice > 0 && (!productPrice.minimumOtherPrice || productPrice.yourPrice <= productPrice.minimumOtherPrice)) && <CheckCircleFill style={{ color: 'green' }} />}</>
                                            : <>Loading...</>
                                        }
                                    </td>
                                    <td>
                                        {productPrice ? productPrice.minimumOtherPrice
                                            : <>Loading...</>
                                        }
                                    </td>
                                    <td style={{ textAlign: 'right' }}>

                                        <ShopPolicies policy={SELLER} access={false}>
                                            <Button size="sm" onClick={() => this.refPolicies.current?.actionAfterCheckPolicy('seller')} variant="danger"><span>Sell</span></Button>
                                        </ShopPolicies>
                                        <ShopPolicies policy={SELLER}>
                                            <Button size="sm" onClick={() => this.props.onClickSell(row.productId)} variant="danger">Sell</Button>
                                        </ShopPolicies>

                                        <Button size="sm" variant="success" className="ml-1" onClick={() => this.props.onClickBuy(row.productId, row.title)}>Buy</Button>
                                    </td>
                                </tr>
                            })
                        }

                    </tbody>
                </Table >
            }
            {
                !this.props.categoryId && <Pagination onChangePageNumber={(number, size) => { this.loadCatalog(number - 1, size) }} totalRows={this.state.totalRows} pageNumber={this.state.pageSkip + 1}
                    onChangePageSize={(size) => { this.loadCatalog(this.state.pageSkip, size) }}></Pagination>
            }


            <ShopPolicies ref={this.refPolicies} action={(data) => { }} policy={SELLER}
                modalConfig={{ description: `It looks like you do'nt have access to the seller features`, small: true }} />
        </div>
    }

    render(): React.ReactNode {

        return <>

            <Modal show={this.state.fullscreen} fullscreen={true} onHide={() => this.setState({ fullscreen: false })}>
                <Modal.Header closeButton>
                    <Modal.Title>Buy suggestion</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {this.mainView()}
                </Modal.Body>
            </Modal>
            {this.state.offerDetails !== undefined &&
                <Modal show={true} onHide={() => this.setState({ offerDetails: undefined })}>
                    <Modal.Header closeButton>
                        <Modal.Title>Offers - {this.state.offerDetails.productTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <label>Buyer Blocked You Count: </label><b>{this.state.offerDetails.buyerBlockCount}</b><br />
                        <label>You Blocked Count: </label><b>{this.state.offerDetails.sellerBlockCount}</b><br />
                        <label>Out Of Your Price Range Count: </label><b>{this.state.offerDetails.outOfPriceRangeCount}</b>
                    </Modal.Body>
                </Modal>
            }
            {
                !this.props.categoryId && <>
                    <h4 style={{ backgroundColor: '#42ab93', color: '#fff', padding: '10px 30px', borderRadius: '10px 10px 0 0' }}>Buy Suggestion</h4>
                    <Note>You can see list of buy suggestion products</Note>
                </>
            }

            {this.mainView()}
        </>
    }
}