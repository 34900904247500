import { mapToDataOnly } from "../common/HttpUtility";
import { SelectionView } from "../common/Models";
import { ProductCategoryApiFactory } from "../../generated-api";

export default class ProductCategoryService {
    getChildOnlyTitleValues(): Promise<SelectionView[]> {
        return mapToDataOnly<SelectionView[]>(ProductCategoryApiFactory().buyerProductCategoryTitleValueChildOnlyGet());
    }

    openSellRequest(productCategoryId: string): Promise<{}> {
        return mapToDataOnly<{}>(ProductCategoryApiFactory().sellerProductCategoryProductCategoryIdPost(productCategoryId));
    }
}

export interface IProductCategoryMultiLevelDTO {
    id: string;
    title: string;
    parentId: string | null;
}

export interface IUpdateShopProductCategoryFavoriteDTO {
    productCategoryId: string;
    orderId: number;
}