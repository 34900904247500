import { Navigate, useSearchParams } from "react-router-dom";
import Orders from "../../components/orders/Orders/Orders";
import ShopAuthenticated from "../../components/shared/ShopAuthenticated/ShopAuthenticated";
import { ORDER_MENU } from "../../constants/ConstantsPermissions";

export default function SellOrdersRoute() {
    const [searchParams] = useSearchParams();
    let tab = searchParams.get("tab");

    return <>
        <ShopAuthenticated showAccessDenied permission={ORDER_MENU}><Orders mode="sell" tab={tab} /></ShopAuthenticated>
    </>
}