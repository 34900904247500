import { GridResult } from "../../../components/Grid/Grid.models";
import { PaginationQuery } from "../../../components/Grid/Pagination/Pagination.models";
import { mapToDataOnly } from "../../common/HttpUtility";
import { SupportTicketCartableApiFactory } from "../../../generated-api/admin";

interface SupportTicketCartableRowDTO {
    id: string
    subject: string
    description: string
    createAt: string
}

export default class AdminReviewRequestService {
    getCartablePaged(filters: {}, paginationQuery: PaginationQuery): Promise<GridResult<SupportTicketCartableRowDTO>> {
        return mapToDataOnly<any>(SupportTicketCartableApiFactory().adminSupportTicketCartableGet(paginationQuery.pageskip - 1, paginationQuery.pagesize));
    }

    review(id: string): Promise<any> {
        return mapToDataOnly<any>(SupportTicketCartableApiFactory().adminSupportTicketCartableReviewIdPut(id));
    }
}