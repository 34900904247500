import React, { RefObject } from "react";
import DepositService, { IDepositsPaginationFilters } from "../../../../services/financials/DepositService";
import { GridAction, GridColumn, GridColumnTypes } from "../../../Grid/Grid.models";
import { PaginationQuery } from "../../../Grid/Pagination/Pagination.models";
import DynamicGrid from "../../../shared/DynamicGrid/DynamicGrid";
import cashPaymentStatusDisplayBadge, { CashPaymentStatusEnum } from "../../Enums/CashPaymentStatusEnum";

export default class Deposit extends React.Component {
    dynamicGrid: RefObject<DynamicGrid<IDepositsPaginationFilters>>;
    depositService: DepositService;
    query: PaginationQuery;
    constructor(props: any) {
        super(props);
        this.dynamicGrid = React.createRef();
        this.depositService = new DepositService();
        this.query = { pageskip: 1, pagesize: 10 };
    }

    remove = (id: string) => {
        this.depositService.delete(id).then(() => {
            this.dynamicGrid.current.fetch();
        })
    }
    render(): React.ReactNode {
        let gridColumns: GridColumn[] = [
            { title: 'Account', key: 'cashPaymentAccountTitle' },
            { title: 'Amount', key: 'amount', type: GridColumnTypes.Amount },
            { title: 'Transaction Number', key: 'transactionNumber' },
            { title: 'Status', key: 'statusBadge' },
            { title: 'Purchase Price', key: 'purchasePrice', type: GridColumnTypes.Amount },
            { title: 'Purchase Currency', key: 'purchaseCurrency' },
            { title: 'Date', key: 'crreateAt', type: GridColumnTypes.Date },
        ];

        let gridActions: GridAction[] = [
            {
                caption: 'Delete', variant: 'outline-danger', checkIsDisabled: (row) => row.status !== CashPaymentStatusEnum.Pending,
                onClick: (row) => {
                    this.remove(row.id);
                }

            },
        ];
        return (<div className="Deposit">
            <DynamicGrid<IDepositsPaginationFilters> ref={this.dynamicGrid}
                columns={gridColumns}
                onFetch={this.depositService.get}
                actions={gridActions}
                refactorRow={(row) => {
                    return { ...row, statusBadge: cashPaymentStatusDisplayBadge(row.status) }
                }} />
        </div>)
    }
}