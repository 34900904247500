import React from "react"
import { ShopStatusEnum } from "../../../shops/ShopStatusEnum"
import AdminSellerService from "../../../../services/admin/shops/AdminSellerService"
import AdminSellersList from "./List/AdminSellersList"
import { NavLink } from "react-router-dom"
import { Button } from "react-bootstrap"

export default class AdminSellers extends React.Component<{}, {}> {
    adminSellerService: AdminSellerService

    constructor(props: any) {
        super(props)
        this.state = {}
        this.adminSellerService = new AdminSellerService();
    }

    render(): React.ReactNode {
        return (
            <>
                <NavLink to={'/admin/create-seller'}><Button variant="success" className="mt-2 mb-4">Add</Button></NavLink>
                <AdminSellersList status={ShopStatusEnum.Accepted} />
            </>
        )
    }
}