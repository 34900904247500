import React from "react"
import { Button, Container } from "react-bootstrap"
import { PrivacyPolicyData } from "../../constants/Content/PrivacyPolicy"
import SectionView from "../../shared/SectionView/SectionView"

export default class PrivacyPolicy extends React.PureComponent<{}, { isReadMore: boolean }> {
    constructor(props: any) {
        super(props)
        this.state = { isReadMore: false }
    }
    render(): React.ReactNode {
        let description = this.state.isReadMore ? PrivacyPolicyData.description : PrivacyPolicyData.description.slice(0, window.innerWidth > 996 ? 970 : 350)
        return <div>
            <Container className="pt-7">
                <SectionView title={PrivacyPolicyData.title} description={description} />
                <div className="plr-large mb-4 text-center">
                    <Button variant="danger" className="btn-brownish-red" onClick={() => this.setState({ isReadMore: !this.state.isReadMore })}>{this.state.isReadMore ? 'Show less' : 'Read More...'}</Button>
                </div>
            </Container>
        </div>
    }
}