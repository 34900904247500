import React from "react"
import './VerticalCardViewComponent.scss'

export default class VerticalCardViewComponent extends React.PureComponent<{ image: any, title: string, onclick?: () => void }, {}> {
    render(): React.ReactNode {
        const { image, title } = this.props
        return <>
            <div className="card-vertical">
                <div className="card_img">
                    <img width="100%" alt="" src={image} />
                </div>
                <div className="font-bold pt-4 fs-sm" dangerouslySetInnerHTML={{ __html: title }}></div>
            </div>
        </>
    }
}