import axios from "axios"
import { GridResult } from "../../../components/Grid/Grid.models"
import { PaginationQuery } from "../../../components/Grid/Pagination/Pagination.models"
import { mapToDataOnly } from "../../common/HttpUtility"
import { ShopStatusEnum } from "../../../components/shops/ShopStatusEnum"
import { SellerApiFactory } from "../../../generated-api/admin"

export default class AdminSellerService {
    get(query: IShopFilters, paginationQuery: PaginationQuery): Promise<GridResult<any>> {
        return mapToDataOnly<any>(SellerApiFactory().adminSellerGet(query.status, paginationQuery.pageskip - 1, paginationQuery.pagesize))
    }

    delete(shopId: string): Promise<any> {
        return mapToDataOnly<any>(axios.delete(`Admin/Seller/${shopId}`))
    }

    create(ownerUserId: string, body: CreateShopDTO): Promise<void> {
        return mapToDataOnly<any>(axios.post(`Admin/Seller/${ownerUserId}`, body))
    }
}

interface IShopFilters {
    status?: ShopStatusEnum
}

export interface CreateShopDTO {
    title: string;
    siteAddreess: string;
    email: string;
    tell: string;
    address: string;
}