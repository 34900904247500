import React from "react";

import { Spinner } from "react-bootstrap";

import AdminArticleService, { IArticleDTO } from "../../../../services/admin/articles/AdminArticlesService";
import './ArticleDetails.scss';

export default class ArticleDetails extends React.Component<{ articleId: string }, { loading: boolean, article: IArticleDTO }> {
    adminArticleService: AdminArticleService
    constructor(props: any) {
        super(props);
        this.state = { loading: false, article: { categoryId: '', details: '', imageName: '', imageSrc: '', published: false, title: '' } }
        this.adminArticleService = new AdminArticleService()
    }

    componentDidMount(): void {
        this.getDetails()
    }

    getDetails = () => {
        this.setState({ loading: true });
        this.adminArticleService.getById(this.props.articleId).then((response) => {
            this.setState({ article: response, loading: false })
        })
    }

    render(): React.ReactNode {
        let { loading, article } = this.state

        return <div className="d-flex justify-content-center article-details-container">
            {
                !loading ?
                    <div className="article-details-body">
                        <div className="text-center">
                            {article.imageSrc && <img className="main-img" src={article.imageSrc} />}
                            <h3>{article.title}</h3>
                        </div>
                        <div className="details" dangerouslySetInnerHTML={{ __html: article.details }}></div>
                    </div>
                    : <Spinner animation="grow" />
            }
        </div>
    }
}