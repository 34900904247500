import React from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import AdminCreditsService from "../../../../../services/admin/financials/AdminCreditsService";
import { toast } from "react-toastify";
import AdminWalletsServices from "../../../../../services/admin/financials/AdminWalletsServices";
import AdminSupportTicketDepartementService from "../../../../../services/admin/Support/AdminSupportTicketDepartementService";
import { IProps, IState } from "./SelecetDepartmentModal.models";

export default class SelecetDepartmentModal extends React.Component<IProps, IState> {
    adminCreditsService: AdminCreditsService
    adminWalletsServices: AdminWalletsServices
    AdminSupportTicketDepartementService: AdminSupportTicketDepartementService
    constructor(props: any) {
        super(props);
        this.state = { pendingRequest: false, departmentId: ''}
        this.adminCreditsService = new AdminCreditsService()
        this.AdminSupportTicketDepartementService = new AdminSupportTicketDepartementService()
    }

    submit = () => {
        let body = {departmentId: this.state.departmentId, SupportTicketId: this.props.ticketId}

        this.setState({ pendingRequest: true })
        this.AdminSupportTicketDepartementService.changeDepartment(body)
            .then(() => {
                toast.success("Change department successfully")
                this.props.onClose()
                this.props.reload()
            }).finally(() => this.setState({ pendingRequest: false }))
    }

    render(): React.ReactNode {
        let { pendingRequest } = this.state
        let { onClose } = this.props

        return <Modal show={true} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Select Department</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    <Row className="mt-2">
                        <Col md="12" sm="12">
                            <Form.Select aria-label="Select Department" id="supportTicketReply.department" value={this.state.departmentId}
                                onChange={(e) => this.setState({ departmentId: e.target.value })}>
                                    {
                                        this.props.departments.map((department) => <option value={department.id} key={department.id}>{department.title}</option>)
                                    }
                            </Form.Select>
                        </Col>
                    </Row>
            </Modal.Body>
            <Modal.Footer>
                {pendingRequest ? <Button variant='success' disabled><Spinner size="sm" animation='border' /></Button> :
                    <Button variant='success' onClick={this.submit}>Save</Button>}
            </Modal.Footer>
        </Modal>
    }
}