import React from "react";
import { Spinner, Tab, Tabs } from "react-bootstrap";
import CashPaymentService from "../../../services/financials/CashPaymentService";
import { LocalCurrencyService } from "../../../services/financials/LocalCurrencyService";
import SetLocalCurrencyModal from "../LocalCurrency/LocalCurrencyModal/SetLocalCurrencyModal";
import IState from "./Basic.models";
import CurrencyAverage from "./CurrencyAverage/CurrencyAverage";

export default class Basic extends React.PureComponent<{}, IState> {
    localCurrencyService: LocalCurrencyService
    cashPaymentService: CashPaymentService
    constructor(props: any) {
        super(props)
        this.localCurrencyService = new LocalCurrencyService()
        this.cashPaymentService = new CashPaymentService()
        this.state = { currencyId: '', loading: false, pendingRequest: false, activeShop: '', localCurrencyId: '' }
    }

    componentDidMount() {
        this.getPaged()
    }

    getPaged = () => {
        this.setState({ loading: true, activeShop: localStorage.getItem('activeshop') ?? '' })
        this.localCurrencyService.get().then((localCurrency) => {
            this.setState({ localCurrencyId: localCurrency.localCurrencyId, loading: false });
        })
    }

    render(): React.ReactNode {
        return <>
        
            <Tabs defaultActiveKey="localCurrency" className="mb-3" mountOnEnter={true} unmountOnExit={true}>
                <Tab eventKey="localCurrency" title="Local Currency">
                    {this.state.loading ? <Spinner animation="grow" /> :
                        <SetLocalCurrencyModal currentCurrencyId={this.state.localCurrencyId} onSubmit={this.getPaged} shopId={this.state.activeShop} />
                    }
                </Tab>
                <Tab eventKey="average" title="Average" disabled={!this.state.localCurrencyId ? true : false}>
                    <CurrencyAverage />
                </Tab>
            </Tabs>
        </>
    }
}