import { AxiosError } from "axios";
import React, { FormEvent } from "react";
import { Badge, Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import { Navigate, NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import authenticationContext from "../../../contexts/AuthenticationContext/AuthenticationContext";
import IdentityService from "../../../services/identity/IdentityService";
import CardView from "../../shared/Card/CardView";
import '../IdentityLayout.scss'
import ShopService from "../../../services/shops/ShopService";

export default class Register extends React.Component<{}, State> {
    identityService: IdentityService;
    shopService: ShopService;
    hostUrl: string;
    static contextType = authenticationContext;
    context!: React.ContextType<typeof authenticationContext>;
    constructor(props: any) {
        super(props);
        this.state = { email: '', password: '', repeatPassword: '', firstname: '', lastname: '', isRegistred: false, loading: false, logged: false }
        this.identityService = new IdentityService();
        this.shopService = new ShopService();
        this.hostUrl = window.location.protocol + "//" + window.location.host;
    }
    register = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (this.state.firstname.length === 0 || this.state.lastname.length === 0 || this.state.password.length === 0 || this.state.email.length === 0) {
            toast.error('All fields are required.');
            return;
        }

        if (this.state.password.length === 0) {
            toast.error('Password is required');
            return;
        }
        if (this.state.password !== this.state.repeatPassword) {
            toast.error('Your password is not equal to repeating it');
            return;
        }

        if (this.state.password.length < 8) {
            toast.error('Passowrd should have minimum 8 characters');
            return;
        }

        this.setState({ loading: true });
        this.identityService.register({
            email: this.state.email,
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            password: this.state.password
        }, `${this.hostUrl}/registerConfirmed`).then(() => {
            toast.success('Your account has been created successfully.');
            this.identityService.login(this.state.email, this.state.password).then((response) => {
                this.context.login(response.data.accessToken);
                this.setState({ logged: true });
                this.setState({ loading: false });
            });
        }).catch((data: AxiosError) => {
            var responseErrors = data.response.data.errors;
            if (responseErrors) {
                var errors = [];
                for (var error of Object.keys(responseErrors).map((key) => responseErrors[key]))
                    errors.push(error);
                toast.error(errors[0][0]);
            }
            else
                toast.error('This email has already been registered.');
            this.setState({ loading: false });

        })
    }
    render(): React.ReactNode {
        return <>
            {
                this.state.logged && <Navigate to='/marketplace' />
            }
            <div className="IdentityLayout">
                <div className="box large">
                    <h3>Register</h3>
                    <hr />
                    {
                        this.state.isRegistred ? <div>
                            The activation link has been sent to you via email, go to your email and click on it to continue.
                        </div> :
                            <>
                                <Row>
                                    <Col xs="12" lg="4" xxl="4">
                                        <Form.Group className="mb-3" controlId="formRegisterFirstName">
                                            <Form.Label>FirstName</Form.Label>
                                            <Form.Control type="text" placeholder="Enter your firstname" value={this.state.firstname} onChange={(e) => this.setState({ firstname: e.target.value })} />
                                        </Form.Group>
                                    </Col>

                                    <Col xs="12" lg="4" xxl="4">
                                        <Form.Group className="mb-3" controlId="formRegisterLastName">
                                            <Form.Label>LastName</Form.Label>
                                            <Form.Control type="text" placeholder="Enter your lastname" value={this.state.lastname} onChange={(e) => this.setState({ lastname: e.target.value })} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs="12" lg="4" xxl="4">
                                        <Form.Group className="mb-3" controlId="formRegisterEmail">
                                            <Form.Label>Email address</Form.Label>
                                            <Form.Control type="email" placeholder="Enter your email" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" xxl="4">
                                        <Form.Group className="mb-3" controlId="formRegisterPassword">
                                            <Form.Label>Password</Form.Label>

                                            <Form.Control type="password" placeholder="Enter your password" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} />
                                            {
                                                this.state.password.length >= 8 || this.state.password.length == 0 ? null : <Badge bg={'danger'}>Your password is not strong enough</Badge>
                                            }
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formRegisterPassword2">
                                            <Form.Label>Repeat Password</Form.Label>
                                            <Form.Control type="password" placeholder="Enter your password" value={this.state.repeatPassword} onChange={(e) => this.setState({ repeatPassword: e.target.value })} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs="12" xxl="8">
                                        <div className="mt-4">
                                            <CardView small card={{ bg: 'light', body: 'Passowrd should have at least one lower case, upper case, number, special character and minimum 8 characters' }} />
                                        </div>
                                    </Col>
                                </Row>
                                <Form onSubmit={this.register}>
                                    {
                                        this.state.loading ? <Button disabled>
                                            <Spinner animation="border" size="sm" />
                                        </Button> :
                                            <Button variant="primary" type="submit">
                                                Sign Up
                                            </Button>
                                    }
                                </Form>


                            </>
                    }
                </div>
                <div className="footer">
                    <NavLink to={'/login'}>You already have an existing account?</NavLink>
                </div>
            </div>
        </>

    }
}

interface State {
    firstname: string,
    lastname: string,
    email: string,
    password: string,
    repeatPassword: string,
    isRegistred: boolean,
    loading: boolean,
    logged: boolean,
}