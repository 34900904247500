import React from "react"
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import './Slider.scss'
import RedirectView from "../../../shared/RedirectView/RedirectView"
import VerticalCardViewComponent from "../../../shared/Cards/VerticalCardView/VerticalCardViewComponent"

interface ICardView {
    title: string,
    image: any,
    subTitle?: string
}
export default class SliderComponent extends React.PureComponent<{ title?: string, data: ICardView[], dir?: string, redirectButton?: boolean }> {
    render(): React.ReactNode {
        let width = window.innerWidth
        return <div className="mt-5">
            <div className="font-bold fs-3 pb-3 text-black">{this.props.title}</div>
            <div className={`swiper-background ${this.props.dir ?? 'ltr'}`}></div>
            <Swiper
                watchSlidesProgress={true}
                slidesPerView={width > 960 ? 5.7 : width < 960 && width > 640 ? 3.5 : 2.5}
                spaceBetween={20}
                navigation
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                scrollbar={{ draggable: true, }}
                className={`pb-4 pt-4 p-3`}
            >
                {
                    this.props.data.map((item, index) => {
                        return <SwiperSlide key={index}>
                            <VerticalCardViewComponent title={item.title} image={item.image} />
                        </SwiperSlide>
                    })
                }
                {
                    this.props.redirectButton &&
                    <SwiperSlide className="redirect-button web">
                        <RedirectView title="See more Gift Cards on API Gift just right now!"/>
                    </SwiperSlide>
                }
            </Swiper>
        </div>
    }
}