import React, { ContextType, FormEvent } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import authenticationContext from "../../../contexts/AuthenticationContext/AuthenticationContext";
import IdentityService from "../../../services/identity/IdentityService";
import TOTPForm from "../Security/TwoFactor/TOTPForm/TOTPForm";
import { toast } from 'react-toastify';
import { Navigate, NavLink } from "react-router-dom";
import '../IdentityLayout.scss'
export default class Login extends React.Component<{}, { username: string, password: string, activeOTPMode: boolean, loading: boolean, logged: boolean, hasAccessToStore: boolean }> {
    identityService: IdentityService;
    static contextType = authenticationContext;
    context!: React.ContextType<typeof authenticationContext>;
    /**
     *
     */
    constructor(props: any) {
        super(props);
        this.identityService = new IdentityService();
        this.state = { username: '', password: '', activeOTPMode: false, loading: false, logged: false, hasAccessToStore: true };

    }

    loginForm = (e: FormEvent<HTMLFormElement>, totp?: string | undefined) => {
        e.preventDefault();
        e.stopPropagation();
        this.login();
    }
    login = (otp?: string | undefined) => {
        this.setState({ loading: true });



        this.identityService.login(this.state.username, this.state.password, otp).then((response) => {

            if (response.data.accessToken === null && response.data.hasTwoFactor)
                this.openTotpForm();
            else
                this.logon(response.data.accessToken, response.data.hasAccessToStore);

        }).catch((error) => {

            if (error.message === 'Network Error') {
                return;
            }

            if (otp === undefined)
                toast.error('Invalid username or password');
            else
                toast.error('Invalid your OTP code');
        }).finally(() => {
            this.setState({ loading: false });
        });
    }
    logon = (token: string, hasAccessToStore: boolean) => {
        this.context.login(token);
        this.setState({ logged: true, hasAccessToStore });
    }
    openTotpForm = () => {
        this.context.twoFactorAvtive = true;
        this.setState({ activeOTPMode: true });
    }
    render(): React.ReactNode {
        return <div className="IdentityLayout">
            <div className="box sm">
                <h3>Login</h3>
                <hr />

                {
                    this.state.logged && (this.state.hasAccessToStore ? <Navigate to='/marketplace' /> : <Navigate to='/create-shop-only' />)
                }

                <Form onSubmit={this.loginForm}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email" placeholder="Enter email" value={this.state.username} onChange={(e) => this.setState({ username: e.target.value })} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} />
                    </Form.Group>

                    {
                        this.state.loading ? <Button variant="primary" disabled>
                            <Spinner animation="border" size="sm" />
                        </Button> : <Button variant="primary" type="submit">
                            Login
                        </Button>
                    }




                    <TOTPForm login={(token, hasAccessToStore) => { this.logon(token, hasAccessToStore) }} isLoginPage signInInformation={{ password: this.state.password, userName: this.state.username }} hasRecovery showModal={this.state.activeOTPMode} onSubmit={this.login} onHide={() => this.setState({ activeOTPMode: false })} loading={this.state.loading} />
                </Form>
            </div>
            <div className="footer">
                <NavLink to={'/signUp'}>Sign up</NavLink>
                <NavLink to={'/forgotPassword'}>Forgot Password?</NavLink>
            </div>
        </div>
    }
}