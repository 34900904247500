import { mapToDataOnly } from "../common/HttpUtility";
import { TwoFactorApiFactory, TwoFactorRecoveryApiFactory } from "../../generated-api";

export default class TwoFactorService {
    getToken(): Promise<string> {
        return mapToDataOnly<string>(TwoFactorApiFactory().identityTwoFactorGet());
    }

    verify(totp: string): Promise<IVerificationTwoFactorDTO> {
        return mapToDataOnly(TwoFactorApiFactory().identityTwoFactorEnablePut({ headers: { totp } }));
    }

    disable(totp: string): Promise<{}> {
        return mapToDataOnly(TwoFactorApiFactory().identityTwoFactorDisablePut({ headers: { totp } }));
    }

    signInWithRecoveryCode(dto: IRecoveryTwoFactorDTO, totpRecovery: string): Promise<IAccessTokenDTO> {
        return mapToDataOnly<IAccessTokenDTO>(TwoFactorRecoveryApiFactory().identityTwoFactorRecoverySignInPut(dto, { headers: { 'totp-recovery': totpRecovery } }));
    }

    disableWithRecoveryCode(totpRecovery: string): Promise<{}> {
        return mapToDataOnly(TwoFactorRecoveryApiFactory().identityTwoFactorRecoveryDisablePut({ headers: { 'totp-recovery': totpRecovery } }));
    }
}

interface IVerificationTwoFactorDTO {
    isVerified: boolean
    recoveryCodes: string[]
}

interface IRecoveryTwoFactorDTO {
    email: string,
    password: string,
    disableTwoFactor: boolean
}

interface IAccessTokenDTO {
    accessToken: string,
    refreshToken: string,
    expirationDate: Date,
    refreshExpirationDate: Date,
    hasTwoFactor: boolean
}