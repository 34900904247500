import React from "react";

import { Card, Col, Placeholder, Row } from "react-bootstrap";

import { IArticleDTO } from "../../../../services/helpCenter/ArticleService";
import GridArticleItem from "./GridArticleItem/GridArticleItem";

interface IProps {
    items: IArticleDTO[],
    isLoading: boolean,
    generalProductImage?: string
    onClickRow?: (articleId: string) => void
}

export default class GridArticle extends React.Component<IProps> {
    constructor(props: any) {
        super(props);
    }

    render(): React.ReactNode {
        return this.props.isLoading ?
            <Row>
                {
                    Array.from(Array(10), (e, i) => <Col  xs="12" md="6" lg="4" xl="3" xxl="3"><div className={"ProductCatalogItem"}
                        key={'ProductCatalogItem' + i}>
                        <Placeholder animation="glow" className="article-image">
                            <Placeholder xs={12} style={{ height: '200px', borderRadius: '8px 8px 0 0' }} />
                        </Placeholder>
                        <div className="m-3 pb-3">
                            <Placeholder animation="glow">
                               <Placeholder xs={4} size='lg' />
                            </Placeholder>
                            <Placeholder as={Card.Text} animation="glow">
                               <Placeholder xs={10} />
                            </Placeholder>
                        </div>
                           
                    </div></Col>)
                }
            </Row>
            :
            <>
                <Row>
                    {
                        this.props.items.map((item) => <Col xs="12" md="6" lg="4" xl="3" xxl="3">
                            <GridArticleItem
                                title={item.title}
                                imageSrc={this.props.generalProductImage}
                                imagePath={item.imageSrc}
                                articleId={item.id}
                                details={item.details}
                            />
                        </Col>)
                    }
                </Row>
            </>
    }
}