import React from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap'
import IState, { IProps } from './AdminSupportTicketDepartementCreate.models';
import AdminSupportTicketDepartementService from '../../../../../services/admin/Support/AdminSupportTicketDepartementService';
import { toast } from 'react-toastify';

export default class AdminSupportTicketDepartementCreate extends React.Component<IProps, IState> {
    adminSupportTicketDepartementService: AdminSupportTicketDepartementService

    constructor(props: any) {
        super(props)
        this.adminSupportTicketDepartementService = new AdminSupportTicketDepartementService()
        this.state = { isActive: false, title: '', pendingRequest: false }
    }

    componentDidMount(): void {
        if (this.props.id) {
            this.adminSupportTicketDepartementService.getById(this.props.id).then(response => {
                this.setState({ title: response.title, order: response.orderID, isActive: response.isActive })
            })
        }
    }

    requestToCreate = (body) => {
        this.adminSupportTicketDepartementService.create(body).then(() => {
            toast.success('Departement created successfullly')
            this.props.reload()
            this.props.onClose()
        }).finally(() => this.setState({ pendingRequest: false }))
    }

    requestToUpdate = (body) => {
        this.adminSupportTicketDepartementService.update(this.props.id, body).then(() => {
            toast.success('Departement updated successfullly')
            this.props.reload()
            this.props.onClose()
        }).finally(() => this.setState({ pendingRequest: false }))
    }

    submit = () => {
        this.setState({ pendingRequest: true })

        let body = {
            title: this.state.title,
            isActive: this.state.isActive,
            orderID: this.state.order,
        }

        if (this.props.id) this.requestToUpdate(body)
        else this.requestToCreate(body)
    }

    render(): React.ReactNode {
        const { } = this.state

        return <>
            <Modal size="lg"
                show={true}
                backdrop="static"
                onHide={this.props.onClose}
            >
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.id ? 'Update Departement' : 'Create Departement'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md="6" sm="12">
                                <Form.Label> Title: </Form.Label>
                                <Form.Control placeholder="Enter title"
                                    value={this.state.title}
                                    onChange={(e) => this.setState({ title: e.target.value })} />
                            </Col>
                            <Col md="6" sm="12">
                                <Form.Label> Order: </Form.Label>
                                <Form.Control type="number" placeholder="Enter order"
                                    value={this.state.order}
                                    onChange={(e) => this.setState({ order: Number(e.target.value) })} />
                            </Col>
                            <Col md="12" sm="12">
                                <Form.Check className='mt-4' type="checkbox" label="Active"
                                    checked={this.state.isActive}
                                    onChange={(e) => this.setState({ isActive: e.target.checked })} />
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        {this.state.pendingRequest ? <Button variant='success' disabled><Spinner animation='border' size='sm' /></Button> :
                            <Button variant='success' onClick={this.submit}>{this.props.id ? 'Update' : 'Create'}</Button>}
                    </Modal.Footer>
                </Form>
            </Modal >
        </>
    }
}
