import React from "react"
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap"
import { toast } from "react-toastify"
import FinancialManagementService from "../../../../services/financials/FinancialManagementService"
import IState, { IProps } from './CreateFinancialAccountModal.models'

export default class CreateFinancialAccountModal extends React.PureComponent<IProps, IState> {
    financialManagementService: FinancialManagementService
    constructor(props: any) {
        super(props)
        this.financialManagementService = new FinancialManagementService()
        this.state = { accountIdentity: '', financialAccountTypeId: '', nickName: '', accountTypes: [], isLoading: true }
    }

    componentDidMount() {
        this.financialManagementService.getTypesTitleValues().then((response) => {
            this.setState({ accountTypes: response, financialAccountTypeId: response[0].value, isLoading: false })
        })

        if (this.props.financialAccountId !== '' && this.props.financialAccountId !== undefined && this.props.financialAccountId !== null) this.getData()
    }

    getData = () => {
        if (this.props.financialAccountId)
            this.financialManagementService.getById(this.props.financialAccountId).then((response) => {
                this.setState({ nickName: response.nickName, accountIdentity: response.accountIdentity })
            })
    }

    submit = () => {
        this.setState({ isLoading: true })

        if (this.props.financialAccountId !== undefined) {
            this.financialManagementService.update(this.props.financialAccountId, { nickName: this.state.nickName }).then(() => {
                toast.success('Financial Account updated successfully')
                this.setState({ isLoading: false })
                this.props.onComplete()
            })
        } else {
            this.financialManagementService.create({ accountIdentity: this.state.accountIdentity, financialAccountTypeId: this.state.financialAccountTypeId, nickName: this.state.nickName }).then(() => {
                toast.success('Financial Account created successfully')
                this.setState({ isLoading: false })
                this.props.onComplete()
            })
        }
    }

    render(): React.ReactNode {
        const { financialAccountTypeId, accountIdentity, nickName, accountTypes, isLoading } = this.state
        return <Modal size="sm"
            show={true}
            onHide={this.props.onCancel}
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>{this.props.financialAccountId !== undefined ? 'Update Account' : 'Create Account'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Row>
                        <Col md="12" sm="12">
                            <Form.Label>Nick Name</Form.Label>
                            <Form.Control aria-label="Enter Nick Name"
                                disabled={this.state.isLoading}
                                value={nickName}
                                onChange={(e) => this.setState({ nickName: e.target.value })} />
                        </Col>
                        {
                            this.props.financialAccountId === undefined && <Col md="12" sm="12">
                                <Form.Label>Wallet Address</Form.Label>
                                <Form.Control aria-label="Enter Wallet Address"
                                disabled={this.state.isLoading}
                                value={accountIdentity} onChange={(e) => this.setState({ accountIdentity: e.target.value })} />
                            </Col>
                        }
                        {
                            this.props.financialAccountId === undefined && <Col md="12" sm="12">
                                <Form.Label>Financial Account Type</Form.Label>
                                <Form.Select aria-label=">Financial Account Type" disabled={this.state.isLoading} value={financialAccountTypeId} onChange={(e) => this.setState({ nickName: e.target.value })} >
                                    {
                                        accountTypes.map((item) => <option value={item.value} key={item.value}>{item.title}</option>)
                                    }
                                </Form.Select>
                            </Col>
                        }


                    </Row>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {isLoading ? <Button variant='success' disabled><Spinner animation='border' /></Button> :
                    <Button variant='success' onClick={this.submit}>Submit</Button>}
            </Modal.Footer>
        </Modal>
    }
}