import React, { FormEvent } from 'react';
import { Alert, Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap'
import { NavLink, Navigate } from 'react-router-dom';
import { toast } from 'react-toastify'
import CashOutsService from '../../../../services/financials/CashOutsService'
import FinancialAccountService from '../../../../services/financials/FinancialAccountService'
import WalletService from '../../../../services/financials/WalletService'
import InventorySourceService from '../../../../services/inventory/InventorySourceService'
import Amount from '../../../utilities/Amount/Amount';
import IState, { IProps } from './CreateCashOut.models'
import ValidAmount from '../../../utilities/Amount/ValidAmount';
import InventorySourceTransactionService from '../../../../services/inventory/InventorySourceTransactionService';

export default class CreateCashOut extends React.Component<IProps, IState> {
    financialAccountService: FinancialAccountService
    inventorySourceService: InventorySourceService
    inventorySourceTransactionService: InventorySourceTransactionService
    walletService: WalletService
    cashOutsService: CashOutsService
    USDTCurrencyId: string = 'b8b8eaaa-c421-4783-b481-ae4fea6024de';
    constructor(props: any) {
        super(props)
        this.financialAccountService = new FinancialAccountService()
        this.inventorySourceService = new InventorySourceService()
        this.inventorySourceTransactionService = new InventorySourceTransactionService()
        this.cashOutsService = new CashOutsService()
        this.walletService = new WalletService()
        this.state = {
            isLoading: true, amount: '0', withdrawCredit: 0, withdrawFee: 0, pendingRequest: false,
            hasInventorySourceTransaction: false, financialAccountId: '', inventorySourceId: '', walletBalance: 0, financialAccounts: [], inventorySources: []
        }
    }

    componentDidMount() {
        if (this.props.currencyId == this.USDTCurrencyId) {
            this.getData();
            this.getFee();
        }
    }

    getData = () => {
        this.financialAccountService.getTitleValueByWallet(this.props.walletId).then((response) => {
            this.walletService.getBalance(this.props.walletId).then((walletBalance) => {
                this.setState({
                    financialAccounts: response, walletBalance: walletBalance.balance,
                    withdrawCredit: walletBalance.withdrawCredit,
                    //withdrawCredit: 30000,
                    isLoading: false
                })
                if (response.length > 0)
                    this.setState({ financialAccountId: response[0].value });
            })
        })

        this.setInventoryTitleValues()
    }

    getFee = () => {
        this.cashOutsService.getFee().then((response) => {
            this.setState({ withdrawFee: response.value })
        })
    }

    setInventoryTitleValues = () => {
        this.inventorySourceService.getTitleValues().then((response) => {
            this.setState({ inventorySources: response, inventorySourceId: response[0].value })
        })
    }


    submit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();

        let { inventorySourceId } = this.state
        this.setState({ pendingRequest: true })
        var request: any = {
            amount: Number(this.state.amount),
            financialAccountId: this.state.financialAccountId,
            description: ''
        };
        if (this.state.hasInventorySourceTransaction)
            request.inventorySourceId = this.state.inventorySourceId;

        this.cashOutsService.create(request).then(() => {
            if (this.state.hasInventorySourceTransaction) {
                this.inventorySourceTransactionService
                .create({
                    transactionAt: new Date(), isToSource: true, inventorySourceId, description: "Cashout Inventory Source Transaction",
                    shopAmountValue: request.amount, sourceAmountValue: request.amount, sourceCurrencyId: this.USDTCurrencyId, shopCurrencyId: this.USDTCurrencyId
                })
            }
            
            toast.success('Your cashout requested')
            this.setState({ redirectToList: true })
        }).catch(() => {
            toast.error('Your wallet not enough funds to this request.')
        }).finally(() => {
            this.setState({ pendingRequest: false })
        })
    }


    balanceCheck = () => {
        let { withdrawFee } = this.state
        if (this.state.isLoading)
            return true;

        return this.state.walletBalance + (this.state.withdrawCredit ?? 0) >= Number(this.state.amount) + withdrawFee
    }

    render(): React.ReactNode {
        const { financialAccounts, inventorySources, walletBalance, withdrawCredit, hasInventorySourceTransaction, withdrawFee } = this.state
        let widthrawAmount = (withdrawCredit + walletBalance) - withdrawFee;
        widthrawAmount = widthrawAmount > 0 ? widthrawAmount : 0;

        return <>
            {this.state.redirectToList === true && <Navigate to={'?activeTab=withdraw'} />}
            <Modal size="lg"
                show={true}
                backdrop="static"
                onHide={this.props.onClose}
            >
                <Form onSubmit={this.submit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Create Withdraw</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.currencyId == this.USDTCurrencyId ?
                            <>
                                {
                                    this.state.isLoading ?
                                        <Spinner animation="grow" /> :
                                        this.state.financialAccounts.length == 0 ?
                                            <Alert variant='warning'>
                                                To create a withdrawal request from a wallet, you must first create an external wallet.
                                                <NavLink to={"/financials?activeTab=externalWallets"}>
                                                    <Button variant="warning" className='ms-1' size="sm">External Wallet</Button>
                                                </NavLink>
                                            </Alert> :
                                            <>
                                                <Alert variant='warning'>
                                                    A ${withdrawFee} fee will be added to all withdrawals because the TRC20 network fee has increased.
                                                    this is a normal issue in blockchain , of course.
                                                </Alert>
                                                {
                                                    !this.state.isLoading &&

                                                    <Row>
                                                        <Col md="12" sm="12">
                                                            <span> Wallet Balance: <Amount value={walletBalance} floor />$</span>
                                                        </Col>
                                                        <Col md="12" sm="12">
                                                            <span> Withdrawable amount: <Amount value={(withdrawCredit + walletBalance) - withdrawFee} />$</span>
                                                        </Col>
                                                        {withdrawCredit > 0 ? <Col md="12" sm="12">
                                                            <span> Withdraw credit: <Amount value={withdrawCredit} />$</span>
                                                        </Col> : <></>}

                                                        <Col md="12" sm="12">
                                                            <span> Network Fee(TRC20): <Amount value={withdrawFee} />$</span>
                                                        </Col>
                                                        <Col md="12" sm="12">
                                                            <Form.Group className="mb-2">
                                                                <Form.Label>Withdraw amount:
                                                                    <Button variant="link" size='sm' onClick={() => this.setState({ amount: String(widthrawAmount) })}>MAX</Button>
                                                                </Form.Label>
                                                                <Form.Control type="text"
                                                                    inputMode='decimal' placeholder="Enter cash out amount"
                                                                    value={this.state.amount}
                                                                    onChange={(e) => ValidAmount(e.target.value) && this.setState({ amount: e.target.value })} />
                                                            </Form.Group>
                                                            {this.balanceCheck() === false &&
                                                                <Alert variant='danger'>
                                                                    Note that ${withdrawFee} is the transfer fee and you can withdraw a maximum of ${this.state.walletBalance - withdrawFee}.
                                                                </Alert>
                                                            }
                                                        </Col>
                                                        <Col md="12" sm="12">
                                                            <Form.Group className="mb-2">
                                                                <Form.Label>Financial account:</Form.Label>
                                                                <Form.Select aria-label=">Select financial account"
                                                                    onChange={(e) => this.setState({ financialAccountId: e.target.value })}>
                                                                    {
                                                                        financialAccounts.map((financialAccount) => <option value={financialAccount.value} key={financialAccount.value}>{financialAccount.title}</option>)
                                                                    }
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md="12" sm="12">
                                                            <Form.Check type="checkbox" label="Has Inventory Source Transaction"
                                                                checked={hasInventorySourceTransaction}
                                                                onChange={(e) => this.setState({ hasInventorySourceTransaction: e.target.checked })} />
                                                        </Col>
                                                        {
                                                            hasInventorySourceTransaction &&
                                                            <Col md="12" sm="12">
                                                                <Form.Group className="mb-2">
                                                                    <Form.Label>Inventory Source:</Form.Label>
                                                                    {
                                                                        inventorySources.length > 0 &&
                                                                        <Form.Select aria-label=">Select financial account"
                                                                            onChange={(e) => this.setState({ inventorySourceId: e.target.value })}>
                                                                            {
                                                                                inventorySources.map((inventorySource) => <option value={inventorySource.value} key={inventorySource.value}>{inventorySource.title}</option>)
                                                                            }
                                                                        </Form.Select>
                                                                    }
                                                                </Form.Group>
                                                            </Col>
                                                        }
                                                    </Row>
                                                }
                                            </>
                                }
                            </> : <Alert variant='warning'>The currency in this wallet cannot be withdrawn directly, in order to withdraw it, convert it to another currency that can be withdrawn (USDT is recommended).</Alert>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        {this.state.pendingRequest ? <Button variant='success' disabled><Spinner animation='border' size='sm' /></Button> :
                            (<Button variant='success' type="submit" disabled={this.state.financialAccounts.length == 0}>Create</Button>)}
                    </Modal.Footer>
                </Form>
            </Modal >
        </>
    }
}
