import React from 'react';
import { Button, Form, Spinner, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { LocalCurrencyService } from '../../../../services/financials/LocalCurrencyService';
import Note from '../../../shared/Note/Note';
import IState, { IProps } from './SetLocalCurrencyModal.models';

export default class SetLocalCurrencyModal extends React.Component<IProps, IState> {
    localCurrencyService: LocalCurrencyService;
    constructor(props: any) {
        super(props)
        this.state = { pendingRequest: false, currencies: [], currencyId: '' }
        this.localCurrencyService = new LocalCurrencyService()
    }

    componentDidMount() {
        this.getCurrencyTitleValues();
    }

    getCurrencyTitleValues = () => {
        this.localCurrencyService.getTitleValues().then((response) => {
            this.setState({ currencies: response, currencyId: this.props.currentCurrencyId ?? response[0].value })
        })
    }

    submit = () => {
        this.setState({ pendingRequest: true })
        this.localCurrencyService.setLocalCurrency(this.props.shopId, this.state.currencyId).then(() => {
            toast.success('Your shop Local currency updated successfully.')
            this.props.onSubmit && this.props.onSubmit()
        }).finally(() => {
            this.setState({ pendingRequest: false })
        }).catch(() => {
            toast.error('An error has occurred.')
        })
    }

    render(): React.ReactNode {
        const { pendingRequest, currencyId, currencies } = this.state

        return <div>
            <h5>{this.props.currentCurrencyId ? 'Edit Local Currency' : 'Set Local Currency'}</h5>
            <Note>Choose your local currency and make your financial calculations more accurate.</Note>
            <hr />
            <Row>
                <Col md="4" sm="12">
                    <Form.Select aria-label=">Select Local currency" id="currencyId" value={currencyId}
                        onChange={(e) => this.setState({ currencyId: e.target.value })}>
                        {
                            currencies.map((item) => <option value={item.value} key={item.value}>{item.title}</option>)
                        }
                    </Form.Select>
                    <Note>If your local currency is USD or you don't want to use local currency now then set your local currency on USD</Note>
                    {pendingRequest ? <Button variant={this.props.currentCurrencyId ? 'warning' : 'success'} size="sm" className="mt-4" disabled><Spinner size='sm' animation='border' /></Button> :
                        <Button variant={this.props.currentCurrencyId ? 'warning' : 'success'} onClick={this.submit} className="mt-4">{this.props.currentCurrencyId ? 'Edit' : 'Set Local Currency'}</Button>}
                </Col>
            </Row>
        </div>
    }
}