import axios from "axios";
import { toast } from "react-toastify";
var lastConnectionErrorTime: Date = undefined;

export function AxiosConfig(baseUrl: string) {


    let token = localStorage.getItem('token');
    if (token !== undefined && token !== null && token !== '')
        axios.defaults.headers.common['authorization'] = `Bearer ${token}`;


    let activeshop = localStorage.getItem('activeshop');
    if (activeshop !== undefined && activeshop !== null && activeshop !== '') {
        axios.defaults.headers.common['activeshop'] = `${activeshop}`;
        axios.defaults.headers.common['ownerId'] = `${activeshop}`;
    }


    // axios.defaults.baseURL = baseUrl

    // Add a request interceptor
    axios.interceptors.request.use(function (config) {
        // Do something before request is sent
        return config;
    }, function (error) {
        // Do something with request error
        console.log(error)
        return Promise.reject(error);
    });

    // Add a response interceptor
    axios.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    }, function (error) {
        if (!error.request.responseURL) {
            if (lastConnectionErrorTime === undefined || (new Date().getTime() - lastConnectionErrorTime.getTime()) > 5000) {
                lastConnectionErrorTime = new Date();
                toast.error('Server connection problem , please check internet')
            }
            return Promise.reject(error);
        }

        let url: string = error.request.responseURL;

        if (error.response.status === 401 && url.replace(axios.defaults.baseURL as string, '').indexOf('/Buyer/Order/Details/Full') == -1 && url.indexOf('Identity/Account') == -1) {
            localStorage.removeItem('token');
            window.location.href = '/login';
        }
        return Promise.reject(error);
    });
}