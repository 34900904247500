import { Card } from "react-bootstrap"
import { IProps } from './CardView.models'

export default function CardView(props: IProps) {
    return <>
        <Card bg={props.card.bg}>
            {props.card.header && <Card.Header>
                {props.card.header}
                <div>
                    {props.renderHeader}
                </div>
            </Card.Header>}
            <Card.Body>
                {props.card.body && <Card.Text>{
                    props.small ? <small className="text-muted">{props.card.body}</small> : props.card.body
                }</Card.Text>}
                {props.children && props.children}
            </Card.Body>
        </Card>
    </>
}