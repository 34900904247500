import React from "react";
import { NavLink } from "react-router-dom";
import './GridArticleItem.scss'

export interface ArticleItemView {
    title: string
    currencyId: string
    imageSrc: string | null
    imagePath: string | null
    articleId: string
}

export interface GridCatalogItemProps {
    title: string
    imageSrc: string | null
    imagePath?: string | null
    articleId: string
    details: string
}

export default class GridArticleItem extends React.Component<GridCatalogItemProps, ArticleItemView> {
    constructor(props: any) {
        super(props);
        this.state = {
            currencyId: '', imageSrc: null, articleId: '', title: '', imagePath: null,
        };
    }

    render(): React.ReactNode {
        let item = this.props;

        return <div className="article-item"
            key={item.articleId}>
            <NavLink to={`/article-details/?articleId=${item.articleId}`}><img src={item.imagePath == null ? './images/product-none.png' : item.imagePath} className="article-image" /></NavLink>
            <div className="article-title">{item.title}</div>
            <div className="article-description">
              <div className="details-text" dangerouslySetInnerHTML={{ __html: item.details?.length > 25 ? item.details.substring(0, 25) + '...' : item.details }}></div>
            </div>
        </div>
    }
}