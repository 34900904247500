import React from 'react';
import deliveryTimeDisplayBadge, { deliveryTimeColor, deliveryTimeDisplayCaption } from '../../orders/Enums/DeliveryTimeEnum';
import './ProductCatalog.scss'
import { DashCircleFill, Infinity, PlusCircleFill, QuestionCircleFill, Trash } from "react-bootstrap-icons";
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { InventoryShopPlanDeliveryEnum } from '../../inventory/Enums/InventoryShopPlanDeliveryEnum';
import axios from 'axios';

interface Props {
    delayDescription: string | null
    isYourShop: boolean
    inventoryCount: number | null
    plans: [
        {
            countFrom: number,
            countTo: number,
            price: number,
            discount: number
        }]
    sellerShopId: string
    title: string
    totalSoldCount: string
    productSoldCount: string
    onChangeCount: (count: number) => void
}

export default class ProductSellerCatalog extends React.Component<Props, { count: number }> {
    constructor(props: any) {
        super(props);

        this.state = { count: 0 };
    }

    plusCount = () => {
        if (this.props.inventoryCount != null && this.state.count >= this.props.inventoryCount)
            return;
        var count = this.state.count + 1;
        this.setState({ count })
        this.props.onChangeCount(count);
    }
    minusCount = () => {
        if (this.state.count === 0)
            return;
        var count = this.state.count - 1;
        this.setState({ count })
        this.props.onChangeCount(count);
    }

    changeCount = (count: number) => {
        if (count < 0)
            count = 0;
        if (this.props.inventoryCount != null && count >= this.props.inventoryCount)
            count = this.props.inventoryCount;
        this.setState({ count })
        this.props.onChangeCount(count);
    }


    deliveryTimeView(delayDescription: string) {
        return <>
            <OverlayTrigger
                key="top"
                placement="top"
                overlay={
                    <Tooltip id="tooltip-top">
                        This seller can make the product available up to the maximum time specified.
                        Create a offer to buy!
                    </Tooltip>
                }
            >
                <span>
                    <Badge bg='warning'>Offer <QuestionCircleFill /></Badge>
                    <span className='ml-1'> {delayDescription}</span>
                </span>
            </OverlayTrigger>
        </>
    }

    soldCountView() {
        if (this.props.isYourShop)
            return null
        return <div style={{ fontSize: '11px' }}>Sold Count <div>All Product: {this.props.totalSoldCount}</div> <div>This Product: {this.props.productSoldCount}</div></div>
    }

    render(): React.ReactNode {

        const isViewerShop = this.props.isYourShop;
        const seller = this.props;
        const plans = seller.plans;
        const styleTdPerSller = { borderBottom: '1px solid black', background: isViewerShop ? '#a7ccb7' : 'none' };

        var emptyPlan = [];
        for (var i = 0; i < (isViewerShop || (seller.inventoryCount === 0 && seller.inventoryCount != null) ? 6 : 4) - (plans ? plans.length : 0); i++)
            emptyPlan.push({});

        var inventoryStatus = <span>Variable</span>;
        if (seller.inventoryCount === 0)
            inventoryStatus = <span>{this.deliveryTimeView(seller.delayDescription)}</span>;
        else if (seller.inventoryCount !== null)
            inventoryStatus = <span>{seller.inventoryCount}</span>;


        return <>
            <tr>
                <td rowSpan={2} style={{ ...styleTdPerSller, textAlign: 'left', color: isViewerShop ? '#198754' : 'none' }}>{seller.title} {isViewerShop && "(Your shop)"}{this.soldCountView()}</td>
                <td rowSpan={2} style={{ ...styleTdPerSller, color: seller.inventoryCount > 0 || seller.inventoryCount === null ? 'green' : 'orange' }}>{inventoryStatus}</td>
                {
                    plans && plans.map((plan) => <th style={{ background: styleTdPerSller.background }} className={this.state.count >= plan.countFrom && this.state.count <= plan.countTo && 'selected-plan'}>{plan.countFrom} - {plan.countTo}</th>)
                }
                {
                    emptyPlan.map(() => <th style={{ background: styleTdPerSller.background }} ></th>)
                }
                {
                    !isViewerShop && (seller.inventoryCount > 0 || seller.inventoryCount === null) && <td rowSpan={2} style={{ ...styleTdPerSller, width: '20%' }} className="action-area">
                        <DashCircleFill onClick={() => this.minusCount()} className={`red ${this.state.count == 0 && 'disable'} `} />
                        <input type="number" value={this.state.count} onChange={(e) => this.changeCount(parseInt(e.target.value))} />
                        <PlusCircleFill onClick={() => this.plusCount()} className={this.state.count >= this.props.inventoryCount && this.props.inventoryCount !== null && 'disable'} />
                    </td>
                }

            </tr>
            <tr style={styleTdPerSller}>
                {
                    plans && plans.map((plan) => <td className={this.state.count >= plan.countFrom && this.state.count <= plan.countTo && 'selected-plan'}>
                        {plan.discount > 0 ? <>
                            <s>{plan.price + plan.discount}$</s><br />
                            <span>{plan.price}$</span>
                        </> : <span>{plan.price}$</span>}

                    </td>)
                }
                {
                    emptyPlan.map(() => <td></td>)
                }
            </tr>
        </>
    }
}