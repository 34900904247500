import React, { RefObject } from "react"
import AdminSupportTicketsService from "../../../../services/admin/Support/AdminSupportTicketsService"
import IState, { IProps } from "./AdminSupportTicketDetails.models"
import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap"
import { HandThumbsDownFill, HandThumbsUpFill, ShieldFillCheck } from "react-bootstrap-icons"
import supportTicketStatusDisplayBadge, { SupportTicketStatus, supportTicketStatusDisplayCaption } from "../../../helpCenter/SupportTickets/SupportTicketStatusEnum"
import TicketView from "../../../helpCenter/Common/TicketView/TicketView"
import { toast } from "react-toastify"
import HybridMoment from "../../../utilities/HybridMoment"
import OrdersService from "../../../../services/orders/OrdersService"
import AdminReviewRequestService from "../../../../services/admin/review-request/AdminReviewRequestService"
import MakeDecisionModal from "../Mediation/AdminMakeDecisionModal"
import AdminMediationService from "../../../../services/admin/orders/AdminMediationService"
import SupportTicketAddReply from "../../../helpCenter/SupportTickets/Details/AddReplay/SupportTicketAddReply"
import TicketCardView from "../../../helpCenter/Common/TicketCardView/TicketCardView"

export default class AdminSupportTicketDetails extends React.Component<IProps, IState> {
    ticketService: AdminSupportTicketsService
    mediationService: AdminMediationService
    ticketView: RefObject<TicketView>
    ordersService: OrdersService
    reviewRequestService: AdminReviewRequestService

    constructor(props: any) {
        super(props)
        this.state = { loading: false, pendingRequest: false, isOpenMakeDecisionModal: false, isMediated: false, replyTo: 'Seller' }

        this.ticketView = React.createRef();
        this.ticketService = new AdminSupportTicketsService()
        this.ordersService = new OrdersService()
        this.reviewRequestService = new AdminReviewRequestService()
        this.mediationService = new AdminMediationService()
    }


    componentDidMount(): void {
        this.getDetails()
    }

    getDetails = () => {
        this.setState({ loading: true })

        this.ticketService.getById(this.props.ticketId).then(response => {
            this.setState({ details: response })

            if (response.mediationRequestDate != null)
                this.mediationService.getDetails(this.props.ticketId, response.orderProductId).then(response => {
                    if (response.decision != null) this.setState({ isMediated: true })
                })

            this.ordersService.getStatistics(response.fromId).then((statistics) => {
                this.setState({ fromShopStatistics: statistics })
            })

            if (response.toId)
                this.ordersService.getStatistics(response.toId).then((statistics) => {
                    this.setState({ toShopStatistics: statistics })
                })
        }).finally(() => this.setState({ loading: false }))
    }

    addComment = (text: string): Promise<void> => {
        return this.ticketService.createComment({ commentText: text, supportTicketId: this.props.ticketId }).then(() => {
            this.getDetails()
        });
    }

    addReply = (text: string, attachmentNames?: string[]): Promise<void> => {
        return this.ticketService.createReply({ replyText: text, supportTicketId: this.props.ticketId, attachmentNames, sellerTarget: this.state.replyTo == "Seller" }).then(() => {
            this.getDetails()
        });
    }

    updateTicketStatus = (status: SupportTicketStatus) => {
        this.ticketService.updateStatus(this.props.ticketId, status).then(() => {
            toast.success(`Ticket status updated to ${supportTicketStatusDisplayCaption(status)} successfully.`)
            this.getDetails()
        })
    }

    releaseSellerMoney = (id: string) => {
        if (window.confirm('are you sure from release Seller money?')) {
            this.setState({ pendingRequest: true })
            this.mediationService.releaseSellerMoney(id).then(() => {
                toast.success('The money was successfully released.')
            }).finally(() => this.setState({ pendingRequest: false }))
        }
    }

    releaseBuyerMoney = (id: string) => {
        if (window.confirm('are you sure from release Buyer money?')) {
            this.setState({ pendingRequest: true })
            this.mediationService.releaseBuyerMoney(id).then(() => {
                toast.success('The money was successfully released.')
            }).finally(() => this.setState({ pendingRequest: false }))
        }
    }

    satisfactionLevelView = (isGood: boolean) => {
        switch (isGood) {
            case true:
                return <HandThumbsUpFill className="text-success" />
            case false:
                return <HandThumbsDownFill className="text-danger" />
            default:
                return <span>User didn't vote for like/dislike.</span>
        }
    }
    informationToArray(information): string[] {
        return JSON.parse(information);
    }
    informationDetailsView = () => {
        return <Card>
            <Card.Header>Inventory Information</Card.Header>
            <Card.Body>
                <Card.Text>
                    {
                        this.state.details.inventoryInformation && this.informationToArray(this.state.details.inventoryInformation).map((info, index) => <div key={index}><label>Code:</label> <b>{info}</b></div>)
                    }
                    <div><label>Product:</label>  <b>{this.state.details.title}</b></div>
                    <div><label>Enter at:</label>  <b>{HybridMoment(this.state.details.inventoryEnterAt)}</b></div>
                    <div><label>View by Buyer at:</label>  <b>{HybridMoment(this.state.details.viewByBuyerAt)}</b></div>
                </Card.Text>
            </Card.Body>
        </Card>
    }

    fromShopRelativeStatisticsView = () => {
        return <Card>
            <Card.Header>Seller Statistics - <b>{this.state.details.to}</b></Card.Header>
            <Card.Body>
                <Row style={{ color: 'green' }}>
                    <Col md="6" sm="6">
                        <span>Sale Order Count:</span>
                        <b>
                            {
                                this.state.toShopStatistics.saleOrderItemCount
                            }
                        </b>
                    </Col>
                    <Col md="6" sm="6">
                        <span>Sale Review Request Percent:</span>
                        <b>
                            {
                                this.state.toShopStatistics.saleReviewRequestPercent
                            }%
                        </b>
                    </Col>
                </Row>
                <Row>
                    <Col md="6" sm="6">
                        <span>Buy Order Count:</span>
                        <b>
                            {
                                this.state.toShopStatistics.buyOrderItemCount
                            }
                        </b>
                    </Col>
                    <Col md="6" sm="6">
                        <span>Buy Review Request Percent:</span>
                        <b>
                            {
                                this.state.toShopStatistics.buyReviewRequestPercent
                            }%
                        </b>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    }

    toShopRelativeStatisticsView = () => {
        return <Card>
            <Card.Header>Buyer Statistics - <b>{this.state.details.from}</b></Card.Header>
            <Card.Body>
                <Row>
                    <Col md="6" sm="6">
                        <span>Sale Order Count:</span>
                        <b>
                            {
                                this.state.fromShopStatistics.saleOrderItemCount
                            }
                        </b>
                    </Col>
                    <Col md="6" sm="6">
                        <span>Sale Review Request Percent:</span>
                        <b>
                            {
                                this.state.fromShopStatistics.saleReviewRequestPercent
                            }%
                        </b>
                    </Col>
                </Row>
                <Row style={{ color: 'green' }}>
                    <Col md="6" sm="6">
                        <span>Buy Order Count:</span>
                        <b>
                            {
                                this.state.fromShopStatistics.buyOrderItemCount
                            }
                        </b>
                    </Col>
                    <Col md="6" sm="6">
                        <span>Buy Review Request Percent:</span>
                        <b>
                            {
                                this.state.fromShopStatistics.buyReviewRequestPercent
                            }%
                        </b>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    }

    replyView = () => {
        let isMobile = window.innerWidth > 960 ? false : true
        const comments = this.state.details.replies;
        const shopId = undefined;
        const isCommentList = false;
        return <div className="ticket-view">
            <TicketCardView headerTitle={'Reply'} footerChildren={<>
                <SupportTicketAddReply hideUploader draftId={this.props.ticketId} draftText={false} onAddReply={this.addReply} />

                <div style={{ display: 'inline-block', marginTop: '10px' }}>

                    <Form.Group className="mb-3" controlId="replyto_input">
                        <Form.Label>Reply To (see by both):</Form.Label>
                        <Form.Select aria-label="Reply To (see by both):" id="replyto_input"
                            value={this.state.replyTo}
                            onChange={e => this.setState({ replyTo: e.target.value as any })}>
                            <option value={'Seller'} key={'Seller'}>Seller</option>
                            <option value={'Buyer'} key={'Buyer'}>Buyer</option>
                        </Form.Select>
                    </Form.Group>
                </div>
            </>}>
                {comments !== null && comments.length > 0 ?
                    comments.map((comment, index) => {
                        let textDocument;
                        let floatType = shopId === null || shopId === undefined ? null : comment.shopId === shopId;

                        if (comment.isSystemic === true) {

                            textDocument = <>
                                <div className="text-center systemic-text mt-2">{comment.description}</div>
                                <div style={{ clear: "both" }}></div>
                            </>;
                        }
                        else textDocument = <TicketCardView attachmentPaths={comment.attachmentPaths} width={(floatType != null && !isMobile) ? "45%" : "100%"} float={floatType} description={comment.description} createAt={comment.createAt} headerTitle={comment.userDisplayName} />;

                        return (

                            <span key={index}>
                                {textDocument}
                            </span>
                        )
                    }) : <span>No comments yet.</span>
                }
            </TicketCardView>
        </div>
    }

    render(): React.ReactNode {
        let { loading, details, isOpenMakeDecisionModal } = this.state

        return <div>
            {
                !loading && details !== undefined ?
                    <>
                        <Row>
                            <Col md="4" sm="12">
                                <h3>{details.title} - {details.ticketNumber}</h3>
                                {(details.mediationRequestDate !== null && details.mediationRequestDate !== undefined && details.isMediationDone !== true) && <span style={{ color: "green", display: 'block' }}>
                                    This ticket is under mediation <ShieldFillCheck />
                                </span>}
                            </Col>
                            <Col md="4" sm="12">
                                <span>Status: {supportTicketStatusDisplayBadge(details.status)}</span>
                            </Col>
                            <Col md="4" sm="12" className="text-end">
                                {
                                    details.status !== SupportTicketStatus.Pending &&
                                    <Button variant="outline-warning" className="me-2" onClick={() => this.updateTicketStatus(SupportTicketStatus.Pending)}>Pending</Button>
                                }
                                {
                                    details.status !== SupportTicketStatus.Closed &&
                                    <Button variant="outline-danger" className="me-2" onClick={() => this.updateTicketStatus(SupportTicketStatus.Closed)}>Close Ticket</Button>
                                }
                                {(details.mediationRequestDate != null && this.state.isMediated) && (
                                    this.state.pendingRequest ? <Button variant="outline-info" disabled><Spinner size="sm" animation='border' /></Button> :
                                        <Button variant="outline-info" className="me-2" onClick={() => this.releaseSellerMoney(this.state.details.orderProductId)}>Release Seller Money</Button>
                                )}
                                {(details.mediationRequestDate != null && this.state.isMediated) && (
                                    this.state.pendingRequest ? <Button variant="outline-info" disabled><Spinner size="sm" animation='border' /></Button> :
                                        <Button variant="outline-info" className="me-2" onClick={() => this.releaseBuyerMoney(this.state.details.orderProductId)}>Release Buyer Money</Button>
                                )}
                                {details.mediationRequestDate != null && !this.state.isMediated && (
                                    this.state.pendingRequest ? <Button variant="outline-primary" disabled><Spinner size="sm" animation='border' /></Button> :
                                        <Button variant="outline-primary" className="me-2" onClick={() => this.setState({ isOpenMakeDecisionModal: true })}>Make Decision</Button>
                                )}
                            </Col>
                        </Row>

                        <Row className="mt-2">
                            <Col md="4" sm="12">
                                <span>{this.satisfactionLevelView(details.isGood)}</span>
                            </Col>
                            <Col md="4" sm="12">
                                <span>Ticket Number: </span>
                                <b>{details.ticketNumber}</b>
                            </Col>
                            <Col md="4" sm="12">
                                <span>Created At: <b>{HybridMoment(details.createAt)}</b></span>
                            </Col>
                        </Row>
                        {
                            this.state.toShopStatistics && this.state.fromShopStatistics && <Row className="mt-2">
                                <Col md="6" sm="12">
                                    {this.fromShopRelativeStatisticsView()}
                                </Col>
                                <Col md="6" sm="12">
                                    {this.toShopRelativeStatisticsView()}
                                </Col>
                            </Row>
                        }
                        <Row className="mt-2">
                            <Col md="3" sm="12">
                                {this.informationDetailsView()}
                                <TicketView onHandleReply={this.addComment} title="Comment" hasEnableAddReply={details.status !== SupportTicketStatus.Closed} comments={details.comments} />
                            </Col>
                            <Col md="9" sm="12">
                                {
                                    details.replies && this.replyView()
                                }
                            </Col>
                        </Row>
                    </>
                    : <Spinner animation="grow" />
            }
            {
                isOpenMakeDecisionModal && <MakeDecisionModal onSubmit={() => this.setState({ isMediated: true })} alreadyDecided={this.state.isMediated}
                    sellPrice={details.sellPrice} orderProductId={details.orderProductId}
                    onClose={() => this.setState({ isOpenMakeDecisionModal: false })} ticketId={this.props.ticketId}
                    buyerId={details.fromId} sellerId={details.toId} />
            }
        </div>
    }
}
