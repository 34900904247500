import React from "react";
import { Alert, Button, Form, Spinner } from "react-bootstrap";
import { SelectionView } from "../../../../../services/common/Models";
import WalletService, { PaymentMethodDto } from "../../../../../services/financials/WalletService";
import ShopService from "../../../../../services/shops/ShopService";
import Amount from "../../../../utilities/Amount/Amount";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import './ChargePaymentForm.scss'
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { Navigate } from "react-router-dom";
import ValidAmount from "../../../../utilities/Amount/ValidAmount";
import { CheckLg, XLg } from "react-bootstrap-icons";
import DepositService from "../../../../../services/financials/DepositService";

export default class ChargePaymentForm extends React.Component<ChargePaymentFormProps, ChargePaymentFormState> {
    walletService: WalletService;
    depositService: DepositService;
    shopService: ShopService;
    tronscanAccountSymbol: string = 'USDT'
    constructor(props: any) {
        super(props);
        this.walletService = new WalletService();
        this.shopService = new ShopService();
        this.depositService = new DepositService();
        this.state = {
            selectionWallets: [], amountMatched: false, paymentMethod: this.props.paymentMethod, transactionNumber: '',
            transactionAt: new Date(), autoExchangeRequestWalletId: null, purchasePrice: '0', localCurrency: null, lastAverageOfPurchasePrice: null,
            pendingInquiry: false, tronScanVerified: null
        }
    }
    componentDidMount() {

        this.walletService.getWalletList({}, { pagesize: 100, pageskip: 1 }).then(response => {
            let wallets = response.rows.filter(wallet => wallet.currencyId !== this.props.currencyId)
            this.setState({
                selectionWallets: wallets.map(wallet => {
                    return { title: wallet.currencyTitle, value: wallet.id } as SelectionView;
                }), autoExchangeRequestWalletId: wallets[0].id
            });
        })

        this.shopService.getLocalCurrency().then((currency) => {
            this.setState({ localCurrency: currency.currencyTitle });
        });

        this.walletService.getLastAverage(this.props.currencyId).then((average) => {
            this.setState({ lastAverageOfPurchasePrice: average.lastAverage, purchasePrice: average.lastAverage == null ? '0' : average.lastAverage < 1000 ? String(average.lastAverage) : String(Math.round(average.lastAverage / 1000) * 1000) });
        });
        if (this.props.transactionNumber) this.onChangeTransactionNumber(this.props.transactionNumber)
    }

    clear = () => {
        this.setState({
            amountMatched: false, transactionNumber: '', transactionAt: new Date(), purchasePrice: null
        });
    }

    onChangeTransactionNumber = (hash: string) => {
        this.setState({ transactionNumber: hash })
        this.transactionInquiry(hash)
    }

    savePayment = () => {
        if (this.state.localCurrency !== null && this.state.localCurrency !== undefined)
            if (this.state.purchasePrice === null || Number(this.state.purchasePrice) === 0) return toast.error('Enter purchase price')

        if (this.state.paymentMethod.providerKey === 'TRON_SCAN') {
            let payment: any = {
                amount: this.props.amount,
                autoExchangeRequestWalletId: this.state.autoExchangeRequestWalletId,
                cashPaymentAccountId: this.state.paymentMethod.id,
                description: this.props.description,
                purchasePrice: Number(this.state.purchasePrice),
                transactionAt: this.state.transactionAt,
                transactionNumber: this.state.transactionNumber,
                financialAccountIdentity: this.state.financialAccountIdentity
            };
            this.walletService.createPayment(payment).then(() => {
                toast.success('Your payment saved successfully');
                this.clear();
                this.props.onPayment(true)
                return <Navigate to="/home" />
            }).catch(() => {
                toast.error('Your payment invalid');
            });
        } else {
            var purchasePrice = Number(this.state.purchasePrice);
            purchasePrice = purchasePrice > 0 ? purchasePrice : undefined;
            this.depositService.create({
                amount: this.props.amount,
                autoExchangeRequestWalletId: this.state.autoExchangeRequestWalletId,
                cashPaymentAccountId: this.state.paymentMethod.id,
                description: this.props.description,
                purchasePrice,
                refrenceNumber: this.state.transactionNumber,
                callbackUrl: `https://apigift.com/deposit/`
            }).then((response) => {
                window.location.replace(response.paymentUrl)
            })
        }
    }

    autoExchangeChanged = () => {
        this.setState({ autoExchangeRequestWalletId: this.state.autoExchangeRequestWalletId === null ? this.state.selectionWallets[0].value : null });
    }

    displayAlertOfPurchase = () => {
        let borderPercent = 10;
        let prev = this.state.lastAverageOfPurchasePrice as number;
        let current = Number(this.state.purchasePrice);
        let border = ((prev / 100) * borderPercent);
        let borderValueDown = prev - border;
        let borderValueUp = prev + border;
        let message = current > borderValueUp ? (`The difference between the entered number and the previous average is more than ${borderPercent}%`)
            : current < borderValueDown ? (`The difference between the entered number and the previous average is less than ${borderPercent}%`) : null;
        return message !== null && <Alert variant="danger" className="mt-1 mb-1">{message}</Alert>;
    }

    transactionInquiry = (hash: string) => {
        if (hash == "") {
            this.props.onInquiry(false, hash)
            return this.setState({ inquiryErrorMessage: undefined, tronScanVerified: null })
        }

        this.setState({ pendingInquiry: true })
        this.depositService.inquiry(hash)
            .then((response) => {
                let tronscanDetails = response.details
                let isVerifyTransaction: boolean = response.details != null && !response.isExisting && tronscanDetails.isConfirm
                    && (tronscanDetails.toWalletAddress == this.props.walletAddress)
                    && (tronscanDetails.token == this.tronscanAccountSymbol)

                let inquiryErrorMessage = response.isExisting ? 'The deposit has already been registered' :
                    !isVerifyTransaction ? 'The transaction number was not found in the blockchain network' : undefined

                if (tronscanDetails && tronscanDetails?.token != this.tronscanAccountSymbol) inquiryErrorMessage = 'The currency does not match the currency in the blockchain network'

                if (isVerifyTransaction) {
                    let amountMatched: boolean = tronscanDetails.amount == this.props.amount
                    inquiryErrorMessage = amountMatched ? inquiryErrorMessage : 'The amount entered does not match the amount in the blockchain network'
                    this.props.onInquiry(amountMatched && isVerifyTransaction, hash)
                    this.setState({
                        amountMatched,
                        inquiryErrorMessage,
                        amount: tronscanDetails.amount,
                        tronScanVerified: amountMatched && isVerifyTransaction,
                        transactionAt: new Date(tronscanDetails.transactionDate),
                        financialAccountIdentity: tronscanDetails.fromWalletAddress
                    })
                }
                else {
                    this.setState({ tronScanVerified: isVerifyTransaction, inquiryErrorMessage, amountMatched: true, amount: tronscanDetails?.amount })
                    this.props.onInquiry(false, hash)
                }
            })
            .finally(() => { this.setState({ pendingInquiry: false }) })
    }

    changeAmount = () => {
        this.setState({ amountMatched: true, tronScanVerified: true })
        this.props.changeAmount(this.state.amount)
        this.props.onInquiry(true, this.state.transactionNumber)
    }

    render(): React.ReactNode {
        const { tronScanVerified, pendingInquiry, inquiryErrorMessage, amountMatched } = this.state

        return <div className="ChargePaymentForm">
            <Form>
                {
                    this.props.paymentMethod.providerKey === 'TRON_SCAN' && <>
                        <Form.Group controlId="formBasicTransactionNumber" className="mb-3">
                            <Form.Label>Transaction Number</Form.Label>
                            <div className="d-flex align-items-center">
                                <Form.Control type="text" placeholder="Enter transaction number"
                                    value={this.state.transactionNumber}
                                    onChange={(e) => this.onChangeTransactionNumber(e.target.value)}
                                />
                                {
                                    this.state.transactionNumber &&
                                    <div className="m-2">
                                        {
                                            pendingInquiry ?
                                                <Spinner animation="border" size="sm" /> : !tronScanVerified ?
                                                    <XLg className="text-danger" /> : <CheckLg className="text-success" size="20px" />
                                        }
                                    </div>
                                }
                            </div>
                        </Form.Group>
                        {
                            (this.state.transactionNumber && inquiryErrorMessage && !tronScanVerified) &&
                            <Alert variant={!amountMatched ? "warning" : "danger"}>
                                {inquiryErrorMessage}
                                {!amountMatched && <Button variant="warning" size="sm" onClick={this.changeAmount}>Change Amount To {this.state.amount}</Button>}
                            </Alert>
                        }
                    </>
                }
                {(this.state.localCurrency !== null && this.state.localCurrency !== undefined) && (<>
                    <Form.Group className="mb-2" controlId="formBasicPurchasePrice">
                        <Form.Label>Purchase Price({this.state.localCurrency})</Form.Label>
                        <Form.Control type="text"
                            inputMode='decimal' placeholder="Enter purchase price"
                            value={this.state.purchasePrice}
                            onChange={(e) => ValidAmount(e.target.value) && this.setState({ purchasePrice: e.target.value === "" ? null : e.target.value })} />
                    </Form.Group>
                    {this.displayAlertOfPurchase()}
                    {this.state.lastAverageOfPurchasePrice !== null && <div>Wallet last average: <Amount value={this.state.lastAverageOfPurchasePrice as number} /> </div>}
                </>)}

                <Form.Group controlId="formBasicAutoExchange">
                    <Form.Check type="checkbox" label="Auto Exchange"
                        checked={this.state.autoExchangeRequestWalletId !== null}
                        onChange={this.autoExchangeChanged} />
                </Form.Group>
                {
                    this.state.autoExchangeRequestWalletId !== null && (<Form.Group controlId="formBasicExchangeWallet">
                        <Form.Select aria-label=">Select target wallet" value={this.state.autoExchangeRequestWalletId as string}
                            onChange={(e) => this.setState({ autoExchangeRequestWalletId: e.target.value })}>
                            {
                                this.state.selectionWallets.map((wallet) => <option value={wallet.value} key={wallet.value}>{wallet.title}</option>)
                            }
                        </Form.Select>
                    </Form.Group>)
                }
            </Form>
        </div >
    }
}

interface ChargePaymentFormState {
    selectionWallets: SelectionView[]
    transactionNumber: string
    autoExchangeRequestWalletId: string | null
    paymentMethod: PaymentMethodDto
    purchasePrice: string | null;
    transactionAt: Date
    localCurrency: string | null
    lastAverageOfPurchasePrice: number | null
    pendingInquiry: boolean
    amountMatched?: boolean
    tronScanVerified: boolean | null
    inquiryErrorMessage?: string
    amount?: number
    financialAccountIdentity?: string
}

interface ChargePaymentFormProps {
    description: string;
    paymentMethod: PaymentMethodDto
    currencyId: string
    amount?: number
    walletAddress: string
    transactionNumber?: string
    onInquiry: (verified: boolean, hash?: string) => void
    onPayment?: (status: boolean) => void
    changeAmount?: (amount: number) => void
}