import { mapToDataOnly } from "../common/HttpUtility";
import { BuyPlanApiFactory } from "../../generated-api";

export default class BuyPlanService {

    get(productId: string): Promise<BuyPlanDto> {
        return mapToDataOnly<any>(BuyPlanApiFactory().buyPlanProductIdGet(productId));
    }

    update(dto: UpdateBuyPlanDto): Promise<any> {
        return mapToDataOnly<any>(BuyPlanApiFactory().buyPlanPut(dto));
    }
}

export interface UpdateBuyPlanDto {
    productId: string;
    customLimitPolicy: boolean;
    allowAllSellers: boolean;
    allowedSellerShopIds: string[];
    denySellerShopIds: string[];
    maxPrice: number;
    minimumCount: number;
    maximumCount: number;
    plannedDayCount: number;
    status: BuyPlanStatus;
}

export interface BuyPlanDto {
    productId: string;
    customLimitPolicy: boolean;
    allowAllSellers: boolean;
    allowedSellerShopIds: string[];
    denySellerShopIds: string[];
    maxPrice: number;
    minimumCount: number;
    maximumCount: number;
    plannedDayCount: number;
    status: BuyPlanStatus;
    outDailyAverage: number;
}

export enum BuyPlanStatus {
    PendingOnly,
    AutoMinimum,
    CustomMinimum
}