import { GridResult } from "../../components/Grid/Grid.models";
import { PaginationQuery } from "../../components/Grid/Pagination/Pagination.models";
import { ShopInventoryStatusEnum } from "../../components/inventory/Enums/ShopInventoryStatusEnum";
import { InventoryFilters, IShopInventoryRowDTO } from "../../components/inventory/InventoriesList/InventoryList.models";
import { mapToDataOnly } from "../common/HttpUtility";
import { ITakeoutInventoryFilters } from "../../components/inventory/TakeoutInventory/TakeoutInventory.models";
import { InventoryProductApiFactory, TakeoutInventoryApiFactory } from "../../generated-api";
import { ToLocaleDate } from "../../components/utilities/HybridMoment";

export default class InventoryService {
    getPaged(filters: InventoryFilters, paginationQuery: PaginationQuery): Promise<GridResult<IShopInventoryRowDTO>> {
        return mapToDataOnly<GridResult<IShopInventoryRowDTO>>(InventoryProductApiFactory()
            .sellerInventoryProductGet(filters.shopCollectionId, filters.orderProductId, filters.productId, filters.information, filters.status, filters.duplicateInformationAccepted, filters.unhandledDuplicateInformation, paginationQuery.pageskip - 1, paginationQuery.pagesize))
    }

    getById(id: string): Promise<IShopInventoryRowDTO> {
        return mapToDataOnly<IShopInventoryRowDTO>(InventoryProductApiFactory().sellerInventoryProductInventoryIdGet(id));
    }

    getInformation(id: string): Promise<{ information: string }> {
        return mapToDataOnly<any>(InventoryProductApiFactory().sellerInventoryProductInformationInventoryShopIdGet(id));
    }

    create(dto: IAddShopInventoryDTO): Promise<AddShopInventoryResultDTO> {
        return mapToDataOnly<AddShopInventoryResultDTO>(InventoryProductApiFactory().sellerInventoryProductItemPost({ ...dto, buyDate: ToLocaleDate(dto.buyDate) }))
    }

    unlock(inventoryId: string, duplicateInformationAccepted): Promise<any> {
        return mapToDataOnly(InventoryProductApiFactory().sellerInventoryProductUnlockIdPut(inventoryId, { duplicateInformationAccepted }))
    }

    changeInventoryProduct(dto: IChangeInventoryProductDTO): Promise<any> {
        return mapToDataOnly(InventoryProductApiFactory().sellerInventoryProductChangePut({ inventoryShopId: dto.inventoryShopId, status: dto.status }));
    }

    holdInventoryProductByCollectionId(collectionId: string): Promise<any> {
        return mapToDataOnly(InventoryProductApiFactory().sellerInventoryProductHoldCollectionCollectionIdPut(collectionId));
    }

    getTakeoutInventories(filters: ITakeoutInventoryFilters, paginationQuery: PaginationQuery): Promise<GridResult<TakeoutInventoryRowDTO>> {
        return mapToDataOnly<GridResult<TakeoutInventoryRowDTO>>(TakeoutInventoryApiFactory().marketPlaceTakeoutInventoryGet(filters.sourceKey, filters.sourceDeliveryKey, paginationQuery.pageskip - 1, paginationQuery.pagesize))
    }

    createManualTakeoutInventory(dto: { inventoryId: string, description: string }): Promise<any> {
        return mapToDataOnly<any>(TakeoutInventoryApiFactory().marketPlaceTakeoutInventoryPost(dto));
    }

    resendWebhook(id: string): Promise<any> {
        return mapToDataOnly(TakeoutInventoryApiFactory().marketPlaceTakeoutInventoryResendWebhookIdPut(id));
    }

    download(productId: string, query: { isOut: boolean, description: string }): Promise<any> {
        return InventoryProductApiFactory().sellerInventoryProductDetailsFullExcelProductIdPost(productId, query, { responseType: 'blob' })
            .then(({ data }) => {
                const downloadUrl = window.URL.createObjectURL(new Blob([data as any]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', `product-inventory.csv`);
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
    }
}

interface IAddShopInventoryResultErrorDTO {
    index: number;
    inventoryId: string;
    conflictFields: string[];
}

interface AddShopInventoryResultDTO {
    errors: IAddShopInventoryResultErrorDTO[];
}

export interface IAddShopInventoryDTO {
    withoutConflictValidation: boolean;
    productId: string;
    price?: number;
    buyDate?: Date;
    isDebt?: boolean;
    sourceId?: string | null;
    orderNumber?: string;
    description?: string;
    deficitCount?: number;
    inventoryItems: string[][];
}

export interface IChangeInventoryProductDTO {
    inventoryShopId: string;
    status: ShopInventoryStatusEnum;
}

interface IUnlockInventoryDto {
    duplicateInformationAccepted: boolean
}

export interface TakeoutInventoryRowDTO {
    id: string;
    productId: string;
    productTitle: string;
    inventoryShopId: string | null;
    inventoryShopTitle: string;
    isCanceled: boolean;
    isWebhookDelivered: boolean;
    webhookDeliveredLastError: string;
    sourceDocument: string;
    sourceKey: string;
    sourceSubKey: string;
    sourceDeliveryKey: string;
    rowVersion: string;
    createDate: any;
    lastProccess: string | null;
}