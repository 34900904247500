import React, { RefObject } from "react";
import { Badge, Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import IState, { IBuyOrderItemFilters } from "./OrderReport.models";
import orderStatusDisplayBadge, { orderStatusDisplayCaption, OrderStatusEnum } from "../../OrderEnums";
import OrdersService from "../../../../services/orders/OrdersService";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import orderTypeDisplayBadge, { OrderTypeEnum, orderTypeDisplayCaption } from "../../Enums/OrderTypeEnum";
import PeriodDatePicker from "../../../shared/PeriodDatePicker/PeriodDatePicker";
import { SelectionView } from "../../../../services/common/Models";
import CommonService from "../../../../services/common/CommonService";
import HubridMoment from "../../../utilities/HybridMoment";
import TOTPForm from "../../../identity/Security/TwoFactor/TOTPForm/TOTPForm";
import OrderDetails from "../../OrderDetails/OrderDetails";
import { GridAction, GridColumn, GridColumnTypes, GridResult } from "../../../Grid/Grid.models";
import { BuyOrderListItem } from "../OrdersList/OrdersList.models";
import DynamicGrid from "../../../shared/DynamicGrid/DynamicGrid";
import AuthenticationContext from "../../../../contexts/AuthenticationContext/AuthenticationContext";
import { Stack } from "react-bootstrap-icons";

export default class OrderReport extends React.Component<{}, IState> {
    dynamicGrid: RefObject<DynamicGrid<IBuyOrderItemFilters>>;
    ordersService: OrdersService
    commonService: CommonService
    static contextType = AuthenticationContext
    context!: React.ContextType<typeof AuthenticationContext>
    constructor(props: any) {
        super(props);
        this.dynamicGrid = React.createRef()
        this.ordersService = new OrdersService()
        this.commonService = new CommonService()

        this.state = {
            showOtpForm: false,
            paginationQuery: { pageskip: 1, pagesize: 10 }, products: [{ title: '', value: '' }]
        }
    }

    reloadGrid(gridResult: GridResult<BuyOrderListItem>) {
        let rows = gridResult.rows.map((row) => {
            return { ...row, status: orderStatusDisplayBadge(row.status), type: orderTypeDisplayBadge(row.orderType) }
        })
        this.dynamicGrid.current?.load(rows, gridResult.totalRows)
    }

    downloadHandler = (row: any) => {
        this.setState({ selectedRow: { id: row.id, orderNumber: row.orderNumber } })
        if (this.context.twoFactorAvtive) {
            this.setState({ showOtpForm: true })
            this.dynamicGrid.current?.resetSpinner()
        }
        else this.downloadOrderDetail(row.id, row.orderNumber);
    }
    downloadOrderDetail = (id: string, orderNumber: string) => {
        this.ordersService.download(id as string, `Order-${orderNumber}.csv`).then(() => {
            this.clear()
        })
    }
    clear = () => {
        this.dynamicGrid.current?.resetSpinner()
        this.setState({ selectedRow: undefined })
        if (this.state.showOtpForm) this.setState({ showOtpForm: false })
    }

    componentDidMount() {
        this.ordersService.getProductsTitleValues().then((response) => {
            this.setState({ products: response })
        })
    }
    render(): React.ReactNode {
        const { showOtpForm, orderDetailsId } = this.state
        let gridColumns: GridColumn[] = [
            { title: 'Order Number', key: 'orderNumber' },
            { title: 'Amount', key: 'totalAmount', type: GridColumnTypes.Amount },
            { title: 'Product Count', key: 'productCount' },
            { title: 'Status', key: 'statusBadge' },
            { title: 'Type', key: 'orderTypeBadge' },
            { title: 'Date', key: 'createAt', type: GridColumnTypes.Date },
        ]
        let gridActions: GridAction[] = [
            {
                caption: 'Download', variant: 'outline-info', onClick: (row) => this.downloadHandler(row)
            },
            {
                caption: 'Details', variant: 'outline-info', onClick: (row) => {
                    this.setState({ orderDetailsId: row.id })
                }
            }
        ]

        return (<div>
            {orderDetailsId !== undefined && <OrderDetails orderId={orderDetailsId}
                onClose={() => this.setState({ orderDetailsId: undefined })}
                onLoaded={() => this.dynamicGrid.current?.resetSpinner()} />}

            <DynamicGrid<IBuyOrderItemFilters> ref={this.dynamicGrid}
                columns={gridColumns}
                actions={gridActions}
                onFetch={this.ordersService.getBuyOrders}
                onExportClick={this.ordersService.downloadExcelBuyOrders}
                refactorFilterDisplay={this.renderCustomFilterView}
                filtersForm={this.filtersView}
                refactorRow={(row) => {
                    return {
                        ...row,
                        statusBadge: <>
                            {orderStatusDisplayBadge(row.status)}
                            <span style={{ display: 'inline-block', marginLeft: '5px' }}>
                                {row.addToInventory && row.status == 1 &&
                                    <OverlayTrigger
                                        key="top"
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip-top">
                                                This order items added to your inventory
                                            </Tooltip>
                                        }
                                    >
                                        <div style={{ color: 'green' }}>
                                            <Stack />
                                        </div>
                                    </OverlayTrigger>
                                }
                            </span>
                        </>,
                        orderTypeBadge: orderTypeDisplayBadge(row.orderType),
                    }
                }} />
            {showOtpForm &&
                <TOTPForm showModal={true} onSubmit={() => this.downloadOrderDetail(this.state.selectedRow.id, this.state.selectedRow.orderNumber)} onHide={() => this.setState({ showOtpForm: false })} />
            }
        </div>)
    }


    renderCustomFilterView = (key: string, value: string) => {
        switch (key) {
            case 'status':
                return orderStatusDisplayCaption(Number(value))
            case 'type':
                return orderTypeDisplayCaption(Number(value))
            case 'productId':
                return this.state.products.filter(item => item.value === value)[0].title
            case 'fromDate':
            case 'toDate':
                return HubridMoment(value)
            default: return value
        }
    }

    filtersView = (filters: IBuyOrderItemFilters, onChange: (newFilters: IBuyOrderItemFilters) => void): JSX.Element => {
        return <>
            <PeriodDatePicker
                fromDate={filters.fromDate}
                toDate={filters.toDate}
                onChangeFromDate={(date) => onChange({ ...filters, fromDate: date })}
                onChangeToDate={(date) => onChange({ ...filters, toDate: date })}
            />
            <Row className="mt-2">
                <Col md="6" sm="12">
                    <Form.Group>
                        <label>Status</label>
                        <Form.Select aria-label=">Select status"
                            id="status" value={filters.status}
                            onChange={(e) => onChange({ ...filters, status: Number(e.target.value) })}>
                            {Object.keys(OrderStatusEnum).map(key => typeof OrderStatusEnum[key as any] !== 'number' && (
                                <option value={key} key={key}>{OrderStatusEnum[key as any]}</option>
                            ))}
                            <option value="" selected>All</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col md="6" sm="12">
                    <Form.Group>
                        <label>Type</label>
                        <Form.Select aria-label=">Select type"
                            id="type" value={filters.type}
                            onChange={(e) => onChange({ ...filters, type: e.target.value })}>
                            {Object.keys(OrderTypeEnum).map(key => typeof OrderTypeEnum[key as any] !== 'number' && (
                                <option value={key} key={key}>{OrderTypeEnum[key as any]}</option>
                            ))}
                            <option value="" selected>All</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>
            <hr />
            <Row className="mt-2">
                <Col md="12" sm="12">
                    <Form.Group>
                        <label>Order Number</label>
                        <Form.Control value={filters.orderNumber} onChange={(e) => {
                            onChange({ ...filters, orderNumber: e.target.value })
                        }} placeholder="Enter Order Number" />
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md="12" sm="12" >
                    <label>Product</label>
                    <ReactSearchAutocomplete<SelectionView>
                        items={this.state.products}
                        fuseOptions={{ keys: ["title"] }}
                        resultStringKeyName="title"
                        inputSearchString={filters.productId && this.state.products.filter(product => product.value === filters.productId)[0].title}
                        showClear={false}
                        showIcon={false}
                        styling={{ borderRadius: "3px", border: "1px solid #ced4da", boxShadow: "unset", placeholderColor: "#000", height: '35px', fontSize: "15px", fontFamily: "Roboto-Regular" }}
                        onSelect={(item) => onChange({ ...filters, productId: item.value })}
                        autoFocus={false}
                        showItemsOnFocus={false}
                        showNoResults={true}
                    />
                </Col>
            </Row>
        </>
    }
}