import React from "react";
import FormatMoney from "../FormatMoney";

export default class Amount extends React.PureComponent<{ value: number, formatMoney?: boolean, floor?: boolean }> {

    render(): React.ReactNode {
        return <>{this.props.formatMoney ? round(this.props.value, this.props.floor).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : round(this.props.value, this.props.floor).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</>
    }
}

export function amount(value: number): string {
    return round(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function round(num: number, floor?: boolean): number {
    if (num > 0) {
        if (floor)
            return Math.floor((num + Number.EPSILON) * 100) / 100;
        else
            return Math.round((num + Number.EPSILON) * 100) / 100;

    }
    else {
        num *= -1;
        if (floor)
            num = Math.floor((num + Number.EPSILON) * 100) / 100;
        else
            num = Math.round((num + Number.EPSILON) * 100) / 100;
        return num * -1;
    }
}