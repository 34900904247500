import React, { RefObject } from "react";
import { toast } from "react-toastify";
import ShopMemberService from "../../../../services/shops/shopMemberService";
import Grid from "../../../Grid/Grid";
import { GridAction, GridColumn } from "../../../Grid/Grid.models";
import { PaginationQuery } from "../../../Grid/Pagination/Pagination.models";
import IState, { IProps } from './ShopMemberList.models'

export default class ShopMemberList extends React.Component<IProps, IState> {
    grid: RefObject<Grid>
    shopMemberService: ShopMemberService
    constructor(props: IProps) {
        super(props);
        this.state = { paginationQuery: { pageskip: 1, pagesize: 10 }, showInviteModal: false }
        this.grid = React.createRef()
        this.shopMemberService = new ShopMemberService()
    }

    componentDidMount = () => {
        this.reloadGrid();
    }

    removeMember = (id: string) => {
        if (window.confirm('Are you sure ? This member will be deleted from the shop permanently')) {
            this.shopMemberService.delete(id).then(() => {
                toast.success('Member has been deleted from the shop.')
                this.reloadGrid()
            })
        }

        this.grid.current?.resetSpinner()
    }

    reloadGrid() {
        this.shopMemberService.get(this.state.paginationQuery).then((gridResult) => {
            this.grid.current?.load(gridResult.rows, gridResult.totalRows);
        })
    }

    changePage = (query: PaginationQuery) => {
        this.setState({ paginationQuery: query });
        this.reloadGrid();
    }

    render(): React.ReactNode {
        return (<>
            <div className="pt-2">
                {this.memberLisView()}
            </div>
        </>)
    }

    memberLisView() {
        let gridColumns: GridColumn[] = [
            { title: 'Name', key: 'name' },
            { title: 'Email', key: 'email' },
        ];

        let gridActions: GridAction[] = [
            {
                caption: 'Change Privileges', variant: 'outline-success', generateLink: (row) => `/user-privilege/?userId=${row.userId}`, checkIsDisabled: (row) => { return row.userId ? false : true }
            },
            {
                caption: 'Remove Access', variant: 'outline-danger', onClick: (row) => { this.removeMember(row.id) }
            },
        ];

        return (
            <Grid ref={this.grid} columns={gridColumns} actions={gridActions} onChangePage={this.changePage}></Grid>
        )
    }
}