import React, { RefObject } from "react";
import { Button, Col, Form, Modal, Row, Spinner, } from "react-bootstrap";
import TwoFactorService from "../../../../../services/identity/TwoFactorService";
import ITwoFactorState, { ITwoFactorProps } from "./TwoFactorStepsModal.models";
import { toast } from "react-toastify";
import QrCodeGenerator from "../../../../shared/QrCodeGenerator/QrCodeGenerator";

export default class TwoFactorStepsModal extends React.PureComponent<ITwoFactorProps, ITwoFactorState> {
    twoFactorService: TwoFactorService
    input: RefObject<HTMLInputElement>
    constructor(props: any) {
        super(props)
        this.state = { pendingRequest: false, loading: false, totp: '', key: '', recoveryCodes: [], showRecoveryCode: false }
        this.twoFactorService = new TwoFactorService()
        this.input = React.createRef()
    }

    componentDidMount() {
        this.getToken()
    }

    focusToInput = () => {
        this.input.current?.focus();
    }

    submit = () => {
        const { totp } = this.state
        if (totp === '') return toast.error("Please enter the token");
        if (totp.length !== 6) return toast.error("The code entered must be 6 digits, please enter correctly");

        this.twoFactorService.verify(totp).then((response) => {
            if (response.isVerified) {
                toast.success("Two-factor has been activated for your account.")
                this.setState({ recoveryCodes: response.recoveryCodes, showRecoveryCode: true, pendingRequest: true, totp: '' })
                this.props.onHandle()
            }
        })
    }


    getToken = () => {
        this.twoFactorService.getToken().then((response) => {
            this.setState({ key: response })
        })
    }

    render(): React.ReactNode {
        const { pendingRequest, showRecoveryCode } = this.state
        const { showModal, closeModal } = this.props

        return (
            <Modal
                onShow={this.focusToInput}
                className="two-factor"
                show={showModal}
                onHide={closeModal}
                backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Two-factor</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div> {!showRecoveryCode ? this.qrCodeScanView() : this.recoveryCodeView()}</div>
                </Modal.Body>
                <Modal.Footer>
                    { !showRecoveryCode ?
                        pendingRequest ? <Button variant='success' disabled><Spinner animation='border' /></Button> :
                            <Button variant='success' onClick={this.submit}>Verify</Button>
                        : <label className="text-danger">
                            These are secret codes that once you forgot your password or had not access to your phone to use authentication code, they will be required. DONT SHARE IT WITH ANYONE and write/paste somewhere safe.
                        </label>
                    }
                </Modal.Footer>
            </Modal >
        )
    }

    qrCodeScanView() {
        const { loading, totp, key } = this.state
        return (
            <>
                <h5>Scan the following QRCode</h5>(Recommended App: Google Authenticator)
                <Row>
                    <Col md="12" sm="12">
                        <div style={{ textAlign: 'center' }} className="p-4">
                            {key !== '' && !loading ? <QrCodeGenerator keyGenerator={key} /> :
                                <Spinner animation="grow" />
                            }
                        </div>
                    </Col>
                    <Col md="12" sm="12">
                        <Form.Group>
                            <Form.Label>Enter the 6 digit code from the app once the scan is complete. </Form.Label>
                            <Form.Control ref={this.input} value={totp} onChange={e => this.setState({ totp: e.target.value })} type="text" placeholder="Type code..." />
                        </Form.Group>
                    </Col>
                </Row>
            </>
        )
    }

    recoveryCodeView() {
        return (
            <div style={{ textAlign: 'center' }}>
                <h5>Recovery Codes</h5>{this.state.recoveryCodes.map((code, index) => { return (<span className="text-danger p-2"> {code} &nbsp; {(index + 1) % 2 === 0 && <br />}</span>) })}
            </div>
        )
    }
}