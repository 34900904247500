import React from "react";
import { Link } from "react-router-dom";
import SumMenuItem from "./SubMenuItem/SubMenuItem";
import './MenuItem.scss';
import authenticationContext from "../../../../../contexts/AuthenticationContext/AuthenticationContext";
import { SidebarItemView } from "../CategorySidebar";
import { Collection, CollectionFill } from "react-bootstrap-icons";

export default class MenuItem extends React.PureComponent<{
    customStyle?: string, onNavigate?: () => void, onClick: () => void,
    catalogMode: 'express' | 'trade' | 'api', title: string, subItems: SidebarItemView[], isOpened: boolean, id: string
},
    { title: string, isOpened: boolean, subItems: SidebarItemView[], id: string }> {
    static contextType = authenticationContext;
    context!: React.ContextType<typeof authenticationContext>;
    constructor(props: any) {
        super(props);
        this.state = { title: '', subItems: [], isOpened: false, id: '' };
    }
    componentDidMount() {
        this.setState({ title: this.props.title, subItems: this.props.subItems, isOpened: false, id: this.props.id });
    }

    componentDidUpdate() {
        this.setState(prevState => { return { ...prevState, isOpened: this.props.isOpened } });
    }

    handleClickMenuItem = (id: string) => {
        this.closeAll(id);
        this.setState(prevState => {
            return {
                ...prevState,
                subItems: prevState.subItems
                    .map(item => {

                        if (item.id === id) {
                            console.log(item);
                            return { ...item, isActive: !item.isActive };
                        } else {
                            return item;
                        }
                    })
            }
        });
    }
    closeAll = (exceptedId: string) => {
        this.setState(prevState => {
            return {
                subItems: prevState.subItems
                    .map(item => { return item.id === exceptedId ? item : { ...item, isActive: false } })
            }
        });

    }

    render(): React.ReactNode {
        return (
            <div className={`MenuItem  ${this.props.customStyle && this.props.customStyle}`} onClick={this.props.onClick}>
                {
                    this.state.subItems.length > 0 ?
                        <div>
                            <span className="menu-link">
                                <CollectionFill></CollectionFill>
                                {this.props.title}
                            </span>
                            {
                                this.state.isOpened ? (
                                    <ul>
                                        {
                                            this.state.subItems.map((item, index) => (
                                                <SumMenuItem role={this.context.role} key={index} menuItem={item} catalogMode={this.props.catalogMode}
                                                 onNavigate={this.props.onNavigate} onClick={() => this.handleClickMenuItem(item.id)}></SumMenuItem>
                                            ))
                                        }
                                    </ul>
                                ) : null
                            }
                        </div>
                        :
                        <span className="menu-link" onClick={this.props.onNavigate}>
                            <Collection></Collection>
                            <Link style={{ color: '#696969' }} to={`/marketplace/?categoryId=${this.state.id}`}>{this.state.title}2</Link>
                        </span>
                }
            </div>
        );
    }
}