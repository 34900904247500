import { GridResult } from "../../../components/Grid/Grid.models"
import { PaginationQuery } from "../../../components/Grid/Pagination/Pagination.models"
import { mapToDataOnly } from "../../common/HttpUtility"
import { SelectionView } from "../../common/Models"
import { ProductApiFactory } from "../../../generated-api/admin"
import { CurrencyApiFactory } from "../../../generated-api/financials"

export interface IProductRowDTO {
    title: string
    isActive: boolean
    isPrivate: boolean
    categoryId: string
    structures: ProductStructureDTO[]
    shortTitle: string
    currencyId: string
    realPrice: number
    imageName: string
    imageSrc?: string
}

interface  ProductStructureDTO {
    title: string
    key: string
    isUniqueValue: boolean
}

export default class AdminProductService {
    getPaged(filters: any, paginationQuery: PaginationQuery): Promise<GridResult<IProductRowDTO>> {
        return mapToDataOnly<GridResult<IProductRowDTO>>(ProductApiFactory().adminProductGet(paginationQuery.pageskip - 1, paginationQuery.pagesize))
    }

    getById(id: string): Promise<IProductRowDTO> {
        return mapToDataOnly<IProductRowDTO>(ProductApiFactory().adminProductIdGet(id))
    }

    update(id: string, dto: IProductRowDTO): Promise<void> {
        return mapToDataOnly<any>(ProductApiFactory().adminProductIdPut(id, dto))
    }

    add(dto: IProductRowDTO): Promise<void> {
        return mapToDataOnly<any>(ProductApiFactory().adminProductPost(dto))
    }

    delete(id: string): Promise<void> {
        return mapToDataOnly<any>(ProductApiFactory().adminProductIdDelete(id))
    }

    getCurrency(): Promise<SelectionView[]> {
        return mapToDataOnly<SelectionView[]>(CurrencyApiFactory().apiV1AdminCurrencyTitleValueGet())
    }
}