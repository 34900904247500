import { CashPaymentStatusEnum } from "../../../components/financials/Enums/CashPaymentStatusEnum";
import { GridResult } from "../../../components/Grid/Grid.models";
import { PaginationQuery } from "../../../components/Grid/Pagination/Pagination.models";
import { mapToDataOnly } from "../../common/HttpUtility";
import { CashPaymentApiFactory } from "../../../generated-api/financials";
import { DepositApiFactory } from "../../../generated-api/financials";

interface IAdminDepositRowDto {
    id: string
    amount: number
    handleAt: string | null
    crreateAt: string
    status: CashPaymentStatusEnum
    isBlockchainVerification: boolean
    shopTitle: string
    shopId: string
    transactionNumber: string
    cashPaymentAccountTitle: string
}

export interface HandleCashPaymentDTO {
    cashPaymentId: string;
    isAccepted: boolean;
    handleDescription: string;
    withTransaction: boolean;
}

interface IAdminDepositQueryString extends PaginationQuery {
    status?: CashPaymentStatusEnum;
    unreviewedOnly?: boolean
    shopTitle?: string
}

export default class AdminDepositsService {
    getPaged(query: IAdminDepositQueryString): Promise<GridResult<IAdminDepositRowDto>> {
        return mapToDataOnly<any>(CashPaymentApiFactory().apiV1AdminCashPaymentGet(query.shopTitle, query.status, query.unreviewedOnly, query.pageskip - 1, query.pagesize));
    }

    review(depositId: string): Promise<void> {
        return mapToDataOnly<any>(CashPaymentApiFactory().apiV1AdminCashPaymentReviewDepositIdPut(depositId));
    }

    reject(depositId: string): Promise<void> {
        return mapToDataOnly<any>(DepositApiFactory().apiV1DepositRejectDepositIdPut(depositId));
    }

    accept(dto: AcceptDepositDto): Promise<void> {
        return mapToDataOnly<any>(DepositApiFactory().apiV1DepositAcceptPut(dto));
    }
}

interface AcceptDepositDto {
    id: string
}