import { Route } from "react-router-dom";
import DefaultLayout from "../../components/layout/DefaultLayout";
import BuyOrdersRoute from "../orders/BuyOrdersRoute";
import SellOrdersRoute from "../orders/SellOrdersRoute";

export function OrdersRoutes() {
    return (
        <>
            <Route element={<DefaultLayout />}>
                <Route path="/orders/buy" element={<BuyOrdersRoute />} />
                <Route path="/orders/sell" element={<SellOrdersRoute />} />
            </Route>
        </>
    )
}