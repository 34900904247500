import { useSearchParams } from 'react-router-dom';
import AdminReviewRequest from '../../../components/admin/review-requests/ReviewRequests';
import AdminAuthenticated from '../../../components/shared/AdminAuthenticated/AdminAuthenticated';
import { ADMIN, CONTENT_WRITER, MEDIATOR, SUPERADMIN } from '../../../constants/ConstantsPermissions';

export default function AdminReviewRequestRoute() {
    const [searchParams] = useSearchParams()
    let activeTab = searchParams.get("activeTab")
    
    return <AdminAuthenticated roles={[SUPERADMIN, ADMIN, MEDIATOR]}><AdminReviewRequest activeTab={activeTab} /></AdminAuthenticated>
}