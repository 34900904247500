import React, { RefObject } from "react";
import { Button, Col, Form, Modal, Row, Spinner, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import { Country } from "../../../constants/Countries";
import { productRequestMessageTemplate } from "../../../constants/Templates/CreateNewProductRequestMessageTemplate";
import AdminProductService from "../../../services/admin/products/AdminProductService";
import ProductCategoryService from "../../../services/category/ProductCategoryService";
import SupportTicketsService from "../../../services/helpCenter/SupportTicketService";
import Uploader from "../../shared/Uploader/Uploader";
import IState, { IProps } from "./RequestNewProduct.models";

export default class RequestNewProductModal extends React.Component<IProps, IState> {
    uploader: RefObject<Uploader>
    supportTicketsService: SupportTicketsService
    productService: AdminProductService
    productCategoryService: ProductCategoryService
    constructor(props: any) {
        super(props);
        this.state = {
            pendingRequest: false, product: {
                categoryId: '', country: '', currencyId: '', description: '', descriptionUse: '', imageName: '', price: '', title: '', url: '', structures: [], category: '', currency: ''
            }, categoryTitleValues: [], currencyTitleValues: [], productGridData: [{ title: '' }], departmentId: ''
        }
        this.supportTicketsService = new SupportTicketsService()
        this.productService = new AdminProductService()
        this.productCategoryService = new ProductCategoryService()
    }

    componentDidMount(): void {
        this.fetchData();
    }

    fetchData = () => {
        this.supportTicketsService.getDepartments().then((departments) => this.setState({ departmentId: departments[0].value }))
        this.productCategoryService.getChildOnlyTitleValues().then(response => this.setState({ categoryTitleValues: response }))
        this.productService.getCurrency().then((response) => this.setState({ currencyTitleValues: response }))
    }

    handleTitleValue = (value, index) => {
        let temp = this.state.productGridData
        temp[index].title = value

        this.setState({ productGridData: temp })
        this.forceUpdate();
    }

    addRow = () => {
        let temp = this.state.productGridData;
        temp.push({ title: '' });
        this.setState({ productGridData: temp })
        this.forceUpdate();
    }

    removeRow = (index) => {
        let temp = this.state.productGridData;
        temp.splice(index, 1);
        this.setState({ productGridData: temp })
        this.forceUpdate();
    }

    gridView() {
        return (
            <>
                <Table hover responsive size="lg">
                    <thead>
                        <tr>
                            <th>Title</th>
                            <th className="d-flex flex-row-reverse">
                                <Button variant="success" onClick={() => this.addRow()}>Add</Button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.productGridData && this.state.productGridData.map((product, index) =>
                                <tr key={index}>
                                    <td>
                                        <Form.Control
                                            type="text"
                                            value={product.title}
                                            onChange={(e) => this.handleTitleValue(e.target.value, index)}
                                        />
                                    </td>
                                    <td className="d-flex flex-row-reverse">
                                        <Button variant="outline-danger" onClick={() => this.removeRow(index)}>Remove</Button>
                                    </td>
                                </tr>)}
                    </tbody>
                </Table>
            </>
        )
    }

    submit = () => {
        let { product, productGridData, departmentId } = this.state;
        if (product.title == null || product.title == "") toast.error("Please enter the product title")
        else if (product.price == null || product.price == "") toast.error("Please enter the real price")
        else {
            let description = productRequestMessageTemplate(product, productGridData)

            this.setState({ pendingRequest: true })
            this.supportTicketsService.create("New Product Request", description, departmentId).then(() => {
                toast.success("Product request created successfully")
                this.props.onClose()
            })
        }
    }

    render(): React.ReactNode {
        let { pendingRequest, product, categoryTitleValues, currencyTitleValues } = this.state
        let { onClose } = this.props

        return <Modal show={true} onHide={onClose} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Didn't find what you're looking for? Ask us to make it available!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <span className="text-muted">Here you can tell us what you want to be available on API Gift. Please fill the following form with the accurate information and we'll review your request then will notify you about the result.</span>
                <Form className="mt-3">
                    <Row>
                        <Col className="mt-3" md="6" sm="12">
                            <Form.Label>Title:</Form.Label>
                            <Form.Control type="text" value={product.title} placeholder='E.g. iTunes USA 2$'
                                onChange={e => this.setState({ product: { ...product, title: e.target.value } })} />
                        </Col>
                        <Col className="mt-3" md="6" sm="12">
                            <Form.Label>Real Price:</Form.Label>
                            <Form.Control type="number" value={product.price} placeholder='E.g. 2'
                                onChange={e => this.setState({ product: { ...product, price: e.target.value } })} />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mt-3" md="4" sm="12">
                            <Form.Label>Category:</Form.Label>
                            <Form.Select aria-label="Select Product Category" value={product.categoryId}
                                onChange={(e) => this.setState({ product: { ...product, category: e.target.value, categoryId: e.target.value } })}>
                                <option value="" selected>Select Product Category</option>
                                {
                                    categoryTitleValues.map((category) => <option value={category.title} key={category.value}>{category.title}</option>)
                                }
                                <option value="other" selected>Other</option>
                            </Form.Select>
                        </Col>
                        <Col className="mt-3" md="4" sm="12">
                            <Form.Label>Country:</Form.Label>
                            <Form.Select aria-label="Select Product Country" id="country" value={product.country}
                                onChange={(e) => this.setState({ product: { ...product, country: e.target.value } })}>
                                <option value="" selected>Select Product Country</option>
                                {
                                    Country().map((country) => <option value={country} key={country}>{country}</option>)
                                }
                                <option value="other" selected>Other</option>

                            </Form.Select>
                        </Col>
                        <Col className="mt-3" md="4" sm="12">
                            <Form.Label>Currency:</Form.Label>
                            <Form.Select aria-label="Select Product Currency" value={product.currencyId}
                                onChange={(e) => this.setState({ product: { ...product, currency: e.target.value, currencyId: e.target.value } })}>
                                <option value="" selected>Select Product Currency</option>
                                {
                                    currencyTitleValues.map((currency) => <option value={currency.title} key={currency.value}>{currency.title}</option>)
                                }
                                <option value="other" selected>Other</option>
                            </Form.Select>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mt-3" md="4" sm="12">
                            {
                                product.category == "other" && <>
                                    <Form.Label>Other Category:</Form.Label>
                                    <Form.Control type="text" value={product.otherCategory} placeholder='Enter Product Category'
                                        onChange={e => this.setState({ product: { ...product, otherCategory: e.target.value } })} />
                                </>
                            }
                        </Col>
                        <Col className="mt-3" md="4" sm="12">
                            {
                                product.country == "other" &&
                                <>
                                    <Form.Label>Other Country:</Form.Label>
                                    <Form.Control type="text" value={product.otherCountry} placeholder='Enter Product Country'
                                        onChange={e => this.setState({ product: { ...product, otherCountry: e.target.value } })} />
                                </>
                            }
                        </Col>
                        <Col className="mt-3" md="4" sm="12">
                            {
                                product.currency == "other" &&
                                <>
                                    <Form.Label>Other Currency:</Form.Label>
                                    <Form.Control type="text" value={product.otherCurrency} placeholder='Enter Product Currency'
                                        onChange={e => this.setState({ product: { ...product, otherCurrency: e.target.value } })} />
                                </>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mt-3" md="6" sm="12">
                            <Form.Label className="mb-1">Website (optional):</Form.Label>
                            <Form.Control type="text" value={product.url} placeholder='E.g. https://www.apple.com'
                                onChange={e => this.setState({ product: { ...product, url: e.target.value } })} />
                        </Col>
                        <Col className="mt-3" md="6" sm="12">
                            <Form.Label className="mb-1"></Form.Label>
                            <Uploader ref={this.uploader} folderName='products' onUpload={(path) => this.setState({ product: { ...product, imageName: path } })} imageAddress={product.imageName} />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mt-3" md="12" sm="12">
                            <Form.Label>How To Use:</Form.Label>
                            <Form.Control as="textarea" rows={2} value={product.descriptionUse}
                                onChange={e => this.setState({ product: { ...product, descriptionUse: e.target.value } })} />
                        </Col>
                        <Col className="mt-3" md="12" sm="12">
                            <Form.Label>Description:</Form.Label>
                            <Form.Control as="textarea" rows={2} value={product.description}
                                onChange={e => this.setState({ product: { ...product, description: e.target.value } })} />
                        </Col>

                    </Row>
                    <Row className="mb-3">
                        <Form.Label className="mt-3">Product Structure:</Form.Label>
                        {this.gridView()}
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                {pendingRequest ? <Button variant='success' disabled><Spinner size="sm" animation='border' /></Button> :
                    <Button variant='success' onClick={this.submit}>Send request</Button>}
            </Modal.Footer>
        </Modal>
    }
}