import { useSearchParams } from 'react-router-dom';
import AdminSupportTicketDetails from '../../../components/admin/support-tickets/Details/AdminSupportTicketDetails';
import AdminAuthenticated from '../../../components/shared/AdminAuthenticated/AdminAuthenticated';
import { ADMIN, SUPERADMIN, SUPPORT } from '../../../constants/ConstantsPermissions';
import NotFoundRoute from '../../shared/NotFoundRoute';

export default function AdminSupportTicketDetailsRoute() {
    const [searchParams] = useSearchParams();
    let ticketId = searchParams.get("ticketId");
    return  ticketId === null ? <NotFoundRoute/> : <AdminAuthenticated roles={[SUPERADMIN, ADMIN, SUPPORT]}><AdminSupportTicketDetails ticketId={ticketId as string}/></AdminAuthenticated>
}