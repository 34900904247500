import React, { RefObject } from "react"
import { Col, Form, Row } from "react-bootstrap"
import { GridAction, GridColumn, GridColumnTypes } from "../../../Grid/Grid.models"
import DynamicGrid from "../../../shared/DynamicGrid/DynamicGrid"
import { GetPagedEarthSupportTicketsQueryString, IFilters, IState, IProps } from "./AdminTicketsList.models"
import AdminSupportTicketsService from "../../../../services/admin/Support/AdminSupportTicketsService"
import supportTicketStatusDisplayBadge, { SupportTicketStatus } from "../../../helpCenter/SupportTickets/SupportTicketStatusEnum"
import { HandThumbsDownFill, HandThumbsUpFill } from "react-bootstrap-icons"
import SupportTicketsService from "../../../../services/helpCenter/SupportTicketService"
import SupportTicketService from "../../../../services/helpCenter/SupportTicketService"
import AdminReviewRequestService from "../../../../services/admin/review-request/AdminReviewRequestService"
import SelecetDepartmentModal from "./SelecetDepartmentModal/SelecetDepartmentModal"
import ShopsSelection from "../../../shops/ShopsSelection/ShopsSelection"

export default class AdminTicketsList extends React.Component<IProps, IState> {
  dynamicGrid: RefObject<DynamicGrid<GetPagedEarthSupportTicketsQueryString>>
  ticketService: AdminSupportTicketsService
  supportTicketService: SupportTicketsService
  reviewRequestService: AdminReviewRequestService
  filters: IFilters

  constructor(props: any) {
    super(props)
    this.state = { editFilters: { departmentId: '' }, filters: { departmentId: '' }, departments: [], departmentId: '' }
    this.filters = { departmentId: '' }
    this.dynamicGrid = React.createRef()
    this.ticketService = new AdminSupportTicketsService()
    this.supportTicketService = new SupportTicketService()
    this.reviewRequestService = new AdminReviewRequestService()
  }

  componentDidMount() {
    this.supportTicketService.getDepartments().then((departments) => {
      this.setState({ departments: departments.map(department => { return { id: department.value, title: department.title } }), departmentId: departments[0].value })
    })
  }

  satisfactionLevelView = (isGood: boolean) => {
    return isGood ? <HandThumbsUpFill className="text-success" /> : <HandThumbsDownFill className="text-danger" />
  }

  reload = () => this.dynamicGrid.current.fetch()

  gridView = (isActive?: boolean, hasDepartment?: boolean, onlyMediationRequested?: boolean, status?: SupportTicketStatus) => {
    let gridColumns: GridColumn[] = [
      { title: "Title", key: "title" },
      { title: "Ticket Number", key: "ticketNumber" },
      { title: "User", key: "title" },
      { title: "Support Agent", key: "handlerName" },
      { title: "Modified", key: "modifyAt", type: GridColumnTypes.Date },
      { title: "Status", key: "status", type: GridColumnTypes.Enum },
      { title: "Liked", key: "isGood" },
    ]

    let gridActions: GridAction[] = [
      {
        caption: "Details",
        variant: "outline-info",
        generateLink: (row) => `/admin/support-ticket-report/?ticketId=${row.id}`,
      }
    ]

    if (this.props.hasSelecteDepartment) {
      gridActions.push({
        caption: "Select Department",
        variant: "outline-primary",
        onClick: (row) => this.setState({ ticketId: row.id }),
        skipSpinner: true
      })
    }

    return (
      <>
        <DynamicGrid<GetPagedEarthSupportTicketsQueryString>
          ref={this.dynamicGrid}
          columns={gridColumns}
          onFetch={
            this.props.isReviewRequest
              ? this.ticketService.getSupportTicketArchive
              : this.ticketService.getPaged
          }
          filtersForm={this.filtersView}
          actions={gridActions}
          hiddenFilterkeys={['hasDepartment', 'isActive']}
          defaultFilters={{ isActive, hasDepartment, onlyMediationRequested, status }}
          refactorRow={(row) => {
            return { ...row, status: supportTicketStatusDisplayBadge(row.status), isGood: row.isGood != null && this.satisfactionLevelView(row.isGood) }
          }}
        />
      </>
    )
  }

  filtersView = (filters: GetPagedEarthSupportTicketsQueryString, onChange: (newFilters: GetPagedEarthSupportTicketsQueryString) => void) => {
    return <>
      {
        this.props.hasDepartment && <Form.Group>
          <label htmlFor="supportTicketReply.department">Department:</label>
          <Form.Select aria-label="Select Department" id="supportTicketReply.department" value={filters.id}
            onChange={(e) => onChange({ ...filters, id: e.target.value })}>
            {
              this.state.departments.map((department) => <option value={department.id} key={department.id}>{department.title}</option>)
            }
          </Form.Select>
        </Form.Group>
      }

      <Form.Group>
        <label>Shop (Any Side):</label> 
        <ShopsSelection selectedShopId={filters.anySideShopId ?? 'all'} onChanged={(shop) => onChange({ ...filters, anySideShopId: shop.value == 'all' ? undefined : shop.value })} additionalItem={{ title: "All Shops", value: 'all' }} />
      </Form.Group>


      <Form.Group className="mt-2">
        <label>Ticket Number</label>
        <Form.Control value={filters.ticketNumber} onChange={(e) => {
          onChange({ ...filters, ticketNumber: e.target.value })
        }} placeholder="Enter ticket number" />
      </Form.Group>
    </>
  }

  render(): React.ReactNode {
    return (
      <>
        {this.gridView(this.props.isActive, this.props.hasDepartment, this.props.onlyMediationRequested, this.props.status)}
        {this.state.ticketId && <SelecetDepartmentModal departments={this.state.departments} reload={this.reload} ticketId={this.state.ticketId} onClose={() => this.setState({ ticketId: undefined })} />}
      </>
    )
  }
}