import React from "react"
import { CurrencyDollar, ThreeDotsVertical, Speedometer2, Shop, People, Person, CardList, Gear } from "react-bootstrap-icons";
import './AdminNavMenu.scss'
import { NavLink } from "react-router-dom";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import CoverFullScreen from "../../../CoverFullScreen/CoverFullScreen";
import { ADMIN, CONTENT_WRITER, MEDIATOR, SUPERADMIN, SUPPORT } from "../../../../../constants/ConstantsPermissions";
import AdminAuthenticated from "../../../../shared/AdminAuthenticated/AdminAuthenticated";

export interface IMenu {
    url: string,
    icon: any,
    label: string,
    permission: string | null
    policy?: string
    roles: string[]
    childs?: IMenu[]
}
export default class AdminNavMenu extends React.Component<{}, { showNavbar: boolean, isMobile: boolean, currentRoute: string }> {
    constructor(props: any) {
        super(props)
        this.state = { isMobile: false, showNavbar: false, currentRoute: '' }
    }

    componentDidMount() {
        this.setState({ isMobile: window.innerWidth > 960 ? false : true, currentRoute: window.location.pathname })
    }

    componentDidUpdate() {
        if (window.location.pathname !== this.state.currentRoute) this.setState({ currentRoute: window.location.pathname })
    }

    onToggle = () => {
        if (this.state.isMobile)
            this.setState({ showNavbar: !this.state.showNavbar })
        return
    }

    menuItemView = (url: string, index: number, icon: any, label: string, childs?: IMenu[]) => {
        return (
            childs ? <NavDropdown key={index} title={<div style={{ color: 'rgba(0,0,0,.55)' }}><span> {icon}<span>{label}</span></span></div>} id="basic-nav-dropdown">
                {
                    childs.map((child, childIndex) => {
                        return <div className={this.state.currentRoute === url ? 'active nav-link' : 'nav-link'} onClick={() => this.onToggle()}>
                            <NavLink key={childIndex} to={child.url}><span>{child.label}</span></NavLink>
                        </div>
                    })
                }
            </NavDropdown> :
                <div className={this.state.currentRoute === url ? 'active nav-link' : 'nav-link'} key={index} onClick={() => this.onToggle()}>
                    <NavLink to={url}> {icon}<span>{label}</span></NavLink>
                </div>
        )
    }

    render(): React.ReactNode {
        const items: IMenu[] = [
            { icon: <Speedometer2 />, label: 'Dashboard', url: 'admin/dashboard', permission: null, roles: [ADMIN, SUPERADMIN] },
            { icon: <CurrencyDollar />, label: 'Financials', url: 'admin/financials', permission: null, roles: [ADMIN, SUPERADMIN] },
            {
                icon: <Shop />, label: 'Market', url: '', permission: null, roles: [ADMIN, SUPERADMIN], childs: [
                    { icon: <Shop />, label: 'Shops', url: 'admin/shops', permission: null, roles: [ADMIN, SUPERADMIN] },
                    { icon: <Shop />, label: 'Sellers', url: 'admin/market', permission: null, roles: [ADMIN, SUPERADMIN] },
                    { icon: <Shop />, label: 'Categories', url: 'admin/categories', permission: null, roles: [ADMIN, SUPERADMIN] },
                    { icon: <Shop />, label: 'Products', url: 'admin/products', permission: null, roles: [ADMIN, SUPERADMIN] },
                ]
            },
            { icon: <Person />, label: 'Users', url: 'admin/users', permission: null, roles: [ADMIN] },
            {
                icon: <People />, label: 'Support', url: '', permission: null, roles: [ADMIN, SUPERADMIN, SUPPORT], childs: [
                    { icon: <People />, label: 'Help Center', url: 'admin/support-ticket', permission: null, roles: [ADMIN, SUPERADMIN, SUPPORT]},
                    { icon: <People />, label: 'Review Request', url: 'admin/review-request', permission: null, roles: [ADMIN, SUPERADMIN, MEDIATOR] },
                ]
            },
            { icon: <CardList />, label: 'Article', url: 'admin/article', permission: null, roles: [ADMIN, SUPERADMIN, CONTENT_WRITER] },
            { icon: <Gear />, label: 'Setting', url: 'admin/setting', permission: null, roles: [ADMIN, SUPERADMIN, CONTENT_WRITER] },
        ]

        return (
            <>
                <Navbar className="NavMenu" bg="light" expand="lg" style={{ zIndex: '3' }} expanded={this.state.showNavbar} onToggle={() => this.onToggle()}>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"><ThreeDotsVertical /></Navbar.Toggle>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            {this.props.children}
                            {
                                items.map((item, index) => {
                                    return (
                                        item.roles !== null ?
                                            <AdminAuthenticated roles={item.roles}>
                                                {this.menuItemView(item.url, index, item.icon, item.label, item.childs)}
                                            </AdminAuthenticated>
                                            : this.menuItemView(item.url, index, item.icon, item.label, item.childs))
                                })
                            }
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                {this.state.isMobile && <CoverFullScreen onClick={() => this.onToggle()} isHide={!this.state.showNavbar} />}
            </>
        );
    }
}