import React, { RefObject } from "react";
import { Button, Modal, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import Note from "../../../shared/Note/Note";
import OffersService, { OwnedOfferDto } from "../../../../services/orders/OffersService";
import { toast } from 'react-toastify';
import DynamicGrid from "../../../shared/DynamicGrid/DynamicGrid";
import { GridAction, GridColumn, GridColumnTypes } from "../../../Grid/Grid.models";
import offerStatusEnumDisplayBadge, { OfferStatusEnum } from "../OfferStatusEnum";
export default class OwnedArchiveOffersBoard extends React.Component<{  }, { offers: OwnedOfferDto[] }> {
    dynamicGrid: RefObject<DynamicGrid<any>>;
    offersService: OffersService;
    constructor(props: any) {
        super(props);
        this.dynamicGrid = React.createRef();
        this.offersService = new OffersService();
        this.state = { offers: [] }
    }


    render(): React.ReactNode {
        let gridColumns: GridColumn[] = [
            { title: 'Number', key: 'number' },
            { title: 'Product Title', key: 'productTitle' },
            { title: 'Quantity', key: 'quantity' },
            { title: 'Accepted', key: 'acceptedCount' },
            { title: 'Max Price', key: 'price' },
            { title: 'Closed At', key: 'closedAt', type: GridColumnTypes.Date },
            { title: 'Expire At', key: 'expireAt', type: GridColumnTypes.Date },
            { title: 'Status', key: 'statusBadge' },
        ];

        let gridActions: GridAction[] = [
        ];

        return <>
            <Note>Your all  offers (open and closed)</Note>
            <DynamicGrid<any> ref={this.dynamicGrid}
                columns={gridColumns}
                actions={gridActions}
                onFetch={this.offersService.get}
                refactorRow={(row) => {
                    return {
                        ...row,
                        statusBadge: offerStatusEnumDisplayBadge(row.status),
                    }
                }} />
        </>

    }
}