import React, { RefObject } from "react";
import { Alert, Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { Navigate } from "react-router";
import { toast } from "react-toastify";
import SupportTicketService from "../../../../services/helpCenter/SupportTicketService";
import EditorComponent from "../../../shared/Editor/Editor";
import Uploader from "../../../shared/Uploader/Uploader";

export default class CreateSupportTicket extends React.Component<{}, State> {
    supportTicketService: SupportTicketService;
    uploader: RefObject<Uploader>
    constructor(props: any) {
        super(props);
        this.state = { title: '', text: '', departmentId: '', loading: false, departments: [], navigator: false, createdTicketId: '', attachmentNames: [] };
        this.supportTicketService = new SupportTicketService();
        this.uploader = React.createRef()
    }

    componentDidMount() {
        var text = localStorage.getItem('support-ticket-draft');
        if (text !== undefined && text !== null)
            this.setState({ text })

        this.supportTicketService.getDepartments().then((departments) => {
            this.setState({ departments: departments.map(department => { return { id: department.value, title: department.title } }), departmentId: departments[0].value })
        })
    }

    onChangeText = (text: string) => {
        localStorage.setItem('support-ticket-draft', text);
        this.setState({ text: text });
    }

    createHandle = () => {
        if (!this.state.text || !this.state.title) {
            toast.error('Please complete the form!');
            return;
        }
        // this.setState({ file: this.uploader.current?.getFile() })
        this.create()
    }


    create = () => {
        this.setState({ loading: true });
        this.supportTicketService.create(this.state.title, this.state.text, this.state.departmentId, this.state.attachmentNames).then(response => {
            toast.success('Your ticket created successfully');
            this.setState({ text: '', title: '', createdTicketId: response, navigator: true });
            localStorage.removeItem('support-ticket-draft');
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    handleUpload = (filename: string) => {
        this.setState({ attachmentNames: [...this.state.attachmentNames, filename] })
    }

    handleRemoved = (filename: string) => {
        let temp = this.state.attachmentNames
        temp.splice(temp.indexOf(filename), 1);
        this.setState({ attachmentNames: temp })
    }

    render(): React.ReactNode {
        return <div>
            {this.state.navigator && <Navigate to={`/support-ticket?id=${this.state.createdTicketId}`} />}
            <h3>Create Support Ticket</h3>
            <Row>
                <Col md="6" sm="12" className="mb-2">

                    <label htmlFor="supportTicketReply.department">Department:</label>

                    <Form.Select aria-label=">Select Department" id="supportTicketReply.department" value={this.state.departmentId}
                        onChange={(e) => this.setState({ departmentId: e.target.value })}>
                        {
                            this.state.departments.map((department) => <option value={department.id} key={department.id}>{department.title}</option>)
                        }
                    </Form.Select>

                    <label htmlFor="supportTicketReply.title">Subject:</label>
                    <input value={this.state.title}
                        id="supportTicketReply.title"
                        className="form-control"
                        onChange={(e) => this.setState({ title: e.target.value })} />
                    <label htmlFor="supportTicketReply.text">Issue:</label>
                    <EditorComponent onChange={this.onChangeText} />
                    <Col md="6" className="mb-2 mt-2">
                        <Uploader
                            ref={this.uploader} multiple folderName={"tickets"}
                            onRemovd={this.handleRemoved}
                            onUpload={this.handleUpload} imageAddress={""} />
                    </Col>
                    {
                        this.state.loading ? <Button variant="success" size="sm" disabled><Spinner size="sm" animation="border" /></Button>
                            : <Button variant="success" size="sm" onClick={this.createHandle}>Send</Button>
                    }
                </Col>
                <Col md="6" sm="12">
                    <Alert variant="success">
                        <Alert.Heading>How to respond to support tickets?</Alert.Heading>
                        <p>
                            You register a support ticket and it will be placed in the support queue and reviewed immediately.<br />
                            Tickets can take up to 24 working hours to be answered.
                        </p>
                        <p>
                            We will notify you when we respond.
                        </p>
                    </Alert>
                </Col>
            </Row>
        </div>
    }
}

interface State {
    title: string,
    text: string,
    departmentId: string,
    file?: File | null,
    loading: boolean,
    departments: { id: string, title: string }[]
    attachmentNames?: string[],
    createdTicketId?: string,
    navigator: boolean,
}