import { GridResult } from "../../components/Grid/Grid.models";
import { PaginationQuery } from "../../components/Grid/Pagination/Pagination.models";
import { mapToDataOnly } from "../common/HttpUtility";
import { ShopMemberApiFactory } from "../../generated-api";

export default class ShopMemberService {
    get(query: PaginationQuery): Promise<GridResult<IShopMemberRowDTO>> {
        return mapToDataOnly<GridResult<IShopMemberRowDTO>>(ShopMemberApiFactory().buyerShopMemberGet(undefined, undefined, query.pageskip - 1, query.pagesize))
    }

    delete(memberId: string): Promise<any> {
        return mapToDataOnly<any>(ShopMemberApiFactory().buyerShopMemberShopMemberIdDelete(memberId));
    }

    create(dto: { email: string }, returnUrl: string): Promise<{}> {
        return mapToDataOnly<{}>(ShopMemberApiFactory().buyerShopMemberPost(dto));
    }
}

interface IShopMemberRowDTO {
    id: string;
    name: string;
    email: string;
    privileges: string[];
}

export interface IShopMemberBasePrivilegeDTO {
    id: string;
    title: string;
    shortTitle: string;
    isNumberValue: boolean;
}