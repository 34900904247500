import axios from "axios";
import React, { RefObject } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { Phone } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import TwoFactorService from "../../../../../services/identity/TwoFactorService";
import RecoveryCodeModal from "../RecoveryCode/RecoveryCode";
import IState, { IProps } from "./TOTPForm.models";
import authenticationContext from "../../../../../contexts/AuthenticationContext/AuthenticationContext";
import './TOTPForm.scss';

export default class TOTPForm extends React.Component<IProps, IState> {
    static contextType = authenticationContext;
    context!: React.ContextType<typeof authenticationContext>;
    input: RefObject<HTMLInputElement>
    recoveryCodeModalRef: RefObject<RecoveryCodeModal>
    twoFactorService: TwoFactorService
    constructor(props: IProps) {
        super(props);
        this.state = { totp: '', showModal: false, showRecoveryModal: false, loading: false };
        this.input = React.createRef();
        this.recoveryCodeModalRef = React.createRef();
        this.twoFactorService = new TwoFactorService()
    }

    componentDidMount() {
        this.setState({ showModal: this.props.showModal });
    }

    componentDidUpdate(prevProps: { showModal: boolean }) {
        if (prevProps.showModal !== this.props.showModal) {
            this.setState({ showModal: this.props.showModal });
        }
    }

    hide = () => {
        this.setState({ showModal: false });
        this.props.onHide();
        this.setState({ loading: false });
    }

    focusToInput = () => {
        this.input.current?.focus();
    }

    recovery = (totp: string, disableTwoFactor: boolean) => {
        if (this.props.isLoginPage) {
            this.twoFactorService.signInWithRecoveryCode({ disableTwoFactor, email: this.props.signInInformation?.userName as string, password: this.props.signInInformation?.password as string }, totp).then((response) => {
                this.setState({ showRecoveryModal: false, showModal: false })
                this.props.login && this.props.login(response.accessToken, true)
            }).finally(() => {
                this.recoveryCodeModalRef.current?.resetSpinner()
            })
        } else {
            this.twoFactorService.disableWithRecoveryCode(totp).then(() => {
                toast.success('Two-factor has been disabaled successful')
                this.setState({ showRecoveryModal: false, showModal: false })
                this.props.onHandle && this.props.onHandle()
            }).finally(() => {
                this.recoveryCodeModalRef.current?.resetSpinner()
            })
        }
    }

    submit = () => {

        this.setState({ loading: true });
        if (this.context.twoFactorAvtive) {
            if (this.state.totp === '') return toast.error("Please enter your TOTP")

            if (this.state.totp.length !== 6) return toast.error("Entered code must be 6 digits, please enter correctly")

            axios.defaults.headers.common['totp'] = this.state.totp;
        }
        this.props.onSubmit && this.props.onSubmit(this.state.totp);

        if (!this.context.twoFactorAvtive)
            this.hide();

        this.setState({ loading: false });
    }

    onKeyDown = (event: React.KeyboardEvent<any>): void => {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            this.submit();
        }
    }

    render(): React.ReactNode {
        var hasTwofactor = this.context.twoFactorAvtive;
        return (<>
            {hasTwofactor && this.modalView()}
            {/* {(!hasTwofactor && this.state.showModal && !this.props.loading && !this.state.loading ) && this.submit()} */}
        </>
        )
    }

    modalView() {
        return <>
            <Modal
                size="sm"
                show={this.state.showModal}
                onHide={() => this.hide()}
                onShow={this.focusToInput}
                className="verify-code"
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Two Factor
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row style={{ textAlign: 'center' }}>
                        <Col className="p-3"><Phone size="60px" /></Col>
                        <label>Enter your verification code generated by the authenticator application</label>
                    </Row>
                    <Form>
                        <Form.Group className="mt-4" controlId="formBasicEmail">
                            <Form.Label>TOTP</Form.Label>
                            <Form.Control type="text" placeholder="Enter code..." autoComplete="off" ref={this.input} onKeyDown={(e) => this.onKeyDown(e)}
                                value={this.state.totp} onChange={(e) => this.setState({ totp: e.target.value })} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="footer-reverse">
                    {
                        this.props.loading ? <Button disabled variant="success">
                            <Spinner animation="border" size="sm" />
                        </Button> : <Button type="submit" variant="success" onClick={() => this.submit()}>Submit</Button>
                    }
                    {this.props.hasRecovery && (<div> having trouble ? <a className="link" onClick={() => this.setState({ showRecoveryModal: true })}> Try Another Way </a></div>)}
                </Modal.Footer>
            </Modal>
            {
                this.props.hasRecovery && <RecoveryCodeModal showDisableTwoFactor={this.props.isLoginPage} onSubmit={(otp, disabledTwoFactor) => { this.recovery(otp as string, disabledTwoFactor as boolean) }} closeModal={() => this.setState({ showRecoveryModal: false })} showModal={this.state.showRecoveryModal} />
            }
        </>
    }
}