import React from "react"
import SectionTitle from "./SectionTitle/SectionTitle"

export default class SectionView extends React.PureComponent<{ title: string, description: string }, {}> {
    render(): React.ReactNode {
        return <div className="line-normal">
            <SectionTitle title={this.props.title} />
            <div className="p-large fs-sm text-justify" dangerouslySetInnerHTML={{ __html: this.props.description }}></div>
        </div>
    }
}