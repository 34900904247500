import React from "react"
import Actions from "../../shared/Actions/Actions"

export default class GuidComponent extends React.PureComponent<{}, {}> {
    render(): React.ReactNode {
        return (<div className="text-center">
            <span className="font-medium fs-md pb-3 text-black">Want to join API Gift today? You're a click away from it!</span>
            <div className="mt-lg">
                <Actions showAlreadyMember direction="vertical" />
            </div>
        </div>)
    }
}