import React from "react";
import { Badge } from "react-bootstrap";
import { PlusCircle } from "react-bootstrap-icons";
import { SelectionView } from "../../../../services/common/Models";
import SupportTicketTagService from "../../../../services/helpCenter/SupportTicketTagService";
import CreateTagModal from "../create-tag-modal/CreateTagModal";

export default class TagList extends React.PureComponent<{ datas: SelectionView[], ticketId: string, onHandle: () => void }, { showModal: boolean }> {

    supportTicketTagService: SupportTicketTagService;
    constructor(props: any) {
        super(props);
        this.state = { showModal: false };
        this.supportTicketTagService = new SupportTicketTagService();
    }

    handleModal = (value: boolean) => {
        this.setState({ showModal: value });
    }

    closeModal = () => {
        this.handleModal(false);
        this.props.onHandle();
    }

    deleteTag = (id: string) => {
        this.supportTicketTagService.deleteById(id).then(response => {
            this.props.onHandle();
        })
    }

    render(): React.ReactNode {
        const { datas, ticketId } = this.props;
        const badges = ['warning', 'info', 'success', 'primary', 'dark']
        let i = 0;
        return <>
            <div>{datas.length > 0 ? datas.map((data, index) => {
                i = i > 6 ? 0 : i;
                i++;
                return <Badge bg={badges[i - 1]} className="mr-1" key={index}>{data.title} <button type="button" style={{ fontSize: "8px" }} className="btn-close ml-1" aria-label="Close" onClick={() => this.deleteTag(data.value)}></button> </Badge>
            }) : <span onClick={() => this.handleModal(true)} style={{ color: 'green', fontSize: 14 }}>Add Tag </span>}
                <span onClick={() => this.handleModal(true)} style={{ color: 'green', fontSize: 14 }}> <PlusCircle /> </span>
            </div>

            <CreateTagModal ticketId={ticketId} showModal={this.state.showModal} onClose={this.closeModal} />
        </>
    }
}