import { Badge } from "react-bootstrap";

export enum UserStatusEnum {
    Register,
    Normal,
    Suspended
}

export function userStatusDisplayCaption(status: number) {
    let types = ['Pending', 'Accepted', 'none', 'Rejected'];
    return types.length <= status ? status : types[status];
}

export function userStatusColor(status: number) {
    let types = ['warning', 'success', 'dark', 'danger'];
    return types.length <= status ? 'dark' : types[status];
}

export default function userStatusDisplayBadge(status: number) {
    return <Badge bg={userStatusColor(status)}>{userStatusDisplayCaption(status)}</Badge>;
}