import React from "react";
import { Badge, Button, OverlayTrigger, Spinner, Table, Tooltip } from "react-bootstrap";
import '../../Grid/Grid.scss'
import Moment from 'moment';
import { NavLink } from "react-router-dom";
import { GridAction, GridColumnTypes, GridResult } from "../../Grid/Grid.models";
import Amount from "../../utilities/Amount/Amount";
import ShopAuthenticated from "../ShopAuthenticated/ShopAuthenticated";
import { PaginationQuery } from "../../Grid/Pagination/Pagination.models";
import Pagination from "../../Grid/Pagination/Pagination";
import GridProps, { GridState } from "./DynamicGrid.models";
import ExportButtonView from "../../reports/ExportButtonView/ExportButtonView";
import FilterBar from "../../Grid/Filters/FilterBar";

export default class DynamicGrid<TFilter> extends React.PureComponent<GridProps<TFilter>, GridState<TFilter>> {
    /**
     *
     */
    constructor(props: any) {
        super(props);
        var defaultFilters = this.props.defaultFilters ?? {} as TFilter;
        this.state = {
            columns: [], rows: [], actions: [], startIndex: 1, totalRows: 0, spinnerPoint: [], loading: true, filters: defaultFilters,
            editFilters: defaultFilters, pageNumber: 1, pageSize: 10
        };
    }

    componentDidMount = () => {
        this.setState({ columns: this.props.columns, actions: this.props.actions === undefined ? [] : this.props.actions });
    }

    componentDidUpdate(prevProps: Readonly<GridProps<TFilter>>, prevState: Readonly<GridState<TFilter>>, snapshot?: any): void {
        if (this.props.skipUpdateFilters !== true && prevProps.defaultFilters !== this.props.defaultFilters) {
            this.setState({ filters: this.props.defaultFilters });
            this.fetch();
        }
    }

    applyFilters = (onApplied: () => void) => {
        this.setState({ filters: this.props.defaultFilters }, onApplied);
    }

    load = (rows: any[], totalRows: number) => {
        let records: Record<string, any>[] = rows.map((row) => {
            let columns: Record<string, any> = [];
            let index = 0;
            for (let key of Object.keys(row)) {
                columns[key] = Object.values(row)[index++];
            }
            return columns;
        });
        this.setState({ rows: records, totalRows: totalRows, loading: false, spinnerPoint: [] });
    }

    loadFirstPage = () => {
        this.setState({ pageNumber: 1 })
        this.fetch(10)
    }

    changeFilter = () => {
        // this.setState({filters})
    }

    filtersView = () => {
        return <FilterBar large onApply={() => {
            this.setState({ filters: this.state.editFilters }, this.loadFirstPage);

        }}
            onOpened={() => this.setState({ editFilters: { ...this.state.filters } })}
            filters={this.state.filters}
            onRemoveFilter={this.removeFilter}
            hiddenKeys={this.props.hiddenFilterkeys}
            renderCustomFilterView={this.props.refactorFilterDisplay}
            readonly={this.props.filtersForm === undefined}
        >
            {this.props.filtersForm !== undefined && this.props.filtersForm(this.state.editFilters, (newFilters) => this.setState({ editFilters: newFilters }))}
        </FilterBar>
    }


    removeFilter = (key: string) => {
        let filters: any = this.state.filters;
        filters[key] = '';
        this.setState({ filters }, this.loadFirstPage);
    }


    render(): React.ReactNode {
        return <div>
            {
                !this.props.hideFilterBar && (
                    !this.state.loading && <div className="d-flex">
                        {
                            (this.props.filtersForm !== undefined || this.props.defaultFilters !== undefined) &&
                            <div className="w-100 me-2">{this.filtersView()}</div>
                        }
                        {this.props.onExportClick !== undefined &&
                            <div className="d-flex justify-content-end"><ExportButtonView onClick={() => this.props.onExportClick(this.state.filters)} /></div>
                        }
                    </div>
                )
            }
            <div className={"Grid" + (this.state.loading ? ' loading ' : '')}>
                <Table striped={!this.state.loading} hover responsive size={this.props.size ?? "lg"}>
                    <thead>
                        <tr>
                            <th>#</th>
                            {this.state.columns.map((column) => {
                                if (column.tooltip)
                                    return <th className={column.align && `text-${column.align}`} key={column.key}>
                                        <OverlayTrigger
                                            key="top"
                                            placement="top"
                                            overlay={
                                                <Tooltip id="tooltip-top">
                                                    {column.tooltip}
                                                </Tooltip>
                                            }
                                        >
                                            <span>{column.title}</span>
                                        </OverlayTrigger>
                                    </th>

                                else
                                    return <th className={column.align && `text-${column.align}`} key={column.key}>{column.title}</th>
                            })}
                            {this.state.actions.length == 0 ? null : (<th>Actions</th>)}
                        </tr>
                    </thead>
                    {
                        this.state.loading ? <tbody>
                            <tr className="empty-row"><td colSpan={this.state.columns.length + 2}><Spinner animation="border" /></td></tr>
                        </tbody> :
                            (this.state.rows.length > 0 ? this.bodyView() : this.emptyBodyView())
                    }
                </Table>
                <Pagination onChangePageNumber={this.changePageNumber} totalRows={this.state.totalRows} pageNumber={this.state.pageNumber}
                    pagesize={this.props.defaultPagesize}
                    onChangePageSize={this.fetch}></Pagination>
            </div>
        </div>;
    }
    resetSpinner = () => {
        this.setState({ spinnerPoint: [] });
    }
    changePageNumber = (number: number, size: number) => {
        this.setState({ pageNumber: number }, () => this.fetch(size))
    }
    fetch = (size: number = undefined) => {
        if (size !== undefined)
            this.setState({ pageSize: size })
        else
            size = this.state.pageSize;
        var pageQuery: PaginationQuery = { pageskip: this.state.pageNumber, pagesize: size }
        this.setState({ startIndex: ((pageQuery.pageskip - 1) * size) + 1, loading: true })
        this.props.onFetch(this.state.filters, pageQuery)
            .then((gridResult: GridResult<any>) => {
                let rows = gridResult.rows;
                if (this.props.refactorRow !== undefined)
                    rows = gridResult.rows.map(this.props.refactorRow);
                this.load(rows, gridResult.totalRows);
                this.props.onFetched && this.props.onFetched(gridResult);
            })
            .finally(() => {

                this.setState({ loading: false })
            });
    }
    bodyView() {
        const badges = ['warning', 'info', 'success', 'primary', 'dark']
        let i = 0;
        return (<tbody>
            {this.state.rows.map((row, index) => (
                <tr key={index} className={this.props.rowClassName && this.props.rowClassName(row)}>
                    <td>{this.state.startIndex + index}</td>
                    {this.state.columns.map((column) => {
                        switch (column.type) {
                            case GridColumnTypes.Date:
                                return <td title={(Moment.utc(row[column.key]).utc().format('YYYY/MM/DD HH:mm')) + " UTC"} key={column.key}>
                                    {row[column.key] != null ? Moment.utc(row[column.key]).local().format('YYYY/MM/DD HH:mm') : ''}</td>;
                            case GridColumnTypes.DateOnly:
                                return <td title={(Moment.utc(row[column.key]).format('YYYY/MM/DD')) + " UTC"} key={column.key}>{row[column.key] != null ? Moment.utc(row[column.key]).local().format('YYYY/MM/DD') : ''}</td>;
                            case GridColumnTypes.TimeOnly:
                                return <td title={(Moment.utc(row[column.key]).format('HH:mm')) + " UTC"} key={column.key}>{row[column.key] != null ? Moment.utc(row[column.key]).local().format('HH:mm') : ''}</td>;
                            case GridColumnTypes.Amount:
                                return <td key={column.key}><Amount formatMoney={row[column.key] > 0} value={row[column.key]} /></td>;
                            case GridColumnTypes.BadgeList:
                                return row[column.key] ?
                                    <td key={column.key + index}>
                                        {
                                            row[column.key].map((item, index) => {
                                                i = i > 6 ? 0 : i;
                                                i++;
                                                return <Badge bg={badges[i - 1]} className="mr-1" key={index}>{item}</Badge>;
                                            })
                                        }
                                    </td> :
                                    <td></td>;
                            default:
                                return <td key={column.key}>{row[column.key]}</td>;
                        }
                    })}
                    {this.state.actions.length === 0 ? null : (<td>
                        {this.state.actions.map((action, actionIndex) => (
                            <span key={actionIndex}>
                                {(this.state.spinnerPoint[0] === index && this.state.spinnerPoint[1] === actionIndex) ? <Spinner className="spinner" animation="grow" size="sm" /> :
                                    action.permission ? <ShopAuthenticated permission={action.permission}> {this.actionView(action, index, actionIndex, row)}</ShopAuthenticated> : this.actionView(action, index, actionIndex, row)
                                }
                            </span>
                        ))}
                    </td>)}
                </tr>))}
        </tbody>);
    }

    emptyBodyView() {
        return (<tbody>
            <tr className="empty-row">
                <td colSpan={this.state.columns.length + 1}>not exist any data.</td>
            </tr>
        </tbody>);
    }

    actionView(action: GridAction, index: number, actionIndex: number, row: Record<string, any>) {
        if (action.render !== undefined)
            return action.render(row);
        return (action.generateLink === undefined ?
            (this.state.spinnerPoint[0] !== index) &&
            <Button className="action-button mt-1" size="sm" disabled={this.state.spinnerPoint.length !== 0 || (action.checkIsDisabled ? action.checkIsDisabled(row) : false)} variant={action.variant} onClick={() => {
                if (action.onClick !== undefined) {
                    if (action.skipSpinner !== true)
                        this.setState({ spinnerPoint: [index, actionIndex] })
                    action.onClick(row)
                }
            }} > {action.caption}</Button>
            : <NavLink to={action.generateLink(row)} className={(action.checkIsDisabled && action.checkIsDisabled(row)) ? 'button-disable' : ''}><Button disabled={action.checkIsDisabled ? action.checkIsDisabled(row) : false} className="action-button" size="sm" variant={action.variant}>{this.state.spinnerPoint[0] === index && this.state.spinnerPoint[1] === actionIndex ? 'loading' : action.caption}</Button></NavLink>)
    }
}