import React from 'react';
import { Alert, Button, Modal, Spinner, Tab, Table, Tabs } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { BarChartLine, Cart3 } from 'react-bootstrap-icons';
import ProductCatalog from '../ProductCatalog/ProductCatalog';
import AutoBuyPlan from '../AutoBuyPlan/AutoBuyPlan';
import CreateNewOffer from '../CreateNewOffer/CreateNewOffer';
import Note from '../../shared/Note/Note';
import PriceComparison from '../../inventory/InventoryPlans/InventoryProductConfig/PriceComparison';


interface Props {
    mode: 'express' | 'trade'
    title: string
    productId: string
    onClose: () => void
    onAddToCart: (count: number, productId: string, sellerShopId: string, unitPrice: number) => Promise<any>
}

export default class BuyProductCatalog extends React.Component<Props, { loading: boolean, pendingRequest: number, activeTab: string }> {
    constructor(props: any) {
        super(props);
        this.state = { loading: true, pendingRequest: 0, activeTab: 'offer' }
    }

    activeSection = (key: 'express' | 'trade') => {
        switch (key) {
            default: case 'express':
                return <>
                    <Note>Sellers who have the product in stock are displayed in this section and delivery is immediate</Note>
                    <ProductCatalog
                        productId={this.props.productId}
                        onAddToCart={this.props.onAddToCart}
                        onClose={this.props.onClose} />
                </>
            case 'trade':
                return <>

                    <Tabs
                        activeKey={this.state.activeTab}
                        onSelect={(key) => this.setState({ activeTab: key })}
                        className="mb-3" mountOnEnter={true} unmountOnExit={true}>
                        <Tab eventKey="offer" title="Offer">
                            <Note>
                                In cases where the product was not available by any of the sellers or the price you want is lower, you can create your offer so that we share it with all the sellers (this is an auction for your purchase!)
                            </Note>
                            <CreateNewOffer productId={this.props.productId} onCreated={this.props.onClose} />
                        </Tab>
                        <Tab eventKey="auto" title="Auto Offer">
                            <Note>
                                In this section, you can make settings so that your warehouse is filled automatically (create automatic order and  offer), it can be very useful if you use the web service.
                            </Note>
                            <AutoBuyPlan productId={this.props.productId} />
                        </Tab>
                    </Tabs>

                </>
        }
    }

    render(): React.ReactNode {
        return <Modal size="lg"
            className='ProductCatalogSellers'
            show={true}
            backdrop="static"
            onHide={this.props.onClose}>
            <Modal.Header closeButton>
                <Modal.Title>{this.props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {this.activeSection(this.props.mode)}
            </Modal.Body>
            <Modal.Footer>

                {
                    this.props.mode == 'trade' && <>
                        <h5 className="text-muted pt-5" style={{textAlign:'left',width:'100%'}}> 
                            <BarChartLine /> Price comparison</h5>
                        <PriceComparison productId={this.props.productId} apiPlanPrice={0} oneUnitPlanPrice={0} hideOwnedPlan/>
                    </>
                }

            </Modal.Footer>
        </Modal>
    }
}
