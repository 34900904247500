import API from "../../components/api/API";
import ShopAuthenticated from "../../components/shared/ShopAuthenticated/ShopAuthenticated";
import { API_CONFIG_MENU } from "../../constants/ConstantsPermissions";

export default function APIRoute() {
    return <>
        <ShopAuthenticated showAccessDenied permission={API_CONFIG_MENU}>
            <API />       
        </ShopAuthenticated>
    </>
}