import { Route } from "react-router-dom";
import DefaultLayout from "../../components/layout/DefaultLayout";
import CreateSourceTransactionRoute from "../inventory/CreateSourceTransactionRoute";
import EditCategoryInventoryProductPlanRoute from "../inventory/EditCategoryInventoryProductPlanRoute";
import InventoriesRoute from "../inventory/InventoriesRoute";
import InventorySourceTransactionRoute from "../inventory/InventorySourceTransactionRoute";

export function InventoryRoutes() {
    return (
        <>
            <Route element={<DefaultLayout />}>
                <Route path="/inventory" element={<InventoriesRoute />} />
                <Route path="/inventory-source-transaction" element={<InventorySourceTransactionRoute />} />
                <Route path="/create-inventory-source-transaction" element={<CreateSourceTransactionRoute />} />
                <Route path="/edit-category-inventory-product-plan" element={<EditCategoryInventoryProductPlanRoute />} />
            </Route>
        </>
    )
}
