import React from 'react';
import IState, { IProps } from './AdminWalletTransferFund.models';
import AdminWalletsServices from '../../../../../services/admin/financials/AdminWalletsServices';
import TransferFund from '../../../../TransferFund/TransferFund';

export default class AdminWalletTransferFund extends React.Component<IProps, IState> {
    adminWalletsServices: AdminWalletsServices
    constructor(props: any) {
        super(props)
        this.adminWalletsServices = new AdminWalletsServices()
        this.state = {
            isLoading: false,
            walletBalance: 0,
            transactionId: '',
            blockedBalance: 0,
            showReceipt: false
        }
    }

    componentDidMount() {
        this.adminWalletsServices.getStatus(this.props.walletId).then((walletBalance) => {
            this.setState({ walletBalance: walletBalance.balance, blockedBalance: walletBalance.blockedBalance })
        })
    }

    submit = (destinationAddress: string, amount: number, description: string) => {
        this.setState({ isLoading: true })

        this.adminWalletsServices.transferFund({
            amount,
            description,
            destinationWalletId: destinationAddress,
            sourceWalletId: this.props.walletId
        }).then((response) => {
            this.setState({ isLoading: false, showReceipt: true, transactionId: response.transactionId });
            this.props.reload && this.props.reload()
        })
    }

    render(): React.ReactNode {
        return <>
            <TransferFund
                isLoading={this.state.isLoading}
                showReceipt={this.state.showReceipt}
                walletBlockedBalance={this.state.blockedBalance}
                walletBalance={this.state.walletBalance}
                walletId={this.props.walletId}
                transactionId={this.state.transactionId}
                onClose={this.props.onClose}
                submit={this.submit}
            />
        </>
    }
}
