import { mapToDataOnly } from "../../common/HttpUtility";
import { MediationApiFactory } from "../../../generated-api/admin";

export default class AdminMediationService {
    getDetails(ticketId, orderProductId): Promise<{ decision: DecisionView | null }> {
        return mapToDataOnly<{ decision: DecisionView | null }>(MediationApiFactory().adminMediationDetailsGet(orderProductId, ticketId));
    }
    create(dto: CreateDecisionDTO): Promise<any> {
        return mapToDataOnly<any>(MediationApiFactory().adminMediationPost(dto));
    }

    releaseSellerMoney(id: string): Promise<any> {
        return mapToDataOnly<any>(MediationApiFactory().adminMediationReleaseMoneyOrderItemIdSellerPost(id));
    }

    releaseBuyerMoney(id: string): Promise<any> {
        return mapToDataOnly<any>(MediationApiFactory().adminMediationReleaseMoneyOrderItemIdBuyerPost(id));
    }
}

interface DecisionView {
    id: string;
    creatorUserId: string;
    createDate: string;
    sellerLossAmount: number;
    buyerLossAmount: number;
}

interface CreateDecisionDTO {
    correctnessResult: CorrectnessResults;
    ticketId: string;
    orderProductId: string;
    sellerLossAmount: number;
    buyerLossAmount: number;
}

export enum CorrectnessResults {
    Buyer,
    Seller,
    Both
}