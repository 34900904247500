import React from "react";
import { Dash, Plus, Trash, X } from "react-bootstrap-icons";
import './CartItem.scss'
export default class CartItem extends React.Component<{ title: string, count: number, inventoryCount: number, unitPrice: number, imageSrc: string, onDelete: () => void, onChangeCount: (count: number) => void }> {
    constructor(props: any) {
        super(props);
    }

    render(): React.ReactNode {
        return <div className="CartItem">
            <div className="title">{this.props.title}<div className="delete"><X onClick={this.props.onDelete} /></div> </div>
            <div className="price-and-counter">
                <div className="price">
                    {this.props.unitPrice}$
                </div>
                <div className="counter">
                    {
                        this.props.count > 1 ? < Dash onClick={() => this.props.onChangeCount(this.props.count - 1)} />
                            : <Trash className="text-danger trash" onClick={this.props.onDelete} />
                    }
                    {this.props.count}
                    <span className={this.props.count >= this.props.inventoryCount && this.props.inventoryCount !== null && 'disable'}>
                        <Plus onClick={() => this.props.onChangeCount(this.props.count + 1)} />
                    </span>
                </div>
                <div className="clear"></div>
            </div>
        </div>
    }
}