import React from "react";
import { Button, Col, Row, Card, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import Note from "../../shared/Note/Note";
import CreateReportModal from "../../helpCenter/SupportTickets/Report/CreateReportModal";

export interface IFileType {
    fileName: string,
    title: string
}

export default class ApiDocument extends React.PureComponent<{}, { selectedTitle?: string }> {
    constructor(props: any) {
        super(props);
        this.state = { selectedTitle: undefined }
    }

    render(): React.ReactNode {
        const { selectedTitle } = this.state
        const files: IFileType[] = [
            { fileName: '/api-webhooks-doc.pdf', title: 'Api Webhooks  Documentation ' },
            { fileName: '/Web-API-Documentation.pdf', title: 'Web API Documentation ' },
        ]

        return <>
            <Note>Documentation for working with our API is here, email us if you think it needs improvement. We are eager to improve our API. <a href="mailto:info@apigift.com">info@apigift.com</a></Note>
            <Row className="mt-4 mb-3">
                <Col md="5" sm="12">
                    <Card>
                        <Card.Header>Documentation</Card.Header>
                        {
                            files.map((file, index) => {
                                return <ListGroup key={index} variant="flush">
                                    <ListGroup.Item className="d-flex justify-content-between">
                                        <strong className="p-2">{file.title}</strong>
                                        <div>
                                            <Link to={file.fileName} target="_blank" download><Button size="sm" variant="outline-info">Download</Button></Link>
                                            <Button size="sm" variant="outline-warning" className="ms-1" onClick={() => this.setState({ selectedTitle: file.title })}>Report</Button>
                                        </div>
                                    </ListGroup.Item>
                                </ListGroup>
                            })
                        }
                    </Card>
                </Col>
            </Row>
            {selectedTitle && <CreateReportModal title={selectedTitle} onClose={() => this.setState({ selectedTitle: '' })} />}
        </>
    }
}