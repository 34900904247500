import React from "react";

import { List } from "react-bootstrap-icons";
import { Offcanvas } from "react-bootstrap";

import { articleCategories, IArticleDTO } from "../../../services/helpCenter/ArticleService";
import GridArticle from "./Grid/GridArticle";
import './Articles.scss';
import CategorySidebar from "./Sidebar/CategorySidebar";
import ArticleService from "../../../services/helpCenter/ArticleService";

export default class Articles extends React.Component<Props, State> {
    articleService: ArticleService;
    constructor(props: any) {
        super(props);
        this.articleService = new ArticleService();
        this.state = { articleCategories: [], loading: false, items: [], selectedArticleId: null, showSidebar: false, categoryLoading: false };
    }

    componentDidMount() {
        this.loadArticles()
        this.loadArticleCategory()    
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.categoryId !== this.props.categoryId)
            this.loadArticles();
    }

    loadArticles = () => {
        this.setState({loading: true})
        this.articleService.getArticle(this.props.categoryId ?? '').then(response => this.setState({items: response, loading: false}))
    }

    loadArticleCategory = () => {
        this.setState({categoryLoading: true})
        this.articleService.getArticleCategory().then(response => this.setState({articleCategories: response, categoryLoading: false}))
    }
    
    render(): React.ReactNode {
        return <div className="ArticleCatalog">
            <div className="mobile-category-toggle">
                    <List onClick={() => this.setState({ showSidebar: true })} />
                </div>
            <Offcanvas show={this.state.showSidebar} onHide={() => this.setState({ showSidebar: false })} responsive="lg">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Categories</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <CategorySidebar onNavigate={() => this.setState({ showSidebar: false })} loading={this.state.categoryLoading} fixMargin categories={this.state.articleCategories} />
                </Offcanvas.Body>
            </Offcanvas>
            <div className="article-body">
                <div className="categories">
                    <CategorySidebar categories={this.state.articleCategories} loading={this.state.categoryLoading}  />
                </div>
                <div className="articles">
                    <GridArticle
                        items={this.state.items}
                        isLoading={this.state.loading}
                        generalProductImage={'./images/product-none.png'}
                        onClickRow={(articleId) => this.setState({ selectedArticleId: articleId })}
                    />
                </div>
            </div>
        </div>
    }
}

interface State {
    articleCategories: articleCategories[]
    loading: boolean
    selectedArticleId: string | null,
    items: IArticleDTO[]
    showSidebar: boolean
    categoryLoading: boolean
}

interface Props {
    categoryId?: string
}