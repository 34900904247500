import { PaginationQuery } from "../../../components/Grid/Pagination/Pagination.models"
import { mapToDataOnly } from "../../common/HttpUtility"
import { ArticleApiFactory } from "../../../generated-api/admin";


export default class AdminArticleService {
    getPaged(filters: IArticleFilter, paginationQuery: PaginationQuery): Promise<any> {
        return mapToDataOnly<any>(ArticleApiFactory().adminArticleGet(filters.title, filters.published, filters.articleCategoryId, paginationQuery.pageskip - 1, paginationQuery.pagesize));
    }

    update(id: string, dto: IArticleDTO): Promise<any> {
        return mapToDataOnly(ArticleApiFactory().adminArticleIdPut(id, dto));
    }

    delete(id: string): Promise<any> {
        return mapToDataOnly(ArticleApiFactory().adminArticleIdDelete(id));
    }

    add(dto: IArticleDTO): Promise<any> {
        return mapToDataOnly(ArticleApiFactory().adminArticlePost(dto));
    }

    getById(id: string): Promise<IArticleDTO> {
        return mapToDataOnly<IArticleDTO>(ArticleApiFactory().adminArticleIdGet(id))
    }
}

interface IArticleFilter {
    published?: boolean,
    title?: string
    articleCategoryId?: string
}

export interface IArticleDTO {
    title: string;
    details: string;
    published: boolean;
    imageName: string;
    categoryId: string;
    imageSrc: string;
}