import React, { RefObject } from "react"
import DynamicGrid from "../../../shared/DynamicGrid/DynamicGrid"
import { GridAction, GridColumn, GridColumnTypes } from "../../../Grid/Grid.models"
import AdminCashierService from "../../../../services/admin/financials/AdminCashierService"

export default class Cashier extends React.Component<{}, {}> {
    dynamicGrid: RefObject<DynamicGrid<{}>>
    adminCashierService: AdminCashierService

    constructor(props: any) {
        super(props)
        this.dynamicGrid = React.createRef()
        this.state = { transferWalletId: undefined }
        this.adminCashierService = new AdminCashierService()
    }

    render(): React.ReactNode {
        let gridColumns: GridColumn[] = [
            { title: "Account", key: "accountNumber" },
            { title: "Balance", key: "balance", type: GridColumnTypes.Amount },
            { title: "Real Balance", key: "realBalance", type: GridColumnTypes.Amount },
        ]
        let gridActions: GridAction[] = [
            {
                caption: "Transactions",
                variant: "outline-info",
                generateLink: (row) => `/admin/walletTransactions/?id=${row.walletId}`
            },
        ]

        return (
            <>
                <DynamicGrid<{}>
                    ref={this.dynamicGrid}
                    actions={gridActions}
                    columns={gridColumns}
                    onFetch={this.adminCashierService.getPaged}
                    defaultFilters={{}}
                    hideFilterBar
                    size="sm"
                />
            </>
        )
    }
}