import React from "react"
import { Alert, Button, Card, Form, Spinner, Table } from "react-bootstrap"
import { FileEarmarkExcelFill, FileExcel, Plus } from "react-bootstrap-icons"
import { toast } from "react-toastify"
import InventoryProductOverviewService from "../../../../services/inventory/InventoryProductOverviewService"
import ImportFromExcel from "./ImportFromExcel/ImportFromExcel"
import IState, { ICreateInventoryInformationView, InventoryView, IProps } from "./InventoriesInformation.models"

export default class InventoriesInformation extends React.PureComponent<IProps, IState> {
    inventoryProductOverviewService: InventoryProductOverviewService
    constructor(props: IProps) {
        super(props);
        this.inventoryProductOverviewService = new InventoryProductOverviewService()
        this.state = {
            ignoreInventoryDuplication: false, duplicates: [], showImportFromExcelModal: false,
            ignoreDuplication: false, inventories: [], loading: false, isSelectedAll: false, pendingRequest: false, columns: [], pasteFromExcel: ''
        }
    }

    componentDidMount() {
        this.getColumns()
    }

    getColumns = () => {
        this.setState({ loading: true })
        this.inventoryProductOverviewService.getProductStructureTitleValues(this.props.productId).then((response) => {

            this.setState({ columns: response.reverse(), loading: false })
            this.addRow()
        })
    }

    addRow = () => {
        let tempInventories = this.state.inventories
        let tmpInformation: ICreateInventoryInformationView[] = this.state.columns.map((column) => { return { key: column.value, value: '' } as ICreateInventoryInformationView })
        tempInventories.push({ isSelected: false, Informations: tmpInformation })

        this.updateInventoriesState(tempInventories)
    }

    removeRow = (index: number) => {
        let tempInventories = this.state.inventories
        tempInventories.splice(index, 1)

        this.updateInventoriesState(tempInventories)
    }

    getTotalDuplicateCount() {
        return (this.state.ignoreDuplication ? 0 : this.state.duplicates.length) + (this.state.ignoreInventoryDuplication ? 0 : this.props.errors.length)
    }

    toggleSelectAll = () => {
        let tempInventories = this.state.inventories
        tempInventories.forEach((inventory) => inventory.isSelected = !this.state.isSelectedAll)

        this.setState({ isSelectedAll: !this.state.isSelectedAll, inventories: tempInventories })
    }

    selectRow = (index: number) => {
        let tempInventories = this.state.inventories
        tempInventories[index].isSelected = !tempInventories[index].isSelected

        this.setState({ inventories: tempInventories, isSelectedAll: tempInventories.filter(item => !item.isSelected).length > 0 ? false : true })
        this.forceUpdate()
    }

    changeInformationValue = (e: any, index: number, key: string) => {
        let tempInventories = this.state.inventories
        tempInventories[index].Informations.filter((information) => information.key === key)[0].value = e.target.value

        this.updateInventoriesState(tempInventories)
    }

    updateInventoriesState = (inventories: InventoryView[]) => {
        this.setState({ inventories: inventories })
        this.forceUpdate()
    }

    copyFromExcel = (text: string) => {
        this.setState({ pasteFromExcel: text })
        if (text == '') return

        let lines = text.split("\n")
        let addedCount = 0
        let tempInventories = this.state.inventories
        if (this.state.inventories.length == 1 && this.state.inventories[0].Informations.filter(info => info.value.length == 0).length == this.state.inventories[0].Informations.length) {
            tempInventories = [];
        }

        lines.map((line) => {

            let createInventoryRow: InventoryView = { Informations: [], isSelected: false }

            if (this.state.importFromExcelModalColumnKey !== undefined) {
                if (line.indexOf("\t") >= 0)
                    return toast.error('Your excel information have problem')

                createInventoryRow.Informations.push({
                    value: line,
                    key: this.state.importFromExcelModalColumnKey as string
                })

                this.state.columns.filter(column => column.value !== this.state.importFromExcelModalColumnKey)
                    .map((column) => {
                        createInventoryRow.Informations.push({
                            value: "",
                            key: column.value
                        });
                    })

            } else {

                var i = 0
                var dataValues = line.split("\t")
                if (dataValues.length === 0 || dataValues.length < this.state.columns.length || dataValues.length > this.state.columns.length)
                    return toast.error('Your excel information have problem')

                dataValues.map((dataValue, index) => {
                    createInventoryRow.Informations.push({
                        value: dataValue,
                        key: this.state.columns[index].value
                    })

                    if (i === this.state.columns.length - 1) return null
                })
            }

            if (createInventoryRow.Informations.length > 0) {
                addedCount = + 1
                tempInventories.push(createInventoryRow)
            }
        })

        this.updateInventoriesState(tempInventories)

        if (addedCount > 0)
            toast.success(`Added ${addedCount} row to product list`);
    }

    render(): React.ReactNode {
        return <>
            {(this.state.duplicates.length > 0 && !this.state.ignoreDuplication) && this.duplicateView()}
            {(this.props.errors.length > 0 && !this.state.ignoreInventoryDuplication) && this.errorsView()}
            <Card className="mb-4 mt-4">
                <Card.Header>
                    <div>
                        <span className="text-muted p-2">Total Row Count: {this.state.inventories.length}</span> |
                        <span className="text-danger p-2">Duplicate Count:{this.getTotalDuplicateCount()}</span> |
                        <span className="text-success p-2">Unique Count: {this.state.inventories.length - this.getTotalDuplicateCount()}</span>
                    </div>
                </Card.Header>
                <Card.Body>
                    {this.gridView()}
                </Card.Body>
                <Card.Footer>
                    <Button variant="primary" size="sm" className="me-2" onClick={() => this.setState({ showImportFromExcelModal: true, importFromExcelModalColumnKey: undefined })}><FileEarmarkExcelFill /> Import From Excel</Button>
                    <Button variant="outline-success" size="sm" onClick={() => this.addRow()}><Plus /> Add Row</Button>
                    {this.state.inventories.filter(inventory => inventory.isSelected).length > 0 &&
                        <Button variant="outline-danger" size="sm" className="m-2" onClick={() => this.updateInventoriesState(this.state.inventories.filter(item => { return !item.isSelected }))}>Delete Selection</Button>
                    }
                </Card.Footer>
            </Card>
            {this.state.showImportFromExcelModal && <ImportFromExcel onPaste={this.copyFromExcel}
                colTitles={this.state.importFromExcelModalColumnKey ? [this.state.importFromExcelModalColumnKey] : this.state.columns.map((column) => column.title)}
                closeModal={() => this.setState({ showImportFromExcelModal: false })} />}
        </>
    }

    gridView() {
        return this.state.loading ? <Spinner animation="grow" /> : <Table hover responsive size="lg">
            <thead>
                <tr>
                    <th><Form.Check type="checkbox" checked={this.state.isSelectedAll} onChange={() => this.toggleSelectAll()} /></th>
                    <th>ID</th>
                    {this.state.columns.map((column) => {
                        return <th key={column.value}>{column.title} <FileEarmarkExcelFill className="pointer" onClick={() => this.setState({ showImportFromExcelModal: true, importFromExcelModalColumnKey: column.value })} /></th>
                    })}
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {this.state.inventories.map((inventory, index) => {
                    return <tr key={index}>
                        <td><Form.Check checked={inventory.isSelected} onChange={() => this.selectRow(index)} type="checkbox" /></td>
                        <td>{index + 1}</td>
                        
                        {/* {inventory.Informations.map((information) => { */}
                        {this.state.columns.map((column) => {
                            return <td key={column.value}>
                                <Form.Control value={inventory.Informations.filter(info=>info.key == column.value)[0].value} onChange={(e) => this.changeInformationValue(e, index, column.value)} />
                            </td>
                        })}
                        <td className="text-center" style={{ verticalAlign: 'middle' }}>
                            <Button size="sm" variant="outline-danger" onClick={() => this.removeRow(index)}>Delete Row</Button>
                        </td>
                    </tr>
                })}
            </tbody>
        </Table>
    }

    duplicateView() {
        return <Alert variant="danger">
            Duplicate Rows:
            <ul> {this.state.duplicates.map((duplicate, index) => {
                return <li key={index}>{duplicate.key}</li>
            })} </ul>
            <Button variant="warning" size="sm" style={{ marginLeft: '6px' }} onClick={() => this.setState({ ignoreDuplication: true })}>No-Problem</Button>
        </Alert>
    }

    errorsView() {
        return <Alert variant="danger">
            Already Exists  Inventories:
            <ul> {this.props.errors.map((error, index) => {
                return <li key={index}>Row {error.index + 1} on {error.conflictFields.join(', ')} keys</li>
            })} </ul>
            <Button variant="warning" size="sm" style={{ marginLeft: '6px' }} onClick={() => this.setState({ ignoreInventoryDuplication: true })}>No-Problem</Button>
        </Alert>
    }
}