import { GridResult } from "../../../components/Grid/Grid.models"
import { mapToDataOnly } from "../../common/HttpUtility"
import { SelectionView } from "../../common/Models"
import { ProductCategoryApiFactory } from "../../../generated-api/admin"

export default class AdminProductCategoriesService {
    getPaged(): Promise<GridResult<any>> {
        return mapToDataOnly<any>(ProductCategoryApiFactory().adminProductCategoryGet())
    }

    update(id: string, dto: ProductCategoryDTO): Promise<any> {
        return mapToDataOnly(ProductCategoryApiFactory().adminProductCategoryIdPut(id, dto));
    }

    delete(id: string): Promise<any> {
        return mapToDataOnly(ProductCategoryApiFactory().adminProductCategoryIdDelete(id));
    }

    getCategory(): Promise<SelectionView[]> {
        return mapToDataOnly<SelectionView[]>(ProductCategoryApiFactory().adminProductCategoryTitleValueGet())
    }

    add(dto: ProductCategoryDTO): Promise<any> {
        return mapToDataOnly(ProductCategoryApiFactory().adminProductCategoryPost(dto));
    }

    getById(id: string): Promise<ProductCategoryDTO> {
        return mapToDataOnly<ProductCategoryDTO>(ProductCategoryApiFactory().adminProductCategoryIdGet(id))
    }
}

export interface ICategory {
    rows: {
        id: string,
        title: string,
        orderId: number,
        isActive: boolean,
        parentId: string | null,
        parentTitle: string | null,
        sellerCount: number,
        productCount: number,
        imageName: null
    },
    pageSize: number
}

export interface ProductCategoryDTO {
    title: string;
    orderId: number;
    isActive: boolean;
    parentId: string | null;
    imageSrc?: string | null;
    imageName: string | null;
}