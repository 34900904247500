import React from "react";
import { Badge, Button, Card, Modal } from "react-bootstrap";
import { X, Pencil, Pen, Filter, FilterSquare, Funnel } from "react-bootstrap-icons";
import './FilterBar.scss'

interface FilterBarProps {
    onApply: () => void,
    onOpened: () => void,
    onRemoveFilter: (key: string) => void
    renderCustomFilterView?: (key: string, value: any) => any
    unDeletableKeys?: string[]
    filters: any,
    large?: boolean
    readonly?: boolean
    hiddenKeys?: string[]
}

export default class FilterBar extends React.Component<FilterBarProps, { showModal: boolean }> {
    /**
     *
     */
    constructor(props: any) {
        super(props);
        this.state = { showModal: false }
    }

    apply = () => {
        this.props.onApply();
        this.setState({ showModal: false });
    }

    openFilters = () => {
        this.props.onOpened();
        this.setState({ showModal: true });
    }

    renderTitle(title: string) {
        let text = ''
        for (const char of title) {
            text += char.charCodeAt(0) <= 90 ? ' ' + char.toLowerCase() : char;
        }
        return text
    }

    render(): React.ReactNode {
        return <div className="FilterBar">
            <Modal
                size={this.props.large ? undefined : 'sm'}
                show={this.state.showModal}
                onHide={() => this.setState({ showModal: false })}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Search
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.props.children}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => this.apply()}>Apply</Button>
                </Modal.Footer>
            </Modal>
            <Card>
                <Card.Header>
                    {
                        !this.props.readonly &&
                        <Button variant="outline-secondary" size="sm"
                            onClick={this.openFilters}><Funnel /> Search</Button>
                    }
                    <span style={{ marginLeft: '5px' }}>
                        {Object.keys(this.props.filters).map((key) => {
                            let filters: any = this.props.filters;
                            return <span key={key}> {filters[key] === undefined || filters[key] === null || filters[key] === '' ? null :
                                (!this.props.hiddenKeys || !this.props.hiddenKeys.includes(key)) && <>
                                    <Badge bg="secondary" className="m-1" onClick={() => {
                                        if (this.props.readonly === true)
                                            return;
                                        this.props.unDeletableKeys?.includes(key) ? this.openFilters() : this.props.onRemoveFilter(key)
                                    }}>

                                        {!this.props.readonly && (this.props.unDeletableKeys?.includes(key) ? <Pencil className="mr-1" size="11px" /> : <X className="mr-1" size="14px" />)}
                                        <span>
                                            {this.renderTitle(key) + ': '}
                                        </span>
                                        {this.props.renderCustomFilterView !== undefined ? this.props.renderCustomFilterView(key, filters[key]) : filters[key]}
                                    </Badge>
                                </>
                            }</span>
                        })}
                    </span>

                </Card.Header>
            </Card>
        </div >;
    }
}