import { Route } from "react-router-dom";
import DefaultLayout from "../../components/layout/DefaultLayout";
import ArticleDetailsRoute from "../helpCenter/ArticleDetailsRoute";
import ArticleRoute from "../helpCenter/ArticleRoute";
import CreateSupportTicketRoute from "../helpCenter/CreateSupportTicketRoute";
import HelpCenterRoute from "../helpCenter/HelpCenterRoute";
import ReportDetailsRoute from "../helpCenter/ReportDetailsRoute";
import SupportTicketDetialsRoute from "../helpCenter/SupportTicketDetialsRoute";

export function HelpCenterRoutes() {
    return (
        <>
            <Route element={<DefaultLayout />}>
                <Route path="/support-ticket" element={<SupportTicketDetialsRoute />} />
                <Route path="/create-support-ticket" element={<CreateSupportTicketRoute />} />
                <Route path="/support-ticket-report-view" element={<ReportDetailsRoute />} />
                <Route path="/helpCenter" element={<HelpCenterRoute />} />
                <Route path="/article" element={<ArticleRoute />} />
                <Route path="/article-details" element={<ArticleDetailsRoute />} />
            </Route>
        </>
    )
}
