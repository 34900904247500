import React from "react"
import { Button } from "react-bootstrap"
import { NavLink } from "react-router-dom"
import './RedirectView.scss'

export default class RedirectView extends React.PureComponent<{ title?: string }, {}> {
    render(): React.ReactNode {
        return (<>
            <div className={`area-action pt-5`}>
                <div className="d-grid action">
                    <div className="fs-7">{this.props.title}</div>
                    <NavLink to="/marketplace" className="w-100">
                        <Button variant="danger" className="btn-brownish-red mt-4 w-100">Go to Marketplace</Button>
                    </NavLink>
                </div>
            </div>
        </>)
    }
}