import FortniteV from '../../../assets/images/giftcarts/FortniteV.webp'
import SteamChileCLP from '../../../assets/images/giftcarts/SteamChileCLP.webp'
import Playstation from '../../../assets/images/giftcarts/Playstation.webp'
import XboxLive from '../../../assets/images/giftcarts/XboxLive.webp'
import BlizzardGame from '../../../assets/images/giftcarts/BlizzardGame.webp'
import PubgMobile from '../../../assets/images/giftcarts/PubgMobile.webp'
import RazerGold from '../../../assets/images/giftcarts/RazerGold.webp'
import Mincraft from '../../../assets/images/giftcarts/Mincraft.webp'
import Nintendo from '../../../assets/images/giftcarts/Nintendo.webp'
import LeagueofLegends from '../../../assets/images/giftcarts/LeagueofLegends.webp'
import FreeFire from '../../../assets/images/giftcarts/FreeFire.webp'
import Roblox from '../../../assets/images/giftcarts/Roblox.webp'

interface ICardView {
    title: string,
    image: any,
    subTitle?: string
}
export const Games: ICardView[] = [
    {
        image: FortniteV,
        title: 'Fortnite',
    },
    {
        image: SteamChileCLP,
        title: 'Steam',
    },
    {
        image: Playstation,
        title: 'Playstation',
    },
    {
        image: XboxLive,
        title: 'Xbox',
    },
    {
        image: BlizzardGame,
        title: 'Blizzard Game',
    },
    {
        image: PubgMobile,
        title: 'PubG Mobile',
    },
    {
        image: RazerGold,
        title: 'Razer Gold',
    },
    {
        image: Mincraft,
        title: 'Minecraft',
    },
    {
        image: Nintendo,
        title: 'Nintendo',
    },
    {
        image: LeagueofLegends,
        title: 'League of Legends',
    },
    {
        image: FreeFire,
        title: 'Free Fire',
    },
    {
        image: Roblox,
        title: 'Roblox',
    },
]
