import React from "react";
import { ReactNode } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import SupportTicketTagService from "../../../../services/helpCenter/SupportTicketTagService";

export default class CreateTagModal extends React.PureComponent<{ showModal: boolean, onClose: () => void, ticketId: string }, { tagName: string, isLoading: boolean }>{
    supportTicketTagService: SupportTicketTagService;
    constructor(props: any) {
        super(props);
        this.supportTicketTagService = new SupportTicketTagService();
        this.state = { isLoading: false, tagName: '' };
    }

    submit = () => {
        this.setState({ isLoading: true });
        this.supportTicketTagService.create({ ticketId: this.props.ticketId, value: this.state.tagName }).then(response => {
            this.setState({ tagName: '', isLoading: false })
            this.props.onClose();
        });
    }

    render(): ReactNode {
        const { showModal, onClose } = this.props;
        const { tagName, isLoading } = this.state;

        return <>
            {showModal && <Modal size="sm"
                show={showModal}
                onHide={onClose}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Create Tag</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md="12" sm="12">
                            <Form.Label>Tag Name</Form.Label>
                            <Form.Control aria-label="Enter Tag Name"
                                disabled={isLoading}
                                value={tagName}
                                onChange={(e) => this.setState({ tagName: e.target.value })} />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    {isLoading ? <Button variant='success' disabled><Spinner animation='border' /></Button> :
                        <Button variant='success' onClick={this.submit}>Submit</Button>}
                </Modal.Footer>
            </Modal>}
        </>
    }
}