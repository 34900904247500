import { PaginationQuery } from "../../../components/Grid/Pagination/Pagination.models";
import { mapToDataOnly } from "../../common/HttpUtility";
import { CreditApiFactory } from "../../../generated-api/financials";

export interface IFilters {
    shopTitle: string;
    isActive: boolean;
    negativeCreditsOnly: boolean;
}

export default class AdminCreditsService {
    getPaged(filters: IFilters, paginationQuery: PaginationQuery): Promise<any> {
        return mapToDataOnly<any>(CreditApiFactory().apiV1AdminCreditGet(filters.shopTitle, filters.isActive, filters.negativeCreditsOnly, paginationQuery.pageskip - 1, paginationQuery.pagesize));
    }

    add(body: {}): Promise<any> {
        return mapToDataOnly<any>(CreditApiFactory().apiV1AdminCreditPost(body));
    }

    archive(id: string): Promise<any> {
        return mapToDataOnly<any>(CreditApiFactory().apiV1AdminCreditArchiveCreditIdPut(id));
    }
}