import { mapToDataOnly } from "../common/HttpUtility";
import { SelectionView } from "../common/Models";
import { SearchApiFactory } from "../../generated-api";

export default class SearchService {
    getSuggestion(searchText?: string): Promise<SearchSuggestionDto[]> {
        return mapToDataOnly<SearchSuggestionDto[]>(SearchApiFactory().marketPlaceSearchSuggestionGet(searchText));
    }
}

export interface SearchSuggestionDto extends SelectionView<string> {
    type: SuggestionType
}

export enum SuggestionType {
    Product,
    Category
}