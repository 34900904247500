import axios from "axios"
import { GridResult } from "../../../components/Grid/Grid.models"
import { PaginationQuery } from "../../../components/Grid/Pagination/Pagination.models"
import { mapToDataOnly } from "../../common/HttpUtility"
import { UserStatusEnum } from "../../../components/identity/Enums/UserStatusEnum"
import { SelectionView } from "../../common/Models"
import { UserApiFactory } from "../../../generated-api/admin"

export default class AdminUserService {
    getPaged(filters: IUserFilters, paginationQuery: PaginationQuery): Promise<GridResult<IUserRowDTO>> {
        return mapToDataOnly<GridResult<IUserRowDTO>>(UserApiFactory().adminUserGet(filters.id, filters.name, filters.email, paginationQuery.pageskip - 1, paginationQuery.pagesize))
    }

    getUserRoles(userId: string): Promise<UserRoleView[]> {
        return mapToDataOnly<UserRoleView[]>(UserApiFactory().adminUserRolesUserIdGet(userId))
    }

    editUserRoles(userId: string, userRoleKeys: string[]): Promise<any> {
        return mapToDataOnly<any>(UserApiFactory().adminUserRolesUserIdPut(userId, userRoleKeys))
    }

    getTitleValues(): Promise<SelectionView[]> {
        return mapToDataOnly<SelectionView[]>(axios.get(`Admin/User/TitleValue`))
    }
}

interface UserRoleView {
    id: string;
    title: string;
    key: string;
    hasRole: boolean;
}

interface IUserFilters {
    id?: string
    name?: string
    email?: string
}

interface IUserRowDTO {
    id: string;
    displayName: string;
    userName: string;
    email: string;
    registerDate: string;
    status: UserStatusEnum;
    hasTwoFactor: boolean;
}