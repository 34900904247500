import React, { FormEvent } from "react"
import { Button, Col, Form, InputGroup, Row, Spinner } from "react-bootstrap"
import IApiState, { IApiProps } from "./WebHooksConfigs.models"
import ConfigurationService from "../../../../services/api/ConfigurationService"
import { toast } from "react-toastify"
import TOTPForm from "../../../identity/Security/TwoFactor/TOTPForm/TOTPForm"
import AuthenticationContext from "../../../../contexts/AuthenticationContext/AuthenticationContext";
import Note from "../../../shared/Note/Note"

export default class ConfigurationApi extends React.PureComponent<IApiProps, IApiState> {
    configurationService: ConfigurationService
    static contextType = AuthenticationContext
    context!: React.ContextType<typeof AuthenticationContext>
    constructor(props: IApiProps) {
        super(props)
        this.configurationService = new ConfigurationService()
        this.state = { activeOTPMode: false, loading: false, apiConfig: { apiKey: '', takeoutInventoryRefundedWebHookPath: '', sellReviewRequestCreatedWebHookPath: '', supportTicketUpdatedWebHookPath: '', orderProductChangeStatusWebHookPath: '', takeoutInventoryWebHookPath: '', productChangeStatusWebHookPath: '', secretKey: '', title: '', webHookSecurityHeaderKey: '', webHookSecurityHeaderValue: '', walletDepositWebHookPath: '' }, pendingRequest: false }
    }

    componentDidMount() {
        this.getPaged()
    }

    getPaged = () => {
        this.setState({ loading: true })
        this.configurationService.get().then((response) => {
            this.setState({ apiConfig: response })
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    submitHandler = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (this.context.twoFactorAvtive)
            this.setState({ activeOTPMode: true })
        else this.submit()
    }

    submit = () => {
        const { apiConfig } = this.state
        this.setState({ pendingRequest: true, activeOTPMode: false })

        this.configurationService.updateWebHooks({
            orderProductChangeStatusWebHookPath: apiConfig.orderProductChangeStatusWebHookPath,
            productChangeStatusWebHookPath: apiConfig.productChangeStatusWebHookPath,
            webHookSecurityHeaderValue: apiConfig.webHookSecurityHeaderValue,
            walletDepositWebHookPath: apiConfig.walletDepositWebHookPath,
            sellReviewRequestCreatedWebHookPath: apiConfig.sellReviewRequestCreatedWebHookPath,
            supportTicketUpdatedWebHookPath: apiConfig.supportTicketUpdatedWebHookPath,
            takeoutInventoryWebHookPath: apiConfig.takeoutInventoryWebHookPath,
            takeoutInventoryRefundedWebHookPath: apiConfig.takeoutInventoryRefundedWebHookPath 
        }).then(() => {
            toast.success('WebHook Configs Updated successfully.')
            this.setState({ pendingRequest: false })
        })
    }

    render(): React.ReactNode {
        const { apiConfig, pendingRequest, loading } = this.state
        return <>
            {loading ? <Spinner animation="grow" /> :
                <Form onSubmit={this.submitHandler}>
                    <Note>The Webhook method is here so that we can notify your system of various events when needed.</Note>
                    <Row className="mb-4">
                        <Col md="6" sm="12">
                            <Form.Group>
                                <Form.Label>Orders Changes</Form.Label>
                                <InputGroup>
                                    <InputGroup.Text className="text-success">
                                        Post
                                    </InputGroup.Text>
                                    <Form.Control value={apiConfig.orderProductChangeStatusWebHookPath} onChange={(e) => this.setState((prevState) => { return { apiConfig: { ...prevState.apiConfig, orderProductChangeStatusWebHookPath: e.target.value } } })} />
                                </InputGroup>
                                <Form.Text>Any changes in orders and order items are sent to this endpoint.</Form.Text>
                            </Form.Group>
                        </Col>
                        <Col md="6" sm="12">
                            <Form.Group>
                                <Form.Label>Product Status Changes</Form.Label>
                                <InputGroup>
                                    <InputGroup.Text className="text-success">
                                        Post
                                    </InputGroup.Text>
                                    <Form.Control value={apiConfig.productChangeStatusWebHookPath} onChange={(e) => this.setState((prevState) => { return { apiConfig: { ...prevState.apiConfig, productChangeStatusWebHookPath: e.target.value } } })} />
                                </InputGroup>
                                <Form.Text>Any changes in product status (available, delay sell and other) are sent to this endpoint.</Form.Text>
                            </Form.Group>
                        </Col>
                        <Col md="6" sm="12" className="mt-4">
                            <Form.Group>
                                <Form.Label> Takeout inventory </Form.Label>
                                <InputGroup>
                                    <InputGroup.Text className="text-success">
                                        Post
                                    </InputGroup.Text>
                                    <Form.Control value={apiConfig.takeoutInventoryWebHookPath} onChange={(e) => this.setState((prevState) => { return { apiConfig: { ...prevState.apiConfig, takeoutInventoryWebHookPath: e.target.value } } })} />
                                </InputGroup>
                                <Form.Text>Any takeout inventory is sent to this endpoint.</Form.Text>
                            </Form.Group>
                        </Col>
                        <Col md="6" sm="12" className="mt-4">
                            <Form.Group>
                                <Form.Label> Takeout inventory Refunded</Form.Label>
                                <InputGroup>
                                    <InputGroup.Text className="text-success">
                                        Post
                                    </InputGroup.Text>
                                    <Form.Control value={apiConfig.takeoutInventoryRefundedWebHookPath} onChange={(e) => this.setState((prevState) => { return { apiConfig: { ...prevState.apiConfig, takeoutInventoryRefundedWebHookPath: e.target.value } } })} />
                                </InputGroup>
                                <Form.Text>Any takeout inventory refunded is sent to this endpoint.</Form.Text>
                            </Form.Group>
                        </Col>
                        <Col md="6" sm="12" className="mt-4">
                            <Form.Group>
                                <Form.Label>Support Ticket Changes</Form.Label>
                                <InputGroup>
                                    <InputGroup.Text className="text-success">
                                        Post
                                    </InputGroup.Text>
                                    <Form.Control value={apiConfig.supportTicketUpdatedWebHookPath} onChange={(e) => this.setState((prevState) => { return { apiConfig: { ...prevState.apiConfig, supportTicketUpdatedWebHookPath: e.target.value } } })} />
                                </InputGroup>
                                <Form.Text>Any changes in ticket status, new reply or request medation are sent to this endpoint.</Form.Text>
                            </Form.Group>
                        </Col>
                        <Col md="6" sm="12" className="mt-4">
                            <Form.Group>
                                <Form.Label>Sell Review Request</Form.Label>
                                <InputGroup>
                                    <InputGroup.Text className="text-success">
                                        Post
                                    </InputGroup.Text>
                                    <Form.Control value={apiConfig.sellReviewRequestCreatedWebHookPath} onChange={(e) => this.setState((prevState) => { return { apiConfig: { ...prevState.apiConfig, sellReviewRequestCreatedWebHookPath: e.target.value } } })} />
                                </InputGroup>
                                <Form.Text>If sell review request is created, are sent to this endpoint.</Form.Text>
                            </Form.Group>
                        </Col>
                        <Col md="6" sm="12" className="mt-4">
                            <Form.Group>
                                <Form.Label>Wallet Transactions</Form.Label>
                                <InputGroup>
                                    <InputGroup.Text className="text-success">
                                        Post
                                    </InputGroup.Text>
                                    <Form.Control value={apiConfig.walletDepositWebHookPath} onChange={(e) => this.setState((prevState) => { return { apiConfig: { ...prevState.apiConfig, walletDepositWebHookPath: e.target.value } } })} />
                                </InputGroup>
                                <Form.Text>Wallet deposit is sent to this endpoint.</Form.Text>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6" sm="12">
                            <Form.Group>
                                <Form.Label>Security Key</Form.Label>
                                <Form.Control value={apiConfig.webHookSecurityHeaderValue} onChange={(e) => this.setState((prevState) => { return { apiConfig: { ...prevState.apiConfig, webHookSecurityHeaderValue: e.target.value } } })} />
                                <Form.Text>You can set security key and this key will be sent in all web hooks on request headers.</Form.Text>
                            </Form.Group>
                            {
                                apiConfig.webHookSecurityHeaderValue && (
                                    <div className="mt-2">
                                        <span>Request Header</span><br />
                                        <span className="text-secondary">key:</span> <span className="text-success">{apiConfig.webHookSecurityHeaderKey}</span><br />
                                        <span className="text-secondary">value:</span> <span className="text-success">{apiConfig.webHookSecurityHeaderValue}</span>
                                    </div>)
                            }
                        </Col>
                    </Row>
                    <div className="mt-4">
                        {
                            pendingRequest ? <Button size="sm" variant="success" disabled><Spinner animation="border" size="sm"></Spinner></Button> : <Button variant="success" type="submit">Save Changes</Button>
                        }
                    </div>
                </Form>
            }
            {
                this.state.activeOTPMode &&
                <TOTPForm showModal={this.state.activeOTPMode} onSubmit={() => this.submit()} onHide={() => this.setState({ activeOTPMode: false })} />
            }
        </>
    }
}