import React, { RefObject } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { LocalCurrencyService } from "../../../../services/financials/LocalCurrencyService";
import InventorySourceService from "../../../../services/inventory/InventorySourceService";
import InventorySourceTransactionService from "../../../../services/inventory/InventorySourceTransactionService";
import Grid from "../../../Grid/Grid";
import { GridAction, GridColumn, GridColumnTypes } from "../../../Grid/Grid.models";
import { PaginationQuery } from "../../../Grid/Pagination/Pagination.models";
import InventorySourceTransactionBalances from "../InventorySourceTransactionBalances/InventorySourceTransactionBalances";
import ModifyInventorySourceTransaction from "../modify-inventory-source-transaction/ModifyInventorySourceTransaction";
import IState, { IProps } from "./InventorySourceTransactionList.models";
import './InventorySourceTransactionList.scss'

export default class InventorySourceTransactionList extends React.PureComponent<IProps, IState> {
    grid: RefObject<Grid>
    modifyInventorySourceTransaction: RefObject<ModifyInventorySourceTransaction>
    inventorySourceTransactionService: InventorySourceTransactionService
    inventorySourceService: InventorySourceService
    currencyService: LocalCurrencyService
    constructor(props: IProps) {
        super(props)
        this.grid = React.createRef();
        this.modifyInventorySourceTransaction = React.createRef();
        this.state = { selectedTransactionId: '', paginationQuery: { pageskip: 1, pagesize: 10 }, currencies: [], filters: { currencyId: '', inventorySourceId: this.props.id }, title: '', showModal: false };
        this.inventorySourceTransactionService = new InventorySourceTransactionService();
        this.inventorySourceService = new InventorySourceService();
        this.currencyService = new LocalCurrencyService();
    }

    loadData = () => {
        this.currencyService.getTitleValues().then((titleValues) => {
            this.inventorySourceService.getDetails(this.props.id).then((details) => {
                this.setState((prevState) => { return { title: details.title, currencies: titleValues, filters: { ...prevState.filters, currencyId: titleValues.filter(currency => currency.title === "USD")[0].value } } })
                this.reloadGrid()
            })
        })
    }

    reloadGrid = () => {
        this.inventorySourceTransactionService.getPaged({ ...this.state.paginationQuery, ...this.state.filters }).then((gridResult) => {
            this.grid.current?.load(gridResult.rows, gridResult.totalRows);
        })
    }

    changePage = (query: PaginationQuery) => {
        this.setState({ paginationQuery: query }, () => this.loadData());

    }

    openModal = (row: any) => {
        this.setState({ selectedTransactionId: row.id, showModal: true });
        this.grid.current?.resetSpinner()
    }

    deleteRow = (row: any) => {
        this.inventorySourceTransactionService.delete(row.id).then(response => {
            this.reloadGrid()
            this.grid.current?.resetSpinner()
        })
    }

    render(): React.ReactNode {
        let gridColumns: GridColumn[] = [
            { title: 'Source', key: 'isToSource' },
            { title: 'Amount', key: 'amount', type: GridColumnTypes.Amount },
            { title: 'Transaction At', key: 'transactionAt', type: GridColumnTypes.Date },
            { title: 'Description', key: 'description' },
        ];

        let gridActions: GridAction[] = [
            {
                caption: 'Edit', variant: 'warning', onClick: (row) => this.openModal(row),
            },
            {
                caption: 'Delete', variant: 'danger', onClick: (row) => this.deleteRow(row),
            },
        ];

        return (
            <>
                <div>
                    <h5> {this.state.title}
                        <NavLink to="/inventory?defaultActiveKey=source"><Button variant="outline-info" size="sm" className="m-2">Source List</Button></NavLink>
                        <NavLink to={`/create-inventory-source-transaction/?sourceId=${this.props.id}`}><Button variant="success" size="sm">Add Transaction</Button></NavLink>
                    </h5> <hr />
                    <InventorySourceTransactionBalances id={this.props.id} />
                    {this.filterView()}
                    <Grid ref={this.grid} columns={gridColumns} actions={gridActions} onChangePage={this.changePage}></Grid>
                </div>

                {this.state.showModal && <ModifyInventorySourceTransaction ref={this.modifyInventorySourceTransaction} onSubmit={this.reloadGrid} onClose={() => this.setState({ showModal: false })} sourceId={this.state.selectedTransactionId} />}
            </>
        )
    }

    filterView() {
        return (
            <Row className="mt-2 mb-2">
                <Col md="6">
                    <Form.Select className="catalog-search" value={this.state.filters?.currencyId}
                        onChange={(e) => this.setState((prevState) => { return { filters: { ...prevState.filters, currencyId: e.target.value } } })}>
                        {
                            this.state.currencies.map((item) => <option value={item.value} key={item.value}>{item.title}</option>)
                        }
                    </Form.Select>
                </Col>
                <Col>
                    {<Button variant="outline-secondary" size="sm" style={{ padding: "6px" }} onClick={this.reloadGrid}>Change Currency</Button>}
                </Col>
            </Row>
        )
    }
}