import { Route, Routes } from "react-router-dom";
import BaseRoutes from "./routing/BaseRoutes";
import FinancialRoutes from "./routing/FinancialRoutes";
import { HelpCenterRoutes } from "./routing/HelpCenterRoutes";
import { IdentityRoutes } from "./routing/IdentityRoutes";
import { InventoryRoutes } from "./routing/InventoryRoutes";
import { ShopsRoutes } from "./routing/ShopsRoutes";
import { StoreRoutes } from "./routing/StoreRoutes";
import { OrdersRoutes } from "./routing/OrdersRoutes";
import ApiRoutes from "./routing/ApiRoutes";
import CategoriesRoutes from "./routing/CategoriesRoutes";
import AdminRoutes from "./admin/base/AdminRoutes";
import { NotificationsRoutes } from "./routing/NotificationsRoutes";

export default function Routing() {
    return (<Routes>
        {BaseRoutes()}
        {FinancialRoutes()}
        {HelpCenterRoutes()}
        {InventoryRoutes()}
        {StoreRoutes()}
        {OrdersRoutes()}
        {ShopsRoutes()}
        {IdentityRoutes()}
        {ApiRoutes()}
        {CategoriesRoutes()}
        {NotificationsRoutes()}
        {AdminRoutes()}
    </Routes>)
}