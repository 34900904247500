import React, { RefObject } from "react";
import WalletService from "../../../../services/financials/WalletService";
import Grid from "../../../Grid/Grid";
import { GridColumn, GridColumnTypes } from "../../../Grid/Grid.models";
import { PaginationQuery } from "../../../Grid/Pagination/Pagination.models";
import walletTransactionStatusDisplayBadge from "../../Enums/WalletTransactionStatusEnum";
import WalletTransactionReport from "../WalletTransactionReport/WalletTransactionReport";

export default class WalletTransactionList extends React.Component<{ walletId: string }, {}> {
    grid: RefObject<Grid>;
    walletService: WalletService;
    constructor(props: any) {
        super(props);
        this.grid = React.createRef();
        this.walletService = new WalletService();
    }

    componentDidMount = () => {
        // this.goPage({ pageskip: 1, pagesize: 10 });
    }

    goPage = (paginationQuery: PaginationQuery) => {
        let query = { pageskip: paginationQuery.pageskip, pagesize: paginationQuery.pagesize, walletId: this.props.walletId };
        this.walletService.getWalletTransactionList(query).then((table) => {
            let rows = table.rows.map((row) => {
                return { ...row, status: walletTransactionStatusDisplayBadge(row.status), amount: row.isOutTransaction ? row.amount * -1 : row.amount }
            });

            this.grid.current?.load(rows, table.totalRows);
        });
    }

    render(): React.ReactNode {
        return <WalletTransactionReport />;
    }

    defaultView() {
        let gridColumns: GridColumn[] = [
            { title: 'Amount', key: 'amount', type: GridColumnTypes.Amount },
            { title: 'Status', key: 'status' },
            { title: 'Description', key: 'description' },
            { title: 'Member', key: 'memberName' },
            { title: 'CreateAt', key: 'createAt', type: GridColumnTypes.Date },
        ];


        return (<div>
            <h3>Transactions</h3>
            <hr />
            <Grid ref={this.grid} columns={gridColumns} onChangePage={this.goPage} rowClassName={(row) => { return row['isOutTransaction'] ? "text-danger" : "text-success" }}></Grid>
        </div>)
    }
}