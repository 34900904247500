import { GridResult } from "../../../components/Grid/Grid.models"
import { PaginationQuery } from "../../../components/Grid/Pagination/Pagination.models"
import { mapToDataOnly } from "../../common/HttpUtility"
import { ShopStatusEnum } from "../../../components/shops/ShopStatusEnum"
import { ShopApiFactory } from "../../../generated-api/admin"


export default class AdminShopService {
    get(query: IShopFilters, paginationQuery: PaginationQuery): Promise<GridResult<any>> {
        return mapToDataOnly<any>(ShopApiFactory().adminShopGet(query.status, paginationQuery.pageskip - 1, paginationQuery.pagesize - 1))
    }

    accept(shopId: string): Promise<void> {
        return mapToDataOnly<any>(ShopApiFactory().adminShopPut({ shopId, shopStatus: 1 }))
    }
}

interface IShopFilters {
    status?: ShopStatusEnum
    type?: ShopTypeEnum
}

export enum ShopTypeEnum {
    Seller,
    Buyer
} 