import React, { RefObject } from "react"
import { Navigate } from "react-router-dom"
import InventoryShopCollectionService from "../../../services/inventory/InventoryShopCollectionService"
import { GridAction, GridColumn, GridColumnTypes } from "../../Grid/Grid.models"
import { PaginationQuery } from "../../Grid/Pagination/Pagination.models"
import { EditDeficitModal } from "./Deficit/EditDeficitModal"
import DynamicGrid from "../../shared/DynamicGrid/DynamicGrid"
import { Form } from "react-bootstrap"
import InventoryService from "../../../services/inventory/InventoryService"
import { toast } from "react-toastify"

export interface InventoryShopCollectionFilters {
    orderNumber?: string
}
export default class InventoryShopCollectionList extends React.PureComponent<{ changeTab?: (key: string) => void },
    { showDeficitModal: boolean, deficitCount: number, collectionId: string, rowId: string | null, paginationQuery: PaginationQuery }> {
    inventoryShopCollectionService: InventoryShopCollectionService
    inventoryService: InventoryService
    grid: RefObject<DynamicGrid<InventoryShopCollectionFilters>>

    constructor(props: any) {
        super(props);
        this.grid = React.createRef()
        this.inventoryShopCollectionService = new InventoryShopCollectionService()
        this.inventoryService = new InventoryService()
        this.state = { collectionId: '', showDeficitModal: false, rowId: null, deficitCount: 0, paginationQuery: { pageskip: 1, pagesize: 10 } }
    }

    openDeficitModal = (id: string, count: number) => {
        this.setState({ showDeficitModal: true, collectionId: id, deficitCount: count })
        this.grid.current?.resetSpinner()
    }

    goToInventory = (id: string) => {
        this.setState({ rowId: id }, () => {
            this.props.changeTab && this.props.changeTab(`inventory`)
        })
    }

    holdInventoryShopCollection = (collectionId?: string) => {
        if (window.confirm(`Are you sure about hold this collection?`)) {
            this.inventoryService.holdInventoryProductByCollectionId(collectionId).then(() =>
                toast.success("The collection held successfuly.")
            ).finally(() => { this.grid.current?.resetSpinner()})
        }
    }

    filtersView = (filters: InventoryShopCollectionFilters, onChange: (newFilters: InventoryShopCollectionFilters) => void) => {
        return <>
            <Form.Group>
                <Form.Label>Order Number</Form.Label>
                <Form.Control value={filters.orderNumber} onChange={(e) => {
                    onChange({ ...filters, orderNumber: e.target.value })
                }} type="text" name="OrderNumber" placeholder="Enter Order Number" />
            </Form.Group>
        </>
    }

    render(): React.ReactNode {
        let gridActions: GridAction[] = [
            { caption: 'Hold', variant: 'danger', onClick: (row) => { this.holdInventoryShopCollection(row.id) } },
            { caption: 'Edit Deficit', variant: 'warning', onClick: (row) => { this.openDeficitModal(row.id, row.count) } },
            { caption: 'Inventory', variant: 'info', onClick: (row) => { this.goToInventory(row.id) } },
        ]

        let gridColumns: GridColumn[] = [
            { title: 'Product', key: 'productTitle' },
            { title: 'Source', key: 'sourceTitle' },
            { title: 'Price', key: 'price', type: GridColumnTypes.Amount },
            { title: 'Count', key: 'count' },
            { title: 'Deficits', key: 'deficitCount' },
            { title: 'Purchase Date', key: 'buyAt', type: GridColumnTypes.Date },
            { title: 'Invoice Number', key: 'buyOrderNumber' },
        ]
        return (<>
            {
                this.state.rowId && <Navigate to={`/inventory/?defaultActiveKey=inventory&shopcollectionid=${this.state.rowId}`} />
            }

            <DynamicGrid<InventoryShopCollectionFilters> ref={this.grid}
                columns={gridColumns}
                actions={gridActions}
                onFetch={this.inventoryShopCollectionService.getPaged}
                filtersForm={this.filtersView}
                hiddenFilterkeys={["status"]}
            />
            {this.state.showDeficitModal && <EditDeficitModal collectionId={this.state.collectionId} count={this.state.deficitCount}
                reload={() => this.grid.current?.fetch()} closeModal={() => this.setState({ showDeficitModal: false })} />}
        </>)
    }
}