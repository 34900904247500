import React from "react"
import { Button, Col, Row, Spinner } from "react-bootstrap"
import ConfigurationService from "../../../../services/api/ConfigurationService"
import TOTPForm from "../../../identity/Security/TwoFactor/TOTPForm/TOTPForm"
import AuthenticationContext from "../../../../contexts/AuthenticationContext/AuthenticationContext";

export default class ActivateShopConfig extends React.PureComponent<{ reload: any }, { isLoading: boolean, activeOTPMode: boolean }> {
    configurationService: ConfigurationService
    static contextType = AuthenticationContext
    context!: React.ContextType<typeof AuthenticationContext>
    constructor(props: any) {
        super(props)
        this.configurationService = new ConfigurationService()
        this.state = { isLoading: false, activeOTPMode: false }
    }

    activateShopConfigHandler = () => {
        if (this.context.twoFactorAvtive)
            this.setState({ activeOTPMode: true })
        else this.requestActivateShopConfig()
    }

    requestActivateShopConfig = () => {
        this.setState({ isLoading: true })
        this.configurationService.activeShop().then(() => {
            this.props.reload()
            this.setState({activeOTPMode: false, isLoading: false})
        })
    }

    render(): React.ReactNode {
        const { isLoading } = this.state
        return <>
            <Row>
                <Col md="12" sm="12" className="mt-3">
                    {
                        isLoading ? <Button variant="success" disabled><Spinner animation="border" size="sm"></Spinner></Button> : <Button variant="success" onClick={this.activateShopConfigHandler}>Active API</Button>
                    }
                </Col>
            </Row>
            {this.state.activeOTPMode &&
                <TOTPForm showModal={this.state.activeOTPMode} onSubmit={() => { this.requestActivateShopConfig() }} onHide={() => this.setState({ activeOTPMode: false })} />
            }
        </>
    }
}