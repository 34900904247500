import React from "react";
import { Accordion } from "react-bootstrap";
import FAQService from "../../../services/helpCenter/FAQService";
import './FAQ.scss'
export default class FAQ extends React.Component<{}, State> {
    faqService: FAQService;
    constructor(props: any) {
        super(props);
        this.faqService = new FAQService();
        this.state = { sections: [] };
    }

    componentDidMount() {
        this.faqService.get().then((sections) => {
            console.log(sections);
            this.setState({ sections });
        });
    }
    render(): React.ReactNode {
        return <div className="FAQ">
            {
                this.state.sections.sort(section => section.rank).map((section, sectionIndex) =>
                    <div className="section" key={sectionIndex}>
                        <h3>{section.title}</h3>
                        <Accordion>
                            {section.questions.sort(question => question.rank).map((question, questionIndex) =>
                                <Accordion.Item eventKey={questionIndex.toString()} key={questionIndex}>
                                    <Accordion.Header> {question.text}</Accordion.Header>
                                    <Accordion.Body>
                                        {
                                            question.answer
                                        }
                                    </Accordion.Body>
                                </Accordion.Item>
                            )
                            }
                        </Accordion>
                    </div>)
            }
        </div>
    }
}

interface State {
    sections: QuestionSection[]
}

interface QuestionSection {
    title: string,
    rank: number,
    questions: {
        text: string,
        rank: number,
        answer: string
    }[]
}