import React from "react";
import { Button, Table } from "react-bootstrap";
import ProductCategoryService from "../../../../services/category/ProductCategoryService";
import InventoryCategoryService from "../../../../services/inventory/InventoryCategoryService";
import { IProps } from './CategoriesListView.models'

export default class CategoriesListView extends React.PureComponent<IProps, {}> {
    inventoryCategoryService: InventoryCategoryService
    productCategoryService: ProductCategoryService
    constructor(props: any) {
        super(props)
        this.inventoryCategoryService = new InventoryCategoryService()
        this.productCategoryService = new ProductCategoryService()
        this.state = { showModal: false, categories: [], isEditMode: false, isLoading: false, pendingRequest: false }
    }

    render(): React.ReactNode {
        const { action } = this.props
        return <>
            <Table striped hover responsive size="sm">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Title</th>
                        {this.props.isEditMode && <th></th>}
                    </tr>
                </thead>
                <tbody>
                    {this.props.categories.map(((category, index) => {
                        return <tr key={category.id}>
                            <td>{index + 1}</td>
                            <td>{category.title}</td>
                            {action && this.props.isEditMode &&
                                <td className="text-end">
                                    <Button variant={action.variant} size="sm" onClick={() => { action.onClick !== undefined && action.onClick(category) }}>{action.caption}</Button>
                                </td>
                            }
                        </tr>
                    }))}
                </tbody>
            </Table>
        </>
    }
}