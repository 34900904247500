import { GridResult } from "../../../components/Grid/Grid.models";
import { PaginationQuery } from "../../../components/Grid/Pagination/Pagination.models";
import { mapToDataOnly } from "../../common/HttpUtility";
import { SupportTicketApiFactory, SupportTicketDepartmentApiFactory } from "../../../generated-api/admin";

interface ISupportTicketDepartmentRowDTO {
    id: string;
    title: string;
    isActive: boolean;
    orderId: number;
}

interface ICreateSupportTicketDepartmentDTO {
    title: string;
    isActive: boolean;
    orderID: number;
}
interface IChangeSupportTicketDepartmentDTO {
    departmentId: string;
    SupportTicketId: string;
}

export default class AdminSupportTicketDepartementService {
    getPaged(filters: {}, paginationQuery: PaginationQuery): Promise<GridResult<ISupportTicketDepartmentRowDTO>> {
        return mapToDataOnly<GridResult<ISupportTicketDepartmentRowDTO>>(SupportTicketDepartmentApiFactory().adminSupportTicketDepartmentGet(paginationQuery.pageskip - 1, paginationQuery.pagesize));
    }

    getById(departementId: string): Promise<{ orderID: number, title: string, isActive: boolean }> {
        return mapToDataOnly<any>(SupportTicketDepartmentApiFactory().adminSupportTicketDepartmentUpdateIdGet(departementId));
    }

    create(body: ICreateSupportTicketDepartmentDTO): Promise<any> {
        return mapToDataOnly<any>(SupportTicketDepartmentApiFactory().adminSupportTicketDepartmentPost(body));
    }

    update(id: string, body: ICreateSupportTicketDepartmentDTO): Promise<any> {
        return mapToDataOnly<any>(SupportTicketDepartmentApiFactory().adminSupportTicketDepartmentPut({ id, ...body }));
    }

    remove(id: string): Promise<any> {
        return mapToDataOnly<any>(SupportTicketDepartmentApiFactory().adminSupportTicketDepartmentIdDelete(id));
    }

    changeDepartment(body: IChangeSupportTicketDepartmentDTO): Promise<any> {
        return mapToDataOnly<any>(SupportTicketApiFactory().adminSupportTicketChangeDepartmentPut(body));
    }
}