import React, { Component } from "react";
import MenuItem from "./MenuItem/MenuItem";
import './CategorySidebar.scss'
import { Placeholder } from "react-bootstrap";
import ProductCatalogService, { ProductCategoryTitleView } from "../../../../services/catalog/ProductCatalogService";
import { Link } from "react-router-dom";
import LayoutContext from "../../../../contexts/LayoutContext/LayoutContext";
import ShopPolicies from "../../../shared/policy/ShopPolicies/ShopPolicies";
import { SELLER } from "../../../../constants/ConstantsPolicies";
import { Cart4, ChatDots, Speedometer } from "react-bootstrap-icons";
import RequestNewProductModal from "../../../catalog/RequestNewProduct/RequestNewProduct";

export interface SidebarItemView {
    title: string;
    parentId?: string;
    id: string;
    isActive: boolean;
    isFavorite: boolean;
    subMenu: SidebarItemView[];

}

export interface IState {
    menuItems: SidebarItemView[],
    newRequestProductModal: boolean,
}

export default class CategorySidebar extends Component<{
    categories: ProductCategoryTitleView[], onNavigate?: () => void,
    fixMargin?: boolean, catalogMode: 'express' | 'trade' | 'api'
}, IState> {
    productCatalogService: ProductCatalogService
    static contextType = LayoutContext;
    context!: React.ContextType<typeof LayoutContext>;
    constructor(props: any) {
        super(props);
        this.state = { menuItems: [], newRequestProductModal: false };
        this.productCatalogService = new ProductCatalogService()
    }

    componentDidUpdate(prevProps: Readonly<{ categories: ProductCategoryTitleView[]; onNavigate?: () => void; fixMargin?: boolean; }>, prevState: Readonly<IState>, snapshot?: any): void {
        if (prevProps.categories != this.props.categories)
            this.menuInitial(this.props.categories)

    }

    componentDidMount() {
        this.menuInitial(this.props.categories)
    }

    menuInitial = (categories: any[]) => {
        let context = this;
        let menuItems = categories.map((category) => {
            return {
                id: category.id,
                title: category.title,
                parentId: category.parentId,
                isActive: false,
                subMenu: [],
                isFavorite: false,
            } as SidebarItemView;
        });

        let masterMenus = menuItems.filter(item => item.parentId === null)
        masterMenus.map(masterMenu => context.setSubMenu(masterMenu, menuItems));
        masterMenus.map(masterMenu => masterMenu.subMenu.map(subMenu => context.setSubMenu(subMenu, menuItems)));

        context.setState({ menuItems: masterMenus })
    }

    setSubMenu(master: SidebarItemView, allMenuItems: SidebarItemView[]) {
        master.subMenu = allMenuItems.filter(menuItem => menuItem.parentId === master.id);
    }

    handleClickMenuItem = (id: string) => {
        this.closeAll(id);
        this.setState(prevState => {
            return {
                menuItems: prevState.menuItems
                    .map(item => { return item.id === id ? { ...item, isActive: !item.isActive } : item })
            }
        });
    }

    closeAll = (exceptedId: string) => {
        this.setState(prevState => {
            return {
                menuItems: prevState.menuItems
                    .map(item => { return item.id === exceptedId ? item : { ...item, isActive: false } })
            }
        });
    }

    render(): React.ReactNode {
        let menuItems = this.state.menuItems.sort(function (x, y) { return Number(x.isFavorite) - Number(y.isFavorite) })
        return <div className={"CategorySidebar " + (this.props.fixMargin === true && "fix-margin")}>

            {this.props.categories.length > 0 &&
                <ShopPolicies policy={SELLER} >
                    <Link onClick={this.props.onNavigate} to={`/marketplace?mode=${this.props.catalogMode}`} className="fixed-button">
                        <Speedometer />
                        Dashboard
                    </Link>
                </ShopPolicies>

            }
            {
                this.props.categories.length === 0 ? ([...Array(11)].map((e, i) => <Placeholder animation="glow" key={i}>
                    <Placeholder style={{ height: '5vh', borderRadius: '8px', width: '100%', marginTop: '10px' }} />
                </Placeholder>)) :
                    menuItems.map((category) => {
                        return (<MenuItem
                            key={category.id} title={category.title} id={category.id} onNavigate={this.props.onNavigate} catalogMode={this.props.catalogMode}
                            subItems={category.subMenu} isOpened={category.isActive} onClick={() => this.handleClickMenuItem(category.id)} />);
                    })
            }
            {this.state.newRequestProductModal && <RequestNewProductModal onClose={() => this.setState({ newRequestProductModal: false })} />}
            <div className="MenuItem button" onClick={() => this.setState({ newRequestProductModal: true })}><ChatDots className="me-2" />Request a new product</div>
        </div >;
    }
}