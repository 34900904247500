import { mapToDataOnly } from "../../common/HttpUtility";
import { NotificationPermissionApiFactory } from "../../../generated-api/admin";

export default class AdminNotificationPermissionService {
    get(): Promise<NotificationPermissionRowDto[]> {
        return mapToDataOnly<NotificationPermissionRowDto[]>(NotificationPermissionApiFactory().adminNotificationPermissionGet());
    }

    update(body: UpdateNotificationPermission[]): Promise<any> {
        return mapToDataOnly<any>(NotificationPermissionApiFactory().adminNotificationPermissionPut(body));
    }
}

export class NotificationPermissionRowDto {
    id: string
    key: string
    title: string
    isActive: boolean
}

export class UpdateNotificationPermission {
    isActive: boolean
    permissionId: string
}