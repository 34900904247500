import React, { RefObject } from "react";
import { Badge, Button, Spinner, Table } from "react-bootstrap";
import Amount from "../utilities/Amount/Amount";
import GridProps, { GridAction, GridColumnTypes, GridState } from "./Grid.models";
import './Grid.scss'
import Pagination from "./Pagination/Pagination";
import { PaginationQuery } from "./Pagination/Pagination.models";
import Moment from 'moment';
import { NavLink } from "react-router-dom";
import ShopAuthenticated from "../shared/ShopAuthenticated/ShopAuthenticated";

export default class Grid extends React.PureComponent<GridProps, GridState> {
    pagination: RefObject<Pagination>
    /**
     *
     */
    constructor(props: any) {
        super(props);
        this.pagination = React.createRef();
        this.state = { columns: [], rows: [], actions: [], startIndex: 1, totalRows: 0, spinnerPoint: [], loading: true, pageNumber: 1 };
    }

    componentDidMount = () => {
        this.setState({ columns: this.props.columns, actions: this.props.actions === undefined ? [] : this.props.actions });
    }

    load = (rows: any[], totalRows: number) => {
        let records: Record<string, any>[] = rows.map((row) => {
            let columns: Record<string, any> = [];
            let index = 0;
            for (let key of Object.keys(row)) {
                columns[key] = Object.values(row)[index++];
            }
            return columns;
        });
        this.setState({ rows: records, totalRows: totalRows, loading: false, spinnerPoint: [] });
    }
    render(): React.ReactNode {
        return (<div className={"Grid" + (this.state.loading ? ' loading ' : '')}>
            <Table striped={!this.state.loading} hover responsive size="lg">
                <thead>
                    <tr>
                        <th>#</th>
                        {this.state.columns.map((column) => (<th key={column.key}>{column.title}</th>))}
                        {this.state.actions.length == 0 ? null : (<th>Actions</th>)}
                    </tr>
                </thead>
                {
                    this.state.loading ? <tbody>
                        <tr className="empty-row"><td colSpan={this.state.columns.length + 2}><Spinner animation="border" /></td></tr>
                    </tbody> :
                        (this.state.rows.length > 0 ? this.bodyView() : this.emptyBodyView())
                }
            </Table>
            {this.props.onChangePage !== undefined &&
                <Pagination totalRows={this.state.totalRows} pageNumber={this.state.pageNumber} onChangePageNumber={this.changePageNumber}
                    onChangePageSize={this.fetch}></Pagination>}
        </div>);
    }
    resetSpinner = () => {
        this.setState({ spinnerPoint: [] });
    }

    changePageNumber = (number: number, size: number) => {
        this.setState({ pageNumber: number }, () => this.fetch(size))

    }
    fetch = (size: number) => {
        if (this.props.onChangePage === undefined)
            return;

        var pageQuery: PaginationQuery = { pageskip: this.state.pageNumber, pagesize: size }

        this.setState({ startIndex: ((pageQuery.pageskip - 1) * pageQuery.pagesize) + 1, loading: true })
        this.props.onChangePage(pageQuery);
    }
    bodyView() {
        const badges = ['warning', 'info', 'success', 'primary', 'dark']
        let i = 0;
        return (<tbody>
            {this.state.rows.map((row, index) => (
                <tr key={index} className={this.props.rowClassName && this.props.rowClassName(row)}>
                    <td>{this.state.startIndex + index}</td>
                    {this.state.columns.map((column) => {
                        switch (column.type) {
                            case GridColumnTypes.Date:
                                return <td title={(Moment(row[column.key]).utc().format('YYYY/MM/DD HH:mm'))} key={column.key}>{row[column.key] != null ? Moment(row[column.key]).local().format('YYYY/MM/DD HH:mm') : ''}</td>;
                            case GridColumnTypes.DateOnly:
                                return <td title={(Moment(row[column.key]).utc().format('YYYY/MM/DD'))} key={column.key}>{row[column.key] != null ? Moment(row[column.key]).local().format('YYYY/MM/DD') : ''}</td>;
                            case GridColumnTypes.TimeOnly:
                                return <td title={(Moment(row[column.key]).utc().format('HH:mm'))} key={column.key}>{row[column.key] != null ? Moment(row[column.key]).local().format('HH:mm') : ''}</td>;
                            case GridColumnTypes.Amount:
                                return <td key={column.key}><Amount formatMoney={row[column.key] > 0} value={row[column.key]} /></td>;
                            case GridColumnTypes.BadgeList:
                                return row[column.key] ?
                                    <td key={column.key + index}>
                                        {
                                            row[column.key].map((item, index) => {
                                                i = i > 6 ? 0 : i;
                                                i++;
                                                return <Badge bg={badges[i - 1]} className="mr-1" key={index}>{item}</Badge>;
                                            })
                                        }
                                    </td> :
                                    <td></td>;
                            default:
                                return <td key={column.key}>{row[column.key]}</td>;
                        }
                    })}
                    {this.state.actions.length == 0 ? null : (<td>
                        {this.state.actions.map((action, actionIndex) => (
                            <span key={actionIndex}>
                                {(this.state.spinnerPoint[0] === index && this.state.spinnerPoint[1] === actionIndex) ? <Spinner className="spinner" animation="grow" size="sm" /> :
                                    action.permission ? <ShopAuthenticated permission={action.permission}> {this.actionView(action, index, actionIndex, row)}</ShopAuthenticated> : this.actionView(action, index, actionIndex, row)
                                }
                            </span>
                        ))}
                    </td>)}
                </tr>))}
        </tbody>);
    }

    emptyBodyView() {
        return (<tbody>
            <tr className="empty-row">
                <td colSpan={this.state.columns.length + 1}>not exist any data.</td>
            </tr>
        </tbody>);
    }

    actionView(action: GridAction, index: number, actionIndex: number, row: Record<string, any>) {
        if (action.render !== undefined)
            return action.render(row);
        return (action.generateLink === undefined ?
            (this.state.spinnerPoint[0] !== index) &&
            <Button className="action-button" size="sm" disabled={this.state.spinnerPoint.length !== 0 || (action.checkIsDisabled ? action.checkIsDisabled(row) : false)} variant={action.variant} onClick={() => {
                if (action.onClick !== undefined) {
                    if (action.skipSpinner !== true)
                        this.setState({ spinnerPoint: [index, actionIndex] })
                    action.onClick(row)
                }
            }} > {action.caption}</Button>
            : <NavLink to={action.generateLink(row)} className={(action.checkIsDisabled && action.checkIsDisabled(row)) ? 'button-disable' : ''}><Button disabled={action.checkIsDisabled ? action.checkIsDisabled(row) : false} className="action-button" size="sm" variant={action.variant}>{this.state.spinnerPoint[0] === index && this.state.spinnerPoint[1] === actionIndex ? 'loading' : action.caption}</Button></NavLink>)
    }
}