import { PaginationQuery } from "../../../components/Grid/Pagination/Pagination.models";
import { mapToDataOnly } from "../../common/HttpUtility";
import { CurrencyApiFactory, CurrencyRateApiFactory } from "../../../generated-api/financials";

interface ICurrencyDTO {
    title: string;
    symbol: string;
}

export default class AdminCurrenciesService {
    getPaged(filters: {}, paginationQuery: PaginationQuery): Promise<any> {
        return mapToDataOnly<any>(CurrencyApiFactory().apiV1AdminCurrencyGet(paginationQuery.pageskip - 1, paginationQuery.pagesize));
    }

    getById(currencyId: string): Promise<ICurrencyDTO> {
        return mapToDataOnly<ICurrencyDTO>(CurrencyApiFactory().apiV1AdminCurrencyIdGet(currencyId));
    }

    update(body: ICurrencyDTO): Promise<any> {
        return mapToDataOnly(CurrencyApiFactory().apiV1AdminCurrencyPut(body));
    }

    delete(id: string): Promise<any> {
        return mapToDataOnly(CurrencyApiFactory().apiV1AdminCurrencyIdDelete(id));
    }

    create(body: ICurrencyDTO): Promise<any> {
        return mapToDataOnly(CurrencyApiFactory().apiV1AdminCurrencyPost(body));
    }

    rateSetting(body): Promise<any> {
        return mapToDataOnly(CurrencyRateApiFactory().apiV1AdminCurrencyRateUpdateByCurrencyIdPut(body));
    }

    getLastRate(currencyId: string): Promise<CurrencyRateDTO> {
        return mapToDataOnly(CurrencyRateApiFactory().apiV1AdminCurrencyRateCurrencyIdGet(currencyId));
    }
}

interface CurrencyRateDTO {
    currencyId: string;
    dollarRate: number;
    title: string;
    isManual: boolean;
}