import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap"
import './ExportButtonView.scss'
import FiletypeXls from '../../../assets/svg/filetype-xls.svg'

export default class ExportButtonView extends React.PureComponent<{ onClick: () => void }> {
    render(): React.ReactNode {
        return (<OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={<Tooltip id="button-tooltip">
                Download All Record
            </Tooltip>}
        >
            <Button className="export-btn" variant="light" size="sm" onClick={this.props.onClick}>
                <img src={FiletypeXls} alt="Download All Record" width="25" />
            </Button>
        </OverlayTrigger>)
    }
}