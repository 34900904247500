import { Route } from "react-router-dom";
import DefaultLayout from "../../components/layout/DefaultLayout";
import EditCategoryFavoriteRoute from "../categories/EditCategoryFavoriteRoute";

export default function CategoriesRoutes() {
    return (
        <>
            <Route element={<DefaultLayout />}>
                <Route path="/edit-category-favorite" element={<EditCategoryFavoriteRoute />} />
            </Route>
        </>
    )
}