import React from 'react';
import { Alert, Modal, Spinner } from 'react-bootstrap'
import OrdersService from '../../../../services/orders/OrdersService';
import { OrderStatusEnum } from '../../OrderEnums';
import OrderItems from '../../OrderDetails/OrderItems';

export default class OrderDeliveryModal extends React.Component<{ closeModal: () => void, orderId: string, addToInventory: boolean }, { interval?: any, orderStatus: OrderStatusEnum }> {
    ordersService: OrdersService;
    constructor(props: any) {
        super(props)
        this.ordersService = new OrdersService();
        this.state = { orderStatus: OrderStatusEnum.Pending }
    }

    componentDidMount(): void {
        if (!this.props.addToInventory) {
            let interval = setInterval(() => this.loadDetails(), 5000);
            this.setState({ interval: interval })
        }
    }

    componentWillUnmount(): void {
        if (!this.props.addToInventory)
            clearInterval(this.state.interval);
    }

    loadDetails = () => {
        this.ordersService.getDetails(this.props.orderId).then((details: any) => {
            if (details.status == OrderStatusEnum.Done)
                this.setState({ orderStatus: details.status }, () => clearInterval(this.state.interval));
        });
    }

    render(): React.ReactNode {
        let { closeModal } = this.props
        let { orderStatus } = this.state

        return (
            <Modal
                size='lg'
                show={true}
                onHide={closeModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Order Delivery</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='text-center mb-4'>
                        {
                            this.props.addToInventory ? <Alert variant="success">It will be added to the warehouse after the order is completed</Alert> :
                                orderStatus == OrderStatusEnum.Done ? <OrderItems skipSafeMode={true} hideAlert orderId={this.props.orderId} disableShowCode={true} /> : <>
                                    <Spinner className='m-4 text-muted' animation='border' style={{ width: '4rem', height: '4rem' }} />
                                    <div className='text-muted'>Order Processing ...</div>
                                </>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}