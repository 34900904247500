import React from "react";
import { Outlet } from "react-router-dom";
import { Navigate } from 'react-router-dom';
import authenticationContext from "../../../contexts/AuthenticationContext/AuthenticationContext";
import { Steps } from 'intro.js-react';
import LayoutContext from "../../../contexts/LayoutContext/LayoutContext";
import { Subject } from "rxjs";
import IdentityService from "../../../services/identity/IdentityService";
import CoverFullScreen from "../CoverFullScreen/CoverFullScreen";
import ShopService from "../../../services/shops/ShopService";
import SuspendedMode from "../SuspendedMode/SuspendedMode";
import FullScreenLoading from "../FullScreenLoading/FullScreenLoading";
import AdminHeader from "./Header/AdminHeader";
import { ADMIN, CONTENT_WRITER, MEDIATOR, SUPERADMIN, SUPPORT } from "../../../constants/ConstantsPermissions";

export default class AdminLayout extends React.Component<{ hideSidebar?: boolean, hideMenu?: boolean }, {
    SidebarClosed: boolean, isMobile: boolean, progressPercent: number, suspendedMode?: boolean
}> {
    identityService: IdentityService;
    shopService: ShopService
    static contextType = authenticationContext;
    context!: React.ContextType<typeof authenticationContext>;

    constructor(props: any) {
        super(props);
        this.identityService = new IdentityService();
        this.shopService = new ShopService();
        this.state = { SidebarClosed: false, isMobile: false, progressPercent: 30 };
    }

    componentDidMount() {
        this.identityService.getUserInformation().then((response) => {
            this.context.twoFactorAvtive = response.data.hasTwoFactor
            this.context.privileges = response.data.privileges
            this.context.roles = response.data.roles
            this.context.user = { displayName: response.data.displayName, userName: response.data.userName }
            this.setState({ progressPercent: 50 });
        })
            .catch(() => {
                this.setState({ progressPercent: 100 });
            })
            .finally(() => {
                if (this.context.roles.map(item => [ADMIN, SUPERADMIN, MEDIATOR, CONTENT_WRITER, SUPPORT].includes(item)).length == 0)
                    window.location.pathname = '/dashboard'
                this.forceUpdate()

                this.setState({ progressPercent: 100 })
            })


        let isMobile = window.innerWidth > 960 ? false : true
        this.setState({ SidebarClosed: isMobile, isMobile })
    }


    render(): React.ReactNode {
        if (!this.context.isAuthentication())
            return <Navigate replace to="/login" />;

        if (this.state.progressPercent < 100)
            return <FullScreenLoading progressPercent={this.state.progressPercent} />

        let hideMenu = this.props.hideMenu ?? false;
        const steps = [
            {
                element: 'header .nav-area',
                intro: 'Navigate between different pages by clicking on these items',
                position: 'bottom',
                tooltipClass: 'myTooltipClass',
                highlightClass: 'myHighlightClass',
            },
            {
                element: 'header .sidebar-top svg',
                intro: 'With this button you can open and close the sidebar menu',
            },
            {
                element: '.Sidebar .shop-area .shop-selection .form-select',
                intro: 'Navigate between your stores and view their information.',
            },
            {
                element: '.Sidebar .wallet-overview',
                intro: 'You always see your current store wallet balance',
            },
            {
                element: '.Sidebar .CategorySidebar',
                intro: 'Always and everywhere the list of products available to you. Just click to buy!',
            }
        ];

        let contentCssClass = 'content' + (this.state.SidebarClosed ? ' sidebar-closed' : '');

        return (<div className={contentCssClass}>
            <LayoutContext.Provider value={{ onAddToCartSubject: new Subject(), loadCart: () => { }, onReviewRequestChangedStatus: () => { }, reloadSideBar: () => { } }}>
                <AdminHeader onToggleSidebar={() => this.setState({ SidebarClosed: !this.state.SidebarClosed })} hideMenu={hideMenu} isMobile={this.state.isMobile} />
                {
                    this.state.suspendedMode ? <SuspendedMode /> : <>
                        {this.state.isMobile && <CoverFullScreen onClick={() => this.setState({ SidebarClosed: !this.state.SidebarClosed })} isHide={this.state.SidebarClosed} />}
                        <div className="page-content">
                            <Outlet></Outlet>
                        </div>
                        <Steps
                            enabled={false}
                            initialStep={0}
                            steps={steps}
                            options={{ hidePrev: true, showStepNumbers: true, showButtons: true, showProgress: true, exitOnOverlayClick: false, exitOnEsc: false }}
                            onExit={() => { }}
                        />
                    </>
                }
            </LayoutContext.Provider>
        </div>);
    }
}