import React, { RefObject } from "react"
import { Form } from "react-bootstrap"
import CashOutsService from "../../../../services/financials/CashOutsService"
import { GridAction, GridColumn, GridColumnTypes } from "../../../Grid/Grid.models"
import DynamicGrid from "../../../shared/DynamicGrid/DynamicGrid"
import Note from "../../../shared/Note/Note"
import cashOutStatusDisplayBadge, { CashOutStatusEnum } from "../../Enums/CashOutStatusEnum"
import { IWithdrawalsPaginationFilters } from "./CashOutsList.models"
export default class CashOuts extends React.PureComponent {
    cashOutsService: CashOutsService
    dynamicGrid: RefObject<DynamicGrid<IWithdrawalsPaginationFilters>>
    constructor(props: any) {
        super(props);
        this.dynamicGrid = React.createRef()
        this.cashOutsService = new CashOutsService()
    }

    cancel = (id: string) => {
        this.cashOutsService.cancel(id).then(() => {
            this.dynamicGrid.current.fetch();
        })
    }

    render(): React.ReactNode {
        let gridColumns: GridColumn[] = [
            { title: 'Amount', key: 'amount', type: GridColumnTypes.Amount },
            { title: 'Financial Account', key: 'financialAccount' },
            { title: 'Send At', key: 'sendAt', type: GridColumnTypes.Date },
            { title: 'Request At', key: 'requestAt', type: GridColumnTypes.Date },
            { title: 'Status', key: 'statusBadge' },
        ]

        let gridActions: GridAction[] = [
            {
                caption: 'Cancel', variant: 'outline-danger', checkIsDisabled: (row) => row.status !== CashOutStatusEnum.Pending,
                onClick: (row) => {
                    this.cancel(row.id);
                }

            },
        ];
        return (<>
            <Note>You can withdraw your money and register a deposit request to a wallet outside the Gift API</Note>

            <DynamicGrid<IWithdrawalsPaginationFilters> ref={this.dynamicGrid}
                columns={gridColumns}
                onFetch={this.cashOutsService.getPaged}
                actions={gridActions}
                // filtersForm={this.filtersView}
                refactorRow={(row) => {
                    return { ...row, statusBadge: cashOutStatusDisplayBadge(row.status) }
                }} />
        </>)
    }


    filtersView = (filters: IWithdrawalsPaginationFilters, onChange: (newFilters: IWithdrawalsPaginationFilters) => void): JSX.Element => {
        return <>
            <Form.Group>
                <Form.Label>Financial Account</Form.Label>
                <Form.Control value={filters.account} onChange={(e) => {
                    onChange({ ...filters, account: e.target.value })
                }} type="text" placeholder="Enter Financial Account" />
            </Form.Group>
        </>
    }
}