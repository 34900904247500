import { ExchangeStatusEnum } from "../../components/financials/Enums/ExchangeStatusEnum";
import { GridResult } from "../../components/Grid/Grid.models";
import { PaginationQuery } from "../../components/Grid/Pagination/Pagination.models";
import { mapToDataOnly } from "../common/HttpUtility";
import { ExchangeTransactionApiFactory } from "../../generated-api/financials";

export default class ExchangeService {

    get(filters: IExchangesPaginationFilters, paginationQuery: PaginationQuery): Promise<GridResult<IExchangeWalletTransactionRowDTO>> {
        return mapToDataOnly<GridResult<IExchangeWalletTransactionRowDTO>>(ExchangeTransactionApiFactory().apiV1ExchangeTransactionGet(paginationQuery.pageskip - 1, paginationQuery.pagesize))
    }

    getById(exchangeId: string): Promise<IExchangeWalletTransactionRowDTO> {
        return mapToDataOnly<IExchangeWalletTransactionRowDTO>(ExchangeTransactionApiFactory().apiV1ExchangeTransactionExchangeIdGet(exchangeId));
    }

    create(dto: ICreateRequestExchangeDTO) {
        return mapToDataOnly(ExchangeTransactionApiFactory().apiV1ExchangeTransactionPost(dto));
    }
}

interface ICreateRequestExchangeDTO {
    fromWalletId: string,
    toWalletId: string,
    amount: number
}

export interface IExchangesPaginationFilters {

}

interface IExchangeWalletTransactionRowDTO {
    id: string,
    requestAt: Date,
    sourceCurrencyTitle: string,
    destinationCurrencyTitle: string,
    sourceAmount: number,
    destinationAmount: number,
    status: ExchangeStatusEnum,
}