import { Badge } from "react-bootstrap";

export enum OfferStatusEnum {
    Open,
    Canceled,
    Completed,
    Expired
}

export function offerStatusDisplayCaption(status: number) {
    let types = ['Open', 'Canceled', 'Completed', 'Expired'];
    return types.length <= status ? status : types[status];
}

export function offerStatusColor(status: number) {
    let types = ['warning',  'danger', 'success', 'danger'];
    return types.length <= status ? 'dark' : types[status];
}

export default function offerStatusEnumDisplayBadge(status: number) {
    return <Badge bg={offerStatusColor(status)}>{offerStatusDisplayCaption(status)}</Badge>;
}