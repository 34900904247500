import { useSearchParams } from "react-router-dom"
import AdminWalletTransaction from "../../../components/admin/financials/AdminWallets/Transaction/AdminWalletTransaction"
import AdminAuthenticated from "../../../components/shared/AdminAuthenticated/AdminAuthenticated"
import { ADMIN, SUPERADMIN } from "../../../constants/ConstantsPermissions"
import NotFoundRoute from "../../shared/NotFoundRoute"

export default function AdminWalletTransactionsRoute() {
    const [searchParams] = useSearchParams()
    let walletId = searchParams.get("id")

    return walletId === null ? <NotFoundRoute /> : <AdminAuthenticated roles={[SUPERADMIN, ADMIN]}><AdminWalletTransaction walletId={walletId} /></AdminAuthenticated>
}