import { IEditUserPrivilegeDTO, IUserPrivilegesRowDTO } from "../../components/identity/UserPrivilege/UserPrivilege.models";
import { mapToDataOnly } from "../common/HttpUtility";
import { UserPrivilegeApiFactory } from "../../generated-api";

export default class UserPrivilegeService {
    updatePrivilege(dto: IEditUserPrivilegeDTO[], userId: string): Promise<{}> {
        return mapToDataOnly<{}>(UserPrivilegeApiFactory().identityUserPrivilegeUserIdPut(userId, dto));
    }

    getUserPrivileges(userId: string): Promise<IUserPrivilegesRowDTO[]> {
        return mapToDataOnly<IUserPrivilegesRowDTO[]>(UserPrivilegeApiFactory().identityUserPrivilegeUserIdGet(userId));
    }
}