import React, { RefObject } from "react";
import { Button } from "react-bootstrap";
import FinancialManagementService, { IExternalWalletsPaginationFilters } from "../../../../services/financials/FinancialManagementService";
import { GridAction, GridColumn, GridColumnTypes } from "../../../Grid/Grid.models";
import { PaginationQuery } from "../../../Grid/Pagination/Pagination.models";
import DynamicGrid from "../../../shared/DynamicGrid/DynamicGrid";
import Note from "../../../shared/Note/Note";
import financialAccountDisplayBadge, { FinancialAccountStatusEnum } from "../../Enums/FinancialAccountStatusEnum";
import CreateFinancialAccountModal from "../CreateFinancialAccount/CreateFinancialAccountModal";
import FinancialAccountVerifyModal from "../VerifyModal/FinancialAccountVerifyModal";

export default class FinancialAccountsList extends React.PureComponent<{}, { showModal: boolean, rowId?: string, selectedAccount?: any }> {
    dynamicGrid: RefObject<DynamicGrid<IExternalWalletsPaginationFilters>>
    paginationQuery: PaginationQuery
    financialManagementService: FinancialManagementService
    constructor(props: any) {
        super(props);
        this.dynamicGrid = React.createRef()
        this.paginationQuery = { pageskip: 1, pagesize: 10 }
        this.financialManagementService = new FinancialManagementService()
        this.state = { showModal: false }
    }

    requestToRemoveRow = (id: string) => {
        if (window.confirm('Are you sure? This Financial Account will be deleted permanently')) {
            this.financialManagementService.delete(id).then(() => {
                this.dynamicGrid.current.fetch();
            })
        }
    }

    render(): React.ReactNode {
        let gridColumns: GridColumn[] = [
            { title: 'Account Identity', key: 'accountIdentity' },
            { title: 'Nick Name', key: 'nickName' },
            { title: 'Status', key: 'statusBadge' },
            { title: 'Create At', key: 'createAt', type: GridColumnTypes.Date },
            { title: 'Type', key: 'typeTitle' },
        ]

        let gridActions: GridAction[] = [
            { caption: 'Verify', variant: 'success', onClick: (row) => this.setState({ selectedAccount: row }), skipSpinner: true,
                checkIsDisabled:(row) => row.status != FinancialAccountStatusEnum.Unverified,
            },
            { caption: 'Delete', variant: 'danger', onClick: (row) => this.requestToRemoveRow(row.id) },
            { caption: 'Edit', variant: 'warning', onClick: (row) => this.setState({ showModal: true, rowId: row.id }) },
        ]

        return (<>
            <h3>External Wallets <Button variant="success" size="sm" onClick={() => this.setState({ showModal: true })}>Add</Button></h3>
            <Note>Add your external accounts (wallets) to use when withdrawing money.</Note>
            <hr />
            <DynamicGrid<IExternalWalletsPaginationFilters> ref={this.dynamicGrid}
                columns={gridColumns}
                onFetch={this.financialManagementService.getPaged}
                actions={gridActions}
                refactorRow={(row) => {
                    return { ...row, statusBadge: financialAccountDisplayBadge(row.status) }
                }} />

            {this.state.showModal && <CreateFinancialAccountModal onCancel={() => {
                this.setState({ showModal: false, rowId: undefined });
                this.dynamicGrid.current?.resetSpinner();
            }} onComplete={() => {
                this.setState({ showModal: false, rowId: undefined });
                this.dynamicGrid.current?.resetSpinner();
                this.dynamicGrid.current.fetch();
            }} financialAccountId={this.state.rowId} />}
            {this.state.selectedAccount && <FinancialAccountVerifyModal 
                sourceWallet={this.state.selectedAccount?.accountIdentity}
                reload={() => this.dynamicGrid.current?.fetch()}
                onClose={() => this.setState({ selectedAccount: undefined })} accountId={this.state.selectedAccount?.id} />}
        </>)
    }
}