import React, { RefObject } from "react"
import { Button } from "react-bootstrap"
import { NavLink } from "react-router-dom"
import AdminProductService from "../../../services/admin/products/AdminProductService"
import { GridAction, GridColumn } from "../../Grid/Grid.models"
import DynamicGrid from "../../shared/DynamicGrid/DynamicGrid"

export default class AdminProducts extends React.Component<{}, { departmentId?: string }> {
    dynamicGrid: RefObject<DynamicGrid<{}>>
    adminProductService: AdminProductService

    constructor(props: any) {
        super(props)
        this.dynamicGrid = React.createRef()
        this.state = { }
        this.adminProductService = new AdminProductService()
    }

    departmentStatusDisplay = (status: boolean) => {
        return <span>{status ? 'Active' : 'Inactive'}</span>
    }

    requestToRemoveRow = (id: string) => {
        if (window.confirm('Are you sure? This Product will be deleted permanently')) {
            this.adminProductService.delete(id).then(() => {
                this.dynamicGrid.current.fetch();
            })
        }
    }

    render(): React.ReactNode {
        let gridColumns: GridColumn[] = [
            { title: "Title", key: "title" },
            { title: "Status", key: "isActive" },
            { title: "Short Title", key: "shortTitle" },
            { title: "Real Price", key: "realPrice" },
        ]

        let gridActions: GridAction[] = [
            { caption: "Copy", variant: "outline-primary", onClick: (row) => this.setState({ departmentId: row.id }), skipSpinner: true, generateLink: (row) => `/admin/products-manage/?productId=${row.id}&&state=copy` },
            { caption: "Edit", variant: "outline-warning", onClick: (row) => this.setState({ departmentId: row.id }), skipSpinner: true, generateLink: (row) => `/admin/products-manage/?productId=${row.id}&&state=edit` },
            {caption: "Delete", variant: "outline-danger", onClick: (row) => this.requestToRemoveRow(row.id), skipSpinner: true },
        ]

        return (
            <>
                <NavLink to={'/admin/products-manage'}><Button variant="success" className="mt-2 mb-2">Add</Button></NavLink>
                <DynamicGrid<{}>
                    ref={this.dynamicGrid}
                    columns={gridColumns}
                    onFetch={this.adminProductService.getPaged}
                    hideFilterBar
                    actions={gridActions}
                    defaultFilters={{}}
                    refactorRow={(row) => {
                        return { ...row, isActive: this.departmentStatusDisplay(row.isActive) }
                    }}
                />
            </>
        )
    }
}