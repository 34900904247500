import { CashPaymentApiFactory } from "../../generated-api/financials";
import { mapToDataOnly } from "../common/HttpUtility"

export default class CashPaymentService {
    updateCashPaymentAverage(dto: IChangeCashPaymentAverage): Promise<{}> {
        return mapToDataOnly<{}>(CashPaymentApiFactory().apiV1CashPaymentPut(dto));
    }
}

interface IChangeCashPaymentAverage {
    currencyId: string
    value: number
}