import React from "react"
import { Modal, Tab, Tabs } from "react-bootstrap";
import { FileLockFill, Lock, LockFill } from "react-bootstrap-icons";
import CreateSupportTicket from "../../helpCenter/SupportTickets/Create/CreateSupportTicket";
import SupportTicketDetials from "../../helpCenter/SupportTickets/Details/SupportTicketDetials";
import SupportTicketList from "../../helpCenter/SupportTickets/SupportTicketList";
import './SuspendedMode.scss'

export default class SuspendedMode extends React.Component<{}, { selectedTicketId?: string }> {
    constructor(props: any) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
    }


    render(): React.ReactNode {
        return <div className="SuspendedMode">
            <div className="head">
                <FileLockFill />
                <h2 style={{ color: 'red' }}>Your shop has been suspended!</h2>
                <p>
                    All the facilities of the website are blocked for you and you can only take action to unblock your store by sending a ticket.
                </p>
            </div>
            <Tabs defaultActiveKey="list" id="SuspendedMode-tabs" className="mb-3" mountOnEnter={true} unmountOnExit={true}>
                <Tab eventKey="list" title="Support Tickets">
                    <SupportTicketList isActive={true}
                        globalTicketOnly={false}
                        detailsRowActionOnClick={(id) => this.setState({ selectedTicketId: id })} />
                </Tab>

                <Tab eventKey="new" title="New">
                    <CreateSupportTicket />
                </Tab>
            </Tabs>

            <Modal show={this.state.selectedTicketId !== undefined} size="lg"
                onHide={() => this.setState({ selectedTicketId: undefined })}>
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body>
                    <SupportTicketDetials id={this.state.selectedTicketId} />
                </Modal.Body>
            </Modal>
        </div>
    }
}