import React, { RefObject } from "react";
import SupportTicketsService from "../../../services/helpCenter/SupportTicketService";
import Grid from "../../Grid/Grid";
import { GridAction, GridColumn, GridColumnTypes } from "../../Grid/Grid.models";
import { PaginationQuery } from "../../Grid/Pagination/Pagination.models";
import { SupportTicketStatus } from "../Reports/ReportsList/ReportsList.models";
import supportTicketStatusDisplayBadge from "./SupportTicketStatusEnum";
import { Form } from "react-bootstrap";
import DynamicGrid from "../../shared/DynamicGrid/DynamicGrid";
import FilterBar from "../../Grid/Filters/FilterBar";
import ReviewRequestService from "../../../services/orders/ReviewRequestService";

interface IFilters {
    ticketNumber: string
}

export default class SupportTicketList extends React.Component<{
    isActive?: boolean
    status?: SupportTicketStatus,
    globalTicketOnly: boolean,
    detailsRowActionOnClick?: (id: string) => void
}, { filters: IFilters, editFilters: IFilters }> {
    grid: RefObject<Grid>;
    dynamicGrid: RefObject<DynamicGrid<{}>>;
    query: PaginationQuery;
    supportTicketsService: SupportTicketsService;
    reviewRequestService: ReviewRequestService;
    filters: IFilters
    constructor(props: any) {
        super(props);
        this.grid = React.createRef();
        this.dynamicGrid = React.createRef();
        this.supportTicketsService = new SupportTicketsService();
        this.reviewRequestService = new ReviewRequestService();
        this.query = { pageskip: 1, pagesize: 10 };
        this.filters = { ticketNumber: '' }
        this.state = { editFilters: { ticketNumber: '' }, filters: { ticketNumber: '' }}
    }


    componentDidMount = () => {
        this.changePage()
    }

    changePage = (query: PaginationQuery = this.query) => {
        this.query = query;
        var service = this.reviewRequestService.getBuyPaginated;
        if (this.props.globalTicketOnly === true)
            service = this.supportTicketsService.getGlobalTicket;

        service({ pageskip: query.pageskip, pagesize: query.pagesize, isActive: this.props.isActive, status: this.props.status, ...this.filters }).then(tickets => {
            let rows = tickets.rows.map((ticket) => {
                return { ...ticket, status: supportTicketStatusDisplayBadge(ticket.status) }
            });
            this.grid.current?.load(rows, tickets.totalRows);
        });

        this.setState({ filters: { ...this.filters } });
    }

    changeRowColor = (row: any) => {
        if (row.isMediationRequested === true && row.status !== 3)
            return "bg-danger";

        return "";
    }

    render(): React.ReactNode {
        var detailsRowActionOnClick = this.props.detailsRowActionOnClick;
        var self = this;
        let gridColumns: GridColumn[] = [
            { title: 'Ticket Number', key: 'ticketNumber' },
            { title: 'Title', key: 'title' },
            { title: 'User', key: 'memberUserDisplayName' },
            { title: 'Created', key: 'createAt', type: GridColumnTypes.DateOnly },
            { title: 'Status', key: 'status', type: GridColumnTypes.Enum },
            { title: 'Tags', key: 'tags', type: GridColumnTypes.BadgeList },
        ];

        let gridActions: GridAction[] = [];
        if (detailsRowActionOnClick === undefined)
            gridActions.push({ caption: 'Details', variant: 'outline-info', generateLink: (row) => `/support-ticket?id=${row.id}` });
        else
            gridActions.push({
                caption: 'Details', variant: 'outline-info', onClick(row) {
                    detailsRowActionOnClick(row.id);
                    self.grid.current.resetSpinner();
                },
            });

        return (<div>
            {this.filtersView()}
            <Grid rowClassName={this.changeRowColor} ref={this.grid} columns={gridColumns} actions={gridActions} onChangePage={this.changePage}></Grid>
        </div>)
    }

    applyFilters = () => {
        this.filters = { ...this.state.editFilters }
        this.changePage(this.query)
    }

    removeFilter = (key: string) => {
        let filters: any = this.state.editFilters
        filters[key] = ''
        this.setState({ editFilters: filters })
        this.applyFilters()
    }

    filtersView = () => {
        return <>
            <FilterBar onApply={this.applyFilters}
                onOpened={() => this.setState({ editFilters: { ...this.state.filters } })}
                filters={this.state.filters}
                onRemoveFilter={this.removeFilter}>
                <Form.Group>
                    <Form.Label>Ticket Number</Form.Label>
                    <Form.Control value={this.state.editFilters.ticketNumber} onChange={(e) => {
                        this.setState({ editFilters: { ...this.state.editFilters, ticketNumber: e.target.value } })
                    }} type="text" placeholder="Enter Ticket Number" />
                </Form.Group>
            </FilterBar>
        </>
    }
}