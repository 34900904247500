import React, { FormEvent } from "react";
import { Badge, Button, Card, Form, Spinner } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import IdentityService from "../../../services/identity/IdentityService";
import CardView from "../../shared/Card/CardView";
import '../IdentityLayout.scss'

export default class ResetPassword extends React.Component<Props, State> {
    returnUrl: string;
    identityService: IdentityService;
    constructor(props: any) {
        super(props);
        this.state = { password: '', repeatPassword: '', loading: false, done: false };
        this.identityService = new IdentityService();
        this.returnUrl = `${window.location.protocol}//${window.location.host}/reset-password`;
    }
    submit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();

        this.setState({ loading: true });
        this.identityService.resetPassword({ newPassowrd: this.state.password, token: this.props.token, userId: this.props.userId}, this.returnUrl).then(() => {
            toast.success('Password changed successfully.');
            this.setState({ done: true });
        }).finally(() => {
            this.setState({ loading: false });
        })
    }
    render(): React.ReactNode {
        return <div className="IdentityLayout">
            <div className="box">
                <h3>Reset Password</h3>
                <hr />
                {
                    this.state.done ?
                        <div className="center-text">
                            You can sign in with new password now!
                        </div> :
                        <Form onSubmit={this.submit}>
                            <Form.Group className="mb-3" controlId="formResetPassword">
                                <Form.Label>New Password</Form.Label>
                                <Form.Control type="password" placeholder="Enter new password" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} />
                                {
                                    this.state.password.length === 0 || /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,}$/.test(this.state.password) ? null : <Badge bg={'danger'}>Your password is not strong enough</Badge>
                                }
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formResetPassword2">
                                <Form.Label>Repeat</Form.Label>
                                <Form.Control type="password" placeholder="Enter new password again" value={this.state.repeatPassword} onChange={(e) => this.setState({ repeatPassword: e.target.value })} />
                                {this.state.password !== this.state.repeatPassword ? <Badge bg={'danger'}>Your password is not equal to repeating it.</Badge> : null}
                            </Form.Group>

                            <div className="mb-3 mt-5">
                                <CardView small card={{ bg: 'light', body: 'Passowrd should have at least one lower case, upper case, number, special character and minimum 8 characters' }} />
                            </div>

                            {
                                this.state.loading ? <Button variant="primary" disabled>
                                    <Spinner animation="border" size="sm" />
                                </Button> : <Button variant="primary" type="submit" disabled={this.state.password !== this.state.repeatPassword || this.state.password.length === 0}>
                                    Change Password
                                </Button>
                            }
                        </Form>
                }
            </div>
            <div className="footer">
                <NavLink to={'/login'}>Sign in</NavLink>
            </div>
        </div>
    }
}

interface Props {
    token: string,
    userId: string
}

interface State {
    password: string,
    repeatPassword: string,
    done: boolean,
    loading: boolean,
}