import React from "react";
import RequestAccessModal from "../RequestAccessModal/RequestAccessModal";
import IState, { IProps } from "./ShopPolicies.models";
import authenticationContext from "../../../../contexts/AuthenticationContext/AuthenticationContext";
import ShopService from "../../../../services/shops/ShopService";

export default class ShopPolicies extends React.Component<IProps, IState> {
    static contextType = authenticationContext;
    context!: React.ContextType<typeof authenticationContext>;
    shopService: ShopService

    constructor(props: any) {
        super(props)
        this.shopService = new ShopService()
        this.state = { showModal: false }
    }

    setPolicies = () => {
        this.shopService.getOverview().then(response => {
            this.context.policies = response.policies
        })
    }

    actionAfterCheckPolicy = (data?: any) => {
        if (this.hasPolicy(this.props.policy)) this.props.action && this.props.action(data)
        else this.setState({ showModal: true })
    }

    hasPolicy = (policy: string) => this.context.policies?.includes(policy)

    renderChildren = () => {
        if (!this.props.children)
            return null

        if (this.props.access === false) {
            if (!this.context.policies?.includes(this.props.policy))
                return this.props.children;
            else return null
        } else {
            if (this.context.policies?.includes(this.props.policy))
                return this.props.children;
            else return null
        }

    }
    render(): React.ReactNode {
        return (<>
            {
                this.renderChildren()
            }
            {this.state.showModal && <RequestAccessModal onSubmit={this.setPolicies} policyKey={this.props.policy}
                small={this.props.modalConfig?.small}
                description={this.props.modalConfig?.description} closeModal={() => this.setState({ showModal: false })} />}
        </>)
    }
}