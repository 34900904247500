import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import './HeaderComponent.scss'
import { ChevronDown } from "react-bootstrap-icons"
import ActionsComponent from "../../shared/Actions/Actions"
import SlideView from "./SlideView/SlideView"

export default class HeaderComponent extends React.PureComponent<{}, {}> {
    render(): React.ReactNode {
        return <div className="header">
            <Container>
                <Row>
                    <Col md="12" sm="12" className="mt-lg area-header-image">
                        <SlideView />
                    </Col>
                </Row>
                <Row>
                    <Col md="12" sm="12" className="mt-lg">
                        <span className="text-black font-bold fs-1">API Gift</span>
                        <p className="text-grey">The widest variety of Gift Cards at cheapest prices ever!</p>
                        <span className="text-black line-normal">Welcome to API Gift, the world's biggest Gift Card marketplace with the lowest prices ever!
                            Here you can directly buy your desired Gift Cards at the lowest possible price, from the world's widest Gift Card variety,
                            for yourself or for your store to sell them to your customers as a seller and earn money!
                            Also many powerful tools are designed here for you, which we promise you'll be excited about them. So let's get started!</span>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col md="12" lg="3" sm="12">
                        <ActionsComponent />
                    </Col>
                    <Col md="12" lg="9" sm="12" className="mobile-display">
                        <p className="text-grey line-normal">From Apple gift cards to Google Play, PUBG, PlayStation, Xbox, and thousands of gift Cards at the much lower prices than what you think are waiting for you at API Gift!</p>
                        <div className="mt-2 d-flex justify-content-center">
                            <a href="#financial-services" className="link">
                                <div className="circle me-2"><ChevronDown /></div>
                                <span>See Gift Cards and our services</span>
                            </a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    }
}