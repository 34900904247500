import AdminAuthenticated from "../../../components/shared/AdminAuthenticated/AdminAuthenticated"
import { ADMIN, SUPERADMIN } from "../../../constants/ConstantsPermissions"
import AdminMarket from "../../../components/admin/market/AdminMarket"
import { useSearchParams } from "react-router-dom"

export default function AdminMarketRoute() {
    const [searchParams] = useSearchParams()
    let activeTab = searchParams.get("activeTab")
    
    return <AdminAuthenticated roles={[SUPERADMIN, ADMIN]}><AdminMarket defaultActiveKey={activeTab} /></AdminAuthenticated>
}