import { useSearchParams } from "react-router-dom";
import Marketplace from "../../components/shopping/Marketplace/Marketplace";
import NotFoundRoute from "../shared/NotFoundRoute";

export default function StoreRoute() {
    const [searchParams] = useSearchParams();
    let categoryId = searchParams.get("categoryId");
    let productId = searchParams.get("productId");
    let search = searchParams.get("search");
    let mode = searchParams.get("mode");
    if (!mode)
        mode = 'express'
    return <Marketplace categoryId={categoryId as string} productId={productId as string} search={search as string} mode={mode as "express" | "trade" } />
}