import { Badge } from "react-bootstrap";
export enum ShopInventoryStatusEnum {
    Available,
    Sold,
    ManualSold,
    Hold,
    Reserved,
    Preparing,
    Validation,
    Lock
}

export function shopInventoryStatusDisplayCaption(status: number) {
    let types = ['Available', 'Out', 'ManualOut', 'Hold', 'Reserved', 'Preparing','Validation','Lock'];
    return types.length <= status ? status : types[status];
}

export function shopInventoryStatusColor(status: number) {
    let types = ['success', 'info', 'info', 'primary', 'warning', 'warning','warning','danger'];
    return types.length <= status ? 'dark' : types[status];
}

export default function shopInventoryStatusDisplayBadge(status: number) {
    return <Badge bg={shopInventoryStatusColor(status)}>{shopInventoryStatusDisplayCaption(status)}</Badge>;
}