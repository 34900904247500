import { Badge } from "react-bootstrap";

export enum OrderTypeEnum {
    Website, API,Auto,Offer
}

export function orderTypeDisplayCaption(type: number) {
    let types = ['Website', 'API','Auto','Offer'];
    return types.length <= type ? type : types[type];
}

export default function orderTypeDisplayBadge(type: number) {
    return <Badge bg={'info'}>{orderTypeDisplayCaption(type)}</Badge>;
}