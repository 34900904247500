import React from "react";
import { Button } from "react-bootstrap";
import { Check, CheckCircleFill } from "react-bootstrap-icons";
import { NavLink } from "react-router-dom";
import '../IdentityLayout.scss'

export default class Confirmed extends React.Component {
    render(): React.ReactNode {
        return <div className="IdentityLayout">
            <div className="box">
                <CheckCircleFill className="icon" />
                <b>Your email has been confirmed. </b><br />
                Now you can enter the world of products and enjoy it.
                <NavLink to={'/marketplace'}><Button variant="outline-success">Go to market</Button></NavLink>
            </div>
        </div>
    }
}