import Netflix from '../../../assets/images/giftcarts/Netflix.webp'
import AppleMusic from '../../../assets/images/giftcarts/AppleMusic.webp'
import Spotify from '../../../assets/images/giftcarts/Spotify.webp'

interface ICardView {
    title: string,
    image: any,
    subTitle?: string
}
export const MusicVideosData: ICardView[] = [
    {
        image: Netflix,
        title: 'Netflix',
    },
    {
        image: AppleMusic,
        title: 'Apple Music',
    },
    {
        image: Spotify,
        title: 'Spotify',
    },
]
