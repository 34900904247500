import React from "react"
import SalesStatistics from "../orders/statistics/SalesStatisticsChart/SalesStatisticsChart"
import { Col, Row } from "react-bootstrap"
import SalesStatisticsReport from "../orders/statistics/SalesStatisticsReport/SalesStatisticsReport"

export default class AdminDashboard extends React.Component<{}, {}> {
    render(): React.ReactNode {
        return (<>
            <div className="mt-4">
                <SalesStatisticsReport />
                <Row className="mt-4 mb-4">
                    <Col className="mb-5" md="12" sm="12">
                        <SalesStatistics title="Total Sales" />
                    </Col>
                    <Col className="mb-5" md="12" sm="12">
                        <SalesStatistics title="Sales Profit" onlyShared colors={["#febc3b"]} />
                    </Col>
                </Row>
            </div>
        </>)
    }
}