import React from "react"
import { Container } from "react-bootstrap"
import { AboutUsData } from '../../constants/Content/AboutUs'
import SectionView from "../../shared/SectionView/SectionView"

export default class AboutUs extends React.PureComponent<{}, {}> {
    render(): React.ReactNode {
        return <div>
            <Container className="pt-7">
                <SectionView title={AboutUsData.title} description={AboutUsData.description} />
            </Container>
        </div>
    }
}