import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import AdminConfig from "../support-tickets/Config/AdminSupportTicketsConfig";
import AdminTicketsList from "../support-tickets/TicketList/AdminTicketsList";

export default class AdminReviewRequest extends React.Component<{ activeTab: string | null, onlynegative?: boolean | null }, { navigateToTab?: string, onlyNegativeWallet?: boolean }> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    componentDidUpdate(prevProps: Readonly<{ activeTab: string | null; }>, prevState: Readonly<{ navigateToTab?: string; }>, snapshot?: any): void {
        if (prevProps.activeTab !== this.props.activeTab)
            this.setState({ navigateToTab: undefined })
    }

    handleSelect = (key) => this.setState({ navigateToTab: key });

    render(): React.ReactNode {

        return <>
            {this.state.navigateToTab && this.state.navigateToTab !== this.props.activeTab ? <Navigate to={"/admin/review-request/?activeTab=" + this.state.navigateToTab} /> : null}
            <Tabs activeKey={this.props.activeTab ?? "meditation"} onSelect={this.handleSelect} id="support-ticket-tabs" className="mb-3" mountOnEnter={true} unmountOnExit={true}>
                <Tab eventKey="meditation" title="Meditation">
                    <Tabs id="meditation-tabs" className="mb-3" mountOnEnter={true} unmountOnExit={true}>
                        <Tab eventKey="meditation-active" title="Open">
                            <AdminTicketsList onlyMediationRequested isActive={true} />
                        </Tab>
                        <Tab eventKey="meditation-archive" title="Close">
                            <AdminTicketsList onlyMediationRequested isActive={false} />
                        </Tab>
                    </Tabs>
                </Tab>
                <Tab eventKey="review" title="Review">
                    <Tabs id="review-tabs" className="mb-3" mountOnEnter={true} unmountOnExit={true}>
                        <Tab eventKey="review-checked" title="Checked">
                            <AdminTicketsList isReviewRequest />
                        </Tab>
                        <Tab eventKey="review-unchecked" title="Un Checked">
                            <AdminTicketsList isReviewRequest />
                        </Tab>
                    </Tabs>
                </Tab>
                <Tab eventKey="config" title="Config">
                    <AdminConfig />
                </Tab>
            </Tabs>
        </>
    }
}