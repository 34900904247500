import React from "react";
import { Link } from "react-router-dom";

export interface ExpressProductCatalogItemProps {
    title: string
    imageSrc: string | null
    imagePath?: string | null
    categoryId: string
    hasSubMenu: boolean
    onClick: (id: string) => void
}

export default class ExpressCategoryCatalogItem extends React.Component<ExpressProductCatalogItemProps, {}> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    categoryItem = (imagePath: string, title: string) => {
        return (
            <div className={"CategoryCatalogItem "}>
                <img src={imagePath == null ? '/images/product-none.png' : imagePath} className="category-image" />
                <div className="category-title">{title}</div>
            </div>
        )
    }

    render(): React.ReactNode {
        let item = this.props;
        return (
            this.props.hasSubMenu ? <div onClick={() => this.props.onClick(item.categoryId)}>{this.categoryItem(item.imagePath, item.title)}</div> :
                <Link to={`/marketplace/?categoryId=${item.categoryId}`}>
                    {this.categoryItem(item.imagePath, item.title)}
                </Link>
        )
    }
}