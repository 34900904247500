import React from 'react';
import { Col, Modal, Row, Spinner } from 'react-bootstrap'
import { StarFill } from 'react-bootstrap-icons';
import WalletService, { FavoriteTransferDestinationRow } from '../../../services/financials/WalletService';
import './FavoriteDestinationsModal.scss'

export default class FavoriteDestinationsModal extends React.Component<{ onClose: () => void, onSelect: (address: string) => void }, { loading: boolean, destinations: FavoriteTransferDestinationRow[] }> {
    walletService: WalletService;

    constructor(props: any) {
        super(props)
        this.walletService = new WalletService();
        this.state = { loading: false, destinations: [] }
    }

    componentDidMount(): void {
        this.setState({ loading: true })

        // this.walletService.getFavoriteDestinations().then((response) => {
        //     this.setState({ destinations: response.result })
        // }).finally(() => this.setState({ loading: false }))
    }

    render(): React.ReactNode {
        return <>
            <Modal
                show={true}
                backdrop="static"
                onHide={this.props.onClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title><StarFill style={{ color: '#ffc107' }} className='mb-1 mr-1' />Destinations</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        this.state.loading ? <Spinner animation="grow" /> :
                            <>
                                {
                                    this.state.destinations.length == 0 ?
                                        <div className='text-grey text-center'>Favorites list is empty</div> :
                                        <Row>
                                            {
                                                this.state.destinations.map((destination, index) => {
                                                    return <Col md="12" sm="12" className='mb-2' key={index}>
                                                        <div className='bordered pointer d-flex justify-content-between' onClick={() => {
                                                            this.props.onSelect(destination.address)
                                                            this.props.onClose()
                                                        }}>
                                                            <div>{destination.shopName}</div>
                                                            <div className='text-grey'>{destination.address}</div>
                                                        </div>
                                                    </Col>
                                                })
                                            }
                                        </Row>
                                }
                            </>
                    }
                </Modal.Body>
            </Modal >
        </>
    }
}


