import React from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import AdminCurrenciesService from "../../../../../services/admin/financials/AdminCurrenciesService";
import IState, { IProps } from "./AdminChangeRateModal.models";

export default class AdminChangeRateModal extends React.Component<IProps, IState> {
    adminCurrenciesService: AdminCurrenciesService
    constructor(props: any) {
        super(props);
        this.state = { pendingRequest: false, loading: false, isManual: false, title: '' }
        this.adminCurrenciesService = new AdminCurrenciesService()
    }

    componentDidMount(): void {
        this.setState({ loading: true })

        this.adminCurrenciesService.getLastRate(this.props.currencyId).then((response) => {
            this.setState({
                rate: (response.isManual == true) || (response.isManual == null && response.dollarRate > 0) ? response.dollarRate : null,
                minimumRate: response.isManual == false ? response.dollarRate : null,
                title: response.title,
                isManual: response.isManual == true || (response.isManual == null && response.dollarRate > 0) ? true : false
            })
        }).finally(() => this.setState({ loading: false }))
    }

    submit = () => {
        this.setState({ pendingRequest: true })

        let body = {
            currencyId: this.props.currencyId,
            rate: !this.state.isManual ? this.state.minimumRate : this.state.rate,
            isManual: this.state.isManual,
        }

        this.adminCurrenciesService.rateSetting(body).then(() => {
            this.props.onClose()
            this.props.reload()
        }).finally(() => this.setState({ pendingRequest: false }))
    }

    render(): React.ReactNode {
        let { onClose } = this.props

        let { pendingRequest, rate, title, isManual, loading, minimumRate } = this.state

        return <Modal show={true} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Change Rate</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {
                        loading ? <Spinner animation='grow' /> :
                            <Row>
                                <Col md="12" sm="12">
                                    <b>{title}</b>
                                </Col>
                                <Col className="mt-3" md="12" sm="12">
                                    <Form.Check
                                        label="Automatic"
                                        type="radio"
                                        checked={!isManual}
                                        onChange={() => this.setState({ isManual: false })}
                                        id='automatic'
                                    />
                                    <Form.Check
                                        label="Manual"
                                        type="radio"
                                        checked={isManual}
                                        onChange={() => this.setState({ isManual: true })}
                                        id='manual'
                                    />
                                </Col>
                                {
                                    isManual == true ?
                                        <Col className="mt-3" md="12" sm="12">
                                            <Form.Label>Rate</Form.Label>
                                            <Form.Control type="number" value={rate} onChange={e => this.setState({ rate: Number(e.target.value) })} />
                                        </Col>
                                        :
                                        <Col className="mt-3" md="12" sm="12">
                                            <Form.Label>Minimum Rate:</Form.Label>
                                            <div>
                                                <Form.Control id="minimumRate" type="number" value={minimumRate} onChange={e => this.setState({ minimumRate: Number(e.target.value) })} />
                                            </div>
                                        </Col>
                                }
                            </Row>
                    }
                </Form>
            </Modal.Body>
            <Modal.Footer>
                {pendingRequest ? <Button variant='success' disabled><Spinner size="sm" animation='border' /></Button> :
                    <Button variant='success' onClick={this.submit}>Save</Button>}
            </Modal.Footer>
        </Modal>
    }
}