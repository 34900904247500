import React from 'react';
import { Alert, Button, Modal, OverlayTrigger, Spinner, Table, Tooltip } from 'react-bootstrap';
import './ProductCatalog.scss'
import ProductCatalogSellerRow from './ProductSellerCatalog';
import { toast } from 'react-toastify';
import ProductCatalogService from '../../../services/catalog/ProductCatalogService';
import { Cart3 } from 'react-bootstrap-icons';
import Note from '../../shared/Note/Note';
import { NavLink } from 'react-router-dom';


interface Props {
    productId: string
    onClose: () => void
    onAddToCart: (count: number, productId: string, sellerShopId: string, unitPrice: number) => Promise<any>
}

export default class ProductCatalog extends React.Component<Props, { sellers: ProductCatalogSellersView[], loading: boolean, pendingRequest: number }> {
    productCatalogService: ProductCatalogService;
    constructor(props: any) {
        super(props);
        this.state = { sellers: [], loading: true, pendingRequest: 0 }
        this.productCatalogService = new ProductCatalogService();
    }
    componentDidMount(): void {
        this.productCatalogService.getPrices(this.props.productId).then((productPrices) => {
            var sellers = productPrices.sellers.map((seller) => { return { count: 0, details: { ...seller, isYourShop: false } } });
            if (productPrices.ownedSellPlan && productPrices.ownedSellPlan.status === 2)
                sellers.push({
                    count: 0, details: {
                        delayDescription: productPrices.ownedSellPlan.delayDescription,
                        inventoryCount: productPrices.ownedSellPlan.availableInventoryCount,
                        plans: productPrices.ownedSellPlan.plans,
                        sellerShopId: '',
                        title: 'Your Shop',
                        isYourShop: true,
                        totalSoldCount: '0',
                        productSoldCount: '0',
                    }
                })
            this.setState({ sellers: sellers, loading: false })
        });
    }
    changeCount = (index: number, count: number | null) => {
        count = count === null ? 0 : count;
        let sellers = [...this.state.sellers];
        sellers[index] = { ...sellers[index], count };
        this.setState({ sellers });
    }
    addToCart = () => {
        let addItems = this.state.sellers.filter(seller => seller.count > 0);
        this.setState({ pendingRequest: addItems.length })
        addItems.map((seller) => {
            if (seller.details.plans.filter(plan => plan.countFrom <= seller.count && plan.countTo > seller.count).length > 0) {
                let price = seller.details.plans.filter(plan => plan.countFrom <= seller.count && plan.countTo > seller.count)[0].price

                this.props.onAddToCart(seller.count, this.props.productId, seller.details.sellerShopId, price).finally(() => {
                    this.setState({ pendingRequest: this.state.pendingRequest - 1 })
                    this.props.onClose();
                })
            } else {
                this.setState({ pendingRequest: 0 })
                toast.error('The entered number is not in any of the ranges');
            }

        })
    }
    tbodyView(sellers: ProductCatalogSellersView[], onChangeCount: (index: number, count: number) => void) {



        return <tbody>
            {
                sellers.map((seller, index) => <ProductCatalogSellerRow onChangeCount={(count) => onChangeCount(index, count)}
                    productSoldCount={seller.details.productSoldCount}
                    isYourShop={seller.details.isYourShop}
                    totalSoldCount={seller.details.totalSoldCount}
                    delayDescription={seller.details.delayDescription} inventoryCount={seller.details.inventoryCount}
                    sellerShopId={seller.details.sellerShopId} title={seller.details.title} plans={seller.details.plans} />)
            }

        </tbody>
    }
    render(): React.ReactNode {
        let selectedItemCount = this.state.sellers.reduce((a, b) => a + b.count, 0);
        if (this.state.loading)
            return <Spinner animation='grow' />

        if (this.state.sellers.length === 0)
            return <Alert variant='warning' style={{ textAlign: 'center' }} >
                Unfortunately, at this moment, there is no seller to supply this product.<br />
                You can submit your offer so that the auction takes place and the sellers compete for it (of course, it may take some time!)<br /><br />
                <NavLink to={`/marketplace?mode=trade&productId=${this.props.productId}`}><Button variant='outline-success'>Create Offer !</Button></NavLink>
            </Alert>

        return <div className='ProductCatalogSellers'>
            <Table style={{ textAlign: "center", verticalAlign: "middle" }} size="lg" responsive>
                <thead>
                    <tr>
                        <th>Seller</th>
                        <th>
                            <OverlayTrigger
                                key="top"
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip-top">
                                        Inventory Item Count
                                    </Tooltip>
                                }
                            >
                                <span>
                                    Count
                                </span>
                            </OverlayTrigger>

                        </th>
                        <th colSpan={4}>Sell Plan Count</th>
                        <th></th>
                    </tr>
                </thead>
                {this.tbodyView(this.state.sellers, this.changeCount)}

            </Table>

            {this.state.sellers.filter(seller => seller.details.inventoryCount > 0 || seller.details.inventoryCount === null).length === 0 ?
                <Alert variant='warning' style={{ textAlign: 'center' }}>
                    None of the sellers are ready to sell right now, but you can create your own offer to show the sellers and they usually ship it quickly (sometimes it can take a while).
                    <br /><br />
                    <NavLink to={`/marketplace?mode=trade&productId=${this.props.productId}`}><Button variant='outline-success'>Create Offer !</Button></NavLink>
                </Alert>
                : <Note>
                    Also, if you have another proposed price for purchase, you can make your own offer so that the auction is held and the sellers compete for it (of course, it may take some time!)
                    <br /><br />
                    <div style={{ textAlign: 'center' }}>
                        <NavLink to={`/marketplace?mode=trade&productId=${this.props.productId}`}><Button variant='outline-success'>Create Offer !</Button></NavLink>
                    </div>
                </Note>
            }
            {
                this.state.sellers.length !== 0 && (
                    this.state.pendingRequest > 0 ? <Button variant='success' disabled><Spinner animation='border' /></Button> :
                        <Button variant='success' onClick={this.addToCart} disabled={selectedItemCount <= 0}> <Cart3 /> Add To Cart</Button>)
            }
        </div>
    }
}

interface ProductCatalogSellersView {
    details: {
        delayDescription: string | null
        inventoryCount: number | null
        plans: [
            {
                isApiPlan: boolean,
                countFrom: number,
                countTo: number,
                price: number,
                discount: number
            }]
        isYourShop: boolean
        sellerShopId: string
        totalSoldCount: string
        productSoldCount: string
        title: string
    }
    count: number
}
