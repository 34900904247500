import { useSearchParams } from 'react-router-dom';
import AdminSupportTicket from '../../../components/admin/support-tickets/AdminSupportTicket';
import AdminAuthenticated from '../../../components/shared/AdminAuthenticated/AdminAuthenticated';
import { ADMIN, SUPERADMIN, SUPPORT } from '../../../constants/ConstantsPermissions';

export default function AdminTicketsRoute() {
    const [searchParams] = useSearchParams()
    let activeTab = searchParams.get("activeTab")
    
    return <AdminAuthenticated roles={[SUPERADMIN, ADMIN, SUPPORT]}><AdminSupportTicket activeTab={activeTab} /></AdminAuthenticated>
}