import React from "react"
import { Col, Row } from "react-bootstrap"
import InventorySourceTransactionService from "../../../../services/inventory/InventorySourceTransactionService"
import CardView from "../../../shared/Card/CardView"
import Amount from "../../../utilities/Amount/Amount"
import IState, { IProps } from './InventorySourceTransactionBalances.models'

export default class InventorySourceTransactionBalances extends React.PureComponent<IProps, IState>{
    inventorySourceTransactionService: InventorySourceTransactionService
    constructor(props: IProps) {
        super(props)
        this.inventorySourceTransactionService = new InventorySourceTransactionService()
        this.state = { titleValues: [] }
    }

    componentDidMount() {
        this.loadData()
    }

    loadData = () => {
        this.inventorySourceTransactionService.getTitleValue(this.props.id).then((response) => {
            this.setState({ titleValues: response })
        })
    }

    render(): React.ReactNode {
        return <>
            <Row>
                {this.state.titleValues.length > 0 &&
                    this.state.titleValues.map((titleValue, index) => {
                        return (
                            <Col md="4" sm="12" key={index} className="mb-2 mt-2">
                                <CardView card={{ header: titleValue.title }} > Balance: ${<Amount value={Number(titleValue.value)} />}</CardView>
                            </Col>
                        )
                    })
                }
            </Row>
        </>
    }
}