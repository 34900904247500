import { Badge } from "react-bootstrap";

export enum SupportTicketStatus {
    Pending,
    UserPending,
    TargetPending,
    Closed
}

export function supportTicketStatusDisplayCaption(status: number) {
    let types = ['In Progress', 'User Pending', 'Pending', 'Closed'];
    return types.length <= status ? status : types[status];
}

export function supportTicketStatusColor(status: number) {
    let types = ['warning', 'info', 'warning', 'success'];
    return types.length <= status ? 'dark' : types[status];
}

export default function supportTicketStatusDisplayBadge(status: number) {
    return <Badge bg={supportTicketStatusColor(status)}>{supportTicketStatusDisplayCaption(status)}</Badge>;
}