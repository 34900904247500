import { Route } from "react-router-dom";
import DefaultLayout from "../../components/layout/DefaultLayout";
import CreateShopOnly from "../../components/shops/CreateShopOnly/CreateShopOnly";
import CreateShopRoute from "../shops/CreateShopRoute";
import MyShopsRoute from "../shops/MyShopsRoute";
import ManagementRoute from "../shops/ManagementRoute";

export function ShopsRoutes() {
    return (
        <>
            <Route element={<DefaultLayout />}>
                <Route path="/management" element={<ManagementRoute />} />
                <Route path="/create-shop" element={<CreateShopRoute />} />
                <Route path="/new-shops" element={<MyShopsRoute />} />
            </Route>
            <Route element={<DefaultLayout hideSidebar hideMenu />}>
                <Route path="/create-shop-only" element={<CreateShopOnly />} />
            </Route>
        </>
    )
}