import React, { RefObject } from "react";
import { Alert, Button, Form, Spinner } from "react-bootstrap";
import { Check, ClipboardPlus } from "react-bootstrap-icons";
import { NavLink } from "react-router-dom";
import WalletService, { PaymentMethodDto } from "../../../../services/financials/WalletService";
import './WalletCharge.scss'
import { Step, Stepper } from "react-form-stepper";
import IWalletChargeState, { IWalletChargeProps } from "./WalletCharge.models";
import ValidAmount from "../../../utilities/Amount/ValidAmount";
import Note from "../../../shared/Note/Note";
import ChargePaymentForm from "./ChargePaymentForm/ChargePaymentForm";
import FormatMoney from "../../../utilities/FormatMoney";
import DepositService from "../../../../services/financials/DepositService";

export default class WalletCharge extends React.Component<IWalletChargeProps, IWalletChargeState> {
    walletService: WalletService;
    depositService: DepositService;
    chargePaymentFormRef: RefObject<ChargePaymentForm>
    USDTCurrencyId: string = 'b8b8eaaa-c421-4783-b481-ae4fea6024de';
    cryptoAddress: string = 'TRQJm7PcwTSkcfjEdUjsx6TxU6xZ49QnFD';
    constructor(props: any) {
        super(props);
        this.state = {
            fee: 0, finalAmount: 0, isCopied: false, paymentMethods: [], payUSDTRedirectLink: '',
            loading: false, activeStep: 0, description: '', peymentGenerationPending: false, verifiedInformation: false
        }
        this.walletService = new WalletService()
        this.depositService = new DepositService();
        this.chargePaymentFormRef = React.createRef()
    }

    componentDidMount() {
        if (this.props.DepositId)
            this.verifyDeposit();
        else
            this.setData()
    }

    verifyDeposit = () => {
        this.depositService.verify(this.props.DepositId)
            .then((response) => {
                this.setState({ verifyResult: response.verified === true })
            })
            .catch(() => {
                this.setState({ verifyResult: false })
            })
    }

    componentDidUpdate(prevProps: Readonly<{ currencyId: string | null; }>): void {
        if (prevProps.currencyId !== this.props.currencyId)
            this.setData()
    }

    setData = () => {
        this.setState({ loading: true })
        this.walletService.getPaymentMethods(this.props.currencyId).then((paymentMethods) => {
            this.setState({
                paymentMethods: paymentMethods,
                selectedPaymentMethod: paymentMethods.length > 0 ? paymentMethods[0] : undefined
            });

            if (paymentMethods.length == 0) {
                this.setState({ payUSDTRedirectLink: `/deposit/?currencyId=${this.USDTCurrencyId}` })
            }
        }).finally(() => this.setState({ loading: false }));
    }

    selectMethod = (paymentMethod: PaymentMethodDto) => {
        this.setState({ selectedPaymentMethod: paymentMethod });
    }

    copyTextToClipboard = async (text: string) => {
        if ('clipboard' in navigator) {
            this.setState({ isCopied: true }, () => setTimeout(() => {
                this.setState({ isCopied: false })
            }, 3000))
            return await navigator.clipboard.writeText(text)
        }
    }

    stepHandler = () => {
        let { activeStep } = this.state
        if (this.validation()) return

        if (activeStep == 2) {
            this.chargePaymentFormRef.current.savePayment();
            return;
        }

        if (activeStep != 3)
            this.setState({ activeStep: activeStep + 1, peymentGenerationPending: activeStep == 1 ? true : false })

        setTimeout(() => {
            this.state.peymentGenerationPending &&
                this.setState({ peymentGenerationPending: false })
        }, 2000)



    }

    goToConfirmation = () => {
        this.setState({ activeStep: 3 })
    }

    getPercentage = (amount: number, fee: number) => {
        return ((fee / 100) * amount)
    }

    onChangeAmount = (amount: string, isFinalAmount?: boolean) => {
        var fee = this.getPercentage(Number(amount), 0.5)
        this.setState({ amount: amount === "" ? null : isFinalAmount ? String(Number(amount) - fee) : amount, fee, finalAmount: isFinalAmount ? Number(amount) : Number(amount) + Number(fee) })
    }

    validation(): boolean {
        let { activeStep, selectedPaymentMethod, amount } = this.state
        if (this.chargePaymentFormRef)
            console.log('validation', this.chargePaymentFormRef.current)

        switch (activeStep) {
            case 0:
                return amount == undefined || amount == null
            case 1:
                return selectedPaymentMethod == undefined || selectedPaymentMethod == null
            case 2:
                return false
            case 3: return !this.state.verifiedInformation
        }

        return false
    }

    depositView = () => {

        if (this.state.verifyResult === undefined)
            return <>
                <div style={{ textAlign: 'center' }}>
                    <Spinner animation="grow" />
                    Please wait ...
                </div>
            </>
        return <div style={{ textAlign: 'center', margin: '150px' }}>
            {
                this.state.verifyResult === false ? <div style={{ color: '#5fc6cb' }}>
                    <img src="/images/timer-reload-flat-icon.webp" height="150px" /><br /><br />
                    Confirmation of this transaction may take time, Please wait until its final approval.<br /> (The time depends on your payment method).
                </div>
                    : <div style={{ color: 'rgb(72 195 0)' }}>
                        <img src="/images/tick.svg" height="150px" /><br /><br />
                        Your payment has been successfully verified, you can now use it.
                    </div>
            }

        </div>
    }

    render(): React.ReactNode {
        let { activeStep } = this.state
        if (this.props.DepositId)
            return this.depositView();
        else
            return <div className="WalletCharge">
                <h2>Deposit</h2>
                {this.state.loading ? <Spinner animation="grow" /> : this.state.paymentMethods.length == 0 ? this.notFoundMethod() :
                    <>
                        <div className="mt-4 mb-4">
                            {this.automaticallyDeposit()}
                        </div>
                        {this.stepperView()}
                        <div className="stepper-body pt-3 pb-3">
                            <div>
                                {this.stepperBodyView(activeStep)}
                            </div>
                            {
                                activeStep != 3 && <div className="mt-3 body-footer">
                                    {activeStep > 0 && <Button variant="outline-success" className="me-2" onClick={() => this.setState({ activeStep: activeStep - 1 })}>Previous</Button>}
                                    {
                                        activeStep === 2 && this.state.selectedPaymentMethod.providerKey !== 'TRON_SCAN' ?
                                            <Button variant="success" onClick={this.stepHandler} disabled={this.validation()}>Payment</Button> :
                                            <Button variant="success" onClick={this.stepHandler} disabled={this.validation()}>{activeStep == 2 ? 'Confirm' : 'Next'}</Button>

                                    }
                                </div>
                            }

                        </div>

                        <hr />
                        <p>
                            Do you have a problem? Let us know via <NavLink to="/create-support-ticket">Support Ticket</NavLink>
                        </p>
                    </>
                }
            </div>
    }

    automaticallyDeposit = () => {
        return <>
            <Alert variant="secondary">
                The deposit process may take time, It will be done up to the next 72 hours.
                If you want your deposits to be made <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>automatically</span>, you can <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>verify</span> your external wallet.
                <NavLink to={`/financials/?activeTab=externalWallets`} className={'m-2'}>
                    <Button variant="success" size="sm">External Wallets</Button>
                </NavLink>
            </Alert>
        </>
    }

    notFoundMethod = () => {
        return <>
            <Alert variant="warning">
                This currency don't have any payment method and you can only <b>exchange</b> from another currencies to this currency,
                For example you can charge your USDT wallet and exchange it to USA Dollar <NavLink to={this.state.payUSDTRedirectLink}><Button variant="warning" size="sm">Click to Pay with USDT</Button></NavLink>
            </Alert>
        </>
    }

    confermationView = () => {

        return <div style={{ color: '#5fc6cb', textAlign: 'center' }}>
            <img src="/images/timer-reload-flat-icon.webp" height="150px" /><br /><br />
            Confirmation of this transaction may take time, Please wait until its final approval.<br /> (The time depends on your payment method).
        </div>
    }

    paymentView = () => {
        let { finalAmount, amount, fee, transactionNumber } = this.state
        return <>
            {
                (this.state.selectedPaymentMethod.providerKey === 'TRON_SCAN') ? this.tronScanPaymentView() :
                    <div className="mt-4">
                        <span className="font-bold">How to charge wallet with Payment4 (Cryptocurrency Gateway) ?</span>
                        <p className="text-muted">
                            Click on Payment button,so you redirecting to online gateway, then in the payment page,
                            follow the steps according to the same instructions and then you will return here to finalize the payment.
                        </p>
                    </div>
            }
            <p className="body-sub-title">Enter your transaction details here. You can also exchange your sent USDT into USD automatically by checking Auto exchange.</p>
            <div className="body-sub-title font-sm">Amount: <span className="font-bold amount-size text-success ps-1">{FormatMoney(Number(amount))}</span></div>
            <div className="body-sub-title font-sm">Fee: <span className="font-bold amount-size text-success ps-1">{FormatMoney(fee)}</span></div>
            <div className="body-sub-title font-sm pb-3">Final Amount: <span className="font-bold amount-size text-success ps-1">{FormatMoney(finalAmount)}</span></div>
            <ChargePaymentForm ref={this.chargePaymentFormRef}
                currencyId={this.props.currencyId}
                changeAmount={(amount) => this.onChangeAmount(String(amount), true)}
                onPayment={() => this.goToConfirmation()}
                onInquiry={(verified, hash) => this.setState({ verifiedInformation: verified, transactionNumber: hash })}
                paymentMethod={this.state.selectedPaymentMethod} amount={Number(this.state.finalAmount)}
                transactionNumber={transactionNumber} description={this.state.description} walletAddress={this.cryptoAddress} />
        </>
    }


    tronScanPaymentView = () => {
        let { peymentGenerationPending, isCopied, finalAmount } = this.state
        return <>
            <div>
                <span className="body-title font-bold">Wallet Address</span>
                <p className="body-sub-title">Deposit this amount to the following wallet address:
                    <span className="font-bold amount-size text-success ps-1">{FormatMoney(finalAmount)}</span>
                </p>
                {peymentGenerationPending ? <div className="text-center pt-2">
                    <Spinner animation="border" />
                    <p className="text-muted pt-3">Payment Generation ...</p>
                </div> : <>
                    <Note>
                        <span> {this.cryptoAddress}</span>
                        <Button variant={isCopied ? "outline-success" : "outline-secondary"} size="sm" className="copy-btn ms-2" onClick={() => this.copyTextToClipboard(this.cryptoAddress ?? '')}>
                            {isCopied ? 'Copied' : < ClipboardPlus />}
                        </Button>
                    </Note>
                    <div className="mt-4">
                        <span className="font-bold">How to charge shop wallet with Cryptocurrency?</span>
                        <p className="text-muted">
                            Send the desired amount of (USDT) to this wallet ID by any external wallet apps that you have.
                            This way you can deposit into your shop wallet. Then wait a few minutes for your transfer to be accepted and validated by API Gift.
                        </p>
                        <hr />
                        <p>
                            Do you have a problem? Let us know via <NavLink to="/create-support-ticket">Support Ticket</NavLink>
                        </p>
                    </div>
                </>
                }
            </div>
        </>
    }

    enterPeymentInformationView = () => {
        return <>
            <span className="body-title font-bold">Enter Amount</span>
            <p className="body-sub-title">Specify how much do you want to top up your shop wallet by entering the desired number in the Amount field. You can also enter an optional description for your transaction.</p>
            <Form.Group className="mb-2" controlId="formBasicAmount">
                <Form.Label>Amount</Form.Label>
                <Form.Control type="text"
                    inputMode='decimal' placeholder="Enter amount"
                    value={this.state.amount}
                    onChange={(e) => ValidAmount(e.target.value) && this.onChangeAmount(e.target.value)} />
                {
                    this.state.amount &&
                    <p className="body-sub-title mt-1">Transafer Amount:
                        <span className="font-bold text-success ps-1">{FormatMoney(Number(this.state.finalAmount))}</span>
                        <div style={{ fontSize: '14px' }}> <span className="font-bold text-success ps-1">{FormatMoney(Number(this.state.fee))}</span> wallet top up fee will be added to your payment so you should pay <span className="font-bold text-success ps-1">{FormatMoney(Number(this.state.finalAmount))}</span> totally.</div>
                    </p>
                }
            </Form.Group>
            <Form.Group controlId="formBasicDescription">
                <Form.Label>Description</Form.Label>
                <Form.Control as="textarea" rows={3} placeholder="Enter description(optional)"
                    value={this.state.description}
                    onChange={(e) => this.setState({ description: e.target.value })} />
            </Form.Group>
        </>
    }

    paymentMethodView = () => {
        return <>
            <span className="body-title font-bold">Choose Payment Method</span>
            <p className="body-sub-title">In order to charge or top up your wallet, you must first choose a payment method. Find and select it from the list below:</p>
            <ul>
                {
                    this.state.paymentMethods.map((method) => {
                        let selected = this.state.selectedPaymentMethod.id === method.id;
                        return <li className={selected && 'selected'} style={{ marginTop: '10px' }}
                            key={method.id}
                            onClick={() => this.selectMethod(method)}><h3>{method.title}</h3>
                            <div className="description">
                                {method.description}
                            </div>
                            {selected && <Check className="select-check" />}
                        </li>
                    })
                }
            </ul>
        </>
    }

    stepperBodyView = (step: number) => {
        switch (step) {
            case 0:
                return this.enterPeymentInformationView();
            case 1:
                return this.paymentMethodView();
            case 2:
                return this.paymentView();
            case 3:
                return this.confermationView();
        }
    }

    stepperView = () => {
        const { activeStep } = this.state
        let isMobile = window.innerWidth > 960 ? false : true

        return <>
            <Stepper
                styleConfig={{
                    activeBgColor: '#0468c8',
                    activeTextColor: '#fff',
                    inactiveBgColor: '#a2cffe',
                    inactiveTextColor: '#ffffff',
                    completedBgColor: '#008c76',
                    completedTextColor: '#ffffff',
                    borderRadius: "8px",
                    circleFontSize: isMobile ? 12 : 16,
                    fontWeight: 600,
                    labelFontSize: isMobile ? 12 : 16,
                    size: isMobile ? '1.5em' : '2em'
                }}
                activeStep={activeStep}>
                <Step label="Enter Amount" onClick={() => this.state.activeStep !== 3 && this.setState({ activeStep: 0 })} />
                <Step label="Payment Method" onClick={() => this.state.activeStep !== 3 && this.setState({ activeStep: 1 })} />
                <Step label="Payment" onClick={() => this.state.activeStep !== 3 && this.setState({ activeStep: 2 })} />
                <Step label="Confirmation" onClick={() => this.setState({ activeStep: 3 })} />
            </Stepper>
        </>
    }
}